export const fr = {
    "name": "French",
    "flagCode": "fr",
    "locale": "fr-fr",
    "project": {
        "name": "QUp New CC "
    },
    "Sidebar": {
        "Setting": "Paramètres",
        "Map": "Carte",
        "Cue": "Liste reservations",
        "Invoice": "Invoice",
        "Supplier": "Supplier",
        "Vehicle": "Vehicle",
        "Merchant": "Merchant",
        "ImportBookings": "Import Transport Bookings",
        "ImportDeliveryBookings": "Import Delivery Bookings",
        "Trip": "Trip",
        "New_booking": "Nouvelle réservation",
        "City": "Ville",
        "Chauffeur": "Transport",
        "Delivery": "Livraison",
        "Shuttle": "Shuttle",
        "Intercity": "Intercity",
        "Customer": "Clients",
        "Driver": "Chauffeurs",
        "REPORTS": "Rapports",
        "Corporate": "Entreprise",
        "mDispatcher": "MDispatcher",
        "Reservation": "Calendar",
        "withdrawalRequest": "Demandes de retrait",
        "Driver_Settlement": "Règlement du conducteur",
        "Dashboard": "Statistiques",
        "SmartData": "Smart data",
        "Smart_Data": "Smart data",
        "Campaign_Builder": "Campaign builder",
        "Company_operator": "Company operator",
        "Queuing_area": "Queuing area",
        "Withdrawal_Requests": "Demandes de retrait",
        "pickUp": "Pick up",
        "dropOff": "Drop off",
        "SignUpNotifications": "Sign Up Notifications",
        "Payout": "Paiement ",
        "Payout_label": "Paiement ",
        "payout": {
            "Pay_To_Driver": "Pay to Drivers",
            "Payout_History_For_Driver": "Payout Driver History",
            "Pay_To_Merchant": "Pay to Merchants",
            "Payout_History_For_Merchant": "Payout Merchant History"
        },
        "number_of_records_to_export": "Number of records to export",
        "export": "Export",
        "Airline": "Airline",
        "driverPayout": {
            "Pay_to_driver": "Pay to Drivers",
            "Payout_history": "Payout History"
        },
        "Reports": {
            "Driver_Referral_history": "Driver refers customer",
            "Driver_refers_Pax": "Drivers",
            "Refund": "Refund",
            "Driver_refers_Pax_Customers": "Customers",
            "Driver_refers_Pax_Bookings": "Bookings",
            "Driver_refers_customer_Drivers": "Driver refers customer (Drivers)",
            "Driver_refers_customer_Customers": "Driver refers customer (Customers)",
            "Driver_refers_customer_Bookings": "Driver refers customer (Bookings)",
            "Passenger_refers_Passengers": "Customer refers customer",
            "Driver_refers_drivers_Referrer": "Driver refers drivers (Referrer)",
            "Deletion_request": "Deletion request",
            "Driver_refers_drivers_Referee": "Driver refers drivers (Referee)",
            "Driver_refers_Driver": "Driver refers driver",
            "Driver_refers_driver_Referrer": "Referrer",
            "Driver_refers_driver_Referee": "Referee",
            "Referral_history": "Referral history",
            "Booking_details": "Détails réservations",
            "Financial": "Financier",
            "Financial_driver": "Pilotes",
            "Financial_fleet": "Flotte",
            "Financial_company": "Compagnie",
            "Financial_Driver": "Financier (Pilotes)",
            "Financial_Fleet": "Financier (Flotte)",
            "Financial_Company": "Financier (Compagnie)",
            "Financial_fleet_profit": "Profit de la flotte",
            "Financial_Company_Tooltip": "Rapport des revenus net de chaque Transport, groupé par compagnie",
            "Financial_Driver_ToolTip": "Rapport des revenus net de chaque Transport",
            "Financial_Fleet_Profit_ToolTip": "Profit de la flotte",
            "Driver_activity": "Activité du Driver",
            "Revenue_Summary": "Revenue summary",
            "Revenue_fleet": "Flotte",
            "Revenue_driver": "Chauffeurs",
            "Revenue_monthly": "Monsuel",
            "Revenue_Summary_Fleet": "Récapitulatif des recettes (Flotte)",
            "Revenue_Summary_Driver": "Récapitulatif des recettes (Pilotes)",
            "Revenue_Summary_Monthly": "Récapitulatif des recettes (Monsuel)",
            "Total_adjustment": "Ajustement total",
            "Promotion": "Promotion",
            "Incident_Cancellation": "Incident & annulation",
            "Booking_logs": "Logs réservations",
            "Rating": "Évaluation",
            "Thumbs_rating": "Thumbs rating",
            "Stars_rating": "Driver rating",
            "Operator_logs": "Logs opérateur",
            "Daily": "Quotidien",
            "Daily_Driver": "Transport (quotidien)",
            "Daily_Car": "Voiture (quotidien)",
            "Daily_Driver_Car": "Transport Voiture (quotidien)",
            "mDispatcher": "MDispatcher",
            "Corporate": "Entreprise",
            "Driver_login_status": "Statut de connexion du Transport",
            "Car_activity": "Activité du véhicule",
            "Partner": "Partenaire",
            "Prepaid_top_up": "Réapprovisionner la carte prépayée",
            "Affiliation": "Affiliation",
            "Affiliation_penalty_compensation": "Pénalité/Compensation",
            "Affiliation_payout_history": "Settlement history",
            "Affiliation_bookings": "Réservations",
            "Booking_summary": "Résumé des réservations",
            "Cash_Wallet": "Cash wallet",
            "Credit_Wallet": "Portefeuille de crédit",
            "Driver_wallet": "Portefeuille du conducteur",
            "Driver_Deposit": "Credit Wallet",
            "Driver_cash_wallet": "Cash Wallet",
            "Driver_Deposit_old": "Driver deposit",
            "Document_expiry": "Document expiry",
            "Settlement": "Installation",
            "Unapproved_Driver": "Driver non approuvé",
            "Approved_Driver": "Driver approuvé",
            "Pay_to_driver": "Payé au Transport",
            "Settlement_history": "Historique d'implantation",
            "Withdrawal_History": "Retrait du Transport",
            "creditWalletTooltip": "Le Transport a fait un dépot dans le porte-monnaie",
            "Incomplete_payment": "Paiement incomplet",
            "Driver_withdrawal": "Retrait du chauffeur",
            "Incident_&_Cancellation": "Portefeuille du passager",
            "Passenger_wallet": "Portefeuille du passager",
            "Accept_Cancel_rate": "Acceptance/Cancellation rate",
            "Acceptance/Cancellation_rate": "Acceptance/Cancellation rate",
            "driverDepositTooltip": "Le chauffeur a fait un dépot dans le porte-monnaie",
            "Customer_quest": "Quest",
            "First_will_win": "First will win",
            "Largest_will_win": "Largest will win",
            "redeemed": "Code #",
            "Code_#": "Code #",
            "Quests": "Quest",
            "Merchant_Report": "Vendeur",
            "Merchant_Financial_Report": "Financial",
            "Merchant_Cash_Report": "Cash wallet",
            "Merchant_Credit_Report": "Credit wallet",
            "Deletion_Request": "Deletion request",
            "Driver_Rating": "Customer rates driver",
            "Passenger_Rating": "Driver rates customer",
            "Company_settlement": "Company settlement",
            "Export": "Export History",
            "Third_Party_Booking": "3rd party Booking",
            "BookingDotCom": "Booking.com",
            "Third_Party_Booking_Bookingcom": "Third Party Booking (Booking.com)",
            "Third_Party_Booking_HolidayTaxis": "Third Party Booking (HolidayTaxis)",
            "Third_Party_Booking_Karhoo": "Third Party Booking (Karhoo)",
            "Third_Party_Booking_Hoppa": "Third Party Booking (Hoppa)"
        },
        "Settings": {
            "Fleet_info": "Informations de la flotte",
            "Surchage_Policy": "Surcharge Policy",
            "RateLegacy": "Rate (Legacy)",
            "Driver": "Driver",
            "Merchant": "Merchant",
            "Calendar": "Calendar",
            "Socaial": "Socaial",
            "Quest": "Quest",
            "Referral": "Referral",
            "Social": "Social",
            "Shift": "Shift",
            "Rate_Templates": "Rate Templates",
            "Supplier_Plan": "Supplier Plan",
            "Penalty_Policy": "Penalty Policy",
            "Stripe_connect": "Stripe Connect",
            "stripe_title_connect": "Activate your Stripe Connect account to receive your payout automatically.",
            "Payment_info": "Billing info",
            "Permission": "Autorisation",
            "Driver_refer_driver": "Driver refer driver",
            "Show_referal_history": "Show/hide referral history on driver app",
            "companyTooltip": "Supplier company",
            "User": "Utilisateur",
            "General": "Général",
            "Regular": "Ordinaire",
            "Sharing": "Street Sharing",
            "Flat": "Forfaits",
            "Hourly": "Tarif de mise à disposition",
            "Company": "Compagnie",
            "Dispatch": "Dispatch",
            "Voice_SMS": "Voix & SMS",
            "Rate": "Tarif",
            "RateNew": "Rate",
            "Car": "Véhicule",
            "Car_type": "Type de véhicule",
            "Car_mgmt": "Gestion des véhicules",
            "Car_Make": " Marque et modèle",
            "Stripe_Connect": "Stripe connect",
            "Voice_&_SMS": "Voice & SMS",
            "Street-sharing_rate": "Street sharing rate",
            "Partner_type": "Type de partenaire",
            "Shift_template": "Modèle Shift",
            "Driver_App": "Driver App",
            "Shift_settings": "Paramètres Shift",
            "Operation": "Opération",
            "Driver_Note": "Driver note",
            "Promotion_code": "Code promo",
            "Point_config": "Point config",
            "Code_#": "Code #",
            "Voucher_code": "Code #",
            "Campaign": "Campagne",
            "Queuing_area": "Zone de file d'attente",
            "Referral_code": "Code Parrainage",
            "City": "Ville",
            "category": "Category",
            "3rd_party_location": "Emplacement de la 3ème partie",
            "Zone": "Zone de filtre",
            "App_banner": "Banière de l'application",
            "App_banner_pegasus": "Bannière de l'appli",
            "Flight_integration": "Flight Integration",
            "SMS_integration": "SMS Integration",
            "Invoice_Setting": "Invoice",
            "Driver_refers_customer": "Driver refers customer",
            "Pax_refers_Pax": "Customer refers customer",
            "Customer_refers_customer": "Customer refers customer",
            "Dynamic_Surcharge": "Supplément dynamique",
            "Dynamic_Fare": "Tarif dynamique",
            "FlatZone": "Flat rate",
            "IntercityZone": "Intercity",
            "Intercity_Zone": "Intercity Zone",
            "Intercity_Rate": "Intercity Rate",
            "Car_Color": "Couleur du véhicule",
            "third_party_integration": "3rd party integration",
            "bookingCom": "Booking.com",
            "HolidayTaxis": "HolidayTaxis",
            "Karhoo": "Karhoo",
            "Corporate": "Corporate",
            "KarhooIntegration": "Karhoo Integration",
            "holidaytaxis": "HolidayTaxis",
            "karhoo": "Karhoo",
            "hoppa": "Hoppa",
            "Hoppa": "Hoppa",
            "HoppaIntegration": "Hoppa Integration",
            "Third_Party_Integration_Hoppa": "3rd party Integration (Hoppa.com)",
            "titleHoppa": "Hoppa API Credentials",
            "noteHoppa": "Communicate with Hoppa’s technical team to provide you with client, and secret key to connect to their system.",
            "titleHoppaWebhook": "Webhook Configuration",
            "noteHoppaWebhook": "Provide the parameters below for Hoppa to configure. These credentials are used to communicate between both platforms. For any issues please contact your tech provider.",
            "Third_Party_Integration_Booking": "3rd party Integration (Booking.com)",
            "Third_Party_Integration_HolidayTaxis": "3rd party Integration (HolidayTaxis)",
            "Third_Party_Integration_Karhoo": "3rd party Integration (Karhoo.com)",
            "Third_Party_Integration_Mozio": "3rd party Integration (Mozio.com)",
            "invoiceBranding": "Invoice Branding",
            "titleInvoice": "Customize your company header and footer information.",
            "titleheader": "Header Title",
            "descriptionHeader": "Header Description",
            "placehodelHeader": "For example add company details like email, phone number, address, tax number, etc.",
            "titleFooter": "Footer Title",
            "placehodelFooter": "For example, add bank wire information, or any policy etc",
            "descriptionFooter": "Footer Description ",
            "titleBookingCom": "Booking.com API Credentials",
            "noteBookingCom": "Communicate with booking.com’s technical team to provide you with client, and secret key to connect to their system.",
            "clientKey": "Client Key",
            "placeHolderClientKey": "Enter client key",
            "secretKey": "Secret Key",
            "placeHolderSecretKey": "Enter client secret",
            "titleBookingComWebhook": "Webhook Configuration",
            "noteBookingComWebhook": "Provide the parameters below for booking.com to configure. These credentials are used to communicate between both platforms. For any issues please contact your tech provider.",
            "webhook": "Fleet Webhook URL",
            "clientId": "Fleet Client Key",
            "clientSecret": "Fleet Secret Key",
            "driver_app": "Driver App",
            "Jobs_tab": "Jobs Tab",
            "Email_config": "Email config",
            "Driver_fields": "Driver fields",
            "Driver_document": "Driver document",
            "Selected": "Selected",
            "API_Management": "API management",
            "Penalty_Policy_Customer": "Customer Penalty Policy",
            "Penalty_Policy_Supplier": "Supplier Penalty Policy"
        }
    },
    "APIKeySetting": {
        "APIKey": "API Keys",
        "APIKey_title": "Do not share your API key with others, or expose it in the browser or other client-side code. In order to protect the security of your account, Good Journey may also automatically disable any API key that we've found has leaked publicly.",
        "newKeyBtn": "Create new key",
        "updateApplication": "Update Application",
        "RegisterApp": "Register a new application",
        "RegisterBtn": "Register Application",
        "appDescription": "App Description",
        "appName": "App Name",
        "clientKey": "Client Key",
        "secretKey": "Secret Key",
        "hostedEndPoint": "Hosted endpoints",
        "maxEndpoints": "You can have a max of 5 endpoints",
        "addEndpoint": "Add Endpoint",
        "updateEndpoint": "Update Endpoint",
        "ManageWebhooks": "Manage Webhooks",
        "CreateWebhook": "Create webhooks",
        "subscriptionID": "Subscription ID",
        "webhookURL": "Webhook URL",
        "signingSecret": "Signing secret",
        "eventsToSend": "Events to send",
        "listeningFor": "Listening For",
        "assignedDriver": "Assigned Driver",
        "bookingStatus": "Booking Status",
        "driverLocation": "Driver Location",
        "titleSigningSecret": "This secret is provided by your partner. If for any reason the secret has been compromised, you can update the secret, or delete the web hook.",
        "notUsingHTTPS": "Webhook URL is not using HTTPS.",
        "invalidURL": "Webhook URL is invalid.",
        "clientType": "Client Type",
        "individual": "Individual",
        "corporate": "Corporate",
        "signingSecretLength": "Signing secret must be at least 30 characters long.",
        "bookingCreated": "Booking Created",
        "partner": "Partner",
        "fleetManager": "Fleet Manager"
    },
    "messages": {
        "credits": {
            "406": "L'action a échoué",
            "407": "L'action a échoué",
            "416": "Coupon has been used",
            "417": "Your account has been inactive. Please contact our adminitrator",
            "418": "There were errors when making payment",
            "419": "Promo code is invalid!",
            "420": "This promo code was applied for another ride. Please use another one.",
            "421": "Le numéro de la carte n'est pas valide.",
            "422": "Date d'expiration non valide.",
            "429": "Code postal invalide.",
            "430": "L'AVS et le Code postal ne correspondent pas",
            "431": "Code postal non vérifié par AVS",
            "432": "CVV non valide.",
            "433": "CVV ne correspond pas",
            "434": "CVV non vérifié",
            "435": "L'émetteur du CVV ne participe pas",
            "436": "Cette carte a été déclinée par l'émetteur. Veuillez le contacter pour plus d'informations",
            "437": "Fonds insuffisants. S'il vous plaît vérifier votre solde!",
            "439": "CVV non traité",
            "441": "Aucune donnée CVV de l'émetteur",
            "443": "Pas de données de l'émetteur/Banknet switch",
            "445": "Système AVS dans l'impossibilité d'exécuter",
            "446": "La banque émettrice ne prend pas en charge les AVS",
            "447": "Erreur, AVS non pris en charge pour votre entreprise",
            "448": "Cette carte a été bloquée à cause de plusieurs tentatives de paiement refusées. Veuillez changer de moyen de paiement ou réessayer plus tard",
            "452": "Votre carte est refusée. Veuillez saisir une autre carte de crédit enregistrée dans la même zone que votre numéro de téléphone",
            "453": "Non supporté",
            "454": "La vérification d'adresse ne correspond pas",
            "455": "Les vérifications d'adresse et de Code Postal ne correspondent pas",
            "458": "Authentification échouée",
            "459": "La banque ne prend pas en charge",
            "461": "Cette carte n'est pas prise en charge",
            "462": "Nom du titulaire invalide",
            "463": "Code de vérification invalide",
            "464": "La banque ne prend pas en charge l'envoi et la vérification de code",
            "470": "Please enter the traveler's email address before make a booking with credit card",
            "474": "Please enter the traveler's name before make a booking with credit card",
            "475": "Phone number is required",
            "476": "Amount is invalid",
            "524": "Le compte n'a pas les fonds suffisants pour couvrir le montant de la transaction.",
            "911": "We are unable to authenticate your payment method. Please choose a different payment method and try again.",
            "2000": "N'honorez pas",
            "2001": "Le compte n'a pas les fonds suffisants pour couvrir le montant de la transaction.",
            "2002": "Limite dépassée",
            "2003": "Le détenteur de la carte a dépassé sa limite d'activité",
            "2004": "Carte périmée",
            "2005": "Numéro de carte de crédit non valide",
            "2006": "Date d'expiration non valide",
            "2007": "Pas de compte",
            "2008": "Problème de longueur de numéro de compte",
            "2009": "Cet emetteur n'existe pas",
            "2010": "CVV refusé",
            "2011": "Voice Authorization Required. The cardholder&#39;s bank is requesting that the merchant calls to obtain a special authorization code in order to complete this transaction",
            "2012": "Paiement décliné - Possibilité que la carte soit perdue",
            "2013": "Paiement décliné - Possibilité que la carte soit volée",
            "2014": "Paiement décliné - Fraude suspectée.",
            "2015": "Transaction refusée",
            "2016": "Dupliquer la transaction",
            "2017": "Le détenteur a annulé la transaction",
            "2018": "Le détenteur de la carte a annulé toutes ses transactions",
            "2019": "Transaction invalide",
            "2020": "Violation",
            "2021": "Violation de sécurité",
            "2022": "Refusé - Nouveau détenteur disponible",
            "2023": "Cette fonction n'est pas prise en charge",
            "2024": "Type de carte non activé",
            "2025": "Erreur de set-up - Commercant",
            "2026": "Numéro de commercant invalide",
            "2027": "Erreur de set-up - Montant",
            "2028": "Erreur de set-up - Hierarchie",
            "2029": "Erreur de set-up - Carte",
            "2030": "Erreur de set-up - Terminal",
            "2031": "Erreur d'encryptage",
            "2032": "Supplément refusé",
            "2033": "Donnée incompatible",
            "2034": "Pas d'action effectuée",
            "2035": "Acceptation Partielle pour le montant en version Group III",
            "2036": "L'autorisation est introuvable pour l'annulation",
            "2037": "Déjà annulé",
            "2038": "Processor Declined. The customer&#39;s bank is unwilling to accept the transaction, need to contact their bank for more details.",
            "2039": "Code d'autorisation invalide",
            "2040": "Store invalide",
            "2041": "Refusé - Appeler pour autorisation",
            "2042": "Numéro de client invalide",
            "2043": "Erreur - ne pas réessayer et appeler l'émetteur",
            "2044": "Refusé - Appeler l'émetteur",
            "2045": "Numéro de Commercant invzlide",
            "2046": "Declined. The customer&#39;s bank is unwilling to accept the transaction, need to contact their bank for more details.",
            "2047": "Appelez l'émetteur. Récupérez la Carte.Cette erreur indique que la carte a été reportée volée ou perdue par le titulaire",
            "2048": "Montant invalide",
            "2049": "SKU invalide",
            "2050": "Plan de crédit invalide",
            "2051": "Le numéro de carte bleue ne correspond pas à la méthode de paiement",
            "2052": "Achat Niveau III invalide",
            "2053": "Carte reportée perdue ou volée",
            "2054": "Le montant ne correspond pas à l'autorisation",
            "2055": "Numéro de transaction invalide",
            "2056": "Le montant de la transaction excède la limite de division",
            "2057": "L'émetteur ou le titulaire a indiqué une restriction sur la carte",
            "2058": "Le commerçant n'a pas activé le code de sécurité Master CardMerchant not MasterCard SecureCode enabled.",
            "2060": "Vérification de l'Adresse et Code de Sécurité de la Carte échoués",
            "2061": "Données de transaction invalides",
            "2062": "Montant de Taxe invalide",
            "2064": "Code de devise invalide",
            "2078": "Donnée de sécurisation de paiement invalide",
            "2080": "Crédits utilisateur invalides",
            "3000": "Réseau indisponiblle - Réessayez",
            "4001": "Règlement refusé",
            "4006": "Le montant excède la limite autorisée",
            "81703": "Type de carte de crédit non accepté par ce compte marchand.",
            "81706": "CVV nécessaire",
            "81707": "CVV doit être 3 ou 4 chiffres.",
            "81709": "Date d'expiration nécessaire.",
            "81710": "Date d'expiration invalide.",
            "81714": "Un numéro de carte de crédit est nécessaire.",
            "81715": "Le numéro de carte de crédit est invalide.",
            "81716": "Le numéro de carte de crédit doit être de 12 à 19 chiffres.",
            "81717": "Le numéro de carte de crédit n'a pas un nombre de test accepté.",
            "81718": "Le numéro de carte de crédit ne peut pas être mis à jour vers un type de carte non pris en charge tant qu'il est associé à des inscriptions.",
            "81723": "Le nom du titulaire est trop long. Maximum 175 caractères.",
            "81809": "Le code postal ne peut contenir plus de 9 caractères",
            "81813": "Le code postal ne peut contenir que des lettres, chiffres, espaces, et tirets",
            "91722": "Le mode de paiement jeton est nécessaire.",
            "91723": "Mis à jour du jeton existant invalide",
            "91730": "Vérification non prise en charge sur ce compte marchand.",
            "91734": "Type de carte de crédit non accepté par ce compte marchand.",
            "91738": "Le mode de paiement spécifié n'est pas une carte de crédit.",
            "91745": "Paramètres non valides pour la mise à jour de carte de crédit.",
            "91826": "Code postal invalide. Il doit être composé de 5 à 9 caractères, qui peuvent être séparés par un tiret ou un espace",
            "-1": "Ce client a été désactivé"
        },
        "reBookingMsg": {
            "303": "L'heure que vous avez sélectionné doit être postérieure à l'heure actuelle!",
            "304": "Voulez-vous terminer cette réservation sans paiement?",
            "500": "Certaines informations de réservation sont invalides ou manquantes. Veuillez vérifier et réessayez!"
        },
        "promoMsg": {
            "304": "Le code promo est pas invalide",
            "306": "Invalid promotion code. It seems that the promo is applied to other zones.",
            "413": "Désolé, ce code promo n'est pas disponible en ce moment",
            "414": "Le code promo est pas invalide",
            "415": "Le code promo est pas invalide",
            "416": "Le code promo a été utilisé.",
            "419": "Désolé, ce code promo n'est pas disponible en ce moment",
            "420": "Sorry, you have reached your limited promo uses",
            "421": "Désolé, ce code promo a atteint sa quantité maximale ",
            "422": "Ce code promo ne peut pas être utilisé avec votre compte ",
            "423": "Désolé, vous avez atteint votre limite de budget de promotions ",
            "424": "Invalid promotion code. It seems that the promo is only applied to referred customers",
            "425": "This promo code has reached its usage limit per day.",
            "426": "You have reached your limited promo uses per day.",
            "427": "Cannot apply promotion code. This customer’s profile is incomplete.",
            "428": "This customer has used up monthly promotion code.",
            "430": "You have used up your yearly promotion codes.",
            "431": "The promotion code can't be used for this payment method.",
            "432": "This fare amount isn't eligible for the promotion code.",
            "433": "The promotion code isn't eligible to use at selected time.",
            "434": "Sorry, you have reached your limited promo budget.",
            "phoneInvalid": "Veuillez entrer le numéro de téléphone du passager avant d'appliquer le code promo"
        },
        "updateBookingMsg": {
            "303": "La mise à jour de la réservation a échoué, Veuillez vérifier et essayer à nouveau!",
            "304": "Cette réservation n'a pas été trouvée",
            "pickUpTime": "L'heure que vous avez sélectionné doit être postérieure à l'heure actuelle!",
            "pickup": "Il n'y a pas de service de réservation disponible dans cette zone",
            "destination": "Certaines informations de réservation sont invalides ou manquantes. Veuillez vérifier et réessayez!",
            "dispatchType": "Certaines informations de réservation sont invalides ou manquantes. Veuillez vérifier et réessayez!"
        },
        "sessionExpired": {
            "403": "Vous avez été deconnecté car votre compte est connecté depuis un autre appareil, ou votre session a expiré"
        },
        "not_found": {
            "404": "The page you requested could not be found"
        },
        "booking": {
            "303": "L'heure que vous avez sélectionné doit être postérieure à l'heure actuelle!",
            "304": "Cette réservation n'a pas été trouvée",
            "305": "Booking canceled or completed by Booking.com; please reject the job to remove it from the queue.",
            "306": "No rate assign to selected car type. To apply a rate for any car type, please go to Settings > Car > Car Type.",
            "307": "Can not update booking. Please try again later.",
            "407": "Type rate ko support extra location",
            "500": "Certaines informations de réservation sont invalides ou manquantes. Veuillez vérifier et réessayez!",
            "upgroup_success": "Booking has been ungrouped successfully",
            "Please_Select_driver": "Please select driver",
            "assign_driver_vehicle_success": "Driver/Vehicle has been assigned successfully",
            "assign_driver_success": "Driver has been assigned successfully",
            "detach_driver_success": "Driver has been detach successfully",
            "detach_vehicle_success": "Vehicle has been detach successfully",
            "Group_success": "Booking has been grouped successfully",
            "change_supplier_success": "Booking has been changed suppliers successfully",
            "change_supplier_partial": "Partial Success: Batch assignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
            "detach_driver_partial": "Partial Success: Batch assignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
            "assign_driver_partial": "Partial Success: Batch assignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
            "update_dispatch": "Update & Dispatch",
            "remove_booking_false": "Failed to remove booking",
            "remove_booking_success": "Booking has been removed successfully",
            "GROUP_NOT_FOUND": "Group Id is not found",
            "NO_SHARING_BOOKINGS": "You can only group bookings if the customer’s booking allows ride-sharing.",
            "DIFFERENT_CAR_TYPES": "The booking you are trying to group has a different car-type",
            "LIMIT_EXCEEDED_ON_SEATS_OR_LUGGAGE": "Booking cannot be grouped: Seat or luggage limit exceeded.",
            "INVALID_CAR_TYPE": "Cannot assign driver, check driver’s vehicle seat & luggage capacity.",
            "VALIDATION_ERROR": "Validation error",
            "INTERNAL": "Internal server error",
            "fare_gt_zero": "Le tarif doit être supérieur à 0!",
            "edit_total_fail": "Echec de la modification de la valeur totale!",
            "complete_fail": "The booking cannot be completed!",
            "cancle_fail": "Echec de l'annulation",
            "incident_fail": "Echec de l'incident",
            "phone_invalid": "Numéro de téléphone non valide!",
            "add_psg_fail": "Impossible d'ajouter de nouveaux passagers",
            "REMOVE_RECIPIENT_DELIVERED": "Cannot remove recipient has been delivered",
            "REMOVE_LAST_ACTIVE_RECIPIENT": "Cannot remove the last active recipient",
            "ORDER_HAS_BEEN_FINISHED": "Order has been finished already",
            "end_booking_leave": "Quitter cette page mettra fin à votre réservation",
            "update_booking_fail": "La mise à jour de la réservation a échoué",
            "EXTERNAL_ID_EXISTED": "External ID already exist on the system.",
            "area_service_unavailable": "Il n'y a pas de service de réservation disponible dans cette zone",
            "pickupSameDestination": "L'adresse de prise en charge doit être différente de la destination.",
            "time_invalid": "L'heure que vous avez sélectionné doit être postérieure à l'heure actuelle!",
            "psg_inactivate": "Votre compte a été inactif. Veuillez contacter notre administrateur",
            "data_incorect": "Something went wrong. Please try again later.",
            "preAuthorize": "Please check your account as an amount of {0} is required to guarantee your booking",
            "booking_limited": "Sorry, you have reached the maximum number of bookings per minute/day. Please try again later.",
            "minimumBookAhead": "Reservation pickup time must be at least {0} hour(s) {1} minute(s) from current time",
            "exDoDiffDo": "Extra destination must be different from destination.",
            "recurringTimeRange": "Date out of range. You can only create bookings {0} months in advance. Please contact admin for any issues.",
            "recipientsLimit": "Maximum number of recipients is {0}.",
            "maximumBookAhead": "Reservation pickup time cannot be later than {0} day(s) from current time",
            "Please_select_or_add_a_card": "Veuillez sélectionner ou ajouter une carte!",
            "Unable_to_process_by_fare_zero": "We are unable to process your request, please change car type or booking type and try again. If you need any assistance, please contact our support team",
            "Can_not_connect_to_server": "Cannot connect to server!",
            "Check_connection_or_contact_system_support": "Please check your connection or contact system support for help!",
            "Create_new_booking_success": "A new booking has been created successfully!",
            "Create_new_recurring_booking_success": "New booking(s) has been created successfully",
            "Update_booking_success": "This booking has been updated successfully!",
            "Booking_drop_of_success": "Booking drop off Sucessful",
            "Cancel_booking_success": "This booking has been cancelled successfully!",
            "adjust_fare_booking_success": "Fare has been adjusted successfully!",
            "adjust_fare_booking_fail": "Fare adjustment failed!",
            "Booking_detail_not_found": "Booking detail is not found!",
            "Check_promo_error": "Check promoCode error!",
            "Invalid_format": "Invalid format",
            "Flight_not_found": "Flight not found!",
            "Add_new_credit_error": "Add new credit error!",
            "Socket_server_disconnected": "Socket server disconnected!",
            "Please_reload_page_or_check_your_connection": "Sorry. Your request cannot be processed. Please try again.",
            "request_timeout": "Sorry. Your request timeout. Please try again.",
            "Check_customer_error": "Check customer error!",
            "Please_select_car_type": "Please select car type",
            "DropOff_country": "Drop-off point not available for this car type. Please choose another car type or adjust route.",
            "Different_Country": "Pickup and drop-off locations must be in 2 different countries.",
            "Please_enter_destination_for_ridesharing_booking": "Please enter destination for ride-sharing booking",
            "Extra_detination_must_be_difference_from_destination": "Extra detination must be difference from destination",
            "Pickup_address_must_be_different_from_destination": "Pick-up address must be different from destination",
            "Best_price": "Best price",
            "You_may_be_charged_extra_fee_if_your_trip_excceds": "You may be charged extra fee if your trip exceeds",
            "You_may_be_charged_extra_fee_if_your_trip_excceds_with_params": "You may be charged extra fee if your trip exceeds {0} {1}, {2} {3}",
            "Reject_booking_success": "This booking has been rejected successfully!",
            "Reject_fail": "Something went wrong when rejecting this booking. Please try again!",
            "Please_select_payment_method": "Please select payment method",
            "Driver_signed_out_or_deactivated": "This driver has just signed out or been deactivated",
            "reservation_booking_overlaps": "Sorry, this reservation booking overlaps with another one that Driver has accepted.",
            "driver_is_not_available": "Sorry, this driver isn’t available at the requested time.",
            "seats_luggage_limit": "This booking can’t be assigned to the driver because the seats/luggage limit has been exceeded.",
            "full_capacity": "This booking can’t be assigned to the driver because the trip has reached its maximum capacity.",
            "marked_failed_ok": "The order has been marked as failed.",
            "warning_markFail_lastRecipient": "This is the last recipient of this booking. If you proceed with marking this order as failed you will then need to complete this booking.",
            "BOOKINGS_LIMIT_EXCEEDED": "You can only assign a maximum of 5 orders into each group",
            "bookingCanceled": "Booking canceled or completed by Booking.com; please reject the job to remove it from the queue."
        },
        "item_no_found": "Aucun élément n'a été trouvé.",
        "commonMessages": {
            "greater_than_0": "Veuillez entrer une valeur supérieure à 0.",
            "Required_field": "Ce champ est requis",
            "bankPhone_is_required": "Phone number is invalid",
            "bankPhone": "Phone number",
            "distance_from_equa_or_smaller": "The distance 'from' must be smaller than or equal to the distance 'to'",
            "must_be_number": "Veuillez entrer un nombre valide",
            "equal_or_greater_than_0": "Please enter a value equal or greater than 0.",
            "must_be_integer": "Veuillez entrer un nombre entier",
            "invalid_email_format": "Veuillez entrer un email valide",
            "greater_or_equa": "Veuillez entrer une valeur supérieure ou égale à {0}.",
            "value_must_between": "Please enter a value between {0} and {1}.",
            "large_10x_markup_price": "Edited fare should not exceed 10x estimated fare.",
            "greater_than": "Please enter a value greater than {0}.",
            "less_than": "Please enter a value less than {0}.",
            "less_or_equal": "Please enter a value less than or equal to {0}.",
            "driver_earnings_warning": "Offline payments: Driver earnings must not surpass the collected fare.",
            "limit_amount": "Please enter a value less than or equal to limit amount",
            "Integer_from_range": "Veuillez entrer un nombre entier entre {0} et {1}",
            "integer_greater_than_0": "Please enter a integer number greater than 0. (Less than 100 with percent and decimal separator is 1 character)",
            "Error_message": "Error!",
            "callcenter_newbooking_message": "Demande de réservation par téléphone <span class='bold'>{0}</span>",
            "callcenter_existed_booking_message": "Une réservation active <span class='bold'>#{0}</span> existe avec téléphone <span class='bold'>{1}</span>",
            "callcenter_close_newbooking_form_warning": "Veuillez fermer /terminer votre réservation en cours avant de recevoir une nouvelle demande client",
            "Emergency_SOS_Title": "Emergency SOS",
            "Please_enter_a_future_date": "Please enter a future date",
            "Card_holder_is_required": "Card holder is required",
            "Card_number_is_required": "Card number is required",
            "Expired_date_is_required": "Expired date is required",
            "Credit_code_is_required": "Please enter credit code (last validation)",
            "Expired_date_is_invalid": "Expiration date is invalid. Please enter expiration date in format mm/yy",
            "cvv_is_required": "CVV is required",
            "City_is_required": "City is required",
            "State_is_required": "State is required",
            "Address_is_required": "Address is required",
            "Zipcode_is_required": "Zip code is required",
            "Country_is_required": "Country is required",
            "Over_query_limit": "Google API can't load due to temporary error. Please try again later.",
            "outOfInputQueueOrder": "The order # cannot be greater than the total number of drivers in the queue.",
            "Same_pickup_destination": "Pick-up address must be different from destination address.",
            "Max_length": "Maximum length is {0} characters.",
            "choose_at_least_one": "Please choose at least one option.",
            "choose_at_least_one_intercity_schedule": "Please add schedule or allow customer to request their preferred time.",
            "startDate_greater_than_endDate": "The start date is greater than the end date"
        },
        "trip": {
            "cancel_trip_success": "This trip has been cancelled successfully!",
            "data_incorect": "No driver has been assigned. Select a driver from the dropdown list. <br/> If no drivers is available, select Auto Dispatch to assign later.",
            "INTERNAL": "Internal server error.",
            "NOT_FOUND": "Trip not found or invalid status.",
            "SAME_DRIVER": "The assignee must be different from the current driver.",
            "OVERLAP": "Sorry, this trip overlaps with another one which is accepted by this driver.",
            "CANNOT_ASSIGN": "This driver is invalid (i.e. driver not found, not online, or not logged in)."
        },
        "car_mgmt": {
            "Search_car_color": "Search car color",
            "Searching": "Searching"
        },
        "driver_note": {
            "Search_driver_note": "Recherche de la note du conducteur",
            "Searching": "Searching"
        }
    },
    "errors": {
        "303": "Utilisateur non trouvé",
        "453": "Gateway unsupported",
        "553": "Sorry! Customer has a booking in-progress, please try again later.",
        "1007": "The period between the start date and the end date must be less than 60 days!!",
        "3001": "Numéro de téléphone déjà existant",
        "3002": "Date de naissance invalide",
        "3003": "Date d'expiration du permis de conduire non valide",
        "3004": "Chauffeur non trouvé",
        "3005": "Informations sur le règlement du chauffeur manquantes (adresse de la banque, ville, état, code postal)",
        "3006": "Informations de compte bancaire manquantes (nom du compte, type de compte, numéro de routing, numéro de compte, ssn)",
        "3007": "Vous ne pouvez pas modifier ce chauffeur, qui est en course",
        "3008": "Ce Driver est en train de se connecter. Veuillez lui demander de se déconnecter afin de modifier ses informations.",
        "3009": "Ce Driver a accepté une ou plusieurs réservation(s). Veuillez affecter lales réservation(s) à d'autre(s) Driver(s) avant de désactiver le Driver.",
        "3010": "Échec de la connexion au dispatch.",
        "3011": "Ce chauffeur doit être configuré pour fonctionner avant d'être activé.",
        "3012": "Ce chauffeur doit être configuré pour fonctionner avant d'être activé.",
        "3013": "Seul un élément qui a un statut d'inactif peut être supprimé.",
        "3014": "Les champs obligatoires sont manquants. Veuillez vérifier",
        "3015": "Année de naissance non valide. Veuillez vérifier",
        "3016": "Code postal invalide. Veuillez vérifier",
        "3017": "Etat/Province invalide. Veuillez vérifier",
        "3018": "Nom de compte invalide. Veuillez vérifier",
        "3019": "Numéro de routage invalide. Veuillez vérifier",
        "3020": "La date de naissance est obligatoire. Veuillez vérifier",
        "3021": "Numéro de ssn invalide. Veuillez vérifier",
        "3022": "Le compte bancaire du chauffeur ne peut recevoir de fonds. Vérifiez s'il vous plaît.",
        "3023": "La connexion des Drivers ne peut pas être effectuée. Veuillez réessayer plus tard.",
        "3026": "Le chauffeur n'existe plus.",
        "3027": "Le compte bancaire du conducteur n'est pas configuré. Veuillez vous rendre sur le module Chauffeurs pour configurer son compte bancaire.",
        "3028": "Échec du paiement du (des) chauffeur (s) sélectionné (s). Veuillez réessayer.",
        "3029": "Echec de l'effacement des données de gain de chauffeur. Veuillez réessayer.",
        "3032": "Ce nom d'utilisateur existe déjà dans notre système.Veuillez en entrer un autre.",
        "3033": "Invalid BSB. The number should be 6 digits in the format xxxxxx.",
        "3039": "Group ID is required.",
        "3040": "This phone number is already in use by a  %{brandName} driver (i.e. phone number can't be duplicated). Please enter another one",
        "3041": "Username existed.",
        "3044": "National ID is duplicated.",
        "3045": "Driver already has a vehicle, Please refresh page",
        "3046": "The company of the vehicle and the company of the driver are different.",
        "4001": "Votre plaque d'immatriculation existe déjà sur le système. Veuillez en saisir un autre.",
        "4002": "Invalid expired date",
        "4003": "Vehicle is active (must deactive before deleting)",
        "4004": "Vehicle not found",
        "4005": "Vehicle is inactive (and no need to deactive any more)",
        "4006": "Vehicle is in using (cannot delete or deactive)",
        "4007": "Plate number is required",
        "4009": "The vehicle is being assigned to a booking.",
        "4010": "The vehicle is being assigned to a driver.",
        "5001": "Company existed",
        "5004": "Company not found",
        "6001": "Vehicle model existed",
        "6004": "Vehicle model not found",
        "6005": "Vehicle model is in use",
        "7001": "Your car make name has been existed on system. Please input another one.",
        "7004": "Vehicle make not found",
        "8001": "Vehicle type existed",
        "8004": "Vehicle type not found",
        "9001": "Flat route existed",
        "9004": "Flat route not found",
        "10001": "Zone existed",
        "10004": "Zone not found",
        "11001": "Zone existed",
        "11002": "Zone conflict",
        "11003": "Zone must be a polygon object",
        "11004": "Zone not found",
        "11005": "Zone is in using",
        "12001": "Inbox existed",
        "12002": "Veuillez configurer SMS Twilio.",
        "12004": "Inbox not found",
        "12005": "There is no recipient match your filters. Please check the list and try again.",
        "12006": "No customer exists in the file.",
        "13001": "This promotion code has been existing on the system. Please input another one",
        "13002": "Invalid Date",
        "13003": "Privé",
        "13004": "Promotion code not found",
        "13005": "Only New Customer",
        "13006": "Over Quantity Limit",
        "13007": "Over Budget Limit",
        "13008": "Over Total Uses Limit",
        "13011": "Promoction campaign has been deactivated or deleted",
        "13012": "Date range is invalid",
        "13013": "Invalid promotion code. It seems that the promo is applied to other zones",
        "13014": "The zone you selected has been deactivated.",
        "14004": "Booking not found",
        "15002": "Votre flotte a été désactivé. Veuillez contacter notre administrateur",
        "15004": "Fleet not found",
        "16001": "Your flat rate name has been existed on system. Please input another one",
        "16004": "Fare not found",
        "17001": "Name is existed",
        "17004": "Package rate not found",
        "18001": "Name is existed",
        "18002": "Fare in use",
        "18004": "Fare not found",
        "19001": "Name is existed",
        "19002": "Fare in use",
        "19004": "Fare not found",
        "20001": "This username already exists on our system. Please enter another one.",
        "20002": "Votre compte a été désactivé. Veuillez contacter notre administrateur",
        "20003": "Le nom d'utilisateur ou le mot de passe que vous avez tapé est incorrect. Veuillez réessayer",
        "20004": "Le nom d'utilisateur n'est pas enregistré dans le système.",
        "20005": "This link was expired",
        "20006": "Current password does not match. Please try again.",
        "20007": "User # has been existed",
        "20008": "This email already exists on our system. Please enter another one.",
        "21001": "Votre nom de permission existe déjà sur le système. Veuillez en saisir un autre.",
        "21002": "Impossible de désactiver cette permission, car elle est attribué à un utilisateur ou plus.",
        "21003": "Role active",
        "21004": "Role not found",
        "22001": "Customer is existed",
        "22004": "Customer not found",
        "22005": "Invalid File Format.",
        "24001": "Le numéro de téléphone est invalide",
        "25001": "Ce nom existe déjà sur le système. Veuillez en saisir un autre.",
        "25002": "Plage de temps incorrecte",
        "25004": "Shift non trouvé",
        "26001": "Ce nom existe déjà sur le système. Veuillez en saisir un autre.",
        "26002": "Ce shift est actuellement utilisé en exécution, veuillez lui retirer son assignation avant de l'effacer",
        "26004": "Modèle de shift non trouvé",
        "27001": "Campaign is existed",
        "27002": "Campaign is activated",
        "27004": "Campaign not found",
        "28001": "City is existed",
        "28002": "City is beeing active",
        "28003": "City is in use",
        "28004": "City not found",
        "29002": "Phone number is existed",
        "30001": "Queuing area not found",
        "30002": "Queuing area in using",
        "30003": "Your queuing area name has been existed on system. Please input another one",
        "30004": "Queuing area must not overlap with each others",
        "30005": "Queuing area coordinates are invalid",
        "30006": "Pick-up areas are invalid",
        "31001": "Votre nom de compagnie existe déjà sur le système. Veuillez en saisir un autre.",
        "31002": "Ce nom d'utilisateur existe déjà dans notre système.Veuillez en entrer un autre.",
        "31003": "Corporate not found.",
        "31004": "Corporate's User not found.",
        "31005": "Seules les sociétés inactives peuvent être supprimées.",
        "31006": "Ce téléphone est déjà existant sur le voyageur entreprise. Veuillez en saisir un autre.",
        "31007": "Corporate's Traveler not found.",
        "31008": "Card not found.",
        "31009": "Ce téléphone existe déjà sur le système. Veuillez en saisir un autre",
        "31010": "This corporate has been deactivated. Please contact administrator for more information",
        "31011": "This traveller cannot be activated because it belongs to an inactive corporate account",
        "31013": "A business with this name already exists",
        "31014": "Please assign rate for at least one vehicle type",
        "31015": "This email/phone is already linked to another account.",
        "31016": "This email/phone is linked to another corporate account. Please update the profile or remove the user from the existing account before assigning them to a new one.",
        "31017": "This email/phone is empty. Please enter a valid email/phone.",
        "32001": "Your service name has been existed on system. Please input another one.",
        "32004": "Service not found",
        "33002": "Les intervalles de temps ne doivent pas se chevaucher les uns avec les autres",
        "34001": "Code is existed",
        "34013": "Date range is invalid",
        "36001": "Name is existed",
        "39001": "The document is required",
        "43001": "Penalty Template existed",
        "43002": "Penalty Template not found",
        "44001": "Rate Template existed",
        "44002": "Rate Template not found",
        "44003": "The Rate Template is currently assigned to a corporate. Please unassign it before deleting.",
        "45001": "Surcharge Policy existed",
        "45002": "Surcharge Policy not found",
        "45003": "Surcharge Item not found",
        "46001": "Name is existed",
        "46002": "Item not found",
        "71001": "Dynamic surcharge existed",
        "71002": "This area overlaps with another one. Please draw it again.",
        "71003": "Dynamic surcharge must be a polygon object",
        "71004": "Dynamic surcharge not found",
        "72001": "Dynamic fare existed",
        "72002": "This area overlaps with another one. Please draw it again.",
        "72003": "Dynamic fare must be a polygon object",
        "72004": "Dynamic fare not found",
        "74001": "Zone existed",
        "74002": "This area overlaps with another one. Please draw it again.",
        "74003": "Zone must be a polygon object",
        "74004": "Zone fare not found",
        "74005": "Zone fare not found",
        "80001": "App banner existed.",
        "80002": "App banner not found.",
        "80003": "Just only item in the inactive status can be deleted.",
        "81001": "File upload is empty.",
        "81002": "File upload is limit size.",
        "82002": "Une erreur s'est produite lors de la configuration de flightaware. Veuillez réessayer plus tard.",
        "90001": "Intercity zone existed",
        "90002": "This area overlaps with another one. Please draw it again.",
        "90003": "Intercity zone must be a polygon object",
        "90004": "Intercity zone not found",
        "90005": "Intercity zone is in use",
        "91001": "Intercity rate is existed",
        "91002": "Intercity rate is in use",
        "91003": "Intercity rate not found",
        "92001": "Route name is existed",
        "92002": "Route not found",
        "92003": "This route already exists at other route, please check and try again",
        "92004": "There is an active trip on this route.",
        "93001": "Driver note has been existed on system. Please enter another one.",
        "93002": "Driver note are inactive can be deleted.",
        "93003": "Driver note is in use.",
        "93004": "Driver note not found.",
        "94001": "Your car color name has been existed on system. Please input another one.",
        "94002": "Car color not found",
        "400000": "Numéro de téléphone non valide!",
        "400002": "A validation error has occurred.",
        "400003": "Permission denied",
        "400005": "File content is invalid",
        "500000": "Internal system error.",
        "500001": "Veuillez configurer SMS Twilio.",
        "500002": "Request timeout. Please try again later",
        "600001": "Location not found",
        "600002": "This Longitude & Latitude coordinates have existed on the system. Please add another one",
        "610001": "Category existed",
        "610002": "Category not found",
        "610003": "Category existed in party location",
        "700001": "Type is inuse",
        "700002": "mDispatcher not found",
        "700003": "Your mDispatcher type name has been existed on system. Please input another one.",
        "800000": "This store has been assigned to another merchant account. Please select another one.",
        "800001": "Merchant's bank account can't receive funds.",
        "800002": "Merchant's bank account can't receive funds.",
        "31016_2": "Message: This email/phone is already linked to another account. Please contact your account manager for assistance.",
        "undefined": "Une erreur est survenue avec notre serveur, veuillez réessayer.",
        "completeBooking": {
            "113": "We are unable to authenticate your payment method. Please choose a different payment method and try again.",
            "450": "The balance is insufficient to pay. Please select another method",
            "451": "The balance is insufficient to pay. Please select another method",
            "521": "The wallet does not have sufficient funds."
        },
        "upload_file_fail": "Invalid file, please check and try again.",
        "upload_photo_fail": "Invalid file, please check and try again.",
        "missing_some_field": "Invalid field, please check and try again.",
        "validAddress": "Please select a valid address."
    },
    "statusDisplay": {
        "supplierAccepted": "Supplier accepted",
        "delivery_supplierAccepted": "Supplier accepted",
        "completedWithoutService": "Completed without driver",
        "confirmed": "Réservation confirmée",
        "pending": "En attente",
        "queue": "En cours de dispatch",
        "offered": "En cours de dispatch",
        "booked": "Driver en route",
        "engaged": "P.O.B",
        "droppedOff": "Déposé",
        "pre": "En attente",
        "recurrent": "Récurrent",
        "vacant": "",
        "vacantTime": "",
        "arrived": "Arrivé et en attente",
        "completed": "Terminé",
        "canceled": "Annulé",
        "noShow": "Non présentation",
        "action": "En attente d'exécution",
        "incident": "Incident",
        "pickupTimeExceeded": "Temps libre",
        "canceledByCC": "Annulé par CC",
        "canceledByPassenger": "Annulé par passenger",
        "canceledByDriver": "Canceled by driver",
        "canceledByPartner": "Annulé par partner",
        "canceledBymDispatcher": "Annulé par mDispatcher",
        "canceledByCorporateAdmin": "Annulé par l'Administrateur Entreprise",
        "canceledByAPI": "Annulé par API",
        "canceledByWebBooking": "Annulé par reservation web",
        "canceledByTimeout": "Annulé par timeout ",
        "accepted": "Accepted",
        "rejected": "Rejeter",
        "canceledByCorpAd": "Annulé par l'Administrateur Entreprise",
        "started": "Started",
        "intransit": "In transit",
        "empty": "Empty",
        "notLive": "Partial - not live",
        "live": "Partial - live",
        "full": "Complète ",
        "delivering": "Delivering",
        "delivery_booked": "Chauffeur sur le chemin de l'expéditeur",
        "delivery_arrived": "Arrivé et attends l'expéditeur",
        "shopForYou_booked": "Chauffeur en route pour récupérer l'argent",
        "shopForYou_arrived": "Arrived & waiting to collect cash",
        "collecting": "Chauffeur en route vers le vendeur",
        "confirmed_delivery": "Confirmé",
        "food_booked": "Chauffeur en route vers le vendeur",
        "food_collecting": "Arrivé et en attente de récupérer l'article",
        "mart_collecting": "Arrivé et en attente de récupérer l'article",
        "otwMerchant": "Chauffeur en route vers le vendeur",
        "arrivedAndWaitingToCollectItem": "Arrived & waiting to collect item",
        "canceledByAirlineAdmin": "Canceled by Airline Admin",
        "partialCompleted": "Partial Completed",
        "failed": "Failed",
        "allocated": "Allocated"
    },
    "tripTypes": {
        "scheduled": "Scheduled trips",
        "requested": "Request trips",
        "preferred": "Preferred"
    },
    "login": {
        "Username": "Nom d'utilisateur",
        "Password": "Mot de passe",
        "Remember_me": "Se rappeler de moi",
        "Forgot_your_password": "Mot de passe oublié",
        "Login": "S'identifier"
    },
    "invoice": {
        "createBtn": "Create invoice",
        "searchPlInvoice": "Search client, contact, invoice number",
        "searchPl": "Search bookId, customer name",
        "createInvoice": "Create Invoice",
        "invoicePeriod": "Invoice Period",
        "invoicePeriodNote": "You can select month to month date ranges.",
        "viewEdit": "View/Edit",
        "billingContact": "Billing Contact",
        "memo": "Memo",
        "memoMessage": "Leave message to customer",
        "dueDate": "Due Date",
        "updateInvoice": "Update Invoice",
        "chargeCustomer": "Charge Customer",
        "invoiceNumber": "Invoice Number",
        "noItem": "No. Items",
        "issuedDate": "Issued Date",
        "refId": "Ref #",
        "pickupTime": "Pick Up Time",
        "pickup": "Pick Up Location",
        "destination": "Drop Off Location",
        "customerName": "Customer Name",
        "totalAmount": "Amount Due",
        "amountDue": "Amount Due",
        "sendInvoice": "Send Invoice",
        "copyInvoice": "Copy Invoice",
        "downloadPDF": "Download as PDF",
        "paymentLink": "Payment link",
        "deleteInvoice": "Delete Invoice",
        "onlySave": "Save Invoice",
        "saveSend": "Save & Send Invoice",
        "sendEmail": "Send Email",
        "addRecipients": "Add Recipients",
        "editInvoice": "Edit Invoice",
        "check": "Check",
        "wire": "Wire",
        "digitalWallet": "Digital Wallet",
        "cash": "Cash",
        "completedTime": "Completed Time",
        "Status": "Status",
        "bookingStatus": "Booking Status",
        "chargeBtn": "Charge {0}",
        "pastDue": "Past due",
        "payCompleted": "This invoice has been paid successfully!",
        "Delete": "Delete invoice?",
        "Delete_content": "This action will permanently remove this invoice. If necessary, you can create a new one for these order(s).",
        "viewInvoice": "View Invoice",
        "pending": "Pending",
        "paid": "Paid",
        "CopyLink": "Copy Link",
        "partial": "Partial",
        "corporatePrepaid": "Corporate Prepaid"
    },
    "cue": {
        "bookingServiceType": "Service type",
        "pointToPoint": "Point To Point",
        "fromAirport": "From Airport",
        "toAirport": "To Airport",
        "hourly": "Hourly",
        "roundTrip": "Round Trip",
        "jobVisibility": "Job Visibility",
        "jobPubic": "Public",
        "jobPrivate": "Private",
        "jobSetPubic": "Set Public",
        "jobSetPrivate": "Set Private",
        "supplierAccepted": "Supplier accepted",
        "creationTime": "Creation time",
        "Pax": "Pax #",
        "Luggage": "Luggage",
        "allocated": "Allocated",
        "completedWithoutService": "Completed without driver",
        "shuttle": "Shuttle",
        "farmIn": "Farm In",
        "chargeCode": "Charge Code",
        "clientCaseMatter": "Trip Description",
        "operatorNote": "Notes internes",
        "farmOut": "Farm Out",
        "roaming": "Roaming",
        "FlightUpdate": "Flight Update",
        "networkType": "Network type",
        "InHouse": "In-house",
        "carHailing": "Car Hailing",
        "uploadBookings": "Upload Bookings",
        "moreActions": "More Actions",
        "rideSharing": "Ride-sharing",
        "Rearrange": "Réarranger",
        "GroupId": "Group ID #",
        "Active": "Actif",
        "Finished": "Terminé",
        "Home_affiliate": "Ma flotte/Affilié",
        "Booking_type": "Type de réservation",
        "From": "De",
        "balanceWallet": "{0}: {1} remaining",
        "partialWallet": "Partial payment with Wallet",
        "To": "À",
        "Status": "Statut",
        "Migration_Status": "Migration Status",
        "Migrated": "migrated",
        "Select_Status": "Sectionner un statut",
        "Operator": "Opérateur",
        "Car_types": "Type de véhicule",
        "Service_class": "Service class",
        "Booking_from": "Réservé de",
        "All": "Tout",
        "Pending": "En attente",
        "Dispatching": "En cours de dispatch",
        "Waiting_for_process": "En attente d'exécution",
        "Confirmed_reservation": "Réservation confirmée",
        "Driver_on_the_way": "Driver en route",
        "Arrived_and_waiting": "Arrivé et en attente",
        "Passenger_on_board": "Passager à bord",
        "Dropped_off": "Déposé",
        "combobox_select": "Sélectionnez...",
        "Search_here": "Rechercher ici",
        "Bookingid": "Réservation #",
        "Pickup_Drop_Off": "Départ/ Déposé",
        "Pickup_Drop_Off_Crew": "Pickup/Drop Off time",
        "Pickup_Drop_Off_Local_Time": "Temps de l'opérateur",
        "Pickup_Location_Destination": "Lieu de départ/ Destination",
        "Passenger": "Passager",
        "Intercity_Route": "Itinéraire interurbain",
        "Airport_Pickup": "Accueil à l'aéroport",
        "Airport_Pickup_Crew": "Airport",
        "Flight_Status": "Statut",
        "Payment": "Paiement",
        "Vehicle": "Véhicule",
        "Car_type": "Type de véhicule",
        "Driver": "Chauffeurs",
        "Estimated_Fare": "Tarif estimées",
        "Notes": "Remarques",
        "Note": "Note",
        "Type": "Type",
        "Home": "Ma flotte",
        "Affiliate": "Affilié",
        "now": "Maintenant",
        "reservation": "Réservation",
        "recurring": "Recurring (Chauffeur)",
        "No_show": "Non présentation",
        "Canceled_by_CC": "Annulé par CC",
        "Canceled_by_passenger": "Annulé par le passager",
        "Canceled_by_partner": "Annulé par le partenaire",
        "Canceled_by_mDispatcher": "Annulé par le mDispatcher",
        "Canceled_by_Corporate_Admin": "Annulé par l'Administrateur Entreprise",
        "Canceled_by_API": "Annulé par API",
        "canceled_By_Web_Booking": "Annulé par reservation web",
        "canceled_By_Time_Out": "Annulé par timeout ",
        "Canceled": "Annulé",
        "Completed": "Terminé",
        "Incident": "Incident",
        "Payment_Method": "Mode de paiement",
        "No_Phone": "No Phone",
        "VIP": "VIP",
        "Non_VIP": "Non-VIP",
        "Passenger_type": "Passager type",
        "Error_load_booking": "Cannot load the booking, Please try again or refresh page!",
        "Edit_total_success": "Edit total successful!",
        "Edit_total_fail": "The total fare must be equal or greater than {0}",
        "Time_out": "Temps dépassé",
        "Ride_Service": "Service de transport",
        "Regulation": "Règlement",
        "Ride_Sharing": "Partage de trajet",
        "Unmatching": "Unmatching",
        "Matching": "Matching",
        "all": "Tout",
        "matching": "Matching",
        "unmatching": "Unmatching",
        "regulation": "Règlement",
        "Support_service": "Service d'assistance",
        "SOS": "SOS",
        "Non_SOS": "Non-SOS",
        "Corporate": "Entreprise",
        "Individual": "Individuel",
        "On_curb": "Sur le trottoir",
        "Meet_Greet": "Accueil personnalisé",
        "Close": "Fermer",
        "auto_refrest_cue": "Auto-refresh ({0} seconds)",
        "Ride_type": "Type de véhicule",
        "Edit_total_note": "Note: subtotal, tech fee, booking fee, tax and tip will be updated when changing the total amount",
        "Booking": "Booking",
        "Edit_fare": "Edit fare",
        "Adjust_price": "Adjust price",
        "Total": "Total",
        "Reason": "Raison",
        "Please_enter_reason": "Please enter reason.",
        "Save": "Sauvegarder",
        "Cancel": "Annuler",
        "dmc": "Booking board (DMC)",
        "corp": "Booking board (CTM)",
        "Zone": "Zone de filtre",
        "mDispatcher": "Partner",
        "streetSharing": "Street-sharing",
        "Driver_No_Job_found": "Waiting for assignments...",
        "expandFilter": "Afficher les filtres",
        "collapseFilter": "Cacher les filtres",
        "clearSelectedItems": "Clear Selected Items",
        "Accepted": "Accepted",
        "Yes": "Oui",
        "No": "Non",
        "Order_accepted": "Commande acceptée",
        "Confirmed": "Confirmé",
        "spotTime": "Spot Time",
        "ETA": "ETA",
        "spareTime": "Spare Time",
        "expectedTime": "Expected pickup time",
        "Driver_on_the_way_to_sender": "Chauffeur sur le chemin de l'expéditeur",
        "Driver_on_the_way_to_recipient": "Driver on the way to recipient",
        "Driver_on_the_way_to_collect_cash": "Chauffeur en route pour récupérer l'argent",
        "Driver_on_the_way_to_collect_goods": "Le conducteur en route pour collecter les colis",
        "Arrived_waiting_for_sender": "Arrivé et attends l'expéditeur",
        "Arrived_waiting_for_recipient": "Arrived & waiting for recipient",
        "Arrived_waiting_to_collect_cash": "Arrivé et en attente de récupérer de l'argent",
        "Arrived_waiting_to_deliver_goods": "Arrived & waiting to deliver goods",
        "Delivered": "En livraison",
        "delivery": "Livraison",
        "Service": "Un service ",
        "intercity": "Intercity",
        "Transport": "Chauffeur",
        "About_Sender": "About Sender",
        "About_Merchant": "About Merchant",
        "About_Recipient": "About Recipient",
        "photos": "Photos",
        "listOfItems": "List of item",
        "cashOnPickUp": "COP",
        "Package_information": "Package information",
        "otwMerchant": "Chauffeur en route vers le vendeur",
        "arrivedAndWaitingToCollectItem": "Arrivé et en attente de récupérer l'article",
        "Save_Filter": "Enregistrer filtre",
        "add_favorite_filter": "Ajouter un filtre",
        "favorite_filter_name": "Nom",
        "list_favorite_filter": "Gérer les filtres",
        "favorite_filter_actions": "Action",
        "clear_all_filters": "Clear all filters",
        "Delete_Filter": "Delete Filter",
        "CONFIRM_DELETE_FILTER_TEMPLATE": "Are you sure you want to delete this filter?",
        "batchDelivery": "Batch Delivery",
        "editFare": {
            "creditTransactionFee": "Frais de carte de crédit",
            "preferredAdjustment": "Preferred adjustment",
            "editFareFee": "Edit fare & fees",
            "markupPrice": "Markup price",
            "quotedPrice": "Quoted price",
            "basicFare": "Basic fare",
            "preferredEarning": "Preferred earning",
            "difference": "Différence",
            "rushHourFee": "Taxes ",
            "serviceFee": "Add'l service",
            "otherFees": "Other fees",
            "subTotal": "Sub total",
            "airportFee": "Airport fee",
            "meetDriverFee": "Meet driver",
            "deliveryFee": "Delivery fee",
            "tollFee": "Toll fee",
            "parkingFee": "Parking fee",
            "gasFee": "Gas fee",
            "techFee": "Frais techniques",
            "tip": "Tip",
            "bookingFee": "Booking fee",
            "tax": "Tax",
            "promoAmount": "Promo amt",
            "etaFare": "Total",
            "fleetService": "Fleet service",
            "Note_serviceFee": "(to edit additional service, visit outer list)",
            "startingFee": "Starting",
            "moveFeeInJourney": "Moving",
            "waitFeeInJourney": "Waiting (in-journey)",
            "total": "Total",
            "totalPayout": "Payout",
            "updatedTotal": "Updated Total",
            "updatedPayout": "Updated Payout",
            "profit": "Profit",
            "extraWaitFee": "Waiting fee Initial",
            "heavyTraffic": "Heavy traffic"
        },
        "partialCompleted": "Partial Completed",
        "failed": "Failed",
        "Recurring": "Recurring #",
        "DragAndDrop": "Drag and drop to reorder columns.",
        "bookingCom": "Booking.com",
        "syncingCompleted": "Syncing completed",
        "groupManifest": "Group Manifest",
        "assignSuppliers": "Assign Suppliers",
        "viewActions": "View Actions",
        "holidaytaxis": "HolidayTaxis",
        "karhoo": "Karhoo",
        "pickupTime": "Pick-up time",
        "AssignSupplier": "Assign Supplier",
        "sharedTransfer": "Shared Transfer (%{id})",
        "batchDeliveryGroup": "Batch Delivery (%{id})",
        "foundersFund": "Founders Fund",
        "zeroCommission": "Zero Commission",
        "unassignDriver": "Unassign Driver",
        "notUnassignDriver": "Cannot unassign driver, Please try again",
        "notAssignDriver": "Cannot assign driver, Please try again",
        "selectDriver": "Select Driver",
        "unassignVehicle": "Unassign Vehicle",
        "unassignAll": "Unassign All",
        "notUnassignAll": "Cannot unassign all, Please try again",
        "unassignAllSuccess": "The vehicle and driver have been successfully detached.",
        "unassign_all_partial": "Partial Success: Batch unassignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
        "notUnassignVehicle": "Cannot unassign vehicle, Please try again",
        "notAssignVehicle": "Cannot assign vehicle, Please try again",
        "selectVehicle": "Select Vehicle",
        "confirmUnassign": "Are you sure you want to unassign?"
    },
    "trip": {
        "Driver_vehicle": "Driver's vehicle",
        "tripId": "Trip #",
        "tripTypes": "Trip types",
        "Start_Time": "Start time",
        "Seats_Status": "Seats status",
        "No_Of_Booking": "No. of bookings",
        "Vehicle": "Driver's vehicle",
        "Estimated_Fare": "Fare",
        "capacityStatus": "Seat status",
        "Trip_Types": "Trip types",
        "scheduled": "Scheduled trips",
        "requested": "Request trips",
        "preferred": "Preferred",
        "All": "Tout",
        "Confirmed": "Confirmé",
        "Started": "Started",
        "In_Transit": "In Transit",
        "Empty": "Empty",
        "Partial_not_live": "Partial - not live",
        "Partial_live": "Partial - live",
        "Full": "Complète ",
        "Error_load_trip": "Cannot load the trip, Please try again or refresh page!"
    },
    "tripDetails": {
        "Trip_ID": "Intercity trip",
        "DRIVER_JOB": "Infos sur le conducteur",
        "BOOKINGS": "Bookings",
        "updateTrip": "Update Trip",
        "cancelTrip": "Cancel Trip",
        "note_cancel": "Please assign all bookings to another driver before cancel this trip.",
        "TRIP_INFORMATION": "Trip Info",
        "route": "Route",
        "startTime": "Start Time",
        "distance": "Distance",
        "duration": "Duration",
        "fare": "Fare",
        "cartype": "Intercity cartype",
        "seatStatus": "Seat status",
        "started": "Started",
        "intransit": "In transit",
        "empty": "Empty",
        "notLive": "Partial - not live",
        "live": "Partial - live",
        "full": "Complète ",
        "noOfBooking": "No. of booking",
        "luggage": "Luggage",
        "nameDriver": "Nom",
        "vehicleType": "Vehicle",
        "assignDriver": "Assign driver",
        "pickUpAddress": "Pick up",
        "destinationAddress": "Drop off",
        "customerName": "Nom",
        "customerPhone": "Phone",
        "customerNumber": "Seats",
        "customerLuggageNumber": "Luggage",
        "additionalService": "Services supplémentaires",
        "paymentBooking": "Payment",
        "note": "Note",
        "CONFIRM_UPDATE": "confirm update",
        "CONFIRM_UPDATE_MESSAGE": "Are you sure you want to update this trip?",
        "Yes": "Yes",
        "No": "No",
        "Update_info": "Yes, Update info"
    },
    "newbooking": {
        "Search_Vehicle": "Search for vehicle",
        "rideSharing_multi_point": "Only 1 pick-up and 1 drop off point are allowed for ride sharing booking.",
        "Pick_up_time": "Pick up time",
        "Drop_off_time": "Drop off time",
        "All_Suppliers": "All suppliers",
        "Recipient": "Destinataire",
        "All": "Tout",
        "New_Booking": "Nouvelle réservation",
        "GoogleMapRoute": "Google Map",
        "BOOKING_INFORMATION": "Informations réservation",
        "TIP_PROMO": "Pourboire - Promo",
        "Pick_up": "Départ",
        "instructions": "Instructions",
        "instructions_placeholder": "List your instructions here...",
        "Enter_a_location": "Entrez un lieu",
        "Enter_a_location_delivery": "Entrer votre adresse d'expédition",
        "Enter_3rd_party_location": "Emplacement de la 3ème partie",
        "Enter_a_sender": "Saisissez l'information de l'expéditeur",
        "Enter_a_recipient": "Saisissez l'information du destinataire",
        "Vehicle_Selection": "Sélection du service",
        "Destination": "Destination",
        "Pickup_time": "Heure de départ",
        "Note": "Remarques",
        "note_for_driver": "Note au chauffeur",
        "affiliate_note": "Note for affiliate",
        "affiliate_note_placeholder": "Enter affiliate notes for operators",
        "operator_note": "Opérateur note",
        "TRAVELER_INFORMATION": "Information du voyageur",
        "Traveler_type": "Type de voyageur",
        "Individual": "Individuel",
        "Corporation": "Entreprise",
        "Phone_number": "Numéros de téléphone",
        "First_name": "Prénom",
        "Last_name": "Nom",
        "Email_address": "Adresse e-mail",
        "Corp_id": "Numéro d'entreprise",
        "Manager_name": "Nom du gestionnaire",
        "Manager_email": "Courriel du directeur",
        "Cost_centre": "Centre de coûts",
        "Department": "Département",
        "Corp_division": "Division d'entreprise",
        "Tip": "Pourboire",
        "Promo_code": "Code promo",
        "Apply": "Appliquer",
        "Promo": "Promo:",
        "DISPATCH": "Dispatch",
        "Auto_dispatch": "Dispatch automatique",
        "Assign_driver": "Attribuer Driver",
        "FarmOut": "Farm-out",
        "Driver": "Chauffeurs",
        "Driver_Supplier": "Supplier/Driver",
        "PAYMENT_METHOD": "Mode de paiement",
        "OPERATOR_NOTE": "Notes internes",
        "TRIP_ESTIMATE": "Estimation du trajet",
        "Choose_a_stored_card": "Choisissez une carte enregistrée",
        "Choose_a_payment_method": "Choisissez un moyen de paiement",
        "Use_new_card": "Utiliser une nouvelle carte",
        "Card_holder": "Nom du titulaire de la carte",
        "Card_number": "Numéro de carte",
        "Expired_date": "Date d'expiration(mm/yy)",
        "ccv": "CVV",
        "Distance": "Distance",
        "Duration": "Durée",
        "Fare": "Tarif",
        "Route": "Routes",
        "Create": "Créer",
        "Cancel": "Annuler",
        "Meet_driver": "Rencontrez Driver",
        "Airline": "Compagnie aérienne",
        "Flight": "Vol #",
        "FLY_INFO": "Informations du vol",
        "Ride_Sharing": "Partage de course",
        "Round_trip": "Aller-retour",
        "Car_type": "Type de véhicule",
        "Address": "Adresse",
        "City": "Ville",
        "State": "Etat",
        "Zip_code": "Code postal",
        "Remove": "Retirer",
        "Client_case_matter": "Description du voyage",
        "Charge_code": "Code de voyage",
        "CANCEL_BOOKING": "Feremer la confirmation de réservation",
        "CANCEL_BOOKING_CONFIRM": "Voulez vous quitter? Les données de réservation seront effacées",
        "Search_here": "Rechercher ici",
        "No_support_location": "Désolé,Il n'y a pas de service de réservation dans cette zone",
        "Closest_driver_message": "Votre chauffeur le plus proche est à {0} minutes",
        "No_cars_available": "Aucun véhicule disponible dans le rayon de dispatch.",
        "processed_by_affiliate": "Vos requêtes seront traitées par nos partenaires affiliés",
        "FlightExample": "Vol#, p. ex. AA1097",
        "Yes": "Oui",
        "No": "Non",
        "dispatch_3rd": "Dispatcher via une 3ème partie",
        "Company": "Entreprise",
        "Card_required": "Veuillez sélectionner ou ajouter une carte!",
        "Tip_value": "N/A",
        "Calendar": "Calendar",
        "Extra_Destination": "Additional Destination",
        "Driver_credit_limited_balance_warning": "This driver's balance is less than or equal limited amount",
        "Meet_Greet_option": "Accueil personnalisé",
        "On_Curb_option": "Sur le trottoir",
        "Extra_Services": "Additional Services",
        "Addl_Services": "Add'l Services",
        "Save": "Sauvegarder",
        "Regular": "Regular",
        "Please_fill_in_all_the_required_fields": "Please fill in all the required fields.",
        "Route_names_not_dup": "Route names must not be duplicated.",
        "Please_select_weekdays": "Please select the repeat on days you want this booking to occur",
        "Flat_rate": "Flat rate",
        "All_company": "Toute entreprise",
        "Best_price": "Best price",
        "PromoTitle": "Promo",
        "Show_more": "Voir plus",
        "Warning_pending_payment": "This customer has incomplete payment from past bookings: %{fare}",
        "Warning_outstanding_balance": "Outstanding balance will be applied at the end of the trip.",
        "Not_available": "Non disponible",
        "Warning_assigned_rate": "Veuillez configurer et attribuer un tarif au type de voiture sélectionné pour continuer.",
        "PaxLuggageNumber": "Passenger/luggage number",
        "paxNumber": "Passenger",
        "luggageNumber": "Luggage",
        "Date": "Date",
        "Single": "Single",
        "Recurring": "Recurring",
        "Date_range": "Date Range",
        "WeekDays": "Repeat on days",
        "Create_Route": "Create Route",
        "Pickup_Point": "Prise en charge",
        "Drag_To_Render": "Drag and drop to reorder",
        "DropOff_Point": "Drop off points",
        "Sender": "Expéditeur",
        "Recipients": "Recipients",
        "Sender_Infomation": "Information de l'expéditeur",
        "Recipient_Infomation": "Information du destinataire",
        "Recipient_Name": "Nom du destinataire*",
        "Recipient_Phone": "Numéro de téléphone*",
        "Room_floor_block": "Code, étage escalier (facultatif)",
        "Details": "Détails",
        "Package_Content": "Contenu du colis*",
        "Order_ID": "Order # (eg. shopify order #)",
        "Instructions_for_driver": "Des instructions pour le livreur",
        "Cost_of_Goods": "Montant à collecter chez le client",
        "Enter_amount": "Entrer le montant",
        "Additional_Information": "Additional Information",
        "Insurance": "Insurance",
        "Special_Care": "Special Care",
        "Delivery_Fee": "Delivery Fee",
        "Item_fees": "Item fees",
        "Tech_Fee": "Frais techniques",
        "Tax": "Tax",
        "driverNotes": "Note au chauffeur",
        "rejectModal": "Reject booking",
        "rejectModalBody": "Are you sure you want to reject this booking?",
        "rejectButton": "Reject booking",
        "acceptModal": "Accept booking",
        "acceptModalBody": "Are you sure you want to Accept this booking?",
        "ConfirmedTo": "Confirmed to ({0})",
        "proceedAction": "Are you sure you want to proceed with this action?",
        "acceptButton": "Accept booking",
        "denyModal": "Deny booking",
        "denyModalBody": "Are you sure you want to deny this booking?",
        "denyButton": "Deny booking",
        "Accept_booking_success": "This booking has been accepted successfully!",
        "Deny_booking_success": "This booking has been denied successfully!",
        "adjustFare": "Adjust fare",
        "totalFare": "Total Fare",
        "Profit": "Profit",
        "DriverEarning": "Driver Earning",
        "Payout": "Payout",
        "totalPayout": "Total payout",
        "DriverPayOut": "Driver Payout",
        "confirmReject": "Confirm Rejection",
        "confirmCancelMSG": "Are you sure you want to reject this booking? You may incur a penalty from your provider.",
        "reasonCancel3rd": "Reason for rejection",
        "welcome_psg": "Welcome passenger",
        "AmountDue": "Amount Due",
        "Fleet_Company": "Fleet",
        "Search_driver_hydra": "We will try to find you a driver soon.",
        "ExternalID": "External ID",
        "viewRate": "View Rate",
        "PointToPoint": "Point to point",
        "Offer_driver": "Offer Driver",
        "Assign_driver_vehicle": "Assign Driver / Vehicle",
        "notify_via_email": "Notify Customer via Email",
        "fleetFare": "Fleet Fare",
        "onlySave": "Save",
        "saveCharge": "Save & Charge",
        "saveRefund": "Save & Refund",
        "lateCancelConfirm": "Are you sure you want to complete this booking as",
        "canceledDriverConfirm": "Are you sure you want to cancel this booking for the driver? A penalty fee will be applied.",
        "canceledDriverNoChargeConfirm": "Are you sure you want to cancel this booking for the driver",
        "NoShowConfirm": "No show policy will be applied.",
        "completeConfirm": "Are you sure you want to Complete without driver?",
        "completeChargeConfirm": "Full charge will be applied to customer. Driver will not receive any commission from this booking.",
        "PricingPlan": "Pricing Plan",
        "editFerviceFees": "to edit service fees, visit outer list",
        "ungroupAll": "Ungroup All",
        "satisfaction": "satisfaction",
        "Reassign": "Reassign",
        "addNote": "Add note",
        "noteMagicSend": "Select the magic wand or type [ to instantly access booking keywords suggestions.",
        "items": "Items",
        "PaidAmount": "Paid Amount",
        "ExternalPartner": "External Partner",
        "ExternalPartnerNote": " Independent partners who manage their own drivers and vehicles. Payouts are handled directly with the supplier.",
        "InHousePartner": "In-house Partner",
        "InHousePartnerNote": " Directly managed by your fleet. Payouts are made directly to the drivers.",
        "Role": "Role",
        "Client": "Client",
        "ApplyCancellation": "Apply cancellation policy",
        "unassigned": "unassigned",
        "bookingSelected": "booking selected",
        "Update": "Update",
        "emailInvoice": "Email Invoice",
        "Receipt": "Receipt",
        "addItem": "Add Item"
    },
    "bookingDetailLogs": {
        "recipient": "Recipient",
        "updatedInfo": "Updated info",
        "updatedField": "Updated field",
        "valueBefore": "Value before",
        "valueAfter": "Value after",
        "location": "Location",
        "etaFare": "Fare",
        "dispatchType": "Dispatch",
        "drvInfo": "Driver",
        "vehicleTypeRequest": "Vehicle Request",
        "clientCaseMatter": "Trip description",
        "chargeCode": "Trip code",
        "driverEarningType": "Driver Earning Type",
        "editedDriverEarning": "Driver earning",
        "reasonEditFare": "Reason",
        "bookingReference": "External ID",
        "tip": "Tip",
        "type": "Service type",
        "promo": "Promo",
        "dispatch3rd": "Dispatch 3rd",
        "rideSharing": "Ride-Sharing",
        "notes": "Driver notes",
        "operatorNote": "Internal notes",
        "welcomeMessage": "Welcome Message",
        "flightNumber": "Flight Number",
        "fullName": "Full Name",
        "email": "Email",
        "cardMask": "Card mask",
        "paymentType": "Payment method",
        "recipients": "Recipients",
        "sender": "Sender",
        "pickupName": "Pickup Name",
        "pickupPhone": "Pickup Phone",
        "pickupEmail": "Pickup Email",
        "supplierEarningType": "Payout type",
        "editedSupplierEarning": "Payout value",
        "route": "Route name",
        "markupDifference": "Markup Difference",
        "selectedDates": "Selected Dates",
        "services": "Fleet services",
        "destinationAddress": "Destination location",
        "pickupAddress": "Pick-up location",
        "tripType": "Trip Type",
        "pickUpTime": "Pickup time",
        "paxNumber": "Passenger",
        "luggageNumber": "Luggage",
        "vehicle": "Vehicle",
        "minutesToArrive": "Expected driver arrival",
        "affiliateNote": "Affiliate Note",
        "packageRateName": "Package name",
        "supplierCompanies": "Company",
        "puPoints": "Pick-up location",
        "doPoints": "Destination location",
        "promoValue": "Promo value",
        "packageInfo": "Package info",
        "orderId": "Order Id",
        "cashOnDelivery": "Cash on delivery",
        "externalId": "External Id",
        "recurring": "Recurring",
        "batchDelivery": "Batch Delivery",
        "payout": "Payout",
        "locationDetails": "Location Details",
        "name": "Name",
        "phone": "Phone",
        "address": "Address",
        "basicFare": "Basic fare",
        "airportFee": "Airport fee",
        "bookingFee": "Booking fee",
        "creditTransactionFee": "Credit card fees",
        "fleetService": "Fleet service",
        "fleetServices": "Fleet services",
        "gasFee": "Gas fee",
        "meetDriverFee": "Meet driver fee",
        "moveFeeInJourney": "Moving fee",
        "otherFees": "Other fee",
        "parkingFee": "Parking fee",
        "promoAmount": "Promo amt",
        "rushHourFee": "Rush hour fee",
        "serviceFee": "Service fee",
        "startingFee": "Starting fee",
        "subTotal": "Sub total",
        "tax": "Tax",
        "techFee": "Tech fee",
        "tollFee": "Toll fee",
        "waitFeeInJourney": "Waiting (in-journey) fee",
        "psgPhone": "Passenger phone"
    },
    "bookingdetail": {
        "settings": "Settings",
        "updateSuccessJobVisibilitySingle": "Update visibility successfully",
        "updateSuccessJobVisibilityMulti": "%{number} bookings has been updated successfully",
        "failApi": "Something went wrong!",
        "settingsTooltip": "Driver already assigned or trip is currently in progress.",
        "shareJobPublicly": "Share Job Publicly",
        "groupOder": "Group order",
        "ExistingGroup": "Existing Group",
        "NewGroup": "New Group",
        "Search_trip": "Search trip #",
        "All": "Tout",
        "creditTransactionFee": "Frais de carte de crédit",
        "partial_payment": "Partial Payment",
        "Write_off_debt": "Write off debt",
        "Booking_detail": "Détails réservations",
        "Order_detail": "Order",
        "Completed_Booking": "Completed Booking",
        "Late_Cancel": "Late cancel",
        "Cancel_Driver": "Cancel by driver",
        "No_Show": "No show",
        "Without_driver": "Complete without driver",
        "Complete_driver": "Complete with driver",
        "BOOKING_INFORMATION": "Informations réservation",
        "TIP_PROMO": "Pourboire - Promo",
        "Rearrange_Column": "Rearrange Column",
        "Pick_up": "Départ*",
        "Enter_a_location": "Entrez un lieu",
        "Destination": "Destination",
        "Pickup_time": "Heure de départ",
        "Notes": "Remarques",
        "TRAVELER_INFORMATION": "Information du voyageur",
        "Traveler_type": "Type de voyageur",
        "Individual": "Individuel",
        "Corporation": "Entreprise",
        "Phone_number": "Numéros de téléphone",
        "First_name": "Prénom",
        "Last_name": "Nom",
        "Email_address": "Adresse e-mail",
        "Corp_id": "Numéro d'entreprise",
        "Manager_name": "Nom du gestionnaire",
        "Manager_email": "Courriel du directeur",
        "Cost_centre": "Centre de coûts",
        "Department": "Département",
        "Corp_division": "Division d'entreprise",
        "Tip": "Pourboire",
        "Promo_code": "Code promo",
        "Apply": "Appliquer",
        "Promo": "Promo",
        "DISPATCH": "Dispatch",
        "Auto_dispatch": "Dispatch automatique",
        "Assign_driver": "Attribuer Driver",
        "Driver": "Chauffeurs",
        "PAYMENT_METHOD": "Mode de paiement",
        "Payment_methods": "Méthodes de Paiement",
        "pending_payment": "Pending Payment",
        "TRIP_ESTIMATE": "Mode de paiement",
        "Choose_a_stored_card": "Choisissez une carte enregistrée",
        "Choose_a_payment_method": "Choisissez un moyen de paiement",
        "OPERATOR_NOTE": "Notes internes",
        "Use_new_card": "Utiliser une nouvelle carte",
        "Card_holder": "Nom du titulaire de la carte",
        "Card_number": "Numéro de carte",
        "Expired_date": "Date d'expiration(mm/yy)",
        "ccv": "CVV",
        "Distance": "Distance",
        "Duration": "Durée",
        "Fare": "Tarif",
        "Route": "Routes",
        "Meet_driver": "Rencontrez Driver",
        "Airline": "Compagnie aérienne",
        "Flight": "Vol #",
        "FLY_INFO": "Informations du vol",
        "Ride_Sharing": "Partage de course",
        "Round_trip": "Aller-retour",
        "Car_type": "Type de véhicule",
        "Address": "Adresse",
        "City": "Ville",
        "State": "Etat",
        "Zip_code": "Code postal",
        "Remove": "Enlever",
        "New_Booking": "Nouvelle réservation",
        "Clone_Booking": "Clone Booking",
        "New_Order": "Nouvelle commande",
        "ViewTrackLink": "Voir le suivi de la course",
        "driverLink": "Driver link",
        "ClickCopy": "Click to copy link",
        "ViewLog": "Afficher les historiques des envois",
        "Message": "Message",
        "Update_Booking": "Mise à jour Réservation",
        "Cancel_Booking": "Annuler Réservation",
        "Complete_with_payment": "Finaliser la commande",
        "Incident": "Incident",
        "CONFIRM_CANCEL": "Confirmer l'annulation",
        "CONFIRM_CANCEL_MESSAGE": "Veuillez cliquer sur 'D'accord' pour annuler cette réservation.Si vous ne voulez pas, cliquez sur 'Annuler' pour fermer cette boîte de dialogue",
        "CONFIRM_UPDATE": "Confirmer la mise à jour",
        "CONFIRM_UPDATE_MESSAGE": "Voulez-vous vraiment mettre à jour cette réservation?",
        "CONFIRM_RESET_EDIT_FARE_MESSAGE": "Your edited fare has been reset, please review before continue.",
        "Close": "Fermer",
        "Update_info": "Mettre à jour les infos",
        "Update_info_dispatch": "Mettre à jour les infos et le dispatch",
        "Update_info_tooltip": "Si vous allez réaffecter cette réservation à un nouveau chauffeur, veuillez choisir \"Oui, mettre à jour les informations et envoyer\"",
        "BOOKING": "Réservation",
        "DROPPED_OFF": "Déposé",
        "Payment_Type": "Type de paiement",
        "Total": "Total",
        "Next": "Suivant",
        "Pay_here": "Payez ici",
        "Complete_booking": "Finaliser la commande",
        "Complete_booking_tooltip": "Please click \"Update booking\" to save your changes before continuing.",
        "Cancel": "Annuler",
        "Back": "Retour",
        "Tax": "Taxe",
        "Booking_fees": "Frais de réservation",
        "Tech_fees": "Frais de Technique",
        "Subtotal": "Sous-total",
        "Other_fees": "Autres frais",
        "Waiting_fee": "Waiting fee",
        "Toll_fees": "Frais de péage",
        "Heavy_traffic": "Circulation dense",
        "Rush_hour": "Heure de pointe",
        "Basic_fare": "Tarif de base",
        "Ok": "D'accord",
        "Input_reason": "Saisir une raison",
        "Client_case_matter": "Client case matter / Trip description",
        "Charge_code": "Charge code / Trip code",
        "Enter_3rd_party_location": "Enter 3rd party location",
        "Company": "Entreprise",
        "Check_promo_error": "Check promoCode error!",
        "Add_new_credit_error": "Add new credit error!",
        "Can_not_get_fare_setting": "Cannot get fare setting!",
        "Can_not_get_affilate_fare_setting": "Cannot get Affiliate fare settings!",
        "Can_not_check_commisson": "cannot check commisson!",
        "No_flight_info": "No flight info!",
        "Socket_server_disconnected": "Socket server disconnected!",
        "Please_reload_page_or_check_your_connection": "Sorry. Your request cannot be processed. Please try again.",
        "View_Logs": "Voir les actions",
        "Bookingid": "Réservation #",
        "Calendar": "Calendar",
        "Extra_Destination": "Additional Destination",
        "Meet_Greet_option": "Meet & Greet",
        "On_Curb_option": "On Curb",
        "Credit_Card": "Credit card",
        "Charge": "Charge",
        "No_charge": "No charge",
        "QR_Code_method_not_allow_error_message": "Please select another payment type as QRCode is not available to pay on dashboard!",
        "change_payment_method_note": "If you want to change payment method, please go back to booking form.",
        "Airport_Fee": "Airport Fee",
        "Addi_service": "Add'l Services",
        "Additional_Services": "Additional Services",
        "Minimum": "Minimum",
        "Search_here": "Rechercher ici",
        "No_Driver_Yet": "No driver assigned yet",
        "Personal_Card": "Carte Personnelle",
        "Reject_booking": "Reject Booking",
        "Confirm_reject": "Confirm reject",
        "Confirm_reject_message": "Are you sure you want to reject this booking?",
        "Confirm_reject_booking_with_charge": "You will be charged {0}% of quoted fare based on our cancellation policy. Are you sure you want to reject this booking?",
        "Yes": "Oui",
        "No": "Non",
        "shortTrip": "Short Trip",
        "Add_new_card": "Add new card",
        "Require_customer_info": "Veuillez saisir les informations client pour continuer.",
        "confirm_force_overlap": "Confirmation",
        "confirm_force_overlap_message": "This booking overlaps with driver's accepted bookings. Are you sure you want to assign it to this driver?",
        "select": "Select",
        "PICKUP_TIME_RANGE": "Estimated pickup at {0}",
        "food": "Aliments ",
        "mart": "Marché ",
        "Order_summary": "Order Summary",
        "addOnPrice": "Adjusted price",
        "applicableFee": "Total (applicable)",
        "itemValue": "Item value",
        "promotion_note_paymentMethod": "This order is being applied promotion code with limited payment method: %{method}.",
        "promotion_note_minimum_fare": "This order is being applied promotion code with limited minimum fare: %{fare}.",
        "promotion_note_schedules": "This order is being applied promotion code with limited pickup time.",
        "edited_fare_note": "This is fare adjustment logs for reference only. Please visit reports to check final fare and fee.",
        "view_good_receipt": "Voir la quittance des articles",
        "confirmRemoveGroupTransport": "Are you sure you want to remove current booking from this manifest?",
        "confirmRemoveGroupDelivery": "Are you sure you want to remove current booking from this group?",
        "confirmUngroupTransport": "Are you sure you want to ungroup all bookings from this manifest?",
        "confirmUngroupDelivery": "Are you sure you want to ungroup all bookings from this group?",
        "view_delivery_receipt": "Voir la photo de la livraison",
        "select_time": "Select time",
        "InputReason": "Input reason",
        "viewOrder": "View order",
        "markFailed": "Mark as failed",
        "corporateName": "Corporate Name",
        "sendMessage": "Send Message",
        "AmountDueBefore": "Amount Due Before",
        "AmountDueAfter": "Amount Due After",
        "chargeCustomer": "Charge Customer",
        "pending": "Not Paid",
        "refundPayment": "Refund Customer",
        "refundableAmount": "Refundable amount",
        "refundReason": "Add refund reason",
        "refundBtn": "Refund {0}",
        "partial": "Partial",
        "paid": "Paid",
        "ViewPaymentLogs": "View payment logs",
        "pmActivity": "Payments Activity",
        "paidStatus": "Paid status",
        "paymentLog": "Payment Log",
        "extraWaitFee": "Waiting fee Initial",
        "extraWaitTime": "Wait time (Initial)",
        "copied": "Copied",
        "warnningCancelCorporate": "Please note, you may incur a charge of amount in accordance with our cancellation policy.",
        "customerAdjustments": "Customer Adjustments",
        "customerAdjustmentsTooltip": "Customer Adjustments",
        "supplierDriverAdjustments": "Supplier/Driver Adjustments",
        "updateSummary": "Update Summary",
        "orderDetails": "Order Details",
        "pastDetacted": "Past Booking Detected",
        "pastDetactedContent": "You are about to create a booking in the past"
    },
    "map": {
        "Enter_a_location": "Entrez un lieu",
        "All_company": "Toute les flottes",
        "All_cars": "Tout les services",
        "Show_all_cars": "Montrer tout les services",
        "Hide_all_cars": "Cacher tout les services",
        "Vacant": "Disponible ",
        "Dispatching": "En cours de dispatch",
        "Driver_on_the_way": "Driver en route",
        "Arrived_and_waiting": "Arrivé et en attente",
        "P_O_B": "P.A.B",
        "Dropped_off": "Déposé",
        "Waiting_for_process": "En attente d'exécution",
        "Unavailable": "Indisponible",
        "Total_Drivers": "Total Chauffeurs",
        "Estimate": "Estimées",
        "Destination": "Destination",
        "Pickup": "Prise en charge",
        "Start_point": "Start point",
        "Passenger": "Passager",
        "Driver_name": "Nom du Driver",
        "Search_car_here": "Rechercher véhicule ici",
        "Error_load_booking": "Cannot load the booking, Please try again or refresh page!",
        "Show_all": "Tout montrer",
        "driversLiveMap": "Drivers Map",
        "heatMap": "Demand Map",
        "Trip": "Trip",
        "traffic": "Traffic",
        "bicyclingLayers": "Bicycling",
        "layer": "Layer",
        "trafficBicycling": "Traffic,Bicycling",
        "All_airline": "All airline"
    },
    "customer": {
        "Customers": "Clients",
        "Points": "Points",
        "Migration_Status": "Migration Status",
        "ADD_CUSTOMER": "Ajouter client",
        "EDIT_CUSTOMER": "Modifier client",
        "Add": "Ajouter",
        "Activate": "Activer",
        "Deactivate": "Désactiver",
        "Delete": "Effacer",
        "Import": "Importer",
        "Download_template": "Téléchargez le modèle",
        "View_Message": "Message",
        "Send_Message": "Message",
        "Export_to_excel": "Exporter vers Excel",
        "Filter": "Filter",
        "Apply": "Appliquer",
        "Clear": "Clair",
        "Name": "Nom",
        "Phone_Number": "Numéro De Téléphone",
        "Email": "Email",
        "Vip": "Vip",
        "companies": "Entreprise",
        "File": "Fichier",
        "Number_successful_imports": "Nombre d'importations réussies:",
        "Number_skipped": "Number of skipped: ",
        "Number_duplicate_phone_numbers": "Nombre de numéros de téléphone identiques:",
        "Number_wrong_phone_numbers": "Nombre de numéros de téléphone erronés:",
        "Corporate_Name": "Entreprise",
        "Registered_From": "Enregistré À Partir De",
        "Registration_Date": "Date D'enregistrement",
        "Registration_date_from": "Date d'inscription de",
        "Registration_date_to": "Date d'inscription à",
        "IOS_Last_Login_Version": "Version IOS Lors De La Dernière Connexion",
        "Android_Last_Login_Version": "Version Android Lors De La Dernière Connexion",
        "Last_Active_Date": "Dernière date d'activation",
        "Verification": "Vérification",
        "Support_ID": "ID de support",
        "Days_Since_Last_Active": "Jours depuis la dernière activité",
        "Completed_Bookings": "Réservation terminées",
        "Outstanding_amount": "Montant impayé",
        "Status": "Statut",
        "Actions": "Actions",
        "Action": "action",
        "Inactive": "Inactif",
        "Active": "Actif",
        "Edit": "Modifier",
        "View": "Voir",
        "ALERT_SELECT_ACTIVATE": "Veuillez sélectionner un client à activer",
        "ALERT_SELECT_DEACTIVATE": "Veuillez sélectionner un client à désactiver",
        "ALERT_SELECT_DELETE": "Veuillez sélectionner un client à supprimer",
        "CONFIRM_DELETE": "Êtes-vous sûr de vouloir supprimer ce compte? Le client peut créer un nouveau profil avec des données vides après la suppression du compte.",
        "CONFIRM_DELETE_MULTI": "Voulez-vous supprimer ces clients?",
        "CONFIRM_DEACTIVATE_MULTI": "Voulez-vous désactiver ces clients?",
        "CONFIRM_ACTIVATE_MULTI": "Voulez-vous activer ces clients?",
        "CONFIRM_EXPORT_TO_EXCEL": "Etes vous sûr de vouloir exporter toutes les données dans un fichier .xls?",
        "Add_card_top_up_success": "Prepaid card has been topped up successfully",
        "Add_card_success": "La carte a été ajoutée avec succès!",
        "Add_customer_success": "New customer has been created successfully",
        "Update_customer_success": "The customer has been updated successfully",
        "Deleted_customer_success": "The customer has been deleted successfully",
        "Create_customer_fail": "Failed to create customer",
        "Update_customer_fail": "Failed to update customer ",
        "ERROR_EXISTED_PHONE": "Ce téléphone existe déjà sur le système. Veuillez en saisir un autre.",
        "ERROR_INPUT_FIRSTNAME": "Veuillez saisir le prénom.",
        "ERROR_INPUT_PHONE": "Veuillez saisir le numéro de téléphone",
        "ERROR_INPUT_VALIDPHONE": "Veuillez spécifier un numéro de téléphone valide",
        "ERROR_INPUT_VALIDEMAIL": "Veuillez spécifier un numéro de email valide",
        "CONFIRM_DELETE_CARD": "Etes vous sûr de vouloir effacer cette carte?",
        "First_name": "Prénom",
        "Last_name": "Nom",
        "Phone_number": "Numéro de téléphone",
        "Email_address": "Adresse e-mail",
        "Notes": "Remarques",
        "Account": " Compte",
        "Card_management": "Gestion de carte",
        "Home": "Ma flotte",
        "Affiliate": "Affilié",
        "SHOW_PERSONAL_CARD": "Carte personnel",
        "ADD_PERSONAL_CARD": "Ajouter une carte personnelle",
        "SHOW_mDISPATCHER_CARD": "Show mdispatcher card",
        "ADD_mDISPATCHER_CARD": "Ajouter la carte mDispatcher",
        "Personal_card": "Carte Personnelle",
        "mDispatcher_card": "Carte mDispatcher",
        "Card_holder": "Titulaire de la carte ",
        "Card_number": "Numéro de carte",
        "Credit_type": "Credit type",
        "Credit_code": "Credit code",
        "Sms_verification": "SMS verification",
        "Sms_verification_placeholder": "Enter verification code",
        "Expired_date": "Date d'expiration",
        "CVV": "CVV",
        "Address": "Adresse",
        "Country": "Pays",
        "City": "Ville",
        "State": "Etat",
        "Zip_code": "Code postal",
        "postal_code": "Postal code",
        "Required_fields": "Champs requis",
        "Cancel": "Annuler",
        "Save": "Sauvegarder",
        "ERROR_INPUT_CARD_HOLDER": "Veuillez entrer le titulaire de la carte",
        "ERROR_INPUT_CARD_NUMBER": "Veuillez entrer le numéro de carte",
        "ERROR_INPUT_EXPIRED_DATE": "Date d'expiration non valide",
        "ERROR_VAILD_EXPIRED_DATE": "Expired date is not valid",
        "ERROR_INPUT_CVV": "Veuillez entrer le CVV",
        "ERROR_INPUT_ADDRESS": "Veillez entrer l'adresse",
        "ERROR_INPUT_CITY": "Veuillez entrer la ville",
        "ERROR_INPUT_ZIPCODE": "Veuillez entrer le code postal",
        "ERROR_INPUT_COUNTRY": "Veuillez choisir un pays",
        "Info_edit_error": "Veuillez!",
        "Gender": "Gender",
        "Select_Gender": "Sélectionner genre",
        "Select_Idtype": "Select",
        "Male": "Mâle",
        "Female": "Femme",
        "Other": "Autre",
        "Date_of_birth": "Date de naissance",
        "Street_address": "adresse de rue",
        "Select_Zone": "Select Zone",
        "ERROR_GET_GATEWAYS": "Error !!! Get Gateways",
        "Require_email": "Please enter the email before adding new card",
        "Require_lastName": "Please enter the last name before adding new card",
        "Require_phone_number": "Please enter the traveler's phone number before make a booking with credit card",
        "IMPORT_CUSTOMER_SUCCESS": "import customers successfully",
        "No_card": "Pas de carte trouvée",
        "No_Support": "New card adding is currently not supported in this area",
        "Card_Management": "Gestion de carte",
        "Emergency_contacts": "Contact d'urgence",
        "DETAIL_CUSTOMER": "Détails du client",
        "Select_Country": "Choisissez un pays",
        "Customer_ID": "ID# / National IC#",
        "Credit_balance": "Solde créditeur",
        "required_email_payment": "The email of customer are required. Please update profile to continue.",
        "Yes": "Yes",
        "No": "No",
        "WriteOffDebt": "Radier la dette",
        "selectPayment": "Veuillez sélectionner un mode de paiement pour fermer toutes les réservations en attente de paiement.",
        "writeOffDebtSuccess": "Write off debt successfully",
        "writeOffDebtNotes": "Note: This write-off action should only be confirmed after all the outstanding amount is paid in full.",
        "totalCustomer": "Clientes totals",
        "Personal_Card": "Carte personnelle",
        "INFO_ADD_BALANCE_SUCCESS": "Passenger balance has been updated successfully",
        "INFO_UPDATE_POINT_SUCCESS": "Passenger point has been updated successfully",
        "currency": "Devise",
        "balance": "Solde",
        "changeBalance": "Modification du solde",
        "change": "Change",
        "WALLET_DEPOSIT": "Portefeuille",
        "AccessDenied": "You don't have permission to access this module!",
        "referral_code": "code de parrainage",
        "loginMethod": "Login method"
    },
    "importBookings": {
        "no": "No.",
        "status": "Statut",
        "pickupDate": "Pickup Date",
        "pickup": "Pickup Address",
        "dropoff": "Dropoff Address",
        "passenger": "Passenger",
        "phone": "Numéro de téléphone",
        "seats": "Seats",
        "luggages": "Luggages",
        "rideSharing": "Ride-share",
        "flightNumber": "Flight No.",
        "notes": "Notes",
        "externalId": "External ID",
        "operatorNote": "Notes internes",
        "clientCaseMatter": "Trip description",
        "chargeCode": "Trip code",
        "price": "Price",
        "payout": "Payout",
        "paymentType": "Payment",
        "vehicleTypeRequest": "Car type",
        "importBookings": "Import bookings",
        "corporation": "Entreprise",
        "clickOrDropCsvFile": "Click or drop.csv file",
        "importingFileForReview": "Importing file for review... ",
        "import": "Import",
        "submit": "Submit",
        "downloadTemplate": "Download template",
        "learnMore": "Learn more",
        "total": "Total",
        "valid": "Valid",
        "invalid": "Invalid",
        "failed": "Failed",
        "processing": "Processing",
        "success": "Success",
        "confirm": "Confirm",
        "confirmMessage": "orders will be created. Are you sure you want to proceed ?",
        "create": "Créer",
        "cancel": "Cancel",
        "close": "Fermer",
        "leavingConfirmMessage": "Your work is not saved! Are you sure you want to leave?",
        "resultMessage": "${success} booking(s) has been created successfully, ${failed} failed.",
        "limitExceededBookings": "Maximum import file size: 100 bookings",
        "delivery": {
            "no": "#",
            "pickupDate": "Pickup date",
            "senderName": "Sender name",
            "senderPhone": "Sender phone",
            "senderMail": "Sender email",
            "senderAddress": "Sender address",
            "recipients": "Recipients",
            "senderDistrictOptional": "District",
            "senderLocationDetails": "Location details",
            "recipientName": "Recipient name",
            "recipientPhone": "Recipient phone",
            "recipientAddress": "Recipient address",
            "recipientDistrictOptional": "District",
            "recipientLocationDetailsOptional": "Location Details",
            "packageContent": "Package content",
            "orderNumber": "Order #",
            "instructions": "Instructions for Driver",
            "cod": "COD Item Value",
            "rideShare": "Ride-share",
            "paymentType": "Payment type",
            "carType": "Car type",
            "recipientLocationDetails": "Location details",
            "operatorNote": "Internal notes",
            "rideSharing": "Batch delivery"
        }
    },
    "merchant": {
        "INFO_ADD_BALANCE_SUCCESS": "Merchant balance has been updated successfully",
        "ADD_MERCHANT_USER": "Ajouter un compte de marchand",
        "EDIT_MERCHANT_USER": "Edit merchant account",
        "MERCHANT_USER_DETAILS": "Merchant account details",
        "CASH_WALLET": "Cash wallet",
        "CREDIT_WALLET": "Credit wallet",
        "ADD_MERCHANT": "Add merchant",
        "ACCOUNT_INFO": "infos de compte",
        "BILLING_ADDRESS": "Bank Information",
        "user": {
            "fullName": "Utilisateur marchand",
            "Phone_Number": "Numéro de téléphone",
            "Email": "Email",
            "storeName": "Vendeur",
            "status": "Statut",
            "actions": "action",
            "First_name": "Prénom",
            "Last_name": "Nom de famille",
            "Select_store": "Select merchant",
            "accountHolder": "Account holder's name",
            "First_M_Last": "First M. Last",
            "accountNumber": "Account number",
            "ibanNumber": "IBAN number",
            "bankName": "Name of bank",
            "IDtype": "ID Type",
            "IDnumber": "ID# / National IC#",
            "routingNumber": "Routing/Transit/Branch number",
            "checkNumber": "Check number",
            "ssn": "SSN/NI",
            "sortCode": "Sort code",
            "transitNumber": "Transit number",
            "institutionNumber": "Institution Number",
            "birthDay": "Birthday",
            "address": "Adresse",
            "city": "Ville",
            "state": "Etat",
            "postalCode": "Zipcode",
            "beneficiaryIDIC": "Beneficiary ID# / IC#",
            "relationshipOtherName": "Relationship with other?",
            "verificationDocumentFront": "Verification document (front)",
            "verificationDocumentBack": "Verification document (back)",
            "Verification_document_tooltip": "Acceptable forms for identification: \n  - Passport \n  - Driver license \n  - Resident permit ID \n  - Citizen Card \n  - ID card",
            "additionalDocumentFront": "Additional document (front)",
            "additionalDocumentBack": "Additional document (back)",
            "Additional_document_tooltip": "Acceptable forms of address verification: \n  - Driver license \n  - Utility bill \n  - Government issued letter \n  - Statement from a financial institution \n  - Electoral Register entry",
            "please_select": "Please select",
            "commission": "Commission",
            "ERROR_INPUT_VALIDPHONE": "Please enter a valid phone number.",
            "ERROR_EXISTED_PHONE": "This phone has been existing on the system. Please input another one",
            "Activate": "Activer",
            "Deactivate": "Désactiver",
            "ALERT_SELECT_ACTIVATE": "Please select user to activate.",
            "ALERT_SELECT_DEACTIVATE": "Please select user to deactivate.",
            "CONFIRM_ACTIVATE_MULTI": "Do you want to activate these users?",
            "CONFIRM_DEACTIVATE_MULTI": "Do you want to deactivate these users?",
            "RESET_PASSWORD": "Do you want to reset password?",
            "CONFIRM_RESET_PASSWORD": "Do you want to reset password?",
            "REMOVE_BANK_INFO": "Confirm remove bank info",
            "CONFIRM_REMOVE_BANK_INFO": "Do you want to remove bank info?",
            "note_account_password_default": "Remarque: Pour un nouveau compte de marchand, le mot de passe par défaut est « 12345678 ».",
            "Cash_Balance": "Solde de trésorerie",
            "Credit_Balance": "Solde créditeur"
        },
        "Update_merchant_user_success": "The merchant user has been updated successfully",
        "Update_merchant_user_fail": "Failed to update merchant user",
        "Create_merchant_user_success": "New merchant user has been created successfully",
        "Create_merchant_user_fail": "Failed to create merchant user",
        "Update_merchant_user_status_success": "The merchant user has been updated successfully",
        "Update_merchant_user_status_fail": "Failed to update merchant user",
        "Reset_password_merchant_user_success": "Reset password successfully",
        "Reset_password_merchant_user_fail": "Reset password fail",
        "Deleted_merchant_bank_info_success": "Remove bank info successfully",
        "Deleted_merchant_bank_info_fail": "Remove bank info fail"
    },
    "table_header": {
        "Items_per_page": "Eléments par page",
        "Page": "Page",
        "Search_here": "Rechercher ici",
        "Shown_Active": "%{start} - %{end} de %{total} Affiche ",
        "Active": "%{active} Actif ",
        "OF": "of"
    },
    "driver": {
        "Driver": "Driver",
        "Add": "Ajouter",
        "addDriver": "Add driver",
        "Detail": "Detail",
        "detailDriver": "Driver Details",
        "addNewVehicle": "Add Vehicle",
        "serviceType": "Service Type",
        "transport": "Transport",
        "delivery": "Delivery",
        "stripeConnect": "Stripe Connect",
        "stripeConnectDes": "Add a stripe connect account for easier payouts",
        "bankAccount": "Banking Info",
        "bankAccountDes": "Drivers bank info to handle payouts",
        "supplierComission": "Supplier Commission",
        "supplierComissionDes": "Set commissions the supplier earns for every complete order.",
        "fleetCommission": "Fleet/Supplier commission",
        "fleetCommissionDes": "Set commissions the fleet or supplier earns for every complete order. If the driver is a supplier’s driver, the commission will be deducted from the supplier’s earning.",
        "operationZones": "Operation Zones",
        "operationZonesDes": "Zones that suppliers can receive jobs from:",
        "notes": "Notes",
        "notesDes": "Internal notes about this driver:",
        "searchNotes": "Add notes for driver",
        "marketplace": "Marketplace",
        "marketplaceDes": "Empower drivers to accept jobs they want to do.",
        "otherSettings": "Other Settings",
        "topDriver": "TOP Driver",
        "topDriverDes": "Prioritize top performers with the \"Top Driver\" tag, ensuring they get more rides.",
        "walletTransferBetweenDriver": "Wallet Transfers Between Drivers",
        "walletTransferBetweenDriverDes": "Enable drivers to transfer funds directly to each other’s wallets, facilitating easy distribution of credits by team leaders or ambassadors.",
        "rideSharing": "Ride-sharing",
        "rideSharingDes": "Enable drivers to perform ride-sharing jobs. Contact your account manager for this advance feature.",
        "individual_vehicle": "Individual vehicle",
        "company_vehicle": "Company vehicle",
        "ContactInfo": "Contact Info",
        "name": "Name",
        "expiryDate": "Expiry Date",
        "action": "Action",
        "editDocument": "Edit Document",
        "documentToUpload": "Document to upload",
        "addFiles": "Click or drag file to this area to upload.",
        "fileSize": "Maximum file size 10 MB",
        "fileSizeDes": "The file extension must be .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip",
        "removeOldFile": "Please remove the old file before uploading a new one.",
        "maxLengthFiles": "Please upload max length 1 file",
        "maxSizeFiles": "Please upload file size less than 10MBs",
        "typeFiles": "Please upload file type .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip",
        "maxAvatarSizeFiles": "Please upload avatar size less than 8MBs",
        "IFSCCode": "IFSC Code",
        "Drivers": "Chauffeurs",
        "default": "Default",
        "customize": "Customized",
        "Activate": "Activer",
        "Stripe_connect": "STRIPE CONNECT",
        "status_stripe": "Status: {0} ",
        "stripeConnectStatus": "Stripe connect",
        "stripeStatus": "Stripe account status",
        "Deactivate": "Désactiver",
        "Delete": "Effacer",
        "acceptInDay": "Maximum reservations driver can accept in a day",
        "acceptATime": "Total number of reservations a driver can accept at a time",
        "Commission": "Set fleet commission",
        "Title_Commission": "Définir la commission de flotte pour tous les conducteurs",
        "Confirm_Set_Commission": "Êtes-vous sûr de vouloir définir cette valeur de commission pour tous les pilotes?",
        "Commission_Successfully": "Driver commission has been updated successfully",
        "Apply": "Appliquer",
        "Type": "Type",
        "Currency": "Devise",
        "View_Message": "Message",
        "Send_Message": "Envoyer le message",
        "Driver_Name": "Nom Du Driver",
        "Driver_ID": "ID# / National IC#",
        "Phone_Number": "Numéro de téléphone",
        "Driver_Type": "Type De Driver",
        "TOP": "TOP",
        "Company": "Compagnie",
        "City": "Ville",
        "Driver_License_Number": "Numéro De Permis De Conduire",
        "License_Plate": "Plaque D'immatriculation",
        "Car_Type": "Type De Véhicule",
        "Zone": "Zone de filtre",
        "Registered_From": "Enregistré À Partir De",
        "Registration_Date": "Date D'enregistrement",
        "IOS_Last_Login_Version": "Version IOS Lors De La Dernière Connexion",
        "Android_Last_Login_Version": "Version Android Lors De La Dernière Connexion",
        "Last_Active_Date": "Dernière date d'activité",
        "Days_Since_Last_Active": "Nombre de jurs depuis la dernière activation",
        "Completed_bookings": "Réservations terminées",
        "Status": "Statut",
        "Actions": "Actions",
        "Action": "action",
        "Inactive": "Inactif",
        "Active": "Actif",
        "Edit": "Modifier",
        "View": "Voir",
        "ALERT_SELECT_ACTIVATE": "Veuillez sélectionner un Driver à activer.",
        "ALERT_SELECT_DEACTIVATE": "Veuillez sélectionner un Driver à désactiver",
        "ALERT_SELECT_DELETE": "Veuillez sélectionner un Driver à supprimer",
        "CONFIRM_DELETE": "Voulez-vous supprimer ce Driver?",
        "CONFIRM_DEACTIVATE_MULTI": "Voulez-vous vraiment désactiver le (s) chauffeur (s) sélectionné (s)? Le (s) compteur (s) physique (s) connecté (s) sera également désactivé. Seuls le(s) conducteur(s) qui n'a pas de trajet actuel ou de réservation en cours peuvent être désactivés.",
        "CONFIRM_ACTIVATE_MULTI": "Voulez-vous pour activer ces Drivers? Seuls les chauffeurs qui sont complètement configurés pour le fonctionnement peuvent être activés.",
        "CONFIRM_DELETE_MULTI": "Voulez-vous supprimer ces Drivers? Seul un Driver qui a un statut d'inactif peut être supprimé.",
        "CONFIRM_REMOVE_BANKING_INFOR": "Are you sure you want to remove banking information?",
        "REMOVE_BANKING_INFOR": "Remove banking information",
        "ADD_DRIVER": "Ajouter driver",
        "Avatar": "Avatar",
        "Individual_Driver": "Driver individuel",
        "Owner_Operator": "Gérant de flotte",
        "deliveryCompany": "Livraison - Véhicule d'entreprise",
        "deliveryIndividual": "Livraison - Véhicule individuel",
        "CONTACT_INFO": "Infomations de contact",
        "Home_address": "Adresse du domicile",
        "Username": "Nom d'utilisateur",
        "First_name": "Prénom",
        "Last_name": "Nom",
        "Phone_number": "Numéro de téléphone",
        "Email_address": "Adresse e-mail",
        "Select_city": "Sélectionner une ville",
        "State": "Etat",
        "Zip_code": "Code postal",
        "Date_of_birth": "Date de naissance",
        "placeholderDate": "mm/dd/yyyy",
        "City_driver_want_to_drive": "Ville dans laquelle le Driver veut exercer",
        "Driver_license_number": "Numéro de permis de conduire",
        "Driver_license_state": "Pays du permis de conduire",
        "Driver_license_expiry": "Expiration du contrôle technique",
        "FOR_INSPECTOR": "Pour l'inspecteur",
        "Document": "Document",
        "Document_Id": "Document ID",
        "Link": "Lien",
        "Notes": "Remarques",
        "UPLOAD_DOCUMENT": "Télécharger le document",
        "Click_here_to_download": "Cliquer ici pour télécharger.",
        "Click_here_to_view": "Click to view",
        "EXTENSION_REQUIREMENT": "L'extension du fichier doit être .jpg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip et la taille maximale est 5Mb",
        "Invalid_file": "Fichier non valide",
        "File_size_larger_than_5Mb": "File size must be larger than 5Mb",
        "BANK_ACCOUNT": "Compte bancaire",
        "Name_of_bank": "Nom de la banque",
        "Name_of_account": "Nom de compte",
        "First_M_Last": "First M. Last",
        "RTBnumber": "Numéro du routage / de la correspondance / de la succursale",
        "bankName": "Bank name",
        "Select_a_bank": "Select a bank",
        "Account_number": "Numéro de compte",
        "IBAN_number": "Numéro de IBAN",
        "Check_number": "Numéro de vérification",
        "Swift_code_number": "Numéro de code Swift",
        "SSNNI": "SSNNI",
        "sortCode": "Sort code",
        "Verification_document": "Verification document (front)",
        "Additional_document": "Additional document (front)",
        "Verification_document_back": "Verification document (back)",
        "Additional_document_back": "Additional document (back)",
        "others": "Autres",
        "signedW9Form": "Signed W-9 Form",
        "driverLicense": "Permis de conduire",
        "Verification_document_tooltip": "Acceptable forms for identification: \n  - Passport \n  - Permis de conduire \n  - Resident permit ID \n  - Citizen Card \n  - ID card",
        "Additional_document_tootip": "Acceptable forms of address verification: \n  - Permis de conduire \n  - Utility bill \n  - Government issued letter \n  - Statement from a financial institution \n  - Electoral Register entry",
        "isBankAccountOwner": "Is this your account?",
        "yes": "Yes",
        "no": "No",
        "beneficiary_name": "Beneficiary Name",
        "beneficiary_idic": "Beneficiary ID/IC",
        "relationship": "Relationship",
        "select_relationship": "Select relationship status",
        "spouse": "Spouse",
        "daughterOrSon": "Daughter/Son",
        "brotherOrSister": "Brother/Sister",
        "relative": "Relative",
        "friends": "Friends",
        "other": "Other",
        "relationshipOtherName": "Relationship with other?",
        "OPERATION": "Opération",
        "CAR_INSURANCE": "Assurance voiture",
        "Policy_number": "Numéro de police",
        "Effective_date": "Date d'effet",
        "Expired_date": "Date dépassée",
        "Select_company": "Sélectionner une compagnie",
        "All_company": "Toutes les entreprises",
        "All_Car_Type": "Tous types de véhicules",
        "Car": "Véhicule",
        "Existing_car": "Véhicule existant",
        "New_car": "Nouveau véhicule",
        "License_plate": "Plaque d'immatriculation",
        "Select_license_plate": "Sélectionnez la plaque d'immatriculation",
        "Select_Car_Type": "Veuillez sélectionner le type de véhicule",
        "vehicleOwner": "Nom du propriétaire du véhicule",
        "Make": "Marque",
        "Select_Car_Make": "Sélectionner marque de véhicule",
        "Model": "Modèle",
        "Select_Car_Model": "Sélectionner le modèle de véhicule",
        "Year": "Année",
        "Select_Year": "Sélectionnez l'année",
        "Color": "Couleur",
        "Passengers": "Passagers",
        "Luggage": "Colis",
        "License_expiry": "Expiration de la licence",
        "Shift": "Horraires",
        "Select_Shift_Template": "Sélectionner un modèle Shift",
        "Fleet_commission": "Commission de la Flotte (rapport)",
        "Company_commission": "Company commission (report)",
        "rideHailing": "Street Hailing",
        "intercity": "Intercity",
        "food": "Aliments ",
        "mart": "Marché ",
        "streetSharing": "Street Sharing",
        "Shuttle": "Shuttle",
        "parcel": "Colis ",
        "driverInsurance": "Driver Insurance",
        "Percentage": "Pourcentage",
        "Amount": "Montant",
        "transaction": "transaction",
        "Terminal_ID": "Identification de terminal",
        "Auth_key": "Clé d'authentification",
        "CREDIT_DEPOSIT": "Portefeuille de crédit",
        "Credit_Balance": "Balance de crédit",
        "Balance": "Équilibre",
        "Change_balance": "Modification du solde",
        "Select_balance": "Select balance",
        "Adjust_amount": "Adjust amount",
        "Adjust_value": "Adjust value",
        "New_balance": "Nouveau solde ",
        "New_point": "New point",
        "Enter_reason": "Entrer la raison",
        "ERROR_INPUT_BALANCE": "Veuillez sélectionner l'équilibre.",
        "ERROR_INPUT_AMOUNT": "Veuillez entrer cette valeur",
        "ERROR_INPUT_REASON": "Veuillez entrer la raison.",
        "ERROR_INPUT_VALID_AMOUNT": "Veuillez entrer une valeur supérieure ou égale à 0.",
        "INFO_ADD_BALANCE_SUCCESS": "Le solde du pilote a été mis à jour avec succès",
        "INFO_ADD_CASH_BALANCE_SUCCESS": "Driver cash balance has been updated successfully",
        "Force_meter": "Force_meter",
        "Force_meter_tooltip": "L'appli chauffeur doit être connectée au compteur avant d'être utilisée. Appliqué pour les compteurs Pulsar & Centrodyne",
        "ERROR_EXISTED_PHONE": "Ce téléphone existe déjà sur le système. Veuillez en saisir un autre.",
        "TOP_driver": "TOP¨chauffeur",
        "Ride_sharing": "Partage de course",
        "Note_password": "Remarque: Après la création du compte, veuillez saisir \"12345678\" comme mot de passe par défaut pour vous connecter",
        "Required_fields": "Champs requis",
        "Cancel": "Annuler",
        "Save": "Sauvegarder",
        "SaveAndInProgress": "Save & In progress",
        "SaveAndDeactivate": "Save & Deactivate",
        "ERROR_REQUIRE_": "Please input ",
        "ERROR_INPUT_AVATAR": "Please upload avatar.",
        "ERROR_INPUT_DRIVER_TYPE": "Please select driver type.",
        "ERROR_INPUT_DOCUMENT": "Please upload ",
        "ERROR_INPUT_USERNAME": "Please input username.",
        "ERROR_INPUT_VALID_USERNAME": "Username may only contain lower letters & numbers and must be between 3 and 20 characters long",
        "ERROR_INPUT_PHONE": "Veuillez spécifier un numéro de téléphone valide.",
        "ERROR_INPUT_VALID_EMAIL": "Veuillez entrer un email valide",
        "ERROR_INPUT_EMAIL": " Please input email.",
        "ERROR_INPUT_FIRSTNAME": "Veuillez saisir le prénom.",
        "ERROR_INPUT_LASTNAME": "Veuillez saisir un nom de famille.",
        "ERROR_INPUT_ADDRESS": "Veuillez saisir l'adresse postale.",
        "ERROR_INPUT_CITY": "Veuillez entrer la ville.",
        "ERROR_INPUT_STATE": "Veuillez saisir l'état.",
        "ERROR_INPUT_ZIPCODE": "Veuillez saisir le code postal.",
        "ERROR_INPUT_BIRTHDAY": "Veuillez entrer la date de naissance.",
        "ERROR_INPUT_NAME_OF_BANK": "Veuillez saisir le nom de la banque.",
        "ERROR_INPUT_NAME_OF_ACCOUNT": "Veuillez entrer le nom du compte.",
        "ERROR_INPUT_ROUTING_NUMBER": "Veuillez saisir le numéro de routage.",
        "ERROR_INPUT_BANK_NAME": "Please select a bank.",
        "ERROR_INPUT_ACCOUNT_NUMBER": "Veuillez saisir le numéro de compte.",
        "ERROR_INPUT_CHECK_NUMBER": "Veuillez entrer le numéro de vérification.",
        "ERROR_INPUT_SSN": "Veuillez saisir le Numéro de Sécurité sociale.",
        "ERROR_INPUT_DocumentId": "Please input Document ID.",
        "ERROR_INPUT_SORTCODE": "Please input sort code.",
        "ERROR_INPUT_COMPANY": "Veuillez sélectionner une compagnie à activer.",
        "ERROR_INPUT_LICENSE_PLATE": "Veuillez choisir une plaque d'immatriculation.",
        "ERROR_INPUT_ZONE": "Veuillez sélectionner une zone à activer.",
        "ERROR_INPUT_SHIFT": "Veuillez sélectionner un shift à activer.",
        "ERROR_ACCOUNT_NUMBER_ONLY_NUMBERS": "Ne doit contenir que des nombres.",
        "ERROR_SSN_ONLY_NUMBERS": "Ne doit contenir que des nombres.",
        "ERROR_SSN_LENGTH": "Le SSN doit comporter au moins 4 caractères",
        "ERROR_COMMISSION_NUMBERS": "S'il vous plait, entrez un nombre valide",
        "INFO_DEACTIVAED_SUCCESS": "Les {0} chauffeurs sélectionnés ont bien été désactivés.",
        "INFO_ACTIVAED_SUCCESS": "Les {0} chauffeurs sélectionnés ont bien été activés.",
        "INFO_DEACTIVAED_SUCCESS_BUT_HARDWARE": "Le chauffeur a été désactivé mais le compteur intégré ne peut pas être désactivé pour des raisons imprévues.",
        "INFO_MULTIDEACTIVAED_SUCCESS": "The {0} selected drivers have been successfully deactivated.",
        "INFO_MULTIDEACTIVAED_HARDWARE_SUCCESS": "Les {0} chauffeurs sélectionnés ont été désactivés avec succès. {1} compteur (s) connecté (s) ont été désactivés.",
        "INFO_MULTIDEACTIVAED_SUCCESS_BUT_HARDWARE": "Les {0} pilotes sélectionnés ont bien été désactivés. {1} compteur (s) connecté (s) ont été désactivés. {2} ne l'ont pas été à cause de raisons imprévues.",
        "INFO_MULTIACTIVAED_SUCCESS": "The {0} selected drivers have been successfully activated.",
        "DRIVER_UPDATE_SUCCESS": "Le Driver a bien été mis à jour.",
        "DRIVER_CREATE_SUCCESS": "Les informations sur le pilote ont été ajoutées avec succès",
        "Expiry_Date": "Date d'expiration ",
        "Case_number": "Numéro de dossier",
        "Export_to_csv": "Export to CSV",
        "CONFIRM_EXPORT_TO_CSV": "Are you sure you want to export all data as a .csv file?",
        "Ride_sharing_tooltip": "Le partage de course est une fonctionnalité avancée, veuillez contacter notre équipe de support pour avoir plus d’informations.",
        "In_Review": "En revue",
        "Reset_password_success": "Reset password successfully",
        "Topup_note": "Il est permis de recharger le solde de votre pilotes après avoir activé le pilotes",
        "cash_balance": "Solde",
        "cash_wallet": "Porte-monnaie",
        "total_balance": "Solde total",
        "total_cash_balance": "Solde de caisse total",
        "total_credit_balance": "Solde créditeur total",
        "banking_status": "Banking status",
        "bankingStatus": {
            "verified": "Verified",
            "not_verified": "Not verified"
        },
        "INVALID_FILE_SIZE": "The file size must be less than 8Mb.",
        "totalDrivers": "Nombre de conducteur",
        "profileStatus": "Statut du profil",
        "profile_completed": "Profil complet",
        "profile_incomplete": "Profil incomplet",
        "profile_inProgress": "Profile in progress",
        "in_review_completed": "En cours de révision (profil complété)",
        "in_review_incomplete": "En cours de révision (profil incomplet)",
        "in_review_inProgress": "En cours de révision (profil en cours)",
        "CASH_WALLET_DEPOSIT": "Cash wallet",
        "payoutSelect": "Payout (an inbox and email will be sent to the driver after payout)",
        "Confirm_update_cash_balance": "Change Cash Balance",
        "Confirm_update_cash_balance_msg": "Are you sure you want to change driver balance?",
        "cashBalance": "Solde de trésorerie",
        "cashBalance_unavailable": "0.00",
        "cashBalance_active": "actif",
        "cashBalance_negative": "Négatif",
        "creditBalance": "Credit balance",
        "ERROR_INPUT_DOCUMETID": "Please input Document Id.",
        "ERROR_INPUT_EXPIRYDATE": "Please input Expiry Date.",
        "driverType": "Type de conducteur",
        "tooltip_in_review_completed": "Envoyer un message aux conducteurs 'en cours de traitement' qui n'ont pas complété leur profil.",
        "tooltip_in_review_incomplete": "Envoyer un message aux conducteurs  'en cours de traitement' qui n'ont pas complété leur profil.",
        "tooltip_in_review_inProgress": "Envoyer un message aux conducteurs 'en cours de traitement' qui ont complété leur profil.",
        "tooltip_active": "Envoyer un message aux conducteurs 'actifs' .",
        "tooltip_inactive": "Envoyer un message aux conducteurs dont le compte a été réactivé.",
        "active": "Activer",
        "inactive": "Désactiver",
        "inReviewInProgress": "En cours de traitement",
        "Market_place": "Marketplace",
        "Market_place_tooltip": "Empower drivers to choose on-demand or reservations bookings to accept",
        "selectDriverType": "Select driver type",
        "PlsSelectDriverType": "Please select driver type",
        "emergencyContacts": "Emergency Contacts",
        "switchVehicle": "Switch to any vehicle",
        "switchVehicleNote": "Allow driver to switch to any vehicle of their assigned supplier company.",
        "vehicleModel": "Model",
        "licensePlateNumber": "Vehicle Plate",
        "addVehicle": "Add Vehicle",
        "newVehicle": "New Vehicle",
        "linkVehicle": "Link Vehicle",
        "modal": "Modal",
        "selectVehicle": "Select Vehicle",
        "selectVehicleNote": "Enable driver to select or change vehicle for a booking.",
        "Warning_change_company": "Warning: Reassigning this driver to another company will unlink all associated vehicles."
    },
    "corporate": {
        "User_Name": "Nom d'utilisateur",
        "Edit_Balance": "Éditer le solde",
        "Corporates": "Corporates",
        "Supplier": "Fournisseur",
        "CarTypes": "Car Types",
        "Add": "Ajouter",
        "Balance": "Solde",
        "Activate": "Activer",
        "Deactivate": "Désactiver",
        "Delete": "Effacer",
        "Company_Name": "Nom de la société",
        "Admin_Name": "Nom de l'administrateur",
        "Username": "Nom d'utilisateur",
        "Phone_Number": "Numéro De Téléphone",
        "importBooking": "Possibilité d'importer des réservations",
        "Email": "Email",
        "Registered_From": "Enregistré À Partir De",
        "Registration_Date": "Date D'enregistrement",
        "Status": "Statut",
        "Actions": "Actions",
        "Action": "action",
        "Inactive": "Inactif",
        "Active": "Actif",
        "In_Review": "En revue",
        "Edit": "Modifier",
        "View": "Voir",
        "ADD_CORPORATE_CARD": "Ajouter une carte d'entreprise",
        "selectCountry": "Sélectionnez un pays",
        "CONFIRM_DELETE_CARD": "Etes vous sûr de vouloir effacer cette carte?",
        "ALERT_SELECT_ACTIVATE": "Veuillez sélectionner l'entreprise à activer.",
        "ALERT_SELECT_DEACTIVATE": "Veuillez sélectionner l'entreprise à désactiver.",
        "ALERT_SELECT_DELETE": "Veuillez sélectionner l'entreprise à supprimer.",
        "CONFIRM_DELETE": "Voulez-vous supprimer cette entreprise?",
        "CONFIRM_DELETE_USER": "Voulez-vous supprimer cet utilisateur entreprise?",
        "CONFIRM_DEACTIVATE_MULTI": "Voulez-vous désactiver ces entreprises?",
        "CONFIRM_ACTIVATE_MULTI": "Voulez-vous activer ces entreprises?",
        "CONFIRM_DELETE_MULTI": "Voulez-vous supprimer ces entreprises?\n ** Seules les sociétés inactives peuvent être supprimées.",
        "ERROR_INPUT_PHONE": "Veuillez saisir le numéro de téléphone",
        "ERROR_INPUT_VALIDPHONE": "Veuillez spécifier un numéro de téléphone valide",
        "ERROR_EXISTED_PHONE": "Ce téléphone existe déjà sur le système. Veuillez en saisir un autre.",
        "Account": "Compte",
        "Corp_traveler": "Utilisateurs",
        "Payment_methods": "Moyens de paiement",
        "Corp_operators": "Opérateurs Corporate",
        "Pricing": "Tarification",
        "COMPANY_INFORMATION": "Informations sur la société",
        "Company_name": "Nom de la société",
        "Company_ID": "Compagnie ID (pour l'inscription d'un utilisateur)",
        "Company_address": "Adresse de la société",
        "Invoice_email": "Email de facture",
        "Confirmation_email": "Email de confirmation",
        "colorCodeBooking": "Code couleur",
        "ADMIN_ACCOUNT": "Compte admin",
        "First_name": "Prénom",
        "Last_name": "Nom",
        "Commission": "Commission",
        "commissionBooking": "Appliquer une commission pour les réservations effectuées à partir de l'application",
        "Required_fields": "Champs requis",
        "Save": "Sauvegarder",
        "Cancel": "Annuler",
        "Reset_password": "Réinitialiser le mot de passe",
        "Employee_Name": "Nom De L'employé",
        "Created_Date": "\tCréer Date",
        "Add_corporate_card": "Ajouter une carte d'entreprise",
        "Corp_Users": "Utilisateurs Entreprise",
        "Pricing_Default": "Corporate Rate Template",
        "Pricing_Discount": "Pourcentage de réduction (appliqué pour les passagers de la société)",
        "Markup_By_Percent": "Majoration en pourcentage (appliquée uniquement aux voyageurs corp)",
        "Pricing_Discount_new": "Discount by percentage (based on default rate)",
        "Markup_By_Percent_new": "Mark-up by percentage (based on default rate)",
        "Pricing_Add_different_rates": "Ajouter des tarifs différents",
        "ERROR_INPUT_COMPANYNAME": "Veuillez saisir le nom de l'entreprise.",
        "ERROR_INPUT_USERNAME": "Veuillez saisir le nom d'utilisateur",
        "ERROR_INPUT_FIRSTNAME": "Veuillez saisir le prénom.",
        "ERROR_INPUT_EMAIL": "Veuillez saisir l'email",
        "ERROR_INPUT_VALID_EMAIL": "Veuillez entrer un l'email valide .",
        "ERROR_INPUT_VALID_NUMBER": "Veuillez entrer un nombre valide",
        "ERROR_INPUT_VALID_RANGE_AMOUNT": "Please enter a value between {0} and {1}.",
        "INFO_ADD_CORPORATE_SUCCESS": "Le compte {0} a bien été créé. Veuillez vérifier le mail {1} pour les détails de certificat",
        "INFO_UPDATE_CORPORATE_SUCCESS": "La société a bien été mise à jour.",
        "INFO_UPDATE_PRICING_SUCCESS": "Mettre à jour la tarification avec succès",
        "INFO_RESET_PASSWORD_SUCCESS": "Account {0} has been reset successfully. Please check the email {1} for login credentials details.",
        "COMFIRM_RESET_PASSWORD": "Voulez-vous réinitialiser le mot de passe?",
        "Traveler_signature": "Signature du voyageur",
        "Parcel_Delivery": "Activer la livraison de colis",
        "Tracking_log": "Journal de suivi",
        "Rating": "Évaluation",
        "Email_address": "Adresse e-mail",
        "Assistant_email": "Assistant email",
        "Corp_PO": "Corp PO",
        "Cost_centre": "Centre de coûts",
        "Corp_division": "Division d'entreprise",
        "Corp_id": "Numéro d'entreprise",
        "Department": "Département",
        "Manager_email": "Mail du directeur",
        "Manager_name": "Nom du gestionnaire",
        "Corporate_card": "Carte entreprise ",
        "Direct_invoicing": "Facturation directe",
        "Corporate_Prepaid": "Payé via Wallet ",
        "Cash": "Paiement à bord",
        "Car_Type_ID": "Identification Du Type De Véhicule",
        "App_Display_Name": "Nom De L'affichage De L'application",
        "Normal_Fare": "Régulier",
        "Flat_Fare": "Forfait",
        "Hourly_Daily_Rate": "Horaire / quotidien",
        "Delivery_Rate": "Livraison",
        "Assign_rate": "Attribuez tarif",
        "Zone": "Zone de filtre",
        "Rate": "Tarif",
        "Zone_Rate": "Zone - Tarif",
        "Please_select_rate": "Veuillez sélectionner un tarif",
        "Paid_by": "Payé par",
        "Enter_new_credit_card": "Entrez une nouvelle carte de crédit",
        "TOP_UP": "Réapprovisionnement",
        "Top_up": "Top up",
        "Topup_amount": "Topup amount",
        "Currency": "Devise",
        "ERROR_INPUT_AMOUNT": "Veuillez entrer un montant valide",
        "VAT_number": "Numéro de TVA",
        "Gender": "Gender",
        "Select_Gender": "Sélectionner genre",
        "Male": "Male",
        "Female": "Female",
        "Date_of_birth": "Date de naissance",
        "Street_address": "adresse de rue",
        "City": "Ville",
        "State": "Etat",
        "Zip_code": "Code postal",
        "Country": "Pays",
        "adding_new_card": "You are adding a new card to use service in %{zoneName} area",
        "No_Support": "New card adding is currently not supported in this area",
        "Job_title": "Job title",
        "required_phone_number_payment": "The phone number of corporate are required. Please update profile to continue.",
        "Yes": "Oui",
        "No": "Non",
        "AddCardBeforeTopup": "No credit card was found. Please add a new card to continue.",
        "flightNumber": "Flight number",
        "Company_Type": "Type d'entreprise",
        "Standard": "Défaut",
        "Airline": "Airline",
        "Branding": "Branding",
        "bgImage": "Background image (1600x900)",
        "logoImage": "Logo image",
        "aliasTitle": "Alias (URL) parameter",
        "importChauffeurBooking": "Autorisé à importer des réservations de transport",
        "isCorporateVip": "Corporate VIP",
        "isCorporateVipToolTip": "Enable this setting to automatically assign VIP status to all corporate travelers upon creation, you may turn it off for each individual user.",
        "qrTitle": "QR Web booking link",
        "qrNote": "Your personalized partner booking link is here! Display it prominently in bars, hotel receptions, guest rooms, and on your website—anywhere your customers might need a ride or delivery. Consider designing standees with your unique QR code, allowing customers a quick and easy scan to book their journey!",
        "PricingLegacy": "Pricing (Legacy)",
        "defaultTemplate": "Default Template",
        "Select_Rate_Template": "Select Rate Template"
    },
    "airline": {
        "Airlines": "Arilines",
        "Add": "Ajouter ",
        "Activate": "Activer",
        "Deactivate": "Désactiver",
        "Delete": "Delete",
        "Company_Name": "Company Name",
        "Admin_Name": "Admin Name",
        "Username": "Nom d'utilisateur",
        "Phone_Number": "Numéro de téléphone",
        "Email": "Email",
        "Registered_From": "Registered From",
        "Registration_Date": "Registration Date",
        "Status": "Statut",
        "Actions": "Actions",
        "Action": "action",
        "Inactive": "Inactive",
        "Active": "actif",
        "In_Review": "En cours de traitement",
        "Edit": "Modifier",
        "View": "Voir",
        "ADD_CORPORATE_CARD": "Add Airline card",
        "selectCountry": "Sélectionnez un pays",
        "CONFIRM_DELETE_CARD": "Are you sure you want to delete this cards?",
        "ALERT_SELECT_ACTIVATE": "Please select Airline to activate.",
        "ALERT_SELECT_DEACTIVATE": "Please select Airline to deactivate.",
        "ALERT_SELECT_DELETE": "Please select Airline to delete.",
        "CONFIRM_DELETE": "Do you want to delete this Airline?",
        "CONFIRM_DELETE_USER": "Do you want to delete this Airline user?",
        "CONFIRM_DEACTIVATE_MULTI": "Are you sure want to deactivate these company?",
        "CONFIRM_ACTIVATE_MULTI": "Are you sure you want to active this company?",
        "CONFIRM_DELETE_MULTI": "Are you sure you want to delete this company?\n ** Only company who are inactive can be deleted.",
        "ERROR_INPUT_PHONE": "Please specify a valid phone number.",
        "ERROR_INPUT_VALIDPHONE": "Please enter a valid phone number.",
        "ERROR_EXISTED_PHONE": "This phone has been existing on the system. Please input another one",
        "Airline": "Airline",
        "Crew": "Crew",
        "Payment_methods": "Méthodes de Paiement",
        "Airline_operators": "Airline operators",
        "Pricing": "Pricing",
        "COMPANY_INFORMATION": "Company information",
        "Company_name": "Company name",
        "Company_ID": "Company ID",
        "Company_address": "Company address",
        "Invoice_email": "Invoice email",
        "Confirmation_email": "Confirmation email",
        "colorCodeBooking": "Color code",
        "ADMIN_ACCOUNT": "Admin account",
        "First_name": "Prénom",
        "Last_name": "Last name",
        "Commission": "Commission",
        "Required_fields": "Required fields",
        "Required_fields_crews": "Note: For new Crew account, the default password is \"12345678\"",
        "Save": "Save",
        "Cancel": "Cancel",
        "Reset_password": "Reset password",
        "Crew_Name": "Crew",
        "Created_Date": "\tCreated Date",
        "Add_corporate_card": "Add airline card",
        "Corp_Users": "Operators",
        "Pricing_Default": "Défaut",
        "Pricing_Discount": "Discount by percentage",
        "Markup_By_Percent": "Mark-up by percentage",
        "Pricing_Add_different_rates": "Add different rates",
        "ERROR_INPUT_COMPANYNAME": "Please input company name.",
        "ERROR_INPUT_USERNAME": "Please input username.",
        "ERROR_INPUT_FIRSTNAME": "Please input first name.",
        "ERROR_INPUT_EMAIL": "Please input email.",
        "ERROR_INPUT_VALID_EMAIL": "Please enter a valid email address.",
        "ERROR_INPUT_VALID_NUMBER": "Please enter valid number",
        "ERROR_INPUT_VALID_RANGE_AMOUNT": "Please enter a value between {0} and {1}.",
        "INFO_ADD_CORPORATE_SUCCESS": "Account {0} has been created successfully.",
        "INFO_UPDATE_CORPORATE_SUCCESS": "The information has been updated successfully.",
        "INFO_UPDATE_PRICING_SUCCESS": "Pricing updated successfully",
        "INFO_RESET_PASSWORD_SUCCESS": "Account {0} has been reset successfully.",
        "COMFIRM_RESET_PASSWORD": "Do you want to reset password?",
        "Traveler_signature": "Traveler signature",
        "Tracking_log": "Tracking log",
        "Rating": "Étoile",
        "Email_address": "Email address",
        "Assistant_email": "Assistant email",
        "Corp_PO": "Corp PO",
        "Cost_centre": "Cost centre",
        "Corp_division": "Corp division",
        "Corp_id": "Corp id",
        "Department": "Department",
        "Manager_email": "Manager's email",
        "Manager_name": "Manager's name",
        "Corporate_card": "Airline card",
        "Direct_invoicing": "Facture directe",
        "Corporate_Prepaid": "Airline Prepaid",
        "Cash": "En espèces",
        "Car_Type_ID": "Car Type ID",
        "App_Display_Name": "App Display Name",
        "Normal_Fare": "Regular",
        "Flat_Fare": "Flat Rate",
        "Hourly_Daily_Rate": "Hourly/ Daily",
        "Delivery_Rate": "Livraison",
        "Assign_rate": "Assign rate",
        "Zone": "Zone de filtre",
        "Rate": "Avis",
        "Zone_Rate": "Zone - Rate",
        "Please_select_rate": "Please select rate",
        "Paid_by": "Paid by",
        "Enter_new_credit_card": " Enter new credit card ",
        "TOP_UP": "TOP UP",
        "Top_up": "Top up",
        "Topup_amount": "Topup amount",
        "Currency": "Devise",
        "ERROR_INPUT_AMOUNT": "Please input valid amount.",
        "VAT_number": "VAT number",
        "Gender": "Gender",
        "Select_Gender": "Select",
        "Male": "Male",
        "Female": "Female",
        "Date_of_birth": "Date de naissance",
        "Street_address": "Street address",
        "City": "Ville",
        "State": "Etat",
        "Zip_code": "Code postal",
        "Country": "Pays",
        "adding_new_card": "You are adding a new card to use service in %{zoneName} area",
        "No_Support": "New card adding is currently not supported in this area",
        "Job_title": "Job title",
        "required_phone_number_payment": "The phone number of corporate are required. Please update profile to continue.",
        "Yes": "Yes",
        "No": "No",
        "AddCardBeforeTopup": "No credit card was found. Please add a new card to continue."
    },
    "message": {
        "Add": "Ajouter",
        "Delete": "Effacer",
        "Items_per_page": "Eléments par page",
        "Page": "Page",
        "Search_here": "Rechercher ici",
        "Shown_Active": "{0} - {1} de {2} Affiche",
        "Date": "Date",
        "Subject": "Sujet",
        "To": "À",
        "Type": "Type",
        "Result": "Résultat",
        "Actions": "Actions",
        "New_message": "Nouveau message",
        "MESSAGE_DETAILS": "Message details",
        "All": "Tout",
        "Company": "Compagnie",
        "Active": "Actif",
        "In_Active": "Inactif",
        "In_Review": "En revue",
        "Send_to": "Envoyer à",
        "All_drivers": "Tous les driver",
        "All_customers": "Tous les clients",
        "All_merchants": "Tous les marchands",
        "Customized_driver": "Chauffeur personnalisé",
        "Customized_customer": "Client personnalisé",
        "Customized_merchant": "Personnalisation Marchand",
        "Receiver_list": "Liste de destinataires",
        "Content": "Contenu",
        "Required_fields": "Champs requis",
        "Search_driver_name_driver_phone_number": "Rechercher nom du chauffeur, numéro de téléphone du chauffeur",
        "Search_category_name": "Search category name",
        "Search_customer_name_customer_phone_number": "Rechercher le nom du client, le numéro de téléphone du client",
        "Search_merchant_name_merchant_phone_number": "Search merchant name, merchant phone number",
        "Search_car_type": "Search car type",
        "Searching": "Recherche...",
        "No_results": "Aucun résultat",
        "Send": "Envoyer",
        "Cancel": "Annuler",
        "SMS_failed_Inbox_succeeded": "L'envoi de SMS a échoué, le message a été envoyé par inbox",
        "Failed": "Échoué",
        "Succeeded": "Réussi",
        "CONFIRM_DELETE": "Voulez-vous supprimer ce message?",
        "CONFIRM_DELETE_MULTI": "Voulez-vous supprimer ces messages?",
        "ALERT_SELECT_DELETE": "Veuillez sélectionner un message à supprimer",
        "ERROR_INPUT_RECEIVERLIST": "Veuillez choisir au moins un Driver pour envoyer un message",
        "ERROR_INPUT_CUSTOMER_RECEIVERLIST": "Veuillez choisir au moins un client pour envoyer un message",
        "ERROR_INPUT_SUBJECT": "Veuillez saisir le sujet",
        "ERROR_INPUT_CONTENT": "Veuillez saisir le contenu",
        "ERROR_INPUT_LEAST_ONE_SUBJECT_CONTENT": "Please set up at least one language to continue",
        "ERROR_INPUT_SUBJECT_MULTI_LANGUAGE": "Please input subject for {0} language",
        "ERROR_INPUT_CONTENT_MULTI_LANGUAGE": "Please input content for {0} language",
        "ERROR_INPUT_URL_MULTI_LANGUAGE": "Please input URL for {0} language",
        "ERROR_INPUT_TITLE_MULTI_LANGUAGE": "Please input title for {0} language",
        "ERROR_INPUT_BODY_MULTI_LANGUAGE": "Please input body for {0} language",
        "import_fail_success_driver": "%{success} driver(s) has been imported successfully, %{fail} failed.",
        "import_fail_success_passenger": "%{success} customer(s) has been imported successfully, %{fail} failed.",
        "import_fail_success_merchant": "%{success} merchant(s) has been imported successfully, %{fail} failed.",
        "CONFIRM_SEND_SUCCESS": "Votre message a été envoyé. Cela peut prendre plusieurs minutes. Veuillez patienter pendant que le système traite votre demande.",
        "Load_message_and_sms_fail": "Des erreurs sont survenues lors du chargement des détails de messages et SMS",
        "Update_message_and_sms_fail": "Des erreurs sont survenues lors de la mise à jour des détails de messages et SMS",
        "Update_message_and_sms_success": "Les détails des messages et des SMS ont bien été mis à jour",
        "Driver_List": "Liste de chauffeurs",
        "Car_Type_List": "Liste de catégories de véhicule",
        "Search_vehicle_type_name": "Search Vehicle Type Name",
        "ALERT_PROCESSING_DELETE": "Your request is being processed. This could take several seconds",
        "Customer": "Client",
        "Driver": "Chauffeurs",
        "StatusOfCustomer": "Statut du client",
        "StatusOfDriver": "Statut du conducteur",
        "StatusOfMerchant": "Status of merchant",
        "SendTo": "Send to",
        "DownloadReceiverList": "Download receiver list",
        "warningInputChangeOver": "Il y a un changement relatif de plus de 20% entre les 2 entrées récentes. Veuillez considérer!",
        "NoteDownloadReveverList": "Après 1 mois, le téléchargement de la liste des récepteurs sera désactivé.",
        "Load_voip_fail": "The errors occurred while loading VOIP details.",
        "Update_voip_success": "VOIP details has been updated successfully.",
        "Update_voip_fail": "The errors occurred while updating VOIP details.",
        "expiryDate": "Envoyer au nouvel utilisateur jusqu'à",
        "expiryDate_placeholder": "Expiry Date (mm/dd/yyyy)",
        "NA": "N/A",
        "PleaseSelectStatusOfDriver": "Please select status of driver",
        "versionApp": "Version de l'appli",
        "platform": "Platform",
        "contentType": "Type de contenu",
        "url": "URL",
        "title": "Titre",
        "contentId": "ContentId",
        "body": "Corps",
        "image_notification": "Image",
        "platformItem": {
            "all": "Tout",
            "iOS": "iOS",
            "android": "Android"
        },
        "comparisonVersionApp": {
            "is": "Est",
            "is_not": "N'est pas",
            "is_one_of": "Est l'un des",
            "is_not_one_of": "N'est pas l'un des",
            "greater_or_equal": "Supérieur ou égal",
            "less_or_equal": "Inférieur ou égal"
        },
        "contentTypeItem": {
            "html": "HTML",
            "link": "Link"
        },
        "Please_input": "This field is required"
    },
    "fleetInfo": {
        "Fleet_name": "Nom de la flotte",
        "Phone_number": "Numéro de téléphone",
        "Email_address": "Adresse e-mail",
        "Country": "Pays",
        "Time_zone": "Fuseau horaire",
        "Address": "Adresse",
        "Website": "Site Internet",
        "Currency": "Devise",
        "Distance_unit": "Unité de distance",
        "Load_fleet_info_fail": "The errors occurred while loading fleet info"
    },
    "surchargePolicy": {
        "Surchage_Policy": "Surcharge Policy",
        "SurgePolicy": "Surge Policy",
        "surgeType": "Surge Type",
        "bookingTimeCoverage": "Booking Time Coverage",
        "Delete_surcharge": "Are you sure you want to delete the selected surcharge?",
        "Delete_surcharge_item": "Are you sure you want to delete the selected surcharge item?",
        "Delete_surcharge_item_success": "Surcharge has been deactivated successfully",
        "Delete_surcharge_item_fail": "The errors occurred while deactivating surcharge item",
        "linkNewPolicy": "Link a new policy",
        "Link": "Link",
        "ViewPolicy": "View Policy",
        "Unlink": "Unlink",
        "Create_Surcharge_Item_success": "Surcharge item has been created successfully",
        "Create_Surcharge_Item_fail": "The errors occurred while creating surcharge item",
        "Update_Surcharge_Item_success": "Surcharge item has been updated successfully",
        "Update_Surcharge_Item_fail": "The errors occurred while updating surcharge item",
        "single": "Single Day",
        "yearly": "Yearly",
        "weekly": "Day of week",
        "Please_select_at_least_one_day": "Please select at least one day"
    },
    "penaltyPolicy": {
        "penaltyPolicy": "Penalty Policy",
        "Reservation": "Reservation",
        "onDemand": "On-demand",
        "noShow": "No Show",
        "freeCancellation": "Free cancellation buffer",
        "fieldExclusions": "Included Fields",
        "penaltyActive": "Cancel Duration Tier - before pickup time",
        "penaltyType": "Penalty Type",
        "Compensation": "Supplier/Driver Compensation",
        "Create_penalty_success": "Penalty template has been created successfully",
        "Create_penalty_fail": "The errors occurred while creating penalty template",
        "Update_penalty_success": "Penalty template has been updated successfully",
        "Update_penalty_fail": "The errors occurred while updating penalty template",
        "confirmDelete": "Confirm Delete",
        "confirmDeleteContent": "Are you sure you want to delete this penalty policy? Deleting this policy will unlink from all the booking rates.",
        "Delete_success": "Penalty template has been deleted successfully",
        "Delete_fail": "The errors occurred while deleting penalty template",
        "payoutPolicy": "Payout Policy",
        "commission": "Percentage of Customer Penalty",
        "commissionPayout": "Percentage of Supplier Payout",
        "fixAmount": "Amount",
        "amount": "Amount",
        "percent": "Percent",
        "percentPayout": "% of Payout ",
        "percentFare": "% of Fare",
        "penaltyFee": "Cancellation Fee",
        "commissionType": "Comp Type",
        "commissionFee": "Compensation",
        "tax": "Tax",
        "techFee": "Tech Fee",
        "minute": "minute",
        "hour": "hour"
    },
    "rateTemplates": {
        "Rate_Templates": "Rate Templates",
        "newRate": "New Rate Template",
        "placeHolderName": "Enter Rate Template Name",
        "Assign_Rate": "Assign Rate",
        "carTypeName": "Car Type Name",
        "appDisplayName": "App Display Name",
        "assign_rate_success": "Car Type has been assigned rate successfully",
        "assign_rate_fail": "The errors occurred while assigning rate",
        "confirmDeleteTitle": "Confirm Delete",
        "confirmDelete": "Are you sure you want to delete this template? Deleting this template will unlink from all the partners/corp it links to.",
        "setDefaultSuccess": "Default rate template has been set successfully",
        "setDefaultFail": "The errors occurred while setting default rate template",
        "Delete_rate_template_success": "Rate template has been deleted successfully",
        "Delete_rate_template_fail": "The errors occurred while deleting rate template",
        "setDefault": "Set as Default",
        "Regular": "Regular rate",
        "Sharing": "Street Sharing Rate",
        "Flat": "Flat Rate",
        "Hourly": "Hourly/ Daily",
        "Delete_default": "If you try to delete the default template, we should not allow till they set another default rate."
    },
    "supplierPlan": {
        "Supplier_Plan": "Supplier Plan",
        "rateTemplate": "Rate Schema",
        "rateTemplateNote": "the fare calculation used to determine the amount paid to the driver or supplier.",
        "percent": "Fleet Commission",
        "percentNote": " the percentage charged by the fleet on each booking.",
        "amount": "Fixed Amount",
        "amountNote": " the amount the fleet deducts for each booking.",
        "supplierPenaltyId": "Supplier Penalty Policy",
        "payoutType": "Type",
        "Select_penalty_policy": "Select Penalty Policy",
        "Select_supplier_plan": "Select Supplier Plan",
        "confirm_delete": "Are you sure you want to delete this plan? Deleting this plan will unlink from all the suppliers it links to and use the default.",
        "Create_supplier_plan_success": "Supplier plan has been created successfully",
        "Create_supplier_plan_fail": "The errors occurred while creating supplier plan",
        "Update_supplier_plan_success": "Supplier plan has been updated successfully",
        "Update_supplier_plan_fail": "The errors occurred while updating supplier plan",
        "Payout_Plan": "Payout Plan",
        "Payout_Plan_note": "Set up how much the supplier is paid out.",
        "default": "Default",
        "custom": "Custom Plan",
        "cannotSetPayout": "Cannot set payout plan for external partner’s driver."
    },
    "generalSetting": {
        "alertNotifications": {
            "title": "Alert notifications",
            "emails": "Emails",
            "emailsPlaceholder": "Enter email",
            "slack": "Slack Channel (Instructions)",
            "slackPlaceholder": "Slack POST URL",
            "discord": "Discord Channel (Instructions)",
            "discordPlaceholder": "Enter Discord Channel",
            "telegram": "Telegram Channel (Instructions)",
            "telegramPlaceholder": "Enter Telegram Channel"
        },
        "showFare": {
            "title": "Show fare to driver",
            "totalFare": "Total fare",
            "estimateGross": "Estimate gross earning"
        },
        "carHailing": "Street hailing",
        "regular": "Regular",
        "cancelTimeout": "Cancel the timeout bookings automatically",
        "allowStripePaymentLink": "Allow generate Stripe payment link",
        "flat": "Flat",
        "techFee": "Frais de technologie par transaction",
        "customizeLogo": "Customize dashboard logo",
        "logo": "Logo",
        "customerApp": "Customer App",
        "hourly": "Hourly",
        "shuttle": "Shuttle",
        "parcel": "Colis ",
        "freeCancelTime": "Free cancellation times per day",
        "AM": "AM",
        "PM": "PM",
        "RUSH_HOUR": "Surcharge",
        "Name": "Nom",
        "Date": "Date",
        "Mon": "Lun",
        "Tue": "Mar",
        "Wed": "Mer",
        "Thu": "Jeu",
        "Fri": "Ven",
        "Sat": "Sam",
        "Sun": "Dim",
        "Time_range": "Intervalle de temps",
        "From": "De",
        "To": "À",
        "Rush_hour_time_calculation": "Calcul d'heure de pointe",
        "Rush_hour_surcharge": "Heure de pointe",
        "Required_fields": "Champs requis",
        "Save": "Sauvegarder",
        "Cancel": "Annulé",
        "MDISPATCHER_CANCELLATION": "Annulation de réservation web/passagers/mDispatcher",
        "Book_now": "Réserver maintenant",
        "MDISPATCHER_CANCELLATION_TOOLTIP": "Pax / Partner / Web booking cancellation",
        "Book_now_toolTip": "Des frais d'annulation sont applicables si les réservations à la demande sont annulées dans les",
        "Book_later_toolTip": "Des frais d'annulation sont applicables si une réservation est annulée dans les avant l'heure de prise en charge",
        "Drv_Book_now_toolTip": "Cancellation fee is applicable if on-demand bookings get canceled within set time, from accept time",
        "Drv_Book_later_toolTip": "Cancellation fee is applicable if in advance bookings get canceled within set time, before expected pick-up time",
        "Free_cancel": "Free cancel duration",
        "Hide_cancel_btn": "Hide cancel button after set times",
        "Driver_cancellation": "Driver cancellation",
        "Time_day": "Times per day",
        "Duration": "Durée",
        "Times": "Fois",
        "Book_later": "Réserver plus tard",
        "Intercity": "Intercity",
        "Intercity_tooltip": "Cancellation fee is applicable if an intercity booking gets canceled within the set time, before pickup time.",
        "MDISPATCHER_NO_SHOW": "Non présentation",
        "AIRPORT_FEE": "Frais Aéroport",
        "From_airport": "De l'aéroport",
        "To_airport": "Vers l'aéroport",
        "MEET_DRIVER": "Rencontrez Driver",
        "On_curb": "Sur le trottoir",
        "Meet_Greet": "Accueil personnalisé",
        "Add": "Ajouter",
        "Edit": "Modifier",
        "DELETE_SURCHARGE": "Delete surcharge",
        "DELETE_SERVICE": "Delete service",
        "CHANGE_APPLY_SERVICE_FEES": "Change apply service fees",
        "DELETE_CONFIRM": "Do you want to delete this item?",
        "DELETE_SURCHARGE_CONFIRM": "Are you sure you want to delete the selected surcharge?",
        "DELETE_ADDITIONAL_SERVICES_CONFIRM": "Are you sure you want to delete the selected service?",
        "CHANGE_APPLY_SERVICE_FEES_CONFIRM_CUSTOM": "Êtes-vous sûr de demander des frais différents pour les zones séparées?",
        "CHANGE_APPLY_SERVICE_FEES_CONFIRM_ALL": "Êtes-vous sûr de vouloir appliquer les mêmes frais pour toutes les zones?",
        "EDIT_SERVICE_FEES": "Edit service fees",
        "EDIT_SERVICE_FEES_CONFIRM": "Êtes-vous sûr de vouloir partir sans enregistrer les paramètres de %{currentZone}?",
        "HEAVY_TRAFFIC": "Circulation dense",
        "Heavy_traffic_surcharge": "Supplément pour circulation dense",
        "TOLL_FEE": "Frais de péage",
        "parkingFee": "Parking fee",
        "gasFee": "Gas fee",
        "OTHER_FEES": "Autres frais",
        "Can_edit": "Capable de modifier",
        "Can_add_note": "Capable de Ajouter une note",
        "Other_fees_amount": "Montant des autres frais",
        "TAX": "Taxe",
        "Tax_surcharge": "Supplément de taxe",
        "TIP": "Tip",
        "Default_tip": "Pourboire par défaut",
        "RESERVATION_REMINDER": "Rappel de réservation",
        "Driver_app_SMS_alert_before": "Application Chauffeur - SMS & alerte avant",
        "notifyWhenDriverFailStartTrip": "Notify operator if driver fails to start trip before pickup time",
        "notifyWhenDriverFailStartTripTooltip": "Notifications will be displayed in the platform’s notifications section. You can also configure alerts to be sent via email, Slack, Discord, or Telegram for timely updates.",
        "Pax_app_alert_before": "Application utilisateur - alerter avant",
        "Confirm_driver_info_via_email_before": "Send email / sms booking confirmation to customer before",
        "Confirm_driver_info_via_email_before_hint": "The system will send out a booking confirmation to the customer based on your set time, before the pickup time.",
        "Show_request_tab_on_driver_app": "Show 'Request' tab on driver app",
        "Show_preferred_tab_on_driver_app": "Show 'Preferred' tab on driver app",
        "Sound_notification": "Notification sonore",
        "Advance_information": "Information avancée du voyageur d'entreprise",
        "Load_general_fail": "Des erreurs sont survenues lors du chargement des réglages généraux",
        "Update_general_success": "Les réglages généraux ont bien été mis à jour",
        "Update_general_fail": "Des erreurs sont survenues en mettant à jour les réglages généraux",
        "Time_over_lap": "Les intervalles de temps ne doivent pas se chevaucher les uns avec les autres",
        "Allow_passenger_to_register_as_corp_traveler": "Permettre au passager de s'inscrire comme voyageur de corp",
        "Not_Allow_Make_Booking_Without_Assign_Fare": "Ne pas permettre aux clients de faire une réservation si le tarif de base = 0.00",
        "Not_Allow_show_car_type_on_cc": "Do not show car type on CC if no fare assign",
        "Disable_Direct_Invoicing_Receipt_Email": "Désactiver l'e-mail de réception de facture directe",
        "Additional_services": "Additional Services",
        "Edit_additional_services": "Edit Additional Services",
        "Service_Fee_By_Currencies": "Frais de service",
        "Service_type": "Type de service",
        "Optional": "Facultatif",
        "Compulsory": "Obligatoire",
        "Vehicle_type": "Type de véhicule",
        "Adding_additional_service_success": "Add Service success",
        "Update_additional_service_success": "Update service success",
        "Delete_additional_service_success": "The service has been deleted successfully",
        "Adding_additional_service_fail": "Add Service fail",
        "Update_additional_service_fail": "Update service fail",
        "Other_settings": "Autres réglages",
        "Operator_assign_error": "Operator assign errors",
        "Create_surcharge_success": "Create surcharge success",
        "Update_surcharge_success": "Update surcharge success",
        "Delete_surcharge_success": "Delete surcharge success",
        "Create_surcharge_fail": "Create surcharge failed",
        "Update_surcharge_fail": "Update surcharge failed",
        "Delete_surcharge_fail": "Delete surcharge failed",
        "Get_surcharge_detail_fail": "Get surcharge detail failed",
        "Repeat": "Répéter",
        "DayOfWeek": "Jour de la semaine",
        "SingleDay": "Une seule fois",
        "Yearly": "Tous les ans",
        "Backup_phone_number": "Backup phone number",
        "Backup_phone_number_hint": "Direct to this number when driver and passenger are out of operation zones",
        "Apply_countdown_clock": "Apply countdown clock",
        "No_show_duration_tooltip": "Configurez l’intervalle de durée entre le moment où le chauffeur clique sur « Arrivé » et l’affichage du bouton « No Show »",
        "Limit_radius": "Rayon limite",
        "Limit_radius_tooltip": "Le conducteur peut signaler une réservation comme non-présentation dans ce rayon",
        "Limit_radius_arrive_pickup": "Rayon limite d'arrivée et de prise en charge du conducteur pour la réservation du transport",
        "Booking_validation": "Validation des réservations",
        "Invalid_period_of_time": "Période de temps invalide",
        "hide_Provider_Filter_From_Corporate_Board": "Cacher le filtre des fournisseurs au conseil d'administration de l'entreprise",
        "customer_email_is_optional": "L'email du client est facultatif sur l'application client",
        "disable_hourly_rate": "Désactiver le taux horaire lorsque le taux horaire et le taux fixe sont tous deux disponibles.",
        "customer_gender_is_compulsory": "Le numéro d'identification du client / numéro d'identification national est obligatoire sur l'application pour les clients.",
        "SHOW_MAP_STATUS": "Afficher l'état de la carte",
        "Pay_to": "Payer à",
        "EMERGENCY_SOS": "Emergency S.O.S",
        "customer_address_is_compulsory": "Le sexe du client est obligatoire sur l'application clients",
        "customer_id_is_compulsory": "Customer's ID# / National IC# is compulsory on passenger app",
        "allowPassengerToUploadDocument": "Permettre au client de télécharger le document ID# / National IC#.",
        "compulsoryNationalIcDocumentInPaxApp": "Customer's ID# / National IC# document is compulsory on Pax app",
        "doNotAllowAddDriverWhenNationalIdDuplicated": "Ne pas autoriser l'opérateur à ajouter un conducteur lorsque la carte d'identité nationale est dupliquée.",
        "forceCustomerToUseLiveLocation": "Obliger le client à utiliser la localisation en direct",
        "disableMarketPlace": "Disable Market Place for new drivers",
        "dontShowSecondStageImmediately": "Hide stage 2 signup",
        "compulsoryCarInsurance": "Driver's car insurance is compulsory",
        "marketPlace": "Market Place",
        "Enable": "Enable",
        "Disable": "Disable",
        "showTotalFareWithoutPromotionOnDriverApp": "Afficher le tarif total sans promotion sur l'application conducteur",
        "showTotalFareWithoutPromotionOnDriverApp_hint": "- Transport: apply this setting before driver makes payment. <br/> - Delivery & Intercity: apply this setting before driver start job.",
        "hideFareOnPaxAppForAllCorporateBookings": "Masquer le tarif sur l'application Pax pour toutes les réservations d'entreprise",
        "hidePaxNameAvatarOnDispatchScreen": "Masquer le nom et l'avatar du client sur l'écran d'expédition",
        "showFullCustomerNameOnDriverApp": "Show full customer name on Driver app",
        "hideDriverLogoutBtn": "Hide Driver logout button on Driver app",
        "showMeetAndGreetButtonForEveryBooking": "Show Meet & Greet button for every booking",
        "settings": "Réglages",
        "Default_value": "Valeur par défaut",
        "limitTopupAmount": {
            "valueByCurrencies": "Montant d'alerte",
            "minimumByCurrencies": "Montant minium pour recharger",
            "maximumByCurrencies": "Montant minium pour recharger",
            "title": "Montant minimum et maximum de rechargement"
        },
        "driverApp": "Application conducteur ",
        "passengerApp": "Application Clients",
        "Default_Topup_Values": "Valeurs de recharge par défaut",
        "service_fees": "Frais de service",
        "commission_fleet": "commission flotte",
        "apply_all": "La même chose pour toutes les zones d'opération",
        "apply_custom_zone": "Séparé dans chaque zone d'opération",
        "apply": "Appliquer",
        "tollFeeDriverCanInput": "Limiter le montant que le conducteur peut saisir",
        "unlimited": "Illimité ",
        "limited": "Limité ",
        "sameFee": "Même tarif",
        "customizedAirportfee": "Personnaliser les frais",
        "noteAirportFeeModal": "Note: Airport fee is only applied for airports that are on the list.",
        "intercity_schedule_trip_time_range": "To ensure the chronological order of booking time, please make sure the time-range of preferred/request trips is larger than the schedule trips",
        "airportFee": {
            "tooltipApply": "Default airport fee will be applied for each trip from / to airport by default. If airport fee is customized then new fee will be applied for that place.",
            "titleSearchAiportZone": "Please search your airport and add your fee",
            "airport": "Airport",
            "currency": "Devise",
            "fromAirportFee": "From airport fee",
            "toAirportFee": "To airport fee",
            "action": "Actions"
        },
        "Yes": "Oui",
        "No": "Non",
        "tipPaymentMethod": "Méthodes de paiement disponibles pour que le client fasse basculer son chauffeur après le trajet",
        "allowPaxUpdateTip": "Allow passenger update tip percent during ride ",
        "tipDefaultValues": "Astuce par défaut après le trajet",
        "creadit": "Credit card (Carte personnelle / Corporate card)",
        "wallet": "Portefeuille",
        "tnGeWallet": "TnG eWallet",
        "vippseWallet": "Vipps",
        "DurationRegularBooking": "Duration (for regular bookings)",
        "googlePay": "Google Pay",
        "applePay": "Apple Pay",
        "DurationRegularBookingToolTip": "\"No show\" button will be displayed in after the driver taps on \"Arrived\" button on Driver app.",
        "noShowCountDown": {
            "customizedNoShow": "Personnalisation pour des lieux spéciaux (aéroport, par exemple)",
            "customizedNoShowTitle": "Appliquer la durée du compte à rebours pour les lieux spéciaux (par exemple, un aéroport, etc.)"
        },
        "maxWaitTime": "Max extra wait time",
        "maxWaitTimeToolTip": "This is the maximum extra waiting time that will be charged to the customer. You have the flexibility to customize the per-minute fee in your rate settings.",
        "maxWaitTimePlaceholder": "Maximum charge wait time",
        "HEAT_MAP": "Carte de la demande",
        "heatMap": {
            "defaultMode": "Default mode",
            "radius": "Rayon",
            "intensity": "Intensité",
            "opacity": "Opacité",
            "requestedBookings": "Requested bookings",
            "activePassengers": "Active passengers",
            "completedBookings": "Modification du solde",
            "timeoutBookings": "Time out bookings",
            "tooltipRadius": "The radius of influence for each data point, in pixels.",
            "tooltipIntensity": "The maximum intensity of the heatmap.",
            "tooltipOpacity": "The opacity of the heatmap, expressed as a number between 0 and 1."
        },
        "schedule_trip": "Schedule trip",
        "driver_starts_trip_before_set_time": "Driver starts trip before set time",
        "request_trip": "Request trip",
        "preferred_trip": "Preferred trip",
        "time_range": "Time range for request and preferred trip",
        "working_time": "Working time",
        "hour": "hour",
        "hours": "hours",
        "minutes": "minutes",
        "indicate_pickup_time_in_advance_to_customer": "Indicate pickup time in advance to customer.",
        "customers_can_select_start_time_and_request_intercity_trip": "Customers can select start time and request intercity trip. Drivers also can create their preferred trip with their own schedule. Example: a time range of 1 hour will allow customers and drivers to create an intercity trip starts at 8:00 am - 9:00 am, 9:00 am - 10:00 am, or 10:00 am - 11:00 am.",
        "this_setting_limits_customers_to_create_request_trip_within_working_hours_only": "This setting limits customers to create request trip within working hours only.",
        "face_mask_verify": "Face mask verification",
        "faceMask_allZones": "All zones",
        "faceMask_customized": "Customized zone",
        "turn_on_verify": "Turn on verification",
        "force_to_wear_face_mask": "Force to wear face mask",
        "time_range_to_verify": "Do not verify again within",
        "customizedFaceMaskZone": "Customized face mask verification",
        "zoneOperation": "Operation Zone",
        "FaceMaskCustomized": "Personnalisé",
        "time": "time",
        "day": "day",
        "days": "days",
        "allow_driver_to_skip_verification_after": "Allow driver to skip verification after",
        "driver_need_to_verify": "When driver need to verify",
        "open_app": "Open app",
        "start_reservation": "Start reservation",
        "choose_at_least_one": "You must select at least one action to continue.",
        "face_mask_customized_fail": "You must select at least one action to continue ({0}).",
        "vehicleAgeLimit": "Limite d'âge des véhicules",
        "vehicleAgeLimit_years": "years",
        "founderFund_firstBooking": "Founder's Fund: first booking only",
        "distance_diversity": "Diversité des distances",
        "Add_Surcharge_Distance": "Ajouter",
        "from": "De ",
        "to": "À ",
        "surchargeValue": "Taxes ",
        "actions": "action",
        "please_enter_distance_range_valid": "Distance %{name} is invalid!",
        "please_enter_distance_to": "Please enter distance \"To\"",
        "please_enter_surchargeValue": "Please enter distance \"Surcharge\" for distance range %{name}",
        "please_enter_surchargeValue_is_number": "Please enter valid number \"Surcharge\" for distance range %{name}",
        "Update_service_fee_success": "Service fees has been updated successfully",
        "Update_service_fee_fail": "The errors occurred while updating service fees",
        "Update_fleet_commission_success": "Default fleet commision has been updated successfully",
        "Update_fleet_commission_fail": "The errors occurred while updating default fleet commission",
        "RESERVATION_POLICY": "Reservation policy",
        "default_expected_pickup_time": "Default expected pickup time",
        "default_expected_pickup_time_hint": "The default value you expect a driver to arrive from the pickup time.",
        "same_original_pickup_time": "Same as original pickup time",
        "quarter_before": "15 min before",
        "half_before": "30 min before",
        "quarter_after": "15 min after",
        "half_after": "30 min after",
        "custom": "Custom",
        "min_before": "min before",
        "min_after": "min after",
        "TransactionFee": "Transaction fee",
        "creditCardPercent": "Credit card/ prepaid",
        "directBilling": "Direct billing",
        "externalCard": "External Card"
    },
    "social": {
        "pax_app": "Passenger app",
        "driver_app": "Driver app",
        "default": "Default",
        "customized": "Customized",
        "html": "HTML",
        "about": "About",
        "email": "Email support",
        "enablePhone": "Phone number",
        "phone": "",
        "policy": "Link Privacy Policy",
        "termOfUse": "Link Terms of Use",
        "site": "Website",
        "logoDriverIOS": "Logo - IOS",
        "logoDriverAndroid": "Logo - Android",
        "htmlUrl": "HTML file",
        "Update_fail": "The errors occurred while updating social",
        "Update_success": "The social has been updated successfully"
    },
    "user": {
        "Show_per_page": "Eléments par page",
        "Page": "Page",
        "Search_here": "Rechercher ici",
        "show_pages": "{0} - {1} de {2} Affiche",
        "Username": "Nom D'utilisateur",
        "Name": "Nom",
        "Phone_number": "Numéro De Téléphone",
        "Email": "Email",
        "Permission": "Permission",
        "Status": "Statut",
        "Actions": "Actions",
        "Active": "Actif",
        "Inactive": "Inactif",
        "Edit": "Modifier",
        "Deactivate": "Désactiver",
        "Delete": "Effacer",
        "Add": "Ajouter",
        "ADD_USER": "Ajouter un utilisateur",
        "First_name": "Prénom",
        "Last_name": "Nom",
        "AgendId": "Utilisateur #",
        "Email_address": "Adresse e-mail ",
        "Address": "Adresse",
        "Required_fields": "Champs requis ",
        "Save": "Sauvegarder",
        "Cancel": "Annuler",
        "EDIT_USER": "Modifier l'utilisateur",
        "Yes": "Oui",
        "DELETE_USER": "Effacer l'utilisateur",
        "DETAIL_USER": "Infos utilisateur",
        "DELETE_CONFIRM": "Êtes-vous sûr de supprimer l'utilisateur?",
        "Update_user_fail": "Des erreurs sont survenues lors de la mise à jour de l'utilisateur",
        "Update_user_success": "L'utilisateur a bien été mis à jour",
        "Create_user_fail": "Des erreurs sont survenues lors de la création de l'utilisateur",
        "Create_user_success": "Le compte {0} a bien été créé. Veuillez vérifier le mail {1} pour les détails de certificat",
        "Load_user_fail": "Des erreurs sont survenues lors du chargement de l'utilisateur",
        "Update_user_status_fail": "Des erreurs sont survenues lors de la mise à jour du statut de l'utilisateur",
        "Delete_user_fail": "Des erreurs sont survenues lors de la suppression de l'utilisateur",
        "Delete_user_success": "L'utilisateur a bien été supprimé",
        "CONFIRM_RESET_PASSWORD": "Voulez-vous réinitialiser le mot de passe?",
        "RESET_PASSWORD": "Voulez-vous réinitialiser le mot de passe?",
        "Reset_password": " Réinitialiser le mot de passe",
        "Select_permission": "Sélectionnez Permission ",
        "Reset_password_success": "Réinitialisation de mot de passe effectuée"
    },
    "report": {
        "query": {
            "errorMessage": {
                "bookFrom": "Veuillez sélectionner le moyen de réservation",
                "paymentMethod": "Veuillez sélectionner un mode de paiement",
                "bookingType": "Veuillez sélectionner un type de réservation",
                "ratingType": "Veuillez sélectionner un type de tarification",
                "stars": "Veuillez sélectionner un type de tarification",
                "type": "Type",
                "transactionType": "Veuillez sélectionner un type de transaction",
                "serviceType": "Veuillez sélectionner un type de service",
                "rideService": "Please select ride service",
                "invalidEmail": "Veuillez spécifier un numéro de email valide"
            },
            "search": {
                "driverReferalPax": "Search: Driver Name, Phone Number",
                "passengerRefersPassengers": "Search: Customer Name, Phone Number",
                "driverReferalPaxDetail": "Search: Passenger Name",
                "bookingDetails": "Rechercher: Numéro de réservation, Opérateur, Téléphone client, Nom du client, Nom du chauffeur, ID/ Permis du chauffeur, Plaque d'immatriculation, Zone de file, Note",
                "financial": "Rechercher: Nom du chauffeur, Téléphone",
                "completedTime": "Temps de finalisation",
                "collectedTime": "Temps de collecte",
                "financialDriver": "Rechercher: Numéro de réservation, Nom du chauffeur, ID/ Permis du chauffeur",
                "carActivity": "Rechercher: Plaque d'immatriculation",
                "affiliation": "Rechercher: Numéro de réservation",
                "prepaidTopUp": "Rechercher: Nom de la compagnie, ID d'approvisionnement, ID transaction",
                "operatorLogs": "Rechercher: Nom de l'Opérateur, nom d'utilisateur",
                "corporate": "Rechercher: Numéro de réservation, Nom du chauffeur, Voyageur d'entreprise, Référence du client, Code de frais",
                "bookingLogs": "Rechercher: Numéro de réservation, Nom du client, Nom du chauffeur, ID/ Permis du chauffeur",
                "rating": "Rechercher: Numéro de réservation, Numéro du client, Nom du client",
                "mDispatcher": "Rechercher: Numéro de réservation, Nom du mDispatcher",
                "totalAdjustment": "Rechercher: Numéro de réservation",
                "incidentCancellation": "Rechercher: Numéro de réservation, Numéro du client, Nom du client",
                "acceptanceCancellationRate": "Search: Driver name, Phone number",
                "revenueDriver": "Rechercher: Nom du chauffeur",
                "dailyDriver": "Rechercher: Numéro du chauffeur, Nom du chauffeur, ID du chauffeur/Permis",
                "dailyCar": "Rechercher: Plaque d'immatriculation, Permission",
                "dailyDriverCar": "Rechercher: Numéro du chauffeur, Nom du chauffeur, ID du chauffeur/Permis, Plaque d'immatriculation",
                "driverLoginStatus": "Rechercher: Numéro du chauffeur, Nom du chauffeur, ID du chauffeur/Permis, Plaque d'immatriculation",
                "bookingSummary": "Rechercher: Nom de l'Opérateur, nom d'utilisateur, numéro de réservation",
                "creditWallet": "Rechercher: Nom de la compagnie, Driver name, Booking ID, Operator",
                "cashWallet": "Rechercher: Nom de la compagnie, Driver name, Booking ID, Phone number",
                "settlement": "Rechercher: Nom du chauffeur, ID/Permis du chauffeur",
                "driverRating": "Rechercher: Numéro de réservation, Numéro du client, Nom du client",
                "driverWithdrawal": "Driver name, Phone, Company, ID de la transaction, Reference #",
                "penaltyCompensation": "Search: Booking ID, Driver Name, Passenger Name",
                "incompletePayment": "Search: Booking ID, Support ID, Customer Name",
                "passengerWallet": "Search: Booking ID, Customer Name",
                "driverDeposit": "Rechercher: Nom de la compagnie, Driver name, Booking ID, Operator",
                "refund": "Search: Booking ID, Trip Id, Customer name",
                "driverRefersCustomerBookings": "Search: Referral code",
                "driverHistory": "Search: Payout ID",
                "merchantHistory": "Search: Payout ID",
                "firstWillWin": "Search: customer name, customer phone, customer ID",
                "firstWillWinDriver": "Search: driver name, driver phone, username",
                "merchant": "Search: store",
                "merchantCashTransactionHistory": "Search: Booking ID, ID de la transaction, Merchant name",
                "merchantCreditTransactionHistory": "Search: Booking ID, ID de la transaction, Merchant name",
                "deleteAccountRequest": "Search: Phone",
                "affiliationPayoutHistory": "Search: Payout ID",
                "thirdPartyBooking": "Search: Booking Id, External Id"
            },
            "common": {
                "all": "Tous"
            },
            "today": "Aujourd'hui",
            "sendDownloadLink": "Recipient Email Address",
            "generaltingYourExcel": "Please note, generating your excel file may take some time. We'll email it to you as soon as it's ready. ",
            "messageSuccess": "File in progress. You'll get an email when ready. Track progress or download directly from Reports/Export History",
            "thisMonth": "Ce mois-ci",
            "lastMonth": "Le mois dernier",
            "past30Days": "Depuis 30 jours",
            "thisYear": "Cette année",
            "custom": "Personnalisé",
            "customize": "Personnalisez",
            "thisWeek": "Cette semaine",
            "past7Days": "7 derniers jours",
            "weekly": "Hebdomadaire",
            "monthly": "Monthly",
            "allTime": "Toutle temps",
            "tomorrow": "Demain",
            "nextWeek": "Semaine prochaine",
            "lastWeek": "La semaine dernière",
            "nextMonth": "Mois prochain",
            "yesterday": "Hier",
            "fromDate": "A partir de la date",
            "toDate": "Jusqu'à",
            "driver": "Pilotes: %{name}",
            "customer": "Customer: %{name}",
            "referralCode": "Referral Code: %{name}",
            "referer": "Referer: %{name}",
            "referee": "Referee: %{name}",
            "usedPromoCode": "Promotion: %{name}",
            "voucherCode": "Code: %{name}",
            "city": "City",
            "company": "Compagnie",
            "company_report": "Compagnie: %{name}",
            "corporate_report": "Corporate: %{name}",
            "campaign_report": "rapport de campagne: %{name}",
            "operator": "Opérateur ",
            "farmType": "Type de ferme",
            "carLicensePlate": "Plaque d'immatriculation du véhicule: %{name}",
            "settlementType": "Type",
            "bookingService": "Network type",
            "payoutOption": "Payout option",
            "currency": "Devise",
            "bookingFrom": "Réservé de",
            "thirdPartyAction": "Action",
            "paymentMethod": "Mode de paiement",
            "paymentStatus": "Statut de paiement",
            "bookingType": "Type de réservation",
            "adjustPrice": "Adjust price",
            "intercityRoute": "Itinéraire interurbain",
            "ratingType": "Rating type",
            "stars": "Étoile",
            "driverRating": "Driver rating",
            "vehicleRating": "Vehicle rating",
            "mDispatcherType": "Type mDispatcher",
            "mDispatcher": "Dispatcher name",
            "campaign": "Campagne",
            "promocode": "Code promo",
            "canceledBy": "Canceled by",
            "timezone": "Fuseau horaire",
            "month": "Mois",
            "year": "Année",
            "view": "Voir",
            "action": "action",
            "status": "Statut",
            "module": "Module",
            "driverType": "Type",
            "vehicleType": "Type de véhicule",
            "serviceType": "Type de service",
            "expireDate": "Expiry date",
            "rideSharing": "Partage de course",
            "rideService": "Service de transport",
            "transactionType": "Type de transaction",
            "completeFromCC": "Complete from CC",
            "withDrawalTransactionType": "Type de transaction",
            "bookingStatus": "Statut de réservation",
            "withdrawalStatus": "Statut",
            "originalStatus": "État du paiement initial",
            "currentStatus": "État actuel du paiement",
            "tip": "Tip",
            "type": "Type",
            "userType": "User type",
            "eventType": "Event type",
            "eventState": "Event state",
            "eventName": "Event name",
            "merchantType": "Merchant type",
            "merchantService": "Merchant service",
            "storeName": "Store",
            "merchant": "Vendeur",
            "event": "Event name: %{name}",
            "store": "Store name: %{name}",
            "selectedMerchant": "Merchant: %{name}",
            "associatedApps": "Associated apps",
            "settlement": "Settlement",
            "rideServiceItem": {
                "all": "Tous",
                "regulation": "Règlement",
                "rideSharing": "Partage de course"
            },
            "actionItem": {
                "all": "Tous",
                "login": "S'identifier",
                "logout": "Se déconnecter",
                "bookIn": "Disponible",
                "bookOff": "Indisponible",
                "deny": "Refuser",
                "ignore": "Ignorer",
                "accept": "Accepter",
                "startTrip": "En route",
                "updateBooking": "Mettre à jour",
                "arrive": "Arrivé",
                "pickup": "Pris en charge",
                "drop": "Déposer",
                "complete": "Terminer",
                "noShow": "Non présentation",
                "rqJob": "En dispatch",
                "cancel": "Annuler"
            },
            "loginStatusItem": {
                "all": "Tous",
                "online": "Connexion",
                "offline": "Déconnecté"
            },
            "payoutType": "Payout type",
            "affiliationPayoutTypeItem": {
                "auto": "Auto payout",
                "manual": "Manual payout"
            },
            "affiliationSettlementItem": {
                "payable": "Payable",
                "receivable": "Receivable"
            },
            "affiliationPaymentMethod": {
                "online": "Online",
                "directInvoicing": "Direct invoicing"
            },
            "payoutPaymentStatusItem": {
                "paid": "Paid",
                "pending": "Pending"
            },
            "farmTypeItem": {
                "all": "Tous",
                "farmIn": "Ferme en",
                "farmOut": "Ferme hors",
                "roaming": "Roaming",
                "affiliationOwner": "Propriétaire de l'affiliation",
                "farmInAndAffiliationOwner": "Ferme en & propriétaire de l'affiliationr",
                "farmOutAndAffiliationOwner": "Ferme hors & propriétaire de l'affiliation"
            },
            "settlementDriverTypeItem": {
                "all": "Tous les Driver",
                "individual": "Conducteur individuel"
            },
            "shortTrip": {
                "all": "Tout",
                "shortTrip": "Short trip",
                "nonShortTrip": "Non-short trip"
            },
            "paymentMethodItem": {
                "B2BTerminal": "B2BTerminal",
                "webBooking": "Web booking",
                "pendingPayment": "Pending payment",
                "all": "Tous",
                "cash": "Paiement à bord",
                "cashBySender": "Payé par l'expéditeur",
                "cashByRecipient": "Cash par le destinataire",
                "personalCard": "Carte bleue",
                "corporateCard": "Carte d'entreprise",
                "qrCodePayment": "Paiement par code QR",
                "directBilling": "Facturation directe",
                "externalCard": "Carte externe",
                "mDispatcherCard": "Carte Concierge",
                "corporatePrepaid": "Payé via Wallet ",
                "canceled": "Annulé",
                "noShow": "Non présentation",
                "incident": "Incident",
                "fleetCard": "External card",
                "applePay": "Apple pay",
                "creditCardExternal": "Carte de crédit",
                "paxWallet": "Portefeuille",
                "tngeWallet": "TnG eWallet",
                "vippseWallet": "Vipps",
                "zainCash": "ZainCash",
                "xendit": "Xendit",
                "FIB": "FIB",
                "SantimPay": "SantimPay",
                "gCash": "GCash",
                "completedWithoutService": "Completed without driver",
                "BankOfGeorgia": "Bank Of Georgia",
                "partialCompleted": "Partial Completed",
                "failed": "Failed",
                "googlePay": "Google Pay",
                "paymentLink": "Payment Link",
                "easyPaisa": "Easy Paisa",
                "jazzCash": "Jazz Cash"
            },
            "paymentStatusItem": {
                "all": "Tout",
                "fullPayment": "Règlement de la totalité",
                "partialPayment": "Paiement partiel",
                "pendingPayment": "En attente de paiement",
                "partialRefund": "Remboursement partiel",
                "fullRefund": "Remboursement complet"
            },
            "companySettlementTypeItem": {
                "all": "All",
                "fleetOweCompany": "Fleet owe company",
                "companyOweFleet": "Company owe fleet"
            },
            "bookingStatusItem": {
                "all": "Tout",
                "completed": "Terminé",
                "incident": "Incident",
                "paxNoShow": "Pax no show",
                "driverNoShow": "Conducteur no show",
                "rejected": "Rejeter",
                "canceledByTimeOut": "Annulé par timeout ",
                "canceledByTimeOutRejected": "Canceled by timeout (rejected)",
                "canceledByBookingBoard": "Annulé par le tableau de réservation",
                "canceledByCustomer": "Canceled by customer",
                "canceledBySupplier": "Canceled by supplier"
            },
            "bookingFromItem": {
                "all": "Tous",
                "paxApp": "Pplication passager",
                "mDispatcher": "MDispatcher",
                "kiosk": "Kioske",
                "cc": "Centre de commandes",
                "webBooking": "Réservation en ligne",
                "carHailing": "Héler un véhicule",
                "streetSharing": "Street-sharing",
                "dashboard": "Booking board",
                "corp": "Booking board (CTM)",
                "dmc": "Booking board (DMC)",
                "Boost_web_link": "Boost web link"
            },
            "thirdPartyActionItem": {
                "all": "All",
                "searched": "Searched",
                "allocated": "Allocated",
                "updated": "Updated",
                "cancelled": "Cancelled"
            },
            "bookingTypeItem": {
                "batchDelivery": "Batch Delivery",
                "all": "Tous",
                "now": "Maintenant",
                "reservation": "Réservation",
                "intercity": "Intercity",
                "delivery": "Delivery",
                "shuttle": "Shuttle"
            },
            "adjustPrices": {
                "all": "All",
                "normalPrice": "Normal price",
                "addonPrice": "Addon price"
            },
            "bookingServiceItem": {
                "all": "Tous",
                "roaming": "Roaming",
                "homeFleet": "Ferme hors",
                "localBooking": "Ma flotte",
                "farmIn": "Ferme en",
                "farmInAndAffiliationOwner": "Ferme en & propriétaire de l'affiliation",
                "provideService": "Ferme en"
            },
            "ratingTypeItem": {
                "all": "Tout",
                "like": "Satisfait",
                "dislike": "Non satisfait"
            },
            "starsItem": {
                "all": "Tous",
                "star1": "1 Star",
                "star2": "2 Stars",
                "star3": "3 Stars",
                "star4": "4 Stars",
                "star5": "5 Stars"
            },
            "incidentCanceledTypes": {
                "all": "Tout",
                "incident": "Incident",
                "canceled": "Canceled"
            },
            "canceledByItem": {
                "all": "Tout",
                "CorpAD": "Admin Entreprise",
                "passenger": "Passager",
                "driver": "Client",
                "merchant": "Merchant",
                "CC": "CC",
                "mDispatcher": "MDispatcher",
                "Partner": "Partenaire",
                "webBooking": "Réservation en ligne",
                "timeout": "Timeout"
            },
            "bookingSummaryActionItem": {
                "all": "Tout",
                "create": "Créer",
                "update": "Mettre à jour",
                "cancel": "Annuler",
                "complete": "Terminer",
                "incident": "Incident"
            },
            "transactionTypeItem": {
                "bookingAdjustment": "Booking Adjustment",
                "bankAccount": "Transferred to bank account",
                "sentToCustomer": "Send to customer",
                "receivedFromDriver": "Received from driver",
                "sentToDriver": "Sent to driver",
                "creditWallet": "Transferred to credit wallet",
                "netEarning": "Net Earning",
                "referralEarning": "Referral Earning",
                "all": "Tout",
                "topUp": "Recharger",
                "editBalance": "Éditer le solde",
                "bookingDeduction": "Déduction de réservation",
                "bookingPromo": "Booking promotion",
                "topUpGCash": "Recharger par Gcash",
                "cashWallet": "Porte-monnaie",
                "topUpMOLPay": "Top up by Razer Pay",
                "topUpZainCash": "Top up by ZainCash",
                "topUpXendit": "Top up by Xendit",
                "topUpFIB": "Top up by FIB",
                "topUpSantimPay": "Top up by SantimPay",
                "topUpTelebirr": "Top up by Telebirr",
                "topUpKsher": "Top up by Ksher",
                "topUpWallet": "Top up by {0}",
                "TnG": "Top up by TnG",
                "credit": "Top up by credit card",
                "ride": "Ride",
                "tipAfterCompleted": "Tip après tour",
                "debtWriteOff": "Debt write-off",
                "refund": "Refund",
                "excessOffsetAmount": "Excess Cash Credit",
                "excessDeductionAmount": "Débit de trésorerie excédentaire",
                "cashExcess": "Débit de trésorerie excédentaire",
                "gateway": "Top up from gateway",
                "driverTopUpForPax": "Débit de trésorerie excédentaire",
                "updatedBalance": "Updated balance",
                "partialEarning": "Earning",
                "payout": "Payout manual",
                "payoutManual": "Payout manual",
                "payoutDriver": "Paiement ",
                "itemValue": "Item values",
                "redeemVoucher": "Redeem code #",
                "topUpReferrer": "Referral reward",
                "driverInsurance": "Driver Insurance",
                "cancellationPenalty": "Cancellation Penalty",
                "WingBank": "Transfers to Wingbank account",
                "customerDebt": "Customer Debt",
                "googlePay": "Top up by Google Pay",
                "applePay": "Top up by Apple Pay",
                "merchantCommission": "Merchant commission",
                "eWallet": "Transferred to external e-wallet",
                "reversed": "Withdrawal reversed"
            },
            "moduleItem": {
                "all": "Tout",
                "bookings": "Réservations",
                "customers": "Customers",
                "dispatch": "Dispatch",
                "general": "Général",
                "promotion": "Promotion",
                "quest": "Recherche",
                "rate": "Avis",
                "car": "Véhicule",
                "city": "Ville",
                "company": "Compagnie",
                "drivers": "Chauffeurs",
                "driverSettlement": "Règlement du conducteur",
                "login": "Connexion",
                "logout": "Logout",
                "mDispatcher": "mDispatcher",
                "mDispatcherType": "Type mDispatcher",
                "operation": "Opération",
                "partners": "Partenaires",
                "permission": "Permission",
                "queuingArea": "Zone de file d'attente",
                "report": "Rapport",
                "shift": "Horraires",
                "user": "Utilisateur",
                "voiceSms": "Voix et SMS",
                "intercityZone": "Zone (interurbain)",
                "Payout": "Payout",
                "code#": "Code #",
                "ApiManagement": "API management"
            },
            "serviceTypeItem": {
                "all": "Tous",
                "oneWay": "Aller simple",
                "fromAirport": "De l'aéroport",
                "toAirport": "À l'aéroport",
                "hourly": "Horaire/jour",
                "roundTrip": "Aller et retour",
                "fromAirportHourlyDaily": "Depuis l'aéroport - toutes les heures/quotidiennement",
                "fromAirportRoundTrip": "Depuis l'aéroport - aller-retour",
                "toAirportHourlyDaily": "Vers l'aéroport - toutes les heures/quotidiennement",
                "toAirportRoundTrip": "Vers l'aéroport - aller-retour"
            },
            "merchantTransactionTypeItem": {
                "all": "Tout",
                "editBalance": "Éditer le solde",
                "payout": "Paiement ",
                "earning": "Earning",
                "itemValue": "Item value",
                "orderDeduction": "Order deduction"
            },
            "withdrawalTransactionType": {
                "WingBank": "Transferred to Wingbank account",
                "bankAccount": "Transférer vers le compte bancaire",
                "cashWallet": "Transferred to cash wallet",
                "creditWallet": "Tranférer vers le porte-monnaie"
            },
            "payoutDriver": {
                "driversHaveBankAccount": "Drivers have bank account",
                "driversDontHaveBankAccount": "Drivers don't have bank account",
                "payoutEndTime": "Payout end time",
                "driverHaveInvalidBankAccount": "Drivers have invalid bank account"
            },
            "payoutMerchant": {
                "HaveBankAccount": "Merchants have bank account",
                "DontHaveBankAccount": "Merchants don't have bank account",
                "HaveInvalidBankAccount": "Merchants have invalid bank account",
                "payoutEndTime": "Payout end time"
            }
        },
        "error": {
            "23005": "For system performance you may only view reports in %{interval} %{unitLabel}, please adjust your date-range"
        },
        "result": {
            "idPermitType": "Type d'ID",
            "noResultFound": "Aucun résultat trouvé",
            "page": "Page",
            "itemsPerPage": "Eléments par page",
            "showPages": "Affiche %{from} - %{to} de %{total}",
            "exportToExcel": "Exporter vers Excel",
            "exportToCSV": "Export to csv",
            "exportToPaymentFile": "Export to payment file",
            "pay": "Payé",
            "paySelected": "Pay selected",
            "payAll": "Pay all",
            "invalidBankAccount": "Invalid bank account",
            "validBankAccount": "Valid bank account",
            "bookingDetails": {
                "supplierPayout": "Supplier payout",
                "driverPayout": "Driver payout",
                "profit": "Profit",
                "customerAdjustmentsFee": "Customer Adjustment",
                "supplierDriverAdjustmentsFee": "Supplier/Driver Adjustment",
                "successSendMessage": "Your message has been sent",
                "packageName": "Package Name",
                "transactionId": "ID de la transaction",
                "proofOfFailure": "Proof Of Failure",
                "recipientsNumber": "No. of recipient",
                "recipientsInfo": "Recipient info",
                "creditTransactionFee": "Frais de carte de crédit",
                "carTypeRequest": "Requested cartype",
                "bookingId": "Réservation #",
                "bookingStatus": "Booking status",
                "tripId": "Trip #",
                "bookFrom": "Réservé de",
                "bookingService": "Network type",
                "bookingType": "Type de réservation",
                "serviceType": "Type de service",
                "operator": "Opérateur",
                "customerPhone": "Numéro de téléphone du passager",
                "customerName": "Nom du passager",
                "customerIdNumber": "Passenger ID# / IC#",
                "corpDivision": "Division",
                "corpDept": "Département",
                "chargeCode": "Charge code",
                "tripDescription": "Trip description",
                "rideSharing": "Partage de course",
                "queueName": "Zone de file d'attente",
                "bookingTime": "Heure de réservation",
                "pickupTime": "Heure de prise en charge",
                "expectedPickupTime": "Expected Pickup Time",
                "dispatchTime": "Temps de dispatch",
                "onBoardTime": "Heure à bord",
                "droppedOffTime": "Heure d'arrivée",
                "onBoardDuration": "Durée à bord",
                "dispatch": "Dispatch #",
                "completedTime": "Heure de fin",
                "driverName": "Nom du Driver",
                "driverIdPermit": "ID# / National IC#",
                "driverLicenseNumber": "Numéro de permis de conduire",
                "companyName": "Compagnie",
                "carType": "Type de véhicule",
                "licensePlate": "Plaque d'immatriculation",
                "intercityRoute": "Itinéraire interurbain",
                "pickupLocation": "Lieu de prise en charge",
                "geoPickup": "Lat/long de l'emplacement de prise en charge",
                "extraDestination": "Extra destinations",
                "originDestination": "Destination d'origine",
                "originEstDistance": "Distance originale estimée",
                "destination": "Destination",
                "geoDestination": "Lat/long de l'emplacement de prise en charge",
                "pickupFrom": "Emplacement de prise en charge de",
                "distanceTour": "Distance Passager A Bord",
                "internalNotes": "Internal notes",
                "notes": "Remarques",
                "basicFareCalculator": "Calculateur du prix de base",
                "basicFare": "Tarif de base",
                "editFare": "Editer le prix de base",
                "oldBasicFare": "Ancien prix de base",
                "airportFee": "Frais d'aéroport",
                "meetDriver": "Meet driver",
                "rushHour": "Heure de pointe",
                "surchargeParameter": "Supplément dynamique",
                "dynamicFare": "Tarif dynamique",
                "heavyTraffic": "Circulation dense",
                "tollFee": "Frais de péage",
                "parkingFee": "Parking fee",
                "gasFee": "Gas fee",
                "otherFees": "Autres frais",
                "subtotal": "Sous-total",
                "subTotalFF": "Subtotal FF",
                "techFee": "Frais de Technique",
                "serviceFee": "Add'l services",
                "fleetServiceFee": "Services de flotte",
                "bookingFee": "Frais de réservation",
                "salesTax": "TVA",
                "tipAmt": "Montant du pourboire",
                "promoCode": "Code promo",
                "promoAmt": "Montant de la promo",
                "requestedTotalAmount": "Provider Price",
                "total": "Montant total",
                "totalNotRounded": "Tour (amt total)",
                "actualReceived": "Montant réel reçu",
                "differenceAmt": "Difference (received)",
                "customerDebt": "Customer Debt",
                "markupDifference": "Markup difference",
                "receivedWhenDropOff": "Reçu au moment de la livraison",
                "refundAmount": "Montant remboursé",
                "requestedPaymentMethod": "Mode de paiement demandé",
                "paidBy": "Payé par",
                "splitPayment": "Split payment",
                "paidByWallet": "Paid by wallet",
                "paidByOtherMethod": "Paid by other method",
                "cashReceived": "Cash received",
                "transferredChangeByWallet": "Transfer change by wallet",
                "returnedChangeByCash": "Return change by cash",
                "instructions": "Instructions",
                "additionalServiceName": "Nom",
                "additionalServiceValue": "Value",
                "sendReceiptHeader": "",
                "sendReceipt": "Envoyer facture",
                "send": "Envoyer",
                "cancel": "Annuler",
                "sentReceiptSuccess": "La facture a bien été envoyée",
                "sentReceiptFailed": "La facture ne peut pas être envoyée",
                "coRider": "Co-rider",
                "coriderName": "Nom",
                "stopAddress": "Additional Destination",
                "stopGeo": "Lat/long",
                "addonPrice": "Customer adjust price",
                "oldSubTotal": "Subtotal before adjust",
                "view_good_receipt": "Reçu de colis",
                "view_delivery_receipt": "Photo de livraison",
                "view": "Voir",
                "tipAfterCompletedDetails": "Transferred: {0}{1}; Transaction fee: {0}{2}",
                "fleetMarkup": "Profit",
                "editedDriverEarning": "Edited driver earning",
                "driverEarning": "Driver earning",
                "supplierAmount": "Supplier earning",
                "currencyISO": "Currency",
                "pickUpZoneName": "Pickup zone",
                "dynamicFactor": "Dynamic fare (factor)",
                "supplierEarning": "Supplier earning",
                "passengerName": "Passenger name",
                "totalPayout": "Total payout",
                "numberOfTrips": "Number of trips"
            },
            "financial": {
                "supplierPayout": "Supplier payout",
                "driverPayout": "Driver payout",
                "profit": "Profit",
                "currencyISO": "Currency",
                "supplierAmount": "Supplier earning",
                "driverEarning": "Driver earning",
                "supplierEarning": "Supplier earning",
                "passengerName": "Passenger name",
                "totalPayout": "Total payout",
                "numberOfTrips": "Number of trips",
                "driverName": "Nom du Driver",
                "driverPhone": "Numéro de téléphone",
                "driverUserName": "Nom d'utilisateur",
                "companyName": "Compagnie",
                "bookingList": "Liste de réservations",
                "totalFare": "Prix total sans la promo",
                "netEarning": "Résultat net",
                "showBookingList": "Montrer",
                "referralEarning": "Gain par parrainage",
                "totalEarningNet": "Total des gains (net)",
                "totalEarningNetTooltip": "Money you earned after returning all fees to fleet. Total earning (net) = Net earning + Tip after ride",
                "totalEarningNetTooltipRefferal": "Money you earned after returning all fees to fleet. Total earning (net) = Referal earning + Net earning + Tip after ride",
                "totalEarningGross": "Revenu total (brut)",
                "totalEarning": "Total earning",
                "totalEarningGrossTooltip": "Money you earned from booking fare and tips. Total earning (gross) = Gross earning + Tip after ride",
                "totalEarningGrossTooltipRefferal": "Money you earned from booking fare and tip. Total earning (gross) = Referal earning + Gross earning + Tip after ride",
                "note": "Note",
                "shortTrip": "Short trip",
                "deduction": "Deduction",
                "totalDriverEarning": "Total driver earnings",
                "totalDriverEarningTooltip": "Includes bookings with both online and offline payment",
                "totalDriverPayout": "Total driver payout",
                "totalDriverPayoutTooltip": "Does not include bookings with cash or offline payment"
            },
            "financialDriver": {
                "supplierAmount": "Supplier commission",
                "payoutType": "Payout type",
                "date": "Date",
                "driverTipAfterCompleted": "Tip après tour",
                "bookingId": "Réservation #",
                "paidBy": "Payé par",
                "bookFrom": "Réservé de",
                "bookingType": "Type de réservation",
                "companyName": "Compagnie",
                "driverName": "Chauffeurs",
                "driverIdPermit": "CNI du chauffeur / permis de conduire",
                "totalFare": "Prix total sans la promo",
                "subtotal": "Sous-total",
                "airportFee": "Frais d'aéroport",
                "meetDriver": "Accueil",
                "tollFee": "Frais de péage",
                "parkingFee": "Parking fee",
                "gasFee": "Gas fee",
                "rushHour": "Rush hour",
                "transactionFee": "Frais de transaction",
                "techFee": "Frais de Technique",
                "salesTax": "TVA",
                "tipAmt": "Montant du pourboire",
                "bookingFee": "Frais de réservation",
                "commission": "Commission",
                "deduction": "Déduction",
                "promoAmt": "Montant de la promo",
                "ridePayment": "Paiement de la course",
                "driverTax": "Driver tax",
                "grossEarning": "Revenus bruts",
                "driverDeduction": "Driver deduction",
                "netEarning": "Résultat net",
                "payToDriver": "Payez au conducteur",
                "payToFleet": "Pay to fleet",
                "totalNotRounded": "Tour (amt total)",
                "fleetServiceFee": "Frais de service de la flotte",
                "editedDriverEarning": "Edited driver earning",
                "stripeFee": "Stripe Fee",
                "currencyISO": "Currency"
            },
            "financialCompany": {
                "companyName": "Compagnie",
                "homeFleetNetEarning": "Gain net total de la flotte",
                "affiliateNetEarning": "Gain Net de l'Affilié",
                "totalNetEarning": "Total pilotes net des gains",
                "status": "Statut",
                "companyAmount": "Company profit",
                "numberOfTrips": "Number of trips"
            },
            "companySettlement": {
                "companyName": "Company",
                "pendingSettlement": "Pending settlement",
                "viewDetails": "View details",
                "settlementType": "Type"
            },
            "companySettlementDetails": {
                "bookId": "Booking ID",
                "dateTime": "Date time",
                "originalPaymentStatus": "Original payment status",
                "subTotal": "Subtotal",
                "fleetAmount": "Fleet amount",
                "driverAmount": "Driver amount",
                "companyAmount": "Company amount",
                "companyOweFleet": "Company owe fleet",
                "fleetOweCompany": "Fleet owe company",
                "notes": "Notes"
            },
            "financialProfit": {
                "profit": "Bénéfices",
                "bookingId": "Réservation #",
                "subTotal": "Total",
                "subTotalFF": "Subtotal FF",
                "fleetCommission": "Commission de la flotte",
                "techFee": "Frais techniques",
                "promotion": "Promotion",
                "markupDifference": "Markup difference",
                "Profit": "Profit",
                "currency": "Devise",
                "fleetServiceFee": "Service de flotte (prise de flotte)",
                "fleetServiceFeeTooltip": "Amount fleet takes commission from service fees"
            },
            "promotion": {
                "date": "Date",
                "promoCode": "Code promo",
                "users": "# Utilisateurs",
                "uses": "# Utilisations",
                "checked": "Checked",
                "applied": "Applied",
                "totalPromoAmount": "Total du montant des promos",
                "totalReceipt": "Total du montant des factures with promo excluded",
                "numberOfUsers": "Nombre d'utilisateurs",
                "numberOfPromoCodeAppliance": "Nombre de code promo",
                "numberOfSuccessfulBookings": "Nombre de réservations réussies",
                "numberOfCompletedBookings": "Nombre de réservations finalisées",
                "numberOfCompletedUsers": "Nombre d'utilisateurs ayant terminé",
                "numberOfUsersApplyPromotion": "Nombre d'utilisateurs appliquant la promotion",
                "numberOfUsersApplyPromotionAndCompleteBooking": "Nombre d'utilisateurs appliquant la promotion et finalisant la réservation",
                "numberOfPromoCodeApplianceTooltip": "Nombre de codes promotionnels utilisés par tous les utilisateurs",
                "numberOfSuccessfulBookingsTooltip": "Nombre de réservations créées avec succès avec le code de promotion",
                "numberOfCompletedBookingsTooltip": "Nombre de réservations effectuées avec application du code promotionnel"
            },
            "export": {
                "createdDate": "Date",
                "sessionName": "Session Name",
                "download": "Download",
                "delete": "Delete",
                "percent": "Percent",
                "status": "Status",
                "name": "Module name"
            },
            "redeemed": {
                "date": "Date",
                "code": "Code",
                "customers": "Customer",
                "phone": "Phone number",
                "value": "Value",
                "currency": "Currency",
                "campaignName": "Campaign"
            },
            "carActivity": {
                "plateNumber": "Plaque d'immatriculation du véhicule",
                "vehicleType": "Type de voiture",
                "companyName": "Compagnie",
                "day1st": "1er",
                "day2nd": 2,
                "day3rd": 3,
                "day4th": 4,
                "day5th": 5,
                "day6th": 6,
                "day7th": 7,
                "day8th": 8,
                "day9th": 9,
                "day10th": 10,
                "day11th": 11,
                "day12th": 12,
                "day13th": 13,
                "day14th": 14,
                "day15th": 15,
                "day16th": 16,
                "day17th": 17,
                "day18th": 18,
                "day19th": 19,
                "day20th": 20,
                "day21st": 21,
                "day22nd": 22,
                "day23rd": 23,
                "day24th": 24,
                "day25th": 25,
                "day26th": 26,
                "day27th": 27,
                "day28th": 28,
                "day29th": 29,
                "day30th": 30,
                "day31st": 31,
                "operationDays": "Jours travaillés",
                "activeCars": "Total des véhicules actifs:"
            },
            "carActivityLogsTitle": "Logs",
            "carActivityLogs": {
                "licensePlate": "Plaque d'immatriculation du véhicule",
                "companyName": "Compagnie",
                "bookingId": "Réservation #",
                "driverName": "Nom du Driver",
                "driverIDNumber": "ID du chauffeur",
                "date": "Heure d'acceptation",
                "bookFrom": "Réservé de"
            },
            "affiliation": {
                "date": "Heure de prise en charge",
                "bookingId": "Réservation #",
                "bookingType": "Type de réservation",
                "homeFleet": "Ma flotte",
                "providerFleet": "Fournisseur de la flotte",
                "driver": "Chauffeurs",
                "passenger": "Passager",
                "paidBy": "Payé par",
                "airportFee": "Frais Aéroport",
                "meetDriver": "Rencontre avec le chauffeur",
                "rushHour": "Heure de pointe",
                "tollFee": "Frais de péage",
                "parkingFee": "Parking fee",
                "gasFee": "Gas fee",
                "heavyTraffic": "Circulation dense",
                "otherFees": "Autres frais",
                "provider": "Fournisseur",
                "basicFareProvider": "Basic fare provider",
                "subTotalProvider": "Sub total provider",
                "techFeeProvider": "Tech fee provider",
                "bookingFeeProvider": "Booking fee provider",
                "taxValueProvider": "Tax value provider",
                "tipProvider": "Tip provider",
                "totalFareProvider": "Total fare provider",
                "basicFare": "Tarif de base",
                "subtotal": "Sous-total",
                "techFee": "Frais de Technique",
                "bookingFee": "Frais de réservation",
                "tax": "Taxe",
                "tip": "Pourboire",
                "totalFare": "Total fare",
                "payoutInLocalCurrency": "Ride earning in local currency",
                "payoutInUSD": "Ride earning in USD",
                "fleetCommInUSD": "Fleet comm in USD",
                "bookingFeeInUSD": "Booking fee in USD",
                "totalPayoutInUSD": "Total payout in USD",
                "subTotal": "Subtotal",
                "bookingStatus": "Statut de réservation",
                "vehicle": "Vehicle",
                "customerPhone": "Customer phone number",
                "driverPhone": "Driver phone number",
                "total": "Total",
                "totalInUSD": "Total fare (USD)",
                "supplierPayout": "Supplier payout",
                "supplierPayoutInUSD": "Supplier payout (USD)",
                "buyerPayout": "Buyer commission",
                "buyerPayoutInUSD": "Commission (USD)",
                "buyerCommission": "Commission",
                "buyerCommissionInUSD": "Commission (USD)",
                "receivableInUSD": "Receivable (USD)",
                "payableInUSD": "Payable (USD)",
                "fleetMarkupInUSD": "Profit (USD)",
                "paymentMethod": "Payment method",
                "paymentStatus": "Payment status"
            },
            "prepaidTopUp": {
                "date": "Date & heure",
                "topUpId": "Numéro de réapprovisionnement",
                "transactionId": "ID de la transaction",
                "name": "Nom",
                "company": "Compagnie",
                "paidBy": "Payé par",
                "amount": "Montant",
                "newAmount": "Nouveau solde ",
                "transactionType": "Transaction type"
            },
            "operatorLogs": {
                "date": "Date",
                "operator": "Opérateur",
                "username": "Nom d'utilisateur",
                "module": "Module",
                "description": "Description"
            },
            "corporate": {
                "date": "Date",
                "bookingId": "Réservation #",
                "operatorName": "Corp operator",
                "corporateName": "Compagnie",
                "corpTraveler": "Voyageur entreprise",
                "travelerSignature": "Signature du voyageur",
                "corpId": "Numéro d'entreprise",
                "corpDivision": "Division d'entreprise",
                "costCentre": "Centre de coûts",
                "corpPO": "Corp PO",
                "managerName": "Nom du gestionnaire",
                "managerEmail": "Mail du directeur",
                "title": "Titre",
                "department": "Département",
                "clientCaseMatter": "Affaire client/ Description du voyage",
                "chargeCode": "Code de charge/  Code de voyage",
                "driverName": "Chauffeurs",
                "pickupLocation": "Lieu de prise en charge",
                "destination": "Destination",
                "total": "Montant total",
                "subtotal": "Sous-total",
                "subTotalFF": "Subtotal FF",
                "commission": "Commission",
                "commissionBooking": "Appliquer une commission pour les réservations effectuées à partir de l'application",
                "paidBy": "Payé par",
                "statusDisplay": "Statut",
                "flightNumber": "Flight number"
            },
            "bookingLogs": {
                "bookingId": "Réservation #",
                "bookingService": "Network type",
                "bookingType": "Type de réservation",
                "driverName": "Chauffeurs",
                "driverIdPermit": "CNI du chauffeur / permis de conduire",
                "customerName": "Passager",
                "pickupTime": "Heure de prise en charge",
                "onBoardTime": "Heure à bord",
                "droppedOffTime": "Heure d'arrivée",
                "pickupLocation": "Lieu de prise en charge",
                "destination": "Destination",
                "dispatchHistory": "Historique du dispatch",
                "otwDistance": "O.T.W distance",
                "distanceTour": "Distance",
                "duration": "Durée",
                "paidBy": "Payé par",
                "bookFrom": "Réservé de",
                "map": "Carte",
                "showDispatchHistory": "Montrer",
                "showMap": "Montrer",
                "paymentActivities": "Payment activities"
            },
            "rating": {
                "date": "Date",
                "bookingId": "Réservation #",
                "driverName": "Chauffeurs",
                "driverIdPermit": "CNI du chauffeur / permis de conduire",
                "customerName": "Nom du passage",
                "customerPhone": "Numéro de téléphone du passager",
                "like": "Satisfait",
                "dislike": "Non satisfait",
                "notes": "Commentaire",
                "stars": "Évaluation",
                "vehicleRating": "Vehicle Rating",
                "driverRating": "Driver Rating",
                "plateNumber": "Numéro de plaque"
            },
            "mDispatcher": {
                "date": "Date",
                "bookingId": "Réservation #",
                "mDispatcherName": "Dispatcher name",
                "mDispatcherType": "Type mDispatcher",
                "subtotal": "Sous-total",
                "commission": "Commission",
                "paidBy": "Payé par"
            },
            "totalAdjustment": {
                "date": "Date",
                "bookingId": "Réservation #",
                "oldAmount": "Ancien montant",
                "type": "Type",
                "markupDifference": "Markup difference",
                "newAmount": "Nouveau montant",
                "username": "Nom d'utilisateur",
                "reason": "Raison"
            },
            "incidentCancellation": {
                "date": "Date",
                "bookingId": "Réservation #",
                "customerName": "Nom du passage",
                "customerPhone": "Numéro de téléphone du passager",
                "bookFrom": "Réservé de",
                "canceledBy": "Annulé par",
                "type": "Type",
                "reason": "Raison",
                "reasonCode": {
                    "0": "J'ai trouvé un autre moyen de déplacement",
                    "1": "Chauffeur trop éloigné",
                    "2": "J'ai entré une adresse eronnée",
                    "3": "J'ai attendu trop longtemps",
                    "4": "Le chauffeur a annulé",
                    "5": "I changed my plans",
                    "6": "I wanted to change location",
                    "101": "Most or all items are unavailable",
                    "102": "We're too busy right now",
                    "103": "We're closed right now",
                    "104": "We're closing soon"
                },
                "canceller": "Annulé par"
            },
            "acceptanceCancellationRate": {
                "companyId": "Company Id",
                "driverId": "Driver Id",
                "driverName": "Driver name",
                "phoneNumber": "Numéro de téléphone",
                "company": "Entreprise",
                "acceptanceRate": "Taux d'acceptation",
                "cancellationRate": "Taux d'annulation",
                "rating": "Étoile"
            },
            "revenueFleet": {
                "date": "Date",
                "fareRevenue": "Tarif de revenu",
                "trip": "# Course",
                "avgFareTrip": "Tarif Moyen/ course",
                "drivers": "# Chauffeurs",
                "avgTripPerDriver": "Course moyenne/ Driver",
                "dispatcher": "# Dispatcher"
            },
            "revenueDriver": {
                "date": "Date",
                "driverName": "Chauffeurs",
                "fareRevenue": "Tarif de revenu",
                "trip": "# Course",
                "avgFareTrip": "Tarif Moyen/ course"
            },
            "revenueMonthly": {
                "month": "Mois",
                "trip": "Trajets terminés",
                "subtotal": "Sous-total",
                "tipAmt": "Pourboire",
                "techFee": "Frais de Technique",
                "salesTax": "Taxe",
                "partnerCommission": "Commission du partenaire/ ODD",
                "totalFare": "Prix total sans la promo",
                "netEarning": "Résultat net",
                "fleetServiceFee": "Fleet service fees"
            },
            "dailyDriver": {
                "driverName": "Nom du Driver",
                "driverIDPermit": "CNI du chauffeur / permis de conduire",
                "driverPhone": "Numéro de téléphone",
                "companyName": "Compagnie",
                "workingHoursOnline": "En ligne (h)",
                "workingHoursAvailable": "Disponible (h)",
                "driverWorkingHours": "Heures de travail du conducteur",
                "distance": "Distance parcourue par le conducteur",
                "CC_activity": "Activité CC",
                "fare_summary": "Résumé des tarifs",
                "workingHoursNotAvailable": "Non disponible (h)",
                "workingHoursPob": "Passager A Bord (h)",
                "workingHoursPobToolTip": "Passenger on board duration",
                "workingHoursAccepted": "Accepté",
                "workingHoursReject": "Refuser",
                "workingHoursIgnore": "Ignorer",
                "workingHoursCancel": "Annuler",
                "completedTripsTotal": "Total",
                "completedTripsCc": "Du Centre de Commandes",
                "completedTripsCarHailing": "Hélé dans la rue",
                "completedTripsStreetSharing": "From Street Sharing",
                "completedTripsPaxApp": "De l'application client",
                "completedTripsWebBooking": "De la réservation web",
                "completedTripsMDispatcher": "De mDispatcher",
                "completedTripsKiosk": "Depuis le Kioske",
                "completedTripsApi": "Depuis l'API",
                "ccActivityDispatchedTrips": "Trajets dispatchés",
                "ccActivityAccepted": "Accepté",
                "ccActivityReject": "Refuser",
                "ccActivityIgnore": "Ignorer",
                "ccActivityCancelByPassenger": "Annulé par passager",
                "ccActivityCancelByDriver": "Annulé par le chauffeur",
                "ccActivityCancelByCc": "Annulé par CC",
                "ccActivityCancelByAPI": "Annulé par l'API",
                "ccActivityNoShow": "Non présentation",
                "fareSummaryTotalCash": "Paiement à bord",
                "fareSummaryTotalCreditCard": "Carte de crédit",
                "fareSummaryTotalExternalCard": "Carte externe",
                "fareSummaryTotalPaxWallet": "Portefeuille",
                "fareSummaryTotalDirectBilling": "Driect invoicing",
                "fareSummaryTotalQrCode": "QR code",
                "fareSummaryTotalPrePaid": "Portefeuille Entreprise",
                "fareSummaryTotalApplePay": "Apple pay",
                "fareSummaryTotalTngeWallet": "TNG eWallet",
                "fareSummaryTotalVippseWallet": "Vipps",
                "fareSummaryTotal": "Total",
                "ratingNotes": "Nombre de notes",
                "ratingDislike": "Nombre de \"je n'aime pas\"",
                "ratingLike": "Nombre de \"j'aime\"",
                "fareDetailsBasicFare": "Tarif de base",
                "fareDetailsAirportFee": "Frais d'aéroport",
                "fareDetailsRushHour": "Heure de pointe",
                "fareDetailsHeavyTraffic": "Heavy traffic",
                "fareDetailsOtherFees": "Autres frais",
                "fareDetailsSubTotal": "Sous-total",
                "fareDetailsSubTotalFF": "Subtotal FF",
                "fareDetailsTechFee": "Frais de Technique",
                "fareDetailsBookingFee": "Frais de réservation",
                "fareDetailsSalesTax": "Taxe",
                "fareDetailsTip": "Montant du pourboire",
                "fareDetailsPromoAmount": "Montant de la prom",
                "fareDetailsTotalAmt": "Montant total",
                "availableDistance": "Disponible ",
                "otwDistance": "Sur le chemin",
                "otwDistanceToolTip": "On the way distance",
                "pobDistance": "P.O.B",
                "pobDistanceToolTip": "Passenger on board distance",
                "ratingStars1": "No. 1 Étoile",
                "ratingStars2": "No. 2 Étoile",
                "ratingStars3": "No. 3 Étoile",
                "ratingStars4": "No. 4 Étoile",
                "ratingStars5": "No. 5 Étoile",
                "ratingAvg": "Moyenne",
                "ssnNumber": "SSN #",
                "driverEmail": "Adresse e-mail",
                "driverAddress": "Adresse du domicile",
                "fareDetailsAddonPrice": "Customer adjust price",
                "fareDetailsOldSubTotal": "Subtotal before adjust"
            },
            "dailyCar": {
                "carInfo": "Info véhicule",
                "fareDetails": "Détails du tarif",
                "completedTrips": "Trajets terminés",
                "carInfoCompanyName": "Compagnie",
                "carInfoPermission": "Autorisation",
                "carInfoVehiclePlateNumber": "Plaque d'immatriculation",
                "carInfoVhcId": "Voiture #",
                "carInfoCarType": "Type de voiture",
                "completedTripsTotal": "Total",
                "completedTripsCc": "Du Centre de Commandes",
                "completedTripsCarHailing": "Hélé dans la rue",
                "completedTripsStreetSharing": "Du partage de la rue",
                "completedTripsPaxApp": "De l'application client",
                "completedTripsWebBooking": "De la réservation web",
                "completedTripsMDispatcher": "De mDispatcher",
                "completedTripsKiosk": "Depuis le Kioske",
                "completedTripsAPI": "Depuis l'API",
                "fareDetailsBasicFare": "Tarif de base",
                "fareDetailsAirportFee": "Frais d'aéroport",
                "fareDetailsMeetDriver": "Rencontre avec le chauffeur",
                "fareDetailsRushHour": "Heure de pointe",
                "fareDetailsHeavyTraffic": "Circulation dense",
                "fareDetailsTollFee": "Frais de péage",
                "fareDetailsParkingFee": "Parking fee",
                "fareDetailsGasFee": "Gas fee",
                "fareDetailsOtherFees": "Autres frais",
                "fareDetailsSubTotal": "Sous-total",
                "fareDetailsSubTotalFF": "Subtotal FF",
                "fareDetailsTechFee": "Frais de Technique",
                "fareDetailsBookingFee": "Frais de réservation",
                "fareDetailsSalesTax": "Taxe",
                "fareDetailsTip": "Montant du pourboire",
                "fareDetailsPromoAmount": "Montant de la promo",
                "fareDetailsTotalAmt": "Montant total",
                "fareDetailsAddonPrice": "Customer adjust price",
                "fareDetailsOldSubTotal": "Subtotal before adjust"
            },
            "dailyDriverCar": {
                "driverInfo": "Informations du pilote",
                "driverName": "Nom du Driver",
                "driverIDPermit": "CNI du chauffeur / permis de conduire",
                "driverPhone": "Numéro de téléphone",
                "companyName": "Compagnie",
                "actionInfo": "Actions",
                "date": "Date",
                "action": "action",
                "bookingId": "Réservation #",
                "carInfo": "Infos sur la voiture",
                "permission": "Autorisation",
                "licensePlate": "Plaque d'immatriculation",
                "carId": "Voiture #",
                "carType": "Type de voiture"
            },
            "driverLoginStatus": {
                "driver": "Chauffeurs",
                "driverIdPermit": "CNI du chauffeur / permis de conduire",
                "phone": "Numéro de téléphone",
                "username": "Nom d'utilisateur",
                "company": "Compagnie",
                "carType": "Type de voiture",
                "licensePlate": "Plaque d'immatriculation",
                "status": "Statut"
            },
            "bookingSummary": {
                "date": "Date",
                "operator": "Opérateur",
                "username": "Nom d'utilisateur",
                "action": "action",
                "bookingId": "Réservation #"
            },
            "cashWallet": {
                "bankAccount": "Transferred to bank account",
                "creditWallet": "Transferred to credit wallet",
                "tipAfterCompleted": "Tip After Completed",
                "netEarning": "Net Earning",
                "referralEarning": "Referral Earning",
                "receiverAccount": "Receiver Account",
                "dateAndTime": "Date & heure",
                "driverName": "Nom du Driver",
                "phoneNumber": "Numéro de téléphone",
                "userName": "Nom d'utilisateur",
                "company": "Compagnie",
                "type": "Type",
                "bookingId": "Réservation #",
                "operator": "Opérateur",
                "reason": "Raison",
                "amount": "Montant",
                "newAmount": "Nouveau solde ",
                "currency": "Devise",
                "topUpGCashReason": "Recharger par Gcash",
                "topUpReason": "Recharger à partir d'une carte de crédit *{0}",
                "cashWalletReason": "Recharge depuis le porte-monnaie",
                "topUpMOLPayReason": "Razer Pay [ {0} ]",
                "chargedAmount": "Charge amount",
                "differentPercent": "Discount / Extra charge (%)",
                "operatorName": "Opérateur ",
                "sendPayout": "action",
                "sendPayoutTitle": "Resend payout email",
                "payoutReason": "{0} (Payout {1} {2})"
            },
            "creditWallet": {
                "receiverType": "Receiver Type",
                "receiverName": "Receiver Name",
                "receiverPhone": "Receiver phone number",
                "senderPhone": "Sender phone number",
                "senderName": "Sender name",
                "sentToCustomer": "Send to customer",
                "sentToDriver": "Send to driver",
                "dateAndTime": "Date & heure",
                "driverName": "Nom du Driver",
                "phoneNumber": "Numéro de téléphone",
                "userName": "Nom d'utilisateur",
                "company": "Compagnie",
                "type": "Type",
                "bookingId": "Réservation #",
                "operator": "Opérateur",
                "reason": "Raison",
                "amount": "Montant",
                "newAmount": "Nouveau solde ",
                "currency": "Devise",
                "transactionId": "ID de la transaction",
                "topUpGCashReason": "Recharger par Gcash",
                "topUpReason": "Recharger à partir d'une carte de crédit *{0}",
                "cashWalletReason": "Recharge depuis le porte-monnaie",
                "topUpMOLPayReason": "Razer Pay [ {0} ]",
                "topUpWalletReason": "{0} [ {1} ]",
                "chargedAmount": "Montant de la charge",
                "differentPercent": "Remise / Frais supplémentaires (%)",
                "cashExcessReason": "Customer must pay {0}; Cash received {1}",
                "referralEarningReason": "Customer {0} {1} completed first booking {2}",
                "referralEarningReason_2": "Customer {0} {1} completed booking {2}",
                "insurance": "Driver insurance"
            },
            "passengerWallet": {
                "dateTime": "Date et heure ",
                "customerName": "Customer name",
                "phoneNumber": "Numéro de téléphone",
                "type": "Type",
                "bookingId": "Booking #",
                "changedAmount": "Montant modifié",
                "amount": "Montant modifié",
                "newBalance": "Nouveau solde ",
                "currency": "Devise",
                "transactionId": "ID de la transaction",
                "Note": "Remarque",
                "credit": "Topup by credit card *{0}",
                "TnG": "Recharge par TnG #{0}",
                "MOLPay": "Top up from [RazerPay - #{0}]",
                "gatewayName": "Top up from [{0} - #{1}]",
                "cashExcess": "Customer must pay {0}, Cash given {1}",
                "editBalance": "Éditer le solde",
                "ride": "Ride",
                "topUp": "Top up",
                "topUpReferee": "Referral reward ({0})",
                "topUpReferrer": "New referee (#{0}): {1} ({2})"
            },
            "recipientsInfo": {
                "order": "No.",
                "orderId": "Order #",
                "name": "Recipient name",
                "phone": "Recipient phone #",
                "address": "Recipient address",
                "deliveryFee": "Delivery fee",
                "ItemValue": "Item Value (COD)",
                "status": "Status",
                "Performer": "Performer",
                "Reason": "Reason",
                "proofOfFailure": "Proof of failure",
                "referenceNumber": "Reference Number"
            },
            "dispatchHistory": {
                "performer": "Exécutant",
                "name": "Nom",
                "action": "action",
                "date": "Date",
                "location": "Localisation du chaufferu",
                "estimate": "Estimation de durée entre le véhicule et le lieu de prise en charge",
                "passenger": "Customer",
                "detachVehicle": "Detach Vehicle",
                "assignVehicle": "Assign Vehicle"
            },
            "payToDriver": {
                "driverName": "Nom du Driver",
                "totalUnsettledTransactions": "Total unsettled transactions",
                "totalUnsettledAmount": "Total unsettled amount",
                "actions": "Actions",
                "pay": "Payé",
                "remove": "Enlever",
                "clearSuccess": "Les gains du chauffeur selectionné ont bien été effacées",
                "clearFailed": "Echec de l'effacement des données de gain de chauffeur. Veuillez réessayer.",
                "selectDriver": {
                    "title": "",
                    "body": "Veuillez sélectionner un chauffeur à payer.",
                    "buttonTitle": "OK"
                },
                "confirmClear": {
                    "title": "",
                    "body": "Etes vous sûr de vouloir effacer les données de gains de ce chauffeur? Cette action ne peut pas être annulée.",
                    "buttonTitle": "Yes",
                    "closeButtonText": "No"
                },
                "confirmPay": {
                    "body": "Are you sure you want to pay to selected driver(s)?",
                    "buttonTitle": "Yes",
                    "closeButtonText": "No"
                },
                "paymentSuccessfully": "Paiement effectué"
            },
            "customerQuest": {
                "name": "Nom",
                "phoneNumber": "Numéro de téléphone",
                "supportId": "Support ID",
                "ranking": "Ranking",
                "points": "Points",
                "numberOfReservation": "Number of reservation",
                "driver": "Client",
                "customer": "Customer",
                "firstWillWin": "First will win",
                "firstWillWinLuckyDraw": "First will win (lucky draw)",
                "largestWillWin": "Largest will win (leaderboard)",
                "qualified": "Qualified",
                "notQualified": "Not qualified",
                "userName": "User name",
                "completedDate": "Completed date",
                "isQualified": "Statut",
                "ticket": "Ticket",
                "numOfComplete": "Num of completed",
                "bookingDistance": "P.O.B distance",
                "numberOfIntercity": "Number of intercity",
                "numberOfDelivery": "Number of delivery",
                "pleaseSelectEventName": "Please select event name.",
                "numberOfQualifiedSetting": "Number of qualified (setting): ",
                "numberOfActualQualified": "Number of actual qualified: ",
                "driverRating": "Évaluation du conducteur",
                "acceptanceRating": "Acceptance Rating",
                "cancellationRating": "Cancellation Rating",
                "period": "Period: "
            },
            "payoutToDriver": {
                "IFSCCode": "IFSC Code",
                "payoutID": "Payout ID",
                "driverName": "Client",
                "driverPhone": "Numéro de téléphone",
                "companyName": "Entreprise",
                "period": "Period",
                "driverId": "ID# / National IC#",
                "cashWalletBalance": "Cash wallet balance",
                "creditBalance": "Solde créditeur",
                "holdAmount": "Hold amount",
                "minPayout": "Min payout",
                "enterMinimumPayoutAmount": "Enter minimum payout amount",
                "payoutAmount": "Payout amount",
                "payoutDate": "Payout date",
                "actions": "Actions",
                "pay": "Pay",
                "remove": "Retirer",
                "payoutEndTime": "Payout end time",
                "clearSuccess": "The earning of selected driver was successfully cleared.",
                "clearFailed": "Failed to clear earning data of the driver. Please try again.",
                "amountKeepInDriverCashWallet": "Amount keep in driver's Cash Wallet",
                "selectDriver": {
                    "title": "",
                    "body": "Please select driver to pay.",
                    "buttonTitle": "OK"
                },
                "confirmClear": {
                    "title": "",
                    "body": "Are you sure you want to clear earning data of this driver? This action cannot be undone.",
                    "buttonTitle": "Yes",
                    "closeButtonText": "No"
                },
                "confirmPay": {
                    "body": "Are you sure you want to pay to selected driver(s)? <br> - Number of drivers: <b>%{drivers}</b> <br> - Min payout out for each driver: %{currency} <b>%{min}</b>  <br> - Hold amount: %{currency}<b> %{hold}</b> <br> - Total payout: %{currency} <b>%{total}</b>   ",
                    "buttonTitle": "Yes",
                    "closeButtonText": "No"
                },
                "paymentSuccessfully": "Payments settled successfully to driver(s)",
                "paymentCompleted": "Payout completed: <br> - Pay successfully: %{success} <br> - Failed: %{failed} <br> - Total payout successfully: %{currency} %{total}",
                "noteSelectDrivers": "Note: select drivers who have bank account to pay.",
                "sendPayout": "Send payout successful notification to email address",
                "accountNumber": "Account number",
                "iBanNumber": "IBAN number",
                "accountHoldName": "Account holder's name",
                "bankName": "Bank name",
                "selelctedDriverHasBeenPaid": "Selected driver(s) has been paid",
                "selelctedDriverHasBeenFailed": "Selected driver(s) has been failed",
                "selelctedDriverHasBeenUpdateAsInvalid": "Selected driver(s) has been updated as 'Invalid bank account'",
                "selelctedDriverHasBeenUpdateAsValid": "Selected driver(s) has been updated as 'Valid bank account'",
                "isBankAccountOwner": "Beneficiary same as driver",
                "isBankAccountOwner_true": "Yes",
                "isBankAccountOwner_false": "No",
                "beneficiaryIDIC": "Beneficiary ID# / IC#",
                "bankRelationship": "Relationship",
                "bankRelationship_1": "Spouse",
                "bankRelationship_2": "Daughter/Son",
                "bankRelationship_3": "Brother/Sister",
                "bankRelationship_4": "Relative",
                "bankRelationship_5": "Friends",
                "bankRelationship_6": "Other: {0}"
            },
            "payoutToMerchant": {
                "payoutId": "Payout ID",
                "merchantName": "Merchant Name",
                "phone": "Numéro de téléphone",
                "accountNumber": "Account number",
                "iBanNumber": "IBAN number",
                "bankAccountHolder": "Account holder's name",
                "bankName": "Bank name",
                "pendingAmount": "Pending amount",
                "payoutAmount": "Payout amount",
                "payoutDate": "Payout date",
                "holdAmount": "Hold amount",
                "minPayout": "Min payout",
                "selelctedMerchantHasBeenPaid": "Selected merchant(s) has been paid",
                "selelctedMerchantHasBeenFailed": "Selected merchant(s) has been failed",
                "pay": "Pay",
                "selectMerchant": {
                    "title": "Warning",
                    "body": "Please select merchant to pay.",
                    "buttonTitle": "OK"
                },
                "confirmPay": {
                    "body": "Are you sure you want to pay to selected merchant(s)? <br> - Number of merchants: <b>%{merchants}</b> <br> - Min payout out for each driver: %{currency} <b>%{min}</b>  <br> - Hold amount: %{currency}<b> %{hold}</b> <br> - Total payout: %{currency} <b>%{total}</b>",
                    "buttonTitle": "Yes",
                    "closeButtonText": "No"
                },
                "sendPayout": "Send payout successful notification to email address",
                "HasBeenUpdateAsInvalid": "Selected merchant(s) has been updated as 'Invalid bank account'",
                "HasBeenUpdateAsValid": "Selected merchant(s) has been updated as 'Valid bank account'"
            },
            "settlementHistory": {
                "date": "Date",
                "driverName": "Nom du Driver",
                "dateRange": "Date range",
                "totalTransaction": "Total settled transactions",
                "paidAmount": "Total settled amount"
            },
            "settlement": {
                "driverName": "Chauffeurs",
                "driverIdPermit": "CNI du chauffeur / permis de conduire",
                "totalBookings": "Réservation totale",
                "total": "Montant total",
                "approved": "Approuvé",
                "acct": "ACCT",
                "gross": "GROSS",
                "percent": 0.03,
                "net": "NET"
            },
            "documentExpiry": {
                "driverName": "Nom du driver",
                "driverPhone": "Numéro de téléphone",
                "company": "Compagnie",
                "documentName": "Document",
                "expiredDate": "Date d'expiration",
                "status": "Statut",
                "statusStr": {
                    "NA": "N/A",
                    "expired": "Expiré ",
                    "active": "actif",
                    "expireSoon": "Expire soon"
                }
            },
            "referralDetail": {
                "bookingId": "Book #",
                "completedDate": "Completed Time",
                "paidBy": "Paid by",
                "bookFrom": "Réservation à partir de",
                "driverName": "Driver name",
                "driverIdPermit": "ID# / National IC#",
                "companyName": "Entreprise",
                "Passenger": "Passenger",
                "Customer": "Customer",
                "subtotal": "Subtotal",
                "subTotalFF": "Subtotal FF",
                "subtotalWithPromo": "Subtotal with promotion",
                "referralEarning": "Referral Earning",
                "Amount": "Montant ",
                "Note": "Note",
                "Currency": "Devise",
                "PercentOfSubTotal": "{0}% of subtotal",
                "max": "max {0}",
                "referrerInfo": "Driver refers customer",
                "phoneReferral": "Driver phone #",
                "driverNumber": "ID# / National IC#",
                "referralCode": "Referral code"
            },
            "referralHistory": {
                "driverName": "Driver name",
                "phoneNumber": "Numéro de téléphone",
                "companyName": "Entreprise",
                "referralCode": "Referral code",
                "commission": "Commission",
                "expectedReferralEarning": "Expect referral earning",
                "activePaxTooltip": "Passagers ayant échangé le code de référence auprès du conducteur avec au moins une réservation effectuée avec succès.",
                "referredPaxTooltip": "Les passagers qui ont échangé le code de référence auprès du conducteur mais qui n’ont encore effectué aucune réservation.",
                "activePax": "Nombre total de passagers actifs",
                "referredPax": "Nombre total de passagers référés",
                "detailFields": {
                    "passengerId": "Passenger ID",
                    "passengerName": "Nom du passager",
                    "firstBookingDate": "1ère date de réservation complétée",
                    "firstBookingDateGMT": "1st completed booking date (GMT)",
                    "passengerPhone": "Customer phone number",
                    "appliedDate": "Referred date",
                    "referredByDriver": "Referred by driver",
                    "driverPhone": "Driver phone number",
                    "referralCode": "Referral code"
                },
                "showDetail": "Show",
                "detail": "Detail"
            },
            "paxReferralHistory": {
                "customerName": "Customer name",
                "phoneNumber": "Numéro de téléphone",
                "referralCode": "Referral code",
                "commission": "Total commission",
                "activePaxTooltip": "Passengers who have redeemed the referral code with at least one booking successfully made.",
                "referredPaxTooltip": "Passengers who have redeemed the referral code but yet to make any booking.",
                "activePax": "Total active customers",
                "referredPax": "Total referred customers",
                "detailFields": {
                    "passengerId": "Passenger ID",
                    "passengerName": "Passenger name",
                    "firstBookingDate": "1st completed booking date",
                    "firstBookingDateGMT": "1st completed booking date (GMT)"
                },
                "showDetail": "Show",
                "detail": "Detail"
            },
            "driverRefersDriver": {
                "companyName": "Company",
                "phoneNumber": "Phone number",
                "username": "Username",
                "referralCode": "Referral code",
                "referrer": "Referrer",
                "numberOfReferees": "Number of referees",
                "activeReferees": "Active referees",
                "referee": "Referee",
                "refereePhone": "Referee phone number",
                "refereeUsername": "Referee username",
                "referredDate": "Referred date",
                "activatedDate": "Activated date",
                "referrerByDriver": "Referrer",
                "referrerPhone": "Referrer phone number",
                "referralStatus": "Referral status",
                "lifeTimeBookings": "Life time bookings",
                "inReview": "In review",
                "activated": "Activated"
            },
            "driverWithdrawal": {
                "transferToEWallet": "Transferred to external e-wallet *{0}",
                "channelCode": "Channel code",
                "channelName": "Channel name",
                "reversed": "Reversed",
                "currentBalanceTooltip": "Le solde actuel du portefeuille en espèces du conducteur avant la demande de retrait",
                "paymentTime": "Délais de réglement",
                "requestTime": "Demande de temps",
                "referenceId": "Reference #",
                "transactionId": "ID de la transaction",
                "description": "Description",
                "transferAmount": "Montant du transfert",
                "currency": "Devise",
                "approved": "Approuver",
                "rejected": "Rejeter",
                "failed": "Failed",
                "reason": "Raison"
            },
            "penaltyCompensation": {
                "penaltyAmount": "Peine",
                "totalCollect": "Besoin de payer en monnaie locale",
                "totalCollectInUSD": "Besoin de payer en USD",
                "compensationAmount": "Compensation",
                "compensationAmountInUSD": "Compensation (USD)",
                "penaltyReason": "Penalty Reason",
                "estimatedPrice": "Estimated price",
                "estimatedPriceInUSD": "Estimated price (USD)",
                "penaltyAmountInUSD": "Peine (USD)",
                "amount": "Amount",
                "amountInUSD": "Amount (USD)"
            },
            "imcompletePayment": {
                "bookingId": "ID de réservation",
                "bookFrom": "Réservation à partir de",
                "bookingTime": "Date/heure de réservation",
                "completedTime": "Date/heure de terminaison de la réservation",
                "customerName": "Nom du client",
                "requestedPaymentMethod": "Requested payment method",
                "originalPaymentStatus": "État du paiement initial",
                "currentPaymentStatus": "État actuel du paiement",
                "updatedDateTime": "Date / heure mise à jour",
                "totalAmt": "Total amt",
                "originalOutstandingAmount": "Encours d'origine",
                "currentOutstandingAmount": "Encours courant",
                "outstandingAmountPaidBy": "Montant en souffrance payé par",
                "writeOffDebtMethod": "Écrire la méthode deb",
                "reason": "Raison",
                "customerPhone": "Numéro de téléphone",
                "amountCollected": "Montant collecté",
                "partialPaymentAmount": "Partial payment when drop off"
            },
            "driverDeposit": {
                "dateAndTime": "Date & heure",
                "userName": "Nom d'utilisateur",
                "type": "Type",
                "newAmount": "Nouveau solde ",
                "topUpGCashReason": "Recharger par Gcash",
                "topUpReason": "Recharger à partir d'une carte de crédit *{0}",
                "cashWalletReason": "Recharge depuis le porte-monnaie",
                "topUpMOLPayReason": "Razer Pay [ {0} ]"
            },
            "refund": {
                "fullRefund": "Full refund",
                "partialRefund": "Partial refund",
                "tripId": "Trip ID",
                "route": "Route",
                "refundAmount": "Refund amount",
                "refundTime": "Refund time",
                "paymentStatus": "Payment status"
            },
            "merchant": {
                "store": "Store",
                "name": "Nom",
                "phoneNumber": "Numéro de téléphone",
                "bookingId": "Booking #",
                "totalOrders": "Total orders",
                "completedOrders": "Completed orders",
                "canceledOrders": "Canceled orders",
                "incidentOrders": "Incident",
                "revenue": "Revenue",
                "commission": "Commission",
                "earning": "Earning",
                "food": "Aliments ",
                "mart": "Marché ",
                "preferredMerchant": "Preferred merchant",
                "regularMerchant": "Regular merchant",
                "companyName": "Company"
            },
            "merchantCashTransactionHistory": {
                "companyName": "Company",
                "dateTime": "Date et heure ",
                "merchantUserName": "Utilisateur marchand",
                "merchantUserPhone": "Numéro de téléphone",
                "merchantUserEmail": "Email",
                "merchantName": "Vendeur",
                "transactionType": "Type",
                "bookId": "Booking #",
                "transactionId": "ID de la transaction",
                "operatorName": "Opérateur ",
                "reason": "Raison",
                "amount": "Montant ",
                "newBalance": "Nouveau solde ",
                "currencyISO": "Devise"
            },
            "merchantCreditTransactionHistory": {
                "dateTime": "Date et heure ",
                "merchantUserName": "Utilisateur marchand",
                "merchantUserPhone": "Numéro de téléphone",
                "merchantUserEmail": "Email",
                "merchantName": "Vendeur",
                "transactionType": "Type",
                "bookId": "Booking #",
                "transactionId": "ID de la transaction",
                "operatorName": "Opérateur ",
                "reason": "Raison",
                "amount": "Montant ",
                "newBalance": "Nouveau solde ",
                "currencyISO": "Devise"
            },
            "deleteAccountRequest": {
                "date": "Date",
                "phone": "Phone",
                "username": "Nom d'utilisateur",
                "supportId": "Support ID",
                "requestFrom": "Request from",
                "associatedApps": "Associated apps",
                "reason": "Raison"
            },
            "affiliationPayoutHistory": {
                "payoutId": "Payment ID",
                "dateTime": "Date & time",
                "payoutType": "Type",
                "accountHolderName": "Account holder's name",
                "bankName": "Bank name",
                "accountNumber": "Account number",
                "totalPayout": "Total payment (USD)",
                "viewDetails": "View details",
                "notes": "Note",
                "bookId": "Booking #",
                "transactionType": "Transaction type",
                "transactionId": "Transaction Id (auto transfer)",
                "settlement": "Settlement",
                "paymentMethod": "Type",
                "networkType": "Network type"
            },
            "thirdPartyBooking": {
                "date": "Date",
                "bookingId": "Booking ID",
                "externalId": "External ID",
                "psgPhone": "Passenger phone number",
                "psgName": "Passenger name",
                "pickupLocation": "Pickup location",
                "pickupLocationLatLong": "Pickup location lat/long",
                "dropoffLocation": "Dropoff location",
                "dropoffLocationLatLong": "Dropoff location lat/long",
                "pickupTime": "Pickup time",
                "numOfPassenger": "Num of passenger",
                "action": "Action"
            }
        }
    },
    "zoneSetting": {
        "Add": "Ajouter",
        "Import": "Importer",
        "Name": "Nom",
        "Status": "Statut",
        "Active": "Actif",
        "Inactive": "Inactif",
        "Deactivate": "Désactiver",
        "Activate": "Activer",
        "Delete": "Effacer",
        "Cancel": "Annuler",
        "Input_zone_name": "Entrer un nom de zone",
        "Save_zone": "Enregistrer la zone",
        "Clear_draw": "Effacer le dessin",
        "Edit": "Modifier",
        "Delete_zone_success": "La zone a bien été supprimée",
        "Delete_zone_fail": "Des erreurs sont survenues lors de la suppression de la zone",
        "Update_zone_fail": "Des erreurs sont survenues lors de la mise à jour de la zone",
        "Update_zone_success": "La nouvelle zone a bien été mise à jour",
        "Create_zone_fail": "Des erreurs sont survenues lors de la création de la zone",
        "Create_zone_success": "La nouvelle zone a bien été créée",
        "Draw_zone": "Veuillez dessiner la zone sur la carte",
        "Import_zones_successful": "Import zones successful",
        "Import_zones_fail": "Import zones fail",
        "DELETE_ZONE": "Delete Zone",
        "ZONE_CONFIRM": "Do you want to delete zone ?",
        "Name_zone_required": "Name and Zone is required!",
        "Select_file": "Select file",
        "No": "Non",
        "Yes": "Oui",
        "Import_zones_intercity_successful": "[{0}] zone(s) has been imported successfully, [{1}] failed.",
        "Import_zones_intercity_successful_failed": "[{0}] zone(s) has been imported successfully, [{1}] failed.",
        "Flat_zone": "Flat zone",
        "Json_file": "Json file",
        "Import_zones": "Import zones",
        "timezone": "Time Zone"
    },
    "companySetting": {
        "DELETE_COMPANY": "Delete company",
        "DELETE_CONFIRM": "Do you want to delete company!",
        "DETAIL_COMPANY": "Detail company",
        "commissionValue": "Company commission",
        "connectStatus": "Stripe Connect",
        "connectStripe": "Connect",
        "stripeConnectId": "Stripe account ID",
        "loginStripe": "Dashboard",
        "disconnectStripe": "Disconnect",
        "Yes": "Oui",
        "No": "Non",
        "Cancel": "Annuler",
        "EDIT_COMPANY": "Modifier une compagnie",
        "ADD_COMPANY": "Ajouter une compagnie",
        "Name": "Nom",
        "Required_fields": "Champs requis",
        "Save": "Sauvegarder",
        "Actions": "Actions",
        "Edit": "Modifier",
        "Add": "Ajouter",
        "Show_per_page": "Eléments par page",
        "Page": "Page",
        "show_pages": "{0} - {1} de {2} Affiche",
        "Create_company_success": "La nouvelle compagnie a bien été créée",
        "Create_company_fail": "Des erreurs sont survenues en créant la compagnie",
        "Update_company_success": "La compagnie a bien été mise à jour",
        "Update_company_fail": "Des erreurs sont survenues en mettant à jour la compagnie",
        "Load_company_fail": "Des erreurs sont survenues en chargeant la compagnie",
        "Delete_company_success": "La compagnie a bien été supprimée",
        "Delete_company_fail": "Des erreurs sont survenues en supprimant la compagnie",
        "hideFare": "Always hide fare",
        "Broadcast_orders": "Broadcast all orders",
        "paymentMethodsApply": "Support payment method",
        "emailsNotification": "Emails Notification",
        "CopySignUpLink": "Copy sign up link",
        "operationZone": "Operation Zone",
        "delayBroadcast": "Show order after",
        "releaseTime": "Release Time",
        "advanceBookingVisibility": "Advance booking visibility",
        "advanceBookingVisibilityTooltip": "Set the number of days before the pickup date that a supplier can see the booking. This ensures that bookings are released to drivers in a timely manner, based on each supplier."
    },
    "dispatchSetting": {
        "GENERAL": "Général",
        "Offer_interval": "Intervalle d'offre",
        "Offer_interval_merchant": "Offer interval (merchant)",
        "Offer_interval_merchant_toolTip": "Maximum time for a merchant to accept a order",
        "Offer_interval_toolTip": "Délai maximum pour qu'un chauffeur accepte une réservation",
        "soundMarketplace": "Sound",
        "sound": "Sound",
        "Auto_re_dispatch_interval_toolTip": "Temps de pause entre 2 tours d'envoi",
        "Maximum_auto_dispatch_duration_toolTip": "Délai maximum d'envoi d'une réservation aux chauffeurs",
        "Seconds": "Secondes",
        "Auto_re_dispatch_interval": "Re-dispatch automatique intervalle",
        "Maximum_auto_dispatch_duration": "Durée maximale d'auto-dispatch",
        "Time_out": "Temps libre",
        "mins": "mins",
        "minTimeBeforeReservation": "Ne pas autoriser le pilote à démarrer la réservation avant",
        "IN_ADVANCE": "En avance",
        "Assign_reservation_to_driver": "Assigner la réservation à un chauffeur",
        "Dispatcher_confirms_job_for_driver": "Le dispatcher confirme la course pour le chauffeur",
        "Dispatcher_confirms_job_for_offline_driver": "Le dispatcher confirme la réservation du chauffeur hors ligne",
        "Broadcast_orders": "Broadcast orders to all companies",
        "Broadcast_orders_tooltip_1": "Enable this setting if you just want to broadcast all trips to all company suppliers. \n Keep in mind you need to enable broadcast mode on in each company’s profile.",
        "Minimum_book_ahead": "Minimum de temps d'avance pour la réservation",
        "Maximum_book_ahead": "Délai maximal de réservation en avance",
        "Maximum_period_recurring_oders_fleet": "Maximum period for recurring orders (fleet operator)",
        "Maximum_period_recurring_oders_corp": "Maximum period for recurring orders (corporate)",
        "Day": "Day",
        "Week": "Semaines",
        "Month": "Mois",
        "hrs": "heures",
        "Driver_cancellation_duration": "Durée d'annulation du Driver",
        "Mins": "mins",
        "Maximum_Day": "Le conducteur de réservations maximum peut accepter en une journée.",
        "Maximum_Accept_Reservation": "Le conducteur de réservations maximum peut accepter à la fois.",
        "Local_notification_before": "Local notification before",
        "Dispatch_mode": "Mode de dispatch",
        "Manual": "Manuel",
        "Immediate_auto_dispatch": "Auto-dispatch immédiat",
        "Delayed_auto_dispatch": "Auto-dispatch retardé",
        "Auto_dispatch_before_pickup_time": "Auto-dispatch avant l'heure de départ",
        "Required_fields": "Champs requis",
        "Shuttle": "SHUTTLE",
        "Shuttle_Dispatch_mode": "Dispatch mode",
        "Dispatch_setting_Shuttle_Dispatch_mode_tooltip": "- Manual mode: Reservation will be saved and stayed on Cue of your Command Center under “Pending” status once reservation is created. After clicking on “Update booking” at the booking detail, reservation will be dispatched to your assigned drivers or to all drivers in case of no driver assigning. It will be integrated into driver device calendar and reminder will be available. <br>- Delayed mode:Reservation will be saved and stayed on Cue of your Command Center under “Pending” status once reservation is created. Reservation will be automatically dispatched before “time value at “Process in advance”” from pick up time to the assigned driver or the nearest available one in case of no driver assigning. <br>- Immediate auto-dispatch mode: Reservation will be automatically dispatched to all drivers once reservation is created. It will be integrated into driver device calendar and reminder will be available.",
        "Save": "Sauvegarder",
        "Process_in_advance": "Exécuté en avance",
        "Save_dispatch_success": "Le dispatch a bien été mis à jour",
        "Save_dispatch_fail": "Des erreurs sont survenues en mettant à jour le dispatch",
        "Dispatch_setting_general_tooltip": "Postuler pour réservation à la demande, réservation en avance avec un auto-dispatch retardé",
        "Dispatch_setting_Assign_reservation_to_driver_tooltip": "La réservation est assignée et acceptée automatiquement si cette option est activée",
        "Dispatch_setting_Driver_cancellation_duration_tooltip": "La durée est l'intervalle de temps entre l'heure de départ et le moment où le Driver annule la réservation",
        "Dispatch_setting_Dispatch_mode_tooltip": "- Mode manuel: La réservation sera enregistrée et restera sur Cue de votre Command Center sous le statut «en attente»  lorsque la réservation est créée. Après avoir cliqué sur \"Mise à jour de la réservation\" au détail de réservation, la réservation sera envoyée à vos Drivers affectés ou à tous les Drivers en cas de non attribution du Driver. Il sera intégré dans le calendrier du Driver,l et un rappel sera disponible. - Mode Retardement: la réservation sera enregistrée et restera sur Cue de votre Command Center sous le statut «en attente» lorsque la réservation est créée. La réservation sera envoyée automatiquement avant \"valeur temps au Processus à l'avance\" de l'heure de départ au Driver affecté ou du Driver le plus proche en cas de non attribution du Driver. - Mode auto-dispatch immédiat:la réservation sera automatiquement envoyée à tous les Drivers une fois la réservation créée. Elle sera intégrée dans le calendrier du Driver et un rappel sera disponible.",
        "Notification_nearby_drivers_before_pickup_time": "Notification des conducteurs à proximité avant l'heure de prise en charge",
        "Dispatch_setting_Maximum_Day_tooltip": "This is to limit drivers from having too many reservations on the same day. Applicable for Marketplace and Intercity.",
        "Maximum_Accept_Reservation_tooltip": "The number of accepted reservations by driver cannot exceed this number. This limits the driver from accepting too many at a time. Applicable for Marketplace and Intercity.",
        "Notification_nearby_drivers_before_pickup_time_tooltip": "If a customer's reservation has not been accepted by any driver before the set time, before pickup time, the system will notify drivers nearby. This will hopefully prompt them to check marketplace to accept the booking.",
        "marketPlace": "MARKET PLACE",
        "howFarDriverSeeBookingMarketplace": "How far out the driver can see bookings in the marketplace",
        "shortNoticeOrder": "Short notice order",
        "shortNoticeOrderTooltip": "Set the time frame for bookings to be considered urgent and visible to all tiers immediately."
    },
    "voiceSMSSetting": {
        "ACCOUNT_SETTINGS": "Paramètres de compte",
        "Account_SID": "Compte SID",
        "Auth_Token": "Jeton d'autorisation",
        "Application_ID": "Identifiant d'application",
        "SMS_number_1": "Numéro de SMS 1",
        "SMS_number_2": "Numéro de SMS 2",
        "SMS_SETTINGS": "PARAMÈTRES SMS",
        "MASKED_PHONE_SETTINGS": "PARAMÈTRES DU TÉLÉPHONE MASQUÉ",
        "SMS_MARKETING_SETTINGS": "PARAMÈTRES DE MARKETING SMS",
        "Phone_number": "Numéro de téléphone",
        "Save": "Sauvegarder",
        "voiceSMS": "TWILIO",
        "DRIVER_APP": "Application conducteur ",
        "PASSENGER_APP": "Application Clients",
        "allowDriverToCallCC": "Allow Driver to call Helpdesk via:",
        "allowDriverToCallHelpdesk": "Allow Driver to call Helpdesk via:",
        "allowDriverToCallPassenger": "Allow Driver to call Passenger via:",
        "allowPassengerToCallCC": "Allow Passenger to call Helpdesk via:",
        "allowPassengerToCallHelpdesk": "Allow Passenger to call Helpdesk via:",
        "allowPassengerToCallDriver": "Allow Passenger to call Driver via:",
        "voip": "VOIP",
        "mobile": "Mobile",
        "bookingSMS": "Message template",
        "bookingSMSTemplate": {
            "name": "Name",
            "content": "Content",
            "action": "Action",
            "DELETE": "Delete Message template",
            "DELETE_CONFIRM": "Are you sure you want to delete the selected Message template?",
            "EDIT": "Edit Message template",
            "ADD": "Add Message template",
            "DETAIL": "Detail Message template",
            "Update_success": "The message template has been updated successfully",
            "Create_success": "The message template has been created successfully",
            "Delete_success": "The message template has been deleted successfully",
            "Delete_fail": "The errors occurred while deleting Message template",
            "Load_fail": "The errors occurred while loading Message template",
            "Update_fail": "The errors occurred while updating Message template",
            "Create_fail": "The errors occurred while creating Message template"
        },
        "bookingSMSModal": {
            "title": "Add SMS template",
            "save": "Save",
            "cancel": "Cancel",
            "titleMessage": "Send Message",
            "template": "Template",
            "phoneNumber": "Phone number",
            "content": "Content",
            "messageType": "Message type",
            "inbox": "Inbox",
            "sms": "SMS",
            "sendTo": "Sent to",
            "passenger": "Passenger",
            "driver": "Driver"
        }
    },
    "regularSetting": {
        "extraWaitTimeTooltip": "Fee to compensate the driver when he has to wait additional time for the customer till they are onboard.",
        "fareWaitTimeLater": "Fare per minute (Reservation)",
        "fareWaitTimeNow": "Fare per minute (Now)",
        "transport": "Transport",
        "delivery": "Livraison",
        "Fer_Stop": "Fee fer stop",
        "First_Stop": "Fee for first stop",
        "Each_Stop": "Fee for each extra stop",
        "Name": "Nom",
        "Pay_to_driver_Cash": "Pay to Supplier/Driver (Cash booking)",
        "Last_Update": "Dernière Mise À Jour",
        "Status": "Statut",
        "Required_fields": "Champs requis",
        "Yes": "Oui",
        "No": "Non",
        "Cancel": "Annuler",
        "Save": "Sauvegarder",
        "Actions": "Actions",
        "Edit": "Modifier",
        "Add": "Ajouter",
        "Show_per_page": "Eléments par page",
        "Page": "Page",
        "show_pages": "{0} - {1} de {2} Affiche",
        "DELETE_CONFIRM": "Voulez-vous supprimer cet élément?",
        "ADD_REGULAR_RATE": "Ajouter tarif normal",
        "EDIT_REGULAR_RATE": "Modifier tarif de base",
        "DETAIL_REGULAR_RATE": "Détail taux régulier",
        "DELETE_REGULAR_RATE": "Supprimer le tarif normal",
        "Delete_confirm_regular_rate": "Voulez-vous supprimer cet élément?",
        "STARTING": "Prise en charge",
        "Starting_Now": "Prise en charge (instantané)",
        "Starting_Reservation": "Prise en charge (réservation)",
        "FIRST_DISTANCE_FEE": "TARIF PREMIÈRE DISTANCE",
        "First_distance_fee": "Première distance",
        "From": "Distance De",
        "To": "A`",
        "Fee_for_first_distance": "Frais pour la première distance",
        "SECOND_DISTANCE_FEE": "Tarif 2ème distance",
        "Second_distance_fee": "2ème distance",
        "AFTER_SECOND_DISTANCE": "Après la 2ème distance",
        "Fee_after_second_distance": "Frais de 2ème distance",
        "PER_MINUTE": "Par minute",
        "Fee_per_minute": "Tarif par minute",
        "mi": "Mi",
        "hour": "hour",
        "MINIMUM": "Minimum",
        "Minimum_Now": "Minimum (maintenant)",
        "Minimum_Reservation": "Minimum (réservation)",
        "PAX_MDISPATCHER_CANCELLATION": "Annulation du passager",
        "On_demand": "Immédiat",
        "Penalty": "Pénalité",
        "In_advance": "En avance",
        "PAX_MDISPATCHER_NO_SHOW": "No show du passager",
        "Driver_cancel_demand": "Driver cancel policy - on demand",
        "Driver_cancel_advance": "Driver cancel policy - in advance",
        "Assign_car_type": "Attribuer type de véhicule",
        "Select_car_type": "Sélectionner le type de véhicule",
        "Active": "Actif",
        "Inactive": "Inactif",
        "Deactivate": "Désactiver",
        "DELETE_FLATE_RATE": "Supprimer forfait",
        "Delete_confirm_flat_rate": "Voulez-vous supprimer cet élément?",
        "EDIT_FLAT_RATE": "MODIFIER PRIX FORFAITAIRE",
        "ADD_FLAT_RATE": "Ajouter tarif forfaitaire",
        "DELETE_FLAT_RATE": "Delete flat rate",
        "DELETE_HOURLY_RATE": "Supprimer tarif de mise a disposition",
        "Delete_confirm_hour_rate": "Voulez-vous supprimer cet élément?",
        "EDIT_HOURLY_RATE": "Editer un tarif de mise à disposition",
        "ADD_HOURLY_RATE": "Ajouter un tarif de mise à disposition",
        "DELETE_FLAT_ROUTE": "Delete flat route",
        "DELETE_ROUTE_CONFIRM": "Are you sure to delete route?",
        "EDIT_ROUTE": "Modifier l'itinéraire ",
        "ADD_ROUTE": "Ajouter itinéraire",
        "Route_name": "Nom de l'itinéraire",
        "Route_type": "Type d'itinéraire",
        "Zone_to_zone": "Zone à zone",
        "Zipcode_to_zipcode": "Code postal vers code postal",
        "First_location": "Point de départ",
        "Second_location": "Arrivée",
        "First_location_zip_note": "* Remarques: Vous pouvez entrer plusieurs codes postaux en même temps en utilisant le symbole ','. Pour les codes consécutifs, utilisez le symbole '->' au lieu d'entrer un par un (ex: 90001 -> 90099, 90101, 90102, 90103, 90174, 90185)",
        "Second_location_zip_note": "* Remarques: Vous pouvez entrer plusieurs codes postaux en même temps en utilisant le symbole ','. Pour les codes consécutifs, utilisez le symbole '->' au lieu d'entrer un par un (ex: 90001 -> 90099, 90101, 90102, 90103, 90174, 90185)",
        "SINGLE_TRIP_SETUP": "Réglage du tarif aller simple",
        "Route_1": "Trajet 1 (Premier emplacement -> Second emplacement)",
        "Route_2": "Trajet 2 (Second emplacement ->  Premier emplacement)",
        "Limitation": "Limitation",
        "Unlimited": "Illimité",
        "Limited": "Limité",
        "Time_coverage": "Couverture temporelle",
        "Extra_time": "Temps supplémentaire",
        "ROUND_TRIP_SETUP": "Réglage du tarif aller-retour",
        "Round_trip": "Aller-retour",
        "ROUTES": "Routes",
        "Route_1_Fare": "Tarif du trajet 1",
        "Route_2_Fare": "Tarif du trajet 2",
        "Round_Trip_Fare": "Tarif de l'aller-retour",
        "Select_a_zone": "Sélectionnez une zone",
        "minute": "Minute",
        "VIEW_REGULAR_RATE": "View detail regular rate",
        "VIEW_FLAT_RATE": "View flat rate",
        "VIEW_HOURLY_RATE": "View hourly rate",
        "HOURLY_PACKAGE_RATE": "Tarif horaire",
        "Package_name": "Nom Du Package",
        "Package_duration": "Durée Du Package",
        "PackageId": "Package ID",
        "Base_rate": "Taux De Base",
        "Distance_coverage": "Couverture de distance",
        "Extra_duration": "Durée Supplémentaire",
        "Extra_distance": "Distance Supplémentaire",
        "DELETE_HOURLY_PACKAGE": "Delete hourly package",
        "DELETE_PACKAGE_CONFIRM": "Do you want to delete package rate?",
        "EDIT_PACKAGE": "Modifier le package",
        "VIEW_PACKAGE": "DÉTAIL DU PACKAGE",
        "ADD_PACKAGE": "Ajouter un package",
        "Fee_per_extra_duration": "Tarif par durée supplémentaire",
        "Fee_per_extra_distance": "Frais par distance supplémentaire",
        "hours": "Hour(s)",
        "days": "Day(s)",
        "Update_flat_rate_success": "Les tarifs au forfait ont bien été mis à jour",
        "Update_flat_rate_fail": "Des erreurs sont survenues en mettant jour les tarifs au forfait",
        "Created_flat_rate_success": "Les tarifs au forfait ont bien été créés",
        "Created_flat_rate_fail": "Des erreurs sont survenues en créant les tarifs au forfait",
        "Update_flat_rate_route_success": "Le tarif au forfait a bien été mis à jour ",
        "Update_flat_rate_route_fail": "Des erreurs sont survenues en mettant à jour le tarif au forfait",
        "Created_flat_rate_route_success": "Le tarif au forfait a bien été créé",
        "Created_flat_rate_route_fail": "Des erreurs sont survenues en créant le tarif au forfait",
        "Loading_flat_rate_fail": "Des erreurs sont survenues en chargeant les détails du tarif au forfait",
        "Delete_flat_rate_route_fail": "Des erreurs sont survenues en supprimant un tarif au forfait",
        "Delete_flat_rate_success": "Les tarifs au forfait ont bien été supprimée",
        "Delete_flat_rate_fail": "Des erreurs sont survenues en supprimant un tarif au forfait",
        "Update_intercity_rate_success": "Intercity rate has been updated successfully",
        "Update_intercity_rate_fail": "The errors occurred while updating intercity rate",
        "Created_intercity_rate_success": "Intercity rate has been created successfully",
        "Created_intercity_rate_fail": "The errors occurred while creating intercity rate",
        "Update_intercity_rate_route_success": "Intercity rate route has been updated successfully",
        "Update_intercity_rate_route_fail": "The errors occurred while updating intercity rate route",
        "Created_intercity_rate_route_success": "Intercity rate route has been created successfully",
        "Created_intercity_rate_route_fail": "The errors occurred while creating intercity rate route",
        "Loading_intercity_rate_fail": "The errors occurred while loading intercity rate detail",
        "Delete_intercity_rate_route_fail": "The errors occurred while deleting intercity rate route",
        "Delete_intercity_rate_success": "Intercity rate has been deleted successfully",
        "Delete_intercity_rate_fail": "The errors occurred while deleting intercity rate",
        "Update_regular_rate_success": "Les tarifs normaux ont bien été mis à jour",
        "Update_regular_rate_fail": "Des erreurs sont survenues lors de la mise à jour des tarifs normaux",
        "Create_regular_rate_success": "Un nouveau tarif normal a bien été créé",
        "Create_regular_rate_fail": "Des erreurs sont survenues lors de la création du tarif normal",
        "Update_hourly_rate_success": "Les tarifs de mise à disposition ont bien été mis à jour",
        "Update_hourly_rate_fail": "Des erreurs sont survenues en mettant jour les tarifs de mise à disposition",
        "Create_hourly_rate_success": "Les tarifs de mise à disposition ont bien été créés",
        "Create_hourly_rate_fail": "Des erreurs sont survenues en créant les packages de mise à disposition",
        "Update_hourly_rate_package_success": "Les packages de mise à disposition ont bien été mis à jour",
        "Update_hourly_rate_package_fail": "Des erreurs sont survenues en mettant jour les packages de mise à disposition",
        "Create_hourly_rate_package_success": "Les packages de mise à disposition ont bien été créés",
        "Create_hourly_rate_package_fail": "Des erreurs sont survenues en créant les packages de mise à disposition",
        "Load_hourly_rate_fail": "Des erreurs sont survenues lors du chargement des détails de tarifs de mise à disposition",
        "Update_hourly_status": "Des erreurs sont survenues en mettant à jour le statut de tarif de mis à disposition",
        "Delete_hourly_fail": "Des erreurs sont survenues en supprimant des tarifs de mise à disposition",
        "Delete_hourly_success": "Le tarif de mise à disposition a bien été supprimé",
        "Loading_regular_rate_fail": "Des erreurs sont survenues lors du chargement du tarif normal",
        "Update_regular_status_fail": "Des erreurs sont survenues lors de la mise à jour du statut du tarif normal",
        "Delete_regular_rate_success": "Le tarif normal a bien été supprimé",
        "Delete_regular_rate_fail": "Des erreurs sont survenues lors de la suppression du tarif normal",
        "Please_go_to_Car_Type_settings": "Veuillez aller dans les paramètres de types de véhicule pour appliquer ce tarif à des types de véhicule",
        "Car_type_filter": "Car Type Filter",
        "Delete_package_error": "Delete package error!",
        "Pax_mDispatcher_cancellation_tooltip": "Politique d'annulation appliquée pour les passagers",
        "Penalty_tooltip": "Montant facturé",
        "Pax_mDispatcher_no_show_tooltip": "Politique de non-présentation appliquée pour les passagers. Ces frais seront facturés si les Drivers cliquent \"Non Présentation\" lorsque le passager n'est pas au point de départ",
        "detail_route": "Detail route",
        "detail_package": "Detail Package",
        "THIRD_DISTANCE_FEE": "Third distance fee",
        "AFTER_DISTANCE_FEE": "After third distance fee",
        "Loading_delivery_rate_fail": "The errors occurred while loading delivery rate detail",
        "Update_delivery_rate_fail": "The errors occurred while updating delivery rate",
        "Update_delivery_rate_success": "Delivery rate has been updated successfully",
        "Created_delivery_rate_success": "Delivery rate has been created successfully",
        "Created_delivery_rate_fail": "The errors occurred while creating delivery rate",
        "Starting_fee": "Starting fee",
        "First_distance": "First distance",
        "Second_distance": "Second distance",
        "Third_distance": "Third distance",
        "Fee_for_second_distance": "Fee for second distance",
        "Fee_for_third_distance": "Fee for third distance",
        "Fee_after_third_distance": "Fee after third distance",
        "Assign_rate_to_car_type": "Assign rate to cartype",
        "car_type": "Cartype",
        "PRICE_ADJUSTABLE": "Price adjustable",
        "Amount_Step": "Price adjustable",
        "Tax": "Tax",
        "TechFee": "Frais techniques",
        "Pay_to_driver": "Payez au conducteur",
        "Pay_to_driver_tooltip": "Options to compensate drivers:\n - Fixed amount: A fixed amount the driver will receive.\n - Commission: Driver’s set commission applied to the penalty amount.",
        "Fix_amount": "Montant fixe",
        "Commission": "Commission",
        "fareFlat": "Fare flat",
        "flatRoute": "Flat route",
        "Mode": "Mode",
        "speedThresholdKM": "Speed threshold (km/h)",
        "speedThresholdMI": "Speed threshold (mi/h)",
        "timeAndMileage": "Time and mileage",
        "timeOrMileage": "Time or mileage",
        "perDistanceKM": "Per kilometer",
        "perDistanceMI": "Per mile",
        "perTime": "Per minute",
        "regularRates": "Regular Rates",
        "newRate": "New Rate",
        "enterName": "Enter Rate Name",
        "actualMode": "Actual fare",
        "estimatedMode": "Quoted fare",
        "feePerStop": "Fee per Additional Stops",
        "extraWaitTime": "Extra wait time",
        "startingFee": "Starting",
        "minimumFee": "Minimum",
        "minAppend": "/min",
        "kmAppend": "/km",
        "miAppend": "/mi",
        "kmhrAppend": "km/h",
        "mihrAppend": "mi/h",
        "feePerKm": "Per kilometer",
        "feePerMi": "Per mile",
        "feeSpeedThreshold": "Speed threshold",
        "serviceType": "Service Type",
        "fareMode": "Formula",
        "calculationMode": "Calculation Mode",
        "timeChangeMode": "Time Charge Mode",
        "minimum_Percent_Adjust": "Minimum % from estimated fare",
        "maximum_Percent_Adjust": "Maximum % from estimated fare",
        "AllowAdjustPrice": "Allow Customer to Adjust Price",
        "Calculation": "Calculation",
        "distanceTier": "Distance Tier",
        "perKm": "Fee per km",
        "perMi": "Fee per mi",
        "flatFee": "Flat Fee",
        "defaultRate": "Default Rate",
        "timeRange": "Time Range",
        "bookingType": "Booking Type",
        "rateDetail": "Rate Detail",
        "subRate": "Sub Rate",
        "specialRates": "Special Rates",
        "surcharge": "Surcharge",
        "penalty": "Penalty",
        "basedOriginMode": "Based on original pick up time",
        "basedDriverMode": "Based on driver pick up time",
        "hourlyRate": "Hourly Rate",
        "flat_Fare": "Flat Rate",
        "flatFeeOption": "Flat fee",
        "fareModeTooltip": "Time or mileage only applies to customer rates, this will not apply for supplier/driver payout rate.",
        "cloneRate": "Clone Rate",
        "rateName": "Rate Name",
        "Infinity": "Infinity",
        "Fee": "Fee",
        "headerTile": "Enter header title",
        "enterFooter": "Enter footer title",
        "invoiceNo": "Invoice No.",
        "paidOn": "Paid On",
        "amountPaid": "Amount Paid",
        "isFeePerSeatActive": "Charge per passenger",
        "minSeat": "Minimum number of passengers",
        "minSeatTooltip": "This is the minimum number of seats to charge for a booking."
    },
    "flatRateSettings": {
        "flatZoneNote": "The zones you create here are only used to setup your flat rates."
    },
    "deliverySetting": {
        "Name": "Nom",
        "Last_Update": "Dernière mise à jour",
        "Status": "Statut",
        "Actions": "Actions"
    },
    "sharingSetting": {
        "EDIT_RATE": "Edit street sharing rate",
        "ADD_RATE": "Add street sharing rate",
        "VIEW_RATE": "View street sharing rate",
        "Name": "Nom",
        "startingFee": "Starting fare",
        "minimumFee": "Minimum fare",
        "Update_streetSharing_rate_fail": "The errors occurred while updating street sharing rate",
        "Update_streetSharing_rate_success": "Street sharing rate has been updated successfully",
        "Created_streetSharing_rate_success": "Street sharing rate has been created successfully",
        "Created_streetSharing_rate_fail": "The errors occurred while creating street sharing rate",
        "Loading_streetSharing_rate_fail": "The errors occurred while loading street sharing rate detail",
        "Delete_streetSharing_rate_success": "Street sharing rate has been deleted successfully",
        "Delete_streetSharing_rate_fail": "The errors occurred while deleting street sharing rate",
        "Delete_streetSharing_rate": "Delete street sharing rate",
        "Assign_Car": "Assign car type"
    },
    "carTypeSetting": {
        "newService": "New Service",
        "editService": "Edit Service",
        "serviceInfo": "Service Info",
        "name": "Name",
        "namePH": "Car-type name",
        "enterNumber": "Enter a number",
        "appDisplayName": "App display name",
        "shortDescription": "Short description",
        "maxPassengers": "Max passengers",
        "maxLuggage": "Max luggages",
        "vehiclePhoto": "Vehicle Photos",
        "iconShowList": "Icon to show in list",
        "imageShowWeb": "Image to show on web",
        "carPinOnMap": "Car PIN on map",
        "recommendedSize": "Recommended size:",
        "serviceSetting": "Service Settings",
        "serviceType": "Service type",
        "reservation": "Reservation",
        "onDemand": "On-demand",
        "destinationSetting": "Destination setting",
        "requireDestination": "Require destination",
        "extraDestination": "Extra destination",
        "calculatorFareMode": "Calculate fare mode",
        "hideDestinationOnDemand": "On-demand",
        "meteredFare": "Metered fare",
        "quotedFare": "Quoted fare",
        "availableZones": "Available Zones",
        "dispatchAssociation": "Dispatch Association",
        "Save": "Save",
        "Edit": "Edit",
        "Cancel": "Cancel",
        "validateName": "Please input name",
        "validateAppDisplayName": "Please input app display name",
        "validateSelectImage": "Please select an image",
        "updateSuccess": "Update successfully",
        "createSuccess": "Create successfully",
        "failApi": "Something went wrong!",
        "validateRequire": "Please specify passenger count and double-check luggage."
    },
    "carSetting": {
        "Details": "Details",
        "vehicleInfo": "Vehicle Info",
        "Documents": "Documents",
        "vehicle": "Vehicle",
        "addVehicle": "Add Vehicle",
        "detailVehicle": "Vehicle Details",
        "additionalVehicleTitle": "Additional Vehicle Information",
        "Phone_Number_Tooltip": "Enter a vehicle-specific phone number for customer use. If not provided, calls will default to the driver’s personal number. Ideal for suppliers who equip vehicles with dedicated contact phones.",
        "discardChange": "Discard changes",
        "model_name_is_required": "Model name is required",
        "DELETE_CAR": "Ajouter",
        "DELETE_CONFIRM": "Voulez-vous supprimer le véhicule sélectionnée?",
        "Yes": "Oui",
        "Cancel": "Annuler",
        "EDIT_CAR": "Modifier",
        "CREATE_CAR": "Ajouter",
        "Plate_Number": "Numéro De La Plaque",
        "Car_Type": "Type De Véhicule",
        "Select_car_type": "Sélectionner le type de véhicule",
        "Make": "Marque",
        "Select_car_make": "Sélectionner marque de véhicule",
        "Model": "Modèle",
        "Select_a_car_model": "Sélectionner modèle de véhicule",
        "Year": "Année",
        "Select_year": "Sélectionnez l'année",
        "Hardware_Meter": "Compteur Physique",
        "VIN": "Numéro d'identification du véhicule (NIV)",
        "Company": "Compagnie",
        "Select_company": "Sélectionner une compagnie",
        "Max_Passengers": "Nbe maximum de passagers",
        "Max_Luggage": "Nbe maximum de bagages",
        "Color": "Couleur",
        "License_expiry": "Expiration du contrôle technique",
        "Required_fields": "Champs requis",
        "Save": "Sauvegarder",
        "Add": "Ajouter",
        "Car_Model": "Modèle De Véhicule",
        "Created_Date": "Créer Date",
        "Status": "Statut",
        "Actions": "Actions",
        "Show_per_page": "Eléments par page",
        "Page": "Page",
        "show_pages": "{0} - {1} de {2} Affiche",
        "Active": "Actif",
        "activeVehicle": "Active vehicle",
        "Inactive": "Inactif",
        "Edit": "Modifier",
        "Deactivate": "Désactiver",
        "Activate": "Activer",
        "Delete": "Effacer",
        "Name": "Nom",
        "MODEL_LIST": "Modèle",
        "Model_Name": "Modèle De Véhicule",
        "Add_Model": "Ajouter un modèle de véhicule",
        "DELETE_CAR_MODEL": "Delete car model",
        "DELETE_CONFIRM_MODEL": "Do you want to delete car model?",
        "DELETE_CAR_MAKE": "Delete car make",
        "DELETE_CONFIRM_CAR_MAKE": "Do you want to delete this car make?",
        "EDIT_CAR_MAKE": "Modifier marque de véhicule",
        "CREATE_CAR_MAKE": "Ajouter marque de véhicule",
        "Assign_Rate": "Attribuez tarif",
        "ASSIGN_RATE": "Attribuez tarif",
        "Car_type_ID": "Identification Du Type De Véhicule",
        "App_Display_Name": "Nom De L'affichage De L'application",
        "Affiliate_car_type": "Type De Véhicule Affilié",
        "Regular_rate": "Tarif normal",
        "Zone": "Zone de filtre",
        "Rate": "Tarif",
        "Zone_Rate": "Zone - Tarif",
        "Flat_Fare": "Forfait",
        "Flat_Delivery": "Delivery flat",
        "Select_flat_rate": "Sélectionnez forfait.",
        "Hourly_daily_rate": "Horaire / quotidien",
        "Dispatch_To": "Dispatch À",
        "Normal_Fare": "Régulier",
        "Upload_an_image": "Aucune image sélectionnée",
        "DETAIL_CAR": "Car detail",
        "DELETE_MULTI_CAR": "Delete selected cars",
        "DELETE_MULTI_CAR_CONFIRM": "Do you want to delete these vehicles ,Just only vehicles in the inactive status can be delete?",
        "DETAIL_CAR_MAKE": "Detail Car Make",
        "Car_Make": "Marque du véhicule",
        "Create_car_success": "Un nouveau véhicule a bien été créé",
        "Update_car_success": "Ce véhicule a bien été mis à jour",
        "Update_car_fail": "The errors occurred while updating car",
        "Create_car_fail": "Les erreurs se sont produites lors de la création du véhicule",
        "Load_car_fail": "Des erreurs sont survenues en chargeant le véhicule",
        "Delete_car_fail": "Des erreurs sont survenues en supprimant les véhicules",
        "Delete_car_success": "Le véhicule a bien été supprimé",
        "Change_cars_status_success": "Tous les statuts des véhicules ont bien été modifiés",
        "Change_cars_status_fail": "Des erreurs sont survenues en changeant les statuts des véhicules",
        "Delete_cars_success": "Tous les véhicules sélectionnés ont bien été supprimé",
        "Delete_cars_fail": "Des erreurs sont survenues en supprimant les véhicules",
        "Update_car_type_success": "Les catégories de véhicules ont bien été mis à jour",
        "Update_car_type_fail": "Des erreurs sont survenues en mettant à jour les catégories de véhicules",
        "Load_car_type_details_fail": "Les erreurs se sont produites lors du chargement des détails du type de véhicule",
        "Update_car_make_fail": "Des erreurs sont survenues lors de la mise à jour des marques de véhicules",
        "Update_car_make_success": "Les marques de véhicules ont bien été mises à jour",
        "Create_car_make_fail": "Des erreurs sont survenues lors de la création de marques de véhicules",
        "Create_car_make_success": "Une nouvelle marque de véhicule a bien été créée",
        "Update_car_model_fail": "Des erreurs sont survenues lors de la mise à jour des modèles de véhicules",
        "Update_car_model_success": "Les modèles de véhicules ont bien été mises à jour",
        "Create_car_model_fail": "Des erreurs sont survenues lors de la création de modèles de véhicules",
        "Create_car_model_success": "Un nouveau modèle de véhicule a bien été créée",
        "Loading_car_model_fail": "Des erreurs sont survenues lors du chargement du modèle de véhicule",
        "Delete_car_make_success": "La marque de véhicule a bien été supprimée",
        "Delete_cat_make_fail": "Des erreurs sont survenues lors de la suppression de la marque de véhicule",
        "Delete_regular_rate_success": "Delete Regular rate successfull",
        "Delete_regular_rate_fail": "Delete Regular rate error",
        "Update_status_error": "Update status error",
        "Case_number": "Numéro de dossier",
        "Phone_Number": "Numéro de téléphone",
        "ERROR_INPUT_VALIDPHONE": "Veuillez spécifier un numéro de téléphone valide",
        "Car_type_filter": "Filtre de type de véhicule",
        "No": "Non",
        "Intercity_Rate": "Intercity Rate",
        "Select_intercity_rate": "Select intercity rate",
        "Delivery_Rate": "Delivery Rate",
        "Select_delivery_rate": "Select delivery rate",
        "vehicleOwner": "Nom du propriétaire du véhicule",
        "EDIT_CAR_COLOR": "Edit car color",
        "DETAIL_CAR_COLOR": "Car color detail",
        "CREATE_CAR_COLOR": "Ajouter la couleur du véhicule",
        "Car_Color": "Car color",
        "Update_car_color_fail": "The errors occurred while updating car color.",
        "Update_car_color_success": "Car color has been updated successfully.",
        "Create_car_color_fail": "The errors occurred while creating car color.",
        "Create_car_color_success": "New car color has been created successfully",
        "Delete_car_color_success": "Car color has been deleted successfully.",
        "Delete_car_color_fail": "The errors occurred while deleting car color.",
        "DELETE_CAR_COLOR": "Delete car color",
        "DELETE_CONFIRM_CAR_COLOR": "Do you want to delete this car color?",
        "sharing_Rate": "Street Sharing Rate",
        "bookingAffiliate": "Affiliate Booking.com car type",
        "streetSharing": "Street Sharing"
    },
    "operationSetting": {
        "Driver": "Chauffeurs",
        "Search_driver_name": "Rechercher par nom de pilote",
        "Company": "Compagnie",
        "License_plate": "Plaque d'immatriculation",
        "Select_license_plate": "Sélectionnez la plaque d'immatriculation",
        "Shift": "Horraires",
        "Zone": "Zone de filtre",
        "Required_fields": "Champs requis",
        "Save": "Sauvegarder",
        "No": "Non.",
        "Car_Type": "Type De Véhicule",
        "Plate_Number": "Numéro De La Plaque",
        "Unassign": "Annuler l'attribution",
        "Please_choose_driver": "Veuillez choisir un pilote.",
        "Please_choose_company": "Please choose company.",
        "Please_license_plate": "Veuillez choisir un plaque d'immatriculation.",
        "Please_choose_shift": "Veuillez choisir un shift",
        "Please_choose_zone": "Veuillez choisir une zone.",
        "CONFIRM_UNASSIGN": "Voulez-vous annuler l'attribution de ce pilote?",
        "Assign_success": "L'opération a été assigné avec succès",
        "Unassign_success": "Le pilote a bien été affecté"
    },
    "paymentMethod": {
        "cash": "Paiement à bord",
        "easyPaisa": "Easy Paisa",
        "jazzCash": "Jazz Cash",
        "cashBySender": "Payé par l'expéditeur",
        "cashByRecipient": "Cash par le destinataire",
        "personalCard": "Carte bleue",
        "corporateCard": "Carte d'entreprise",
        "qrCodePayment": "Paiement par code QR",
        "directBilling": "Facturation directe",
        "externalCard": "Carte externe",
        "mDispatcherCard": "Carte Concierge",
        "corporatePrepaid": "Payé via Wallet ",
        "fleetCard": "Carte externe",
        "applePay": "Apple pay",
        "creditExternalCard": "Carte de crédit",
        "googlePay": "Google Pay",
        "wallet": "Portefeuille",
        "BankOfGeorgia": "Bank Of Georgia",
        "tnGeWalletlet": "TnG eWallet",
        "paymentMethod_1": "Paiement à bord",
        "paymentMethod_2": "Carte bleue",
        "paymentMethod_3": "Carte Concierge",
        "paymentMethod_4": "Carte d'entreprise",
        "paymentMethod_5": "Facturation directe",
        "paymentMethod_6": "Carte externe",
        "paymentMethod_7": "Payé via Wallet ",
        "paymentMethod_8": "Paiement par code QR",
        "paymentMethod_9": "Apple pay",
        "paymentMethod_11": "Airpay",
        "paymentMethod_12": "Carte de crédit",
        "paymentMethod_13": "Portefeuille",
        "paymentMethod_14": "TnG eWallet",
        "paymentMethod_16": "Payé par l'expéditeur",
        "paymentMethod_17": "Cash par le destinataire",
        "paymentMethod_19": "Boost eWallet",
        "paymentMethod_20": "Vipps",
        "paymentMethod_21": "ZainCash",
        "paymentMethod_23": "Google Pay",
        "paymentMethod_25": "Easy Paisa",
        "paymentMethod_26": "Jazz Cash",
        "vippseWallet": "Vipps",
        "paymentMethod_credit": "Carte personnelle",
        "paymentMethod_razerPay": "RazerPay",
        "paymentMethod_gCash": "GCash",
        "paymentMethod_zainCash": "ZainCash",
        "paymentMethod_xenDit": "Xendit",
        "paymentMethod_FIB": "FIB",
        "paymentMethod_SantimPay": "SantimPay",
        "paymentMethod_ksher": "Ksher",
        "paymentMethod_paydunya": "PayDunya",
        "paymentMethod_fawry": "Fawry",
        "paymentMethod_BankOfGeorgia": "Bank Of Georgia",
        "paymentLink": "Payment link ",
        "paymentMethod_xendit": "Xendit",
        "paymentMethod_easyPaisa": "Easy Paisa",
        "paymentMethod_jazzCash": "Jazz Cash",
        "paymentMethod_flooz": "Flooz",
        "paymentMethod_tmoney": "Tmoney",
        "paymentMethod_corporateCard": "Corporate card"
    },
    "changePassword": {
        "Change_password": "Changer le mot de passe",
        "Current_password": "Mot de passe actuel",
        "New_password": "Nouveau mot de passe",
        "Confirm_password": "Confirmez le mot de passe",
        "ERROR_INPUT_PASSWORD": "Veuillez saisir le mot de passe",
        "ERROR_INPUT_VALID_PASSWORD": "Password must contain at least 8 characters, one numeric digit and a special character",
        "ERROR_INPUT_MATCH_PASSWORD": "Le mot de passe ne correspond pas. Veuillez réessayer",
        "Save": "Sauvegarder",
        "Cancel": "Cancel"
    },
    "userProfile": {
        "My_account": "Mon compte",
        "Username": "Nom d'utilisateur",
        "First_name": "Prénom",
        "Last_name": "Nom de famille",
        "Phone_number": "Numéro de téléphone",
        "Email_address": "Adresse e-mail",
        "Address": "Adresse",
        "Save": "Sauvegarder",
        "Edit_profile": "Changer le mot de passe",
        "Change_password": "Changer le mot de passe",
        "ERROR_INPUT_USERNAME": "Veuillez saisir le nom d'utilisateur.",
        "ERROR_INPUT_FIRSTNAME": "Veuillez saisir le prénom.",
        "ERROR_INPUT_VALID_EMAIL": "Veuillez entrer un email valide",
        "Required_fields": "Champs requis"
    },
    "General": {
        "searchQueuingDriver": "Search: Driver Name, Phone Number",
        "search": "Chercher",
        "User": "Utilisateur ",
        "My_Account": "Mon compte",
        "Sign_out": "Se déconnecter",
        "My_site": "Mon site",
        "Command_center": "Centre de contrôle",
        "Notifications": "Notifications",
        "Show_all": "Show All",
        "SOS_Notifications": "SOS Notifications",
        "You_dont_have_any_message": "You dont have any message",
        "Mon": "Lun",
        "Tue": "Mar",
        "Wed": "Mer",
        "Thu": "Jeu",
        "Fri": "Ven",
        "Sat": "Sam",
        "Sun": "Dim",
        "Now": "Maintenant",
        "NowComplete": "Now (Acttual Pickup Time)",
        "hrs": "Heures",
        "patmentMethod_1": "Paiement à bord",
        "patmentMethod_2": "Carte bleue",
        "patmentMethod_3": "mDispatch card",
        "patmentMethod_4": "Carte d'entreprise",
        "patmentMethod_5": "Facturation directe",
        "patmentMethod_6": "Carte externe",
        "patmentMethod_7": "Payé via Wallet ",
        "patmentMethod_8": "Paiement par code QR",
        "patmentMethod_9": "Apple Pay",
        "patmentMethod_10": "Apple Pay",
        "patmentMethod_12": "Carte de crédit",
        "patmentMethod_13": "Portefeuille",
        "patmentMethod_14": "TnG eWallet",
        "patmentMethod_16": "Payé par l'expéditeur",
        "patmentMethod_17": "Payé par le destinataire",
        "patmentMethod_19": "Boost",
        "patmentMethod_20": "Vipps",
        "patmentMethod_23": "Google Play",
        "patmentMethod_24": "Payment Link",
        "patmentMethod_25": "Easy Paisa",
        "patmentMethod_26": "Jazz Cash",
        "patmentMethod_ZainCash": "ZainCash",
        "patmentMethod_Xendit": "Xendit",
        "patmentMethod_FIB": "FIB",
        "patmentMethod_SantimPay": "SantimPay",
        "patmentMethod_GCash": "GCash",
        "patmentMethod_cash": "En espèces",
        "patmentMethod_credit": "Carte personnelle",
        "patmentMethod_mDispatcher": "Partner card",
        "patmentMethod_corpCredit": "Carte d'entreprise",
        "patmentMethod_directBilling": "Facture directe",
        "patmentMethod_fleetCard": "External card",
        "patmentMethod_prepaid": "Corporate Prepaid",
        "patmentMethod_QRCode": "QR Code",
        "patmentMethod_applePay": "Apple Pay",
        "patmentMethod_easyPaisa": "Easy Paisa",
        "patmentMethod_jazzCash": "Jazz Cash",
        "patmentMethod_prePaid": "Corporate Prepaid",
        "patmentMethod_GooglePay": "Google Pay",
        "patmentMethod_creditCardExternal": "Carte de crédit",
        "patmentMethod_PaxWallet": "Portefeuille",
        "patmentMethod_TnGeWallet": "TnG eWallet",
        "patmentMethod_boosteWallet": "Boost",
        "patmentMethod_Vipps": "Vipps",
        "patmentMethod_exZainCash": "ZainCash",
        "patmentMethod_exGCash": "GCash",
        "patmentMethod_exPayTM": "PayTM",
        "patmentMethod_exFlooz": "Flooz",
        "patmentMethod_exTmoney": "Tmoney",
        "patmentMethod_exTelebirr": "GCash",
        "paymentMethod_exzainCash": "ZainCash",
        "paymentMethod_exXendit": "Xendit",
        "paymentMethod_exFIB": "FIB",
        "paymentMethod_exSantimPay": "SantimPay",
        "patmentMethod_exKsher": "Ksher",
        "paymentMethod_paydunya": "PayDunya",
        "paymentMethod_exfawry": "Fawry",
        "patmentMethod_exBankOfGeorgia": "Bank Of Georgia",
        "patmentMethod_exPayDunya": "PayDunya",
        "patmentMethod_21": "ZainCash",
        "patmentMethod_paymentLink": "Payment Link",
        "patmentMethod_card": "Personal card",
        "patmentMethod_corporateCard": "Corporate card",
        "patmentMethod_paxWallet": "Wallet",
        "patmentMethod_externalWallet": "External Wallet",
        "patmentMethod_googlePay": "Google Pay",
        "patmentMethod_manual": "Manual",
        "patmentMethod_online": "Card/Online Payment",
        "patmentMethod_check": "Check",
        "patmentMethod_wire": "Wire",
        "patmentMethod_digitalWallet": "Digital Wallet",
        "patmentAirlineMethod_5": "Facture directe",
        "patmentAirlineMethod_4": "Airline Card",
        "patmentAirlineMethod_7": "Airline Prepaid",
        "Name": "Nom",
        "Delete": "Effacer",
        "App_SOS_dialog_form_message_1": "{0} Emergency SOS!",
        "App_SOS_dialog_form_message_2": "I need help at ",
        "App_SOS_dialog_form_message_3": "Booking: {0}, Driver: {1}, Passenger: {2}, License Plate: {3} ({4})",
        "mins": "mins",
        "Credit_Card": "Carte de crédit",
        "ALL": "Tout",
        "NotFound": "The page you requested could not be found",
        "ServerError": "Something went wrong",
        "AccessDenied": "You don't have permission to access this module!",
        "Retry": "Retry",
        "Can_not_get_data": "Can't get data"
    },
    "shiftSettings": {
        "Add": "Ajouter",
        "Show_per_page": "Eléments par page",
        "Page": "Page",
        "Name": "Nom",
        "Time_Range": "Intervalle de temps",
        "Actions": "Actions",
        "show_pages": "{0} - {1} of {2} shown",
        "Edit": "Modifier",
        "Delete": "Effacer",
        "EDIT_SHIFT_SETTING": "Modifier Shift",
        "ADD_SHIFT_SETTING": "Ajouter Shift",
        "DETAIL_SHIFT_SETTING": "Réglage du décalage de détail",
        "DELETE_SHIFT_SETTING": "SUPPRIMER LES PARAMETRES DE SHIFT",
        "Delete_confirm_shift_setting": "Voulez-vous supprimer cet élément?",
        "Required_fields": "Champs requis",
        "Save": "Enregistrer",
        "Cancel": "Annuler",
        "From": "De",
        "To": "A",
        "Edit_shift_template": "Modifier modèle de Shift",
        "Add_shift_template": "Ajouter Shift de référence",
        "Detail_shift_template": "Modèle de décalage de détail",
        "Delete_shift_template": "Delete Shift Template",
        "Delete_confirm_shift_template": "Voulez-vous supprimer cet élément?",
        "Working_time": "Temps de travail",
        "Create_shift_settings_success": "Le nouveau paramètre de shift  a été créé avec succès",
        "Create_shift_settings_fail": "Les erreurs se sont produites lors de la création du shift",
        "Update_shift_settings_success": "Le paramètre de shift a été mis à jour avec succès",
        "Update_shift_settings_fail": "Les erreurs se sont produites lors de la mise à jour du shift",
        "Delete_shift_settings_success": "Le paramètre de shift a été supprimé avec succès",
        "Delete_shift_settings_fail": "Les erreurs sont survenues lors de la suppression du paramètre de shift",
        "Create_shift_template_success": "Le nouveau modèle de shift a été créé avec succès",
        "Create_shift_template_fail": "Les erreurs sont survenues lors de la création du modèle de shift",
        "Update_shift_template_success": "Le modèle de shift a été mis à jour avec succès",
        "Update_shift_template_fail": "Les erreurs se sont produites lors de la mise à jour du modèle de shift",
        "Delete_shift_template_success": "Le modèle de shift a été supprimé avec succès",
        "Delete_shift_template_fail": "Les erreurs se sont produites lors de la suppression du modèle de shift",
        "Yes": "Yes",
        "No": "Non"
    },
    "promotionSettings": {
        "Detail_campaign": "Campagne détaillée",
        "Edit_campaign": "Modifier campagne",
        "Add_campaign": "Ajouter campagne",
        "Delete_campaign": "Supprimer la campagne",
        "Delete_confirm_campaign": "Voulez-vous supprimer la campagne sélectionnée?",
        "Add": "Ajouter",
        "Corporate_list": "Corporate list",
        "permittedCorporate": "Permitted corporate list",
        "CorporateCustomers": "Corporate Customers",
        "auto_add_corporate": "Auto add to Corporate User's promotion list",
        "Show_per_page": "Eléments par page",
        "Page": "Page",
        "Name": "Nom",
        "Actions": "Actions",
        "Private": "Privé",
        "show_pages": "{0} - {1} of {2} shown",
        "Edit": "Modifier",
        "Delete": "Effacer",
        "Send_to_inbox": "Envoyer à la boîte de reception",
        "Save": "Sauvegarder",
        "Duplicate": "Dupliquer",
        "Cancel": "Annuler",
        "Required_fields": "Champs requis",
        "Promotion_code": "Code promo",
        "Promotion_type": "Type de promotion",
        "Campaign": "Campagne",
        "Type": "Type",
        "Value": "Valeur",
        "keepMinFee": "Total fare after promo must greater than minimum fare",
        "Zone": "Zones",
        "paymentMethodsApply": "Méthodes de Paiement",
        "schedules": "Schedules",
        "minimumEstFareApply": "Estimation minimale du tarif total pour appliquer le code",
        "quantityLimitPerYear": "Utilisations maximales/utilisateur/année",
        "termAndCondition": "Termes et conditions",
        "Valid_from": "Valide à partir de",
        "Valid_to": "Valide jusqu'à",
        "Total_uses_user": "Total utilisations/ utilisateur",
        "Released_quantity": "Quantité distribuée",
        "Number_of_sending_inbox": "Nombre de boîtes d'envoi",
        "Status": "Statut",
        "Activate": "Activer",
        "Deactivate": "Désactiver",
        "Add_promotion_code": "Ajouter code de promotion",
        "Edit_promotion_code": "Modifier le code promo",
        "Detail_promotion_code": "Code de promotion détaillé",
        "Delete_promotion_code": "Supprimer le code promo",
        "Delete_confirm_promotion_code": "Voulez-vous supprimer le code promo sélectionné?",
        "New_customer": "Nouveau client",
        "Budget_per_user": "Budget/user (for whole promotion time period)",
        "Budget_per_user_day": "Budget/user/day",
        "Budget_per_user_month": "Budget/user/month",
        "Budget_per_user_year": "Budget/user/year",
        "Unlimited": "Illimité",
        "Limited": "Limité",
        "Total_uses_per_user": "Total utilisations/ utilisateur",
        "Send_an_in_box_automatically_to_new_customers": "Envoyer un message automatiquement pour un nouveau client",
        "Notes": "Notes",
        "Public": "Publique",
        "Create_campaign_success": "Une nouvelle campagne a été créée avec succès",
        "Create_campaign_fail": "Les erreurs se sont produites lors de la création de la campagne",
        "Update_campaign_success": "La campagne a été mise à jour avec succès",
        "Update_campaign_fail": "Les erreurs se sont produites lors de la mise à jour",
        "Delete_campaign_success": "Campaign has been deleted successfully",
        "Delete_campaign_fail": "The errors occurred while deleting campaign",
        "Loading_campaign_fail": "Les erreurs se sont produites lors du chargement de la campagne",
        "Update_campaign_status_success": "Le statut de la campagne a été mis à jour avec succès",
        "Update_campaign_status_fail": "Les erreurs se sont produites lors de la mise à jour du statut",
        "Loading_promotion_code_fail": "The errors occurred while loading promotion code",
        "Select_campaign": "Sélectionner campagne",
        "Create_promotion_code_success": "Le nouveau code de promotion a été créé avec succès",
        "Create_promotion_code_fail": "Les erreurs se sont produites lors de la création du code de promotion",
        "Update_promotion_code_status_success": "L'état du code promo a été mis à jour avec succès",
        "Update_promotion_code_status_fail": "Les erreurs se sont produites lors de la mise à jour du code promo",
        "Delete_promotion_code_confirmation": "Do you want to deactivate these promotion codes?\n",
        "Just_delete_inactive": " ** Seul un code promo qui a un statut d'inactif peut être supprimé.",
        "Send_inbox_confirmation": "Etes vous sûr de vouloir envoyer ce code promo à la boîte de réception du passager?",
        "Send_inbox_success": "Le code promo a bien été envoyé.",
        "Send_inbox_fail": "L'envoi du code promo a échoué.Veuillez réessayer!",
        "Confirmation": "Confirmation",
        "Delete_promotion_codes_success": "Promotion code has been deleted successfully",
        "Delete_promotion_codes_fail": "The errors occurred while deleting promotion code",
        "Delete_multiple_promotion_codes_success": "Les codes promo ont été supprimés avec succès",
        "Delete_multiple_promotion_codes_fail": "The errors occurred while deleting promotion codes",
        "Delete_promotion_code_success": "Promotion code has been deleted successfully",
        "Delete_promotion_code_fail": "The errors occurred while deleting promotion code",
        "Update_multiple_promotion_code_status_success": "Les statuts du code promo ont été mis à jour avec succès",
        "Update_multiple_promotion_code_status_fail": "The errors occurred while deleting promotion code statuses",
        "Search_here": "Rechercher ici",
        "Please_input_campaign_name": "Veuillez saisir le nom de la campagne.",
        "Please_input_promotion_code": "Veuillez saisir le code promo",
        "Please_input_promotion_code_at_least_4_characters": "Please enter at least 4 characters.",
        "Please_select_campaign": "Veuillez sélectionner une campagne",
        "Please_input_value": "Veuillez saisir une valeur",
        "Please_select_date": "Veuillez sélectionner une date",
        "Please_add_schedules": "Please add schedule",
        "Please_add_corporate": "Please add corporate",
        "Please_choose_at_least_one_customer": "Veuillez choisir au moins un client",
        "This_campaign_name_has_been_existing_on_the_system_Please_input_another_one": "Ce nom de campagne a déjà existé sur le système. Veuillez saisir un autre.",
        "This_promotion_code_has_been_existing_on_the_system_Please_input_another_one": "Ce code promo existe déjà sur le système. Veuillez en saisir un autre.",
        "Invalid_file": "File content is invalid",
        "File_size_larger_than_5Mb": "File size is larger than 5Mb",
        "Import_promotions_success": "File has been imported successfully",
        "Import_Promotions": "Import File",
        "Number_successful_imports": "Imported items",
        "Number_skipped": "Skip items",
        "File": "File",
        "Phone_Numbers": "phone number(s)",
        "Import_Promotion_code_success": "Le code promo <b>{0}</b> a bien été importé",
        "Import_Promotion_code_fail": "Le code promo <b>{0}</b> n'a pas pu être importé. Veuillez réessayer",
        "Customer_type": "Customer type",
        "All": "Tout",
        "Referred_customers": "Referred customers",
        "Common_customers": "Clients habituels",
        "Operation_zone": "Zones d'opération",
        "totalUsesLimitPerDay": "Utilisations maximales/utilisateur/jour",
        "quantityLimitPerDay": "Quantité maximale/jour",
        "quantityLimitPerMonth": "Quantité maximale / utilisateur / mois",
        "applyVerifiedCustomers": "Appliquer pour les clients malaisiens vérifiés uniquement",
        "applyWhenBudgetLimit": "User can apply code till budget limit is finished",
        "applyVerifiedCustomersTooltip": "Cette promotion s'applique aux Malaisiens qui ont vérifié leur identité nationale et téléchargé leur carte d'identité sur le système uniquement",
        "valueLimitPerUse": "Valeur/utilisation maximale (s'applique à l'application v48 et plus)",
        "Promotion_Services": "Un service ",
        "transport": "Transport",
        "shuttle": "Shuttle",
        "parcel": "Colis ",
        "food": "Aliments ",
        "mart": "Marché ",
        "intercity": "Intercity",
        "addSchedule": "Add Schedule",
        "editSchedule": "Edit Schedule",
        "date": "Date",
        "Time_range": "Time range",
        "startTime": "Start time",
        "endTime": "End time",
        "applyForOtherDays": "Apply for other days",
        "scheduleTimeOverlap": "Schedule time must not overlap with each others",
        "schedule_startDate_greater_than_endDate": "The start date is greater than the end date",
        "scheduleTimeDuplicate": "Schedule time must not duplicate with each others",
        "promoIcon": "Icône de promo",
        "bannerImage": "Image de bannière",
        "Upload_an_image": "Aucune image sélectionnée",
        "Click_here_to_view": "Click here to view."
    },
    "quest_event": {
        "name": "Nom",
        "userType": "Type of User",
        "carType": "Car type",
        "operationZones": "Operation zone",
        "type": "Event Type",
        "isLuckyDraw": "Lucky Draw Round",
        "start_date": "Start Date",
        "end_date": "End Date",
        "numOfQualify": "Number of Qualified",
        "event_progress": "Event Progress",
        "isActive": "Statut",
        "Actions": "Actions",
        "passenger": "Customer",
        "driver": "Client",
        "first_will_win": "First will win",
        "largest_will_win": "Largest will win (leaderboard)",
        "not_start": "Not start",
        "active": "actif",
        "closed": "Closed",
        "event_details": "Quest Details",
        "add_event": "Add Quest",
        "limited": "Limité ",
        "unlimited": "Illimité ",
        "eventImage": "Event Image",
        "sharedImage": "Shared Image",
        "qualifiedImage": "Qualified Image",
        "expiredImage": "Expired Image",
        "luckyDrawImage": "Lucky Draw Image",
        "Upload_an_image": "Aucune image sélectionnée",
        "Required_Image": "Image Extensions (.jpg, .png, .gif)",
        "ok": "OK",
        "eventId": "Event ID",
        "passengerMetric": {
            "booking_distance": "Booking distance",
            "booking_time": "Booking time",
            "booking_amount_spent": "Booking amount spent",
            "num_of_ondemand": "Number of on-demand",
            "num_of_reservation": "Number of reservation",
            "num_of_intercity": "Number of intercity",
            "num_of_delivery": "Number of delivery",
            "num_of_referral": "Number of referral",
            "num_of_sharing": "Number of sharing",
            "topup_wallet_amount": "Topup wallet amount",
            "num_of_complete_wallet": "Number of complete booking (wallet)",
            "num_of_complete_booking_by_referee": "Number of complete booking (by referee)",
            "number_of_complete_booking": "Number of complete booking",
            "number_of_food_mart": "Number of food / mart"
        },
        "driverMetric": {
            "booking_distance": "Job distance",
            "booking_time": "Job time",
            "num_of_referral": "Number of referral",
            "num_of_complete": "Number of complete booking (unique customer)",
            "num_of_referral_driver": "Number of referral (driver)",
            "num_of_accept": "Number of accept booking",
            "num_of_receive": "Number of receive booking",
            "num_of_cancel": "Number of cancel booking by driver",
            "sum_of_rating": "Total number of rating star",
            "total_rating": "Total number of rating times",
            "earnings_amount": "Earnings Amount",
            "num_of_complete_wallet": "Number of complete booking (wallet)",
            "num_of_complete_booking_by_referee": "Number of complete booking (by referee)",
            "available_time": "Online time"
        },
        "Click_here_to_view": "Click here to view.",
        "view": "Click here to view.",
        "metricType": "Type",
        "task": "Task",
        "value": "Value",
        "quantity": "Quantity",
        "quests_list": "Quests",
        "rankImages": "Rank image (point)",
        "qualifiedPrizeOrder": "Qualified Prizes",
        "luckyDrawPrizeOrder": "Lucky Draw Prizes",
        "reward": "Item",
        "from": "De ",
        "to": "À ",
        "image": "Image",
        "Add_Prize": "Ajouter ",
        "bannerText": "Banner text",
        "buttonText": "Button text",
        "bannerLink": "Banner link",
        "shortLink": "Short link",
        "buttonLinkDesktop": "Button link (Desktop)",
        "buttonLinkAndroid": "Button link (Android)",
        "buttonLinkiOS": "Button link (iOS)",
        "termsURL": "Terms & Conditions Link",
        "metadataTitle": "Metadata title",
        "metadataDescription": "Metadata description",
        "isDarkMode": "Dark Mode",
        "isActive_details": "actif",
        "Required_fields": "Required fields",
        "change": "Change",
        "Please_input_name": "Please input name",
        "Please_select_date": "Please select date",
        "endDate_invalid": "End Date invalid",
        "startDate_greater_than_endDate": "The start date is greater than the end date",
        "Please_input_termsURL": "Please input Terms & Conditions Link",
        "Please_input_metadataTitle": "Please input metadata title",
        "Please_input_metadataDescription": "Please input metadata description",
        "Please_input_bannerText": "Please input banner text",
        "Please_input_buttonText": "Please input button text",
        "Please_input_bannerLink": "Please input banner link",
        "Please_input_shortLink": "Please input short link",
        "Please_input_buttonLinkDesktop": "Please input button link (Desktop)",
        "Please_input_buttonLinkAndroid": "Please input button link (Android)",
        "Please_input_buttonLinkiOS": "Please input button link (iOS) ",
        "the_range_1_to_5": "Please input value in the range 1 to 5 for %{name}!",
        "the_range_1_to_100": "Please input value in the range 1 to 100 for %{name}!",
        "task_is_empty": "Please input task for %{name}!",
        "task_limit_50_characters": "Task maximum length is 50 characters for %{name}!",
        "please_input_points": "Please input points for %{name}!",
        "please_input_value": "Please input value for %{name}!",
        "value_is_invalid": "%{name}, value must be a number",
        "please_upload_image": "Please upload image for item %{name}!",
        "rank_invalid": "Rank %{name} is invalid!",
        "rank_invalid_to": "Rank \"To\" must be a number",
        "deepLink": "Deeplink",
        "point_per_task": "Point per task",
        "points": "Points",
        "points_per_some_think": "Points per %{unit}",
        "criterias": "Criteria (optional)",
        "criterias_list": "Criteria",
        "criteriaType": "Type",
        "criteriaValue": "Value",
        "acceptance_rating": "Acceptance Rating (greater than or equal)",
        "cancellation_rating": "Cancellation Rating (less than or equal)",
        "driver_rating": "Driver Rating (greater than or equal)",
        "please_upload_image_rank": "Please upload image for rank %{name}!",
        "Update_event_success": "Quest has been updated successfully",
        "Update_event_fail": "The errors occurred while updating quest",
        "Create_event_success": "New quest has been created successfully",
        "Create_event_fail": "The errors occurred while creating quest",
        "suspend_list": "Suspend List"
    },
    "voucherSettings": {
        "Search_here": "Search here",
        "Voucher_code": "Code #",
        "Campaign": "Campaign",
        "Type": "Type",
        "Value": "Value",
        "Valid_from": "Valid From",
        "Valid_to": "Valid To",
        "isActive": "Statut",
        "Activate": "Activer",
        "Deactivate": "Désactiver",
        "Actions": "Actions",
        "Active": "actif",
        "Inactive": "Inactive",
        "redeemed": "Redeemed",
        "expired": "Expiré ",
        "Add_Voucher_code": "Add code",
        "Add": "Ajouter ",
        "Show_per_page": "Items per page",
        "Page": "Page",
        "Name": "Nom",
        "show_pages": "{0} - {1} of {2} shown",
        "Edit": "Modifier",
        "Delete": "Delete",
        "Save": "Save",
        "Duplicate": "Duplicate",
        "Cancel": "Cancel",
        "Required_fields": "Required fields",
        "Detail_voucher_code": "Detail Voucher Code",
        "Delete_voucher_code": "Delete Voucher Code",
        "Confirmation": "Confirmation",
        "Delete_confirm_voucher_code": "Do you want to delete selected voucher code?",
        "Delete_voucher_code_confirmation": "Do you want to delete these voucher codes?",
        "Just_delete_inactive": "** Just only voucher code in the inactive status can be deleted.",
        "Loading_voucher_code_fail": "The errors occurred while loading voucher code",
        "Select_campaign": "Select Campaign",
        "Create_voucher_code_success": "New voucher code has been created successfully",
        "Create_voucher_code_fail": "The errors occurred while creating voucher code",
        "Update_voucher_code_status_success": "Voucher code status has been updated successfully",
        "Update_voucher_code_status_fail": "The errors occurred while updating voucher code status",
        "Delete_voucher_codes_success": "Voucher code has been deleted successfully",
        "Delete_voucher_codes_fail": "The errors occurred while deleting voucher code",
        "Delete_multiple_voucher_codes_success": "Voucher codes has been deleted successfully",
        "Delete_multiple_voucher_codes_fail": "The errors occurred while deleting voucher codes",
        "Delete_voucher_code_success": "Voucher code has been deleted successfully",
        "Delete_voucher_code_fail": "The errors occurred while deleting voucher code",
        "Update_multiple_voucher_code_status_success": "Voucher code statuses has been updated successfully",
        "Update_multiple_voucher_code_status_fail": "The errors occurred while deleting voucher code statuses",
        "Please_input_voucher_code": "Please input voucher code",
        "Please_input_voucher_code_at_least_4_characters": "Please enter at least 4 characters.",
        "Please_select_campaign": "Please select campaign",
        "Please_input_value": "Please input value",
        "Please_select_date": "Please select date",
        "Invalid_file": "File content is invalid",
        "File_size_larger_than_5Mb": "File size is larger than 5Mb",
        "Import_vouchers_success": "File has been imported successfully",
        "Import_vouchers": "Import File",
        "Number_successful_imports": "Imported items",
        "Number_skipped": "Skip items",
        "File": "File",
        "Phone_Numbers": "phone number(s)",
        "Import_voucher_code_success": "Voucher code <b>{0}</b> has been imported successfully",
        "Import_voucher_code_fail": "Voucher code <b>{0}</b> has been failed to import. Please try again",
        "All": "Tout"
    },
    "pointConfig": {
        "point_config": "Point config",
        "Convert_spent_amount_to_points": "Convert spent amount to points",
        "Convert_topup_amount_to_points": "Convert topup amount to points",
        "Redeem_points_to_voucher": "Redeem points to voucher",
        "others": "Autres",
        "Online_Payment": "Online payment",
        "transport": "Transport",
        "parcel": "Colis ",
        "food": "Aliments ",
        "mart": "Marché ",
        "delivery": "Livraison",
        "intercity": "Intercity",
        "applyCash": "Apply for cash",
        "point": "Point",
        "vourcherName": "Nom",
        "pointsToRedeem": "Points to redeem",
        "vourcherValue": "Voucher value",
        "validTime": "Valid time",
        "month": "Month(s)",
        "day": "Day(s)",
        "termAndCondition": "Terms & conditions",
        "Actions": "Actions",
        "add_voucher": "Ajouter ",
        "instructionURL": "Instruction link",
        "displayName": "Show name on super app",
        "isActive": "actif",
        "EDIT_VOUCHER": "Update Voucher",
        "DETAIL_VOUCHER": "Voucher Details",
        "CREATE_VOUCHER": "Add Voucher",
        "update_successfully": "Point config setting has been updated successfully",
        "Create_voucher_successfully": "New voucher has been created successfully",
        "Create_voucher_fail": "The errors occurred while creating voucher",
        "Update_voucher_successfully": "Voucher has been updated successfully",
        "Update_voucher_fail": "The errors occurred while updating voucher",
        "Delete_voucher_successfully": "Voucher has been deleted successfully",
        "Delete_voucher_fail": "The errors occurred while deleting voucher",
        "Confirmation": "Confirmation",
        "Delete_voucher_confirmation": "Do you want to delete this voucher?",
        "Please_enter_value_least_one": "Conversion rate is required! Set up spent amount or top-up amount to continue.",
        "Please_enter_value_is_number_spentAmount": "Please enter valid amount at \"Convert spent amount\" service \"{0}\".",
        "Please_enter_value_is_number_topUp": "Please enter valid amount at \"Convert topup amount\" method \"{0}\"."
    },
    "resetPassword": {
        "New_Password": "Nouveau mot de passe",
        "New_Comfirm_Password": "Confirmez le mot de passe",
        "Save": "Enregistrer",
        "INFO_SUCCESS": "Un lien de réinitialisation de mot de passe a été envoyé à votre email ",
        "TOKEN_EXPIRED": "Your reset password token has been expired",
        "Back_To_Login_Page": "Back To Login Page"
    },
    "permission": {
        "editBooking": "Edit farm-out bookings",
        "companyOperator": "Company Operator",
        "Third_Party_Integration_Booking": "3rd party Integration (Booking.com)",
        "Third_Party_Integration_HolidayTaxis": "3rd party Integration (HolidayTaxis)",
        "supplierPermission": "Supplier permission",
        "Hint_edit_booking": "Allow companies/suppliers to edit bookings farmed out by the fleet owner.",
        "View": "Vue",
        "Actions": "Actions",
        "EditFare": "Edit Fare",
        "EditDriverEarning": "Edit Driver Earning",
        "EditTotal": "Edit Total",
        "ImportBookings": "Import Transport Bookings",
        "ImportDeliveryBookings": "Import Delivery Bookings",
        "ShowFare": "Show Fare",
        "delete_permission": "Delete Permission",
        "DELETE_CONFIRM": "Do you want to delete permission!",
        "Yes": "Oui",
        "No": "Non",
        "Cancel": "Annuler",
        "DRIVERS": "Chauffeurs",
        "Driver_list": "Liste de Pilotes",
        "Enter_to_search": "Entrer pour rechercher",
        "Permitted_driver_list": "Liste des Pilotes autorisés",
        "Save": "Enregistrer",
        "CAR": "Véhicule",
        "Car_list": "Liste des véhicules",
        "Permitted_car_list": "Liste des véhicules autorisés",
        "Edit_permission": "Modifier Permission",
        "Add_permission": "Ajouter Permission",
        "Detail_permission": "Autorisation détaillée",
        "Name": "Nom",
        "Driver_Car": "Chauffeur/ Véhicule",
        "All": "Tous",
        "Company": "Compagnie",
        "By_specific": "Par spécificité",
        "Drivers": "Chauffeurs",
        "Cars": "Véhicules",
        "Show_per_page": "Eléments par page",
        "Page": "Page",
        "show_pages": "{0} - {1} of {2} shown",
        "Delete": "Effacer",
        "Edit": "Modifier",
        "Status": "Statut",
        "Action": "action",
        "Inactive": "Inactif",
        "Active": "Actif",
        "Update_status_error": "Update status error",
        "All_tooltip": "Tous les chauffeurs seront affichés sur la Carte, Cue, Chauffeurs, Opération et Rapports. Tous les véhicules seront affichés dans la Gestion de Véhicules",
        "Company_tooltip": "Les chauffeurs autorisés pour cette entreprise seront affichés dans les sections Carte, Cue, Chauffeurs,, Opérations et Rapports. Les véhicules autorisés de cette entreprise seront affichés dans la Gestion de Véhicules",
        "By_specific_tooltip": "Les chauffeurs autorisés de cette liste seront affichés dans les sections Carte, Cue, Chauffeurs,, Opérations et Rapports. Les véhicules autorisés de cette liste seront affichés dans la Gestion de Véhicules",
        "Full_permissions": "Full permissions",
        "ChangeBalance": "Change Credit Balance",
        "ChangeCashBalance": "Change Cash Balance",
        "TotalBalance": "Solde total",
        "WriteOffDebt": "Radier la dette",
        "topupPaxWallet": "Modification du solde",
        "SeFleetCommission": "Set fleet commission",
        "EditFleetCommission": "Edit fleet commission",
        "EditBalance": "Edit Balance",
        "ViewHeatMap": "Afficher les cartes thermiques",
        "Delete_permission_success": "The permission has been deleted successfully",
        "Delete_permission_fail": "The errors occurred while deleting permission",
        "Create_permission_fail": "The errors occurred while creating new permission",
        "Create_permission_success": "New permission has been created successfully",
        "Update_permission_fail": "The errors occurred while updating permission",
        "Update_permission_success": "The permission has been updated successfully",
        "No_action_selected": "Please tick at least one action in the action list",
        "Required_fields": "Champs requis",
        "sendSMS": "Send Message (SMS)",
        "sendInbox": "Send Message (Inbox)",
        "PayToDriver": "View 'Pay to Driver'",
        "PayoutHistory": "View 'Payout History'",
        "Pay": "Pay driver",
        "ActivateDriver": "Activate Driver",
        "Export": "Export",
        "Export_csv": "Export .csv",
        "Street-sharing_rate": "Street sharing rate"
    },
    "thirdPartySettings": {
        "category": "Category",
        "Not_over_limit_subs": "You can not create more than 10 sub-locations",
        "Show_per_page": "Eléments par page",
        "Page": "Page",
        "show_pages": "{0} - {1} of {2} shown",
        "Delete": "Effacer",
        "Edit": "Modifier",
        "Status": "Statut",
        "Actions": "Actions",
        "Action": "action",
        "Inactive": "Inactif",
        "Active": "Actif",
        "website": "Website",
        "Check_Google_Address": "Vérifier l'adresse Google de la latitude et de la longitude",
        "Delete_Category_success": "Category has been deleted successfully",
        "Delete_category_fail": "The errors occurred while deleting category",
        "availableTime": "Available time",
        "Please_choose_at_least_one_category": "Please choose at least one category",
        "Please_input_category_name": "Please input category name",
        "Create_category_success": "Category has been created successfully",
        "Create_category_fail": "The errors occurred while creating category",
        "Update_third_party_success": "L'emplacement a été mis à jour avec succès",
        "Update_category_fail": "The errors occurred while updating category",
        "All_Category": "All category",
        "Delete_Category": "Edit category",
        "Edit_Category": "Edit category",
        "Add_Category": "Add category",
        "Add_Third_Party_location": "Ajouter",
        "Add": "Ajouter",
        "Edit_Third_Party_location": "Modifier l'emplacement de la 3ème partie",
        "Location_Not_Found": "L'emplacement est invalide.",
        "Create_third_party_success": "L'emplacement a été créé avec succès",
        "Create_third_party_fail": "Les erreurs sont survenues lors de la création du lieu",
        "Update_third_party_fail": "Les erreurs sont survenues lors de la mise à jour",
        "Alias": "Alias",
        "Address": "Adresse",
        "Latitude": "Latitude",
        "Longitude": "Longitude",
        "Please_input_address": "Veuillez entrer l'adresse",
        "Please_input_name": "Please input location name",
        "Please_input_latitude": "Veuillez entrer la latitue",
        "Please_input_longitude": "Veuillez entrer la longitude",
        "Save": "Sauvegarder",
        "Cancel": "Annuler",
        "Required_fields": "Champs requis",
        "Last_update": "Dernière mise à jour",
        "Username": "Nom d'utilisateur",
        "locationType": "LocationType",
        "single": "Emplacement unique",
        "multiple": "Plusieurs emplacements",
        "AddMore": "Add more",
        "locationList": "Liste des lieux",
        "instructionLink": "Lien d'instruction",
        "location": "Localisation",
        "checkOnMap": "Vérifiez sur la carte",
        "Remove": "Retirer",
        "placeType": "Type",
        "all": "Tout",
        "PU": "Pickup",
        "DO": "Drop off",
        "namePlaceholder": "p.ex. Terminal 1",
        "instructionLinkPlaceholder": "Page HTML avec des instructions complètes",
        "instructionLinkTooltip": "Veuillez entrer un lien pour la page d'instruction. Le passager peut suivre les instructions sur ce lien pour trouver le lieu de prise en charge.",
        "longitudePlaceholder": "108.18379690000006",
        "latitudePlaceholder": "16.0393381",
        "Error_message_lat_in_range": "La latitude doit être comprise entre -90.0 et 90.0",
        "Error_message_lng_in_range": "La longitude doit être comprise entre -180.0 et 180.0",
        "Delete_third_party_success": "L'emplacement a été supprimé avec succès",
        "Delete_third_party_fail": "Les erreurs se sont produites lors de la suppression de l'emplacement",
        "Delete_location": "Delete location",
        "Note_guide": "Note: when searching on the map, your location will be quickly marked with a pin.",
        "PostalCode": "Postal Code"
    },
    "city": {
        "DELETE_CITY": "Delete city",
        "DELETE_CONFIRM": "Do you want to delete city?",
        "Yes": "Oui",
        "No": "Non",
        "Cancel": "Annuler",
        "EDIT_CITY": "Modifier la Ville",
        "ADD_CITY": "Ajouter Une Ville",
        "Name": "Nom De La Ville",
        "Required_fields": "Champs requis",
        "Save": "Enregistrer",
        "Add": "Ajouter",
        "Show_per_page": "Eléments par page",
        "Page": "Page",
        "show_pages": "{0} - {1} of {2} shown",
        "Delete": "Effacer",
        "Edit": "Modifier",
        "DETAIL_CITY": "Détail de la ville",
        "Update_city_success": "The city has been updated successfully",
        "Create_city_success": "New city has been created successfully",
        "Delete_city_success": "The city has been deleted successfully",
        "cityName": "Nom de la ville",
        "Service": "Un service ",
        "serviceTransportation": "Transportation",
        "serviceAssistant": "Assistant",
        "serviceDelivery": "Livraison",
        "isShowDriverSignUp": "Afficher dans l'inscription du fournisseur",
        "note_isShowDriverSignUp": "(S'applique au nouveau module d'inscription à partir de 4.6.46 et plus)"
    },
    "jobTab": {
        "DELETE_JOBTAB": "Delete Jobs Tab",
        "DELETE_CONFIRM": "Are you sure you want to delete the selected Jobs Tab?",
        "Yes": "Yes",
        "No": "No",
        "Cancel": "Cancel",
        "EDIT_JOBTAB": "Edit Job Tab",
        "ADD_JOBTAB": "Add Jobs Tab",
        "Name": "Name",
        "Required_fields": "Required fields",
        "Save": "Save",
        "Add": "Add",
        "Show_per_page": "Items per page",
        "Page": "Page",
        "show_pages": "{0} - {1} of {2} shown",
        "Delete": "Delete",
        "Edit": "Edit",
        "DETAIL_JOBTAB": "Detail Jobs Tab",
        "Update_JOBTAB_success": "The Jobs Tab has been updated successfully",
        "Create_JOBTAB_success": "New Jobs Tab has been created successfully",
        "Delete_JOBTAB_success": "The Jobs Tab has been deleted successfully",
        "JOBTABName": "Jobs Tab name",
        "jobsLink": "Link"
    },
    "driverNote": {
        "name": "Driver note",
        "DELETE": "Delete Driver Note",
        "DELETE_CONFIRM": "Are you sure you want to delete the selected Driver Note?",
        "EDIT": "Edit Driver Note",
        "ADD": "Add Driver Note",
        "DETAIL": "Detail Driver Note",
        "Update_success": "The Driver Note has been updated successfully",
        "Create_success": "New Driver Note has been created successfully",
        "Delete_success": "The Driver Note has been deleted successfully",
        "Delete_fail": "The errors occurred while deleting driver note",
        "Load_fail": "The errors occurred while loading driver note",
        "Update_fail": "The errors occurred while updating driver note",
        "Create_fail": "The errors occurred while creating driver note"
    },
    "flightIntegration": {
        "Username": "Nom d'utilisateur",
        "provider": "Flight Provider",
        "Key": "API Key",
        "apiId": "API Id",
        "Save": "Enregistrer",
        "Update_flight_integration_success": "The flight integration has been updated successfully",
        "Update_flight_integration_fail": "The errors occurred while updating flight integration"
    },
    "smsIntegration": {
        "provider": "SMS Provider",
        "apiToken": "API Token",
        "subAccountId": "Sub Account ID",
        "senderIdSource": "Sender ID (source):",
        "apiKey": "API Key",
        "username": "Username",
        "shortCode": "Short Code",
        "key": "Key",
        "webService": "Web service",
        "bearerToken": "Bearer token",
        "keyId": "Key ID",
        "email": "Email",
        "appKey": "App key",
        "sender": "Sender",
        "password": "Password",
        "maskedPhone": "Masked phone number",
        "senderId": "Sender ID",
        "webservice": "Web service",
        "brandname": "Brand name",
        "appSecret": "App secret",
        "consumerKey": "Consumer key",
        "requestUrl": "Request Url",
        "authId": "Auth ID",
        "authToken": "Auth Token",
        "authKey": "Auth Key",
        "templateId": "Template Id",
        "templateKey": "Template Key",
        "phloId": "PHLO ID",
        "dlcNumber": "10DLC Number",
        "app_key": "App key",
        "api_key": "API key",
        "accountSID": "Account SID",
        "applicationId": "Application ID",
        "phoneNumber": "SMS number 1",
        "phoneNumber2": "SMS number 2",
        "SenderID_1": "SenderID 1",
        "SenderID_2": "SenderID 2",
        "smsMarketingNumber": "SMS makerting phone number",
        "updateSMSSuccess": "The sms integration has been updated successfully",
        "Deleted_success": "The SMS has been deleted successfully",
        "receiverCountries": "Receiver Countries",
        "error": {
            "ERROR_CREATE": "The errors occurred while creating sms",
            "COUNTRY_EXISTED": "Country already exist on system",
            "ERROR_DELETE": "Failed to delete SMS",
            "ERROR_UPDATE": "Failed to update SMS"
        },
        "identifierId": "Identifier ID"
    },
    "mDispatcher": {
        "mDispatchers": "mDispatchers",
        "Active": "Actif",
        "Inactive": "Inactif",
        "Deactivate": "Désactiver",
        "Activate": "Activer",
        "Delete": "Effacer",
        "Save": "Enregistrer",
        "Edit": "Modifier",
        "ADD_MDISPATCHER": "Ajouter un mDispatcher",
        "EDIT_MDISPATCHER": "Modifier un mDispatcher",
        "Phone_number": "Numéro de téléphone",
        "first_name": "Prénom",
        "last_name": "Nom",
        "email": "Email",
        "address": "Adresse",
        "mDispatcher_type": "Type mDispatcher",
        "mDispatcher_type_toolTip": "Pour ajouter d'autres types de partenaires, accédez à Paramètres> Type de partenaire",
        "queuing_area_toolTip": "Pour ajouter d'autres zones de file d'attente, veuillez aller dans le menu Zone de file d'attente.",
        "mDispatcher": "mDispatcher",
        "card_management": "Card Management",
        "Select_mDispatcher_type": "Sélectionner le type mDispatcher",
        "Amount": "Montant",
        "Percent": "Pour cent",
        "Yes": "Oui",
        "Required_fields": "Champs requis",
        "Cancel": "Annuler",
        "commission_type": "Type de Commission",
        "commission_value": "Montant de la Commission",
        "Name": "Nom",
        "Phone_Number": "Numéro de téléphone",
        "queuing_area": "Zone de file d'attente",
        "mdispatcher_type": "Type mDispatcher",
        "registered_from": "Enregistré à partir de",
        "registration_date": "Date d'enregistrement",
        "status": "Statut",
        "actions": "Actions",
        "Select_queue_area": "Sélectionner la zone de file d'attente",
        "for_new_mDipatcher_account_password_default_is_password": "Pour un nouveau compte mDispatcher, le mot de passe par défaut est \"12345678\"",
        "Create_mDispatcher_success": "Le nouveau mDipatcher a été créé avec succès",
        "Create_mDispatcher_fail": "Les erreurs sont survenues lors de la création de mDispatcher",
        "Load_mDispatcher_fail": "Les erreurs sont survenues lors du chargement de mDispatcher",
        "Update_mDispatcher_success": "mDipatcher a été mis à jour avec succès",
        "Update_mDispatcher_fail": "Les erreurs sont survenues lors de la mise à jour de mDispatcher",
        "Update_mDispatcher_status_success": "Le statut de mDipatcher a été mis à jour avec succès",
        "Update_mDispatcher_status_fail": "Les erreurs se sont produites lors de la mise à jour de l'état de mDispatcher",
        "Update_mDispatcher_statuses_success": "Les statuts de mDipatcher ont été mis à jour avec succès",
        "Update_mDispatcher_statuses_fail": "Les erreurs se sont produites lors de la mise à jour des statuts de mDispatcher",
        "Delete_mDispatcher_success": "mDipatcher a été supprimé avec succès",
        "Delete_mDispatcher_fail": "Les erreurs se sont produites lors de la suppression de mDispatcher",
        "Delete_mDispatchers_success": "mDipatchers a été supprimé avec succès",
        "Delete_mDispatchers_fail": "Les erreurs se sont produites lors de la suppression de mDispatchers",
        "Do_you_want_to_delete_these_mDipatchers": "Do you want to delete these mDipatchers?",
        "Only_mDistcher_in_the_inactive_status_can_be_deleted": "***Just only mDistcher in the inactive status can be deleted",
        "No_card": "Pas de carte trouvée",
        "Reset_Password": "Réinitialiser le mot de passe",
        "Reset_password_success": "mDispatcher password has been reset successfully",
        "Reset_password_fail": "Les erreurs se sont produites lors de la réinitialisation du mot de passe mDispatchers",
        "Do_you_want_to_reset_password": "Do you want to reset password ?",
        "ERROR_EXISTED_PHONE": "Ce téléphone existe déjà sur le système. Veuillez en saisir un autre.",
        "ERROR_INPUT_VALIDPHONE": "Veuillez spécifier un numéro de téléphone valide",
        "Partner_detail": "DETAIL MDISPATCHER TYPE",
        "ALERT_SELECT_ACTIVATE": "Veuillez sélectionner le mDispatcher à activer",
        "ALERT_SELECT_DEACTIVATE": "Veuillez sélectionner le mDispatcher à désactiver",
        "CONFIRM_DEACTIVATE_MULTI": "Voulez-vous supprimer ces mDispatcher?",
        "CONFIRM_ACTIVATE_MULTI": "Voulez-vous activer ces mDispatcher?",
        "ERROR_COMMISSION_NUMBERS": "S'il vous plait, entrez un nombre valide",
        "No_Support": "New card adding is currently not supported in this area",
        "Add": "Ajouter",
        "Default_zone": "Default zone",
        "required_email_payment": "The email of partner are required. Please update profile to continue.",
        "titleGuestQR": "Partner Guest-QR Link",
        "questQRContent": "Your personalized partner booking link is here! Display it prominently in bars, hotel receptions, guest rooms, and on your website—anywhere your customers might need a ride or delivery. Consider designing standees with your unique QR code, allowing customers a quick and easy scan to book their journey!",
        "View_Guest_QR": "View Guest QR"
    },
    "mDispatcherType": {
        "Name": "Nom De La Ville",
        "Required_fields": "Champs requis",
        "Save": "Enregistrer",
        "Cancel": "Annuler",
        "No": "Non",
        "Add": "Ajouter",
        "Show_per_page": "Eléments par page",
        "Page": "Page",
        "show_pages": "{0} - {1} of {2} shown",
        "Delete": "Effacer",
        "Edit": "Modifier",
        "DELETE_MDISPATCHER_TYPE": "Effacer Type mDispatcher",
        "DELETE_CONFIRM": "Are you sure to delete mDispatcher Type?",
        "Yes": "Oui",
        "EDIT_MDISPATCHER_TYPE": "Modifier Type mDispatcher",
        "ADD_MDISPATCHER_TYPE": "Ajouter Type mDispatcher",
        "DETAIL_MDISPATCHER_TYPE": "Détail mDispatcher Type",
        "Actions": "Actions",
        "Delete_mDispatcherType_success": "Le type mDispatcher a été supprimé avec succès",
        "Delete_mDispatcherType_fail": "Les erreurs se sont produites lors de la suppression du type mDispatchers",
        "Load_mDispatcherType_fail": "Les erreurs se sont produites lors du chargement du type mDispatchers",
        "Create_mDispatcherType_success": "Le nouveau type mDispatcher a été créé avec succès",
        "Create_mDispatcherType_fail": "Les erreurs se sont produites lors de la création du type mDispatchers",
        "Update_mDispatcherType_success": "Le type mDispatcher a été mis à jour avec succès",
        "Update_mDispatcherType_fail": "The errors occurred while updating mDispatchers type"
    },
    "queuingArea": {
        "queuing_area_info": "Queue area info",
        "queuingDrivers": "Active drivers",
        "Name": "Nom",
        "Required_fields": "Champs requis",
        "Save": "Enregistrer",
        "Cancel": "Annuler",
        "Yes": "Oui",
        "No": "Non",
        "Add": "Ajouter",
        "Show_per_page": "Eléments par page",
        "Enter_to_search": "Enter to search",
        "Page": "Page",
        "show_pages": "{0} - {1} of {2} shown",
        "Delete": "Effacer",
        "Edit": "Modifier",
        "Active": "Actif",
        "Inactive": "Inactif",
        "Deactivate": "Désactiver",
        "Activate": "Activer",
        "drivers": {
            "order": "Order",
            "Driver_name": "Driver name",
            "Phone_Number": "Numéro de téléphone",
            "driverLicenseNumber": "License plate",
            "Car_Type": "Car type",
            "checkInTime": "Heure d'arrivée",
            "duration": "Duration",
            "timestampScore": "Score d'horodatage",
            "changeOrderDriver": "Change l'ordre",
            "detailOrderDriver": "Driver order",
            "Yes": "Yes",
            "No": "No",
            "insert": "Insérer",
            "refresh": "Rafraîchir",
            "insertDriver": "Insérez un pilote dans la file d'attente",
            "orderDriver": "Commande #",
            "reason": "Raison",
            "removeDriver": "Remove Driver from Queue",
            "removeDriverConfirm": "Voulez-vous supprimer ce pilote de la file d'attente?",
            "driverNotStay": "Le pilote n'est pas dans la zone de file d'attente. Veuillez réessayer plus tard.",
            "overOrder": "The order # cannot be greater than the total number of drivers in the queue.",
            "noDriverFound": "No driver found",
            "driverUpdateErrRs": "The driver is not in the queue area. Please try again later.",
            "orderUpdateErrRs": "The order # cannot be greater than the total number of drivers in the queue.",
            "removeDriverSuccess": "The driver has been successfully removed from the queue area.",
            "removeDriverFail": "An error occurred while removing driver from the queue area.",
            "operationInsertFailRs": "The driver does not exist or is unavailable.",
            "versionInsertFailRs": "Pour appliquer cette fonctionnalité, le pilote doit mettre à niveau son application de pilote vers la dernière version",
            "queueInsertFailRs": "The queue does not exist or the driver is not in the queue area. Please try again later.",
            "vehicleInsertFailRs": "The queue does not support the vehicle type of driver.",
            "orderInsertFailRs": "The order # cannot be greater than the total number of drivers in the queue.",
            "positionInsertFailRs": "An error occurred while inserting driver into the queue area.",
            "insertDriverSuccess": "The driver has been successfully inserted to the queue area.",
            "changeOrderSuccess": "The driver's position has been successfully changed.",
            "insertDriverFail": "An error occurred while inserting driver from the queue area.",
            "changeOrderFail": "An error occurred while changing driver's position from the queue area.",
            "selectToInsert": "Search: Driver name, Phone number",
            "updateBy": "Updated by operator",
            "changeOrder": "Change order",
            "remove": "Retirer"
        },
        "keepDriverPlaceCancelNoShow": "Gardez la place du conducteur lorsque la réservation est annulée / non-présentation",
        "keepDriverPlaceUnavailable": "Gardez la place du conducteur lorsque le pilote n'est pas disponible ou hors ligne",
        "range": "Queue range",
        "queueRangeTooltip": "Voici comment les conducteurs verront leur numéro de position de mise en file d'attente sur l'application du conducteur. Par exemple:\nDéfinition de la plage de file d'attente à 1: les conducteurs verront leur position dans la file d'attente sous la forme d'un numéro unique: 1, 2, 3, 4, etc.\nDéfinition de la plage de file d'attente à 5: les conducteurs verront leur position dans la file d'attente sous la forme d'une plage: 1-5, 6-10, 11-15, 16-20, etc.\nDéfinition de la plage de file d'attente à 10: les conducteurs verront leur position dans la file d'attente sous la forme d'une plage: 1-10, 11-20, 21-30, 31-40, etc. ",
        "queueCanceledTooltip": "1. Lorsque le passager annule ou ne se présente pas, le conducteur restera en position supérieure s'il revient dans la file d'attente dans le délai fixé.\n2. La position du conducteur sera prioritaire si le conducteur revient dans la file d'attente dans le délai défini après avoir effectué une réservation pour un court trajet. ",
        "DELETE_QUEUING_AREA": "Delete Queuing Area",
        "DELETE_CONFIRM": "Do you want to delete Queuing Area?",
        "EDIT_QUEUING_AREA": "Modifier la zone de file d'attente",
        "ADD_QUEUING_AREA": "Ajouter zone de file d'attente",
        "DETAIL_QUEUING_AREA": "Détail de la zone de mise en file d'attente",
        "Queuing_area": "Zone de file d'attente",
        "Address": "Adresse",
        "Enter_a_location": "Entrez un lieu",
        "Entered_Address_is_incorrect": "Le système ne peut pas déterminer votre position. Veuillez utiliser l'adresse suggéré.",
        "Not_supported_zone": "La zone de file d'attente n'est pas dans",
        "Active_mDispatcher_list": "Liste des mDispatcher actifs",
        "Queue_list": "Liste de file d'attente",
        "Allow_radius": "Rayon autorisé",
        "Min_drivers_required": "# Min Pilotes nécessaires ",
        "Open_queuing_area": "Ouvrir la zone de file d'attente",
        "Auto_dispatch": "Envoi automatique aux pilotes en file d'attente (inutile d'utiliser l'application partenaire)",
        "Active_car_list": "Liste des véhicules actifs",
        "mDispatcher": "mDispatcher",
        "Google_Address": "Adresse Google",
        "Status": "Statut",
        "Update_status_error": "Update status error",
        "driver_checkin_area": "Zone d'enregistrement des chauffeurs",
        "EDIT_POLYGONS": "Modifier les polygones",
        "pax_pickup_area": "Zone de prise en charge des passagers",
        "pickup_area": "Zone de prise en charge",
        "your_chosen_polygon": "Votre polygone choisi",
        "other_queues": "Other queues",
        "operation_zones": "Operation zones",
        "delete_pickup_area_confirm": "Voulez-vous vraiment supprimer cette zone deprise en charge?",
        "delete_pickup_area": "Veuillez choisir la zone d'enregistrement du chauffeur.",
        "please_choose_driver_check_in_area": "Veuillez choisir une zone de prise en charge.",
        "please_choose_pick_up_area": "Please choose pick-up area.",
        "Load_queuing_area_fail": "The errors occurred while loading queuing area",
        "Create_queuingArea_success": "Queuing area has been created successfully",
        "Update_queuingArea_success": "Queuing area has been updated successfully",
        "Delete_queuingArea_success": "Queuing area has been deleted successfully",
        "Delete_queuingArea_fail": "The errors occurred while deleting queuing area",
        "Create_queuingArea_fail": "The errors occurred while creating queuing area",
        "Load_queuingArea_fail": "The errors occurred while loading queuing area",
        "Update_queuingArea_fail": "The errors occurred while updating queuing area"
    },
    "appBanner": {
        "Save": "Enregistrer",
        "Pax_app": "Appli passager",
        "Apply_to": "Appliquer à",
        "Please_select_view": "Veuillez sélectionner une vue",
        "Select_Type": "Veuillez sélectionner le type de bannière",
        "Home_view": "Vue d'ensemble",
        "Other_view": "Autre vue",
        "Banner_1": "Banière #1",
        "Upload_an_image": "Aucune image sélectionnée",
        "Action": "action",
        "NA": "N/A",
        "Link_to_website": "Lien vers le site web",
        "Make_a_call": "Passer un appel",
        "Send_an_email": "Envoyer un mail",
        "Banner_2": "Banière #2",
        "Banner_3": "Banière #3",
        "Driver_app": "Appli Chauffeur",
        "pax": "Passgener App",
        "driver": "Application conducteur ",
        "Name": "Nom",
        "viewType": "Type",
        "status": "Statut",
        "image": "Image",
        "html": "HTML",
        "Delete_Banner": "Delete banner",
        "Delete_confirm_banner": "Êtes-vous sûr de vouloir supprimer la bannière sélectionnée?",
        "Delete_banner_success": "Banner has been deleted successfully",
        "Delete_banner_fail": "The errors occurred while deleting banner",
        "edit_banner": "Edit Banner",
        "detail_banner": "Detail Banner",
        "create_banner": "Ajouter une bannière",
        "note_1": "*L'extension de fichier doit être .jpg, .jpeg, .png, .gif et moins de 8MB.",
        "note_2": "* Les images doivent être au format 16:9",
        "note_3": "*Les autres vue comprennent Boîte de réception, Parrainer un ami, Factures, Mes réservations, Codes Promo, Ajouter une CB/Modes de paiement, Profil, En attente d'execution/Réservation Confirmée, Vue complète.",
        "Update_app_banner_success": "App banner has been updated successfully",
        "Update_app_banner_fail": "Failed to update the app banner!",
        "Create_app_banner_success": "App banner has been created successfully",
        "Create_app_banner_fail": "Failed to create the app banner!",
        "Note": "Veuillez utiliser le fichier au format .png (fond transparent, taille 400:200px) pour une meilleure performance de l'application.",
        "Limit_size_upload": "The file size must be less than 8Mb.",
        "display_duration": "Durée d'affichage",
        "playback_settings": "Paramètres de retour",
        "display_banner_again_after": "Display banners again after",
        "stop_display_banner_after": "Stop display banner after",
        "enable_playback": "Activer le retour",
        "Update_playback_success": "Playback settings has been updated successfully",
        "Update_playback_fail": "Failed to update the playback settings!",
        "language": "Langue",
        "single": "Langue unique",
        "multi": "Multilingue",
        "es-ES": "Spanish",
        "en-US": "English",
        "fr-FR": "French",
        "id-ID": "Bahasa indonesia",
        "vi-VN": "Vietnamese",
        "ur-PK": "Urdu",
        "ms-MY": "Malaysia",
        "ar-AR": "Arabic",
        "nb-NO": "Norwegian",
        "en-GB": "English (United Kingdom)",
        "am-ET": "Amharic",
        "ro-RO": "Romanian",
        "version": "App Version",
        "comparisonVersionApp": {
            "is": "Is",
            "is_not": "Is not",
            "is_one_of": "Is one of",
            "is_not_one_of": "Is not one of",
            "greater_or_equal": "Greater or equal",
            "less_or_equal": "Less or equal"
        },
        "platform": "Plateforme",
        "platformItem": {
            "all": "Tout",
            "iOS": "iOS",
            "android": "Android"
        },
        "userStatus": "User status",
        "Active": "actif",
        "Inactive": "Inactive",
        "in_review_completed": "En cours de révision (profil complété)",
        "in_review_incomplete": "En cours de révision (profil incomplet)",
        "in_review_inProgress": "In review (En cours de révision (profil en cours))",
        "zones": "Zones"
    },
    "reservation": {
        "name": "Reservation",
        "company": "Entreprise",
        "carType": "Car type",
        "licensePlate": "License Plate: %{name}",
        "driver": "Driver: %{name}",
        "showAllDrivers": "Show All Drivers",
        "available": "Disponible ",
        "reserved": "Reserved",
        "search_holder": "Search here",
        "all": "Tout",
        "today": "Today",
        "thisWeek": "Cette semaine",
        "day": "Day",
        "week": "Week",
        "bookingID": "ID de réservation",
        "tripId": "Trip ID",
        "pickupTime": "Pickup time",
        "pickupLocation": "Pickup location",
        "monday": "Monday",
        "tuesday": "Tuesday",
        "wednesday": "Wednesday",
        "thursday": "Thursday",
        "friday": "Friday",
        "saturday": "Saturday",
        "sunday": "Sunday",
        "overlapped": "Overlapped",
        "noData": "No data was found.",
        "bookingDetails": "Reservation Details",
        "reservedHint": "Reservation bookings are confirmed",
        "availableHint": "Don't have any confirmed reservation bookings",
        "overlappedHint": "Two or more confirmed reservation bookings overlap"
    },
    "dashboard": {
        "API": "API",
        "cc": "CC",
        "dmc": "Tableau de réservation",
        "streetSharing": "Street-Sharing",
        "kiosk": "Kiosque",
        "mDispatcher": "mDispatcher",
        "paxApp": "Super App",
        "webBooking": "Réservation Web",
        "carHailing": "Locations Véhicules",
        "acceptance": "Acceptation",
        "booking_com": "Booking.com",
        "completed": "Terminé",
        "noShow": "No Show",
        "canceled": "Annulé",
        "incident": "Incident",
        "rate1": "1 Étoile",
        "rate2": "2 Étoile",
        "rate3": "3 Étoile",
        "rate4": "4 Étoile",
        "rate5": "5 Étoile",
        "Export_to_Excel": "Export to Excel",
        "Profit": "Gain",
        "day_change": "Taux journalier",
        "week_change": "Taux hebdomadaire ",
        "month_change": "Taux mensuel",
        "Booking_requests": "Les réservations",
        "Complete_bookings": "Demandes finalisées",
        "New_customers": "Nouveaux clients",
        "Booking_dashboard": "Booking board",
        "others": "Autres",
        "Revenue_booking": "Gains et réservations finalisées",
        "Profit_finish_booking": "Gains et réservations finalisées",
        "Booking_request": "Booking request",
        "Finished_booking": "Réservations finalisées",
        "failed": "Failed",
        "partialCompleted": "Partial Completed",
        "Finished_booking_char_header": "Réservations finalisées ({0})",
        "Bookings": "Bookings ({0})",
        "Ratings": "Étoile ({0})",
        "Daily": "Journalier",
        "Weekly": "Hebdomadaire",
        "Monthly": "Mensuel",
        "Total_customer": "Total customer {0}",
        "ProfitToolTips": "L'argent que la flotte gagne après avoir exclu toutes les dépenses",
        "RevenueToolTips": "L'argent que la flotte reçoit effectivement pendant la période sélectionnée, y compris la promotion, les déductions pour les conducteurs, les taxes, etc.",
        "ProfitChangeToolTips": "Le pourcentage de variation des bénéfices de la flotte par rapport à la période précédente",
        "BookingRequestsToolTips": "Nombre total de réservations créées pendant la période choisie.",
        "PercentageOfAcceptanceToolTips": "Le pourcentage de réservations acceptées pendant la période sélectionnée",
        "BookingChangeHint": "Le pourcentage de variation des réservations créées par rapport à la période précédente",
        "CompletedBookingHint": "Nombre total de réservations finalisées pendant la période choisie.",
        "PercentageOfSatisfactionHint": "Le pourcentage de satisfaction pendant la période sélectionnée",
        "CompleteBookingDailyChangeHint": "Le pourcentage de variation des réservations effectuées par rapport à la période précédente.",
        "NewCustomersHint": "Nombre total de nouveaux clients enregistrés pendant la période choisie",
        "CustomerChangeHint": "Le nombre et le pourcentage d'évolution des clients par rapport à la période précédente",
        "TotalCustomer": "Nombre total de clients dans le système",
        "ProfitAndFinishedBookingHint": "Profit & finished bookings of fleet during selected period",
        "BookingRequestHint": "Total number of on-demand bookings and reservations which are created during selected period",
        "FinishedBookingChartsHint": "Total number of on-demand bookings and reservations which are closed during selected period, including cancel, no show, incident, and completed",
        "Customer_count": "{0} clients au total",
        "Revenue": "Recette"
    },
    "referral": {
        "first_package": "1st package (i.e the first completed booking of Pax)",
        "driver_refers_to_pax": "Driver refers to Passengers",
        "driver_will_get": "Driver will get",
        "money": "Money",
        "amount": "Montant ",
        "percent": "Percent",
        "settlement": "Commission (% subtotal amount of each completed booking)",
        "pax_will_get": "Pax will get",
        "promotion_code": "Promotion Code",
        "select_a_promotion": "Select a promotion code",
        "maximum": "Maximum",
        "second_package": "2nd package",
        "from": "De ",
        "to": "À ",
        "After_2nd_package": "After 2nd package",
        "from_end_value_of_above_package": "From value of the last above package + 1",
        "Expiration_date": "Expiration date",
        "Never": "Never",
        "Customized_date": "Customized date",
        "limited": "Limité ",
        "unlimited": "Illimité ",
        "Valid_from": "Valid From",
        "Valid_to": "Valid to",
        "Invitation_limit": "Invitation limit/user",
        "Status": "Statut",
        "Active": "actif",
        "Inactive": "Inactive",
        "note": "* Please note that once the campaign is active, any update on reward values will only affect new customers.",
        "Required_fields": "Required fields",
        "refferal_update_successfully": "Referral setting has been updated successfully",
        "distributeIncentiveToDriver": "Distribute incentive to driver",
        "Immediate": "Immediate",
        "Periodic": "Periodic (Manually)",
        "Period": "Period",
        "startDate": "Start date",
        "nextDistributionDate": "Next distribution date",
        "oneMonth": "1 month",
        "threeMonth": "3 months",
        "sixMonth": "6 months",
        "passenger_refers_to_pax": "Passenger refers to Passengers",
        "referer_will_get": "Referer will get",
        "referee_will_get": "Referee will get",
        "promo": "Promotion",
        "Pax_invitation_limit": "Invitation limit/user",
        "promoPaidBy": "Promotion paid by",
        "fleet": "Flotte",
        "driver": "Client",
        "promoCode": "Promotion code",
        "isSendInbox": "Send inbox",
        "isDefaultCode": "Auto apply on app",
        "autoApplyOnWeb": "Auto apply on web",
        "isDefaultCode_hint": "Add promotion code to customer's list and auto apply to booking when customer creates order",
        "Actions": "Actions",
        "add_promoCode": "Ajouter ",
        "Send_an_in_box_automatically_to_new_customers": "Send inbox to customer",
        "set_default_promo_code": "Auto apply on app",
        "EDIT_PROMO_CODE": "Edit promotion code",
        "DETAIL_PROMO_CODE": "Promotion code details",
        "CREATE_PROMO_CODE": "Create promotion code",
        "DELETE_PROMO_CODE": "Delete promotion code",
        "DELETE_CONFIRM_PROMO_CODE": "Do you want to delete this promotion code?",
        "ERROR_INPUT_SOURCE": "Please choose at least one."
    },
    "upload_document": {
        "NO_FILE_CHOSEN": "Aucun fichier choisi",
        "Limit_size_upload": "File size exceeds the limit allowed {0}Mb."
    },
    "payment_info": {
        "member_since": "Member since",
        "package_name": "Package name",
        "last_payment": "Last payment",
        "credit_card": "Carte de crédit",
        "last_digits": "Last 4 digits",
        "default": "Défaut",
        "add_new_card": "Ajouter une nouvelle carte",
        "history": "History",
        "transaction_date": "Transaction date",
        "transaction_id": "ID de la transaction",
        "billing_cycle": "Billing cycle",
        "amount": "Montant ",
        "payment_history": "Billing history",
        "card_information": "Card information",
        "card_holder": "Card holder",
        "card_number": "Card number",
        "expiration_date": "Expiration date",
        "cvv": "CVV"
    },
    "search_by": {
        "group_id": "Group ID",
        "booking_id": "Réservation #",
        "recurring_id": "Recurring #",
        "ExternalID": "External #",
        "tripId": "Trip #",
        "driver_name": "Nom du Driver",
        "driver_phone_number": "Numéro de téléphone",
        "license_plate": "Plaque d'immatriculation",
        "vehicle": "Véhicule",
        "operator": "Opérateur",
        "passenger_email": "Email du passager",
        "passenger_name": "Nom du passager",
        "passenger_phone_number": "Numéro de téléphone du passager",
        "destination": "Destination",
        "pick_up": "Départ",
        "flight_number": "Vol #",
        "book_from": "Réservé de",
        "note": "Remarques",
        "email": "Email",
        "phone": "Numéro de téléphone"
    },
    "withdrawal": {
        "transferToEWallet": "Transferred to external e-wallet *{0}",
        "channelCode": "Channel code",
        "channelName": "Channel name",
        "reversed": "Reversed",
        "requestTime": "Demande de temps",
        "referenceId": "Reference #",
        "driverName": "Driver name",
        "phoneNumber": "Numéro de téléphone",
        "company": "Entreprise",
        "description": "Description",
        "requestAmount": "Montant de la demande",
        "transferAmount": "Montant du transfert",
        "currentBalance": "Solde actuel",
        "currency": "Devise",
        "action": "action",
        "approve": "Approve",
        "reject": "Reject",
        "transferToBankAccount": "Transférer vers le compte bancaire *{0}",
        "transferToWingBank": "Transferred to Wing bank ID *{0}",
        "transferToCreditWallet": "Tranférer vers le porte-monnaie",
        "reason": "Raison",
        "search": "Driver name, phone, company, transaction ID, reference #",
        "message": {
            "approve": "Êtes-vous sûr de vouloir payer à ce chauffeur un {0}?",
            "approveMulti": "Etes-vous sûr de vouloir régler le chauffeur séléctionné ?",
            "approveSuccess": "Selected request has been paid",
            "approveFail": "Selected request was not successful. Please try again",
            "approveMultiSuccess": "{0} des {1} demandes sélectionnées ont été payées ",
            "approveMultiFail": "{0} dans la demande sélectionnée par {1} n'a pas abouti. Veuillez réessayer",
            "buttonConfirmTitle": "Yes",
            "closeButtonText": "No",
            "pleaseInputReason": "Please input reason",
            "rejectSuccess": "La demande du chauffeur a été rejetée",
            "rejectFail": "Le rejet de la demande a échoué"
        },
        "getDataFailure": "Can't get data",
        "rejectRequest": "Reject request"
    },
    "dynamicSurcharge": {
        "operationZone": "Operation zone",
        "activeSurcharge": "Active surcharge",
        "surcharge": "Taxes ",
        "surchargeName": "Nom de la surtaxe",
        "parameter": "Paramètre",
        "pickupPoint": "Pickup zone",
        "dropOffPoint": "Drop-off zone",
        "delete_surcharge_success": "Surcharge has been deleted successfully",
        "delete_surcharge_fail": "The errors occurred while deleting surcharge",
        "update_surcharge_fail": "The errors occurred while updating surcharge",
        "update_surcharge_success": "Surcharge has been updated successfully",
        "create_surcharge_fail": "The errors occurred while creating surcharge",
        "create_surcharge_success": "Surcharge has been created successfully",
        "active_surcharge_fail": "The errors occurred while activating surcharge",
        "active_surcharge_success": "Surcharge has been activated successfully",
        "deactive_surcharge_fail": "The errors occurred while deactivating surcharge",
        "deactive_surcharge_success": "Surcharge has been deactivated successfully",
        "delete_surcharge": "Delete surcharge",
        "delete_confirm_message": "Are you sure you want to delete the selected surcharge?",
        "note": "*Note: this is how much your basic fare will be multiplied by. Example: a fare of $20 would be $30 when parameter is at 1.5x"
    },
    "dynamicFare": {
        "operationZone": "Operation zone",
        "activeFare": "Active fare",
        "fare": "Fare",
        "fareName": "Fare name",
        "factor": "Multiply by factor",
        "fix_amount": "Amount",
        "parameter": "Paramètre",
        "delete_fare_success": "Fare has been deleted successfully",
        "delete_fare_fail": "The errors occurred while deleting fare",
        "update_fare_fail": "The errors occurred while updating fare",
        "update_fare_success": "Fare has been updated successfully",
        "create_fare_fail": "The errors occurred while creating fare",
        "create_fare_success": "Fare has been created successfully",
        "active_fare_fail": "The errors occurred while activating fare",
        "active_fare_success": "Fare has been activated successfully",
        "deactive_fare_fail": "The errors occurred while deactivating fare",
        "deactive_fare_success": "Fare has been deactivated successfully",
        "delete_fare": "Delete fare",
        "delete_confirm_message": "Are you sure you want to delete the selected fare?",
        "note": "*Note: this is how much your basic fare will be multiplied by. Example: a fare of $20 would be $30 when parameter is at 1.5x",
        "note_amount": "*Note: this is how much your basic fare will be added. Example: a fare of $20 would be $25 when set amount is $5"
    },
    "notifications": {
        "search": "Search booking ID, external ID",
        "searchProviderName": "Search provider name",
        "statusItem": {
            "all": "All notifications",
            "canceledByDriver": "Canceled by driver",
            "canceledByPartner": "Canceled by partner",
            "reservationReminder": "Reservation reminder",
            "localPending": "Local - Pending",
            "reservationIsNotStarted": "Reservation isn't started",
            "partnerRequestsCars": "Partner request cars",
            "timeout": "Timeout",
            "noShow": "Affiliate - No show",
            "affiliatePending": "Affiliate - Pending",
            "affiliatePaymentFailed": "Affiliate Payment Failed",
            "affiliateTimeout": "Affiliate Timeout",
            "flightApi": "Flight API",
            "DebtWriteOff": "Effacement de dette",
            "driverCancelTrip": "Driver cancels trip",
            "minimumSeatRequired": "Minimum seats required",
            "newFarmInJobFrom3rd": "Booking.com - New booking",
            "canceledJobBy3rd": "Booking.com - Canceled",
            "updatedJobBy3rd": "Booking.com - Updated",
            "newFarmInJobFromHolidayTaxis": "HolidayTaxis - New booking",
            "canceledJobByHolidayTaxis": "HolidayTaxis - Canceled",
            "updatedJobByHolidayTaxis": "HolidayTaxis - Updated",
            "newFarmInJobFromKarhoo": "Karhoo - New booking",
            "canceledJobByKarhoo": "Karhoo - Canceled",
            "updatedJobByKarhoo": "Karhoo - Updated",
            "newFarmInJobFromHoppa": "Hoppa - New booking",
            "canceledJobByHoppa": "Hoppa - Canceled",
            "updatedJobByHoppa": "Hoppa - Updated"
        },
        "sos": {
            "sosSearch": "Search booking ID, pax name, driver name",
            "statusItem": {
                "all": "Tout",
                "driver": "Drivers",
                "passenger": "Clients",
                "crew": "Crew"
            }
        }
    },
    "smartData": {
        "No": "No.",
        "driverName": "Nom",
        "phone": "Phone",
        "rating": "Étoile",
        "completed": "Terminé",
        "noShow": "No-show",
        "incident": "Incident",
        "canceled": "Canceled by driver",
        "accepted": "Accepted",
        "reject": "Reject",
        "ignore": "Ignore",
        "earning": "Revenus",
        "query": {
            "top20": "Top 20",
            "top50": "Top 50",
            "top100": "Top 100",
            "bottom20": "Bottom 20",
            "bottom50": "Bottom 50",
            "bottom100": "Bottom 100"
        },
        "status": {
            "canceled": "Canceled by driver",
            "noShow": "No-show",
            "incident": "Incident",
            "completed": "Terminé",
            "earning": "Revenus",
            "reject": "Reject",
            "ignore": "Ignore",
            "accepted": "Accepted"
        }
    },
    "Reject": "Non présentation",
    "Do not allow Driver to start reservat": "Affiliation",
    "intercityRate": {
        "Delete_intercity_rate": "Delete intercity rate",
        "edit_intercity_rate": "Edit intercity rate",
        "view_intercity_rate": "View intercity rate",
        "add_intercity_rate": "Add intercity rate",
        "Delete_confirm_intercity_rate": "Do you want to delete this item?",
        "Update_intercity_rate_success": "Intercity rate has been updated successfully",
        "Update_intercity_rate_fail": "The errors occurred while updating intercity rate",
        "Created_intercity_rate_success": "Intercity rate has been created successfully",
        "Created_intercity_rate_fail": "The errors occurred while creating intercity rate",
        "Update_intercity_rate_route_success": "Intercity rate route has been updated successfully",
        "Update_intercity_rate_route_fail": "The errors occurred while updating intercity route",
        "Created_intercity_rate_route_success": "Intercity rate route has been created successfully",
        "Created_intercity_rate_route_fail": "The errors occurred while creating intercity route",
        "Loading_intercity_rate_fail": "The errors occurred while loading intercity rate detail",
        "Delete_intercity_rate_route_fail": "The errors occurred while deleting intercity rate route",
        "Delete_intercity_rate_success": "Intercity rate has been deleted successfully",
        "Delete_intercity_rate_fail": "The errors occurred while deleting intercity rate"
    },
    "intercityRoute": {
        "alias": "Alias",
        "routeNameHint": "To change route name, please go to Settings > Zone > Intercity and change name of city zones accordingly.",
        "pricePerSeat": "Price per seat (tax excluded)",
        "distance": "Distance",
        "duration": "Duration",
        "cancellationPenalty": "Penalty amount per seat",
        "noShow": "Pax/partner no show",
        "penalty": "Penalty amount per seat",
        "no_show_tooltip": "The amount customer will be charged per seat. In addition to the penalty customer will pay tax or tech fee if applied",
        "firstLocation": "First location",
        "secondLocation": "Second location",
        "routeOneSetup": "Setup route 1",
        "routetwoSetup": "Setup route 2",
        "schedules": "Horaires",
        "date": "Date",
        "time": "Time",
        "editSchedule": "Edit schedule",
        "addSchedule": "Add schedule",
        "requestTimeEnable": "Allow customer to set request time",
        "extraFee": "Extra fee",
        "hideRouteEnable": "Hide this route",
        "timeRange": "Time range",
        "workingTime": "Working time",
        "applyForOtherDays": "Apply for other days",
        "limitSeatLuggage": "Limit seat / luggage",
        "warningMinimumSeats": "Minimum seats required",
        "warningMinimumSeatsHint": "Minimum number of seats required to make a trip happen",
        "allowStartLessMinRequiredSeats": "Allow driver to start trip regardless of the minimum seat requirement",
        "allowStartLessMinRequiredSeatsHint": "Driver can start a trip even if requested seats less than minimum seat required",
        "freeLuggagePerSeat": "Free luggage per seat",
        "freeLuggagePerSeatHint": "Number free of luggage included per seat",
        "allowToAddExtraLuggage": "Allow to add additional luggage",
        "allowToAddExtraLuggageHint": "Passenger can request to add more luggage",
        "feePerExtraLuggage": "Fee per extra luggage",
        "Delete_intercity_route": "Delete intercity route",
        "Delete_confirm_intercity_route": "Do you want to delete this route?",
        "Update_intercity_route_fail": "Intercity route has been updated successfully",
        "Update_intercity_route_success": "Intercity route has been updated successfully",
        "created_intercity_route_success": "Intercity route has been created successfully",
        "created_intercity_route_fail": "The errors occurred while creating intercity route",
        "Delete_intercity_route_success": "Intercity route has been deleted successfully",
        "Delete_intercity_route_fail": "The errors occurred while deleting intercity route",
        "penalty_less_than_or_equal_to_price": "Please enter a value less than or equal to price per seat value.",
        "scheduleTimeOverlap": "Schedule time must not overlap with each others",
        "allows_adding_extra": "Allows adding extra charge when customer request time which isn't scheduled by fleet.",
        "this_is_to_limit_users": "This is to limit users (i.e. customer, driver, operator) from creating new booking or trip on this route. Existing bookings or trips are remained as reserved"
    },
    "dayOfWeek": {
        "0": "Sunday",
        "1": "Monday",
        "2": "Tuesday",
        "3": "Wednesday",
        "4": "Thursday",
        "5": "Friday",
        "6": "Saturday"
    },
    "intercityBooking": {
        "route": "Route",
        "departureDate": "Departure date",
        "departureTime": "Departure time",
        "Departure": "Departure",
        "Arrival": "Arrival",
        "RefundFare": "Refund fare",
        "intercityZoneNote": "These zones are not operational zone. And they are used for setting intercity rate only.",
        "zoneNameHint": "Please keep your zone name short and clear so that your customers can search and select easily.",
        "zoneUploadNote_2": "*  It is recommended that image resolution should be 1095x360 and file size less than 5MB.",
        "Complete_booking": "Finaliser la commande",
        "ConfirmCancel": "Confirm cancel"
    },
    "deliveryRate": {
        "Delete_delivery_rate": "Delete delivery rate",
        "edit_delivery_rate": "Edit delivery rate",
        "view_delivery_rate": "View delivery rate",
        "add_delivery_rate": "Add delivery rate",
        "Delete_confirm_delivery_rate": "Do you want to delete this item?",
        "Update_delivery_rate_success": "Delivery rate has been updated successfully",
        "Update_delivery_rate_fail": "The errors occurred while updating delivery rate",
        "Created_delivery_rate_success": "Delivery rate has been created successfully",
        "Created_delivery_rate_fail": "The errors occurred while creating delivery rate",
        "Update_delivery_rate_route_success": "Delivery rate route has been updated successfully",
        "Update_delivery_rate_route_fail": "The errors occurred while updating delivery route",
        "Created_delivery_rate_route_success": "Delivery rate route has been created successfully",
        "Created_delivery_rate_route_fail": "The errors occurred while creating delivery route",
        "Loading_delivery_rate_fail": "The errors occurred while loading delivery rate detail",
        "Delete_delivery_rate_route_fail": "The errors occurred while deleting delivery rate route",
        "Delete_delivery_rate_success": "Delivery rate has been deleted successfully",
        "Delete_delivery_rate_fail": "The errors occurred while deleting delivery rate",
        "distance_km": "Fee per km",
        "distance_mile": "Fee per mil",
        "flat": "Flat Fee",
        "startingFee": "Starting fee",
        "feeFirstDistance": "Fee first distance",
        "feeSecondDistance": "Fee second distance",
        "feeThirdDistance": "Fee third distance",
        "feeAfterThirdDistance": "Fee after third distance",
        "feePerMinute": "Fare per minute",
        "feeMinimum": "Fee minimum",
        "feeFirstStop": "Fee for first stop",
        "feeExtraStop": "Fee for each extra stop"
    },
    "pwaSettings": {
        "pwa": "PWA",
        "iconName": "Icon & name",
        "logoBackgroundColor": "Logo, background & color",
        "longName": "Long name",
        "shortName": "Short name",
        "icon192": "Icon on Home Screen (192x192)",
        "icon512": "Icon on Splash Screen",
        "logo": "Logo (300x300)",
        "logoMenu": "Logo menu (300x300)",
        "backgroundImage": "Background image - Transport (1024x500)",
        "backgroundImageDelivery": "Background image - Delivery (1024x500)",
        "mainColor": "Main color",
        "secondaryColor": "Secondary color",
        "showMainColor": "Show main color instead of background image",
        "success": "PWA setting has been updated successfully",
        "failed": "Failed to update PWA setting. Please try again!",
        "errorUploadFileSize": "The required dimensions are {0}x{0} px.",
        "hideWBInstallAppBtn": "Hide install app button",
        "enableBookNow": "Now",
        "enableGuestCheckout": "Guest checkout",
        "enableBookLater": "Later",
        "enableHideRecipient": "Hide recipient info (Package content, Delivery instructions)",
        "pwaLink": "PWA for Passenger",
        "partnerLink": "PWA for Partner",
        "pwaDriverLink": "PWA for Driver",
        "providerSignUp": "Driver sign-up link",
        "AdsTracking": "Ads Tracking",
        "googleId": "Google Tag ID",
        "placeholdergoogleId": "Enter Google Tag ID",
        "metaId": "Meta Pixel ID",
        "placeholdermetaId": "Enter Meta Pixel ID"
    },
    "emailConfig": {
        "emailServer": "Email server",
        "default": "Default",
        "customized": "Customized",
        "fromName": "From name",
        "email": "From email",
        "replyTo": "Reply to",
        "userAuth": "Username",
        "password": "Password",
        "smtpServer": "SMTP server",
        "port": "SMTP port",
        "isAuthenticated": "Authenticated",
        "option": "Enable Option",
        "none": "None",
        "tls": "TLS",
        "ssl": "SSL",
        "save": "Save",
        "cancel": "Cancel",
        "success": "Update email server success",
        "fail": "Update email server fail",
        "loadFail": "Load email server detail fail"
    },
    "driverFields": {
        "Load_fail": "",
        "Edit": "Edit",
        "View": "View",
        "Cancel": "Cancel",
        "show": "Show",
        "hide": "Hide",
        "EDIT_DRIVER_FIELDS": "Edit Driver Fields",
        "DETAIL_DRIVER_FIELDS": "Detail Driver Fields",
        "ADD_DRIVER_FIELDS": "Add Driver Fields",
        "fieldKey": "Fields key",
        "fieldName": "Fields name",
        "availableCC": "Available in Command Center",
        "availableDrvSignUp": "Available in Driver sign up",
        "availableCompanyCar": "Company Car - Transport drivers",
        "deliveryCompany": "Company Car - Delivery drivers",
        "availableIndividualCar": "Individual Car - Transport drivers",
        "deliveryIndividual": "Individual Car - Delivery drivers",
        "required": "Required",
        "Update_fail": "The errors occurred while updating driver fields",
        "Update_success": "The Driver Fields has been updated successfully",
        "FieldKey": "Fields Key",
        "FieldName": "Fields Name (American English)",
        "CommandCenter": "Available In Command Center",
        "SignUp": "Available In Driver Sign Up",
        "CompanyTransport": "Company Car - Transport",
        "CompanyDelivery": "Company Car - Delivery",
        "IndividualTransport": "Individual Car - Transport",
        "IndividualDelivery": "Individual Car - Delivery",
        "Require": "Required",
        "Actions": "Actions",
        "fieldType": "Fields Type",
        "default": "Default",
        "customized": "Customized"
    },
    "driverDocument": {
        "Load_fail": "",
        "Edit": "Edit",
        "View": "View",
        "Cancel": "Cancel",
        "show": "Show",
        "hide": "Hide",
        "EDIT_DRIVER_DOCUMENT": "Edit Driver Document",
        "DETAIL_DRIVER_DOCUMENT": "Detail Driver Document",
        "ADD_DRIVER_DOCUMENT": "Add Driver Document",
        "documentKey": "Document key",
        "documentName": "Document name",
        "documentNameDetail": {
            "zh-HK": "Traditional Chinese",
            "it-IT": "Standard Italian",
            "es-ES": "Spanish",
            "en-US": "American English",
            "fr-FR": "Standard French",
            "zh-CN": "Simplified Chinese",
            "id-ID": "Bahasa Indonesia",
            "vi-VN": "Vietnamese",
            "ur-PK": "Urdu (Islamic Republic of Pakistan)",
            "ms-MY": "Malay (Malaysia)",
            "ar-AR": "Arabic",
            "en-GB": "English (United Kingdom)",
            "nb-NO": "Norwegian",
            "am-ET": "Amharic",
            "ro-RO": "Romanian",
            "th-TH": "Thai",
            "ta-IN": "Tamil",
            "hi-IN": "Hindi",
            "te-IN": "Telugu",
            "ka-GE": "Georgian",
            "ru-RU": "Russian",
            "kn-IN": "Kannada"
        },
        "isIndividual": "Company car - Transport drivers",
        "deliveryCompany": "Company car - Delivery drivers",
        "isOwner": "Individual driver - Transport drivers",
        "deliveryIndividual": "Individual driver - Delivery drivers",
        "availableCC": "Available in Command Center",
        "availableDrvSignUp": "Available in Driver sign up",
        "isRequired": "Required",
        "isExpiredDate": "Expiry date - Command Center",
        "expiredDateForDrvSignUp": "Expiry date - Driver signup",
        "requireExpiredDate": "Require Expiry Date",
        "requireExpiredDateForDrvSignUp": "Require Expiry Date",
        "requireDocumentId": "Require Document ID",
        "documentId": "Document ID - Command Center",
        "documentIdForDrvSignUp": "Document ID - Driver signup",
        "Update_fail": "The errors occurred while updating driver document",
        "Update_success": "The Driver Document has been updated successfully",
        "DocumentKey": "Document Key",
        "DocumentName": "Document Name (American English)",
        "CommandCenter": "Available In Command Center",
        "SignUp": "Available In Driver Sign Up",
        "CompanyTransport": "Company Car - Transport",
        "CompanyDelivery": "Company Car - Delivery",
        "IndividualTransport": "Individual Car - Transport",
        "IndividualDelivery": "Individual Car - Delivery",
        "Require": "Required",
        "Actions": "Actions"
    },
    "holidayTaxis": {
        "title": "HolidayTaxis API Credentials",
        "note": "Communicate with HolidayTaxis's technical team to provide you with api key to connect to their system.",
        "apiKey": "API key",
        "placeHolderApiKey": "Enter api key"
    },
    "trailNotes": {
        "maxLengthFiles": "Maximum 3 files per comment",
        "maxSizeFiles": "Maximum 10MB per file",
        "typeFiles": "File type supported: pdf, doc, docx, csv, xls, xlsx, jpeg, jpg, png, webp, txt, note",
        "maxComments": "Maximum 20 comments per booking",
        "bookingActivity": "Booking Activity",
        "viewAll": "View all",
        "operator": "Operator",
        "driver": "Driver",
        "passenger": "Customer",
        "pHInput": "Add a new comment. You can click save to save the note. You can also drag an drop multiple files here.",
        "addFiles": "Add files",
        "save": "Save",
        "inputNotes": "Please input text",
        "successDeleteTrailNotes": "Delete trail notes successfully",
        "successCreateTrailNotes": "Create trail notes successfully",
        "deleteTrailNote": "Delete a trail note",
        "msgDeleteTrailNote": "Are you sure you want to delete this?"
    },
    "supplier": {
        "permissionRequired": "Operator need to select a permission",
        "offerDetail": "Offer Detail",
        "orderDetail": "Order Detail",
        "bookingNumber": "Booking Number",
        "date": "Date",
        "pickupLocation": "Pick up Location",
        "dropoffLocation": "Drop off Location",
        "passenger": "Passenger",
        "luggage": "Luggage",
        "pickupService": "Pickup Service",
        "pickupSignage": "Pickup Signage",
        "flightNumber": "Flight Number",
        "departure": "Departure",
        "arrival": "Arrival",
        "terminal": "Terminal",
        "gate": "Gate",
        "meetGreet": "Meet & Greet",
        "specialRequests": "Special Requests",
        "additionalServices": "Additional Services",
        "price": "Price",
        "prices": "Prices",
        "guest": "Guest",
        "serviceClass": "Service Class",
        "distance": "Distance",
        "duration": "Duration",
        "acceptOffer": "Accept Offer",
        "updateBooking": "Update Booking",
        "package": "Package",
        "route": "Route",
        "assignedChauffer": "Assigned Chauffeur",
        "assignedVehicle": "Assigned Vehicle",
        "logs": "Logs",
        "link": "Links",
        "desLink": "Click to copy link",
        "trackLink": "Track link",
        "driverLink": "Driver link",
        "close": "Close",
        "links": "Links",
        "linksDes": "Click to copy link",
        "status": "Status",
        "rating": "Rating",
        "permissions": "Permissions",
        "permissionsNote": "Assign different levels of authorization to your team. You can choose whether your operators have visibility of open offers or only assignments already accepted.",
        "owner": "Owner",
        "ownerNote": "Full Access",
        "manageUsers": "Manage Users",
        "manageUsersNote": "Add/remove new operators",
        "manageDrivers": "Manage Drivers",
        "manageDriversNote": "Add/remove drivers",
        "manageVehicles": "Manage Vehicles",
        "manageVehiclesNote": "Add/remove vehicles",
        "mapView": "Live Map",
        "mapViewNote": "Track drivers and active trips live on the map.",
        "calendarView": "Calendar View",
        "calendarViewNote": "View calendar of all assigned trips.",
        "bookingView": "Booking View",
        "bookingViewNote": "View and manage current bookings, assign drivers, and allocate vehicles.",
        "offersView": "Offers View",
        "offersViewNote": "Accept new job offers.",
        "pricing": "Pricing",
        "pricingNote": "View trip prices",
        "report": "Reports",
        "reportNote": "View booking and financial reports",
        "reportExport": "Export Reports",
        "reportExportNote": "Export reports to .csv",
        "userDetail": "User Detail",
        "addOperator": "Add Operator",
        "updateOperator": "Update Operator",
        "supplier": "Supplier",
        "zone": "Zone",
        "emails": "Emails",
        "email": "Email",
        "phoneNumber": "Phone Number",
        "Deactivate": "Deactivate",
        "Activate": "Activate",
        "ALERT_SELECT_DEACTIVATE": "Please select supplier to deactivate",
        "ALERT_SELECT_ACTIVATE": "Please select supplier to activate",
        "CONFIRM_DEACTIVATE_MULTI": "Do you want to deactivate these suppliers?",
        "CONFIRM_ACTIVATE_MULTI": "Do you want to activate these suppliers?",
        "Update_supplier_user_status_success": "Supplier status has been updated successfully",
        "Update_supplier_user_status_fail": "The errors occurred while updating supplier status",
        "agent": "Agent",
        "Supplier": "Supplier",
        "addSupplier": "Add Supplier",
        "detailSupplier": "Supplier Detail",
        "addUser": "Add User",
        "Add": "Add",
        "ContactInfo": "Contact Info",
        "policy": "Policy",
        "users": "Users",
        "setting": "Setting",
        "zones": "Zones",
        "actions": "Actions",
        "companyName": "Company Name",
        "corporationType": "Corporation Type",
        "country": "Country",
        "ADMIN_INFORMATION": "Admin Information",
        "firstName": "First Name",
        "lastName": "Last Name",
        "commissionValue": "Fleet commission",
        "commissionValueDes": "Set commissions the fleet earns for every complete order.",
        "SupplierType": "Supplier Type",
        "SupplierTypeDes": "Select a supplier type.",
        "paymentMethodsApplyDes": "Job specific payment methods that suppliers can process:",
        "bookingSettings": "Booking Settings",
        "bookingSettingsDes": "Configure job visibility, release times, and delays for efficient supplier operations.",
        "advanceBookingVisibilityTooltip": "Specify how many days in advance suppliers can see the orders.",
        "delayBroadcastToolTip": "Set a delay time from the release time if set or the start of the day (00:00). This delay controls when the jobs become visible to the suppliers after the initial release time.",
        "releaseTimeToolTip": "Set the time of day when jobs are released to suppliers, starting from the first day they can see bookings. Ensure this time allows suppliers enough preparation time.",
        "otherSettings": "Other Settings",
        "hideFare": "Driver - Always hide fare",
        "hideFareDes": "Hide the fare for all drivers of this supplier.",
        "supplierJobRequired": "Supplier Job Acceptance Required",
        "supplierJobRequiredDes": "Suppliers must acknowledge and accept jobs before they can assign a driver/vehicle or allow the job to be viewed by drivers in the marketplace.",
        "supplierApproval": "Suppliers Driver/Vehicle Approval",
        "supplierApprovalDes": "Allow supplier operators to activate drivers and vehicles within their fleet.",
        "emailsApply": "Email Notifications",
        "emailsApplyDes": "Emails to send new jobs, booking updates.",
        "address": "Address",
        "zipCode": "Zip/Postal Code",
        "city": "City",
        "state": "State/Province",
        "tax": "Tax Identification Number",
        "businessRegistration": "Business Registration Number",
        "vatNumber": "VAT ID/Number",
        "mobile": "Mobile",
        "editOperator": "Edit Operator",
        "searchEmail": "Search Email",
        "acceptOfferSuccess": "Accept offer successfully",
        "acceptOfferFail": "Booking not found or another supplier has accepted the booking",
        "driverPayout": "Driver payout",
        "payout": "Payout",
        "totalPayout": "Total payout",
        "totalPayoutDes": "The total payout includes the base payout amount plus any additional fees you have chosen to allocate to your providers.",
        "supplierPayout": "Supplier payout",
        "driverTip": "Driver tip",
        "cancel": "Cancel",
        "save": "Save",
        "activeSupplier": "Active supplier",
        "userName": "Username",
        "bookingCompletedView": "Completed Bookings",
        "bookingCompletedViewNote": "View the history of all completed bookings.",
        "driverEarning": "Driver earning",
        "payoutCurrency": "Payout Currency",
        "payoutCurrencyDes": "Currency to pay to supplier/driver",
        "commissionSupplier": "Commission",
        "commissionSupplierDes": "Set commissions the supplier earns for every complete order.",
        "amount": "Fixed amount",
        "percent": "Percentage",
        "commissionSupplierValue": "Input commission value"
    },
    "noResultFound": "No results found",
    "invalidDate": "Date is invalid. Please enter date in format mm/dd/yyyy."
};
