import {
  SIDEBAR_COLLAPSED,
  SIDEBAR_LINK_CLICKED,
  BUILD_MENU,
  UPDATE_MENU,
} from '../actions/sidebarCollapsedAction';
import { sidebarMenuConfigs } from '../constants/sidebarMenuConfigs';
import _ from 'lodash';
import setting from '../assets/images/icons/setting.svg';
import setting_active from '../assets/images/icons/setting_active.svg';

function initialState() {
  return {
    sidebarCollapsed: false,
    menuList: [],
    key: '',
    selectedMainMenu: null,
    modulePermission: null,
    bookingPermission: null,
  };
}
export default function menuHandle(state = initialState(), action = {}) {
  switch (action.type) {
    case SIDEBAR_COLLAPSED: {
      return { ...state, sidebarCollapsed: action.sidebarCollapsed };
    }
    case SIDEBAR_LINK_CLICKED: {
      // console.log('state.menuList', state.menuList);
      const menuList = checkActiveMenu(state.menuList, action.key);
      // console.log('SIDEBAR_LINK_CLICKED menuList', menuList);
      const selectedMenu = menuList.filter((mn) => mn.active == true)[0];
      // console.log('selectedMenu', selectedMenu);
      return {
        ...state,
        menuList,
        key: action.key,
        selectedMainMenu: selectedMenu,
        sidebarCollapsed:
          selectedMenu && selectedMenu.sub && selectedMenu.sub.length > 0
            ? true
            : action.isMenuSidebar
            ? false
            : state.sidebarCollapsed,
        modulePermission: getModulePermission(
          menuList.filter((mn) => mn.active == true)[0]
        ),
      };
    }
    case BUILD_MENU: {
      let menus = buildMenuForBaseRoles(
        sidebarMenuConfigs,
        action.data.roles,
        action.data.settings,
        action.data.selectedFleet,
        action.data.auth
      );
      let path = window.location.pathname.split('/');
      path = path.splice(1, path.length).join('.');
      state.menuList = checkActiveMenu(menus, path, 0);
      if (
        !state.selectedMainMenu ||
        (state.selectedMainMenu.show && state.selectedMainMenu.enabled)
      ) {
        if (action.callback) {
          // action.callback(state.menuList)
        }
        const selectedMenu = menus.filter((mn) => mn.active == true)[0];
        return {
          ...state,
          menuList: menus,
          selectedMainMenu: selectedMenu,
          sidebarCollapsed:
            selectedMenu && selectedMenu.sub && selectedMenu.sub.length > 0,
          modulePermission: getModulePermission(
            state.menuList.filter((mn) => mn.active == true)[0]
          ),
          bookingPermission: getBookingPermission(menus),
          editFarmBooking:
            action.data.roles && action.data.roles.companyOperator,
        };
      }
      window.location.href = '/page-not-found';
    }
    case UPDATE_MENU: {
      const path = action.data.splice(1, action.data.length).join('.');
      if (!path || path.length == 0) {
        return state;
      }
      state.menuList = checkActiveMenu(state.menuList, path, 0);
      if (
        !state.selectedMainMenu ||
        (state.selectedMainMenu.show && state.selectedMainMenu.enabled)
      ) {
        if (action.callback) {
          action.callback(state.menuList);
        }
        // console.log('state.menuList 2', state.menuList);
        const selectedMenu = state.menuList.filter(
          (mn) => mn.active == true
        )[0];
        // console.log('selectedMenu', selectedMenu);
        return {
          ...state,
          menuList: state.menuList,
          selectedMainMenu: selectedMenu,
          sidebarCollapsed:
            selectedMenu && selectedMenu.sub && selectedMenu.sub.length > 0,
          modulePermission: getModulePermission(
            state.menuList.filter((mn) => mn.active == true)[0]
          ),
        };
      }
      window.location.href = '/login';

      return state;
    }
    default:
      return state;
  }
}

function checkActiveMenu(menuList, key, pos = 0) {
  return menuList.map((mn) => {
    if (key.toLowerCase() == mn.key.toLowerCase()) {
      if (
        // mn.show &&
        mn.enabled
      ) {
        mn.active = true;
        if (mn.sub && mn.sub.length > 0) {
          mn.sub = mn.sub.map((sub) => ({ ...sub, active: false }));
          mn.sub[0].active = true;
        }
      }
      return mn;
    }
    const clickedMenu = key
      .split('.')
      .splice(0, pos + 1)
      .join('.');
    if (clickedMenu.toLowerCase() == mn.key.toLowerCase()) {
      if (mn.show && mn.enabled) {
        mn.active = true;
        if (mn.sub && mn.sub.length > 0) {
          mn.sub = checkActiveMenu(mn.sub, key, pos + 1);
        }
      }
      return mn;
    }
    mn.active = false;
    return mn;
  });
}

function buildMenuForBaseRoles(menuList, roles, settings, selectedFleet, auth) {
  const menuBaseRoles = menuList.map((mn) => {
    if (mn.moduleSettingField && !settings[mn.moduleSettingField]) {
      mn.show = false;
      mn.enabled = false;
      return mn;
    }
    if (mn.sub && mn.sub.length > 0) {
      if (mn.subShowAll) {
        mn.link = mn.sub[0].link;
      } else {
        mn.sub = buildMenuForBaseRoles(mn.sub, roles, settings, selectedFleet, auth);
        mn.sub = mn.sub.filter((a) => a.show);
        if (mn.sub.length > 0) {
          mn.show = true;
          mn.enabled = true;
          mn.link = mn.sub[0].link;
          return mn;
        }
      }
    }
    // console.log("mn-----------",mn);
    return checkmenuMapPermission(mn, roles, settings, selectedFleet, auth);
  });
  return menuBaseRoles;
}

function checkmenuMapPermission(menu, roles, settings, selectedFleet, auth) {
  if (menu.name === 'Import Bookings' && settings.importChauffeurBooking) {
    menu.show = true;
    return menu;
  }
  if (menu.name === 'Stripe Connect' && !checkFleetHydra(settings)) {
    menu.show = false;
    return menu;
  }

  if (auth?.user?.roles?.isSupplier) {
    if (menu.name === 'Supplier') {
      menu.show = false;
      return menu
    }

    if (menu.name === 'SupplierSetting') {
      menu.show = true;
      menu.link = `/supplier/detail/${auth?.user?.roles?.supplierId}/info`
      return menu
    }

    if (auth?.user?.roles?.supplierPermission?.reportExport && menu.name === 'Export') {
      menu.show = true;
      return menu
    }
  }

  if (
    menu.name === 'Third Party Integration Karhoo' &&
    !selectedFleet?.generalSetting?.allowReceiveKarhooBooking
  ) {
    menu.show = false;
    return menu;
  }

  const menuName = menu.name
    .split(/[\s,_]+/)
    .join('')
    .toLowerCase();
  if (menu.moduleSettingField && !settings[menu.moduleSettingField]) {
    menu.show = false;
    return menu;
  }
  if (!menu.enabled) {
    menu.show = false;
    return menu;
  }
  if (roles == null) {
    menu.show = true;
    return menu;
  }
  const role = roles.filter((r) => {
    const key = r.key
      .split(/[\s,_]+/)
      .join('')
      .toLowerCase();
    const name = r.name
      .split(/[\s,_]+/)
      .join('')
      .toLowerCase();

    let valid = false;
    if (
      (key == menuName || name == menuName) &&
      // menu.key.toLowerCase().indexOf("reports") < 0 ||
      // menu.key.toLowerCase().indexOf("driverpayout") < 0 ||
      // menu.key.toLowerCase().indexOf("merchantpayout") < 0
      menu.key.toLowerCase().indexOf('reports') < 0
    ) {
      valid = true;
    } else {
      //  console.log("menu",menu);
      if (menu.key != menu.name) {
        r.actions.every((a) => {
          const aname = a.name
            .split(/[\s,_]+/)
            .join('')
            .toLowerCase();
          if (aname == menuName && a.isActive) {
            valid = true;
            return false;
          }
          return true;
        });
      }
    }
    return valid;
  });
  if (!role || role.length == 0 || !role[0].actions) {
    menu.show = false;
    return menu;
  }
  const view = role[0].actions.filter(
    (r) =>
      (r.name == 'View' ||
        r.name
        .split(/[\s,_]+/)
        .join('')
        .toLowerCase() == menuName) &&
        r.isActive
      );

  // setting intercity, street-sharing rate thì permission theo Setting -> Permission -> Rate (giống hourly, regular, flat)
  if([
    'intercityrate',
    'street-sharingrate',
    // 'regular',
    // 'flat',
    // 'hourly',
    // 'delivery',
  ].includes(menuName)) {
    const RateRole = roles.find((r) => r.key === 'Setting' && r.name === 'Rate');
    if(RateRole?.actions?.length) {
      menu.show = RateRole.actions?.find((r) => r.name === 'View')?.isActive || false;
      menu.actions = RateRole.actions;
      return menu;
    }
    menu.show = false;
    menu.actions = [];
    return menu;
  }

  if (view && view.length > 0) {
    /* Not show Notification, SOS on Sidebar */
    if (
      ['Notifications', 'Sos', 'SignUpNotifications'].indexOf(menu.name) < 0
    ) {
      menu.show = true;
    }
    menu.actions = role[0].actions;
  }

  if (menu.sub && menu.sub.length > 0) {
    /**
     * Refactor permisison for Driver Settlement module
     *
     */
    if (menu.key === 'Settlement') {
      if (role[0].actions) {
        role[0].actions.every((act) => {
          if (act.isActive) {
            menu.show = true;
            return true;
          }
        });
      } else {
        menu.show = false;
      }
    } else {
      menu.sub = menu.sub.filter((m) => m.show);
      if (menu.sub && menu.sub.length > 0 && menu.show) {
        menu.show = true;
      } else {
        menu.show = false;
      }
    }
  }

  // special case for rating report
  if (menu.key == 'Reports.Rating' && settings.starsRating) {
    menu.text = 'Sidebar.Reports.Stars_rating';
  }
  return menu;
}

function getModulePermission(activatedMenu) {
  if (activatedMenu) {
    let actions = null;
    if (activatedMenu.actions) {
      actions = {};
      activatedMenu.actions.map((ac) => {
        actions[ac.name.toLowerCase()] = ac.isActive;
      });
    }
    if (activatedMenu.key != 'Settlement') {
      if (activatedMenu.sub) {
        actions = getModulePermission(
          activatedMenu.sub.filter((mn) => mn.active == true)[0]
        );
      }
    }
    return actions;
  }
}

function getBookingPermission(menus) {
  const newBookingPermission = menus.find(
    (item) => item.name === 'New booking' && item.key === 'Booking'
  );
  return newBookingPermission.actions;
}

function checkFleetHydra(setting = {}) {
  const { affiliate = {} } = setting;
  if (
    affiliate.dispatching ||
    affiliate.receiveOndemandBooking ||
    affiliate.receiveReservationBooking
  )
    return true;
  return false;
}
