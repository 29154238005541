import React, { Component } from 'react';
import './validator.scss';
import { Form } from 'react-bootstrap';

export default class Validator extends Component {
  componentWillUnmount() {
    const { callback } = this.props;
    callback && callback(this.props.id, true, null);
  }

  render() {
    if (!this.props.disabled) {
      const messages = [];
      const children = React.Children.map(this.props.children, child => {
        if (child && child.props && !child.props.valid) {
          messages.push(child.props.message);
          if ((messages.length == 1 || this.props.multi) && !child.props.hide) {
            return React.cloneElement(child, {});
          }
        }
        return null;
      });
      if (this.props.id && this.props.callback) {
        setTimeout(() => {
          this.props.callback(this.props.id, messages.length == 0, messages);
        }, 10);
      }
      return children && children.length > 0 ? (
        <Form.Control.Feedback type="invalid" className={this.props.className}>{children} </Form.Control.Feedback>
      ) : null;
    }
    this?.props?.callback(this.props.id, true, null);
    return null;
  }
}
