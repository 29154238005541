import React, { useCallback, useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import { Validation } from '../../utils/commonFunctions';
import { VALID_CASE } from '../../constants/commondata';
import './validator.scss';
import { Form } from 'react-bootstrap';

const isEmpty = (value) => {
  return (
    value === null ||
    value === undefined ||
    (typeof value === 'string' && value.trim() === '') ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'object' && Object.keys(value).length === 0)
  );
};

const Validator = React.memo(({ id, callback, renderValidCases, children, disabled, className, multi }) => {

  useEffect(() => {
    return () => {
      setTimeout(() => {
        callback && callback(id, true, null);
      }, 10);
    };
  }, []);

  if (!disabled) {
    if(renderValidCases?.length > 0) {

    }
    const messages = [];
    const validChildren = React.Children.map(children, (child) => {
      if (child && child.props && !child.props.valid) {
        messages.push(child.props.message);
        if ((messages.length === 1 || multi) && !child.props.hide) {
          return React.cloneElement(child, {});
        }
      }
      return null;
    });

    if (id && callback) {
      setTimeout(() => {
        callback(id, messages.length === 0, messages);
      }, 10);
    }

    return validChildren && validChildren.length > 0 ? (
      <Form.Control.Feedback type="invalid" className={className}>
        {validChildren}
      </Form.Control.Feedback>
    ) : null;
  }

  callback(id, true, null);
  return null;
});

const ValidCase = ({ valid, message, hide }) => {
  if (hide) return null;
  return (
    <div className={`valid-case ${valid ? 'valid' : 'invalid'}`}>
      {message}
    </div>
  );
};

const ValidatorCustom = React.memo(({
  validatorCallback,
  id,
  validCases,
  isSubmmitted,
  value,
  isMultiSelect
}) => {
  const [listErr, setListErr] = React.useState([]);
  
  useEffect(() => {
    return () => {
      validatorCallback(id, true, null);
    }
  }, []);

  useEffect(() => {
    if(validCases?.length > 0) {
      let listErr = []
      validCases?.map((validKey) => {
        if (validKey === VALID_CASE.required) {
          if (isMultiSelect && !(value?.length > 0)) {
            listErr.push(I18n.t('messages.commonMessages.Please_select_at_least_one'))
          } else if(value === null || value === undefined || (typeof value === 'string' && value.trim() === '')) {
            listErr.push(I18n.t('messages.commonMessages.Required_field'))
          }
        }
        if (isEmpty(value)) {
          listErr.push(I18n.t('messages.commonMessages.must_be_number'))
        }
      })
      if(listErr.length > 0) {
        validatorCallback(id, false, listErr);
      } else {
        validatorCallback(id, true, null);
      }
      setListErr(listErr);
    }
  }, [
    id,
    // validCases,
    // isSubmmitted,
    value,
  ]);

  // const renderValidCases = useCallback(() => {
  //   return validCases?.map((validKey) => {
  //     let isValid = false,
  //       message = '';
  //     if (validKey === VALID_CASE.required) {
  //       if (isMultiSelect) {
  //         isValid = value?.length > 0;
  //         message = I18n.t('messages.commonMessages.Please_select_at_least_one');
  //       } else {
  //         isValid = Validation.isRequired(value);
  //         message = I18n.t('messages.commonMessages.Required_field');
  //       }
  //     }
  //     if (validKey === VALID_CASE.isNumber) {
  //       isValid = Validation.isNumber(value);
  //       message = I18n.t('messages.commonMessages.must_be_number');
  //     }
  //     return {
  //       isValid,
  //       message,
  //       hide
  //     }
  //     // return (
  //     //   <ValidCase
  //     //     key={validKey}
  //     //     valid={isValid}
  //     //     message={message}
  //     //     hide={!isSubmmitted}
  //     //   />
  //     // );
  //   });
  // }, [validCases]);

  const validatorCallbackState = useCallback((id, valid, mess) => {
    validatorCallback(id, valid, mess);
  }, []);

  return (
    null
    // <Validator id={id} callback={validatorCallback}>
    //   {/* {renderValidCases()} */}
    // </Validator>
  );
});

export default ValidatorCustom;
