import {
    BOOKING_DETAIL_GET_REQUEST,
    BOOKING_DETAIL_GET_SUCCESS,
    BOOKING_DETAIL_GET_FAILURE,
    BOOKING_DETAIL_CLOSED,
} from "../actions/bookingDetailAction";
import _ from 'lodash'
const initialState = {
    data: null,
    loading: false,
    isShowing: false,
    error: null
}
export default function bookingDetail(state = initialState, action = {}) {
    switch (action.type) {
        case BOOKING_DETAIL_GET_REQUEST: {
            return { ...state, loading: true };
        }
        case BOOKING_DETAIL_GET_SUCCESS: {
            if (action.data) {
                if(closeToHydraFarmInBookingForm(action.data)) {
                    return { ...state, data: null, loading: false, isShowing: false };
                }
                return { ...state, data: action.data, loading: false, isShowing: true };
            } else {
                return { ...state, data: null, loading: false, isShowing: false };
            }

        }
        case BOOKING_DETAIL_GET_FAILURE: {
            return { ...state, data: null, loading: false, isShowing: false };
        }
        case BOOKING_DETAIL_CLOSED: {
            return _.cloneDeep(initialState);
        }
        default:
            return state
    }
}

// Kiểm tra có thể  mở booking detail hydra với farmIn
function closeToHydraFarmInBookingForm(bookInfo) {
    let fleetObject;
    try {
      fleetObject = JSON.parse(localStorage.getItem('fleet')) || {};
    } catch (err) {
      fleetObject = {};
    }
    const status = _.get(bookInfo, 'hydraInfo.status', '')
    if( bookInfo.pricingType === 1 // bookHydra
        && fleetObject.fleetId !== _.get(bookInfo, 'request.psgFleetId', '') // farm in
        && (status === 'denied' || status === 'rejected')
    ) {
        return true
    }
    return false
}