import currencyFormatter from 'currency-formatter';
import { finishedStatusList } from '../constants/commondata';

export const convertDataBookingForSupplier = ({
  data,
  paramState,
  paramProps,
}) => {
  if (!data) return {};
  const { selectedTab } = paramState;
  const { commonData, auth } = paramProps;

  let newData = {};

  // convert data prices
  let dataFare = data?.request?.estimate?.fare?.etaFare || 0;
  if (commonData?.companies[0]?._id) {
    dataFare = data.request.estimate.fare.totalPayout;
  }

  let checkCalculateSup =
    (selectedTab == 3 || selectedTab == 0) &&
    data.request?.estimate?.fare.supplierEarningType == 'default';

  const calculatorCurrency = (value) =>
    currencyFormatter.format(parseFloat(value || 0).toFixed(2), {
      symbol: commonData?.companies[0]?.currencyISO,
    });

  if (checkCalculateSup) {
    newData.dataFare = calculatorCurrency(
      data.request.estimate.fare.totalPayout
    );
  } else {
    newData.dataFare = calculatorCurrency(dataFare);
  }

  if (!auth?.user?.roles?.supplierPermission?.pricing) {
    newData.dataFare = '';
  }

  // convert another data

  return newData;
};

export const transformDataBooking = ({ booking, commonData, keys }) => {
  const result = {
    status: booking.status,
  };
  if (keys.includes('status')) {
    const isFinished = (booking) => {
      if (!booking) return;
      return (
        finishedStatusList.some((i) => i.value === booking.status) ||
        !!booking.finishedAt
      );
    };
    if (!isFinished(booking)) {
      const checkStatusSupplierAccepted =
        booking?.supplierAccepted &&
        !booking?.drvInfo?.userId &&
        commonData?.suppliers?.find((item) =>
          booking?.supplierCompanies?.includes(item._id)
        )
          ? 'supplierAccepted'
          : booking?.status;
      result['status'] = checkStatusSupplierAccepted;
    }
  }

  return result;
};
