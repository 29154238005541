export const ja = {
    "name": "Japanese",
    "flagCode": "jp",
    "locale": "ja-jp",
    "project": {
        "name": "QUp New CC"
    },
    "Sidebar": {
        "Setting": "設定",
        "Map": "マップ",
        "Cue": "キュー",
        "Invoice": "インボイス",
        "Supplier": "サプライヤー",
        "Vehicle": "車両",
        "Merchant": "マーチャント",
        "ImportBookings": "輸送予約のインポート",
        "ImportDeliveryBookings": "配達予約のインポート",
        "Trip": "トリップ",
        "New_booking": "新しい予約",
        "City": "都市",
        "Chauffeur": "輸送",
        "Delivery": "配達",
        "Shuttle": "シャトル",
        "Intercity": "都市間",
        "Customer": "顧客",
        "Driver": "ドライバー",
        "REPORTS": "レポート",
        "Corporate": "法人",
        "mDispatcher": "パートナー",
        "Reservation": "カレンダー",
        "withdrawalRequest": "出金リクエスト",
        "Driver_Settlement": "ドライバー決済",
        "Dashboard": "アナリティクス",
        "SmartData": "スマートデータ",
        "Smart_Data": "スマートデータ",
        "Campaign_Builder": "キャンペーンビルダー",
        "Company_operator": "会社オペレーター",
        "Queuing_area": "待機エリア",
        "Withdrawal_Requests": "出金リクエスト",
        "pickUp": "ピックアップ",
        "dropOff": "ドロップオフ",
        "SignUpNotifications": "サインアップ通知",
        "Payout": "ペイアウト",
        "Payout_label": "ペイアウト",
        "payout": {
            "Pay_To_Driver": "ドライバーへの支払い",
            "Payout_History_For_Driver": "ドライバーのペイアウト履歴",
            "Pay_To_Merchant": "マーチャントへの支払い",
            "Payout_History_For_Merchant": "マーチャントのペイアウト履歴"
        },
        "number_of_records_to_export": "エクスポートするレコード数",
        "export": "エクスポート",
        "Airline": "航空会社",
        "driverPayout": {
            "Pay_to_driver": "ドライバーへの支払い",
            "Payout_history": "ペイアウト履歴"
        },
        "Reports": {
            "Driver_Referral_history": "ドライバーが顧客を紹介",
            "Driver_refers_Pax": "ドライバー",
            "Refund": "返金",
            "Driver_refers_Pax_Customers": "顧客",
            "Driver_refers_Pax_Bookings": "予約",
            "Driver_refers_customer_Drivers": "ドライバーが顧客を紹介 (ドライバー)",
            "Driver_refers_customer_Customers": "ドライバーが顧客を紹介 (顧客)",
            "Driver_refers_customer_Bookings": "ドライバーが顧客を紹介 (予約)",
            "Passenger_refers_Passengers": "顧客が顧客を紹介",
            "Driver_refers_drivers_Referrer": "ドライバーがドライバーを紹介 (紹介者)",
            "Deletion_request": "削除リクエスト",
            "Driver_refers_drivers_Referee": "ドライバーがドライバーを紹介 (被紹介者)",
            "Driver_refers_Driver": "ドライバーがドライバーを紹介",
            "Driver_refers_driver_Referrer": "紹介者",
            "Driver_refers_driver_Referee": "被紹介者",
            "Referral_history": "紹介履歴",
            "Booking_details": "予約詳細",
            "Financial": "財務",
            "Financial_driver": "ドライバー",
            "Financial_fleet": "フリート",
            "Financial_company": "会社",
            "Financial_Driver": "財務 (ドライバー)",
            "Financial_Fleet": "財務 (フリート)",
            "Financial_Company": "財務 (会社)",
            "Financial_fleet_profit": "フリートの利益",
            "Financial_Company_Tooltip": "会社ごとにグループ化されたドライバーの純利益レポート",
            "Financial_Driver_ToolTip": "各ドライバーの純利益レポート",
            "Financial_Fleet_Profit_ToolTip": "フリートの利益",
            "Driver_activity": "ドライバーの活動",
            "Revenue_Summary": "収益概要",
            "Revenue_fleet": "フリート",
            "Revenue_driver": "ドライバー",
            "Revenue_monthly": "月次",
            "Revenue_Summary_Fleet": "収益概要 (フリート)",
            "Revenue_Summary_Driver": "収益概要 (ドライバー)",
            "Revenue_Summary_Monthly": "収益概要 (月次)",
            "Total_adjustment": "合計調整",
            "Promotion": "プロモーション",
            "Incident_Cancellation": "インシデントとキャンセル",
            "Booking_logs": "予約ログ",
            "Rating": "評価",
            "Thumbs_rating": "評価",
            "Stars_rating": "評価",
            "Operator_logs": "オペレーターログ",
            "Daily": "日次",
            "Daily_Driver": "日次ドライバー",
            "Daily_Car": "日次車両",
            "Daily_Driver_Car": "日次ドライバー車両",
            "mDispatcher": "パートナー",
            "Corporate": "法人予約",
            "Driver_login_status": "ドライバーのログイン状況",
            "Car_activity": "車両の活動",
            "Partner": "パートナー",
            "Prepaid_top_up": "法人プリペイド",
            "Affiliation": "提携",
            "Affiliation_penalty_compensation": "罰金/補償",
            "Affiliation_payout_history": "決済履歴",
            "Affiliation_bookings": "予約",
            "Booking_summary": "予約概要",
            "Cash_Wallet": "現金ウォレット",
            "Credit_Wallet": "クレジットウォレット",
            "Driver_wallet": "ドライバーウォレット",
            "Driver_Deposit": "ドライバーのデポジット",
            "Driver_cash_wallet": "現金ウォレット",
            "Driver_Deposit_old": "ドライバーのデポジット",
            "Document_expiry": "書類の有効期限",
            "Settlement": "決済",
            "Unapproved_Driver": "未承認ドライバー",
            "Approved_Driver": "承認済みドライバー",
            "Pay_to_driver": "ドライバーへの支払い",
            "Settlement_history": "決済履歴",
            "Withdrawal_History": "ドライバーの出金",
            "creditWalletTooltip": "ドライバーがクレジットウォレットにデポジット",
            "Incomplete_payment": "未完了の支払い",
            "Driver_withdrawal": "ドライバーの出金",
            "Incident_&_Cancellation": "インシデントとキャンセル",
            "Passenger_wallet": "乗客ウォレット",
            "Accept_Cancel_rate": "ドライバーのパフォーマンス",
            "Acceptance/Cancellation_rate": "ドライバーのパフォーマンス",
            "driverDepositTooltip": "ドライバーがクレジットウォレットにデポジット",
            "Customer_quest": "クエスト",
            "First_will_win": "最初が勝つ",
            "Largest_will_win": "最大が勝つ",
            "redeemed": "コード #",
            "Code_#": "コード #",
            "Quests": "クエスト",
            "Merchant_Report": "マーチャント",
            "Merchant_Financial_Report": "財務",
            "Merchant_Cash_Report": "現金ウォレット",
            "Merchant_Credit_Report": "クレジットウォレット",
            "Deletion_Request": "削除リクエスト",
            "Driver_Rating": "顧客がドライバーを評価",
            "Passenger_Rating": "ドライバーが顧客を評価",
            "Company_settlement": "会社決済",
            "Export": "エクスポート履歴",
            "Third_Party_Booking": "サードパーティ予約",
            "BookingDotCom": "Booking.com",
            "Third_Party_Booking_Bookingcom": "サードパーティ予約 (Booking.com)",
            "Third_Party_Booking_HolidayTaxis": "サードパーティ予約 (HolidayTaxis)",
            "Third_Party_Booking_Karhoo": "サードパーティ予約 (Karhoo)",
            "Third_Party_Booking_Hoppa": "サードパーティ予約 (Hoppa)"
        },
        "Settings": {
            "Fleet_info": "フリート情報",
            "Surchage_Policy": "追加料金ポリシー",
            "RateLegacy": "料金 (レガシー)",
            "Driver": "ドライバー",
            "Merchant": "加盟店",
            "Calendar": "カレンダー",
            "Socaial": "Socaial",
            "Quest": "クエスト",
            "Referral": "紹介",
            "Social": "Social",
            "Shift": "シフト",
            "Rate_Templates": "料金テンプレート",
            "Supplier_Plan": "サプライヤープラン",
            "Penalty_Policy": "ペナルティポリシー",
            "Stripe_connect": "Stripe Connect",
            "stripe_title_connect": "Stripe Connectアカウントを有効化して、ペイアウトを自動的に受け取ります。",
            "Payment_info": "請求情報",
            "Permission": "許可",
            "Driver_refer_driver": "ドライバーがドライバーを紹介",
            "Show_referal_history": "ドライバーアプリで紹介履歴を表示/非表示",
            "companyTooltip": "サプライヤー会社",
            "User": "ユーザー",
            "General": "一般",
            "Regular": "レギュラー",
            "Sharing": "ストリートシェアリング",
            "Flat": "フラット",
            "Hourly": "時間ごと - 日ごと",
            "Company": "会社",
            "Dispatch": "ディスパッチ",
            "Voice_SMS": "音声とSMS",
            "Rate": "料金",
            "RateNew": "料金",
            "Car": "車両",
            "Car_type": "車両タイプ",
            "Car_mgmt": "車両管理",
            "Car_Make": "メーカーとモデル",
            "Stripe_Connect": "Stripe connect",
            "Voice_&_SMS": "音声とSMS",
            "Street-sharing_rate": "ストリートシェアリング料金",
            "Partner_type": "パートナータイプ",
            "Shift_template": "シフトテンプレート",
            "Driver_App": "ドライバーアプリ",
            "Shift_settings": "シフト設定",
            "Operation": "運営",
            "Driver_Note": "ドライバーノート",
            "Promotion_code": "プロモーションコード",
            "Point_config": "ポイント設定",
            "Code_#": "コード #",
            "Voucher_code": "コード #",
            "Campaign": "キャンペーン",
            "Queuing_area": "待機エリア",
            "Referral_code": "紹介コード",
            "City": "都市",
            "category": "カテゴリー",
            "3rd_party_location": "サードパーティロケーション",
            "Zone": "ゾーン",
            "App_banner": "アプリバナー",
            "App_banner_pegasus": "アプリバナー (Pegasus)",
            "Flight_integration": "フライト統合",
            "SMS_integration": "SMS統合",
            "Invoice_Setting": "請求書",
            "Driver_refers_customer": "ドライバーが顧客を紹介",
            "Pax_refers_Pax": "顧客が顧客を紹介",
            "Customer_refers_customer": "顧客が顧客を紹介",
            "Dynamic_Surcharge": "ダイナミックサーチャージ",
            "Dynamic_Fare": "ダイナミック運賃",
            "FlatZone": "フラットレート",
            "IntercityZone": "都市間",
            "Intercity_Zone": "都市間ゾーン",
            "Intercity_Rate": "都市間料金",
            "Car_Color": "車両カラー",
            "third_party_integration": "サードパーティ統合",
            "bookingCom": "Booking.com",
            "HolidayTaxis": "HolidayTaxis",
            "Karhoo": "Karhoo",
            "Corporate": "法人",
            "KarhooIntegration": "Karhoo統合",
            "holidaytaxis": "HolidayTaxis",
            "karhoo": "Karhoo",
            "hoppa": "Hoppa",
            "Hoppa": "Hoppa",
            "HoppaIntegration": "Hoppa統合",
            "Third_Party_Integration_Hoppa": "サードパーティ統合 (Hoppa.com)",
            "titleHoppa": "Hoppa API認証情報",
            "noteHoppa": "Hoppaの技術チームと連絡を取り合い、クライアントとシークレットキーを提供してもらい、彼らのシステムに接続してください。",
            "titleHoppaWebhook": "Webhook設定",
            "noteHoppaWebhook": "以下のHoppaが設定するためのパラメータを提供してください。これらの認証情報は両方のプラットフォーム間で通信するために使用されます。問題が発生した場合は、技術プロバイダーに連絡してください。",
            "Third_Party_Integration_Booking": "サードパーティ統合 (Booking.com)",
            "Third_Party_Integration_HolidayTaxis": "サードパーティ統合 (HolidayTaxis)",
            "Third_Party_Integration_Karhoo": "サードパーティ統合 (Karhoo.com)",
            "Third_Party_Integration_Mozio": "サードパーティ統合 (Mozio.com)",
            "invoiceBranding": "請求書ブランディング",
            "titleInvoice": "会社ヘッダーとフッター情報をカスタマイズします。",
            "titleheader": "ヘッダータイトル",
            "descriptionHeader": "ヘッダー説明",
            "placehodelHeader": "例えば、メール、電話番号、住所、税番号などの会社詳細を追加します。",
            "titleFooter": "フッタタイトル",
            "placehodelFooter": "例えば、銀行振込情報やポリシーなどを追加します。",
            "descriptionFooter": "フッター説明",
            "titleBookingCom": "Booking.com API認証情報",
            "noteBookingCom": "Booking.comの技術チームと連絡を取り、クライアントキーとシークレットキーを提供してもらって彼らのシステムに接続してください。",
            "clientKey": "クライアントキー",
            "placeHolderClientKey": "クライアントキーを入力",
            "secretKey": "シークレットキー",
            "placeHolderSecretKey": "シークレットキーを入力",
            "titleBookingComWebhook": "Webhook設定",
            "noteBookingComWebhook": "Booking.comが設定するために以下の情報を提供してください。これらの認証情報は両プラットフォーム間の通信に使用されます。問題がある場合は技術提供者に連絡してください。",
            "webhook": "フリートWebhook URL",
            "clientId": "フリートクライアントキー",
            "clientSecret": "フリートシークレットキー",
            "driver_app": "ドライバーアプリ",
            "Jobs_tab": "ジョブタブ",
            "Email_config": "メール設定",
            "Driver_fields": "ドライバーフィールド",
            "Driver_document": "ドライバー書類",
            "Selected": "選択済み",
            "API_Management": "API管理",
            "Penalty_Policy_Customer": "顧客ペナルティポリシー",
            "Penalty_Policy_Supplier": "サプライヤーペナルティポリシー"
        }
    },
    "APIKeySetting": {
        "APIKey": "APIキー",
        "APIKey_title": "APIキーを他人と共有したり、ブラウザや他のクライアントサイドコードで公開しないでください。アカウントのセキュリティを保護するため、Good Journeyは公開されたAPIキーを自動的に無効にすることがあります。",
        "newKeyBtn": "新しいキーを作成",
        "updateApplication": "アプリケーションを更新",
        "RegisterApp": "新しいアプリケーションを登録",
        "RegisterBtn": "アプリケーションを登録",
        "appDescription": "アプリ説明",
        "appName": "アプリ名",
        "clientKey": "クライアントキー",
        "secretKey": "シークレットキー",
        "hostedEndPoint": "ホスティングエンドポイント",
        "maxEndpoints": "最大5つのエンドポイントを持つことができます",
        "addEndpoint": "エンドポイントを追加",
        "updateEndpoint": "エンドポイントを更新",
        "ManageWebhooks": "Webhookを管理",
        "CreateWebhook": "Webhookを作成",
        "subscriptionID": "サブスクリプションID",
        "webhookURL": "Webhook URL",
        "signingSecret": "署名シークレット",
        "eventsToSend": "送信するイベント",
        "listeningFor": "リッスニング対象",
        "assignedDriver": "割り当てられたドライバー",
        "bookingStatus": "予約ステータス",
        "driverLocation": "ドライバーの位置",
        "titleSigningSecret": "このシークレットはパートナーから提供されます。シークレットが漏洩した場合は、シークレットを更新するか、Webhookを削除できます。",
        "notUsingHTTPS": "Webhook URLがHTTPSを使用していません。",
        "invalidURL": "Webhook URLが無効です。",
        "clientType": "クライアントタイプ",
        "individual": "個人",
        "corporate": "法人",
        "signingSecretLength": "署名シークレットは少なくとも30文字以上である必要があります。",
        "bookingCreated": "予約が作成されました",
        "partner": "パートナー",
        "fleetManager": "フリートマネージャー"
    },
    "messages": {
        "credits": {
            "406": "アクションが失敗しました",
            "407": "アクションが失敗しました",
            "416": "クーポンが使用されています",
            "417": "アカウントが非アクティブです。管理者にお問い合わせください",
            "418": "支払い時にエラーが発生しました",
            "419": "プロモーションコードが無効です！",
            "420": "このプロモーションコードは別の乗車に適用されました。別のものを使用してください。",
            "421": "カード番号が無効です。",
            "422": "有効期限が無効です。",
            "429": "郵便番号が無効です。",
            "430": "AVS郵便番号が一致しません",
            "431": "AVS郵便番号が検証されていません",
            "432": "CVVが無効です。",
            "433": "CVVが一致しません",
            "434": "CVVが検証されていません",
            "435": "CVV発行者が参加していません",
            "436": "カードが拒否されました",
            "437": "クレジットカードの検証に失敗しました。情報を確認して再度お試しください。",
            "439": "CVVが処理されていません",
            "441": "発行者からCVVデータがありません",
            "443": "発行者/バンクネットスイッチからデータがありません",
            "445": "AVSシステムが処理できません",
            "446": "発行銀行がAVSをサポートしていません",
            "447": "AVSはあなたのビジネスでサポートされていません",
            "448": "このカードは繰り返し支払いが失敗したためブロックされています。支払い方法を変更するか、後で再度お試しください",
            "452": "カードが拒否されました。電話番号と同じ地域で登録された別のクレジットカードを入力してください",
            "453": "この支払いゲートウェイはサポートされていません",
            "454": "AVS住所が一致しません",
            "455": "AVS郵便番号と住所の両方が一致しません",
            "458": "認証に失敗しました",
            "459": "銀行カードはサポートしていません",
            "461": "このカードはサポートされていません",
            "462": "カード保有者名が無効です",
            "463": "検証コードが無効です",
            "464": "銀行は検証コードの生成と送信をサポートしていません",
            "470": "クレジットカードで予約を作成する前に旅行者のメールアドレスを入力してください",
            "474": "クレジットカードで予約を作成する前に旅行者の名前を入力してください",
            "475": "電話番号が必要です",
            "476": "金額が無効です",
            "524": "資金不足。アカウントに取引金額をカバーする十分な資金がありませんでした。",
            "911": "お支払い方法を認証できません。別のお支払い方法を選択して再度お試しください。",
            "2000": "お取り扱い不可",
            "2001": "資金不足。アカウントに取引金額をカバーする十分な資金がありません。",
            "2002": "限度額超過",
            "2003": "カード保有者の活動限度額超過",
            "2004": "カードの有効期限切れ",
            "2005": "無効なクレジットカード番号",
            "2006": "無効な有効期限",
            "2007": "アカウントなし",
            "2008": "カードアカウントの長さエラー",
            "2009": "そのような発行者なし",
            "2010": "カード発行者がCVVを拒否",
            "2011": "音声認証が必要。カード保有者の銀行が、取引を完了するために特別な認証コードを取得するために電話をかけるよう要求しています",
            "2012": "プロセッサーが拒否 - カード紛失の可能性",
            "2013": "プロセッサーが拒否 - カード盗難の可能性",
            "2014": "プロセッサーが拒否 - 詐欺の疑い",
            "2015": "取引が許可されていません",
            "2016": "重複取引",
            "2017": "カード保有者が請求を停止",
            "2018": "カード保有者がすべての請求を停止",
            "2019": "無効な取引",
            "2020": "違反",
            "2021": "セキュリティ違反",
            "2022": "拒否 - 更新されたカード保有者情報あり",
            "2023": "プロセッサーがこの機能をサポートしていません",
            "2024": "カードタイプが有効になっていません",
            "2025": "セットアップエラー - マーチャント",
            "2026": "無効なマーチャントID",
            "2027": "セットアップエラー - 金額",
            "2028": "セットアップエラー - 階層",
            "2029": "セットアップエラー - カード",
            "2030": "セットアップエラー - 端末",
            "2031": "暗号化エラー",
            "2032": "追加料金が許可されていません",
            "2033": "データに一貫性がありません",
            "2034": "何も実行されませんでした",
            "2035": "グループIIIバージョンでの金額の部分承認",
            "2036": "認証が見つからず、取り消しできません",
            "2037": "すでに取り消し済み",
            "2038": "プロセッサーが拒否。お客様の銀行が取引を受け付けないため、詳細は銀行に連絡してください。",
            "2039": "無効な認証コード",
            "2040": "無効な店舗",
            "2041": "拒否 - 承認のために電話してください",
            "2042": "無効なクライアントID",
            "2043": "エラー - 再試行せず、発行者に電話してください",
            "2044": "拒否 - 発行者に電話してください",
            "2045": "無効なマーチャント番号",
            "2046": "拒否。お客様の銀行が取引を受け付けないため、詳細は銀行に連絡してください。",
            "2047": "発行者に電話してください。カードを回収してください。このエラーは、カードが紛失または盗難として報告されたことを示します。",
            "2048": "無効な金額",
            "2049": "無効なSKU番号",
            "2050": "無効なクレジットプラン",
            "2051": "クレジットカード番号が支払い方法と一致しません",
            "2052": "無効なレベルIII購入",
            "2053": "カードが紛失または盗難として報告されました",
            "2054": "取り消し金額が認証金額と一致しません",
            "2055": "無効な取引分割番号",
            "2056": "取引金額が取引分割限度を超えています",
            "2057": "発行者またはカード保有者がカードに制限を設けています",
            "2058": "マーチャントがMasterCard SecureCodeを有効にしていません。",
            "2060": "住所検証とカードセキュリティコードが失敗しました",
            "2061": "無効な取引データ",
            "2062": "無効な税額",
            "2064": "無効な通貨コード",
            "2078": "無効なセキュア支払いデータ",
            "2080": "無効なユーザー認証情報",
            "3000": "プロセッサーネットワークが利用できません - 再度お試しください",
            "4001": "決済が拒否されました",
            "4006": "キャプチャ金額が許可された限度を超えました",
            "81703": "クレジットカードタイプはこのマーチャントアカウントで受け付けられていません。",
            "81706": "CVVが必要です",
            "81707": "CVVは3桁または4桁でなければなりません。",
            "81709": "有効期限が必要です。",
            "81710": "有効期限が無効です。",
            "81714": "クレジットカード番号が必要です。",
            "81715": "クレジットカード番号が無効です。",
            "81716": "クレジットカード番号は12〜19桁でなければなりません。",
            "81717": "クレジットカード番号は受け付けられたテスト番号ではありません。",
            "81718": "クレジットカード番号は、サブスクリプションに関連付けられている場合、サポートされていないカードタイプに更新できません。",
            "81723": "カード保有者名が長すぎます。最大175文字です。",
            "81809": "郵便番号は9文字以内の文字または数字のみで構成されます",
            "81813": "郵便番号には文字、数字、スペース、ハイフンのみを含めることができます",
            "91722": "支払い方法トークンが必要です。",
            "91723": "既存トークンの更新が無効です",
            "91730": "このマーチャントアカウントでは検証がサポートされていません。",
            "91734": "クレジットカードタイプはこのマーチャントアカウントで受け付けられていません。",
            "91738": "指定された支払い方法がクレジットカードではありません。",
            "91745": "クレジットカード更新のための無効なパラメータ。",
            "91826": "郵便番号が無効です。郵便番号は5桁または9桁の文字列で、ダッシュまたはスペースで区切られる場合があります。",
            "-1": "この顧客アカウントは非アクティブ化されています。管理者にお問い合わせください。"
        },
        "reBookingMsg": {
            "303": "選択した時間は現在時刻より遅くなければなりません！",
            "304": "この予約が見つかりませんでした",
            "500": "一部の予約情報が無効または欠落しています。確認して再度お試しください！"
        },
        "promoMsg": {
            "304": "プロモーションコードが無効です",
            "306": "無効なプロモーションコード。このプロモーションは他のゾーンに適用されているようです。",
            "413": "申し訳ありません、このプロモーションコードは現在利用できません",
            "414": "プロモーションコードが無効です",
            "415": "プロモーションコードが無効です",
            "416": "このプロモーションコードはすでに使用されています。",
            "419": "申し訳ありません、このプロモーションコードは現在利用できません",
            "420": "申し訳ありません、プロモーションの使用制限に達しました",
            "421": "申し訳ありません、このプロモーションコードは公開された使用制限に達しました",
            "422": "このプロモーションコードはあなたのアカウントでは使用できません",
            "423": "申し訳ありません、プロモーション予算の制限に達しました",
            "424": "無効なプロモーションコード。このプロモーションは紹介された顧客にのみ適用されるようです",
            "425": "このプロモーションコードは1日の使用制限に達しました。",
            "426": "1日のプロモーション使用制限に達しました。",
            "427": "プロモーションコードを適用できません。この顧客のプロフィールが不完全です。",
            "428": "この顧客は月間のプロモーションコードを使い切りました。",
            "430": "年間のプロモーションコードを使い切りました。",
            "431": "この支払い方法ではプロモーションコードを使用できません。",
            "432": "この運賃金額はプロモーションコードの対象外です。",
            "433": "選択した時間にプロモーションコードを使用できません。",
            "434": "申し訳ありません、プロモーション予算の制限に達しました。",
            "phoneInvalid": "プロモーションコードを適用する前に乗客の電話番号を入力してください"
        },
        "updateBookingMsg": {
            "303": "予約の更新に失敗しました。再度お試しください！",
            "304": "この予約が見つかりませんでした",
            "pickUpTime": "選択した時間は現在時刻より遅くなければなりません！",
            "pickup": "このエリアでは予約サービスが利用できません",
            "destination": "一部の予約情報が無効または欠落しています。確認して再度お試しください！",
            "dispatchType": "一部の予約情報が無効または欠落しています。確認して再度お試しください！"
        },
        "sessionExpired": {
            "403": "別のデバイスからアカウントにログインしたか、セッションが期限切れのためログアウトされました"
        },
        "not_found": {
            "404": "リクエストしたページが見つかりませんでした"
        },
        "booking": {
            "303": "選択した時間は現在時刻より遅くなければなりません！",
            "304": "予約 {0} の更新が進行中のアクションにより失敗しました。後でもう一度お試しください。",
            "305": "Booking.comによって予約がキャンセルまたは完了しました。キューから削除するにはジョブを拒否してください。",
            "306": "選択した車両タイプに料金が設定されていません。料金を設定するには、設定 > 車両 > 車両タイプに進んでください。",
            "307": "予約を更新できません。後で再度お試しください。",
            "407": "タイプ料金が追加の場所をサポートしていません",
            "500": "一部の予約情報が無効または欠落しています。確認して再度お試しください！",
            "upgroup_success": "予約のグループ解除に成功しました",
            "Please_Select_driver": "ドライバーを選択してください",
            "assign_driver_vehicle_success": "ドライバー/車両が正常に割り当てられました",
            "assign_driver_success": "ドライバーが正常に割り当てられました",
            "detach_driver_success": "予約のドライバー割り当て解除に成功しました",
            "detach_vehicle_success": "車両が正常に切り離されました",
            "Group_success": "予約のグループ化に成功しました",
            "change_supplier_success": "予約のサプライヤー変更に成功しました",
            "change_supplier_partial": "部分成功：進行中のアクションにより、次のトリップ（予約ID）でバッチ割り当てに失敗しました：{0}。",
            "detach_driver_partial": "部分成功：進行中のアクションにより、次のトリップ（予約ID）でバッチ割り当てに失敗しました：{0}。",
            "assign_driver_partial": "部分成功：進行中のアクションにより、次のトリップ（予約ID）でバッチ割り当てに失敗しました：{0}。",
            "update_dispatch": "更新とディスパッチ",
            "remove_booking_false": "予約の削除に失敗しました",
            "remove_booking_success": "予約の削除に成功しました",
            "GROUP_NOT_FOUND": "グループIDが見つかりません",
            "NO_SHARING_BOOKINGS": "顧客の予約がライドシェアを許可している場合のみ、予約をグループ化できます。",
            "DIFFERENT_CAR_TYPES": "グループ化しようとしている予約の車両タイプが異なります",
            "LIMIT_EXCEEDED_ON_SEATS_OR_LUGGAGE": "予約をグループ化できません：座席または荷物の制限を超えました。",
            "INVALID_CAR_TYPE": "ドライバーを割り当てられません。ドライバーの車両の座席と荷物容量を確認してください。",
            "VALIDATION_ERROR": "検証エラー",
            "INTERNAL": "内部サーバーエラー",
            "fare_gt_zero": "運賃は0より大きくなければなりません！",
            "edit_total_fail": "合計値の編集に失敗しました！",
            "complete_fail": "予約を完了できません！",
            "cancle_fail": "この予約のキャンセル時に何か問題が発生しました。再度お試しください！",
            "incident_fail": "インシデント失敗",
            "phone_invalid": "有効な電話番号を入力してください！",
            "add_psg_fail": "新しい乗客を追加できません",
            "REMOVE_RECIPIENT_DELIVERED": "配達済みの受取人を削除できません",
            "REMOVE_LAST_ACTIVE_RECIPIENT": "最後のアクティブな受取人を削除できません",
            "ORDER_HAS_BEEN_FINISHED": "注文はすでに終了しています",
            "end_booking_leave": "このページを離れると予約が終了します。",
            "update_booking_fail": "予約の更新に失敗しました",
            "EXTERNAL_ID_EXISTED": "外部IDがシステムにすでに存在します。",
            "area_service_unavailable": "このエリアでは予約サービスが利用できません",
            "pickupSameDestination": "ピックアップ住所は目的地と異なっていなければなりません。",
            "time_invalid": "選択した時間は現在時刻より遅くなければなりません！",
            "psg_inactivate": "この顧客アカウントは非アクティブ化されています。管理者にお問い合わせください",
            "data_incorect": "何か問題が発生しました。後で再度お試しください。",
            "preAuthorize": "予約を保証するために{0}の金額が必要ですので、アカウントを確認してください",
            "booking_limited": "申し訳ありません、1分/1日の最大予約数に達しました。後で再度お試しください。",
            "minimumBookAhead": "予約のピックアップ時間は現在時刻から少なくとも{0}時間{1}分後でなければなりません",
            "exDoDiffDo": "追加の目的地は目的地と異なっていなければなりません。",
            "recurringTimeRange": "日付が範囲外です。予約は{0}ヶ月先までしか作成できません。問題がある場合は管理者に連絡してください。",
            "recipientsLimit": "受取人の最大数は{0}です。",
            "maximumBookAhead": "予約のピックアップ時間は現在時刻から{0}日後を超えることはできません",
            "Please_select_or_add_a_card": "カードを選択または追加してください！",
            "Unable_to_process_by_fare_zero": "リクエストを処理できません。車両タイプまたは予約タイプを変更して再度お試しください。サポートが必要な場合はサポートチームにご連絡ください",
            "Can_not_connect_to_server": "サーバーに接続できません！",
            "Check_connection_or_contact_system_support": "接続を確認するか、システムサポートに助けを求めてください！",
            "Create_new_booking_success": "新しい予約が正常に作成されました！",
            "Create_new_recurring_booking_success": "新しい予約が正常に作成されました",
            "Update_booking_success": "この予約が正常に更新されました！",
            "Booking_drop_of_success": "予約の降車に成功しました",
            "Cancel_booking_success": "この予約が正常にキャンセルされました！",
            "adjust_fare_booking_success": "運賃が正常に調整されました！",
            "adjust_fare_booking_fail": "運賃調整に失敗しました！",
            "Booking_detail_not_found": "予約の詳細が見つかりません！",
            "Check_promo_error": "プロモーションコードのチェックエラー！",
            "Invalid_format": "無効な形式",
            "Flight_not_found": "フライトが見つかりません！",
            "Add_new_credit_error": "新しいクレジットの追加エラー！",
            "Socket_server_disconnected": "ソケットサーバーが切断されました！",
            "Please_reload_page_or_check_your_connection": "申し訳ありません。リクエストを処理できません。再度お試しください。",
            "request_timeout": "申し訳ありません。リクエストがタイムアウトしました。再度お試しください。",
            "Check_customer_error": "顧客のチェックエラー！",
            "Please_select_car_type": "車両タイプを選択してください",
            "DropOff_country": "この車両タイプでは降車地点が利用できません。別の車両タイプを選択するか、ルートを調整してください。",
            "Different_Country": "ピックアップと降車地点は2つの異なる国でなければなりません。",
            "Please_enter_destination_for_ridesharing_booking": "ライドシェア予約の目的地を入力してください",
            "Extra_detination_must_be_difference_from_destination": "追加の目的地は現在の目的地と異なっていなければなりません",
            "Pickup_address_must_be_different_from_destination": "ピックアップ住所は目的地と異なっていなければなりません",
            "Best_price": "最安値",
            "You_may_be_charged_extra_fee_if_your_trip_excceds": "旅行が超過した場合、追加料金がかかる場合があります",
            "You_may_be_charged_extra_fee_if_your_trip_excceds_with_params": "旅行が{0} {1}、{2} {3}を超えた場合、追加料金がかかる場合があります",
            "Reject_booking_success": "この予約は正常に拒否されました！",
            "Reject_fail": "この予約の拒否時に何か問題が発生しました。再度お試しください！",
            "Please_select_payment_method": "支払い方法を選択してください",
            "Driver_signed_out_or_deactivated": "このドライバーはログアウトしたか、非アクティブ化されました",
            "reservation_booking_overlaps": "申し訳ありません、この予約はドライバーが受け入れた別の予約と重複しています。",
            "driver_is_not_available": "申し訳ありません、このドライバーは現在利用できません。",
            "seats_luggage_limit": "予約をドライバーに割り当てられません。座席または荷物が制限を超えています。",
            "full_capacity": "この予約は旅行の最大容量に達しているため、ドライバーに割り当てられません。",
            "marked_failed_ok": "注文が失敗としてマークされました。",
            "warning_markFail_lastRecipient": "これがこの予約の最後の受取人です。この注文を失敗としてマークすると、予約を完了する必要があります。",
            "BOOKINGS_LIMIT_EXCEEDED": "各グループには最大5つの注文しか割り当てられません",
            "bookingCanceled": "Booking.comによって予約がキャンセルまたは完了しました。キューから削除するにはジョブを拒否してください。"
        },
        "item_no_found": "アイテムが見つかりませんでした",
        "commonMessages": {
            "greater_than_0": "0より大きい値を入力してください。",
            "Required_field": "このフィールドは必須です",
            "bankPhone_is_required": "電話番号が無効です",
            "bankPhone": "電話番号",
            "distance_from_equa_or_smaller": "「開始」距離は「終了」距離以下でなければなりません",
            "must_be_number": "有効な数字を入力してください",
            "equal_or_greater_than_0": "0以上の値を入力してください。",
            "must_be_integer": "整数を入力してください",
            "invalid_email_format": "有効なメールアドレスを入力してください。",
            "greater_or_equa": "{0}以上の値を入力してください。",
            "value_must_between": "{0}から{1}の間の値を入力してください。",
            "large_10x_markup_price": "編集された運賃は見積もり運賃の10倍を超えてはいけません。",
            "greater_than": "{0}より大きい値を入力してください。",
            "less_than": "{0}より小さい値を入力してください。",
            "less_or_equal": "{0}以下の値を入力してください。",
            "driver_earnings_warning": "オフライン支払い：ドライバーの収益が集めた運賃を超えてはいけません。",
            "limit_amount": "制限金額以下の値を入力してください",
            "Integer_from_range": "{0}から{1}までの整数を入力してください",
            "integer_greater_than_0": "0より大きい整数を入力してください。（パーセントと小数点区切りは1文字で100未満）",
            "Error_message": "エラー！",
            "callcenter_newbooking_message": "電話番号<span class='bold'>{0}</span>での新しい予約リクエスト",
            "callcenter_existed_booking_message": "電話番号<span class='bold'>{1}</span>でアクティブな予約<span class='bold'>#{0}</span>が存在します",
            "callcenter_close_newbooking_form_warning": "新しい顧客リクエストを受ける前に現在の予約を終了/完了してください",
            "Emergency_SOS_Title": "緊急SOS",
            "Please_enter_a_future_date": "将来の日付を入力してください",
            "Card_holder_is_required": "カード保有者が必要です",
            "Card_number_is_required": "カード番号が必要です",
            "Expired_date_is_required": "有効期限が必要です",
            "Credit_code_is_required": "クレジットコード（最終検証）を入力してください",
            "Expired_date_is_invalid": "有効期限が無効です。mm/yy形式で有効期限を入力してください",
            "cvv_is_required": "CVVが必要です",
            "City_is_required": "都市が必要です",
            "State_is_required": "州が必要です",
            "Address_is_required": "住所が必要です",
            "Zipcode_is_required": "郵便番号が必要です",
            "Country_is_required": "国が必要です",
            "Over_query_limit": "Google APIが一時的なエラーでロードできません。後で再度お試しください。",
            "outOfInputQueueOrder": "注文番号はキュー内のドライバーの総数を超えることはできません。",
            "Same_pickup_destination": "ピックアップ住所は目的地住所と異なっていなければなりません。",
            "Max_length": "最大長は{0}文字です。",
            "choose_at_least_one": "少なくとも1つのオプションを選択してください。",
            "choose_at_least_one_intercity_schedule": "スケジュールを追加するか、顧客が希望時間をリクエストできるようにしてください。",
            "startDate_greater_than_endDate": "開始日が終了日より遅いです"
        },
        "trip": {
            "cancel_trip_success": "この旅行は正常にキャンセルされました！",
            "data_incorect": "ドライバーが割り当てられていません。ドロップダウンリストからドライバーを選択してください。<br/>利用可能なドライバーがいない場合は、後で割り当てるために自動ディスパッチを選択してください。",
            "INTERNAL": "内部サーバーエラー。",
            "NOT_FOUND": "旅行が見つからないか、ステータスが無効です。",
            "SAME_DRIVER": "割り当て先は現在のドライバーと異なっていなければなりません。",
            "OVERLAP": "申し訳ありません、この旅行はこのドライバーが受け入れた別の旅行と重複しています。",
            "CANNOT_ASSIGN": "このドライバーは無効です（例：ドライバーが見つからない、オンラインでない、ログインしていない）。"
        },
        "car_mgmt": {
            "Search_car_color": "車両カラーを検索",
            "Searching": "検索中"
        },
        "driver_note": {
            "Search_driver_note": "ドライバーノートを検索",
            "Searching": "検索中"
        }
    },
    "errors": {
        "303": "ユーザーが見つかりません",
        "453": "ゲートウェイがサポートされていません",
        "553": "申し訳ありません！顧客が進行中の予約を持っているため、後で再度お試しください。",
        "1007": "開始日と終了日の期間は60日未満でなければなりません！！",
        "3001": "電話番号が既に存在します",
        "3002": "生年月日が無効です",
        "3003": "有効期限が無効です",
        "3004": "ドライバーが見つかりません",
        "3005": "ドライバーの決済情報が不足しています（銀行住所、都市、州、郵便番号）",
        "3006": "銀行口座情報が不足しています（口座名、口座タイプ、ルーティング番号、口座番号、SSN）",
        "3007": "ジョブ中のドライバーは編集できません。",
        "3008": "このドライバーは現在ログイン中です。情報を編集するにはログアウトするよう依頼してください。",
        "3009": "このドライバーは1つ以上の予約を受け入れています。ドライバーの情報を更新/変更する前に、予約を他のドライバーに割り当ててください。",
        "3010": "ディスパッチ接続に失敗しました。",
        "3011": "このドライバーはアクティブ化前に運用設定が必要です。",
        "3012": "このドライバーはアクティブ化前に運用設定が必要です。",
        "3013": "非アクティブ状態のアイテムのみ削除できます。",
        "3014": "必須フィールドが不足しています。確認してください。",
        "3015": "無効な生年。確認してください。",
        "3016": "無効な郵便番号。確認してください。",
        "3017": "無効な州/県。確認してください。",
        "3018": "無効な口座名。確認してください。",
        "3019": "無効なルーティング番号。確認してください。",
        "3020": "誕生日が必要です。確認してください。",
        "3021": "無効なSSN番号。確認してください。",
        "3022": "ドライバーの銀行口座が資金を受け取れません。",
        "3023": "ログイン中のドライバーは操作できません。後で再度お試しください。",
        "3026": "ドライバーが存在しません。",
        "3027": "ドライバーの銀行口座が設定されていません。ドライバーモジュールで銀行口座を設定してください。",
        "3028": "選択したドライバーへの支払いに失敗しました。再度お試しください。",
        "3029": "ドライバーの収益データのクリアに失敗しました。再度お試しください。",
        "3032": "このユーザー名はシステムに既に存在します。別のものを入力してください。",
        "3033": "無効なBSB。番号はxxxxxxの形式で6桁である必要があります。",
        "3039": "グループIDが必要です。",
        "3040": "この電話番号は既に%{brandName}ドライバーによって使用されています（電話番号は重複できません）。別のものを入力してください。",
        "3041": "ユーザー名が既に存在します。",
        "3044": "国民IDが重複しています。",
        "3045": "ドライバーはすでに車両を持っています。ページを更新してください",
        "3046": "車両の会社とドライバーの会社が異なります。",
        "4001": "あなたのナンバープレートはシステムに既に存在します。別のものを入力してください。",
        "4002": "無効な有効期限",
        "4003": "車両は削除前に非アクティブ化する必要があります",
        "4004": "車両が見つかりません",
        "4005": "この車両は既に非アクティブです",
        "4006": "この車両は使用中で、削除または非アクティブ化できません",
        "4007": "ナンバープレートが必要です",
        "4009": "車両が予約に割り当てられています。",
        "4010": "車両がドライバーに割り当てられています。",
        "5001": "会社が既に存在します",
        "5004": "会社が見つかりません",
        "6001": "車両モデルが既に存在します",
        "6004": "車両モデルが見つかりません",
        "6005": "車両モデルが使用中です",
        "7001": "あなたの車両メーカー名はシステムに既に存在します。別のものを入力してください。",
        "7004": "車両メーカーが見つかりません",
        "8001": "車両タイプが既に存在します",
        "8004": "車両タイプが見つかりません",
        "9001": "フラットルートが既に存在します",
        "9004": "フラットルートが見つかりません",
        "10001": "ゾーンが既に存在します",
        "10004": "ゾーンが見つかりません",
        "11001": "ゾーンが既に存在します",
        "11002": "このエリアは別のエリアと重複しています。再度描いてください。",
        "11003": "ゾーンはポリゴンオブジェクトでなければなりません",
        "11004": "ゾーンが見つかりません",
        "11005": "ゾーンが使用中です",
        "12001": "受信トレイが既に存在します",
        "12002": "SMS Twilioを設定してください",
        "12004": "受信トレイが見つかりません",
        "12005": "フィルターに一致する受信者がいません。リストを確認して再度お試しください。",
        "12006": "ファイルに顧客が存在しません。",
        "13001": "このプロモーションコードはシステムに既に存在します。別のものを入力してください",
        "13002": "無効な日付",
        "13003": "プライベート",
        "13004": "プロモーションコードが見つかりません",
        "13005": "新規顧客のみ",
        "13006": "数量制限超過",
        "13007": "予算制限超過",
        "13008": "総使用制限超過",
        "13011": "このプロモーションキャンペーンは非アクティブ化または削除されています",
        "13012": "日付範囲が無効です",
        "13013": "無効なプロモーションコード。このプロモーションは他のゾーンに適用されているようです",
        "13014": "選択したゾーンは非アクティブ化されています。",
        "14004": "予約が見つかりません",
        "15002": "あなたのフリートは非アクティブ化されています。管理者にお問い合わせください",
        "15004": "フリートが見つかりません",
        "16001": "あなたのフラットレート名はシステムに既に存在します。別のものを入力してください",
        "16004": "運賃が見つかりません",
        "17001": "名前が既に存在します",
        "17004": "パッケージレートが見つかりません",
        "18001": "名前が既に存在します",
        "18002": "運賃が使用中です",
        "18004": "運賃が見つかりません",
        "19001": "あなたのパッケージ名はシステムに既に存在します。別のものを入力してください。",
        "19002": "運賃が使用中です",
        "19004": "運賃が見つかりません",
        "20001": "このユーザー名はシステムに既に存在します。別のものを入力してください。",
        "20002": "あなたのアカウントが非アクティブです。管理者にお問い合わせください",
        "20003": "入力したユーザー名またはパスワードが正しくありません。再度お試しください",
        "20004": "ユーザー名がシステムに登録されていません。",
        "20005": "このリンクは期限切れです",
        "20006": "現在のパスワードが一致しません。再度お試しください。",
        "20007": "ユーザー#が既に存在します",
        "20008": "このメールはすでにシステムに存在します。別のものを入力してください。",
        "21001": "あなたの権限名はシステムに既に存在します。別のものを入力してください。",
        "21002": "この権限は現在ユーザー（複数）に割り当てられているため、非アクティブ化できません。",
        "21003": "ロールがアクティブです",
        "21004": "ロールが見つかりません",
        "22001": "顧客が既に存在します",
        "22004": "顧客が見つかりません",
        "22005": "無効なファイル形式。",
        "24001": "電話番号が無効です",
        "25001": "この名前はシステムに既に存在します。別のものを入力してください。",
        "25002": "無効な時間範囲",
        "25004": "シフトが見つかりません",
        "26001": "この名前はシステムに既に存在します。別のものを入力してください。",
        "26002": "このシフトは現在運用で使用中です。削除前に割り当てを解除してください",
        "26004": "シフトテンプレートが見つかりません",
        "27001": "キャンペーンが既に存在します",
        "27002": "キャンペーンがアクティブ化されています",
        "27004": "キャンペーンが見つかりません",
        "28001": "都市が既に存在します",
        "28002": "都市がアクティブ化されています",
        "28003": "都市が使用中です",
        "28004": "都市が見つかりません",
        "29002": "電話番号が既に存在します",
        "30001": "待機エリアが見つかりません",
        "30002": "待機エリアが使用中です",
        "30003": "あなたの待機エリア名はシステムに既に存在します。別のものを入力してください",
        "30004": "待機エリアは互いに重複してはいけません",
        "30005": "待機エリアの座標が無効です",
        "30006": "ピックアップエリアが無効です",
        "31001": "あなたの会社IDはシステムに既に存在します。別のものを入力してください。",
        "31002": "このユーザー名はシステムに既に存在します。別のものを入力してください。",
        "31003": "法人企業が見つかりません。",
        "31004": "法人ユーザーが見つかりません。",
        "31005": "非アクティブな法人企業のみ削除できます。",
        "31006": "この電話番号は法人ユーザーに既に存在します。別のものを入力してください。",
        "31007": "法人旅行者が見つかりません。",
        "31008": "カードが見つかりません。",
        "31009": "この電話番号はシステムに既に存在します。別のものを入力してください。",
        "31010": "この法人企業は非アクティブ化されています。詳細は管理者に連絡してください。",
        "31011": "この旅行者は非アクティブな法人アカウントに属しているため、アクティブ化できません。",
        "31013": "この名前を持つビジネスが既に存在します。",
        "31014": "少なくとも1つの車両タイプに対して料金を割り当ててください",
        "31015": "このメール/電話番号はすでに別のアカウントにリンクされています。",
        "31016": "このメール/電話番号は別の法人アカウントにリンクされています。プロフィールを更新するか、既存のアカウントからユーザーを削除してから新しいアカウントに割り当ててください。",
        "31017": "このメール/電話番号が空です。有効なメール/電話番号を入力してください。",
        "32001": "あなたのサービス名はシステムに既に存在します。別のものを入力してください。",
        "32004": "サービスが見つかりません。",
        "33002": "時間範囲が互いに重複してはいけません。",
        "34001": "コードが既に存在します。",
        "34013": "日付範囲が無効です。",
        "36001": "名前が既に存在します。",
        "39001": "書類が必要です",
        "43001": "ペナルティテンプレートが既に存在します",
        "43002": "ペナルティテンプレートが見つかりません",
        "44001": "料金テンプレートが既に存在します",
        "44002": "料金テンプレートが見つかりません",
        "44003": "料金テンプレートは現在法人に割り当てられています。削除する前に割り当てを解除してください。",
        "45001": "追加料金ポリシーが既に存在します",
        "45002": "追加料金ポリシーが見つかりません",
        "45003": "追加料金項目が見つかりません",
        "46001": "名前が既に存在します",
        "46002": "項目が見つかりません",
        "71001": "ダイナミックサーチャージが既に存在します。",
        "71002": "このエリアは別のエリアと重複しています。再度描いてください。",
        "71003": "ダイナミックサーチャージはポリゴンオブジェクトでなければなりません。",
        "71004": "ダイナミックサーチャージが見つかりません。",
        "72001": "ダイナミック運賃が既に存在します。",
        "72002": "このエリアは別のエリアと重複しています。再度描いてください。",
        "72003": "ダイナミック運賃はポリゴンオブジェクトでなければなりません。",
        "72004": "ダイナミック運賃が見つかりません。",
        "74001": "ゾーンが既に存在します。",
        "74002": "このエリアは別のエリアと重複しています。再度描いてください。",
        "74003": "ゾーンはポリゴンオブジェクトでなければなりません。",
        "74004": "ゾーン運賃が見つかりません。",
        "74005": "ゾーン運賃が見つかりません。",
        "80001": "アプリバナーが既に存在します。",
        "80002": "アプリバナーが見つかりません。",
        "80003": "非アクティブ状態のアイテムのみ削除できます。",
        "81001": "ファイルアップロードが空です。",
        "81002": "ファイルアップロードはサイズ制限があります。",
        "82002": "FlightAwareの設定中に何か問題が発生しました。後で再度お試しください。",
        "90001": "都市間ゾーンが既に存在します。",
        "90002": "このエリアは別のエリアと重複しています。再度描いてください。",
        "90003": "都市間ゾーンはポリゴンオブジェクトでなければなりません。",
        "90004": "都市間ゾーンが見つかりません。",
        "90005": "都市間ゾーンが使用中です。",
        "91001": "都市間料金が既に存在します。",
        "91002": "都市間料金が使用中です。",
        "91003": "都市間料金が見つかりません。",
        "92001": "ルート名が既に存在します。",
        "92002": "ルートが見つかりません。",
        "92003": "このルートは他のルートに既に存在します。確認して再度お試しください。",
        "92004": "このルートにはアクティブな旅行があります。",
        "93001": "ドライバーノートはシステムに既に存在します。別のものを入力してください。",
        "93002": "非アクティブなドライバーノートのみ削除できます。",
        "93003": "ドライバーノートが使用中です。",
        "93004": "ドライバーノートが見つかりません。",
        "94001": "あなたの車両カラー名はシステムに既に存在します。別のものを入力してください。",
        "94002": "車両カラーが見つかりません。",
        "400000": "電話番号が無効です！",
        "400002": "検証エラーが発生しました。",
        "400003": "許可が拒否されました。",
        "400005": "ファイル内容が無効です。",
        "500000": "内部システムエラー。",
        "500001": "SMS Twilioを設定してください。",
        "500002": "リクエストがタイムアウトしました。後で再度お試しください。",
        "600001": "場所が見つかりません。",
        "600002": "この経度と緯度の座標はシステムに既に存在します。別のものを追加してください。",
        "610001": "カテゴリーが既に存在します。",
        "610002": "カテゴリーが見つかりません。",
        "610003": "カテゴリーがパーティーロケーションに存在します。",
        "700001": "タイプが使用中です。",
        "700002": "パートナーが見つかりません。",
        "700003": "あなたのパートナータイプ名はシステムに既に存在します。別のものを入力してください。",
        "800000": "この店舗は別のマーチャントアカウントに割り当てられています。別のものを選択してください。",
        "800001": "マーチャントの銀行口座が資金を受け取れません。",
        "800002": "マーチャントの銀行口座が資金を受け取れません。",
        "31016_2": "メッセージ：このメール/電話番号はすでに別のアカウントにリンクされています。アカウントマネージャーに連絡して支援を受けてください。",
        "undefined": "サーバーに問題が発生しました。再度お試しください。",
        "completeBooking": {
            "113": "お支払い方法を認証できません。別のお支払い方法を選択して再度お試しください。",
            "450": "残高が不足しているため支払いができません。別の方法を選択してください",
            "451": "残高が不足しているため支払いができません。別の方法を選択してください",
            "521": "ウォレットの資金が不足しています。"
        },
        "upload_file_fail": "無効なファイルです。確認して再度お試しください。",
        "upload_photo_fail": "無効なファイルです。確認して再度お試しください。",
        "missing_some_field": "無効なフィールドです。確認して再度お試しください。",
        "validAddress": "有効なアドレスを選択してください"
    },
    "statusDisplay": {
        "supplierAccepted": "サプライヤーが承認しました",
        "delivery_supplierAccepted": "サプライヤーが承認しました",
        "completedWithoutService": "ドライバーなしで完了",
        "confirmed": "予約確認済み",
        "pending": "保留中",
        "queue": "ディスパッチ中",
        "offered": "ディスパッチ中",
        "booked": "ドライバーが向かっています",
        "engaged": "乗車中",
        "droppedOff": "降車済み",
        "pre": "注文受付済み",
        "recurrent": "定期便",
        "vacant": "",
        "vacantTime": "到着して待機中",
        "arrived": "完了",
        "completed": "キャンセル",
        "canceled": "現れなかった",
        "noShow": "処理待ち",
        "action": "インシデント",
        "incident": "タイムアウト",
        "pickupTimeExceeded": "クレジットカードによるキャンセル",
        "canceledByCC": "乗客によるキャンセル",
        "canceledByPassenger": "ドライバーによるキャンセル",
        "canceledByDriver": "パートナーによるキャンセル",
        "canceledByPartner": "パートナーによるキャンセル",
        "canceledBymDispatcher": "パートナーによるキャンセル",
        "canceledByCorporateAdmin": "法人管理者によるキャンセル",
        "canceledByAPI": "APIによるキャンセル",
        "canceledByWebBooking": "ウェブ予約によるキャンセル",
        "canceledByTimeout": "タイムアウトによるキャンセル",
        "accepted": "受付済み",
        "rejected": "拒否済み",
        "canceledByCorpAd": "法人管理者によるキャンセル",
        "started": "開始済み",
        "intransit": "移動中",
        "empty": "空",
        "notLive": "部分 - 未ライブ",
        "live": "部分 - ライブ",
        "full": "満席",
        "delivering": "配達中",
        "delivery_booked": "ドライバーが送信者に向かっています",
        "delivery_arrived": "到着して送信者を待機中",
        "shopForYou_booked": "ドライバーが現金を集めに向かっています",
        "shopForYou_arrived": "到着して現金の受け取りを待機中",
        "collecting": "ドライバーが商品を集めに向かっています",
        "confirmed_delivery": "確認済み",
        "food_booked": "ドライバーがマーチャントに向かっています",
        "food_collecting": "到着してアイテムの受け取りを待機中",
        "mart_collecting": "到着してアイテムの受け取りを待機中",
        "otwMerchant": "ドライバーがマーチャントに向かっています",
        "arrivedAndWaitingToCollectItem": "到着してアイテムの受け取りを待機中",
        "canceledByAirlineAdmin": "航空会社管理者によるキャンセル",
        "partialCompleted": "部分完了",
        "failed": "失敗",
        "allocated": "割り当て済み"
    },
    "tripTypes": {
        "scheduled": "スケジュールされた旅行",
        "requested": "リクエストされた旅行",
        "preferred": "優先"
    },
    "login": {
        "Username": "ユーザー名",
        "Password": "パスワード",
        "Remember_me": "ログイン情報を記憶",
        "Forgot_your_password": "パスワードを忘れましたか",
        "Login": "ログイン"
    },
    "invoice": {
        "createBtn": "請求書を作成",
        "searchPlInvoice": "クライアント、連絡先、請求書番号を検索",
        "searchPl": "予約ID、顧客名を検索",
        "createInvoice": "請求書を作成",
        "invoicePeriod": "請求期間",
        "invoicePeriodNote": "月ごとの日付範囲を選択できます。",
        "viewEdit": "表示/編集",
        "billingContact": "請求先連絡先",
        "memo": "メモ",
        "memoMessage": "顧客へのメッセージを残す",
        "dueDate": "期日",
        "updateInvoice": "請求書を更新",
        "chargeCustomer": "顧客に請求",
        "invoiceNumber": "請求書番号",
        "noItem": "アイテム数",
        "issuedDate": "発行日",
        "refId": "参照番号",
        "pickupTime": "ピックアップ時間",
        "pickup": "ピックアップ場所",
        "destination": "降車場所",
        "customerName": "顧客名",
        "totalAmount": "合計金額",
        "amountDue": "支払い金額",
        "sendInvoice": "請求書を送信",
        "copyInvoice": "請求書をコピー",
        "downloadPDF": "PDFとしてダウンロード",
        "paymentLink": "支払いリンク",
        "deleteInvoice": "請求書を削除",
        "onlySave": "請求書を保存",
        "saveSend": "請求書を保存して送信",
        "sendEmail": "メールを送信",
        "addRecipients": "受信者を追加",
        "editInvoice": "請求書を編集",
        "check": "小切手",
        "wire": "銀行振込",
        "digitalWallet": "デジタルウォレット",
        "cash": "現金",
        "completedTime": "完了時間",
        "Status": "ステータス",
        "bookingStatus": "予約ステータス",
        "chargeBtn": "{0}を請求",
        "pastDue": "支払期限超過",
        "payCompleted": "この請求書は正常に支払われました！",
        "Delete": "請求書を削除しますか？",
        "Delete_content": "この操作は請求書を完全に削除します。必要に応じて、これらの注文に対して新しい請求書を作成できます。",
        "viewInvoice": "請求書を表示",
        "pending": "保留中",
        "paid": "支払済み",
        "CopyLink": "リンクをコピー",
        "partial": "一部",
        "corporatePrepaid": "法人プリペイド"
    },
    "cue": {
        "bookingServiceType": "サービス種別",
        "pointToPoint": "ポイントトゥポイント",
        "fromAirport": "空港から",
        "toAirport": "空港へ",
        "hourly": "時間単位",
        "roundTrip": "往復",
        "jobVisibility": "ジョブの公開設定",
        "jobPubic": "公開",
        "jobPrivate": "非公開",
        "jobSetPubic": "公開に設定",
        "jobSetPrivate": "非公開に設定",
        "supplierAccepted": "サプライヤーが承認しました",
        "creationTime": "作成時間",
        "Pax": "乗客数",
        "Luggage": "荷物",
        "allocated": "割り当て済み",
        "completedWithoutService": "ドライバーなしで完了",
        "shuttle": "シャトル",
        "farmIn": "ファームイン",
        "chargeCode": "チャージコード",
        "clientCaseMatter": "旅行の説明",
        "operatorNote": "内部メモ",
        "farmOut": "ファームアウト",
        "roaming": "ローミング",
        "FlightUpdate": "フライト更新",
        "networkType": "ネットワークタイプ",
        "InHouse": "社内",
        "carHailing": "カーヘイリング",
        "uploadBookings": "予約のアップロード",
        "moreActions": "その他のアクション",
        "rideSharing": "ライドシェアリング",
        "Rearrange": "並べ替え",
        "GroupId": "グループID #",
        "Active": "アクティブ",
        "Finished": "終了",
        "Home_affiliate": "ホーム / アフィリエイト",
        "Booking_type": "予約タイプ",
        "From": "開始",
        "balanceWallet": "{0}: 残り{1}",
        "partialWallet": "ウォレットでの部分支払い",
        "To": "終了",
        "Status": "ステータス",
        "Migration_Status": "移行ステータス",
        "Migrated": "移行済み",
        "Select_Status": "ステータスを選択",
        "Operator": "オペレーター",
        "Car_types": "車両タイプ",
        "Service_class": "サービスクラス",
        "Booking_from": "予約元",
        "All": "すべて",
        "Pending": "保留中",
        "Dispatching": "ディスパッチ中",
        "Waiting_for_process": "処理待ち",
        "Confirmed_reservation": "予約確認済み",
        "Driver_on_the_way": "ドライバーが向かっています",
        "Arrived_and_waiting": "到着して待機中",
        "Passenger_on_board": "乗客乗車中",
        "Dropped_off": "降車済み",
        "combobox_select": "選択…",
        "Search_here": "ここで検索",
        "Bookingid": "予約番号",
        "Pickup_Drop_Off": "ピックアップ/降車",
        "Pickup_Drop_Off_Crew": "ピックアップ/降車時間",
        "Pickup_Drop_Off_Local_Time": "オペレーター時間",
        "Pickup_Location_Destination": "ピックアップ場所/目的地",
        "Passenger": "乗客",
        "Intercity_Route": "都市間ルート",
        "Airport_Pickup": "空港ピックアップ",
        "Airport_Pickup_Crew": "空港",
        "Flight_Status": "ステータス",
        "Payment": "支払い",
        "Vehicle": "車両",
        "Car_type": "車両タイプ",
        "Driver": "ドライバー",
        "Estimated_Fare": "見積もり運賃",
        "Notes": "メモ",
        "Note": "ノート",
        "Type": "タイプ",
        "Home": "ホーム",
        "Affiliate": "アフィリエイト",
        "now": "今すぐ（輸送）",
        "reservation": "予約（輸送）",
        "recurring": "定期（輸送）",
        "No_show": "現れなかった",
        "Canceled_by_CC": "クレジットカードによるキャンセル",
        "Canceled_by_passenger": "乗客によるキャンセル",
        "Canceled_by_partner": "パートナーによるキャンセル",
        "Canceled_by_mDispatcher": "パートナーによるキャンセル",
        "Canceled_by_Corporate_Admin": "法人管理者によるキャンセル",
        "Canceled_by_API": "APIによるキャンセル",
        "canceled_By_Web_Booking": "ウェブ予約によるキャンセル",
        "canceled_By_Time_Out": "タイムアウトによるキャンセル",
        "Canceled": "キャンセル",
        "Completed": "完了",
        "Incident": "インシデント",
        "Payment_Method": "支払い方法",
        "No_Phone": "電話番号なし",
        "VIP": "VIP",
        "Non_VIP": "非VIP",
        "Passenger_type": "乗客タイプ",
        "Error_load_booking": "予約をロードできません。再度お試しください、またはページを更新してください！",
        "Edit_total_success": "合計が正常に編集されました！",
        "Edit_total_fail": "合計運賃は{0}以上でなければなりません",
        "Time_out": "タイムアウト",
        "Ride_Service": "ライドサービス",
        "Regulation": "規制",
        "Ride_Sharing": "ライドシェアリング",
        "Unmatching": "不一致",
        "Matching": "一致",
        "all": "すべて",
        "matching": "一致",
        "unmatching": "不一致",
        "regulation": "規制",
        "Support_service": "サポートサービス",
        "SOS": "SOS",
        "Non_SOS": "非SOS",
        "Corporate": "法人",
        "Individual": "個人",
        "On_curb": "路上",
        "Meet_Greet": "ミート＆グリート",
        "Close": "閉じる",
        "auto_refrest_cue": "自動更新（{0}秒）",
        "Ride_type": "ライドタイプ",
        "Edit_total_note": "注：小計、技術料、予約料、税金、チップは合計金額を変更すると更新されます",
        "Booking": "予約",
        "Edit_fare": "運賃の編集",
        "Adjust_price": "価格調整",
        "Total": "合計",
        "Reason": "理由",
        "Please_enter_reason": "理由を入力してください。",
        "Save": "保存",
        "Cancel": "キャンセル",
        "dmc": "予約ボード",
        "corp": "予約ボード (CTM)",
        "Zone": "ピックアップゾーン",
        "mDispatcher": "パートナー",
        "streetSharing": "ストリートシェアリング",
        "Driver_No_Job_found": "割り当て待ち…",
        "expandFilter": "フィルターを表示",
        "collapseFilter": "フィルターを非表示",
        "clearSelectedItems": "選択した項目をクリア",
        "Accepted": "受付済み",
        "Yes": "はい",
        "No": "いいえ",
        "Order_accepted": "注文受付済み",
        "Confirmed": "確認済み",
        "spotTime": "スポット時間",
        "ETA": "ETA（到着予定時刻）",
        "spareTime": "余裕時間",
        "expectedTime": "予想ピックアップ時間",
        "Driver_on_the_way_to_sender": "ドライバーが送信者に向かっています",
        "Driver_on_the_way_to_recipient": "ドライバーが受取人に向かっています",
        "Driver_on_the_way_to_collect_cash": "ドライバーが現金を集めに向かっています",
        "Driver_on_the_way_to_collect_goods": "ドライバーが商品を集めに向かっています",
        "Arrived_waiting_for_sender": "到着して送信者を待機中",
        "Arrived_waiting_for_recipient": "到着して受取人を待機中",
        "Arrived_waiting_to_collect_cash": "到着して現金の受け取りを待機中",
        "Arrived_waiting_to_deliver_goods": "到着して商品の配達を待機中",
        "Delivered": "配達中",
        "delivery": "配達",
        "Service": "サービス",
        "intercity": "都市間",
        "Transport": "輸送",
        "About_Sender": "送信者について",
        "About_Merchant": "マーチャントについて",
        "About_Recipient": "受取人について",
        "photos": "写真",
        "listOfItems": "アイテムリスト",
        "cashOnPickUp": "COP（代金引換）",
        "Package_information": "パッケージ情報",
        "otwMerchant": "ドライバーがマーチャントに向かっています",
        "arrivedAndWaitingToCollectItem": "到着してアイテムの受け取りを待機中",
        "Save_Filter": "フィルターを保存",
        "add_favorite_filter": "フィルターを追加",
        "favorite_filter_name": "名前",
        "list_favorite_filter": "フィルターを管理",
        "favorite_filter_actions": "アクション",
        "clear_all_filters": "すべてのフィルターをクリア",
        "Delete_Filter": "フィルターを削除",
        "CONFIRM_DELETE_FILTER_TEMPLATE": "このフィルターを削除してもよろしいですか？",
        "batchDelivery": "一括配達",
        "editFare": {
            "creditTransactionFee": "クレジットカード手数料",
            "preferredAdjustment": "優先調整",
            "editFareFee": "運賃と手数料を編集",
            "markupPrice": "マークアップ価格",
            "quotedPrice": "見積価格",
            "basicFare": "基本運賃",
            "preferredEarning": "優先収益",
            "difference": "差額",
            "rushHourFee": "サーチャージ",
            "serviceFee": "追加サービス",
            "otherFees": "その他の手数料",
            "subTotal": "小計",
            "airportFee": "空港手数料",
            "meetDriverFee": "ドライバーとの待ち合わせ",
            "deliveryFee": "配達手数料",
            "tollFee": "通行料",
            "parkingFee": "駐車料金",
            "gasFee": "ガソリン代",
            "techFee": "技術料",
            "tip": "チップ",
            "bookingFee": "予約手数料",
            "tax": "税金",
            "promoAmount": "プロモーション金額",
            "etaFare": "合計",
            "fleetService": "フリートサービス",
            "Note_serviceFee": "（追加サービスを編集するには、外部リストにアクセスしてください）",
            "startingFee": "開始",
            "moveFeeInJourney": "移動中",
            "waitFeeInJourney": "待機中（旅程中）",
            "total": "合計",
            "totalPayout": "支払い",
            "updatedTotal": "更新された合計",
            "updatedPayout": "更新された支払い",
            "profit": "利益",
            "extraWaitFee": "待機料金初期",
            "heavyTraffic": "渋滞"
        },
        "partialCompleted": "部分完了",
        "failed": "失敗",
        "Recurring": "定期番号",
        "DragAndDrop": "列を並べ替えるためにドラッグ＆ドロップ",
        "bookingCom": "Booking.com",
        "syncingCompleted": "同期完了",
        "groupManifest": "グループマニフェスト",
        "assignSuppliers": "サプライヤーを割り当て",
        "viewActions": "アクションを表示",
        "holidaytaxis": "HolidayTaxis",
        "karhoo": "Karhoo",
        "pickupTime": "ピックアップ時間",
        "AssignSupplier": "サプライヤーを割り当て",
        "sharedTransfer": "共有送迎 (%{id})",
        "batchDeliveryGroup": "一括配達 (%{id})",
        "foundersFund": "ファウンダーズファンド",
        "zeroCommission": "ゼロ手数料",
        "unassignDriver": "ドライバーの割り当て解除",
        "notUnassignDriver": "ドライバーの割り当てを解除できません。再度お試しください",
        "notAssignDriver": "ドライバーを割り当てできません。再度お試しください",
        "selectDriver": "ドライバーを選択",
        "unassignVehicle": "車両の割り当て解除",
        "unassignAll": "すべて解除",
        "notUnassignAll": "すべて解除できません。もう一度お試しください",
        "unassignAllSuccess": "車両とドライバーが正常に切り離されました。",
        "unassign_all_partial": "一部成功：以下のトリップ（予約ID）について、進行中のアクションによりバッチ解除が失敗しました：{0}。",
        "notUnassignVehicle": "車両を解除できません。もう一度お試しください",
        "notAssignVehicle": "車両を割り当てできません。もう一度お試しください",
        "selectVehicle": "車両を選択",
        "confirmUnassign": "本当に解除しますか？"
    },
    "trip": {
        "Driver_vehicle": "ドライバーの車両",
        "tripId": "トリップ番号",
        "tripTypes": "トリップタイプ",
        "Start_Time": "開始時間",
        "Seats_Status": "座席状況",
        "No_Of_Booking": "予約数",
        "Vehicle": "ドライバーの車両",
        "Estimated_Fare": "運賃",
        "capacityStatus": "座席状況",
        "Trip_Types": "トリップタイプ",
        "scheduled": "スケジュールされたトリップ",
        "requested": "リクエストされたトリップ",
        "preferred": "優先",
        "All": "すべて",
        "Confirmed": "確認済み",
        "Started": "開始済み",
        "In_Transit": "移動中",
        "Empty": "空",
        "Partial_not_live": "部分 - 未ライブ",
        "Partial_live": "部分 - ライブ",
        "Full": "満席",
        "Error_load_trip": "トリップをロードできません。再度お試しください、またはページを更新してください！"
    },
    "tripDetails": {
        "Trip_ID": "都市間トリップ",
        "DRIVER_JOB": "ドライバー情報",
        "BOOKINGS": "予約",
        "updateTrip": "トリップを更新",
        "cancelTrip": "トリップをキャンセル",
        "note_cancel": "このトリップをキャンセルする前に、すべての予約を別のドライバーに割り当ててください。",
        "TRIP_INFORMATION": "トリップ情報",
        "route": "ルート",
        "startTime": "開始時間",
        "distance": "距離",
        "duration": "所要時間",
        "fare": "運賃",
        "cartype": "都市間車両タイプ",
        "seatStatus": "座席状況",
        "started": "開始済み",
        "intransit": "移動中",
        "empty": "空",
        "notLive": "部分 - 未ライブ",
        "live": "部分 - ライブ",
        "full": "満席",
        "noOfBooking": "予約数",
        "luggage": "荷物",
        "nameDriver": "名前",
        "vehicleType": "車両",
        "assignDriver": "ドライバーを割り当て",
        "pickUpAddress": "ピックアップ",
        "destinationAddress": "降車",
        "customerName": "名前",
        "customerPhone": "電話番号",
        "customerNumber": "座席",
        "customerLuggageNumber": "荷物",
        "additionalService": "追加サービス",
        "paymentBooking": "支払い",
        "note": "メモ",
        "CONFIRM_UPDATE": "更新を確認",
        "CONFIRM_UPDATE_MESSAGE": "このトリップを更新してもよろしいですか？",
        "Yes": "はい",
        "No": "いいえ",
        "Update_info": "はい、情報を更新"
    },
    "newbooking": {
        "Search_Vehicle": "車両を検索",
        "rideSharing_multi_point": "ライドシェアリング予約では、ピックアップと降車地点はそれぞれ1つだけ許可されます。",
        "Pick_up_time": "ピックアップ時間",
        "Drop_off_time": "降車時間",
        "All_Suppliers": "すべてのサプライヤー",
        "Recipient": "受取人",
        "All": "すべて",
        "New_Booking": "新しい予約",
        "GoogleMapRoute": "Googleマップ",
        "BOOKING_INFORMATION": "予約情報",
        "TIP_PROMO": "チップ - プロモーション",
        "Pick_up": "ピックアップ",
        "instructions": "指示",
        "instructions_placeholder": "ここに指示を記載してください…",
        "Enter_a_location": "場所を入力",
        "Enter_a_location_delivery": "場所を入力",
        "Enter_3rd_party_location": "サードパーティの場所を入力",
        "Enter_a_sender": "送信者を入力",
        "Enter_a_recipient": "受取人を入力",
        "Vehicle_Selection": "車両選択",
        "Destination": "目的地",
        "Pickup_time": "ピックアップ時間",
        "Note": "メモ",
        "note_for_driver": "ドライバー向けメモ",
        "affiliate_note": "アフィリエイト向けメモ",
        "affiliate_note_placeholder": "オペレーター向けアフィリエイトメモを入力",
        "operator_note": "オペレーター向け内部メモを入力",
        "TRAVELER_INFORMATION": "旅行者情報",
        "Traveler_type": "旅行者タイプ",
        "Individual": "個人",
        "Corporation": "法人",
        "Phone_number": "電話番号",
        "First_name": "名",
        "Last_name": "姓",
        "Email_address": "メールアドレス",
        "Corp_id": "法人ID",
        "Manager_name": "マネージャーの名前",
        "Manager_email": "マネージャーのメール",
        "Cost_centre": "コストセンター",
        "Department": "部署",
        "Corp_division": "法人部門",
        "Tip": "チップ",
        "Promo_code": "プロモーションコード",
        "Apply": "適用",
        "Promo": "プロモーション：",
        "DISPATCH": "ディスパッチ",
        "Auto_dispatch": "自動ディスパッチ",
        "Assign_driver": "ドライバーを割り当て",
        "FarmOut": "ファームアウト",
        "Driver": "ドライバー",
        "Driver_Supplier": "サプライヤー/ドライバー",
        "PAYMENT_METHOD": "支払い方法",
        "OPERATOR_NOTE": "内部メモ",
        "TRIP_ESTIMATE": "トリップ見積もり",
        "Choose_a_stored_card": "保存されたカードを選択",
        "Choose_a_payment_method": "支払い方法を選択",
        "Use_new_card": "新しいカードを使用",
        "Card_holder": "カード保有者",
        "Card_number": "カード番号",
        "Expired_date": "有効期限（mm/yy）",
        "ccv": "CVV",
        "Distance": "距離",
        "Duration": "所要時間",
        "Fare": "運賃",
        "Route": "ルート",
        "Create": "作成",
        "Cancel": "キャンセル",
        "Meet_driver": "ドライバーとの待ち合わせ",
        "Airline": "航空会社",
        "Flight": "フライト番号",
        "FLY_INFO": "フライト情報",
        "Ride_Sharing": "ライドシェアリング",
        "Round_trip": "往復",
        "Car_type": "車両タイプ",
        "Address": "住所",
        "City": "都市",
        "State": "州",
        "Zip_code": "郵便番号",
        "Remove": "削除",
        "Client_case_matter": "クライアントケース事項 / トリップ説明",
        "Charge_code": "チャージコード / トリップコード",
        "CANCEL_BOOKING": "予約確認を閉じる",
        "CANCEL_BOOKING_CONFIRM": "このフォームを離れますか？すべての予約データが失われます。",
        "Search_here": "ここで検索",
        "No_support_location": "残念ながら、このエリアではサービスが利用できません",
        "Closest_driver_message": "最寄りのドライバーはおよそ{0}離れています",
        "No_cars_available": "ディスパッチ半径内に利用可能な車両がありません。",
        "processed_by_affiliate": "あなたのリクエストはアフィリエイトパートナーによって処理されます",
        "FlightExample": "フライト番号、例：AA1097",
        "Yes": "はい",
        "No": "いいえ",
        "dispatch_3rd": "サードパーティ経由でディスパッチ",
        "Company": "会社",
        "Card_required": "カードを選択または追加してください！",
        "Tip_value": "該当なし",
        "Calendar": "カレンダー",
        "Extra_Destination": "追加の目的地",
        "Driver_credit_limited_balance_warning": "このドライバーの残高が制限金額以下です",
        "Meet_Greet_option": "ミート＆グリート",
        "On_Curb_option": "路上",
        "Extra_Services": "追加サービス",
        "Addl_Services": "追加サービス",
        "Save": "保存",
        "Regular": "レギュラー",
        "Please_fill_in_all_the_required_fields": "すべての必須フィールドを入力してください。",
        "Route_names_not_dup": "Route names must not be duplicated.",
        "Please_select_weekdays": "この予約が発生する繰り返し日を選択してください",
        "Flat_rate": "フラットレート",
        "All_company": "すべての会社",
        "Best_price": "最安値",
        "PromoTitle": "プロモーション",
        "Show_more": "さらに表示",
        "Warning_pending_payment": "この顧客は過去の予約で未払いがあります：%{fare}",
        "Warning_outstanding_balance": "未払い残高はトリップ終了時に適用されます。",
        "Not_available": "利用不可",
        "Warning_assigned_rate": "選択した車両タイプに料金を設定して割り当ててください。",
        "PaxLuggageNumber": "乗客/荷物の数",
        "paxNumber": "乗客",
        "luggageNumber": "荷物",
        "Date": "日付",
        "Single": "単発",
        "Recurring": "定期",
        "Date_range": "カスタマイズされた日",
        "WeekDays": "繰り返し日",
        "Create_Route": "ルートを作成",
        "Pickup_Point": "ピックアップ",
        "Drag_To_Render": "ドラッグ＆ドロップで並べ替え",
        "DropOff_Point": "降車地点",
        "Sender": "送信者",
        "Recipients": "受取人",
        "Sender_Infomation": "送信者情報",
        "Recipient_Infomation": "受取人情報",
        "Recipient_Name": "受取人名 *",
        "Recipient_Phone": "電話番号 *",
        "Room_floor_block": "部屋、階、ブロック（任意）",
        "Details": "詳細",
        "Package_Content": "パッケージ内容 *",
        "Order_ID": "注文番号（例：Shopify注文番号）",
        "Instructions_for_driver": "ドライバー向け指示",
        "Cost_of_Goods": "代金引換（COD）",
        "Enter_amount": "金額を入力",
        "Additional_Information": "追加情報",
        "Insurance": "保険",
        "Special_Care": "特別ケア",
        "Delivery_Fee": "配達手数料",
        "Item_fees": "アイテム手数料",
        "Tech_Fee": "技術手数料",
        "Tax": "税金",
        "driverNotes": "ドライバーメモ",
        "rejectModal": "予約を拒否",
        "rejectModalBody": "この予約を拒否してもよろしいですか？",
        "rejectButton": "予約を拒否",
        "acceptModal": "予約を受付",
        "acceptModalBody": "この予約を受付してもよろしいですか？",
        "ConfirmedTo": "({0}) に確認済み",
        "proceedAction": "このアクションを進めてもよろしいですか？",
        "acceptButton": "予約を受付",
        "denyModal": "予約を拒否",
        "denyModalBody": "この予約を拒否してもよろしいですか？",
        "denyButton": "予約を拒否",
        "Accept_booking_success": "この予約は正常に受付されました！",
        "Deny_booking_success": "この予約は正常に拒否されました！",
        "adjustFare": "運賃を調整",
        "totalFare": "合計運賃",
        "Profit": "利益",
        "DriverEarning": "ドライバーの収益",
        "Payout": "支払い",
        "totalPayout": "総支払額",
        "DriverPayOut": "ドライバーの支払い",
        "confirmReject": "拒否を確認",
        "confirmCancelMSG": "この予約を拒否してもよろしいですか？プロバイダーからペナルティが発生する場合があります。",
        "reasonCancel3rd": "拒否の理由",
        "welcome_psg": "乗客を歓迎",
        "AmountDue": "支払い金額",
        "Fleet_Company": "フリート/会社",
        "Search_driver_hydra": "近日中にドライバーを見つけようとします。",
        "ExternalID": "外部ID",
        "viewRate": "料金を表示",
        "PointToPoint": "ポイント・トゥ・ポイント",
        "Offer_driver": "ドライバーにオファー",
        "Assign_driver_vehicle": "ドライバー/車両を割り当て",
        "notify_via_email": "メールで顧客に通知",
        "fleetFare": "運賃",
        "onlySave": "保存",
        "saveCharge": "保存 & 請求",
        "saveRefund": "保存 & 返金",
        "lateCancelConfirm": "この予約を完了してもよろしいですか？",
        "canceledDriverConfirm": "この予約をドライバーのためにキャンセルしてもよろしいですか？違約金が発生します。",
        "canceledDriverNoChargeConfirm": "この予約をドライバーのためにキャンセルしてもよろしいですか？",
        "NoShowConfirm": "ノーショーポリシーが適用されます。",
        "completeConfirm": "ドライバーなしで完了してもよろしいですか？",
        "completeChargeConfirm": "全額が顧客に請求されます。ドライバーはこの予約から手数料を受け取りません。",
        "PricingPlan": "料金プラン",
        "editFerviceFees": "サービス料を編集するには、外部リストをご覧ください。",
        "ungroupAll": "すべてのグループを解除",
        "satisfaction": "満足",
        "Reassign": "再割り当て",
        "addNote": "メモを追加",
        "noteMagicSend": "件名",
        "items": "アイテム",
        "PaidAmount": "支払額",
        "ExternalPartner": "外部パートナー",
        "ExternalPartnerNote": "独立したパートナーが自社のドライバーと車両を管理します。支払いはサプライヤーと直接処理されます。",
        "InHousePartner": "社内パートナー",
        "InHousePartnerNote": "自社のフリートによって直接管理されます。支払いはドライバーに直接行われます。",
        "Role": "役割",
        "Client": "クライアント",
        "ApplyCancellation": "キャンセルポリシーを適用する",
        "unassigned": "未割り当て",
        "bookingSelected": "予約が選択されました",
        "Update": "更新",
        "emailInvoice": "メール請求書",
        "Receipt": "レシート",
        "addItem": "アイテムを追加"
    },
    "bookingDetailLogs": {
        "recipient": "受信者",
        "updatedInfo": "更新された情報",
        "updatedField": "更新されたフィールド",
        "valueBefore": "変更前の値",
        "valueAfter": "変更後の値",
        "location": "場所",
        "etaFare": "運賃",
        "dispatchType": "ディスパッチ",
        "drvInfo": "ドライバー",
        "vehicleTypeRequest": "車両リクエスト",
        "clientCaseMatter": "トリップの説明",
        "chargeCode": "トリップコード",
        "driverEarningType": "ドライバーの収益タイプ",
        "editedDriverEarning": "ドライバーの収益",
        "reasonEditFare": "理由",
        "bookingReference": "外部ID",
        "tip": "チップ",
        "type": "サービス種別",
        "promo": "プロモーション",
        "dispatch3rd": "サードパーティディスパッチ",
        "rideSharing": "ライドシェアリング",
        "notes": "ドライバーノート",
        "operatorNote": "内部ノート",
        "welcomeMessage": "ウェルカムメッセージ",
        "flightNumber": "フライト番号",
        "fullName": "フルネーム",
        "email": "メール",
        "cardMask": "カードマスク",
        "paymentType": "支払い方法",
        "recipients": "受信者",
        "sender": "送信者",
        "pickupName": "ピックアップ名",
        "pickupPhone": "ピックアップ電話番号",
        "pickupEmail": "ピックアップメール",
        "supplierEarningType": "支払いタイプ",
        "editedSupplierEarning": "支払い値",
        "route": "ルート名",
        "markupDifference": "マークアップ差額",
        "selectedDates": "選択された日付",
        "services": "フリートサービス",
        "destinationAddress": "目的地の場所",
        "pickupAddress": "ピックアップ場所",
        "tripType": "トリップタイプ",
        "pickUpTime": "ピックアップ時間",
        "paxNumber": "乗客",
        "luggageNumber": "荷物",
        "vehicle": "車両",
        "minutesToArrive": "ドライバーの到着予定時刻",
        "affiliateNote": "アフィリエイトノート",
        "packageRateName": "パッケージ名",
        "supplierCompanies": "会社",
        "puPoints": "ピックアップ場所",
        "doPoints": "目的地の場所",
        "promoValue": "プロモーション値",
        "packageInfo": "パッケージ情報",
        "orderId": "注文ID",
        "cashOnDelivery": "代金引換",
        "externalId": "外部ID",
        "recurring": "定期",
        "batchDelivery": "バッチ配送",
        "payout": "支払い",
        "locationDetails": "場所の詳細",
        "name": "名前",
        "phone": "電話番号",
        "address": "住所",
        "basicFare": "基本運賃",
        "airportFee": "空港料金",
        "bookingFee": "予約手数料",
        "creditTransactionFee": "クレジットカード手数料",
        "fleetService": "フリートサービス",
        "fleetServices": "フリートサービス",
        "gasFee": "ガソリン代",
        "meetDriverFee": "ドライバーとの待ち合わせ料金",
        "moveFeeInJourney": "移動料金",
        "otherFees": "その他の料金",
        "parkingFee": "駐車料金",
        "promoAmount": "プロモーション金額",
        "rushHourFee": "ラッシュアワー料金",
        "serviceFee": "サービス料金",
        "startingFee": "開始料金",
        "subTotal": "小計",
        "tax": "税金",
        "techFee": "技術料金",
        "tollFee": "通行料",
        "waitFeeInJourney": "待機（旅程中）料金",
        "psgPhone": "Passenger phone"
    },
    "bookingdetail": {
        "settings": "設定",
        "updateSuccessJobVisibilitySingle": "公開設定の更新に成功しました",
        "updateSuccessJobVisibilityMulti": "%{number} 件の予約が正常に更新されました",
        "failApi": "何か問題が発生しました！",
        "settingsTooltip": "ドライバーがすでに割り当てられているか、トリップが現在進行中です。",
        "shareJobPublicly": "ジョブを公開する",
        "groupOder": "グループ注文",
        "ExistingGroup": "既存のグループ",
        "NewGroup": "新しいグループ",
        "Search_trip": "トリップ番号を検索",
        "All": "すべて",
        "creditTransactionFee": "クレジットカード手数料",
        "partial_payment": "部分支払い",
        "Write_off_debt": "債務の帳消し",
        "Booking_detail": "予約",
        "Order_detail": "注文",
        "Completed_Booking": "完了した予約",
        "Late_Cancel": "遅延キャンセル",
        "Cancel_Driver": "ドライバーによるキャンセル",
        "No_Show": "現れなかった",
        "Without_driver": "ドライバーなしで完了",
        "Complete_driver": "ドライバーと一緒に完了",
        "BOOKING_INFORMATION": "予約情報",
        "TIP_PROMO": "チップ - プロモーション",
        "Rearrange_Column": "列を並べ替え",
        "Pick_up": "ピックアップ",
        "Enter_a_location": "場所を入力",
        "Destination": "目的地",
        "Pickup_time": "ピックアップ時間",
        "Notes": "メモ",
        "TRAVELER_INFORMATION": "旅行者情報",
        "Traveler_type": "旅行者タイプ",
        "Individual": "個人",
        "Corporation": "法人",
        "Phone_number": "電話番号",
        "First_name": "名",
        "Last_name": "姓",
        "Email_address": "メールアドレス",
        "Corp_id": "法人ID",
        "Manager_name": "マネージャーの名前",
        "Manager_email": "マネージャーのメール",
        "Cost_centre": "コストセンター",
        "Department": "部署",
        "Corp_division": "法人部門",
        "Tip": "チップ",
        "Promo_code": "プロモーションコード",
        "Apply": "適用",
        "Promo": "プロモーション",
        "DISPATCH": "ディスパッチ",
        "Auto_dispatch": "自動ディスパッチ",
        "Assign_driver": "ドライバーを割り当て",
        "Driver": "ドライバー",
        "PAYMENT_METHOD": "支払い方法",
        "Payment_methods": "支払い方法",
        "pending_payment": "保留中の支払い",
        "TRIP_ESTIMATE": "トリップ見積もり",
        "Choose_a_stored_card": "保存されたカードを選択",
        "Choose_a_payment_method": "支払い方法を選択",
        "OPERATOR_NOTE": "内部メモ",
        "Use_new_card": "新しいカードを使用",
        "Card_holder": "カード保有者",
        "Card_number": "カード番号",
        "Expired_date": "有効期限（mm/yy）",
        "ccv": "CVV",
        "Distance": "距離",
        "Duration": "所要時間",
        "Fare": "運賃",
        "Route": "ルート",
        "Meet_driver": "ドライバーとの待ち合わせ",
        "Airline": "航空会社",
        "Flight": "フライト番号",
        "FLY_INFO": "フライト情報",
        "Ride_Sharing": "ライドシェアリング",
        "Round_trip": "往復",
        "Car_type": "車両タイプ",
        "Address": "住所",
        "City": "都市",
        "State": "州",
        "Zip_code": "郵便番号",
        "Remove": "削除",
        "New_Booking": "新しい予約",
        "Clone_Booking": "予約を複製",
        "New_Order": "新しい注文",
        "ViewTrackLink": "トラックリンクを表示",
        "driverLink": "ドライバーリンク",
        "ClickCopy": "リンクをコピーするにはクリック",
        "ViewLog": "ディスパッチログを表示",
        "Message": "メッセージ",
        "Update_Booking": "予約を更新",
        "Cancel_Booking": "予約をキャンセル",
        "Complete_with_payment": "支払いと共に完了",
        "Incident": "インシデント",
        "CONFIRM_CANCEL": "キャンセルを確認",
        "CONFIRM_CANCEL_MESSAGE": "この予約をキャンセルしてもよろしいですか？",
        "CONFIRM_UPDATE": "更新を確認",
        "CONFIRM_UPDATE_MESSAGE": "この予約を更新してもよろしいですか？",
        "CONFIRM_RESET_EDIT_FARE_MESSAGE": "編集した運賃がリセットされました。続ける前に確認してください。",
        "Close": "閉じる",
        "Update_info": "はい、情報を更新",
        "Update_info_dispatch": "はい、情報更新＆ディスパッチ",
        "Update_info_tooltip": "この予約を新しいドライバーに再割り当てする場合は、「はい、情報更新＆ディスパッチ」ボタンを選択してください。",
        "BOOKING": "予約",
        "DROPPED_OFF": "降車済み",
        "Payment_Type": "支払いタイプ",
        "Total": "合計",
        "Next": "次へ",
        "Pay_here": "ここで支払う",
        "Complete_booking": "予約を完了",
        "Complete_booking_tooltip": "変更を保存するには「予約を更新」をクリックしてください。",
        "Cancel": "キャンセル",
        "Back": "戻る",
        "Tax": "税金",
        "Booking_fees": "予約手数料",
        "Tech_fees": "技術手数料",
        "Subtotal": "小計",
        "Other_fees": "その他の手数料",
        "Waiting_fee": "待機手数料",
        "Toll_fees": "通行料",
        "Heavy_traffic": "渋滞",
        "Rush_hour": "サーチャージ",
        "Basic_fare": "基本運賃",
        "Ok": "OK",
        "Input_reason": "理由を入力",
        "Client_case_matter": "クライアントケース事項 / トリップ説明",
        "Charge_code": "チャージコード / トリップコード",
        "Enter_3rd_party_location": "サードパーティの場所を入力",
        "Company": "会社",
        "Check_promo_error": "プロモーションコードのチェックエラー！",
        "Add_new_credit_error": "新しいクレジットの追加エラー！",
        "Can_not_get_fare_setting": "運賃設定を取得できません！",
        "Can_not_get_affilate_fare_setting": "アフィリエイト運賃設定を取得できません！",
        "Can_not_check_commisson": "手数料を確認できません！",
        "No_flight_info": "フライト情報がありません！",
        "Socket_server_disconnected": "ソケットサーバーが切断されました！",
        "Please_reload_page_or_check_your_connection": "申し訳ありません。リクエストを処理できません。再度お試しください。",
        "View_Logs": "ログを表示",
        "Bookingid": "予約番号",
        "Calendar": "カレンダー",
        "Extra_Destination": "追加の目的地",
        "Meet_Greet_option": "ミート＆グリート",
        "On_Curb_option": "路上",
        "Credit_Card": "クレジットカード",
        "Charge": "請求",
        "No_charge": "請求なし",
        "QR_Code_method_not_allow_error_message": "ダッシュボードで支払うためにQRコードが利用できないため、別の支払いタイプを選択してください！",
        "change_payment_method_note": "支払い方法を変更したい場合は、予約フォームに戻ってください。",
        "Airport_Fee": "空港手数料",
        "Addi_service": "追加サービス",
        "Additional_Services": "追加サービス",
        "Minimum": "最小",
        "Search_here": "ここで検索",
        "No_Driver_Yet": "まだドライバーが割り当てられていません",
        "Personal_Card": "個人カード",
        "Reject_booking": "予約を拒否",
        "Confirm_reject": "拒否を確認",
        "Confirm_reject_message": "この予約を拒否してもよろしいですか？",
        "Confirm_reject_booking_with_charge": "キャンセルポリシーに基づき、見積もり運賃の{0}%が請求されます。この予約を拒否してもよろしいですか？",
        "Yes": "はい",
        "No": "いいえ",
        "shortTrip": "短距離トリップ",
        "Add_new_card": "新しいカードを追加",
        "Require_customer_info": "続行するには顧客情報を入力してください。",
        "confirm_force_overlap": "確認",
        "confirm_force_overlap_message": "この予約はドライバーが受け入れた予約と重複しています。このドライバーに割り当ててもよろしいですか？",
        "select": "選択",
        "PICKUP_TIME_RANGE": "{0}での予想ピックアップ",
        "food": "食品",
        "mart": "マート",
        "Order_summary": "注文概要",
        "addOnPrice": "調整価格",
        "applicableFee": "合計（適用可能）",
        "itemValue": "アイテム価値",
        "promotion_note_paymentMethod": "この注文は制限された支払い方法のプロモーションコードが適用されています：%{method}。",
        "promotion_note_minimum_fare": "この注文は制限された最低運賃のプロモーションコードが適用されています：%{fare}。",
        "promotion_note_schedules": "この注文は制限されたピックアップ時間のプロモーションコードが適用されています。",
        "edited_fare_note": "これは参照用の運賃調整ログです。最終運賃と手数料を確認するにはレポートをご覧ください。",
        "view_good_receipt": "商品受領書を表示",
        "confirmRemoveGroupTransport": "現在の予約をこのマニフェストから削除してもよろしいですか？",
        "confirmRemoveGroupDelivery": "現在の予約をこのグループから削除してもよろしいですか？",
        "confirmUngroupTransport": "このマニフェストからすべての予約をグループ解除してもよろしいですか？",
        "confirmUngroupDelivery": "このグループからすべての予約をグループ解除してもよろしいですか？",
        "view_delivery_receipt": "配達写真を表示",
        "select_time": "時間を選択",
        "InputReason": "理由を入力",
        "viewOrder": "注文を表示",
        "markFailed": "失敗としてマーク",
        "corporateName": "法人名",
        "sendMessage": "メッセージを送信",
        "AmountDueBefore": "調整前の支払い金額",
        "AmountDueAfter": "調整後の支払い金額",
        "chargeCustomer": "顧客に請求",
        "pending": "未払い",
        "refundPayment": "支払いの返金",
        "refundableAmount": "返金可能な金額",
        "refundReason": "返金理由を追加",
        "refundBtn": "返金 {0}",
        "partial": "部分支払い",
        "paid": "支払済み",
        "ViewPaymentLogs": "支払いログを表示",
        "pmActivity": "支払いアクティビティ",
        "paidStatus": "支払い状況",
        "paymentLog": "支払いログ",
        "extraWaitFee": "待機手数料（初期）",
        "extraWaitTime": "待機時間（初期）",
        "copied": "コピーしました",
        "warnningCancelCorporate": "なお、キャンセルポリシーに従い、金額の料金が発生する場合があります。",
        "customerAdjustments": "顧客の調整",
        "customerAdjustmentsTooltip": "顧客の調整",
        "supplierDriverAdjustments": "サプライヤー/ドライバーの調整",
        "updateSummary": "更新サマリー",
        "orderDetails": "注文詳細",
        "pastDetacted": "過去の予約が検出されました",
        "pastDetactedContent": "過去の予約を作成しようとしています"
    },
    "map": {
        "Enter_a_location": "場所を入力",
        "All_company": "すべての会社",
        "All_cars": "すべての車両",
        "Show_all_cars": "すべての車両を表示",
        "Hide_all_cars": "すべての車両を非表示",
        "Vacant": "利用可能",
        "Dispatching": "ディスパッチ中",
        "Driver_on_the_way": "ドライバーが向かっています",
        "Arrived_and_waiting": "到着して待機中",
        "P_O_B": "乗車中",
        "Dropped_off": "降車済み",
        "Waiting_for_process": "処理待ち",
        "Unavailable": "利用不可",
        "Total_Drivers": "ドライバー総数",
        "Estimate": "見積もり",
        "Destination": "目的地",
        "Pickup": "ピックアップ",
        "Start_point": "出発点",
        "Passenger": "乗客",
        "Driver_name": "ドライバー名",
        "Search_car_here": "車両/ドライバー名を検索",
        "Error_load_booking": "予約をロードできません。再度お試しください、またはページを更新してください！",
        "Show_all": "すべて表示",
        "driversLiveMap": "ドライバーマップ",
        "heatMap": "需要マップ",
        "Trip": "トリップ",
        "traffic": "交通",
        "bicyclingLayers": "自転車",
        "layer": "レイヤー",
        "trafficBicycling": "交通、自転車",
        "All_airline": "すべての航空会社"
    },
    "customer": {
        "Customers": "顧客",
        "Points": "ポイント",
        "Migration_Status": "移行ステータス",
        "ADD_CUSTOMER": "顧客を追加",
        "EDIT_CUSTOMER": "顧客を編集",
        "Add": "追加",
        "Activate": "アクティブ化",
        "Deactivate": "非アクティブ化",
        "Delete": "削除",
        "Import": "インポート",
        "Download_template": "テンプレートをダウンロード",
        "View_Message": "メッセージ",
        "Send_Message": "メッセージを送信",
        "Export_to_excel": "Excelにエクスポート",
        "Filter": "フィルター",
        "Apply": "適用",
        "Clear": "クリア",
        "Name": "名前",
        "Phone_Number": "電話番号",
        "Email": "メール",
        "Vip": "VIP",
        "companies": "会社",
        "File": "ファイル",
        "Number_successful_imports": "成功したインポートの数：",
        "Number_skipped": "スキップされた数：",
        "Number_duplicate_phone_numbers": "重複した電話番号の数：",
        "Number_wrong_phone_numbers": "誤った電話番号の数：",
        "Corporate_Name": "法人",
        "Registered_From": "登録元",
        "Registration_Date": "登録日",
        "Registration_date_from": "登録日（開始）",
        "Registration_date_to": "登録日（終了）",
        "IOS_Last_Login_Version": "iOS最終ログインのバージョン",
        "Android_Last_Login_Version": "Android最終ログインのバージョン",
        "Last_Active_Date": "最終アクティブ日",
        "Verification": "検証",
        "Support_ID": "サポートID",
        "Days_Since_Last_Active": "最終アクティブからの日数",
        "Completed_Bookings": "完了した予約",
        "Outstanding_amount": "未払い金額",
        "Status": "ステータス",
        "Actions": "アクション",
        "Action": "アクション",
        "Inactive": "非アクティブ",
        "Active": "アクティブ",
        "Edit": "編集",
        "View": "表示",
        "ALERT_SELECT_ACTIVATE": "アクティブにする顧客を選択してください。",
        "ALERT_SELECT_DEACTIVATE": "非アクティブにする顧客を選択してください。",
        "ALERT_SELECT_DELETE": "削除する顧客を選択してください。",
        "CONFIRM_DELETE": "このアカウントを削除してもよろしいですか？アカウント削除後、顧客は空のデータで新しいプロフィールを登録できます。",
        "CONFIRM_DELETE_MULTI": "これらの顧客を削除してもよろしいですか？非アクティブでアプリ経由で登録されていない顧客のみ削除できます。",
        "CONFIRM_DEACTIVATE_MULTI": "これらの顧客を非アクティブにしますか？",
        "CONFIRM_ACTIVATE_MULTI": "これらの顧客をアクティブにしますか？",
        "CONFIRM_EXPORT_TO_EXCEL": "すべてのデータを.xlsxファイルとしてエクスポートしてもよろしいですか？",
        "Add_card_top_up_success": "プリペイドカードが正常にチャージされました",
        "Add_card_success": "カードが正常に追加されました！",
        "Add_customer_success": "新しい顧客が正常に作成されました",
        "Update_customer_success": "顧客が正常に更新されました",
        "Deleted_customer_success": "顧客が正常に削除されました",
        "Create_customer_fail": "顧客の作成に失敗しました",
        "Update_customer_fail": "顧客の更新に失敗しました",
        "ERROR_EXISTED_PHONE": "この電話番号はシステムに既に存在します。別のものを入力してください",
        "ERROR_INPUT_FIRSTNAME": "名を入力してください。",
        "ERROR_INPUT_PHONE": "電話番号を入力してください。",
        "ERROR_INPUT_VALIDPHONE": "有効な電話番号を入力してください。",
        "ERROR_INPUT_VALIDEMAIL": "有効なメールアドレスを入力してください。",
        "CONFIRM_DELETE_CARD": "これらのカードを削除してもよろしいですか？",
        "First_name": "名",
        "Last_name": "姓",
        "Phone_number": "電話番号",
        "Email_address": "メールアドレス",
        "Notes": "メモ",
        "Account": "アカウント",
        "Card_management": "カード管理",
        "Home": "ホーム",
        "Affiliate": "アフィリエイト",
        "SHOW_PERSONAL_CARD": "個人カードを表示",
        "ADD_PERSONAL_CARD": "個人カードを追加",
        "SHOW_mDISPATCHER_CARD": "パートナーカードを表示",
        "ADD_mDISPATCHER_CARD": "パートナーカードを追加",
        "Personal_card": "個人カード",
        "mDispatcher_card": "パートナーカード",
        "Card_holder": "カード保有者",
        "Card_number": "カード番号",
        "Credit_type": "クレジットタイプ",
        "Credit_code": "クレジットコード",
        "Sms_verification": "SMS検証",
        "Sms_verification_placeholder": "検証コードを入力",
        "Expired_date": "有効期限",
        "CVV": "CVV",
        "Address": "住所",
        "Country": "国",
        "City": "都市",
        "State": "州",
        "Zip_code": "郵便番号",
        "postal_code": "郵便番号",
        "Required_fields": "必須フィールド",
        "Cancel": "キャンセル",
        "Save": "保存",
        "ERROR_INPUT_CARD_HOLDER": "カード保有者を入力してください",
        "ERROR_INPUT_CARD_NUMBER": "カード番号を入力してください",
        "ERROR_INPUT_EXPIRED_DATE": "有効期限を入力してください",
        "ERROR_VAILD_EXPIRED_DATE": "有効期限が無効です",
        "ERROR_INPUT_CVV": "CVVを入力してください",
        "ERROR_INPUT_ADDRESS": "住所を入力してください",
        "ERROR_INPUT_CITY": "都市を入力してください",
        "ERROR_INPUT_ZIPCODE": "郵便番号を入力してください",
        "ERROR_INPUT_COUNTRY": "国を選択してください",
        "Info_edit_error": "エラー！",
        "Gender": "性別",
        "Select_Gender": "選択",
        "Select_Idtype": "IDタイプを選択",
        "Male": "男性",
        "Female": "女性",
        "Other": "その他",
        "Date_of_birth": "生年月日",
        "Street_address": "住所",
        "Select_Zone": "ゾーンを選択",
        "ERROR_GET_GATEWAYS": "エラー!!! ゲートウェイの取得",
        "Require_email": "新しいカードを追加する前にメールを入力してください",
        "Require_lastName": "新しいカードを追加する前に姓を入力してください",
        "Require_phone_number": "クレジットカードで予約する前に旅行者の電話番号を入力してください",
        "IMPORT_CUSTOMER_SUCCESS": "顧客のインポートに成功しました",
        "No_card": "カードが見つかりません",
        "No_Support": "このエリアでは現在新しいカードの追加はサポートされていません",
        "Card_Management": "カード管理",
        "Emergency_contacts": "緊急連絡先",
        "DETAIL_CUSTOMER": "顧客詳細",
        "Select_Country": "国を選択",
        "Customer_ID": "ID番号 / 国民IC番号",
        "Credit_balance": "クレジット残高",
        "required_email_payment": "顧客のメールが必要です。続行するにはプロフィールを更新してください。",
        "Yes": "はい",
        "No": "いいえ",
        "WriteOffDebt": "債務の帳消し",
        "selectPayment": "未払いの予約をすべて終了するには支払い方法を選択してください",
        "writeOffDebtSuccess": "債務の帳消しに成功しました",
        "writeOffDebtNotes": "注：この帳消し操作は未払い金額が全額支払われた後にのみ確認してください。",
        "totalCustomer": "顧客総数",
        "Personal_Card": "個人カード",
        "INFO_ADD_BALANCE_SUCCESS": "乗客の残高が正常に更新されました",
        "INFO_UPDATE_POINT_SUCCESS": "乗客のポイントが正常に更新されました",
        "currency": "通貨",
        "balance": "残高",
        "changeBalance": "残高を変更",
        "change": "変更",
        "WALLET_DEPOSIT": "ウォレット",
        "AccessDenied": "このモジュールにアクセスする権限がありません！",
        "referral_code": "紹介コード",
        "loginMethod": "ログイン方法"
    },
    "importBookings": {
        "no": "番号",
        "status": "ステータス",
        "pickupDate": "ピックアップ日",
        "pickup": "ピックアップ住所",
        "dropoff": "降車住所",
        "passenger": "乗客",
        "phone": "電話番号",
        "seats": "座席",
        "luggages": "荷物",
        "rideSharing": "ライドシェア",
        "flightNumber": "フライト番号",
        "notes": "メモ",
        "externalId": "外部ID",
        "operatorNote": "内部メモ",
        "clientCaseMatter": "トリップ説明",
        "chargeCode": "トリップコード",
        "price": "価格",
        "payout": "支払い",
        "paymentType": "支払い",
        "vehicleTypeRequest": "車両タイプ",
        "importBookings": "予約のインポート",
        "corporation": "法人",
        "clickOrDropCsvFile": ".csvファイルをクリックまたはドロップ",
        "importingFileForReview": "ファイルを確認のためにインポート中…",
        "import": "インポート",
        "submit": "送信",
        "downloadTemplate": "テンプレートをダウンロード",
        "learnMore": "詳細を見る",
        "total": "合計",
        "valid": "有効",
        "invalid": "無効",
        "failed": "失敗",
        "processing": "処理中",
        "success": "成功",
        "confirm": "確認",
        "confirmMessage": "注文が作成されます。続行してもよろしいですか？",
        "create": "作成",
        "cancel": "キャンセル",
        "close": "閉じる",
        "leavingConfirmMessage": "作業が保存されていません！本当に離れますか？",
        "resultMessage": "${success}件の予約が正常に作成され、${failed}件が失敗しました。",
        "limitExceededBookings": "最大インポートファイルサイズ：100予約",
        "delivery": {
            "no": "番号",
            "pickupDate": "ピックアップ日",
            "senderName": "送信者名",
            "senderPhone": "送信者電話番号",
            "senderMail": "送信者メール",
            "senderAddress": "送信者住所",
            "recipients": "受取人",
            "senderDistrictOptional": "地区",
            "senderLocationDetails": "場所詳細",
            "recipientName": "受取人名",
            "recipientPhone": "受取人電話番号",
            "recipientAddress": "受取人住所",
            "recipientDistrictOptional": "地区",
            "recipientLocationDetailsOptional": "場所詳細",
            "packageContent": "パッケージ内容",
            "orderNumber": "注文番号",
            "instructions": "ドライバー向け指示",
            "cod": "代引きアイテム価値",
            "rideShare": "ライドシェア",
            "paymentType": "支払いタイプ",
            "carType": "車両タイプ",
            "recipientLocationDetails": "場所詳細",
            "operatorNote": "内部メモ",
            "rideSharing": "一括配達"
        }
    },
    "merchant": {
        "INFO_ADD_BALANCE_SUCCESS": "マーチャント残高が正常に更新されました",
        "ADD_MERCHANT_USER": "マーチャントアカウントを追加",
        "EDIT_MERCHANT_USER": "マーチャントアカウントを編集",
        "MERCHANT_USER_DETAILS": "マーチャントアカウント詳細",
        "CASH_WALLET": "現金ウォレット",
        "CREDIT_WALLET": "クレジットウォレット",
        "ADD_MERCHANT": "マーチャントを追加",
        "ACCOUNT_INFO": "アカウント情報",
        "BILLING_ADDRESS": "銀行情報",
        "user": {
            "fullName": "マーチャントユーザー",
            "Phone_Number": "電話番号",
            "Email": "メール",
            "storeName": "マーチャント",
            "status": "ステータス",
            "actions": "アクション",
            "First_name": "名",
            "Last_name": "姓",
            "Select_store": "マーチャントを選択",
            "accountHolder": "口座名義人名",
            "First_M_Last": "名 M. 姓",
            "accountNumber": "口座番号",
            "ibanNumber": "IBAN番号",
            "bankName": "銀行名",
            "IDtype": "IDタイプ",
            "IDnumber": "ID番号 / 国民IC番号",
            "routingNumber": "ルーティング/トランジット/支店番号",
            "checkNumber": "小切手番号",
            "ssn": "SSN/NI",
            "sortCode": "ソートコード",
            "transitNumber": "トランジット番号",
            "institutionNumber": "金融機関番号",
            "birthDay": "生年月日",
            "address": "住所",
            "city": "市",
            "state": "都道府県",
            "postalCode": "郵便番号",
            "beneficiaryIDIC": "受取人ID# / IC#",
            "relationshipOtherName": "続柄",
            "verificationDocumentFront": "確認書類（表面）",
            "verificationDocumentBack": "確認書類（裏面）",
            "Verification_document_tooltip": "本人確認に使用できる書類:\n  - パスポート\n  - 運転免許証\n  - 在留許可証\n  - 市民カード\n  - IDカード",
            "additionalDocumentFront": "追加書類（表面）",
            "additionalDocumentBack": "追加書類（裏面）",
            "Additional_document_tooltip": "住所確認に使用できる書類:\n  - 運転免許証\n  - 公共料金の請求書\n  - 政府発行の書類\n  - 金融機関の明細書\n  - 選挙人名簿の記載",
            "please_select": "選択してください",
            "commission": "手数料",
            "ERROR_INPUT_VALIDPHONE": "有効な電話番号を入力してください。",
            "ERROR_EXISTED_PHONE": "この電話番号はすでにシステムに存在します。別の番号を入力してください。",
            "Activate": "有効化",
            "Deactivate": "無効化",
            "ALERT_SELECT_ACTIVATE": "有効化するユーザーを選択してください。",
            "ALERT_SELECT_DEACTIVATE": "無効化するユーザーを選択してください。",
            "CONFIRM_ACTIVATE_MULTI": "これらのユーザーを有効化しますか？",
            "CONFIRM_DEACTIVATE_MULTI": "これらのユーザーを無効化しますか？",
            "RESET_PASSWORD": "パスワードをリセットしますか？",
            "CONFIRM_RESET_PASSWORD": "パスワードをリセットしますか？",
            "REMOVE_BANK_INFO": "銀行情報の削除を確認",
            "CONFIRM_REMOVE_BANK_INFO": "銀行情報を削除しますか？",
            "note_account_password_default": "注：新規マーチャントアカウントの場合、デフォルトのパスワードは「12345678」です",
            "Cash_Balance": "現金残高",
            "Credit_Balance": "クレジット残高"
        },
        "Update_merchant_user_success": "マーチャントユーザーは正常に更新されました",
        "Update_merchant_user_fail": "マーチャントユーザーの更新に失敗しました",
        "Create_merchant_user_success": "新規マーチャントユーザーが正常に作成されました",
        "Create_merchant_user_fail": "マーチャントユーザーの作成に失敗しました",
        "Update_merchant_user_status_success": "マーチャントユーザーは正常に更新されました",
        "Update_merchant_user_status_fail": "マーチャントユーザーの更新に失敗しました",
        "Reset_password_merchant_user_success": "パスワードのリセットに成功しました",
        "Reset_password_merchant_user_fail": "パスワードのリセットに失敗しました",
        "Deleted_merchant_bank_info_success": "銀行情報の削除に成功しました",
        "Deleted_merchant_bank_info_fail": "銀行情報の削除に失敗しました"
    },
    "table_header": {
        "Items_per_page": "ページあたりの項目数",
        "Page": "ページ",
        "Search_here": "ここで検索",
        "Shown_Active": "%{start} - %{end} / %{total} 表示",
        "Active": "%{active} アクティブ",
        "OF": "の"
    },
    "driver": {
        "Driver": "ドライバー",
        "Add": "追加",
        "addDriver": "ドライバーを追加",
        "Detail": "詳細",
        "detailDriver": "ドライバーの詳細",
        "addNewVehicle": "車両を追加",
        "serviceType": "サービス種別",
        "transport": "輸送",
        "delivery": "配送",
        "stripeConnect": "Stripe Connect",
        "stripeConnectDes": "より簡単な支払いのためにStripe Connectアカウントを追加",
        "bankAccount": "銀行情報",
        "bankAccountDes": "支払いを処理するためのドライバーの銀行情報",
        "supplierComission": "サプライヤーコミッション",
        "supplierComissionDes": "完了した注文ごとにサプライヤーが稼ぐコミッションを設定します。",
        "fleetCommission": "フリート/サプライヤーコミッション",
        "fleetCommissionDes": "完了した注文ごとにフリートまたはサプライヤーが稼ぐコミッションを設定します。ドライバーがサプライヤーのドライバーである場合、コミッションはサプライヤーの収益から差し引かれます。",
        "operationZones": "運用ゾーン",
        "operationZonesDes": "サプライヤーが仕事を受け取ることができるゾーン：",
        "notes": "ノート",
        "notesDes": "このドライバーに関する内部ノート：",
        "searchNotes": "ドライバー向けノートを追加",
        "marketplace": "マーケットプレイス",
        "marketplaceDes": "ドライバーが希望する仕事を受けられるようにします。",
        "otherSettings": "その他の設定",
        "topDriver": "トップドライバー",
        "topDriverDes": "「トップドライバー」タグで優秀なドライバーを優先し、より多くの乗車機会を提供します。",
        "walletTransferBetweenDriver": "ドライバー間のウォレット送金",
        "walletTransferBetweenDriverDes": "ドライバーが互いのウォレットに直接資金を送金できるようにし、チームリーダーやアンバサダーによるクレジットの簡単な分配を可能にします。",
        "rideSharing": "ライドシェアリング",
        "rideSharingDes": "ドライバーがライドシェアリングの仕事を行えるようにします。この高度な機能についてはアカウントマネージャーにご連絡ください。",
        "individual_vehicle": "個人車両",
        "company_vehicle": "会社車両",
        "ContactInfo": "連絡先情報",
        "name": "名前",
        "expiryDate": "有効期限",
        "action": "アクション",
        "editDocument": "ドキュメントの編集",
        "documentToUpload": "アップロードするドキュメント",
        "addFiles": "このエリアにファイルをクリックまたはドラッグしてアップロードしてください。",
        "fileSize": "最大ファイルサイズ10MB",
        "fileSizeDes": "ファイル拡張子は.jpg、.jpeg、.png、.doc、.docx、.xls、.xlsx、.pdf、.7z、.rar、.zipである必要があります",
        "removeOldFile": "新しいファイルをアップロードする前に古いファイルを削除してください。",
        "maxLengthFiles": "最大1ファイルのみアップロードしてください",
        "maxSizeFiles": "ファイルサイズは10MB未満にしてください",
        "typeFiles": "ファイルタイプは.jpg、.jpeg、.png、.doc、.docx、.xls、.xlsx、.pdf、.7z、.rar、.zipをアップロードしてください",
        "maxAvatarSizeFiles": "アバターサイズは8MB未満にしてください",
        "IFSCCode": "IFSCコード",
        "Drivers": "ドライバー",
        "default": "デフォルト",
        "customize": "カスタマイズ",
        "Activate": "有効化",
        "Stripe_connect": "ストライプ・コネクト",
        "status_stripe": "ステータス: {0}",
        "stripeConnectStatus": "ストライプ・コネクト",
        "stripeStatus": "ストライプアカウントのステータス",
        "Deactivate": "無効化",
        "Delete": "削除",
        "acceptInDay": "ドライバーが1日に受け入れ可能な最大予約数",
        "acceptATime": "ドライバーが同時に受け入れ可能な予約の総数",
        "Commission": "フリート手数料の設定",
        "Title_Commission": "すべてのドライバーに対してフリート手数料を設定",
        "Confirm_Set_Commission": "すべてのドライバーにこの手数料値を設定してもよろしいですか？",
        "Commission_Successfully": "ドライバーの手数料が正常に更新されました",
        "Apply": "適用",
        "Type": "種類",
        "Currency": "通貨",
        "View_Message": "メッセージ",
        "Send_Message": "メッセージを送信",
        "Driver_Name": "ドライバー名",
        "Driver_ID": "ID# / 国民ID#",
        "Phone_Number": "電話番号",
        "Driver_Type": "ドライバータイプ",
        "TOP": "トップ",
        "Company": "会社",
        "City": "市",
        "Driver_License_Number": "運転免許証番号",
        "License_Plate": "ナンバープレート",
        "Car_Type": "車種",
        "Zone": "ゾーン",
        "Registered_From": "登録元",
        "Registration_Date": "登録日",
        "IOS_Last_Login_Version": "iOS最終ログインバージョン",
        "Android_Last_Login_Version": "Android最終ログインバージョン",
        "Last_Active_Date": "最終アクティブ日",
        "Days_Since_Last_Active": "最終アクティブからの日数",
        "Completed_bookings": "完了した予約",
        "Status": "状態",
        "Actions": "アクション",
        "Action": "操作",
        "Inactive": "非アクティブ",
        "Active": "アクティブ",
        "Edit": "編集",
        "View": "表示",
        "ALERT_SELECT_ACTIVATE": "有効化するドライバーを選択してください。",
        "ALERT_SELECT_DEACTIVATE": "無効化するドライバーを選択してください。",
        "ALERT_SELECT_DELETE": "削除するドライバーを選択してください。",
        "CONFIRM_DELETE": "このドライバーを削除しますか？",
        "CONFIRM_DEACTIVATE_MULTI": "選択されたドライバーを無効化してもよろしいですか？ それらの接続されたハードウェアメーターも無効化されます。現在進行中の乗車または予約がないドライバーのみが無効化可能です。",
        "CONFIRM_ACTIVATE_MULTI": "これらのドライバーを有効化しますか？ 完全に運用準備が整っているドライバーのみ有効化できます。",
        "CONFIRM_DELETE_MULTI": "これらのドライバーを削除しますか？ 非アクティブな状態のドライバーのみ削除可能です。",
        "CONFIRM_REMOVE_BANKING_INFOR": "銀行情報を削除してもよろしいですか？",
        "REMOVE_BANKING_INFOR": "銀行情報の削除",
        "ADD_DRIVER": "ドライバー追加",
        "Avatar": "アバター",
        "Individual_Driver": "輸送 - 会社車両",
        "Owner_Operator": "輸送 - 個人車両",
        "deliveryCompany": "配送 - 会社車両",
        "deliveryIndividual": "配送 - 個人車両",
        "CONTACT_INFO": "連絡先情報",
        "Home_address": "自宅住所",
        "Username": "ユーザー名",
        "First_name": "名",
        "Last_name": "姓",
        "Phone_number": "電話番号",
        "Email_address": "メールアドレス",
        "Select_city": "市を選択",
        "State": "都道府県",
        "Zip_code": "郵便番号",
        "Date_of_birth": "生年月日",
        "placeholderDate": "mm/dd/yyyy",
        "City_driver_want_to_drive": "ドライバーが運転したい都市",
        "Driver_license_number": "運転免許証番号",
        "Driver_license_state": "運転免許証発行州/県",
        "Driver_license_expiry": "運転免許証の有効期限",
        "FOR_INSPECTOR": "検査官用",
        "Document": "書類",
        "Document_Id": "書類ID",
        "Link": "リンク",
        "Notes": "備考",
        "UPLOAD_DOCUMENT": "書類アップロード",
        "Click_here_to_download": "クリックしてダウンロード",
        "Click_here_to_view": "クリックして表示",
        "EXTENSION_REQUIREMENT": "ファイルの拡張子は .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip のいずれかで、5Mb未満でなければなりません。",
        "Invalid_file": "ファイルの内容が無効です",
        "File_size_larger_than_5Mb": "ファイルサイズは5Mbより大きくなければなりません",
        "BANK_ACCOUNT": "銀行口座",
        "Name_of_bank": "銀行名",
        "Name_of_account": "口座名義人の名前",
        "First_M_Last": "名・ミドル・姓",
        "RTBnumber": "ルーティング/トランジット/支店番号",
        "bankName": "銀行名",
        "Select_a_bank": "銀行を選択",
        "Account_number": "口座番号",
        "IBAN_number": "IBAN番号",
        "Check_number": "チェック番号",
        "Swift_code_number": "スウィフトコード番号",
        "SSNNI": "社会保障番号/国民保険番号",
        "sortCode": "ソートコード",
        "Verification_document": "確認書類（表面）",
        "Additional_document": "追加書類（表面）",
        "Verification_document_back": "確認書類（裏面）",
        "Additional_document_back": "追加書類（裏面）",
        "others": "その他",
        "signedW9Form": "署名済みW-9フォーム",
        "driverLicense": "運転免許証",
        "Verification_document_tooltip": "本人確認に使用できる書類:\n  - パスポート\n  - 運転免許証\n  - 在留許可証\n  - 市民カード\n  - IDカード",
        "Additional_document_tootip": "住所確認に使用できる書類:\n  - 運転免許証\n  - 公共料金の請求書\n  - 政府発行の書類\n  - 金融機関の明細書\n  - 選挙人名簿の記載",
        "isBankAccountOwner": "これはあなたのアカウントですか？",
        "yes": "はい",
        "no": "いいえ",
        "beneficiary_name": "受取人名",
        "beneficiary_idic": "受取人ID/IC",
        "relationship": "続柄",
        "select_relationship": "続柄を選択",
        "spouse": "配偶者",
        "daughterOrSon": "娘/息子",
        "brotherOrSister": "兄弟/姉妹",
        "relative": "親族",
        "friends": "友人",
        "other": "その他",
        "relationshipOtherName": "続柄",
        "OPERATION": "運用",
        "CAR_INSURANCE": "自動車保険",
        "Policy_number": "契約番号",
        "Effective_date": "有効開始日",
        "Expired_date": "有効期限",
        "Select_company": "会社を選択",
        "All_company": "すべての会社",
        "All_Car_Type": "すべての車種",
        "Car": "車",
        "Existing_car": "既存車両",
        "New_car": "新車",
        "License_plate": "ナンバープレート",
        "Select_license_plate": "ナンバープレートを選択",
        "Select_Car_Type": "車種を選択",
        "vehicleOwner": "車両所有者の名前",
        "Make": "メーカー",
        "Select_Car_Make": "メーカーを選択",
        "Model": "モデル",
        "Select_Car_Model": "モデルを選択",
        "Year": "年式",
        "Select_Year": "年式を選択",
        "Color": "色",
        "Passengers": "乗客数",
        "Luggage": "荷物",
        "License_expiry": "免許の有効期限",
        "Shift": "シフト",
        "Select_Shift_Template": "シフトテンプレートを選択",
        "Fleet_commission": "フリート手数料（レポート）",
        "Company_commission": "会社手数料（レポート）",
        "rideHailing": "ストリートハイリング",
        "intercity": "都市間",
        "food": "フード",
        "mart": "マート",
        "streetSharing": "ストリートシェアリング",
        "Shuttle": "シャトル",
        "parcel": "宅配",
        "driverInsurance": "ドライバー保険",
        "Percentage": "パーセンテージ",
        "Amount": "金額",
        "transaction": "取引",
        "Terminal_ID": "端末ID",
        "Auth_key": "認証キー",
        "CREDIT_DEPOSIT": "クレジットウォレット",
        "Credit_Balance": "クレジット残高",
        "Balance": "残高",
        "Change_balance": "残高を変更",
        "Select_balance": "残高を選択",
        "Adjust_amount": "金額を調整",
        "Adjust_value": "値を調整",
        "New_balance": "新しい残高",
        "New_point": "新しいポイント",
        "Enter_reason": "理由を入力",
        "ERROR_INPUT_BALANCE": "残高を選択してください。",
        "ERROR_INPUT_AMOUNT": "この値を入力してください。",
        "ERROR_INPUT_REASON": "理由を入力してください。",
        "ERROR_INPUT_VALID_AMOUNT": "0以上の値を入力してください。",
        "INFO_ADD_BALANCE_SUCCESS": "ドライバー残高が正常に更新されました",
        "INFO_ADD_CASH_BALANCE_SUCCESS": "ドライバーの現金残高が正常に更新されました",
        "Force_meter": "メーター強制",
        "Force_meter_tooltip": "ドライバーアプリは操作前にメーターに接続する必要があります。Pulsar & Centrodyne のメーターに適用されます。",
        "ERROR_EXISTED_PHONE": "この電話番号はすでにシステムに存在します。別の番号を入力してください。",
        "TOP_driver": "トップドライバー",
        "Ride_sharing": "ライドシェアリング",
        "Note_password": "注：新規ドライバーアカウントの場合、デフォルトのパスワードは「12345678」です",
        "Required_fields": "必須項目",
        "Cancel": "キャンセル",
        "Save": "保存",
        "SaveAndInProgress": "保存 & 進行中",
        "SaveAndDeactivate": "保存 & 無効化",
        "ERROR_REQUIRE_": "入力してください",
        "ERROR_INPUT_AVATAR": "アバターをアップロードしてください。",
        "ERROR_INPUT_DRIVER_TYPE": "ドライバータイプを選択してください。",
        "ERROR_INPUT_DOCUMENT": "アップロードしてください。",
        "ERROR_INPUT_USERNAME": "ユーザー名を入力してください。",
        "ERROR_INPUT_VALID_USERNAME": "ユーザー名は小文字と数字のみを含み、3〜20文字の長さである必要があります。",
        "ERROR_INPUT_PHONE": "有効な電話番号を指定してください。",
        "ERROR_INPUT_VALID_EMAIL": "有効なメールアドレスを入力してください。",
        "ERROR_INPUT_EMAIL": "メールアドレスを入力してください。",
        "ERROR_INPUT_FIRSTNAME": "名を入力してください。",
        "ERROR_INPUT_LASTNAME": "姓を入力してください。",
        "ERROR_INPUT_ADDRESS": "自宅住所を入力してください。",
        "ERROR_INPUT_CITY": "市を入力してください。",
        "ERROR_INPUT_STATE": "州を入力してください。",
        "ERROR_INPUT_ZIPCODE": "郵便番号を入力してください。",
        "ERROR_INPUT_BIRTHDAY": "生年月日を入力してください。",
        "ERROR_INPUT_NAME_OF_BANK": "銀行名を入力してください。",
        "ERROR_INPUT_NAME_OF_ACCOUNT": "口座名義人の名前を入力してください。",
        "ERROR_INPUT_ROUTING_NUMBER": "ルーティング番号を入力してください。",
        "ERROR_INPUT_BANK_NAME": "銀行を選択してください。",
        "ERROR_INPUT_ACCOUNT_NUMBER": "口座番号を入力してください。",
        "ERROR_INPUT_CHECK_NUMBER": "チェック番号を入力してください。",
        "ERROR_INPUT_SSN": "SSNを入力してください。",
        "ERROR_INPUT_DocumentId": "書類IDを入力してください。",
        "ERROR_INPUT_SORTCODE": "ソートコードを入力してください。",
        "ERROR_INPUT_COMPANY": "有効化する会社を選択してください。",
        "ERROR_INPUT_LICENSE_PLATE": "ナンバープレートを選択してください。",
        "ERROR_INPUT_ZONE": "有効化するゾーンを選択してください。",
        "ERROR_INPUT_SHIFT": "有効化するシフトを選択してください。",
        "ERROR_ACCOUNT_NUMBER_ONLY_NUMBERS": "これは数字のみを含める必要があります。",
        "ERROR_SSN_ONLY_NUMBERS": "これは数字のみを含める必要があります。",
        "ERROR_SSN_LENGTH": "SSNは少なくとも4文字必要です。",
        "ERROR_COMMISSION_NUMBERS": "有効な数値を入力してください。",
        "INFO_DEACTIVAED_SUCCESS": "ドライバーは正常に無効化されました。",
        "INFO_ACTIVAED_SUCCESS": "ドライバーは正常に有効化されました。",
        "INFO_DEACTIVAED_SUCCESS_BUT_HARDWARE": "ドライバーは無効化されましたが、統合されたハードウェアメーターを予期しない理由でオフにできませんでした。",
        "INFO_MULTIDEACTIVAED_SUCCESS": "選択された {0} 人のドライバーは正常に無効化されました。",
        "INFO_MULTIDEACTIVAED_HARDWARE_SUCCESS": "選択された {0} 人のドライバーは正常に無効化されました。{1} 台の接続されたハードウェアメーターが無効化されました。",
        "INFO_MULTIDEACTIVAED_SUCCESS_BUT_HARDWARE": "選択された {0} 人のドライバーは正常に無効化されました。{1} 台の接続されたハードウェアメーターが無効化されましたが、{2} は予期しない理由で無効化されませんでした。",
        "INFO_MULTIACTIVAED_SUCCESS": "選択された {0} 人のドライバーは正常に有効化されました。",
        "DRIVER_UPDATE_SUCCESS": "ドライバーが正常に更新されました。",
        "DRIVER_CREATE_SUCCESS": "ドライバー情報が正常に追加されました。",
        "Expiry_Date": "有効期限",
        "Case_number": "ケース番号",
        "Export_to_csv": "CSVにエクスポート",
        "CONFIRM_EXPORT_TO_CSV": "すべてのデータを .csv 形式でエクスポートしてもよろしいですか？",
        "Ride_sharing_tooltip": "ライドシェアリングは高度な機能です。詳細についてはサポートチームにお問い合わせください。",
        "In_Review": "審査中",
        "Reset_password_success": "パスワードのリセットに成功しました",
        "Topup_note": "（ドライバーを有効化した後、ドライバーの残高をチャージすることが可能です）",
        "cash_balance": "現金残高",
        "cash_wallet": "現金ウォレット",
        "total_balance": "総残高",
        "total_cash_balance": "総現金残高",
        "total_credit_balance": "総クレジット残高",
        "banking_status": "銀行のステータス",
        "bankingStatus": {
            "verified": "確認済み",
            "not_verified": "未確認"
        },
        "INVALID_FILE_SIZE": "ファイルサイズは8Mb未満である必要があります。",
        "totalDrivers": "総ドライバー数",
        "profileStatus": "プロフィールステータス",
        "profile_completed": "プロフィール完了",
        "profile_incomplete": "プロフィール未完了",
        "profile_inProgress": "プロフィール進行中",
        "in_review_completed": "審査中（プロフィール完了）",
        "in_review_incomplete": "審査中（プロフィール未完了）",
        "in_review_inProgress": "審査中（プロフィール進行中）",
        "CASH_WALLET_DEPOSIT": "現金ウォレット",
        "payoutSelect": "支払い（支払い後、ドライバーに受信トレイとメールが送信されます）",
        "Confirm_update_cash_balance": "現金残高の変更",
        "Confirm_update_cash_balance_msg": "ドライバーの残高を変更してもよろしいですか？",
        "cashBalance": "現金残高",
        "cashBalance_unavailable": "0.00",
        "cashBalance_active": "アクティブ",
        "cashBalance_negative": "マイナス残高",
        "creditBalance": "クレジット残高",
        "ERROR_INPUT_DOCUMETID": "書類IDを入力してください。",
        "ERROR_INPUT_EXPIRYDATE": "有効期限を入力してください。",
        "driverType": "ドライバータイプ",
        "tooltip_in_review_completed": "プロフィールを完了した「審査中」のドライバーに受信トレイを送信",
        "tooltip_in_review_incomplete": "プロフィールを完了していない「審査中」のドライバーに受信トレイを送信",
        "tooltip_in_review_inProgress": "プロフィールを完了したが追加確認が必要な「審査中」のドライバーに受信トレイを送信",
        "tooltip_active": "「アクティブ」ドライバーに受信トレイを送信",
        "tooltip_inactive": "アカウントが再有効化されたドライバーに受信トレイを送信",
        "active": "有効化",
        "inactive": "無効化",
        "inReviewInProgress": "審査中",
        "Market_place": "マーケットプレイス",
        "Market_place_tooltip": "ドライバーがオンデマンドまたは予約の受け入れを選択できるようにする",
        "selectDriverType": "ドライバータイプを選択",
        "PlsSelectDriverType": "ドライバータイプを選択してください",
        "emergencyContacts": "緊急連絡先",
        "switchVehicle": "任意の車両に切り替え",
        "switchVehicleNote": "ドライバーが割り当てられたサプライヤー会社の任意の車両に切り替えられるようにします。",
        "vehicleModel": "モデル",
        "licensePlateNumber": "車両ナンバー",
        "addVehicle": "車両を追加",
        "newVehicle": "新しい車両",
        "linkVehicle": "車両をリンク",
        "modal": "モーダル",
        "selectVehicle": "車両を選択",
        "selectVehicleNote": "ドライバーが予約用の車両を選択または変更できるようにします。",
        "Warning_change_company": "警告：このドライバーを別の会社に再割り当てすると、関連するすべての車両がリンク解除されます。"
    },
    "corporate": {
        "User_Name": "ユーザー名",
        "Edit_Balance": "残高を編集",
        "Corporates": "法人",
        "Supplier": "サプライヤー",
        "CarTypes": "車種",
        "Add": "追加",
        "Balance": "残高",
        "Activate": "有効化",
        "Deactivate": "無効化",
        "Delete": "削除",
        "Company_Name": "会社名",
        "Admin_Name": "管理者名",
        "Username": "ユーザー名",
        "Phone_Number": "電話番号",
        "importBooking": "予約をインポート可能",
        "Email": "メールアドレス",
        "Registered_From": "登録元",
        "Registration_Date": "登録日",
        "Status": "ステータス",
        "Actions": "アクション",
        "Action": "操作",
        "Inactive": "非アクティブ",
        "Active": "アクティブ",
        "In_Review": "審査中",
        "Edit": "編集",
        "View": "表示",
        "ADD_CORPORATE_CARD": "法人カードを追加",
        "selectCountry": "国を選択",
        "CONFIRM_DELETE_CARD": "このカードを削除してもよろしいですか？",
        "ALERT_SELECT_ACTIVATE": "有効化する法人を選択してください。",
        "ALERT_SELECT_DEACTIVATE": "無効化する法人を選択してください。",
        "ALERT_SELECT_DELETE": "削除する法人を選択してください。",
        "CONFIRM_DELETE": "この法人を削除しますか？",
        "CONFIRM_DELETE_USER": "この法人ユーザーを削除しますか？",
        "CONFIRM_DEACTIVATE_MULTI": "これらの法人を無効化しますか？",
        "CONFIRM_ACTIVATE_MULTI": "これらの法人を有効化しますか？",
        "CONFIRM_DELETE_MULTI": "これらの法人を削除しますか？\n** 非アクティブな法人のみ削除可能です。**",
        "ERROR_INPUT_PHONE": "有効な電話番号を指定してください。",
        "ERROR_INPUT_VALIDPHONE": "有効な電話番号を入力してください。",
        "ERROR_EXISTED_PHONE": "この電話番号はすでにシステムに存在します。別の番号を入力してください。",
        "Account": "アカウント",
        "Corp_traveler": "法人ユーザー",
        "Payment_methods": "支払い方法",
        "Corp_operators": "法人オペレーター",
        "Pricing": "料金設定",
        "COMPANY_INFORMATION": "会社情報",
        "Company_name": "会社名",
        "Company_ID": "会社ID（法人ユーザー登録用）",
        "Company_address": "会社住所",
        "Invoice_email": "請求書メールアドレス",
        "Confirmation_email": "確認メールアドレス",
        "colorCodeBooking": "カラーコード",
        "ADMIN_ACCOUNT": "管理者アカウント",
        "First_name": "名",
        "Last_name": "姓",
        "Commission": "手数料",
        "commissionBooking": "アプリ/PWAからの予約に手数料を適用",
        "Required_fields": "必須項目",
        "Save": "保存",
        "Cancel": "キャンセル",
        "Reset_password": "パスワードをリセット",
        "Employee_Name": "名前",
        "Created_Date": "作成日",
        "Add_corporate_card": "法人カードを追加",
        "Corp_Users": "法人ユーザー",
        "Pricing_Default": "デフォルト（通常の利用者と同じ料金プラン）",
        "Pricing_Discount": "パーセンテージ割引（法人ユーザー専用）",
        "Markup_By_Percent": "パーセンテージ上乗せ（法人ユーザー専用）",
        "Pricing_Discount_new": "割引率（デフォルト料金に基づく）",
        "Markup_By_Percent_new": "マークアップ率（デフォルト料金に基づく）",
        "Pricing_Add_different_rates": "異なる料金を追加",
        "ERROR_INPUT_COMPANYNAME": "会社名を入力してください。",
        "ERROR_INPUT_USERNAME": "ユーザー名を入力してください。",
        "ERROR_INPUT_FIRSTNAME": "名を入力してください。",
        "ERROR_INPUT_EMAIL": "メールアドレスを入力してください。",
        "ERROR_INPUT_VALID_EMAIL": "有効なメールアドレスを入力してください。",
        "ERROR_INPUT_VALID_NUMBER": "有効な数値を入力してください。",
        "ERROR_INPUT_VALID_RANGE_AMOUNT": "{0} から {1} の間の値を入力してください。",
        "INFO_ADD_CORPORATE_SUCCESS": "アカウント {0} が正常に作成されました。ログイン情報の詳細はメール {1} を確認してください。",
        "INFO_UPDATE_CORPORATE_SUCCESS": "情報が正常に更新されました。",
        "INFO_UPDATE_PRICING_SUCCESS": "料金が正常に更新されました。",
        "INFO_RESET_PASSWORD_SUCCESS": "アカウント {0} は正常にリセットされました。ログイン情報の詳細はメール {1} を確認してください。",
        "COMFIRM_RESET_PASSWORD": "パスワードをリセットしますか？",
        "Traveler_signature": "旅行者の署名",
        "Parcel_Delivery": "宅配機能を有効化",
        "Tracking_log": "追跡ログ",
        "Rating": "評価",
        "Email_address": "メールアドレス",
        "Assistant_email": "補助メールアドレス",
        "Corp_PO": "法人PO",
        "Cost_centre": "コストセンター",
        "Corp_division": "法人部門",
        "Corp_id": "法人ID",
        "Department": "部署",
        "Manager_email": "管理者のメールアドレス",
        "Manager_name": "管理者の名前",
        "Corporate_card": "法人カード",
        "Direct_invoicing": "直接請求",
        "Corporate_Prepaid": "法人プリペイド",
        "Cash": "現金",
        "Car_Type_ID": "車種ID",
        "App_Display_Name": "アプリ表示名",
        "Normal_Fare": "通常",
        "Flat_Fare": "固定料金",
        "Hourly_Daily_Rate": "時間/日単位",
        "Delivery_Rate": "配送",
        "Assign_rate": "料金を割り当てる",
        "Zone": "ゾーン",
        "Rate": "料金",
        "Zone_Rate": "ゾーン - 料金",
        "Please_select_rate": "料金を選択してください",
        "Paid_by": "支払者",
        "Enter_new_credit_card": "新しいクレジットカードを入力",
        "TOP_UP": "チャージ",
        "Top_up": "チャージする",
        "Topup_amount": "チャージ金額",
        "Currency": "通貨",
        "ERROR_INPUT_AMOUNT": "有効な金額を入力してください。",
        "VAT_number": "VAT番号",
        "Gender": "性別",
        "Select_Gender": "選択",
        "Male": "男性",
        "Female": "女性",
        "Date_of_birth": "生年月日",
        "Street_address": "通りの住所",
        "City": "市",
        "State": "州",
        "Zip_code": "郵便番号",
        "Country": "国",
        "adding_new_card": "%{zoneName} エリアでサービスを利用するために新しいカードを追加しています",
        "No_Support": "このエリアでは現在、新しいカードの追加はサポートされていません",
        "Job_title": "職業",
        "required_phone_number_payment": "法人の電話番号が必要です。続行するにはプロフィールを更新してください。",
        "Yes": "はい",
        "No": "いいえ",
        "AddCardBeforeTopup": "クレジットカードが見つかりませんでした。新しいカードを追加してください。",
        "flightNumber": "フライト番号",
        "Company_Type": "会社タイプ",
        "Standard": "標準法人",
        "Airline": "航空会社",
        "Branding": "ブランディング",
        "bgImage": "背景画像（1600x900）",
        "logoImage": "ロゴ画像",
        "aliasTitle": "エイリアス（URL）パラメータ",
        "importChauffeurBooking": "輸送予約をインポート可能",
        "isCorporateVip": "法人VIP",
        "isCorporateVipToolTip": "この設定を有効にすると、すべての法人旅行者に自動的にVIPステータスが付与されます。個々のユーザーごとにオフにすることもできます。",
        "qrTitle": "QRウェブ予約リンク",
        "qrNote": "あなた専用のパートナー予約リンクがここにあります！バー、ホテルの受付、客室、ウェブサイトなど、顧客が乗車や配送を必要とする可能性のある場所に目立つように表示してください。独自のQRコード付きのスタンドをデザインし、顧客が簡単にスキャンして旅を予約できるようにすることを検討してください！",
        "PricingLegacy": "価格設定（レガシー）",
        "defaultTemplate": "デフォルトテンプレート",
        "Select_Rate_Template": "料金テンプレートを選択"
    },
    "airline": {
        "Airlines": "航空会社",
        "Add": "追加",
        "Activate": "有効化",
        "Deactivate": "無効化",
        "Delete": "削除",
        "Company_Name": "会社名",
        "Admin_Name": "管理者名",
        "Username": "ユーザー名",
        "Phone_Number": "電話番号",
        "Email": "メールアドレス",
        "Registered_From": "登録元",
        "Registration_Date": "登録日",
        "Status": "ステータス",
        "Actions": "アクション",
        "Action": "操作",
        "Inactive": "非アクティブ",
        "Active": "アクティブ",
        "In_Review": "審査中",
        "Edit": "編集",
        "View": "表示",
        "ADD_CORPORATE_CARD": "航空会社カードを追加",
        "selectCountry": "国を選択",
        "CONFIRM_DELETE_CARD": "このカードを削除してもよろしいですか？",
        "ALERT_SELECT_ACTIVATE": "航空会社を有効化するために選択してください。",
        "ALERT_SELECT_DEACTIVATE": "航空会社を無効化するために選択してください。",
        "ALERT_SELECT_DELETE": "航空会社を削除するために選択してください。",
        "CONFIRM_DELETE": "この航空会社を削除しますか？",
        "CONFIRM_DELETE_USER": "この航空会社のユーザーを削除しますか？",
        "CONFIRM_DEACTIVATE_MULTI": "この会社を無効化してもよろしいですか？",
        "CONFIRM_ACTIVATE_MULTI": "この会社を有効化してもよろしいですか？",
        "CONFIRM_DELETE_MULTI": "この会社を削除してもよろしいですか？\n** 非アクティブな会社のみ削除可能です。**",
        "ERROR_INPUT_PHONE": "有効な電話番号を指定してください。",
        "ERROR_INPUT_VALIDPHONE": "有効な電話番号を入力してください。",
        "ERROR_EXISTED_PHONE": "この電話番号はすでにシステムに存在します。別の番号を入力してください。",
        "Airline": "航空会社",
        "Crew": "クルー",
        "Payment_methods": "支払い方法",
        "Airline_operators": "航空会社オペレーター",
        "Pricing": "料金設定",
        "COMPANY_INFORMATION": "会社情報",
        "Company_name": "会社名",
        "Company_ID": "会社ID",
        "Company_address": "会社住所",
        "Invoice_email": "請求書メールアドレス",
        "Confirmation_email": "確認メールアドレス",
        "colorCodeBooking": "カラーコード",
        "ADMIN_ACCOUNT": "管理者アカウント",
        "First_name": "名",
        "Last_name": "姓",
        "Commission": "手数料",
        "Required_fields": "必須項目",
        "Required_fields_crews": "注：新規クルーアカウントの場合、デフォルトのパスワードは「12345678」です",
        "Save": "保存",
        "Cancel": "キャンセル",
        "Reset_password": "パスワードをリセット",
        "Crew_Name": "クルー",
        "Created_Date": "作成日",
        "Add_corporate_card": "航空会社カードを追加",
        "Corp_Users": "オペレーター",
        "Pricing_Default": "デフォルト",
        "Pricing_Discount": "パーセンテージ割引",
        "Markup_By_Percent": "パーセンテージ上乗せ",
        "Pricing_Add_different_rates": "異なる料金を追加",
        "ERROR_INPUT_COMPANYNAME": "会社名を入力してください。",
        "ERROR_INPUT_USERNAME": "ユーザー名を入力してください。",
        "ERROR_INPUT_FIRSTNAME": "名を入力してください。",
        "ERROR_INPUT_EMAIL": "メールアドレスを入力してください。",
        "ERROR_INPUT_VALID_EMAIL": "有効なメールアドレスを入力してください。",
        "ERROR_INPUT_VALID_NUMBER": "有効な数値を入力してください。",
        "ERROR_INPUT_VALID_RANGE_AMOUNT": "{0} から {1} の間の値を入力してください。",
        "INFO_ADD_CORPORATE_SUCCESS": "アカウント {0} が正常に作成されました。",
        "INFO_UPDATE_CORPORATE_SUCCESS": "情報が正常に更新されました。",
        "INFO_UPDATE_PRICING_SUCCESS": "料金が正常に更新されました。",
        "INFO_RESET_PASSWORD_SUCCESS": "アカウント {0} は正常にリセットされました。",
        "COMFIRM_RESET_PASSWORD": "パスワードをリセットしますか？",
        "Traveler_signature": "旅行者の署名",
        "Tracking_log": "追跡ログ",
        "Rating": "評価",
        "Email_address": "メールアドレス",
        "Assistant_email": "補助メールアドレス",
        "Corp_PO": "法人PO",
        "Cost_centre": "コストセンター",
        "Corp_division": "法人部門",
        "Corp_id": "法人ID",
        "Department": "部署",
        "Manager_email": "管理者のメールアドレス",
        "Manager_name": "管理者の名前",
        "Corporate_card": "航空会社カード",
        "Direct_invoicing": "直接請求",
        "Corporate_Prepaid": "航空会社プリペイド",
        "Cash": "現金",
        "Car_Type_ID": "車種ID",
        "App_Display_Name": "アプリ表示名",
        "Normal_Fare": "通常",
        "Flat_Fare": "固定料金",
        "Hourly_Daily_Rate": "時間/日単位",
        "Delivery_Rate": "配送",
        "Assign_rate": "料金を割り当てる",
        "Zone": "ゾーン",
        "Rate": "料金",
        "Zone_Rate": "ゾーン - 料金",
        "Please_select_rate": "料金を選択してください",
        "Paid_by": "支払者",
        "Enter_new_credit_card": "新しいクレジットカードを入力",
        "TOP_UP": "チャージ",
        "Top_up": "チャージする",
        "Topup_amount": "チャージ金額",
        "Currency": "通貨",
        "ERROR_INPUT_AMOUNT": "有効な金額を入力してください。",
        "VAT_number": "VAT番号",
        "Gender": "性別",
        "Select_Gender": "選択",
        "Male": "男性",
        "Female": "女性",
        "Date_of_birth": "生年月日",
        "Street_address": "通りの住所",
        "City": "市",
        "State": "州",
        "Zip_code": "郵便番号",
        "Country": "国",
        "adding_new_card": "%{zoneName} エリアでサービスを利用するために新しいカードを追加しています",
        "No_Support": "このエリアでは現在、新しいカードの追加はサポートされていません",
        "Job_title": "職業",
        "required_phone_number_payment": "法人の電話番号が必要です。続行するにはプロフィールを更新してください。",
        "Yes": "はい",
        "No": "いいえ",
        "AddCardBeforeTopup": "クレジットカードが見つかりませんでした。新しいカードを追加してください。"
    },
    "message": {
        "Add": "追加",
        "Delete": "削除",
        "Items_per_page": "ページあたりの項目数",
        "Page": "ページ",
        "Search_here": "ここで検索",
        "Shown_Active": "{0} - {1} / {2} 表示",
        "Date": "日付",
        "Subject": "件名",
        "To": "宛先",
        "Type": "タイプ",
        "Result": "結果",
        "Actions": "アクション",
        "New_message": "新しいメッセージ",
        "MESSAGE_DETAILS": "メッセージの詳細",
        "All": "すべて",
        "Company": "会社",
        "Active": "アクティブ",
        "In_Active": "非アクティブ",
        "In_Review": "審査中",
        "Send_to": "送信先",
        "All_drivers": "全ドライバー",
        "All_customers": "全顧客",
        "All_merchants": "全マーチャント",
        "Customized_driver": "カスタマイズドライバー",
        "Customized_customer": "カスタマイズ顧客",
        "Customized_merchant": "カスタマイズマーチャント",
        "Receiver_list": "受信者リスト",
        "Content": "内容",
        "Required_fields": "必須項目",
        "Search_driver_name_driver_phone_number": "ドライバー名または電話番号を検索",
        "Search_category_name": "カテゴリ名を検索",
        "Search_customer_name_customer_phone_number": "顧客名または電話番号を検索",
        "Search_merchant_name_merchant_phone_number": "マーチャント名または電話番号を検索",
        "Search_car_type": "車種を検索",
        "Searching": "検索中…",
        "No_results": "結果なし",
        "Send": "送信",
        "Cancel": "キャンセル",
        "SMS_failed_Inbox_succeeded": "SMS送信失敗、受信トレイ送信成功",
        "Failed": "失敗",
        "Succeeded": "成功",
        "CONFIRM_DELETE": "このメッセージを削除しますか？",
        "CONFIRM_DELETE_MULTI": "これらのメッセージを削除しますか？",
        "ALERT_SELECT_DELETE": "削除するメッセージを選択してください。",
        "ERROR_INPUT_RECEIVERLIST": "メッセージを送信するドライバーを少なくとも1人選択してください。",
        "ERROR_INPUT_CUSTOMER_RECEIVERLIST": "メッセージを送信する顧客を少なくとも1人選択してください。",
        "ERROR_INPUT_SUBJECT": "件名を入力してください。",
        "ERROR_INPUT_CONTENT": "内容を入力してください。",
        "ERROR_INPUT_LEAST_ONE_SUBJECT_CONTENT": "続行するには少なくとも1つの言語を設定してください。",
        "ERROR_INPUT_SUBJECT_MULTI_LANGUAGE": "{0} 言語の件名を入力してください。",
        "ERROR_INPUT_CONTENT_MULTI_LANGUAGE": "{0} 言語の内容を入力してください。",
        "ERROR_INPUT_URL_MULTI_LANGUAGE": "{0} 言語のURLを入力してください。",
        "ERROR_INPUT_TITLE_MULTI_LANGUAGE": "{0} 言語のタイトルを入力してください。",
        "ERROR_INPUT_BODY_MULTI_LANGUAGE": "{0} 言語の本文を入力してください。",
        "import_fail_success_driver": "%{success} 人のドライバーが正常にインポートされ、%{fail} 人が失敗しました。",
        "import_fail_success_passenger": "%{success} 人の顧客が正常にインポートされ、%{fail} 人が失敗しました。",
        "import_fail_success_merchant": "%{success} 人のマーチャントが正常にインポートされ、%{fail} 人が失敗しました。",
        "CONFIRM_SEND_SUCCESS": "メッセージが送信されました。処理が完了するまで数分かかる場合があります。",
        "Load_message_and_sms_fail": "メッセージとSMSの詳細を読み込む際にエラーが発生しました。",
        "Update_message_and_sms_fail": "メッセージとSMSの詳細を更新する際にエラーが発生しました。",
        "Update_message_and_sms_success": "メッセージとSMSの詳細が正常に更新されました。",
        "Driver_List": "ドライバーリスト",
        "Car_Type_List": "車種リスト",
        "Search_vehicle_type_name": "車両タイプ名を検索",
        "ALERT_PROCESSING_DELETE": "リクエストを処理中です。数秒かかる場合があります。",
        "Customer": "顧客",
        "Driver": "ドライバー",
        "StatusOfCustomer": "顧客のステータス",
        "StatusOfDriver": "ドライバーのステータス",
        "StatusOfMerchant": "マーチャントのステータス",
        "SendTo": "送信先",
        "DownloadReceiverList": "受信者リストをダウンロード",
        "warningInputChangeOver": "直近2つの入力値の変化率が20％を超えています。再確認してください！",
        "NoteDownloadReveverList": "1か月後、受信者リストのダウンロードは無効になります。",
        "Load_voip_fail": "VOIPの詳細を読み込む際にエラーが発生しました。",
        "Update_voip_success": "VOIPの詳細が正常に更新されました。",
        "Update_voip_fail": "VOIPの詳細を更新する際にエラーが発生しました。",
        "expiryDate": "新規ユーザーへの送信期限",
        "expiryDate_placeholder": "有効期限（mm/dd/yy）",
        "NA": "N/A",
        "PleaseSelectStatusOfDriver": "ドライバーのステータスを選択してください。",
        "versionApp": "アプリバージョン",
        "platform": "プラットフォーム",
        "contentType": "コンテンツタイプ",
        "url": "URL",
        "title": "タイトル",
        "contentId": "コンテンツID",
        "body": "本文",
        "image_notification": "画像",
        "platformItem": {
            "all": "すべて",
            "iOS": "iOS",
            "android": "Android"
        },
        "comparisonVersionApp": {
            "is": "である",
            "is_not": "でない",
            "is_one_of": "のいずれかである",
            "is_not_one_of": "のいずれかでない",
            "greater_or_equal": "以上または等しい",
            "less_or_equal": "以下または等しい"
        },
        "contentTypeItem": {
            "html": "HTML",
            "link": "リンク"
        },
        "Please_input": "このフィールドは必須です"
    },
    "fleetInfo": {
        "Fleet_name": "フリート名",
        "Phone_number": "電話番号",
        "Email_address": "メールアドレス",
        "Country": "国",
        "Time_zone": "タイムゾーン",
        "Address": "住所",
        "Website": "ウェブサイト",
        "Currency": "通貨",
        "Distance_unit": "距離単位",
        "Load_fleet_info_fail": "フリート情報の読み込み中にエラーが発生しました"
    },
    "surchargePolicy": {
        "Surchage_Policy": "追加料金ポリシー",
        "SurgePolicy": "急増ポリシー",
        "surgeType": "急増タイプ",
        "bookingTimeCoverage": "予約時間範囲",
        "Delete_surcharge": "選択した追加料金を削除しますか？",
        "Delete_surcharge_item": "選択した追加料金項目を削除しますか？",
        "Delete_surcharge_item_success": "追加料金が正常に非活性化されました",
        "Delete_surcharge_item_fail": "追加料金項目の非活性化中にエラーが発生しました",
        "linkNewPolicy": "新しいポリシーをリンク",
        "Link": "リンク",
        "ViewPolicy": "ポリシーを表示",
        "Unlink": "リンク解除",
        "Create_Surcharge_Item_success": "追加料金項目が正常に作成されました",
        "Create_Surcharge_Item_fail": "追加料金項目の作成中にエラーが発生しました",
        "Update_Surcharge_Item_success": "追加料金項目が正常に更新されました",
        "Update_Surcharge_Item_fail": "追加料金項目の更新中にエラーが発生しました",
        "single": "単日",
        "yearly": "年間",
        "weekly": "曜日",
        "Please_select_at_least_one_day": "少なくとも1日を選択してください"
    },
    "penaltyPolicy": {
        "penaltyPolicy": "ペナルティポリシー",
        "Reservation": "予約",
        "onDemand": "オンデマンド",
        "noShow": "ノーショー",
        "freeCancellation": "無料キャンセルバッファ",
        "fieldExclusions": "含まれるフィールド",
        "penaltyActive": "キャンセル期間階層 - ピックアップ時間前",
        "penaltyType": "ペナルティタイプ",
        "Compensation": "サプライヤー/ドライバー補償",
        "Create_penalty_success": "ペナルティテンプレートが正常に作成されました",
        "Create_penalty_fail": "ペナルティテンプレートの作成中にエラーが発生しました",
        "Update_penalty_success": "ペナルティテンプレートが正常に更新されました",
        "Update_penalty_fail": "ペナルティテンプレートの更新中にエラーが発生しました",
        "confirmDelete": "削除の確認",
        "confirmDeleteContent": "このペナルティポリシーを削除しますか？このポリシーを削除すると、すべての予約料金からリンクが解除されます。",
        "Delete_success": "ペナルティテンプレートが正常に削除されました",
        "Delete_fail": "ペナルティテンプレートの削除中にエラーが発生しました",
        "payoutPolicy": "支払いポリシー",
        "commission": "顧客ペナルティの割合",
        "commissionPayout": "サプライヤー支払いの割合",
        "fixAmount": "金額",
        "amount": "金額",
        "percent": "割合",
        "percentPayout": "支払いの%",
        "percentFare": "運賃の%",
        "penaltyFee": "キャンセル料",
        "commissionType": "補償タイプ",
        "commissionFee": "補償",
        "tax": "税金",
        "techFee": "技術料金",
        "minute": "分",
        "hour": "時間"
    },
    "rateTemplates": {
        "Rate_Templates": "料金テンプレート",
        "newRate": "新しい料金テンプレート",
        "placeHolderName": "料金テンプレート名を入力",
        "Assign_Rate": "料金を割り当て",
        "carTypeName": "車種名",
        "appDisplayName": "アプリ表示名",
        "assign_rate_success": "車種に料金が正常に割り当てられました",
        "assign_rate_fail": "料金の割り当て中にエラーが発生しました",
        "confirmDeleteTitle": "削除の確認",
        "confirmDelete": "このテンプレートを削除しますか？このテンプレートを削除すると、リンクしているすべてのパートナー/法人からリンクが解除されます。",
        "setDefaultSuccess": "デフォルト料金テンプレートが正常に設定されました",
        "setDefaultFail": "デフォルト料金テンプレートの設定中にエラーが発生しました",
        "Delete_rate_template_success": "料金テンプレートが正常に削除されました",
        "Delete_rate_template_fail": "料金テンプレートの削除中にエラーが発生しました",
        "setDefault": "デフォルトに設定",
        "Regular": "通常料金",
        "Sharing": "ストリートシェアリング料金",
        "Flat": "定額料金",
        "Hourly": "時間/日単位",
        "Delete_default": "デフォルトテンプレートを削除しようとした場合、別のデフォルト料金を設定するまでは許可されません。"
    },
    "supplierPlan": {
        "Supplier_Plan": "サプライヤープラン",
        "rateTemplate": "料金スキーマ",
        "rateTemplateNote": "ドライバーまたはサプライヤーに支払われる金額を決定するために使用される運賃計算。",
        "percent": "フリートコミッション",
        "percentNote": "各予約に対してフリートが請求する割合。",
        "amount": "固定金額",
        "amountNote": "各予約に対してフリートが差し引く金額。",
        "supplierPenaltyId": "サプライヤーペナルティポリシー",
        "payoutType": "タイプ",
        "Select_penalty_policy": "ペナルティポリシーを選択",
        "Select_supplier_plan": "サプライヤープランを選択",
        "confirm_delete": "このプランを削除しますか？このプランを削除すると、リンクしているすべてのサプライヤーからリンクが解除され、デフォルトが使用されます。",
        "Create_supplier_plan_success": "サプライヤープランが正常に作成されました",
        "Create_supplier_plan_fail": "サプライヤープランの作成中にエラーが発生しました",
        "Update_supplier_plan_success": "サプライヤープランが正常に更新されました",
        "Update_supplier_plan_fail": "サプライヤープランの更新中にエラーが発生しました",
        "Payout_Plan": "支払いプラン",
        "Payout_Plan_note": "サプライヤーに支払われる金額を設定します。",
        "default": "デフォルト",
        "custom": "カスタムプラン",
        "cannotSetPayout": "外部パートナーのドライバーに対して支払いプランを設定できません。"
    },
    "generalSetting": {
        "alertNotifications": {
            "title": "アラート通知",
            "emails": "メール",
            "emailsPlaceholder": "メールを入力",
            "slack": "Slackチャンネル（手順）",
            "slackPlaceholder": "Slack POST URL",
            "discord": "Discordチャンネル（手順）",
            "discordPlaceholder": "Discordチャンネルを入力",
            "telegram": "Telegramチャンネル（手順）",
            "telegramPlaceholder": "Telegramチャンネルを入力"
        },
        "showFare": {
            "title": "運賃をドライバーに表示",
            "totalFare": "合計運賃",
            "estimateGross": "推定総収益"
        },
        "carHailing": "ストリートハイリング",
        "regular": "通常",
        "cancelTimeout": "タイムアウトした予約を自動キャンセル",
        "allowStripePaymentLink": "Stripe決済リンクの生成を許可",
        "flat": "固定",
        "techFee": "取引ごとの技術料",
        "customizeLogo": "ダッシュボードのロゴをカスタマイズ",
        "logo": "ロゴ",
        "customerApp": "顧客アプリ",
        "hourly": "時間単位",
        "shuttle": "シャトル",
        "parcel": "宅配",
        "freeCancelTime": "1日あたりの無料キャンセル回数",
        "AM": "午前",
        "PM": "午後",
        "RUSH_HOUR": "追加料金",
        "Name": "名前",
        "Date": "日付",
        "Mon": "月",
        "Tue": "火",
        "Wed": "水",
        "Thu": "木",
        "Fri": "金",
        "Sat": "土",
        "Sun": "日",
        "Time_range": "時間範囲",
        "From": "開始",
        "To": "終了",
        "Rush_hour_time_calculation": "追加料金の計算",
        "Rush_hour_surcharge": "追加料金",
        "Required_fields": "必須項目",
        "Save": "保存",
        "Cancel": "キャンセル",
        "MDISPATCHER_CANCELLATION": "乗客/パートナー/ウェブ予約キャンセル",
        "Book_now": "今すぐ予約",
        "MDISPATCHER_CANCELLATION_TOOLTIP": "乗客/パートナー/ウェブ予約キャンセル",
        "Book_now_toolTip": "オンデマンド予約がキャンセルされた場合、キャンセル料が適用されます",
        "Book_later_toolTip": "予約がピックアップ時間前にキャンセルされた場合、キャンセル料が適用されます",
        "Drv_Book_now_toolTip": "オンデマンド予約が指定時間内に受理後キャンセルされた場合、キャンセル料が適用されます",
        "Drv_Book_later_toolTip": "事前予約が指定時間内にピックアップ予定時間前にキャンセルされた場合、キャンセル料が適用されます",
        "Free_cancel": "無料キャンセル時間",
        "Hide_cancel_btn": "指定回数後にキャンセルボタンを非表示",
        "Driver_cancellation": "ドライバーキャンセル",
        "Time_day": "1日あたりの回数",
        "Duration": "時間",
        "Times": "回数",
        "Book_later": "後で予約",
        "Intercity": "都市間",
        "Intercity_tooltip": "都市間予約が指定時間内にピックアップ時間前にキャンセルされた場合、キャンセル料が適用されます",
        "MDISPATCHER_NO_SHOW": "ノーショー",
        "AIRPORT_FEE": "空港料金",
        "From_airport": "空港から",
        "To_airport": "空港へ",
        "MEET_DRIVER": "ドライバーと待ち合わせ",
        "On_curb": "路上で待ち合わせ",
        "Meet_Greet": "ミート＆グリート",
        "Add": "追加",
        "Edit": "編集",
        "DELETE_SURCHARGE": "追加料金を削除",
        "DELETE_SERVICE": "サービスを削除",
        "CHANGE_APPLY_SERVICE_FEES": "適用するサービス料金を変更",
        "DELETE_CONFIRM": "このアイテムを削除してもよろしいですか？",
        "DELETE_SURCHARGE_CONFIRM": "選択した追加料金を削除してもよろしいですか？",
        "DELETE_ADDITIONAL_SERVICES_CONFIRM": "選択したサービスを削除してもよろしいですか？",
        "CHANGE_APPLY_SERVICE_FEES_CONFIRM_CUSTOM": "異なるゾーンごとに異なる料金を適用してもよろしいですか？",
        "CHANGE_APPLY_SERVICE_FEES_CONFIRM_ALL": "すべてのゾーンに同じ料金を適用してもよろしいですか？",
        "EDIT_SERVICE_FEES": "サービス料金を編集",
        "EDIT_SERVICE_FEES_CONFIRM": "%{currentZone} の設定を保存せずに離れてもよろしいですか？",
        "HEAVY_TRAFFIC": "渋滞",
        "Heavy_traffic_surcharge": "渋滞追加料金",
        "TOLL_FEE": "通行料",
        "parkingFee": "駐車料金",
        "gasFee": "ガス料金",
        "OTHER_FEES": "その他の料金",
        "Can_edit": "編集可能",
        "Can_add_note": "メモを追加可能",
        "Other_fees_amount": "その他の料金額",
        "TAX": "税金",
        "Tax_surcharge": "税金追加料金",
        "TIP": "チップ",
        "Default_tip": "デフォルトチップ",
        "RESERVATION_REMINDER": "予約リマインダー",
        "Driver_app_SMS_alert_before": "ドライバーアプリ - SMS & アラート通知前",
        "notifyWhenDriverFailStartTrip": "ドライバーがピックアップ時間前にトリップを開始できなかった場合にオペレーターに通知",
        "notifyWhenDriverFailStartTripTooltip": "通知はプラットフォームの通知セクションに表示されます。また、メール、Slack、Discord、またはTelegramを通じてアラートを送信するよう設定し、タイムリーな更新を受け取ることもできます。",
        "Pax_app_alert_before": "乗客アプリ - アラート通知前",
        "Confirm_driver_info_via_email_before": "予約確認のメール / SMS を顧客に送信",
        "Confirm_driver_info_via_email_before_hint": "システムは設定時間に基づき、ピックアップ時間前に予約確認を顧客へ送信します",
        "Show_request_tab_on_driver_app": "ドライバーアプリに「リクエスト」タブを表示",
        "Show_preferred_tab_on_driver_app": "ドライバーアプリに「優先」タブを表示",
        "Sound_notification": "サウンド通知",
        "Advance_information": "法人ユーザー向けの詳細情報",
        "Load_general_fail": "一般設定の読み込み中にエラーが発生しました",
        "Update_general_success": "一般設定が正常に更新されました",
        "Update_general_fail": "一般設定の更新中にエラーが発生しました",
        "Time_over_lap": "時間範囲が重複しないようにしてください",
        "Allow_passenger_to_register_as_corp_traveler": "乗客が法人ユーザーとして登録できるようにする",
        "Not_Allow_Make_Booking_Without_Assign_Fare": "運賃が設定されていない場合、PWAおよびアプリに車種を表示しない（v4.6.60以降）",
        "Not_Allow_show_car_type_on_cc": "運賃が設定されていない場合、CCに車種を表示しない",
        "Disable_Direct_Invoicing_Receipt_Email": "直接請求の領収書メールを無効化",
        "Additional_services": "追加サービス",
        "Edit_additional_services": "追加サービスを編集",
        "Service_Fee_By_Currencies": "サービス料",
        "Service_type": "サービスの種類",
        "Optional": "任意",
        "Compulsory": "必須",
        "Vehicle_type": "車両タイプ",
        "Adding_additional_service_success": "新しいサービスが正常に作成されました",
        "Update_additional_service_success": "サービスが正常に更新されました",
        "Delete_additional_service_success": "サービスが正常に削除されました",
        "Adding_additional_service_fail": "サービスの追加に失敗しました",
        "Update_additional_service_fail": "サービスの更新に失敗しました",
        "Other_settings": "その他の設定",
        "Operator_assign_error": "オペレーターの割り当て時にエラーが発生しました",
        "Create_surcharge_success": "新しい追加料金が正常に作成されました",
        "Update_surcharge_success": "追加料金が正常に更新されました",
        "Delete_surcharge_success": "追加料金が正常に削除されました",
        "Create_surcharge_fail": "追加料金の作成に失敗しました",
        "Update_surcharge_fail": "追加料金の更新に失敗しました",
        "Delete_surcharge_fail": "追加料金の削除に失敗しました",
        "Get_surcharge_detail_fail": "追加料金の詳細を取得できませんでした",
        "Repeat": "繰り返し",
        "DayOfWeek": "曜日",
        "SingleDay": "単日",
        "Yearly": "毎年",
        "Backup_phone_number": "バックアップ番号",
        "Backup_phone_number_hint": "ドライバーと乗客が運行ゾーン外にいる場合、この番号に転送",
        "Apply_countdown_clock": "カウントダウンクロックを適用",
        "No_show_duration_tooltip": "ドライバーアプリで「到着」ボタンをタップした後、ノーショーボタンを表示する時間を設定",
        "Limit_radius": "制限半径",
        "Limit_radius_tooltip": "ドライバーはこの半径内でノーショーとして予約を報告可能",
        "Limit_radius_arrive_pickup": "ドライバーが到着して輸送予約のピックアップを行える半径を制限",
        "Booking_validation": "予約の検証",
        "Invalid_period_of_time": "無効な時間帯",
        "hide_Provider_Filter_From_Corporate_Board": "法人ボードからプロバイダーフィルターを非表示",
        "customer_email_is_optional": "乗客アプリで顧客のメールアドレスを任意に設定",
        "disable_hourly_rate": "時間料金と固定料金の両方が利用可能な場合、時間料金を無効化",
        "customer_gender_is_compulsory": "乗客アプリで顧客の性別を必須項目に設定",
        "SHOW_MAP_STATUS": "マップステータスを表示",
        "Pay_to": "支払い先",
        "EMERGENCY_SOS": "緊急S.O.S",
        "customer_address_is_compulsory": "乗客アプリで顧客の住所を必須項目に設定",
        "customer_id_is_compulsory": "乗客アプリで顧客のID# / 国民IC#を必須項目に設定",
        "allowPassengerToUploadDocument": "乗客がID# / 国民IC#の書類をアップロード可能にする",
        "compulsoryNationalIcDocumentInPaxApp": "乗客アプリで顧客のID# / 国民IC#の書類を必須項目に設定",
        "doNotAllowAddDriverWhenNationalIdDuplicated": "オペレーターが国民IDが重複している場合、ドライバーを追加できないようにする",
        "forceCustomerToUseLiveLocation": "顧客にライブロケーションの使用を強制",
        "disableMarketPlace": "新規ドライバーのマーケットプレイスを無効化",
        "dontShowSecondStageImmediately": "ステージ2のサインアップを非表示",
        "compulsoryCarInsurance": "ドライバーの車両保険を必須に設定",
        "marketPlace": "マーケットプレイス",
        "Enable": "有効化",
        "Disable": "無効化",
        "showTotalFareWithoutPromotionOnDriverApp": "ドライバーアプリにプロモーションなしの合計運賃を表示",
        "showTotalFareWithoutPromotionOnDriverApp_hint": "輸送：この設定はドライバーが支払いを行う前に適用 <br/> - 配送＆都市間：この設定はドライバーが仕事を開始する前に適用",
        "hideFareOnPaxAppForAllCorporateBookings": "法人予約では乗客アプリに運賃を非表示",
        "hidePaxNameAvatarOnDispatchScreen": "配車画面で顧客の名前とアバターを非表示",
        "showFullCustomerNameOnDriverApp": "ドライバーアプリで顧客のフルネームを表示",
        "hideDriverLogoutBtn": "ドライバーアプリのログアウトボタンを非表示",
        "showMeetAndGreetButtonForEveryBooking": "全ての予約で「ミート＆グリート」ボタンを表示",
        "settings": "設定",
        "Default_value": "デフォルト値",
        "limitTopupAmount": {
            "valueByCurrencies": "警告金額",
            "minimumByCurrencies": "最小チャージ金額",
            "maximumByCurrencies": "最大チャージ金額",
            "title": "最小・最大チャージ金額"
        },
        "driverApp": "ドライバーアプリ",
        "passengerApp": "乗客アプリ",
        "Default_Topup_Values": "デフォルトのチャージ金額",
        "service_fees": "サービス料金",
        "commission_fleet": "デフォルトのフリート手数料",
        "apply_all": "すべての運行ゾーンで同じ",
        "apply_custom_zone": "運行ゾーンごとに異なる",
        "apply": "適用",
        "tollFeeDriverCanInput": "ドライバーが入力できる金額の制限",
        "unlimited": "無制限",
        "limited": "制限付き",
        "sameFee": "同じ料金",
        "customizedAirportfee": "カスタマイズ空港料金",
        "noteAirportFeeModal": "注：空港料金はリストにある空港にのみ適用",
        "intercity_schedule_trip_time_range": "予約時間の時系列順を確保するため、優先/リクエスト予約の時間範囲がスケジュール予約より長くなるようにしてください",
        "airportFee": {
            "tooltipApply": "デフォルトの空港料金は、空港発着の各予約に適用されます。カスタマイズされた場合は、その場所の新しい料金が適用されます",
            "titleSearchAiportZone": "空港を検索し、料金を追加してください",
            "airport": "空港",
            "currency": "通貨",
            "fromAirportFee": "空港からの料金",
            "toAirportFee": "空港への料金",
            "action": "アクション"
        },
        "Yes": "はい",
        "No": "いいえ",
        "tipPaymentMethod": "顧客がドライバーへのチップとして利用できる支払い方法",
        "allowPaxUpdateTip": "乗客が乗車中にチップの割合を調整できるようにする",
        "tipDefaultValues": "ライド後のデフォルトチップ値",
        "creadit": "クレジットカード（個人カード / 法人カード）",
        "wallet": "ウォレット",
        "tnGeWallet": "TnG eWallet",
        "vippseWallet": "Vipps",
        "DurationRegularBooking": "無料待機時間",
        "googlePay": "Google Pay",
        "applePay": "Apple Pay",
        "DurationRegularBookingToolTip": "これはドライバーが顧客を待つための無料待機時間です。この時間が経過するまでは予約をノーショーとして記録できません。",
        "noShowCountDown": {
            "customizedNoShow": "特定の場所（例：空港など）でのカスタマイズ",
            "customizedNoShowTitle": "特定の場所（例：空港など）でノーショーカウントダウン時間を適用"
        },
        "maxWaitTime": "最大追加待機時間",
        "maxWaitTimeToolTip": "これは顧客に請求される最大追加待機時間です。分単位の料金は料金設定でカスタマイズ可能です。",
        "maxWaitTimePlaceholder": "最大請求待機時間",
        "HEAT_MAP": "ヒートマップ",
        "heatMap": {
            "defaultMode": "デフォルトモード",
            "radius": "半径",
            "intensity": "強度",
            "opacity": "不透明度",
            "requestedBookings": "リクエスト予約",
            "activePassengers": "アクティブな乗客",
            "completedBookings": "完了した予約",
            "timeoutBookings": "タイムアウトした予約",
            "tooltipRadius": "各データポイントの影響半径（ピクセル単位）",
            "tooltipIntensity": "ヒートマップの最大強度",
            "tooltipOpacity": "ヒートマップの不透明度（0から1の間の数値）"
        },
        "schedule_trip": "スケジュール予約",
        "driver_starts_trip_before_set_time": "ドライバーが設定時間前に出発",
        "request_trip": "リクエスト予約",
        "preferred_trip": "優先予約",
        "time_range": "リクエスト予約と優先予約の時間範囲",
        "working_time": "稼働時間",
        "hour": "時間",
        "hours": "時間",
        "minutes": "分",
        "indicate_pickup_time_in_advance_to_customer": "顧客に事前にピックアップ時間を知らせる",
        "customers_can_select_start_time_and_request_intercity_trip": "顧客は開始時間を選択して都市間予約をリクエスト可能。ドライバーも自分のスケジュールに合わせた優先予約を作成可能。例: 1時間の時間範囲を設定すると、8:00～9:00、9:00～10:00、10:00～11:00のように都市間予約を作成可能。",
        "this_setting_limits_customers_to_create_request_trip_within_working_hours_only": "この設定により、リクエスト予約は営業時間内のみ作成可能になります。",
        "face_mask_verify": "マスク着用確認",
        "faceMask_allZones": "すべてのゾーン",
        "faceMask_customized": "カスタマイズゾーン",
        "turn_on_verify": "確認を有効にする",
        "force_to_wear_face_mask": "マスク着用を強制",
        "time_range_to_verify": "次回確認までの期間",
        "customizedFaceMaskZone": "カスタマイズマスク確認",
        "zoneOperation": "運行ゾーン",
        "FaceMaskCustomized": "カスタマイズ",
        "time": "時間",
        "day": "日",
        "days": "日数",
        "allow_driver_to_skip_verification_after": "ドライバーが確認をスキップできるようにする",
        "driver_need_to_verify": "ドライバーが確認する必要があるタイミング",
        "open_app": "アプリ起動時",
        "start_reservation": "予約開始時",
        "choose_at_least_one": "少なくとも1つのアクションを選択する必要があります。",
        "face_mask_customized_fail": "少なくとも1つのアクションを選択する必要があります ({0})。",
        "vehicleAgeLimit": "車両年齢制限",
        "vehicleAgeLimit_years": "年",
        "founderFund_firstBooking": "創業者ファンド：初回予約のみ",
        "distance_diversity": "距離の多様性",
        "Add_Surcharge_Distance": "追加",
        "from": "開始",
        "to": "終了",
        "surchargeValue": "追加料金",
        "actions": "アクション",
        "please_enter_distance_range_valid": "距離 %{name} が無効です！",
        "please_enter_distance_to": "距離 \"終了\" を入力してください。",
        "please_enter_surchargeValue": "距離範囲 %{name} の追加料金を入力してください。",
        "please_enter_surchargeValue_is_number": "距離範囲 %{name} の追加料金に有効な数値を入力してください。",
        "Update_service_fee_success": "サービス料金が正常に更新されました。",
        "Update_service_fee_fail": "サービス料金の更新中にエラーが発生しました。",
        "Update_fleet_commission_success": "デフォルトのフリート手数料が正常に更新されました。",
        "Update_fleet_commission_fail": "デフォルトのフリート手数料の更新中にエラーが発生しました。",
        "RESERVATION_POLICY": "予約ポリシー",
        "default_expected_pickup_time": "デフォルトのピックアップ予定時間",
        "default_expected_pickup_time_hint": "ドライバーがピックアップ時間からどれくらい前に到着することを期待するかのデフォルト値",
        "same_original_pickup_time": "元のピックアップ時間と同じ",
        "quarter_before": "15分前",
        "half_before": "30分前",
        "quarter_after": "15分後",
        "half_after": "30分後",
        "custom": "カスタム",
        "min_before": "分前",
        "min_after": "分後",
        "TransactionFee": "取引手数料",
        "creditCardPercent": "クレジットカード/プリペイド",
        "directBilling": "直接請求",
        "externalCard": "外部カード"
    },
    "social": {
        "pax_app": "乗客アプリ",
        "driver_app": "ドライバーアプリ",
        "default": "デフォルト",
        "customized": "カスタマイズ",
        "html": "HTML",
        "about": "概要",
        "email": "メールサポート",
        "enablePhone": "電話番号",
        "phone": "",
        "policy": "プライバシーポリシーのリンク",
        "termOfUse": "利用規約のリンク",
        "site": "ウェブサイト",
        "logoDriverIOS": "ロゴ - iOS",
        "logoDriverAndroid": "ロゴ - Android",
        "htmlUrl": "HTMLファイル",
        "Update_fail": "ソーシャル更新中にエラーが発生しました",
        "Update_success": "ソーシャルが正常に更新されました"
    },
    "user": {
        "Show_per_page": "ページあたりの項目数",
        "Page": "ページ",
        "Search_here": "ここで検索",
        "show_pages": "{0} - {1} / {2} 表示",
        "Username": "ユーザー名",
        "Name": "名前",
        "Phone_number": "電話番号",
        "Email": "メールアドレス",
        "Permission": "権限",
        "Status": "ステータス",
        "Actions": "アクション",
        "Active": "アクティブ",
        "Inactive": "非アクティブ",
        "Edit": "編集",
        "Deactivate": "無効化",
        "Delete": "削除",
        "Add": "追加",
        "ADD_USER": "ユーザーを追加",
        "First_name": "名",
        "Last_name": "姓",
        "AgendId": "ユーザー番号",
        "Email_address": "メールアドレス",
        "Address": "住所",
        "Required_fields": "必須項目",
        "Save": "保存",
        "Cancel": "キャンセル",
        "EDIT_USER": "ユーザーを編集",
        "Yes": "はい",
        "DELETE_USER": "ユーザーを削除",
        "DETAIL_USER": "ユーザー詳細",
        "DELETE_CONFIRM": "選択したユーザーを削除してもよろしいですか？",
        "Update_user_fail": "ユーザー更新中にエラーが発生しました",
        "Update_user_success": "ユーザーが正常に更新されました",
        "Create_user_fail": "ユーザー作成中にエラーが発生しました",
        "Create_user_success": "アカウント {0} が正常に作成されました。ログイン情報の詳細はメール {1} を確認してください。",
        "Load_user_fail": "ユーザー読み込み中にエラーが発生しました",
        "Update_user_status_fail": "ユーザーステータス更新中にエラーが発生しました",
        "Delete_user_fail": "ユーザー削除中にエラーが発生しました",
        "Delete_user_success": "ユーザーが正常に削除されました",
        "CONFIRM_RESET_PASSWORD": "パスワードをリセットしますか？",
        "RESET_PASSWORD": "パスワードをリセットしますか？",
        "Reset_password": "パスワードをリセット",
        "Select_permission": "権限を選択",
        "Reset_password_success": "パスワードを正常にリセットしました"
    },
    "report": {
        "query": {
            "errorMessage": {
                "bookFrom": "予約元を選択してください。",
                "paymentMethod": "支払い方法を選択してください。",
                "bookingType": "予約タイプを選択してください。",
                "ratingType": "評価タイプを選択してください。",
                "stars": "評価タイプを選択してください。",
                "type": "種類",
                "transactionType": "取引タイプを選択してください。",
                "serviceType": "サービス種類を選択してください。",
                "rideService": "ライドサービスを選択してください。",
                "invalidEmail": "有効なメールアドレスを入力してください。"
            },
            "search": {
                "driverReferalPax": "検索: ドライバー名、電話番号",
                "passengerRefersPassengers": "検索: 顧客名、電話番号",
                "driverReferalPaxDetail": "検索: 乗客名",
                "bookingDetails": "検索：予約ID、オペレーター、顧客電話番号、顧客名、ドライバー名、ID#/国民ID番号、ナンバープレート、待機エリア、ノート、トリップ、サポートID",
                "financial": "検索: ドライバー名、電話",
                "completedTime": "完了時間",
                "collectedTime": "回収時間",
                "financialDriver": "検索: 予約ID、ドライバー名、ID# / 国民IC#",
                "carActivity": "検索: ナンバープレート",
                "affiliation": "検索: 予約ID",
                "prepaidTopUp": "検索: 会社名、チャージID、取引ID",
                "operatorLogs": "検索: オペレーター名、ユーザー名、説明",
                "corporate": "検索: 予約ID、ドライバー名、法人ユーザー、クライアントケース、チャージコード",
                "bookingLogs": "検索: 予約ID、顧客名、サポートID、ドライバー名、ID# / 国民IC#",
                "rating": "検索: 予約ID、顧客電話番号、顧客名、ナンバープレート",
                "mDispatcher": "検索: 予約ID、パートナー名",
                "totalAdjustment": "検索: 予約ID",
                "incidentCancellation": "検索: 予約ID、顧客電話番号、サポートID、顧客名",
                "acceptanceCancellationRate": "検索: ドライバー名、電話番号",
                "revenueDriver": "検索: ドライバー名",
                "dailyDriver": "検索: ドライバー電話番号、ドライバー名、ID# / 国民IC#",
                "dailyCar": "検索: ナンバープレート、権限",
                "dailyDriverCar": "検索：ドライバー電話番号、ドライバー名、ID#/国民ID番号、ナンバープレート",
                "driverLoginStatus": "検索：ドライバー電話番号、ドライバー名、ID#/国民ID番号、ナンバープレート",
                "bookingSummary": "検索: オペレーター名、ユーザー名、予約ID",
                "creditWallet": "検索: 会社名、ドライバー名、予約ID、取引ID、オペレーター",
                "cashWallet": "検索: 会社名、ドライバー名、予約ID、電話番号",
                "settlement": "検索: ドライバー名、ID# / 国民IC#",
                "driverRating": "検索: 予約ID、顧客電話番号、顧客名、ナンバープレート、サポートID",
                "driverWithdrawal": "ドライバー名、電話、会社、取引ID、参照番号",
                "penaltyCompensation": "検索: 予約ID、ドライバー名、乗客名",
                "incompletePayment": "検索: 予約ID、サポートID、顧客名",
                "passengerWallet": "検索: 予約ID、取引ID、顧客名",
                "driverDeposit": "検索: 会社名、ドライバー名、予約ID、オペレーター",
                "refund": "検索: 予約ID、トリップID、サポートID、顧客名",
                "driverRefersCustomerBookings": "検索: 紹介コード、サポートID",
                "driverHistory": "検索: 出金ID",
                "merchantHistory": "検索: 出金ID",
                "firstWillWin": "検索: 顧客名、顧客電話番号、顧客ID",
                "firstWillWinDriver": "検索: ドライバー名、ドライバー電話番号、ユーザー名",
                "merchant": "検索: 店舗",
                "merchantCashTransactionHistory": "検索: 予約ID、取引ID、加盟店名",
                "merchantCreditTransactionHistory": "検索: 予約ID、取引ID、加盟店名",
                "deleteAccountRequest": "検索: 電話番号",
                "affiliationPayoutHistory": "検索: 支払いID",
                "thirdPartyBooking": "検索: 予約ID、外部ID"
            },
            "common": {
                "all": "すべて"
            },
            "today": "今日",
            "sendDownloadLink": "受取人のメールアドレス",
            "generaltingYourExcel": "エクセルファイルの生成には時間がかかる場合があります。準備ができ次第、メールでお送りします。",
            "messageSuccess": "ファイル処理中。準備ができ次第、メールでお知らせします。レポート/エクスポート履歴から進捗を確認または直接ダウンロードできます。",
            "thisMonth": "今月",
            "lastMonth": "先月",
            "past30Days": "過去30日間",
            "thisYear": "今年",
            "custom": "カスタム",
            "customize": "カスタマイズ",
            "thisWeek": "今週",
            "past7Days": "過去7日間",
            "weekly": "週間",
            "monthly": "月間",
            "allTime": "すべての期間",
            "tomorrow": "明日",
            "nextWeek": "来週",
            "lastWeek": "先週",
            "nextMonth": "来月",
            "yesterday": "昨日",
            "fromDate": "開始日",
            "toDate": "終了日",
            "driver": "ドライバー: %{name}",
            "customer": "顧客: %{name}",
            "referralCode": "紹介コード: %{name}",
            "referer": "紹介者: %{name}",
            "referee": "被紹介者: %{name}",
            "usedPromoCode": "プロモーション: %{name}",
            "voucherCode": "コード: %{name}",
            "city": "都市",
            "company": "会社",
            "company_report": "会社: %{name}",
            "corporate_report": "法人：%{name}",
            "campaign_report": "キャンペーン: %{name}",
            "operator": "オペレーター",
            "farmType": "農場タイプ",
            "carLicensePlate": "車両ナンバープレート: %{name}",
            "settlementType": "タイプ",
            "bookingService": "ネットワークタイプ",
            "payoutOption": "支払いオプション",
            "currency": "通貨",
            "bookingFrom": "予約元",
            "thirdPartyAction": "アクション",
            "paymentMethod": "支払い方法",
            "paymentStatus": "支払い状況",
            "bookingType": "予約タイプ",
            "adjustPrice": "価格調整",
            "intercityRoute": "都市間ルート",
            "ratingType": "評価タイプ",
            "stars": "評価",
            "driverRating": "ドライバー評価",
            "vehicleRating": "車両評価",
            "mDispatcherType": "パートナータイプ",
            "mDispatcher": "パートナー名",
            "campaign": "キャンペーン",
            "promocode": "プロモコード",
            "canceledBy": "キャンセル者",
            "timezone": "タイムゾーン",
            "month": "月",
            "year": "年",
            "view": "表示",
            "action": "アクション",
            "status": "ステータス",
            "module": "モジュール",
            "driverType": "タイプ",
            "vehicleType": "車両タイプ",
            "serviceType": "サービスタイプ",
            "expireDate": "有効期限",
            "rideSharing": "ライドシェア",
            "rideService": "ライドサービス",
            "transactionType": "取引タイプ",
            "completeFromCC": "CCからの完了",
            "withDrawalTransactionType": "取引タイプ",
            "bookingStatus": "予約状況",
            "withdrawalStatus": "ステータス",
            "originalStatus": "元の支払い状況",
            "currentStatus": "現在の支払い状況",
            "tip": "チップ",
            "type": "タイプ",
            "userType": "ユーザータイプ",
            "eventType": "イベントタイプ",
            "eventState": "イベント状態",
            "eventName": "イベント名",
            "merchantType": "加盟店タイプ",
            "merchantService": "加盟店サービス",
            "storeName": "店舗",
            "merchant": "加盟店",
            "event": "イベント名: %{name}",
            "store": "店舗名: %{name}",
            "selectedMerchant": "加盟店: %{name}",
            "associatedApps": "関連アプリ",
            "settlement": "決済",
            "rideServiceItem": {
                "all": "すべて",
                "regulation": "規制",
                "rideSharing": "ライドシェア"
            },
            "actionItem": {
                "all": "すべて",
                "login": "ログイン",
                "logout": "ログアウト",
                "bookIn": "利用可能",
                "bookOff": "利用不可",
                "deny": "拒否",
                "ignore": "無視",
                "accept": "承認",
                "startTrip": "向かっています",
                "updateBooking": "更新",
                "arrive": "到着",
                "pickup": "乗車",
                "drop": "降車",
                "complete": "完了",
                "noShow": "ノーショー",
                "rqJob": "配車中",
                "cancel": "キャンセル"
            },
            "loginStatusItem": {
                "all": "すべて",
                "online": "ログイン中",
                "offline": "未ログイン"
            },
            "payoutType": "支払いタイプ",
            "affiliationPayoutTypeItem": {
                "auto": "自動支払い",
                "manual": "手動支払い"
            },
            "affiliationSettlementItem": {
                "payable": "支払可能",
                "receivable": "受取可能"
            },
            "affiliationPaymentMethod": {
                "online": "オンライン",
                "directInvoicing": "直接請求"
            },
            "payoutPaymentStatusItem": {
                "paid": "支払い済み",
                "pending": "保留中"
            },
            "farmTypeItem": {
                "all": "すべて",
                "farmIn": "受け入れ",
                "farmOut": "送出",
                "roaming": "ローミング",
                "affiliationOwner": "提携オーナー",
                "farmInAndAffiliationOwner": "受け入れおよび提携オーナー",
                "farmOutAndAffiliationOwner": "送出および提携オーナー"
            },
            "settlementDriverTypeItem": {
                "all": "すべてのドライバー",
                "individual": "会社車両"
            },
            "shortTrip": {
                "all": "すべて",
                "shortTrip": "短距離移動",
                "nonShortTrip": "非短距離移動"
            },
            "paymentMethodItem": {
                "B2BTerminal": "B2Bターミナル",
                "webBooking": "ウェブ予約",
                "pendingPayment": "支払い保留中",
                "all": "すべて",
                "cash": "現金",
                "cashBySender": "送信者による現金支払い",
                "cashByRecipient": "受取人による現金支払い",
                "personalCard": "個人カード",
                "corporateCard": "法人カード",
                "qrCodePayment": "QRコード決済",
                "directBilling": "直接請求",
                "externalCard": "外部カード",
                "mDispatcherCard": "パートナーカード",
                "corporatePrepaid": "法人プリペイド",
                "canceled": "キャンセル済み",
                "noShow": "ノーショー",
                "incident": "インシデント",
                "fleetCard": "外部カード",
                "applePay": "Apple Pay",
                "creditCardExternal": "クレジットカード",
                "paxWallet": "ウォレット",
                "tngeWallet": "TnG eWallet",
                "vippseWallet": "Vipps",
                "zainCash": "ZainCash",
                "xendit": "Xendit",
                "FIB": "FIB",
                "SantimPay": "SantimPay",
                "gCash": "GCash",
                "completedWithoutService": "ドライバーなしで完了",
                "BankOfGeorgia": "ジョージア銀行",
                "partialCompleted": "部分完了",
                "failed": "失敗",
                "googlePay": "Google Pay",
                "paymentLink": "支払いリンク",
                "easyPaisa": "Easy Paisa",
                "jazzCash": "Jazz Cash"
            },
            "paymentStatusItem": {
                "all": "すべて",
                "fullPayment": "全額支払い",
                "partialPayment": "部分支払い",
                "pendingPayment": "支払い保留中",
                "partialRefund": "部分返金",
                "fullRefund": "全額返金"
            },
            "companySettlementTypeItem": {
                "all": "すべて",
                "fleetOweCompany": "フリートが会社に負債",
                "companyOweFleet": "会社がフリートに負債"
            },
            "bookingStatusItem": {
                "all": "すべて",
                "completed": "完了",
                "incident": "インシデント",
                "paxNoShow": "乗客ノーショー",
                "driverNoShow": "ドライバーノーショー",
                "rejected": "拒否済み",
                "canceledByTimeOut": "タイムアウトによるキャンセル",
                "canceledByTimeOutRejected": "タイムアウトによるキャンセル（拒否済み）",
                "canceledByBookingBoard": "予約ボードによるキャンセル",
                "canceledByCustomer": "顧客によるキャンセル",
                "canceledBySupplier": "サプライヤーによるキャンセル"
            },
            "bookingFromItem": {
                "all": "すべて",
                "paxApp": "乗客アプリ",
                "mDispatcher": "パートナー",
                "kiosk": "キオスク",
                "cc": "コマンドセンター",
                "webBooking": "ウェブ予約",
                "carHailing": "カー・ハイリング",
                "streetSharing": "ストリートシェアリング",
                "dashboard": "予約ボード",
                "corp": "予約ボード（CTM）",
                "dmc": "予約ボード（DMC）",
                "Boost_web_link": "ブーストウェブリンク"
            },
            "thirdPartyActionItem": {
                "all": "すべて",
                "searched": "検索済み",
                "allocated": "割り当て済み",
                "updated": "更新済み",
                "cancelled": "キャンセル済み"
            },
            "bookingTypeItem": {
                "batchDelivery": "バッチ配送",
                "all": "すべて",
                "now": "即時（輸送）",
                "reservation": "予約（輸送）",
                "intercity": "都市間",
                "delivery": "配送",
                "shuttle": "シャトル"
            },
            "adjustPrices": {
                "all": "すべて",
                "normalPrice": "通常価格",
                "addonPrice": "追加料金価格"
            },
            "bookingServiceItem": {
                "all": "すべて",
                "roaming": "ローミング",
                "homeFleet": "送出",
                "localBooking": "社内",
                "farmIn": "受け入れ",
                "farmInAndAffiliationOwner": "受け入れ＆提携オーナー",
                "provideService": "受け入れ"
            },
            "ratingTypeItem": {
                "all": "すべて",
                "like": "いいね",
                "dislike": "よくないね"
            },
            "starsItem": {
                "all": "すべて",
                "star1": "1つ星",
                "star2": "2つ星",
                "star3": "3つ星",
                "star4": "4つ星",
                "star5": "5つ星"
            },
            "incidentCanceledTypes": {
                "all": "すべて",
                "incident": "インシデント",
                "canceled": "キャンセル済み"
            },
            "canceledByItem": {
                "all": "すべて",
                "CorpAD": "法人管理者",
                "passenger": "乗客",
                "driver": "ドライバー",
                "merchant": "加盟店",
                "CC": "CC",
                "mDispatcher": "パートナー",
                "Partner": "パートナー",
                "webBooking": "ウェブ予約",
                "timeout": "タイムアウト"
            },
            "bookingSummaryActionItem": {
                "all": "すべて",
                "create": "作成",
                "update": "更新",
                "cancel": "キャンセル",
                "complete": "完了",
                "incident": "インシデント"
            },
            "transactionTypeItem": {
                "bookingAdjustment": "予約調整",
                "bankAccount": "銀行口座への振込",
                "sentToCustomer": "顧客へ送信",
                "receivedFromDriver": "ドライバーから受領",
                "sentToDriver": "ドライバーへ送信",
                "creditWallet": "クレジットウォレットへの振込",
                "netEarning": "純収益",
                "referralEarning": "紹介収益",
                "all": "すべて",
                "topUp": "チャージ",
                "editBalance": "残高編集",
                "bookingDeduction": "予約控除",
                "bookingPromo": "予約プロモーション",
                "topUpGCash": "GCashによるチャージ",
                "cashWallet": "現金ウォレット",
                "topUpMOLPay": "Razer Payによるチャージ",
                "topUpZainCash": "ZainCashによるチャージ",
                "topUpXendit": "Xenditによるチャージ",
                "topUpFIB": "FIBによるチャージ",
                "topUpSantimPay": "SantimPayによるチャージ",
                "topUpTelebirr": "Telebirrによるチャージ",
                "topUpKsher": "Ksherによるチャージ",
                "topUpWallet": "{0}によるチャージ",
                "TnG": "TnGによるチャージ",
                "credit": "クレジットカードによるチャージ",
                "ride": "ライド",
                "tipAfterCompleted": "ライド後のチップ",
                "debtWriteOff": "負債免除",
                "refund": "返金",
                "excessOffsetAmount": "過剰現金クレジット",
                "excessDeductionAmount": "過剰現金デビット",
                "cashExcess": "過剰現金デビット",
                "gateway": "ゲートウェイからのチャージ",
                "driverTopUpForPax": "過剰現金デビット",
                "updatedBalance": "更新済み残高",
                "partialEarning": "収益",
                "payout": "支払い",
                "payoutManual": "手動支払い",
                "payoutDriver": "支払い",
                "itemValue": "アイテム価値",
                "redeemVoucher": "コード#引換",
                "topUpReferrer": "紹介報酬",
                "driverInsurance": "ドライバー保険",
                "cancellationPenalty": "キャンセルペナルティ",
                "WingBank": "Wingbank口座への振込",
                "customerDebt": "顧客負債",
                "googlePay": "Google Payによるチャージ",
                "applePay": "Apple Payによるチャージ",
                "merchantCommission": "加盟店手数料",
                "eWallet": "外部電子ウォレットに送金",
                "reversed": "引き出しが取り消されました"
            },
            "moduleItem": {
                "all": "すべて",
                "bookings": "予約",
                "customers": "顧客",
                "dispatch": "配車",
                "general": "一般",
                "promotion": "プロモーション",
                "quest": "クエスト",
                "rate": "レート",
                "car": "車両",
                "city": "都市",
                "company": "会社",
                "drivers": "ドライバー",
                "driverSettlement": "ドライバー決済",
                "login": "ログイン",
                "logout": "ログアウト",
                "mDispatcher": "パートナー",
                "mDispatcherType": "パートナータイプ",
                "operation": "運用",
                "partners": "パートナー",
                "permission": "権限",
                "queuingArea": "待機エリア",
                "report": "レポート",
                "shift": "シフト",
                "user": "ユーザー",
                "voiceSms": "音声＆SMS",
                "intercityZone": "ゾーン（都市間）",
                "Payout": "支払い",
                "code#": "コード#",
                "ApiManagement": "API管理"
            },
            "serviceTypeItem": {
                "all": "すべて",
                "oneWay": "片道",
                "fromAirport": "空港発",
                "toAirport": "空港着",
                "hourly": "時間・日単位",
                "roundTrip": "往復",
                "fromAirportHourlyDaily": "空港発 - 時間・日単位",
                "fromAirportRoundTrip": "空港発 - 往復",
                "toAirportHourlyDaily": "空港着 - 時間・日単位",
                "toAirportRoundTrip": "空港着 - 往復"
            },
            "merchantTransactionTypeItem": {
                "all": "すべて",
                "editBalance": "残高編集",
                "payout": "支払い",
                "earning": "収益",
                "itemValue": "アイテム価値",
                "orderDeduction": "注文控除"
            },
            "withdrawalTransactionType": {
                "WingBank": "Wingbank口座への振込",
                "bankAccount": "銀行口座への振込",
                "cashWallet": "現金ウォレットへの振込",
                "creditWallet": "クレジットウォレットへの振込"
            },
            "payoutDriver": {
                "driversHaveBankAccount": "銀行口座を持つドライバー",
                "driversDontHaveBankAccount": "銀行口座を持たないドライバー",
                "payoutEndTime": "支払い終了時間",
                "driverHaveInvalidBankAccount": "無効な銀行口座を持つドライバー"
            },
            "payoutMerchant": {
                "HaveBankAccount": "銀行口座を持つ加盟店",
                "DontHaveBankAccount": "銀行口座を持たない加盟店",
                "HaveInvalidBankAccount": "無効な銀行口座を持つ加盟店",
                "payoutEndTime": "支払い終了時間"
            }
        },
        "error": {
            "23005": "システムパフォーマンスのため、レポートは%{interval} %{unitLabel}単位でのみ表示できます。日付範囲を調整してください。"
        },
        "result": {
            "idPermitType": "IDタイプ",
            "noResultFound": "結果が見つかりません",
            "page": "ページ",
            "itemsPerPage": "ページごとのアイテム数",
            "showPages": "%{from} - %{to} / %{total} を表示中",
            "exportToExcel": "Excelにエクスポート",
            "exportToCSV": "CSVにエクスポート",
            "exportToPaymentFile": "支払いファイルにエクスポート",
            "pay": "支払い",
            "paySelected": "選択したものを支払い",
            "payAll": "すべて支払い",
            "invalidBankAccount": "無効な銀行口座",
            "validBankAccount": "有効な銀行口座",
            "bookingDetails": {
                "supplierPayout": "サプライヤー支払い",
                "driverPayout": "ドライバー支払い",
                "profit": "利益",
                "customerAdjustmentsFee": "顧客調整",
                "supplierDriverAdjustmentsFee": "サプライヤー/ドライバー調整",
                "successSendMessage": "メッセージが送信されました",
                "packageName": "パッケージ名",
                "transactionId": "取引ID",
                "proofOfFailure": "失敗の証拠",
                "recipientsNumber": "受取人数",
                "recipientsInfo": "受取人情報",
                "creditTransactionFee": "クレジットカード手数料",
                "carTypeRequest": "リクエストされた車種",
                "bookingId": "予約#",
                "bookingStatus": "予約ステータス",
                "tripId": "トリップ#",
                "bookFrom": "予約元",
                "bookingService": "ネットワークタイプ",
                "bookingType": "予約タイプ",
                "serviceType": "サービスタイプ",
                "operator": "オペレーター",
                "customerPhone": "乗客の電話番号",
                "customerName": "乗客の名前",
                "customerIdNumber": "乗客ID# / IC#",
                "corpDivision": "部門",
                "corpDept": "部署",
                "chargeCode": "チャージコード",
                "tripDescription": "トリップの説明",
                "rideSharing": "ライドシェア",
                "queueName": "待機エリア",
                "bookingTime": "作成時間",
                "pickupTime": "ピックアップ時間",
                "expectedPickupTime": "予定ピックアップ時間",
                "dispatchTime": "配車時間",
                "onBoardTime": "乗車時間",
                "droppedOffTime": "降車時間",
                "onBoardDuration": "乗車時間の長さ",
                "dispatch": "配車番号",
                "completedTime": "完了時間",
                "driverName": "ドライバー名",
                "driverIdPermit": "ID# / 国民IC#",
                "driverLicenseNumber": "運転免許番号",
                "companyName": "会社",
                "carType": "車種",
                "licensePlate": "車両ナンバープレート",
                "intercityRoute": "都市間ルート",
                "pickupLocation": "ピックアップ地点",
                "geoPickup": "ピックアップ地点緯度/経度",
                "extraDestination": "追加目的地",
                "originDestination": "元の目的地",
                "originEstDistance": "元の推定距離",
                "destination": "実際の目的地",
                "geoDestination": "実際の目的地緯度/経度",
                "pickupFrom": "ピックアップ地点（元）",
                "distanceTour": "P.O.B距離",
                "internalNotes": "内部メモ",
                "notes": "メモ",
                "basicFareCalculator": "基本運賃計算機",
                "basicFare": "基本運賃",
                "editFare": "基本運賃を編集",
                "oldBasicFare": "旧基本運賃",
                "airportFee": "空港料金",
                "meetDriver": "ドライバーと会う",
                "rushHour": "追加料金",
                "surchargeParameter": "動的追加料金",
                "dynamicFare": "動的運賃",
                "heavyTraffic": "渋滞料金",
                "tollFee": "通行料",
                "parkingFee": "駐車料金",
                "gasFee": "ガソリン代",
                "otherFees": "その他料金",
                "subtotal": "小計",
                "subTotalFF": "小計 FF",
                "techFee": "技術料金",
                "serviceFee": "追加サービス",
                "fleetServiceFee": "フリートサービス",
                "bookingFee": "予約料金",
                "salesTax": "消費税",
                "tipAmt": "チップ金額",
                "promoCode": "プロモコード",
                "promoAmt": "プロモ金額",
                "requestedTotalAmount": "リクエスト合計金額",
                "total": "合計金額",
                "totalNotRounded": "四捨五入（合計金額）",
                "actualReceived": "実際の受領額",
                "differenceAmt": "受領額の差異",
                "customerDebt": "顧客負債",
                "markupDifference": "マークアップ差異",
                "receivedWhenDropOff": "降車時に受領",
                "refundAmount": "返金額",
                "requestedPaymentMethod": "リクエストされた支払い方法",
                "paidBy": "支払い者",
                "splitPayment": "分割払い",
                "paidByWallet": "ウォレットで支払い",
                "paidByOtherMethod": "他の方法で支払い",
                "cashReceived": "現金受領",
                "transferredChangeByWallet": "ウォレットで釣銭を送金",
                "returnedChangeByCash": "現金で釣銭を返金",
                "instructions": "指示",
                "additionalServiceName": "名前",
                "additionalServiceValue": "値",
                "sendReceiptHeader": "",
                "sendReceipt": "領収書を送信",
                "send": "送信",
                "cancel": "キャンセル",
                "sentReceiptSuccess": "領収書が正常に送信されました",
                "sentReceiptFailed": "現在、領収書を送信できません。もう一度お試しください。",
                "coRider": "同乗者",
                "coriderName": "名前",
                "stopAddress": "追加目的地",
                "stopGeo": "緯度/経度",
                "addonPrice": "顧客による価格調整",
                "oldSubTotal": "調整前の小計",
                "view_good_receipt": "受領書",
                "view_delivery_receipt": "配送写真",
                "view": "表示",
                "tipAfterCompletedDetails": "振込額: {0}{1}; 取引手数料: {0}{2}",
                "fleetMarkup": "利益",
                "editedDriverEarning": "編集済みドライバー収益",
                "driverEarning": "ドライバー収益",
                "supplierAmount": "サプライヤー収益",
                "currencyISO": "通貨",
                "pickUpZoneName": "ピックアップゾーン",
                "dynamicFactor": "動的運賃（係数）",
                "supplierEarning": "サプライヤー収益",
                "passengerName": "乗客名",
                "totalPayout": "総支払額",
                "numberOfTrips": "トリップ数"
            },
            "financial": {
                "supplierPayout": "サプライヤー支払い",
                "driverPayout": "ドライバー支払い",
                "profit": "利益",
                "currencyISO": "通貨",
                "supplierAmount": "サプライヤー収益",
                "driverEarning": "ドライバー収益",
                "supplierEarning": "サプライヤー収益",
                "passengerName": "乗客名",
                "totalPayout": "総支払額",
                "numberOfTrips": "トリップ数",
                "driverName": "ドライバー名",
                "driverPhone": "電話番号",
                "driverUserName": "ユーザー名",
                "companyName": "会社",
                "bookingList": "予約リスト",
                "totalFare": "プロモなしの総運賃",
                "netEarning": "純収益",
                "showBookingList": "表示",
                "referralEarning": "紹介収益",
                "totalEarningNet": "総収益（純額）",
                "totalEarningNetTooltip": "すべての手数料をフリートに返した後の収益。総収益（純額）= 純収益 + ライド後のチップ",
                "totalEarningNetTooltipRefferal": "すべての手数料をフリートに返した後の収益。総収益（純額）= 紹介収益 + 純収益 + ライド後のチップ",
                "totalEarningGross": "総収益（総額）",
                "totalEarning": "総収益",
                "totalEarningGrossTooltip": "予約運賃とチップから得た金額。総収益（総額）= 総収益 + ライド後のチップ",
                "totalEarningGrossTooltipRefferal": "予約運賃とチップから得た金額。総収益（総額）= 紹介収益 + 総収益 + ライド後のチップ",
                "note": "メモ",
                "shortTrip": "短距離移動",
                "deduction": "控除",
                "totalDriverEarning": "ドライバー総収益",
                "totalDriverEarningTooltip": "オンラインおよびオフライン支払いの予約を含む",
                "totalDriverPayout": "ドライバー総支払額",
                "totalDriverPayoutTooltip": "現金またはオフライン支払いの予約は含まない"
            },
            "financialDriver": {
                "supplierAmount": "サプライヤーコミッション",
                "payoutType": "支払いタイプ",
                "date": "日付",
                "driverTipAfterCompleted": "ライド後のチップ",
                "bookingId": "予約ID",
                "paidBy": "支払い者",
                "bookFrom": "予約元",
                "bookingType": "予約タイプ",
                "companyName": "会社",
                "driverName": "ドライバー",
                "driverIdPermit": "ID# / 国民IC#",
                "totalFare": "プロモなしの総運賃",
                "subtotal": "小計",
                "airportFee": "空港料金",
                "meetDriver": "ドライバーと会う",
                "tollFee": "通行料",
                "parkingFee": "駐車料金",
                "gasFee": "ガソリン代",
                "rushHour": "ラッシュアワー",
                "transactionFee": "取引手数料",
                "techFee": "技術料金",
                "salesTax": "消費税",
                "tipAmt": "チップ金額",
                "bookingFee": "予約料金",
                "commission": "手数料",
                "deduction": "控除額",
                "promoAmt": "プロモ金額",
                "ridePayment": "ライド支払い",
                "driverTax": "ドライバー税",
                "grossEarning": "総収益",
                "driverDeduction": "ドライバー控除",
                "netEarning": "純収益",
                "payToDriver": "ドライバーへの支払い",
                "payToFleet": "フリートへの支払い",
                "totalNotRounded": "四捨五入（合計金額）",
                "fleetServiceFee": "フリートサービス手数料",
                "editedDriverEarning": "編集済みドライバー収益",
                "stripeFee": "Stripe手数料",
                "currencyISO": "通貨"
            },
            "financialCompany": {
                "companyName": "会社",
                "homeFleetNetEarning": "ホームフリート純収益",
                "affiliateNetEarning": "提携純収益",
                "totalNetEarning": "ドライバー総純収益",
                "status": "ステータス",
                "companyAmount": "会社利益",
                "numberOfTrips": "トリップ数"
            },
            "companySettlement": {
                "companyName": "会社",
                "pendingSettlement": "未決済",
                "viewDetails": "詳細を見る",
                "settlementType": "タイプ"
            },
            "companySettlementDetails": {
                "bookId": "予約ID",
                "dateTime": "日時",
                "originalPaymentStatus": "元の支払い状況",
                "subTotal": "小計",
                "fleetAmount": "フリート金額",
                "driverAmount": "ドライバー金額",
                "companyAmount": "会社金額",
                "companyOweFleet": "会社がフリートに負債",
                "fleetOweCompany": "フリートが会社に負債",
                "notes": "メモ"
            },
            "financialProfit": {
                "profit": "利益",
                "bookingId": "予約#",
                "subTotal": "小計",
                "subTotalFF": "小計 FF",
                "fleetCommission": "フリート手数料",
                "techFee": "技術料金",
                "promotion": "プロモーション",
                "markupDifference": "マークアップ差異",
                "Profit": "利益",
                "currency": "通貨",
                "fleetServiceFee": "フリートサービス（フリート手数料）",
                "fleetServiceFeeTooltip": "サービス手数料からフリートが得る金額"
            },
            "promotion": {
                "date": "日付",
                "promoCode": "プロモコード",
                "users": "ユーザー数",
                "uses": "使用回数",
                "checked": "確認済み",
                "applied": "適用済み",
                "totalPromoAmount": "総プロモ金額",
                "totalReceipt": "総受領額",
                "numberOfUsers": "ユーザー数",
                "numberOfPromoCodeAppliance": "プロモコード適用回数",
                "numberOfSuccessfulBookings": "成功した予約数",
                "numberOfCompletedBookings": "完了した予約数",
                "numberOfCompletedUsers": "完了したユーザー数",
                "numberOfUsersApplyPromotion": "プロモーションを適用したユーザー数",
                "numberOfUsersApplyPromotionAndCompleteBooking": "プロモーションを適用して予約を完了したユーザー数",
                "numberOfPromoCodeApplianceTooltip": "全ユーザーによるプロモコード適用回数",
                "numberOfSuccessfulBookingsTooltip": "プロモコードを適用して成功した予約数",
                "numberOfCompletedBookingsTooltip": "プロモコードを適用して完了した予約数"
            },
            "export": {
                "createdDate": "日付",
                "sessionName": "セッション名",
                "download": "ダウンロード",
                "delete": "削除",
                "percent": "パーセント",
                "status": "ステータス",
                "name": "モジュール名"
            },
            "redeemed": {
                "date": "日付",
                "code": "コード",
                "customers": "顧客",
                "phone": "電話番号",
                "value": "値",
                "currency": "通貨",
                "campaignName": "キャンペーン"
            },
            "carActivity": {
                "plateNumber": "車両ナンバープレート",
                "vehicleType": "車種",
                "companyName": "会社",
                "day1st": "1日目",
                "day2nd": "2日目",
                "day3rd": "3日目",
                "day4th": "4日目",
                "day5th": "5日目",
                "day6th": "6日目",
                "day7th": "7日目",
                "day8th": "8日目",
                "day9th": "9日目",
                "day10th": "10日目",
                "day11th": "11日目",
                "day12th": "12日目",
                "day13th": "13日目",
                "day14th": "14日目",
                "day15th": "15日目",
                "day16th": "16日目",
                "day17th": "17日目",
                "day18th": "18日目",
                "day19th": "19日目",
                "day20th": "20日目",
                "day21st": "21日目",
                "day22nd": "22日目",
                "day23rd": "23日目",
                "day24th": "24日目",
                "day25th": "25日目",
                "day26th": "26日目",
                "day27th": "27日目",
                "day28th": "28日目",
                "day29th": "29日目",
                "day30th": "30日目",
                "day31st": "31日目",
                "operationDays": "運用日数",
                "activeCars": "総稼働車両:"
            },
            "carActivityLogsTitle": "ログ",
            "carActivityLogs": {
                "licensePlate": "車両ナンバープレート",
                "companyName": "会社",
                "bookingId": "予約ID",
                "driverName": "ドライバー名",
                "driverIDNumber": "ID# / 国民IC#",
                "date": "承認時間",
                "bookFrom": "予約元"
            },
            "affiliation": {
                "date": "ピックアップ時間",
                "bookingId": "予約ID",
                "bookingType": "予約タイプ",
                "homeFleet": "ホームフリート",
                "providerFleet": "プロバイダーフリート",
                "driver": "ドライバー",
                "passenger": "乗客",
                "paidBy": "支払い者",
                "airportFee": "空港料金",
                "meetDriver": "ドライバーと会う",
                "rushHour": "追加料金",
                "tollFee": "通行料",
                "parkingFee": "駐車料金",
                "gasFee": "ガソリン代",
                "heavyTraffic": "渋滞料金",
                "otherFees": "その他料金",
                "provider": "サプライヤー",
                "basicFareProvider": "基本運賃プロバイダー",
                "subTotalProvider": "小計プロバイダー",
                "techFeeProvider": "技術料金プロバイダー",
                "bookingFeeProvider": "予約料金プロバイダー",
                "taxValueProvider": "税金額プロバイダー",
                "tipProvider": "チッププロバイダー",
                "totalFareProvider": "総運賃プロバイダー",
                "basicFare": "基本運賃",
                "subtotal": "小計",
                "techFee": "技術料金",
                "bookingFee": "予約料金",
                "tax": "税金",
                "tip": "チップ",
                "totalFare": "総運賃",
                "payoutInLocalCurrency": "ローカル通貨でのライド収益",
                "payoutInUSD": "USDでのライド収益",
                "fleetCommInUSD": "USDでのフリート手数料",
                "bookingFeeInUSD": "USDでの予約料金",
                "totalPayoutInUSD": "USDでの総支払い額",
                "subTotal": "小計",
                "bookingStatus": "予約状況",
                "vehicle": "車両",
                "customerPhone": "顧客の電話番号",
                "driverPhone": "ドライバーの電話番号",
                "total": "合計",
                "totalInUSD": "総運賃（USD）",
                "supplierPayout": "サプライヤー支払い額",
                "supplierPayoutInUSD": "サプライヤー支払い額（USD）",
                "buyerPayout": "バイヤー手数料",
                "buyerPayoutInUSD": "手数料（USD）",
                "buyerCommission": "手数料",
                "buyerCommissionInUSD": "手数料（USD）",
                "receivableInUSD": "受取額（USD）",
                "payableInUSD": "支払額（USD）",
                "fleetMarkupInUSD": "利益（USD）",
                "paymentMethod": "支払い方法",
                "paymentStatus": "支払い状況"
            },
            "prepaidTopUp": {
                "date": "日付＆時間",
                "topUpId": "チャージID",
                "transactionId": "取引ID",
                "name": "名前",
                "company": "会社",
                "paidBy": "支払い者",
                "amount": "金額",
                "newAmount": "新残高",
                "transactionType": "取引タイプ"
            },
            "operatorLogs": {
                "date": "日付",
                "operator": "オペレーター",
                "username": "ユーザー名",
                "module": "モジュール",
                "description": "説明"
            },
            "corporate": {
                "date": "日付",
                "bookingId": "予約ID",
                "operatorName": "法人オペレーター",
                "corporateName": "会社",
                "corpTraveler": "法人ユーザー",
                "travelerSignature": "旅行者署名",
                "corpId": "法人ID",
                "corpDivision": "法人部門",
                "costCentre": "コストセンター",
                "corpPO": "法人PO",
                "managerName": "マネージャー名",
                "managerEmail": "マネージャーのメールアドレス",
                "title": "役職",
                "department": "部署",
                "clientCaseMatter": "クライアント案件 / 旅行説明",
                "chargeCode": "チャージコード / 旅行コード",
                "driverName": "ドライバー",
                "pickupLocation": "ピックアップ地点",
                "destination": "目的地",
                "total": "総額",
                "subtotal": "小計",
                "subTotalFF": "小計 FF",
                "commission": "手数料",
                "commissionBooking": "アプリ/PWAからの予約に手数料を適用",
                "paidBy": "支払い者",
                "statusDisplay": "ステータス",
                "flightNumber": "フライト番号"
            },
            "bookingLogs": {
                "bookingId": "予約ID",
                "bookingService": "ネットワークタイプ",
                "bookingType": "予約タイプ",
                "driverName": "ドライバー",
                "driverIdPermit": "ID# / 国民IC#",
                "customerName": "乗客",
                "pickupTime": "ピックアップ時間",
                "onBoardTime": "乗車時間",
                "droppedOffTime": "降車時間",
                "pickupLocation": "ピックアップ地点",
                "destination": "目的地",
                "dispatchHistory": "配車履歴",
                "otwDistance": "O.T.W距離",
                "distanceTour": "P.O.B距離",
                "duration": "P.O.B時間",
                "paidBy": "支払い者",
                "bookFrom": "予約元",
                "map": "マップ",
                "showDispatchHistory": "表示",
                "showMap": "表示",
                "paymentActivities": "支払い履歴"
            },
            "rating": {
                "date": "日付",
                "bookingId": "予約ID",
                "driverName": "ドライバー",
                "driverIdPermit": "ID# / 国民IC#",
                "customerName": "顧客名",
                "customerPhone": "顧客電話番号",
                "like": "いいね",
                "dislike": "よくないね",
                "notes": "コメント",
                "stars": "評価",
                "vehicleRating": "車両評価",
                "driverRating": "ドライバー評価",
                "plateNumber": "車両ナンバープレート"
            },
            "mDispatcher": {
                "date": "日付",
                "bookingId": "予約ID",
                "mDispatcherName": "パートナー名",
                "mDispatcherType": "パートナータイプ",
                "subtotal": "小計",
                "commission": "手数料",
                "paidBy": "支払い者"
            },
            "totalAdjustment": {
                "date": "日付",
                "bookingId": "予約ID",
                "oldAmount": "合計",
                "type": "タイプ",
                "markupDifference": "マークアップ差異",
                "newAmount": "編集後の合計 / マークアップ",
                "username": "オペレーター",
                "reason": "理由"
            },
            "incidentCancellation": {
                "date": "日付",
                "bookingId": "予約ID",
                "customerName": "顧客名",
                "customerPhone": "顧客電話番号",
                "bookFrom": "予約元",
                "canceledBy": "キャンセル者",
                "type": "タイプ",
                "reason": "理由",
                "reasonCode": {
                    "0": "別の移動手段を見つけた",
                    "1": "ドライバーが遠すぎる",
                    "2": "間違った場所を入力した",
                    "3": "長時間待ちすぎた",
                    "4": "ドライバーがキャンセルを要求した",
                    "5": "予定を変更した",
                    "6": "場所を変更したかった",
                    "101": "ほとんどまたはすべての商品が利用不可",
                    "102": "現在忙しすぎる",
                    "103": "現在閉店中",
                    "104": "まもなく閉店"
                },
                "canceller": "キャンセル者"
            },
            "acceptanceCancellationRate": {
                "companyId": "会社ID",
                "driverId": "ドライバーID",
                "driverName": "ドライバー名",
                "phoneNumber": "電話番号",
                "company": "会社",
                "acceptanceRate": "受入率",
                "cancellationRate": "キャンセル率",
                "rating": "評価"
            },
            "revenueFleet": {
                "date": "日付",
                "fareRevenue": "運賃収益",
                "trip": "トリップ",
                "avgFareTrip": "平均運賃/トリップ",
                "drivers": "ドライバー数",
                "avgTripPerDriver": "ドライバーあたりの平均トリップ数",
                "dispatcher": "配車担当数"
            },
            "revenueDriver": {
                "date": "日付",
                "driverName": "ドライバー",
                "fareRevenue": "運賃収益",
                "trip": "トリップ数",
                "avgFareTrip": "平均運賃/トリップ"
            },
            "revenueMonthly": {
                "month": "月",
                "trip": "完了したトリップ",
                "subtotal": "小計",
                "tipAmt": "チップ",
                "techFee": "技術料金",
                "salesTax": "税金",
                "partnerCommission": "パートナー手数料 / ODD",
                "totalFare": "プロモなしの総運賃",
                "netEarning": "純収益",
                "fleetServiceFee": "フリートサービス手数料"
            },
            "dailyDriver": {
                "driverName": "ドライバー",
                "driverIDPermit": "ID# / 国民IC#",
                "driverPhone": "電話",
                "companyName": "会社",
                "workingHoursOnline": "オンライン（時間）",
                "workingHoursAvailable": "利用可能（時間）",
                "driverWorkingHours": "ドライバー勤務時間",
                "distance": "ドライバー移動距離",
                "CC_activity": "CCアクティビティ",
                "fare_summary": "CCアクティビティ",
                "workingHoursNotAvailable": "利用不可（時間）",
                "workingHoursPob": "P.O.B（時間）",
                "workingHoursPobToolTip": "乗客乗車時間",
                "workingHoursAccepted": "承認済み",
                "workingHoursReject": "拒否",
                "workingHoursIgnore": "無視",
                "workingHoursCancel": "キャンセル",
                "completedTripsTotal": "合計",
                "completedTripsCc": "CCから",
                "completedTripsCarHailing": "カー・ハイリングから",
                "completedTripsStreetSharing": "ストリートシェアリングから",
                "completedTripsPaxApp": "乗客アプリから",
                "completedTripsWebBooking": "ウェブ予約から",
                "completedTripsMDispatcher": "パートナーから",
                "completedTripsKiosk": "キオスクから",
                "completedTripsApi": "APIから",
                "ccActivityDispatchedTrips": "配車されたトリップ",
                "ccActivityAccepted": "承認済み",
                "ccActivityReject": "拒否",
                "ccActivityIgnore": "無視",
                "ccActivityCancelByPassenger": "乗客によるキャンセル",
                "ccActivityCancelByDriver": "ドライバーによるキャンセル",
                "ccActivityCancelByCc": "CCによるキャンセル",
                "ccActivityCancelByAPI": "APIによるキャンセル",
                "ccActivityNoShow": "ノーショー",
                "fareSummaryTotalCash": "現金",
                "fareSummaryTotalCreditCard": "クレジットカード",
                "fareSummaryTotalExternalCard": "外部カード",
                "fareSummaryTotalPaxWallet": "ウォレット",
                "fareSummaryTotalDirectBilling": "直接請求",
                "fareSummaryTotalQrCode": "QRコード",
                "fareSummaryTotalPrePaid": "法人プリペイド",
                "fareSummaryTotalApplePay": "Apple Pay",
                "fareSummaryTotalTngeWallet": "TNG eWallet",
                "fareSummaryTotalVippseWallet": "Vipps",
                "fareSummaryTotal": "合計",
                "ratingNotes": "メモ数",
                "ratingDislike": "否定的な評価数",
                "ratingLike": "肯定的な評価数",
                "fareDetailsBasicFare": "基本運賃",
                "fareDetailsAirportFee": "空港料金",
                "fareDetailsRushHour": "追加料金",
                "fareDetailsHeavyTraffic": "渋滞料金",
                "fareDetailsOtherFees": "その他の料金",
                "fareDetailsSubTotal": "小計",
                "fareDetailsSubTotalFF": "小計 FF",
                "fareDetailsTechFee": "技術料金",
                "fareDetailsBookingFee": "予約料金",
                "fareDetailsSalesTax": "消費税",
                "fareDetailsTip": "チップ金額",
                "fareDetailsPromoAmount": "プロモ金額",
                "fareDetailsTotalAmt": "合計金額",
                "availableDistance": "利用可能",
                "otwDistance": "O.T.W",
                "otwDistanceToolTip": "移動距離",
                "pobDistance": "P.O.B",
                "pobDistanceToolTip": "乗客乗車距離",
                "ratingStars1": "1つ星数",
                "ratingStars2": "2つ星数",
                "ratingStars3": "3つ星数",
                "ratingStars4": "4つ星数",
                "ratingStars5": "5つ星数",
                "ratingAvg": "平均",
                "ssnNumber": "SSN #",
                "driverEmail": "メールアドレス",
                "driverAddress": "住所",
                "fareDetailsAddonPrice": "顧客による価格調整",
                "fareDetailsOldSubTotal": "調整前の小計"
            },
            "dailyCar": {
                "carInfo": "車両情報",
                "fareDetails": "運賃詳細",
                "completedTrips": "完了したトリップ",
                "carInfoCompanyName": "会社",
                "carInfoPermission": "権限",
                "carInfoVehiclePlateNumber": "車両ナンバープレート",
                "carInfoVhcId": "車両番号",
                "carInfoCarType": "車種",
                "completedTripsTotal": "合計",
                "completedTripsCc": "CCから",
                "completedTripsCarHailing": "カー・ハイリングから",
                "completedTripsStreetSharing": "ストリートシェアリングから",
                "completedTripsPaxApp": "乗客アプリから",
                "completedTripsWebBooking": "ウェブ予約から",
                "completedTripsMDispatcher": "パートナーから",
                "completedTripsKiosk": "キオスクから",
                "completedTripsAPI": "APIから",
                "fareDetailsBasicFare": "基本運賃",
                "fareDetailsAirportFee": "空港料金",
                "fareDetailsMeetDriver": "ドライバーと会う",
                "fareDetailsRushHour": "追加料金",
                "fareDetailsHeavyTraffic": "渋滞料金",
                "fareDetailsTollFee": "通行料",
                "fareDetailsParkingFee": "駐車料金",
                "fareDetailsGasFee": "ガソリン代",
                "fareDetailsOtherFees": "その他の料金",
                "fareDetailsSubTotal": "小計",
                "fareDetailsSubTotalFF": "小計 FF",
                "fareDetailsTechFee": "技術料金",
                "fareDetailsBookingFee": "予約料金",
                "fareDetailsSalesTax": "消費税",
                "fareDetailsTip": "チップ",
                "fareDetailsPromoAmount": "プロモ金額",
                "fareDetailsTotalAmt": "合計金額",
                "fareDetailsAddonPrice": "顧客による価格調整",
                "fareDetailsOldSubTotal": "調整前の小計"
            },
            "dailyDriverCar": {
                "driverInfo": "ドライバー情報",
                "driverName": "ドライバー名",
                "driverIDPermit": "ID# / 国民IC#",
                "driverPhone": "電話番号",
                "companyName": "会社",
                "actionInfo": "アクション",
                "date": "日付",
                "action": "アクション",
                "bookingId": "予約ID",
                "carInfo": "車両情報",
                "permission": "権限",
                "licensePlate": "車両ナンバープレート",
                "carId": "車両番号",
                "carType": "車種"
            },
            "driverLoginStatus": {
                "driver": "ドライバー",
                "driverIdPermit": "ID# / 国民IC#",
                "phone": "電話番号",
                "username": "ユーザー名",
                "company": "会社",
                "carType": "車種",
                "licensePlate": "車両ナンバープレート",
                "status": "ステータス"
            },
            "bookingSummary": {
                "date": "日付",
                "operator": "オペレーター",
                "username": "ユーザー名",
                "action": "アクション",
                "bookingId": "予約ID"
            },
            "cashWallet": {
                "bankAccount": "銀行口座への振込",
                "creditWallet": "クレジットウォレットへの振込",
                "tipAfterCompleted": "完了後のチップ",
                "netEarning": "純収益",
                "referralEarning": "紹介収益",
                "receiverAccount": "受取口座",
                "dateAndTime": "日付＆時間",
                "driverName": "ドライバー名",
                "phoneNumber": "電話番号",
                "userName": "ユーザー名",
                "company": "会社",
                "type": "タイプ",
                "bookingId": "予約番号",
                "operator": "オペレーター",
                "reason": "理由",
                "amount": "金額",
                "newAmount": "新残高",
                "currency": "通貨",
                "topUpGCashReason": "チャージID",
                "topUpReason": "Gcashによるチャージ",
                "cashWalletReason": "クレジットカード*{0}からのチャージ",
                "topUpMOLPayReason": "キャッシュウォレットからのチャージ",
                "chargedAmount": "Razer Pay [ {0} ]",
                "differentPercent": "チャージ金額",
                "operatorName": "割引 / 追加料金（%）",
                "sendPayout": "オペレーター",
                "sendPayoutTitle": "アクション",
                "payoutReason": "支払いメールを再送"
            },
            "creditWallet": {
                "receiverType": "{0} (支払い {1} {2})",
                "receiverName": "受取者タイプ",
                "receiverPhone": "受取者名",
                "senderPhone": "受取者電話番号",
                "senderName": "送信者電話番号",
                "sentToCustomer": "送信者名",
                "sentToDriver": "顧客へ送信",
                "dateAndTime": "ドライバーへ送信",
                "driverName": "日付＆時間",
                "phoneNumber": "ドライバー名",
                "userName": "電話番号",
                "company": "ユーザー名",
                "type": "会社",
                "bookingId": "タイプ",
                "operator": "予約番号",
                "reason": "オペレーター",
                "amount": "理由",
                "newAmount": "金額",
                "currency": "新残高",
                "transactionId": "通貨",
                "topUpGCashReason": "取引ID",
                "topUpReason": "Gcashによるチャージ",
                "cashWalletReason": "クレジットカード*{0}からのチャージ",
                "topUpMOLPayReason": "キャッシュウォレットからのチャージ",
                "topUpWalletReason": "Razer Pay [ {0} ]",
                "chargedAmount": "{0} [ {1} ]",
                "differentPercent": "チャージ金額",
                "cashExcessReason": "割引 / 追加料金（%）",
                "referralEarningReason": "顧客の支払額 {0}; 受取現金 {1}",
                "referralEarningReason_2": "顧客 {0} {1} 初回予約完了 {2}",
                "insurance": "顧客 {0} {1} 予約完了 {2}"
            },
            "passengerWallet": {
                "dateTime": "ドライバー保険",
                "customerName": "日付＆時間",
                "phoneNumber": "顧客名",
                "type": "電話番号",
                "bookingId": "タイプ",
                "changedAmount": "予約番号",
                "amount": "変更後の金額",
                "newBalance": "変更後の金額",
                "currency": "新残高",
                "transactionId": "通貨",
                "Note": "取引ID",
                "credit": "メモ",
                "TnG": "クレジットカード*{0}によるチャージ",
                "MOLPay": "TnG #{0}によるチャージ",
                "gatewayName": "[RazerPay - #{0}] からのチャージ",
                "cashExcess": "[{0} - #{1}] からのチャージ",
                "editBalance": "顧客の支払額 {0}, 返金額 {1}",
                "ride": "残高編集",
                "topUp": "ライド",
                "topUpReferee": "チャージ",
                "topUpReferrer": "紹介報酬 ({0})"
            },
            "recipientsInfo": {
                "order": "新規紹介者 (#{0}): {1} ({2})",
                "orderId": "番号",
                "name": "注文番号",
                "phone": "受取人名",
                "address": "受取人電話番号",
                "deliveryFee": "受取人住所",
                "ItemValue": "配送料金",
                "status": "アイテム価値（COD）",
                "Performer": "ステータス",
                "Reason": "担当者",
                "proofOfFailure": "理由",
                "referenceNumber": "失敗の証拠"
            },
            "dispatchHistory": {
                "performer": "参照番号",
                "name": "担当者",
                "action": "名前",
                "date": "アクション",
                "location": "日付",
                "estimate": "ドライバーの位置",
                "passenger": "車両-ピックアップ地点の推定距離",
                "detachVehicle": "車両の切り離し",
                "assignVehicle": "車両の割り当て"
            },
            "payToDriver": {
                "driverName": "顧客",
                "totalUnsettledTransactions": "ドライバー",
                "totalUnsettledAmount": "未決済取引合計",
                "actions": "未決済金額合計",
                "pay": "アクション",
                "remove": "支払い",
                "clearSuccess": "削除",
                "clearFailed": "選択したドライバーの収益が正常にクリアされました。",
                "selectDriver": {
                    "title": "ドライバーの収益データをクリアできませんでした。もう一度お試しください。",
                    "body": "ドライバーを選択して支払ってください。",
                    "buttonTitle": "OK"
                },
                "confirmClear": {
                    "title": "",
                    "body": "このドライバーの収益データをクリアしてもよろしいですか？ この操作は元に戻せません。",
                    "buttonTitle": "はい",
                    "closeButtonText": "いいえ"
                },
                "confirmPay": {
                    "body": "選択したドライバーへの支払いを行いますか？",
                    "buttonTitle": "はい",
                    "closeButtonText": "いいえ"
                },
                "paymentSuccessfully": "ドライバーへの支払いが正常に完了しました"
            },
            "customerQuest": {
                "name": "名前",
                "phoneNumber": "電話番号",
                "supportId": "サポートID",
                "ranking": "ランキング",
                "points": "ポイント",
                "numberOfReservation": "予約数",
                "driver": "ドライバー",
                "customer": "顧客",
                "firstWillWin": "最初に勝つ",
                "firstWillWinLuckyDraw": "最初に勝つ（抽選）",
                "largestWillWin": "最大が勝つ（リーダーボード）",
                "qualified": "資格あり",
                "notQualified": "資格なし",
                "userName": "ユーザー名",
                "completedDate": "完了日",
                "isQualified": "ステータス",
                "ticket": "チケット",
                "numOfComplete": "完了数",
                "bookingDistance": "P.O.B距離",
                "numberOfIntercity": "都市間予約数",
                "numberOfDelivery": "配送数",
                "pleaseSelectEventName": "イベント名を選択してください。",
                "numberOfQualifiedSetting": "設定上の資格者数:",
                "numberOfActualQualified": "実際の資格者数:",
                "driverRating": "ドライバー評価",
                "acceptanceRating": "受入率",
                "cancellationRating": "キャンセル率",
                "period": "期間:"
            },
            "payoutToDriver": {
                "IFSCCode": "IFSCコード",
                "payoutID": "支払いID",
                "driverName": "ドライバー",
                "driverPhone": "電話番号",
                "companyName": "会社",
                "period": "期間",
                "driverId": "ID# / 国民IC#",
                "cashWalletBalance": "キャッシュウォレット残高",
                "creditBalance": "クレジット残高",
                "holdAmount": "保留金額",
                "minPayout": "最低支払い額",
                "enterMinimumPayoutAmount": "最低支払い額を入力",
                "payoutAmount": "支払い額",
                "payoutDate": "支払い日",
                "actions": "アクション",
                "pay": "支払う",
                "remove": "削除",
                "payoutEndTime": "支払い終了時間",
                "clearSuccess": "選択したドライバーの収益が正常にクリアされました。",
                "clearFailed": "ドライバーの収益データをクリアできませんでした。もう一度お試しください。",
                "amountKeepInDriverCashWallet": "ドライバーのキャッシュウォレットに保持される金額",
                "selectDriver": {
                    "title": "",
                    "body": "支払いを行うドライバーを選択してください。",
                    "buttonTitle": "OK"
                },
                "confirmClear": {
                    "title": "",
                    "body": "このドライバーの収益データをクリアしてもよろしいですか？ この操作は元に戻せません。",
                    "buttonTitle": "はい",
                    "closeButtonText": "いいえ"
                },
                "confirmPay": {
                    "body": "選択したドライバーへの支払いを行いますか？<br>\n- ドライバー数: <b>%{drivers}</b><br>\n- 各ドライバーの最低支払い額: %{currency} <b>%{min}</b><br>\n- 保留金額: %{currency}<b> %{hold}</b><br>\n- 総支払い額: %{currency} <b>%{total}</b><br>",
                    "buttonTitle": "はい",
                    "closeButtonText": "いいえ"
                },
                "paymentSuccessfully": "ドライバーへの支払いが正常に完了しました",
                "paymentCompleted": "支払い完了:<br>\n- 正常に支払い済み: %{success}<br>\n- 失敗: %{failed}<br>\n- 総支払い額: %{currency} %{total}",
                "noteSelectDrivers": "注: 銀行口座を持つドライバーを選択してください。",
                "sendPayout": "支払い完了通知をメールアドレスに送信",
                "accountNumber": "口座番号",
                "iBanNumber": "IBAN番号",
                "accountHoldName": "口座名義人",
                "bankName": "銀行名",
                "selelctedDriverHasBeenPaid": "選択したドライバーへの支払いが完了しました",
                "selelctedDriverHasBeenFailed": "選択したドライバーへの支払いが失敗しました",
                "selelctedDriverHasBeenUpdateAsInvalid": "選択したドライバーが「無効な銀行口座」として更新されました",
                "selelctedDriverHasBeenUpdateAsValid": "選択したドライバーが「有効な銀行口座」として更新されました",
                "isBankAccountOwner": "受取人はドライバーと同じ",
                "isBankAccountOwner_true": "はい",
                "isBankAccountOwner_false": "いいえ",
                "beneficiaryIDIC": "受取人ID# / IC#",
                "bankRelationship": "関係",
                "bankRelationship_1": "配偶者",
                "bankRelationship_2": "娘/息子",
                "bankRelationship_3": "兄弟姉妹",
                "bankRelationship_4": "親戚",
                "bankRelationship_5": "友人",
                "bankRelationship_6": "その他: {0}"
            },
            "payoutToMerchant": {
                "payoutId": "支払いID",
                "merchantName": "加盟店名",
                "phone": "電話番号",
                "accountNumber": "口座番号",
                "iBanNumber": "IBAN番号",
                "bankAccountHolder": "口座名義人",
                "bankName": "銀行名",
                "pendingAmount": "保留金額",
                "payoutAmount": "支払い額",
                "payoutDate": "支払い日",
                "holdAmount": "保留金額",
                "minPayout": "最低支払い額",
                "selelctedMerchantHasBeenPaid": "選択した加盟店への支払いが完了しました",
                "selelctedMerchantHasBeenFailed": "選択した加盟店への支払いが失敗しました",
                "pay": "支払う",
                "selectMerchant": {
                    "title": "警告",
                    "body": "支払いを行う加盟店を選択してください。",
                    "buttonTitle": "OK"
                },
                "confirmPay": {
                    "body": "選択した加盟店への支払いを行いますか？<br>\n- 加盟店数: <b>%{merchants}</b><br>\n- 各加盟店の最低支払い額: %{currency} <b>%{min}</b><br>\n- 保留金額: %{currency}<b> %{hold}</b><br>\n- 総支払い額: %{currency} <b>%{total}</b>\n",
                    "buttonTitle": "はい",
                    "closeButtonText": "いいえ"
                },
                "sendPayout": "支払い完了通知をメールアドレスに送信",
                "HasBeenUpdateAsInvalid": "選択した加盟店が「無効な銀行口座」として更新されました",
                "HasBeenUpdateAsValid": "選択した加盟店が「有効な銀行口座」として更新されました"
            },
            "settlementHistory": {
                "date": "日付",
                "driverName": "ドライバー名",
                "dateRange": "日付範囲",
                "totalTransaction": "合計清算取引数",
                "paidAmount": "合計清算額"
            },
            "settlement": {
                "driverName": "ドライバー",
                "driverIdPermit": "ID# / 国民IC#",
                "totalBookings": "合計予約数",
                "total": "合計金額",
                "approved": "承認済み",
                "acct": "ACCT",
                "gross": "総額",
                "percent": 0.03,
                "net": "純額"
            },
            "documentExpiry": {
                "driverName": "ドライバー名",
                "driverPhone": "ドライバー電話番号",
                "company": "会社",
                "documentName": "書類名",
                "expiredDate": "有効期限",
                "status": "ステータス",
                "statusStr": {
                    "NA": "該当なし",
                    "expired": "期限切れ",
                    "active": "有効",
                    "expireSoon": "まもなく期限切れ"
                }
            },
            "referralDetail": {
                "bookingId": "予約#",
                "completedDate": "完了時間",
                "paidBy": "支払い者",
                "bookFrom": "予約元",
                "driverName": "ドライバー名",
                "driverIdPermit": "ID# / 国民IC#",
                "companyName": "会社",
                "Passenger": "乗客",
                "Customer": "顧客",
                "subtotal": "小計",
                "subTotalFF": "小計 FF",
                "subtotalWithPromo": "プロモ適用後の小計",
                "referralEarning": "紹介収益",
                "Amount": "金額",
                "Note": "メモ",
                "Currency": "通貨",
                "PercentOfSubTotal": "{0}% の小計",
                "max": "最大 {0}",
                "referrerInfo": "ドライバーが顧客を紹介",
                "phoneReferral": "ドライバー電話番号",
                "driverNumber": "ID# / 国民IC#",
                "referralCode": "紹介コード"
            },
            "referralHistory": {
                "driverName": "ドライバー名",
                "phoneNumber": "電話番号",
                "companyName": "会社",
                "referralCode": "紹介コード",
                "commission": "総手数料",
                "expectedReferralEarning": "予想紹介収益",
                "activePaxTooltip": "少なくとも1回の予約を成功させた紹介コードを利用した乗客数",
                "referredPaxTooltip": "紹介コードを利用したが、まだ予約をしていない乗客数",
                "activePax": "総アクティブ顧客数",
                "referredPax": "総紹介顧客数",
                "detailFields": {
                    "passengerId": "乗客ID",
                    "passengerName": "顧客名",
                    "firstBookingDate": "初回完了予約日",
                    "firstBookingDateGMT": "初回完了予約日（GMT）",
                    "passengerPhone": "顧客電話番号",
                    "appliedDate": "紹介日",
                    "referredByDriver": "ドライバーによる紹介",
                    "driverPhone": "ドライバー電話番号",
                    "referralCode": "紹介コード"
                },
                "showDetail": "表示",
                "detail": "詳細"
            },
            "paxReferralHistory": {
                "customerName": "顧客名",
                "phoneNumber": "電話番号",
                "referralCode": "紹介コード",
                "commission": "総手数料",
                "activePaxTooltip": "少なくとも1回の予約を成功させた紹介コードを利用した乗客数",
                "referredPaxTooltip": "紹介コードを利用したが、まだ予約をしていない乗客数",
                "activePax": "総アクティブ顧客数",
                "referredPax": "総紹介顧客数",
                "detailFields": {
                    "passengerId": "乗客ID",
                    "passengerName": "乗客名",
                    "firstBookingDate": "初回完了予約日",
                    "firstBookingDateGMT": "初回完了予約日（GMT）"
                },
                "showDetail": "表示",
                "detail": "詳細"
            },
            "driverRefersDriver": {
                "companyName": "会社",
                "phoneNumber": "電話番号",
                "username": "ユーザー名",
                "referralCode": "紹介コード",
                "referrer": "紹介者",
                "numberOfReferees": "紹介された人数",
                "activeReferees": "アクティブな紹介者",
                "referee": "紹介者",
                "refereePhone": "紹介者電話番号",
                "refereeUsername": "紹介者ユーザー名",
                "referredDate": "紹介日",
                "activatedDate": "アクティブ化日",
                "referrerByDriver": "紹介者",
                "referrerPhone": "紹介者電話番号",
                "referralStatus": "紹介ステータス",
                "lifeTimeBookings": "生涯予約数",
                "inReview": "審査中",
                "activated": "アクティブ化済み"
            },
            "driverWithdrawal": {
                "transferToEWallet": "外部電子ウォレットに送金 *{0}",
                "channelCode": "チャンネルコード",
                "channelName": "チャンネル名",
                "reversed": "取り消されました",
                "currentBalanceTooltip": "ドライバーのキャッシュウォレットの現在残高（出金リクエスト前）",
                "paymentTime": "支払い時間",
                "requestTime": "リクエスト時間",
                "referenceId": "参照番号",
                "transactionId": "取引ID",
                "description": "説明",
                "transferAmount": "振込金額",
                "currency": "通貨",
                "approved": "承認済み",
                "rejected": "拒否済み",
                "failed": "失敗",
                "reason": "理由"
            },
            "penaltyCompensation": {
                "penaltyAmount": "ペナルティ",
                "totalCollect": "現地通貨で支払う必要があります",
                "totalCollectInUSD": "USDで支払う必要があります",
                "compensationAmount": "補償",
                "compensationAmountInUSD": "補償（USD）",
                "penaltyReason": "ペナルティ理由",
                "estimatedPrice": "推定価格",
                "estimatedPriceInUSD": "推定価格（USD）",
                "penaltyAmountInUSD": "ペナルティ（USD）",
                "amount": "金額",
                "amountInUSD": "金額（USD）"
            },
            "imcompletePayment": {
                "bookingId": "予約ID",
                "bookFrom": "予約元",
                "bookingTime": "予約日時",
                "completedTime": "予約完了日時",
                "customerName": "顧客名",
                "requestedPaymentMethod": "リクエストされた支払い方法",
                "originalPaymentStatus": "元の支払い状況",
                "currentPaymentStatus": "現在の支払い状況",
                "updatedDateTime": "残高回収日時",
                "totalAmt": "合計金額",
                "originalOutstandingAmount": "元の未払い額",
                "currentOutstandingAmount": "現在の未払い額",
                "outstandingAmountPaidBy": "未払い額回収者",
                "writeOffDebtMethod": "未払い額回収者",
                "reason": "理由",
                "customerPhone": "電話番号",
                "amountCollected": "回収額",
                "partialPaymentAmount": "降車時の部分支払い"
            },
            "driverDeposit": {
                "dateAndTime": "日付＆時間",
                "userName": "ユーザー名",
                "type": "タイプ",
                "newAmount": "新残高",
                "topUpGCashReason": "Gcashによるチャージ",
                "topUpReason": "クレジットカード*{0}からのチャージ",
                "cashWalletReason": "キャッシュウォレットからのチャージ",
                "topUpMOLPayReason": "Razer Pay [ {0} ]"
            },
            "refund": {
                "fullRefund": "全額返金",
                "partialRefund": "部分返金",
                "tripId": "トリップID",
                "route": "ルート",
                "refundAmount": "返金額",
                "refundTime": "返金時間",
                "paymentStatus": "支払い状況"
            },
            "merchant": {
                "store": "店舗",
                "name": "名前",
                "phoneNumber": "電話番号",
                "bookingId": "予約#",
                "totalOrders": "総注文数",
                "completedOrders": "完了注文数",
                "canceledOrders": "キャンセル注文数",
                "incidentOrders": "インシデント",
                "revenue": "収益",
                "commission": "手数料",
                "earning": "収益",
                "food": "フード",
                "mart": "マート",
                "preferredMerchant": "優先加盟店",
                "regularMerchant": "通常加盟店",
                "companyName": "会社"
            },
            "merchantCashTransactionHistory": {
                "companyName": "会社",
                "dateTime": "日付＆時間",
                "merchantUserName": "加盟店ユーザー",
                "merchantUserPhone": "電話番号",
                "merchantUserEmail": "メールアドレス",
                "merchantName": "加盟店",
                "transactionType": "タイプ",
                "bookId": "予約#",
                "transactionId": "取引ID",
                "operatorName": "オペレーター",
                "reason": "理由",
                "amount": "金額",
                "newBalance": "新残高",
                "currencyISO": "通貨"
            },
            "merchantCreditTransactionHistory": {
                "dateTime": "日付＆時間",
                "merchantUserName": "加盟店ユーザー",
                "merchantUserPhone": "電話番号",
                "merchantUserEmail": "メールアドレス",
                "merchantName": "加盟店",
                "transactionType": "タイプ",
                "bookId": "予約番号",
                "transactionId": "取引ID",
                "operatorName": "オペレーター",
                "reason": "理由",
                "amount": "金額",
                "newBalance": "新残高",
                "currencyISO": "通貨"
            },
            "deleteAccountRequest": {
                "date": "日付",
                "phone": "電話番号",
                "username": "ユーザー名",
                "supportId": "サポートID",
                "requestFrom": "リクエスト元",
                "associatedApps": "関連アプリ",
                "reason": "理由"
            },
            "affiliationPayoutHistory": {
                "payoutId": "支払いID",
                "dateTime": "日付＆時間",
                "payoutType": "タイプ",
                "accountHolderName": "口座名義人",
                "bankName": "銀行名",
                "accountNumber": "口座番号",
                "totalPayout": "総支払い額（USD）",
                "viewDetails": "詳細を見る",
                "notes": "メモ",
                "bookId": "予約番号",
                "transactionType": "取引タイプ",
                "transactionId": "取引ID（自動転送）",
                "settlement": "決済",
                "paymentMethod": "タイプ",
                "networkType": "ネットワークタイプ"
            },
            "thirdPartyBooking": {
                "date": "日付",
                "bookingId": "予約ID",
                "externalId": "外部ID",
                "psgPhone": "乗客の電話番号",
                "psgName": "乗客の名前",
                "pickupLocation": "ピックアップ地点",
                "pickupLocationLatLong": "ピックアップ地点の緯度/経度",
                "dropoffLocation": "降車地点",
                "dropoffLocationLatLong": "降車地点の緯度/経度",
                "pickupTime": "ピックアップ時間",
                "numOfPassenger": "乗客数",
                "action": "アクション"
            }
        }
    },
    "zoneSetting": {
        "Add": "追加",
        "Import": "インポート",
        "Name": "名前",
        "Status": "ステータス",
        "Active": "アクティブ",
        "Inactive": "非アクティブ",
        "Deactivate": "無効化",
        "Activate": "有効化",
        "Delete": "削除",
        "Cancel": "キャンセル",
        "Input_zone_name": "ゾーン名を入力",
        "Save_zone": "ゾーンを保存",
        "Clear_draw": "描画をクリア",
        "Edit": "編集",
        "Delete_zone_success": "ゾーンが正常に削除されました",
        "Delete_zone_fail": "ゾーンの削除中にエラーが発生しました",
        "Update_zone_fail": "ゾーンの更新中にエラーが発生しました",
        "Update_zone_success": "ゾーンが正常に更新されました",
        "Create_zone_fail": "ゾーンの作成中にエラーが発生しました",
        "Create_zone_success": "新しいゾーンが正常に作成されました",
        "Draw_zone": "マップ上でゾーンを描画してください",
        "Import_zones_successful": "ゾーンのインポートが成功しました",
        "Import_zones_fail": "ゾーンのインポートに失敗しました",
        "DELETE_ZONE": "ゾーンを削除",
        "ZONE_CONFIRM": "選択したゾーンを削除してもよろしいですか？",
        "Name_zone_required": "名前とゾーンは必須です！",
        "Select_file": "ファイルを選択",
        "No": "いいえ",
        "Yes": "はい",
        "Import_zones_intercity_successful": "[{0}] ゾーンが正常にインポートされ、[{1}] が失敗しました。",
        "Import_zones_intercity_successful_failed": "[{0}] ゾーンが正常にインポートされ、[{1}] が失敗しました。",
        "Flat_zone": "フラットゾーン",
        "Json_file": "JSONファイル",
        "Import_zones": "ゾーンのインポート",
        "timezone": "タイムゾーン"
    },
    "companySetting": {
        "DELETE_COMPANY": "会社を削除",
        "DELETE_CONFIRM": "会社を削除しますか？",
        "DETAIL_COMPANY": "会社の詳細",
        "commissionValue": "会社の手数料",
        "connectStatus": "Stripe Connect",
        "connectStripe": "接続",
        "stripeConnectId": "StripeアカウントID",
        "loginStripe": "ダッシュボード",
        "disconnectStripe": "切断",
        "Yes": "はい",
        "No": "いいえ",
        "Cancel": "キャンセル",
        "EDIT_COMPANY": "会社サプライヤーを編集",
        "ADD_COMPANY": "会社サプライヤーを追加",
        "Name": "名前",
        "Required_fields": "必須項目",
        "Save": "保存",
        "Actions": "アクション",
        "Edit": "編集",
        "Add": "追加",
        "Show_per_page": "ページごとのアイテム数",
        "Page": "ページ",
        "show_pages": "{0} - {1} / {2} を表示中",
        "Create_company_success": "新しい会社が正常に作成されました",
        "Create_company_fail": "会社の作成中にエラーが発生しました",
        "Update_company_success": "会社が正常に更新されました",
        "Update_company_fail": "会社の更新中にエラーが発生しました",
        "Load_company_fail": "会社の読み込み中にエラーが発生しました",
        "Delete_company_success": "会社が正常に削除されました",
        "Delete_company_fail": "会社の削除中にエラーが発生しました",
        "hideFare": "運賃を常に非表示にする",
        "Broadcast_orders": "すべての注文をブロードキャスト",
        "paymentMethodsApply": "サポートされる支払い方法",
        "emailsNotification": "メール通知",
        "CopySignUpLink": "サインアップリンクをコピー",
        "operationZone": "運用ゾーン",
        "delayBroadcast": "注文の表示タイミング",
        "releaseTime": "公開時間",
        "advanceBookingVisibility": "事前予約の公開設定",
        "advanceBookingVisibilityTooltip": "ピックアップ日前の日数を設定して、サプライヤーが予約を確認できるようにします。これにより、各サプライヤーに基づいて予約がドライバーに適切なタイミングで公開されます。"
    },
    "dispatchSetting": {
        "GENERAL": "一般設定",
        "Offer_interval": "オファー間隔（ドライバー）",
        "Offer_interval_merchant": "オファー間隔（加盟店）",
        "Offer_interval_merchant_toolTip": "加盟店が注文を受け入れる最大時間",
        "Offer_interval_toolTip": "ドライバーが予約を受け入れる最大時間",
        "soundMarketplace": "音声",
        "sound": "音声",
        "Auto_re_dispatch_interval_toolTip": "配車のラウンド間の休憩時間",
        "Maximum_auto_dispatch_duration_toolTip": "ドライバーへの配車の最大時間",
        "Seconds": "秒",
        "Auto_re_dispatch_interval": "自動再配車間隔",
        "Maximum_auto_dispatch_duration": "自動配車の最大継続時間",
        "Time_out": "タイムアウト",
        "mins": "分",
        "minTimeBeforeReservation": "ドライバーがピックアップ時間前に予約を開始できないようにする",
        "IN_ADVANCE": "事前予約",
        "Assign_reservation_to_driver": "予約をドライバーに割り当てる",
        "Dispatcher_confirms_job_for_driver": "配車担当者がドライバーの仕事を確認",
        "Dispatcher_confirms_job_for_offline_driver": "配車担当者がオフラインドライバーの予約を確認",
        "Broadcast_orders": "すべての会社に注文をブロードキャスト",
        "Broadcast_orders_tooltip_1": "この設定を有効にすると、すべての会社サプライヤーにすべてのトリップをブロードキャストできます。\n各会社のプロファイルでブロードキャストモードを有効にする必要があります。",
        "Minimum_book_ahead": "最小予約期間",
        "Maximum_book_ahead": "最大予約期間 - 単一注文",
        "Maximum_period_recurring_oders_fleet": "定期予約の最大期間（フリートオペレーター）",
        "Maximum_period_recurring_oders_corp": "定期予約の最大期間（法人）",
        "Day": "日",
        "Week": "週",
        "Month": "月",
        "hrs": "時間",
        "Driver_cancellation_duration": "ドライバーのキャンセル時間",
        "Mins": "分",
        "Maximum_Day": "ドライバーが1日に受け入れ可能な最大予約数",
        "Maximum_Accept_Reservation": "ドライバーが同時に受け入れ可能な予約数",
        "Local_notification_before": "ローカル通知前",
        "Dispatch_mode": "配車モード",
        "Manual": "手動",
        "Immediate_auto_dispatch": "即時自動配車",
        "Delayed_auto_dispatch": "遅延自動配車",
        "Auto_dispatch_before_pickup_time": "ピックアップ時間前の自動配車",
        "Required_fields": "必須項目",
        "Shuttle": "シャトル",
        "Shuttle_Dispatch_mode": "配車モード",
        "Dispatch_setting_Shuttle_Dispatch_mode_tooltip": "- 手動モード: 予約が作成されると、「保留中」ステータスのままコマンドセンターのキューに保存されます。「予約を更新」をクリックすると、割り当てられたドライバー、またはドライバー未割り当ての場合は全ドライバーへ配車されます。ドライバーのデバイスカレンダーに統合され、リマインダーが利用可能になります。<br>\n- 遅延モード: 予約が作成されると、「保留中」ステータスのままコマンドセンターのキューに保存されます。ピックアップ時間の「事前処理時間」に設定された時間前に、自動的に割り当てられたドライバー、または未割り当ての場合は最寄りの利用可能なドライバーへ配車されます。<br>\n- 即時自動配車モード: 予約が作成されると、全ドライバーに自動配車されます。ドライバーのデバイスカレンダーに統合され、リマインダーが利用可能になります。<br>",
        "Save": "保存",
        "Process_in_advance": "事前処理",
        "Save_dispatch_success": "配車が正常に更新されました",
        "Save_dispatch_fail": "配車の更新中にエラーが発生しました",
        "Dispatch_setting_general_tooltip": "オンデマンド予約および遅延自動配車による事前予約に適用",
        "Dispatch_setting_Assign_reservation_to_driver_tooltip": "このオプションを有効にすると、予約は自動的にドライバーに割り当てられ、受け入れられます",
        "Dispatch_setting_Driver_cancellation_duration_tooltip": "予約の有効期間は、ピックアップ時間からドライバーが予約をキャンセルするまでの時間です",
        "Dispatch_setting_Dispatch_mode_tooltip": "- 手動モード: 予約が作成されると、予約は保存され、コマンド センターのキューに「保留中」のステータスで保持されます。予約の詳細で「予約を更新」をクリックすると、予約は割り当てられたドライバーに、またはドライバーが割り当てられていない場合はすべてのドライバーにディスパッチされます。ドライバーのデバイス カレンダーに統合され、リマインダーが利用可能になります。<br>\n- 遅延モード: 予約が作成されると、予約は保存され、コマンド センターのキューに「保留中」のステータスで保持されます。予約は、ピックアップ時間から「「事前に処理」の時間値」より前に、割り当てられたドライバーまたはドライバーが割り当てられていない場合は最も近い利用可能なドライバーに自動的にディスパッチされます。<br>\n- 即時自動ディスパッチ モード: 予約が作成されると、予約はすべてのドライバーに自動的にディスパッチされます。ドライバーのデバイス カレンダーに統合され、リマインダーが利用可能になります。",
        "Notification_nearby_drivers_before_pickup_time": "近隣ドライバーへの通知（ピックアップ時間前）",
        "Dispatch_setting_Maximum_Day_tooltip": "同日に多くの予約を持つことを制限するために適用されます（マーケットプレイスおよび都市間適用）",
        "Maximum_Accept_Reservation_tooltip": "ドライバーが受け入れられる予約数の上限（マーケットプレイスおよび都市間適用）",
        "Notification_nearby_drivers_before_pickup_time_tooltip": "設定時間前までに予約がドライバーに受け入れられなかった場合、システムが近隣ドライバーへ通知を送信し、マーケットプレイスを確認して予約を受け入れるよう促します。",
        "marketPlace": "マーケットプレイス",
        "howFarDriverSeeBookingMarketplace": "ドライバーがマーケットプレイスで予約を確認できる範囲",
        "shortNoticeOrder": "短時間通知注文",
        "shortNoticeOrderTooltip": "予約が緊急と見なされ、すべての階層にすぐに見えるようにする時間枠を設定します。"
    },
    "voiceSMSSetting": {
        "ACCOUNT_SETTINGS": "アカウント設定",
        "Account_SID": "アカウントSID",
        "Auth_Token": "認証トークン",
        "Application_ID": "アプリケーションID",
        "SMS_number_1": "SMS番号 1",
        "SMS_number_2": "SMS番号 2",
        "SMS_SETTINGS": "SMS設定",
        "MASKED_PHONE_SETTINGS": "マスキング電話設定",
        "SMS_MARKETING_SETTINGS": "SMSマーケティング設定",
        "Phone_number": "電話番号",
        "Save": "保存",
        "voiceSMS": "TWILIO",
        "DRIVER_APP": "ドライバーアプリ",
        "PASSENGER_APP": "乗客アプリ",
        "allowDriverToCallCC": "ドライバーがヘルプデスクに電話することを許可:",
        "allowDriverToCallHelpdesk": "乗客がヘルプデスクに電話することを許可:",
        "allowDriverToCallPassenger": "乗客がドライバーに電話することを許可:",
        "allowPassengerToCallCC": "乗客がヘルプデスクに電話できるようにする:",
        "allowPassengerToCallHelpdesk": "乗客がヘルプデスクに電話できるようにする:",
        "allowPassengerToCallDriver": "乗客がドライバーに電話できるようにする:",
        "voip": "VOIP",
        "mobile": "モバイル",
        "bookingSMS": "メッセージテンプレート",
        "bookingSMSTemplate": {
            "name": "名前",
            "content": "内容",
            "action": "アクション",
            "DELETE": "メッセージテンプレートを削除",
            "DELETE_CONFIRM": "選択したメッセージテンプレートを削除してもよろしいですか？",
            "EDIT": "メッセージテンプレートを編集",
            "ADD": "メッセージテンプレートを追加",
            "DETAIL": "メッセージテンプレートの詳細",
            "Update_success": "メッセージテンプレートが正常に更新されました",
            "Create_success": "メッセージテンプレートが正常に作成されました",
            "Delete_success": "メッセージテンプレートが正常に削除されました",
            "Delete_fail": "メッセージテンプレートの削除中にエラーが発生しました",
            "Load_fail": "メッセージテンプレートの読み込み中にエラーが発生しました",
            "Update_fail": "メッセージテンプレートの更新中にエラーが発生しました",
            "Create_fail": "メッセージテンプレートの作成中にエラーが発生しました"
        },
        "bookingSMSModal": {
            "title": "SMSテンプレートを追加",
            "save": "保存",
            "cancel": "キャンセル",
            "titleMessage": "メッセージを送信",
            "template": "テンプレート",
            "phoneNumber": "電話番号",
            "content": "内容",
            "messageType": "メッセージタイプ",
            "inbox": "受信トレイ",
            "sms": "SMS",
            "sendTo": "送信先",
            "passenger": "乗客",
            "driver": "ドライバー"
        }
    },
    "regularSetting": {
        "extraWaitTimeTooltip": "乗客が乗車するまでの追加待機時間に対するドライバー補償費用",
        "fareWaitTimeLater": "分単位の運賃（予約）",
        "fareWaitTimeNow": "分単位の運賃（即時）",
        "transport": "輸送",
        "delivery": "配送",
        "Fer_Stop": "停車料金",
        "First_Stop": "最初の停車料金",
        "Each_Stop": "追加停車ごとの料金",
        "Name": "名前",
        "Pay_to_driver_Cash": "ドライバーへの支払い（現金予約）",
        "Last_Update": "最終更新",
        "Status": "ステータス",
        "Required_fields": "必須項目",
        "Yes": "はい",
        "No": "いいえ",
        "Cancel": "キャンセル",
        "Save": "保存",
        "Actions": "アクション",
        "Edit": "編集",
        "Add": "追加",
        "Show_per_page": "ページごとのアイテム数",
        "Page": "ページ",
        "show_pages": "{0} - {1} / {2} を表示中",
        "DELETE_CONFIRM": "削除しますか？",
        "ADD_REGULAR_RATE": "通常運賃を追加",
        "EDIT_REGULAR_RATE": "通常運賃を編集",
        "DETAIL_REGULAR_RATE": "通常運賃の詳細",
        "DELETE_REGULAR_RATE": "通常運賃を削除",
        "Delete_confirm_regular_rate": "このアイテムを削除しますか？",
        "STARTING": "開始",
        "Starting_Now": "開始（即時）",
        "Starting_Reservation": "開始（予約）",
        "FIRST_DISTANCE_FEE": "最初の距離料金",
        "First_distance_fee": "最初の距離",
        "From": "開始地点",
        "To": "終了地点",
        "Fee_for_first_distance": "最初の距離料金",
        "SECOND_DISTANCE_FEE": "次の距離料金",
        "Second_distance_fee": "次の距離",
        "AFTER_SECOND_DISTANCE": "次の距離以降",
        "Fee_after_second_distance": "次の距離以降の料金",
        "PER_MINUTE": "分単位",
        "Fee_per_minute": "分単位の運賃",
        "mi": "マイル",
        "hour": "時間",
        "MINIMUM": "最低料金",
        "Minimum_Now": "最低料金（即時）",
        "Minimum_Reservation": "最低料金（予約）",
        "PAX_MDISPATCHER_CANCELLATION": "乗客/パートナーキャンセル",
        "On_demand": "乗客/パートナーキャンセル - オンデマンド",
        "Penalty": "ペナルティ金額",
        "In_advance": "乗客/パートナーキャンセル - 事前予約",
        "PAX_MDISPATCHER_NO_SHOW": "乗客/パートナーノーショー",
        "Driver_cancel_demand": "ドライバーキャンセルポリシー - オンデマンド",
        "Driver_cancel_advance": "ドライバーキャンセルポリシー - 事前予約",
        "Assign_car_type": "車両タイプを割り当てる",
        "Select_car_type": "車両タイプを選択",
        "Active": "アクティブ",
        "Inactive": "非アクティブ",
        "Deactivate": "無効化",
        "DELETE_FLATE_RATE": "固定料金を削除",
        "Delete_confirm_flat_rate": "このアイテムを削除しますか？",
        "EDIT_FLAT_RATE": "固定料金を編集",
        "ADD_FLAT_RATE": "固定料金を追加",
        "DELETE_FLAT_RATE": "固定料金を削除",
        "DELETE_HOURLY_RATE": "時間・日単位料金を削除",
        "Delete_confirm_hour_rate": "このアイテムを削除しますか？",
        "EDIT_HOURLY_RATE": "時間・日単位料金を編集",
        "ADD_HOURLY_RATE": "時間・日単位料金を追加",
        "DELETE_FLAT_ROUTE": "固定ルートを削除",
        "DELETE_ROUTE_CONFIRM": "ルートを削除してもよろしいですか？",
        "EDIT_ROUTE": "ルートを編集",
        "ADD_ROUTE": "ルートを追加",
        "Route_name": "ルート名",
        "Route_type": "ルートタイプ",
        "Zone_to_zone": "ゾーン間",
        "Zipcode_to_zipcode": "郵便番号間",
        "First_location": "最初の地点",
        "Second_location": "2番目の地点",
        "First_location_zip_note": "*備考: 「,」を使用して複数の郵便番号を同時に入力できます。連続するコードの場合は、1つずつ入力する代わりに「->」を使用してください（例: 90001 -> 90099, 90101, 90102, 90103, 90174, 90185）。",
        "Second_location_zip_note": "*備考: 「,」を使用して複数の郵便番号を同時に入力できます。連続するコードの場合は、1つずつ入力する代わりに「->」を使用してください（例: 90001 -> 90099, 90101, 90102, 90103, 90174, 90185）。",
        "SINGLE_TRIP_SETUP": "片道設定",
        "Route_1": "ルート1（最初の地点 -> 2番目の地点）",
        "Route_2": "ルート2（2番目の地点 -> 最初の地点）",
        "Limitation": "制限",
        "Unlimited": "無制限",
        "Limited": "制限あり",
        "Time_coverage": "適用時間",
        "Extra_time": "追加時間",
        "ROUND_TRIP_SETUP": "往復設定",
        "Round_trip": "往復",
        "ROUTES": "ルート",
        "Route_1_Fare": "ルート1運賃",
        "Route_2_Fare": "ルート2運賃",
        "Round_Trip_Fare": "往復運賃",
        "Select_a_zone": "ゾーンを選択",
        "minute": "分",
        "VIEW_REGULAR_RATE": "通常料金の詳細を見る",
        "VIEW_FLAT_RATE": "固定料金を見る",
        "VIEW_HOURLY_RATE": "時間料金を見る",
        "HOURLY_PACKAGE_RATE": "時間パッケージ料金",
        "Package_name": "パッケージ名",
        "Package_duration": "パッケージ期間",
        "PackageId": "パッケージID",
        "Base_rate": "基本料金",
        "Distance_coverage": "距離範囲",
        "Extra_duration": "追加時間",
        "Extra_distance": "追加距離",
        "DELETE_HOURLY_PACKAGE": "時間パッケージを削除",
        "DELETE_PACKAGE_CONFIRM": "パッケージ料金を削除しますか？",
        "EDIT_PACKAGE": "パッケージを編集",
        "VIEW_PACKAGE": "パッケージ詳細を見る",
        "ADD_PACKAGE": "パッケージを追加",
        "Fee_per_extra_duration": "追加時間ごとの料金",
        "Fee_per_extra_distance": "追加距離ごとの料金",
        "hours": "時間",
        "days": "日",
        "Update_flat_rate_success": "固定料金が正常に更新されました",
        "Update_flat_rate_fail": "固定料金の更新中にエラーが発生しました",
        "Created_flat_rate_success": "固定料金が正常に作成されました",
        "Created_flat_rate_fail": "固定料金の作成中にエラーが発生しました",
        "Update_flat_rate_route_success": "固定ルートが正常に更新されました",
        "Update_flat_rate_route_fail": "固定ルートの更新中にエラーが発生しました",
        "Created_flat_rate_route_success": "固定ルートが正常に作成されました",
        "Created_flat_rate_route_fail": "固定ルートの作成中にエラーが発生しました",
        "Loading_flat_rate_fail": "固定料金の詳細を読み込む際にエラーが発生しました",
        "Delete_flat_rate_route_fail": "固定ルートの削除中にエラーが発生しました",
        "Delete_flat_rate_success": "固定料金が正常に削除されました",
        "Delete_flat_rate_fail": "固定料金の削除中にエラーが発生しました",
        "Update_intercity_rate_success": "都市間料金が正常に更新されました",
        "Update_intercity_rate_fail": "都市間料金の更新中にエラーが発生しました",
        "Created_intercity_rate_success": "都市間料金が正常に作成されました",
        "Created_intercity_rate_fail": "都市間料金の作成中にエラーが発生しました",
        "Update_intercity_rate_route_success": "都市間ルート料金が正常に更新されました",
        "Update_intercity_rate_route_fail": "都市間ルート料金の更新中にエラーが発生しました",
        "Created_intercity_rate_route_success": "都市間ルート料金が正常に作成されました",
        "Created_intercity_rate_route_fail": "都市間ルート料金の作成中にエラーが発生しました",
        "Loading_intercity_rate_fail": "都市間料金の詳細を読み込む際にエラーが発生しました",
        "Delete_intercity_rate_route_fail": "都市間ルート料金の削除中にエラーが発生しました",
        "Delete_intercity_rate_success": "都市間料金が正常に削除されました",
        "Delete_intercity_rate_fail": "都市間料金の削除中にエラーが発生しました",
        "Update_regular_rate_success": "通常料金が正常に更新されました",
        "Update_regular_rate_fail": "通常料金の更新中にエラーが発生しました",
        "Create_regular_rate_success": "通常料金が正常に作成されました",
        "Create_regular_rate_fail": "通常料金の作成中にエラーが発生しました",
        "Update_hourly_rate_success": "時間料金が正常に更新されました",
        "Update_hourly_rate_fail": "時間料金の更新中にエラーが発生しました",
        "Create_hourly_rate_success": "時間料金が正常に作成されました",
        "Create_hourly_rate_fail": "時間料金の作成中にエラーが発生しました",
        "Update_hourly_rate_package_success": "時間料金パッケージが正常に更新されました",
        "Update_hourly_rate_package_fail": "時間料金パッケージの更新中にエラーが発生しました",
        "Create_hourly_rate_package_success": "時間料金パッケージが正常に作成されました",
        "Create_hourly_rate_package_fail": "時間料金パッケージの作成中にエラーが発生しました",
        "Load_hourly_rate_fail": "時間料金の詳細を読み込む際にエラーが発生しました",
        "Update_hourly_status": "時間料金ステータスの更新中にエラーが発生しました",
        "Delete_hourly_fail": "時間料金が正常に削除されました",
        "Delete_hourly_success": "時間料金の削除中にエラーが発生しました",
        "Loading_regular_rate_fail": "通常料金の詳細を読み込む際にエラーが発生しました",
        "Update_regular_status_fail": "通常料金ステータスの更新中にエラーが発生しました",
        "Delete_regular_rate_success": "通常料金が正常に削除されました",
        "Delete_regular_rate_fail": "通常料金の削除中にエラーが発生しました",
        "Please_go_to_Car_Type_settings": "この料金を適用するには、「設定」>「車両」>「車両タイプ」に移動してください。",
        "Car_type_filter": "車両タイプフィルター",
        "Delete_package_error": "パッケージの削除中にエラーが発生しました！",
        "Pax_mDispatcher_cancellation_tooltip": "キャンセルポリシー（乗客向け）",
        "Penalty_tooltip": "顧客に請求される金額（ペナルティに加えて税金や技術料金を追加できます）。",
        "Pax_mDispatcher_no_show_tooltip": "乗客にはノーショーポリシーが適用されます。ドライバーがピックアップ場所で乗客に会わずに「ノーショー」をタップすると、この料金が請求されます。",
        "detail_route": "ルートの詳細",
        "detail_package": "パッケージの詳細",
        "THIRD_DISTANCE_FEE": "3番目の距離料金",
        "AFTER_DISTANCE_FEE": "3番目の距離以降の料金",
        "Loading_delivery_rate_fail": "配送料金の詳細を読み込む際にエラーが発生しました",
        "Update_delivery_rate_fail": "配送料金の更新中にエラーが発生しました",
        "Update_delivery_rate_success": "配送料金が正常に更新されました",
        "Created_delivery_rate_success": "配送料金が正常に作成されました",
        "Created_delivery_rate_fail": "配送料金の作成中にエラーが発生しました",
        "Starting_fee": "開始料金",
        "First_distance": "最初の距離",
        "Second_distance": "2番目の距離",
        "Third_distance": "3番目の距離",
        "Fee_for_second_distance": "2番目の距離の料金",
        "Fee_for_third_distance": "3番目の距離の料金",
        "Fee_after_third_distance": "3番目の距離以降の料金",
        "Assign_rate_to_car_type": "車両タイプへの料金割り当て",
        "car_type": "車両タイプ",
        "PRICE_ADJUSTABLE": "価格調整可能",
        "Amount_Step": "価格調整可能",
        "Tax": "税金",
        "TechFee": "技術料金",
        "Pay_to_driver": "ドライバーへの支払い",
        "Pay_to_driver_tooltip": "ドライバーへの補償オプション:\n- 固定金額: ドライバーが受け取る固定金額。\n- 手数料: ペナルティ金額に適用されるドライバーの設定手数料。\n",
        "Fix_amount": "定額",
        "Commission": "手数料",
        "fareFlat": "運賃定額",
        "flatRoute": "定額ルート",
        "Mode": "モード",
        "speedThresholdKM": "速度閾値 (km/h)",
        "speedThresholdMI": "速度閾値 (mi/h)",
        "timeAndMileage": "時間と距離",
        "timeOrMileage": "時間または距離",
        "perDistanceKM": "1キロあたり",
        "perDistanceMI": "1マイルあたり",
        "perTime": "1分あたり",
        "regularRates": "通常料金",
        "newRate": "新しい料金",
        "enterName": "料金名を入力",
        "actualMode": "実際の運賃",
        "estimatedMode": "見積もり運賃",
        "feePerStop": "追加停車ごとの料金",
        "extraWaitTime": "追加待機時間",
        "startingFee": "開始",
        "minimumFee": "最小",
        "minAppend": "/分",
        "kmAppend": "/km",
        "miAppend": "/mi",
        "kmhrAppend": "km/h",
        "mihrAppend": "mi/h",
        "feePerKm": "キロメートルごと",
        "feePerMi": "マイルごと",
        "feeSpeedThreshold": "速度閾値",
        "serviceType": "サービスタイプ",
        "fareMode": "計算式",
        "calculationMode": "計算モード",
        "timeChangeMode": "時間料金モード",
        "minimum_Percent_Adjust": "見積運賃の最小%",
        "maximum_Percent_Adjust": "見積運賃の最大%",
        "AllowAdjustPrice": "顧客が価格を調整できるようにする",
        "Calculation": "計算",
        "distanceTier": "距離階層",
        "perKm": "キロメートルごとの料金",
        "perMi": "マイルごとの料金",
        "flatFee": "定額料金",
        "defaultRate": "デフォルト料金",
        "timeRange": "時間範囲",
        "bookingType": "予約タイプ",
        "rateDetail": "料金詳細",
        "subRate": "サブ料金",
        "specialRates": "特別料金",
        "surcharge": "追加料金",
        "penalty": "ペナルティ",
        "basedOriginMode": "元のピックアップ時間に基づく",
        "basedDriverMode": "ドライバーのピックアップ時間に基づく",
        "hourlyRate": "時間料金",
        "flat_Fare": "定額料金",
        "flatFeeOption": "定額料金",
        "fareModeTooltip": "時間または走行距離は顧客料金にのみ適用され、サプライヤー/ドライバーの支払い料金には適用されません。",
        "cloneRate": "Clone Rate",
        "rateName": "Rate Name",
        "Infinity": "無限",
        "Fee": "料金",
        "headerTile": "ヘッダーのタイトルを入力",
        "enterFooter": "フッターのタイトルを入力",
        "invoiceNo": "請求書番号",
        "paidOn": "支払日",
        "amountPaid": "支払額",
        "isFeePerSeatActive": "Charge per passenger",
        "minSeat": "Minimum number of passengers",
        "minSeatTooltip": "This is the minimum number of seats to charge for a booking."
    },
    "flatRateSettings": {
        "flatZoneNote": "ここで作成したゾーンは、定額料金の設定にのみ使用されます。"
    },
    "deliverySetting": {
        "Name": "名前",
        "Last_Update": "最終更新",
        "Status": "ステータス",
        "Actions": "操作"
    },
    "sharingSetting": {
        "EDIT_RATE": "ストリートシェアリング料金を編集",
        "ADD_RATE": "ストリートシェアリング料金を追加",
        "VIEW_RATE": "ストリートシェアリング料金を表示",
        "Name": "名前",
        "startingFee": "初乗り料金",
        "minimumFee": "最低運賃",
        "Update_streetSharing_rate_fail": "ストリートシェアリング料金の更新中にエラーが発生しました",
        "Update_streetSharing_rate_success": "ストリートシェアリング料金が正常に更新されました",
        "Created_streetSharing_rate_success": "ストリートシェアリング料金が正常に作成されました",
        "Created_streetSharing_rate_fail": "ストリートシェアリング料金の作成中にエラーが発生しました",
        "Loading_streetSharing_rate_fail": "ストリートシェアリング料金の詳細を読み込む際にエラーが発生しました",
        "Delete_streetSharing_rate_success": "ストリートシェアリング料金が正常に削除されました",
        "Delete_streetSharing_rate_fail": "ストリートシェアリング料金の削除中にエラーが発生しました",
        "Delete_streetSharing_rate": "ストリートシェアリング料金を削除",
        "Assign_Car": "車種を割り当てる"
    },
    "carTypeSetting": {
        "newService": "新しいサービス",
        "editService": "サービスを編集",
        "serviceInfo": "サービス情報",
        "name": "名前",
        "namePH": "車種名",
        "enterNumber": "数字を入力してください",
        "appDisplayName": "アプリ表示名",
        "shortDescription": "短い説明",
        "maxPassengers": "最大乗客数",
        "maxLuggage": "最大荷物数",
        "vehiclePhoto": "車両写真",
        "iconShowList": "リストに表示するアイコン",
        "imageShowWeb": "ウェブに表示する画像",
        "carPinOnMap": "地図上の車両PIN",
        "recommendedSize": "推奨サイズ：",
        "serviceSetting": "サービス設定",
        "serviceType": "サービスの種類",
        "reservation": "予約",
        "onDemand": "オンデマンド",
        "destinationSetting": "目的地設定",
        "requireDestination": "目的地必須",
        "extraDestination": "追加目的地",
        "calculatorFareMode": "運賃計算モード",
        "hideDestinationOnDemand": "オンデマンド",
        "meteredFare": "メーター運賃",
        "quotedFare": "見積運賃",
        "availableZones": "利用可能ゾーン",
        "dispatchAssociation": "配車関連",
        "Save": "保存",
        "Edit": "編集",
        "Cancel": "キャンセル",
        "validateName": "名前を入力してください",
        "validateAppDisplayName": "アプリ表示名を入力してください",
        "validateSelectImage": "画像を選択してください",
        "updateSuccess": "正常に更新されました",
        "createSuccess": "正常に作成されました",
        "failApi": "何か問題が発生しました！",
        "validateRequire": "乗客数を指定し、荷物を再確認してください。"
    },
    "carSetting": {
        "Details": "詳細",
        "vehicleInfo": "車両情報",
        "Documents": "書類",
        "vehicle": "車両",
        "addVehicle": "車両を追加",
        "detailVehicle": "車両詳細",
        "additionalVehicleTitle": "追加の車両情報",
        "Phone_Number_Tooltip": "顧客向けの専用電話番号を入力してください。提供しない場合は、ドライバーの個人番号にデフォルト設定されます。専用の連絡先を備えた車両を持つサプライヤー向けに最適です。",
        "discardChange": "変更を破棄",
        "model_name_is_required": "モデル名は必須です",
        "DELETE_CAR": "車を削除",
        "DELETE_CONFIRM": "選択した車両を削除しますか？",
        "Yes": "はい",
        "Cancel": "キャンセル",
        "EDIT_CAR": "車を編集",
        "CREATE_CAR": "車を作成",
        "Plate_Number": "ナンバープレート",
        "Car_Type": "車種",
        "Select_car_type": "車種を選択",
        "Make": "メーカー",
        "Select_car_make": "メーカーを選択",
        "Model": "モデル",
        "Select_a_car_model": "モデルを選択",
        "Year": "製造年",
        "Select_year": "年を選択",
        "Hardware_Meter": "ハードウェアメーター",
        "VIN": "車両識別番号（VIN）",
        "Company": "会社",
        "Select_company": "会社を選択",
        "Max_Passengers": "最大乗客数",
        "Max_Luggage": "最大荷物数",
        "Color": "カラー",
        "License_expiry": "ライセンス有効期限",
        "Required_fields": "必須項目",
        "Save": "保存",
        "Add": "追加",
        "Car_Model": "車両モデル",
        "Created_Date": "作成日",
        "Status": "ステータス",
        "Actions": "操作",
        "Show_per_page": "ページごとのアイテム数",
        "Page": "ページ",
        "show_pages": "{0} - {1} / {2} 表示中",
        "Active": "アクティブ",
        "activeVehicle": "アクティブな車両",
        "Inactive": "非アクティブ",
        "Edit": "編集",
        "Deactivate": "無効化",
        "Activate": "有効化",
        "Delete": "削除",
        "Name": "名前",
        "MODEL_LIST": "モデルリスト",
        "Model_Name": "モデル名",
        "Add_Model": "モデルを追加",
        "DELETE_CAR_MODEL": "車両モデルを削除",
        "DELETE_CONFIRM_MODEL": "この車両モデルを削除しますか？",
        "DELETE_CAR_MAKE": "車両メーカーを削除",
        "DELETE_CONFIRM_CAR_MAKE": "この車両メーカーを削除しますか？",
        "EDIT_CAR_MAKE": "車両メーカーを編集",
        "CREATE_CAR_MAKE": "車両メーカーを追加",
        "Assign_Rate": "料金を割り当てる",
        "ASSIGN_RATE": "料金を割り当てる",
        "Car_type_ID": "車種ID",
        "App_Display_Name": "アプリ表示名",
        "Affiliate_car_type": "アフィリエイト車種",
        "Regular_rate": "通常料金",
        "Zone": "ゾーン",
        "Rate": "料金",
        "Zone_Rate": "ゾーン - 料金",
        "Flat_Fare": "定額料金",
        "Flat_Delivery": "配送定額",
        "Select_flat_rate": "定額料金を選択",
        "Hourly_daily_rate": "時間/日単位",
        "Dispatch_To": "配車先",
        "Normal_Fare": "通常",
        "Upload_an_image": "画像をアップロード",
        "DETAIL_CAR": "車両詳細",
        "DELETE_MULTI_CAR": "選択した車両を削除",
        "DELETE_MULTI_CAR_CONFIRM": "これらの車両を削除しますか？非アクティブ状態の車両のみ削除可能です。",
        "DETAIL_CAR_MAKE": "車両メーカー",
        "Car_Make": "車両メーカー",
        "Create_car_success": "新しい車両が正常に作成されました",
        "Update_car_success": "この車両が正常に更新されました",
        "Update_car_fail": "車両の更新中にエラーが発生しました",
        "Create_car_fail": "車両の作成中にエラーが発生しました",
        "Load_car_fail": "車両の読み込み中にエラーが発生しました",
        "Delete_car_fail": "車両の削除中にエラーが発生しました",
        "Delete_car_success": "車両が正常に削除されました",
        "Change_cars_status_success": "すべての車両ステータスが正常に変更されました",
        "Change_cars_status_fail": "車両ステータスの変更中にエラーが発生しました",
        "Delete_cars_success": "選択したすべての車両が正常に削除されました",
        "Delete_cars_fail": "車両の削除中にエラーが発生しました",
        "Update_car_type_success": "車種が正常に更新されました",
        "Update_car_type_fail": "車種の更新中にエラーが発生しました",
        "Load_car_type_details_fail": "車種の詳細を読み込めませんでした",
        "Update_car_make_fail": "車両メーカーの更新中にエラーが発生しました",
        "Update_car_make_success": "車両メーカーが正常に更新されました",
        "Create_car_make_fail": "車両メーカーの作成中にエラーが発生しました",
        "Create_car_make_success": "新しい車両メーカーが正常に作成されました",
        "Update_car_model_fail": "車両モデルの更新中にエラーが発生しました",
        "Update_car_model_success": "車両モデルが正常に更新されました",
        "Create_car_model_fail": "車両モデルの作成中にエラーが発生しました",
        "Create_car_model_success": "新しい車両モデルが正常に作成されました",
        "Loading_car_model_fail": "車両モデルの読み込み中にエラーが発生しました",
        "Delete_car_make_success": "車両メーカーが正常に削除されました",
        "Delete_cat_make_fail": "車両メーカーの削除中にエラーが発生しました",
        "Delete_regular_rate_success": "通常料金の削除が成功しました",
        "Delete_regular_rate_fail": "通常料金の削除にエラーが発生しました",
        "Update_status_error": "ステータス更新エラー",
        "Case_number": "ケース番号",
        "Phone_Number": "電話番号",
        "ERROR_INPUT_VALIDPHONE": "有効な電話番号を入力してください。",
        "Car_type_filter": "車種フィルター",
        "No": "いいえ",
        "Intercity_Rate": "都市間料金",
        "Select_intercity_rate": "都市間料金を選択",
        "Delivery_Rate": "配送料金",
        "Select_delivery_rate": "配送料金を選択",
        "vehicleOwner": "車両所有者の名前",
        "EDIT_CAR_COLOR": "車両カラーを編集",
        "DETAIL_CAR_COLOR": "車両カラー詳細",
        "CREATE_CAR_COLOR": "車両カラーを追加",
        "Car_Color": "車両カラー",
        "Update_car_color_fail": "車両カラーの更新中にエラーが発生しました",
        "Update_car_color_success": "車両カラーが正常に更新されました",
        "Create_car_color_fail": "車両カラーの作成中にエラーが発生しました",
        "Create_car_color_success": "新しい車両カラーが正常に作成されました",
        "Delete_car_color_success": "車両カラーが正常に削除されました",
        "Delete_car_color_fail": "車両カラーの削除中にエラーが発生しました",
        "DELETE_CAR_COLOR": "車両カラーを削除",
        "DELETE_CONFIRM_CAR_COLOR": "この車両カラーを削除しますか？",
        "sharing_Rate": "ストリートシェアリング料金",
        "bookingAffiliate": "アフィリエイト Booking.com 車種",
        "streetSharing": "ストリートシェアリング"
    },
    "operationSetting": {
        "Driver": "ドライバー",
        "Search_driver_name": "ドライバー名を検索",
        "Company": "会社",
        "License_plate": "ナンバープレート",
        "Select_license_plate": "ナンバープレートを選択",
        "Shift": "シフト",
        "Zone": "ゾーン",
        "Required_fields": "必須項目",
        "Save": "保存",
        "No": "番号",
        "Car_Type": "車種",
        "Plate_Number": "ナンバープレート",
        "Unassign": "割り当て解除",
        "Please_choose_driver": "ドライバーを選択してください。",
        "Please_choose_company": "会社を選択してください。",
        "Please_license_plate": "ナンバープレートを選択してください。",
        "Please_choose_shift": "シフトを選択してください。",
        "Please_choose_zone": "ゾーンを選択してください。",
        "CONFIRM_UNASSIGN": "このドライバーの割り当てを解除しますか？",
        "Assign_success": "運用が正常に割り当てられました",
        "Unassign_success": "ドライバーの割り当てが正常に解除されました。"
    },
    "paymentMethod": {
        "cash": "現金",
        "easyPaisa": "Easy Paisa",
        "jazzCash": "Jazz Cash",
        "cashBySender": "送信者による現金支払い",
        "cashByRecipient": "受取人による現金支払い",
        "personalCard": "個人カード",
        "corporateCard": "法人カード",
        "qrCodePayment": "QRコード決済",
        "directBilling": "直接請求",
        "externalCard": "外部カード",
        "mDispatcherCard": "パートナーカード",
        "corporatePrepaid": "法人プリペイド",
        "fleetCard": "外部カード",
        "applePay": "Apple Pay",
        "creditExternalCard": "クレジットカード",
        "googlePay": "Google Pay",
        "wallet": "ウォレット",
        "BankOfGeorgia": "ジョージア銀行",
        "tnGeWalletlet": "TnG eWallet",
        "paymentMethod_1": "現金",
        "paymentMethod_2": "個人カード",
        "paymentMethod_3": "パートナーカード",
        "paymentMethod_4": "法人カード",
        "paymentMethod_5": "直接請求書発行",
        "paymentMethod_6": "外部カード",
        "paymentMethod_7": "法人プリペイド",
        "paymentMethod_8": "QR コード決済",
        "paymentMethod_9": "Apple Pay",
        "paymentMethod_11": "Airpay",
        "paymentMethod_12": "クレジットカード",
        "paymentMethod_13": "ウォレット",
        "paymentMethod_14": "TnG eWallet",
        "paymentMethod_16": "送金者による現金",
        "paymentMethod_17": "受取人による現金",
        "paymentMethod_19": "Boost eWallet",
        "paymentMethod_20": "Vipps",
        "paymentMethod_21": "ZainCash",
        "paymentMethod_23": "Google Pay",
        "paymentMethod_25": "Easy Paisa",
        "paymentMethod_26": "Jazz Cash",
        "vippseWallet": "Vipps",
        "paymentMethod_credit": "個人カード",
        "paymentMethod_razerPay": "RazerPay",
        "paymentMethod_gCash": "GCash",
        "paymentMethod_zainCash": "ZainCash",
        "paymentMethod_xenDit": "Xendit",
        "paymentMethod_FIB": "FIB",
        "paymentMethod_SantimPay": "SantimPay",
        "paymentMethod_ksher": "Ksher",
        "paymentMethod_paydunya": "PayDunya",
        "paymentMethod_fawry": "Fawry",
        "paymentMethod_BankOfGeorgia": "Bank Of Georgia",
        "paymentLink": "支払いリンク",
        "paymentMethod_xendit": "Xendit",
        "paymentMethod_easyPaisa": "Easy Paisa",
        "paymentMethod_jazzCash": "Jazz Cash",
        "paymentMethod_flooz": "Flooz",
        "paymentMethod_tmoney": "Tmoney",
        "paymentMethod_corporateCard": "法人カード"
    },
    "changePassword": {
        "Change_password": "パスワードの変更",
        "Current_password": "現在のパスワード",
        "New_password": "新しいパスワード",
        "Confirm_password": "パスワードの確認",
        "ERROR_INPUT_PASSWORD": "パスワードを入力してください",
        "ERROR_INPUT_VALID_PASSWORD": "パスワードには 8 文字以上、1 つの数字、および特殊文字が含まれている必要があります",
        "ERROR_INPUT_MATCH_PASSWORD": "パスワードが一致しません。もう一度お試しください",
        "Save": "保存",
        "Cancel": "キャンセル"
    },
    "userProfile": {
        "My_account": "マイ アカウント",
        "Username": "ユーザー名",
        "First_name": "名",
        "Last_name": "姓",
        "Phone_number": "電話番号",
        "Email_address": "メールアドレス",
        "Address": "住所",
        "Save": "保存",
        "Edit_profile": "プロフィールの編集",
        "Change_password": "パスワードの変更",
        "ERROR_INPUT_USERNAME": "ユーザー名を入力してください。",
        "ERROR_INPUT_FIRSTNAME": "名を入力してください。",
        "ERROR_INPUT_VALID_EMAIL": "有効なメール アドレスを入力してください。",
        "Required_fields": "必須フィールド"
    },
    "General": {
        "searchQueuingDriver": "検索: ドライバー名、電話番号",
        "search": "検索",
        "User": "ユーザー",
        "My_Account": "マイアカウント",
        "Sign_out": "サインアウト",
        "My_site": "マイサイト",
        "Command_center": "コマンドセンター",
        "Notifications": "通知",
        "Show_all": "すべて表示",
        "SOS_Notifications": "SOS 通知",
        "You_dont_have_any_message": "メッセージはありません",
        "Mon": "月",
        "Tue": "火",
        "Wed": "水",
        "Thu": "木",
        "Fri": "金",
        "Sat": "土",
        "Sun": "日",
        "Now": "現在",
        "NowComplete": "現在（実際のピックアップ時間）",
        "hrs": "時間",
        "patmentMethod_1": "現金",
        "patmentMethod_2": "個人カード",
        "patmentMethod_3": "パートナーカード",
        "patmentMethod_4": "法人カード",
        "patmentMethod_5": "直接請求",
        "patmentMethod_6": "外部カード",
        "patmentMethod_7": "法人プリペイド",
        "patmentMethod_8": "QR コード",
        "patmentMethod_9": "Apple Pay",
        "patmentMethod_10": "Apple Pay",
        "patmentMethod_12": "クレジットカード",
        "patmentMethod_13": "ウォレット",
        "patmentMethod_14": "TnG eWallet",
        "patmentMethod_16": "送金者による現金",
        "patmentMethod_17": "受取人による現金",
        "patmentMethod_19": "Boost",
        "patmentMethod_20": "Vipps",
        "patmentMethod_23": "Google Play",
        "patmentMethod_24": "支払いリンク",
        "patmentMethod_25": "Easy Paisa",
        "patmentMethod_26": "Jazz Cash",
        "patmentMethod_ZainCash": "ZainCash",
        "patmentMethod_Xendit": "Xendit",
        "patmentMethod_FIB": "FIB",
        "patmentMethod_SantimPay": "SantimPay",
        "patmentMethod_GCash": "GCash",
        "patmentMethod_cash": "現金",
        "patmentMethod_credit": "個人カード",
        "patmentMethod_mDispatcher": "パートナーカード",
        "patmentMethod_corpCredit": "法人カード",
        "patmentMethod_directBilling": "直接請求",
        "patmentMethod_fleetCard": "外部カード",
        "patmentMethod_prepaid": "法人プリペイド",
        "patmentMethod_QRCode": "QR コード",
        "patmentMethod_applePay": "Apple Pay",
        "patmentMethod_easyPaisa": "Easy Paisa",
        "patmentMethod_jazzCash": "Jazz Cash",
        "patmentMethod_prePaid": "法人プリペイド",
        "patmentMethod_GooglePay": "Google Pay",
        "patmentMethod_creditCardExternal": "クレジットカード",
        "patmentMethod_PaxWallet": "ウォレット",
        "patmentMethod_TnGeWallet": "TnG eWallet",
        "patmentMethod_boosteWallet": "Boost",
        "patmentMethod_Vipps": "Vipps",
        "patmentMethod_exZainCash": "ZainCash",
        "patmentMethod_exGCash": "GCash",
        "patmentMethod_exPayTM": "PayTM",
        "patmentMethod_exFlooz": "Flooz",
        "patmentMethod_exTmoney": "Tmoney",
        "patmentMethod_exTelebirr": "GCash",
        "paymentMethod_exzainCash": "ZainCash",
        "paymentMethod_exXendit": "Xendit",
        "paymentMethod_exFIB": "FIB",
        "paymentMethod_exSantimPay": "SantimPay",
        "patmentMethod_exKsher": "Ksher",
        "paymentMethod_paydunya": "PayDunya",
        "paymentMethod_exfawry": "Fawry",
        "patmentMethod_exBankOfGeorgia": "Bank Of Georgia",
        "patmentMethod_exPayDunya": "PayDunya",
        "patmentMethod_21": "ZainCash",
        "patmentMethod_paymentLink": "支払いリンク",
        "patmentMethod_card": "個人カード",
        "patmentMethod_corporateCard": "法人カード",
        "patmentMethod_paxWallet": "ウォレット",
        "patmentMethod_externalWallet": "外部ウォレット",
        "patmentMethod_googlePay": "Google Pay",
        "patmentMethod_manual": "手動",
        "patmentMethod_online": "Card/Online Payment",
        "patmentMethod_check": "Check",
        "patmentMethod_wire": "Wire",
        "patmentMethod_digitalWallet": "Digital Wallet",
        "patmentAirlineMethod_5": "直接請求",
        "patmentAirlineMethod_4": "航空会社カード",
        "patmentAirlineMethod_7": "航空会社プリペイド",
        "Name": "名前",
        "Delete": "削除",
        "App_SOS_dialog_form_message_1": "{0} 緊急SOS！",
        "App_SOS_dialog_form_message_2": "助けが必要です",
        "App_SOS_dialog_form_message_3": "予約: {0}, ドライバー: {1}, 乗客: {2}, ナンバープレート: {3} ({4})",
        "mins": "分",
        "Credit_Card": "クレジットカード",
        "ALL": "すべて",
        "NotFound": "リクエストされたページが見つかりませんでした",
        "ServerError": "問題が発生しました",
        "AccessDenied": "このモジュールにアクセスする権限がありません！",
        "Retry": "再試行",
        "Can_not_get_data": "データを取得できません"
    },
    "shiftSettings": {
        "Add": "追加",
        "Show_per_page": "ページごとのアイテム数",
        "Page": "ページ",
        "Name": "名前",
        "Time_Range": "時間範囲",
        "Actions": "操作",
        "show_pages": "{0} - {1} / {2} 表示中",
        "Edit": "編集",
        "Delete": "削除",
        "EDIT_SHIFT_SETTING": "シフト設定を編集",
        "ADD_SHIFT_SETTING": "シフト設定を追加",
        "DETAIL_SHIFT_SETTING": "シフト設定の詳細",
        "DELETE_SHIFT_SETTING": "シフト設定を削除",
        "Delete_confirm_shift_setting": "この項目を削除しますか？",
        "Required_fields": "必須項目",
        "Save": "保存",
        "Cancel": "キャンセル",
        "From": "開始",
        "To": "終了",
        "Edit_shift_template": "シフトテンプレートを編集",
        "Add_shift_template": "シフトテンプレートを追加",
        "Detail_shift_template": "シフトテンプレートの詳細",
        "Delete_shift_template": "シフトテンプレートを削除",
        "Delete_confirm_shift_template": "この項目を削除しますか？",
        "Working_time": "勤務時間",
        "Create_shift_settings_success": "新しいシフト設定が正常に作成されました",
        "Create_shift_settings_fail": "シフト設定の作成中にエラーが発生しました",
        "Update_shift_settings_success": "シフト設定が正常に更新されました",
        "Update_shift_settings_fail": "シフト設定の更新中にエラーが発生しました",
        "Delete_shift_settings_success": "シフト設定が正常に削除されました",
        "Delete_shift_settings_fail": "シフト設定の削除中にエラーが発生しました",
        "Create_shift_template_success": "新しいシフトテンプレートが正常に作成されました",
        "Create_shift_template_fail": "シフトテンプレートの作成中にエラーが発生しました",
        "Update_shift_template_success": "シフトテンプレートが正常に更新されました",
        "Update_shift_template_fail": "シフトテンプレートの更新中にエラーが発生しました",
        "Delete_shift_template_success": "シフトテンプレートが正常に削除されました",
        "Delete_shift_template_fail": "シフトテンプレートの削除中にエラーが発生しました",
        "Yes": "はい",
        "No": "いいえ"
    },
    "promotionSettings": {
        "Detail_campaign": "キャンペーンの詳細",
        "Edit_campaign": "キャンペーンを編集",
        "Add_campaign": "キャンペーンを追加",
        "Delete_campaign": "キャンペーンを削除",
        "Delete_confirm_campaign": "選択したキャンペーンを削除しますか？",
        "Add": "追加",
        "Corporate_list": "法人リスト",
        "permittedCorporate": "許可された法人リスト",
        "CorporateCustomers": "法人顧客",
        "auto_add_corporate": "法人ユーザーのプロモーションリストに自動追加",
        "Show_per_page": "ページごとのアイテム数",
        "Page": "ページ",
        "Name": "名前",
        "Actions": "操作",
        "Private": "非公開",
        "show_pages": "{0} - {1} / {2} 表示中",
        "Edit": "編集",
        "Delete": "削除",
        "Send_to_inbox": "受信トレイへ送信",
        "Save": "保存",
        "Duplicate": "複製",
        "Cancel": "キャンセル",
        "Required_fields": "必須項目",
        "Promotion_code": "プロモーションコード",
        "Promotion_type": "プロモーションタイプ",
        "Campaign": "キャンペーン",
        "Type": "タイプ",
        "Value": "値",
        "keepMinFee": "プロモ適用後の合計運賃は最低運賃を超える必要があります",
        "Zone": "ゾーン",
        "paymentMethodsApply": "支払い方法",
        "schedules": "スケジュール",
        "minimumEstFareApply": "プロモコード適用のための最低見積もり総運賃",
        "quantityLimitPerYear": "ユーザーあたりの最大使用回数/年",
        "termAndCondition": "利用規約",
        "Valid_from": "有効開始日",
        "Valid_to": "有効終了日",
        "Total_uses_user": "ユーザーあたりの総使用回数",
        "Released_quantity": "発行数量",
        "Number_of_sending_inbox": "受信トレイ送信回数",
        "Status": "ステータス",
        "Activate": "有効化",
        "Deactivate": "無効化",
        "Add_promotion_code": "プロモーションコードを追加",
        "Edit_promotion_code": "プロモーションコードを編集",
        "Detail_promotion_code": "プロモーションコードの詳細",
        "Delete_promotion_code": "プロモーションコードを削除",
        "Delete_confirm_promotion_code": "選択したプロモーションコードを削除しますか？",
        "New_customer": "新規顧客",
        "Budget_per_user": "ユーザーあたりの予算（プロモーション期間全体）",
        "Budget_per_user_day": "ユーザーあたりの予算/日",
        "Budget_per_user_month": "ユーザーあたりの予算/月",
        "Budget_per_user_year": "ユーザーあたりの予算/年",
        "Unlimited": "無制限",
        "Limited": "制限付き",
        "Total_uses_per_user": "ユーザーあたりの最大使用回数（プロモーション期間全体）",
        "Send_an_in_box_automatically_to_new_customers": "新規顧客に自動的に受信トレイを送信",
        "Notes": "メモ",
        "Public": "公開",
        "Create_campaign_success": "新しいキャンペーンが正常に作成されました",
        "Create_campaign_fail": "キャンペーンの作成中にエラーが発生しました",
        "Update_campaign_success": "キャンペーンが正常に更新されました",
        "Update_campaign_fail": "キャンペーンの更新中にエラーが発生しました",
        "Delete_campaign_success": "キャンペーンが正常に削除されました",
        "Delete_campaign_fail": "キャンペーンの削除中にエラーが発生しました",
        "Loading_campaign_fail": "キャンペーンの読み込み中にエラーが発生しました",
        "Update_campaign_status_success": "キャンペーンのステータスが正常に更新されました",
        "Update_campaign_status_fail": "キャンペーンのステータス更新中にエラーが発生しました",
        "Loading_promotion_code_fail": "プロモーションコードの読み込み中にエラーが発生しました",
        "Select_campaign": "キャンペーンを選択",
        "Create_promotion_code_success": "新しいプロモーションコードが正常に作成されました",
        "Create_promotion_code_fail": "プロモーションコードの作成中にエラーが発生しました",
        "Update_promotion_code_status_success": "プロモーションコードのステータスが正常に更新されました",
        "Update_promotion_code_status_fail": "プロモーションコードのステータス更新中にエラーが発生しました",
        "Delete_promotion_code_confirmation": "これらのプロモーションコードを削除しますか？",
        "Just_delete_inactive": "** 非アクティブ状態のプロモーションコードのみ削除可能です。",
        "Send_inbox_confirmation": "このプロモーションコードを乗客の受信トレイに送信しますか？",
        "Send_inbox_success": "プロモーションコードが正常に送信されました。",
        "Send_inbox_fail": "プロモーションコードの送信に失敗しました。もう一度お試しください！",
        "Confirmation": "確認",
        "Delete_promotion_codes_success": "プロモーションコードが正常に削除されました",
        "Delete_promotion_codes_fail": "プロモーションコードの削除中にエラーが発生しました",
        "Delete_multiple_promotion_codes_success": "プロモーションコードが正常に削除されました",
        "Delete_multiple_promotion_codes_fail": "プロモーションコードの削除中にエラーが発生しました",
        "Delete_promotion_code_success": "プロモーションコードのステータスが正常に更新されました",
        "Delete_promotion_code_fail": "プロモーションコードのステータス更新中にエラーが発生しました",
        "Update_multiple_promotion_code_status_success": "プロモーション コードのステータスが正常に更新されました",
        "Update_multiple_promotion_code_status_fail": "プロモーション コードのステータスの削除中にエラーが発生しました",
        "Search_here": "ここで検索",
        "Please_input_campaign_name": "キャンペーン名を入力してください",
        "Please_input_promotion_code": "プロモーションコードを入力してください",
        "Please_input_promotion_code_at_least_4_characters": "少なくとも4文字入力してください。",
        "Please_select_campaign": "キャンペーンを選択してください",
        "Please_input_value": "値を入力してください",
        "Please_select_date": "日付を選択してください",
        "Please_add_schedules": "スケジュールを追加してください",
        "Please_add_corporate": "法人を追加してください",
        "Please_choose_at_least_one_customer": "少なくとも1人の顧客を選択してください。",
        "This_campaign_name_has_been_existing_on_the_system_Please_input_another_one": "このキャンペーン名は既にシステムに存在します。別の名前を入力してください。",
        "This_promotion_code_has_been_existing_on_the_system_Please_input_another_one": "このプロモーションコードは既にシステムに存在します。別のコードを入力してください。",
        "Invalid_file": "ファイルの内容が無効です",
        "File_size_larger_than_5Mb": "ファイルサイズが5MBを超えています",
        "Import_promotions_success": "ファイルが正常にインポートされました",
        "Import_Promotions": "ファイルをインポート",
        "Number_successful_imports": "インポートされたアイテム",
        "Number_skipped": "スキップされたアイテム",
        "File": "ファイル",
        "Phone_Numbers": "電話番号",
        "Import_Promotion_code_success": "プロモーションコード <b>{0}</b> が正常にインポートされました",
        "Import_Promotion_code_fail": "プロモーションコード <b>{0}</b> のインポートに失敗しました。もう一度お試しください",
        "Customer_type": "顧客タイプ",
        "All": "すべて",
        "Referred_customers": "紹介顧客",
        "Common_customers": "一般顧客",
        "Operation_zone": "運用ゾーン",
        "totalUsesLimitPerDay": "ユーザーあたりの最大使用回数/日",
        "quantityLimitPerDay": "最大数量/日",
        "quantityLimitPerMonth": "ユーザーあたりの最大数量/月",
        "applyVerifiedCustomers": "認証済みのマレーシア人顧客のみ適用",
        "applyWhenBudgetLimit": "ユーザーは予算上限に達するまでコードを適用できます",
        "applyVerifiedCustomersTooltip": "このプロモーションは、システムに国民IDをアップロードし、認証済みのマレーシア人にのみ適用されます。",
        "valueLimitPerUse": "最大使用額/回（アプリ v48 以上適用）",
        "Promotion_Services": "サービス",
        "transport": "輸送",
        "shuttle": "シャトル",
        "parcel": "宅配",
        "food": "フード",
        "mart": "マート",
        "intercity": "都市間",
        "addSchedule": "スケジュールを追加",
        "editSchedule": "スケジュールを編集",
        "date": "日付",
        "Time_range": "時間範囲",
        "startTime": "開始時間",
        "endTime": "終了時間",
        "applyForOtherDays": "他の日にも適用",
        "scheduleTimeOverlap": "スケジュール時間が重複してはいけません",
        "schedule_startDate_greater_than_endDate": "開始日が終了日より後になっています",
        "scheduleTimeDuplicate": "スケジュール時間が重複してはいけません",
        "promoIcon": "プロモーションアイコン",
        "bannerImage": "バナー画像",
        "Upload_an_image": "画像をアップロード",
        "Click_here_to_view": "クリックして表示"
    },
    "quest_event": {
        "name": "名前",
        "userType": "ユーザータイプ",
        "carType": "車種",
        "operationZones": "運用ゾーン",
        "type": "イベントタイプ",
        "isLuckyDraw": "抽選ラウンド",
        "start_date": "開始日",
        "end_date": "終了日",
        "numOfQualify": "条件達成者数",
        "event_progress": "イベント進行状況",
        "isActive": "ステータス",
        "Actions": "操作",
        "passenger": "顧客",
        "driver": "ドライバー",
        "first_will_win": "最初の人が勝利",
        "largest_will_win": "最大の人が勝利（リーダーボード）",
        "not_start": "未開始",
        "active": "アクティブ",
        "closed": "終了",
        "event_details": "クエスト詳細",
        "add_event": "クエストを追加",
        "limited": "制限付き",
        "unlimited": "無制限",
        "eventImage": "イベント画像",
        "sharedImage": "共有画像",
        "qualifiedImage": "達成画像",
        "expiredImage": "期限切れ画像",
        "luckyDrawImage": "抽選画像",
        "Upload_an_image": "画像をアップロード",
        "Required_Image": "画像形式（.jpg, .png, .gif）",
        "ok": "OK",
        "eventId": "イベントID",
        "passengerMetric": {
            "booking_distance": "予約距離",
            "booking_time": "予約時間",
            "booking_amount_spent": "予約金額",
            "num_of_ondemand": "オンデマンド回数",
            "num_of_reservation": "予約回数",
            "num_of_intercity": "都市間予約回数",
            "num_of_delivery": "配送回数",
            "num_of_referral": "紹介回数",
            "num_of_sharing": "シェア回数",
            "topup_wallet_amount": "ウォレットへの入金額",
            "num_of_complete_wallet": "完了した予約数（ウォレット決済）",
            "num_of_complete_booking_by_referee": "完了した予約数（紹介者経由）",
            "number_of_complete_booking": "完了した予約数",
            "number_of_food_mart": "フード/マートの予約数"
        },
        "driverMetric": {
            "booking_distance": "ジョブ距離",
            "booking_time": "ジョブ時間",
            "num_of_referral": "紹介数",
            "num_of_complete": "完了した予約数（ユニーク顧客）",
            "num_of_referral_driver": "紹介数（ドライバー）",
            "num_of_accept": "受け入れた予約数",
            "num_of_receive": "受信した予約数",
            "num_of_cancel": "ドライバーによるキャンセル数",
            "sum_of_rating": "評価の合計星数",
            "total_rating": "評価回数の合計",
            "earnings_amount": "獲得額",
            "num_of_complete_wallet": "完全ブッキング数（ウォレット）",
            "num_of_complete_booking_by_referee": "完全ブッキング数（レフェリー別）",
            "available_time": "オンライン時間"
        },
        "Click_here_to_view": "クリックして表示",
        "view": "クリックして表示",
        "metricType": "タイプ",
        "task": "タスク",
        "value": "値",
        "quantity": "数量",
        "quests_list": "クエスト",
        "rankImages": "ランク画像（ポイント）",
        "qualifiedPrizeOrder": "達成賞品",
        "luckyDrawPrizeOrder": "抽選賞品",
        "reward": "アイテム",
        "from": "開始",
        "to": "終了",
        "image": "画像",
        "Add_Prize": "追加",
        "bannerText": "バナーテキスト",
        "buttonText": "ボタンテキスト",
        "bannerLink": "バナーリンク",
        "shortLink": "ショートリンク",
        "buttonLinkDesktop": "ボタンリンク（デスクトップ）",
        "buttonLinkAndroid": "ボタンリンク（Android）",
        "buttonLinkiOS": "ボタンリンク（iOS）",
        "termsURL": "利用規約リンク",
        "metadataTitle": "メタデータタイトル",
        "metadataDescription": "メタデータ説明",
        "isDarkMode": "ダークモード",
        "isActive_details": "アクティブ",
        "Required_fields": "必須項目",
        "change": "変更",
        "Please_input_name": "名前を入力してください",
        "Please_select_date": "日付を選択してください",
        "endDate_invalid": "終了日が無効です",
        "startDate_greater_than_endDate": "開始日が終了日より後になっています",
        "Please_input_termsURL": "利用規約リンクを入力してください",
        "Please_input_metadataTitle": "メタデータタイトルを入力してください",
        "Please_input_metadataDescription": "メタデータ説明を入力してください",
        "Please_input_bannerText": "バナーテキストを入力してください",
        "Please_input_buttonText": "ボタンテキストを入力してください",
        "Please_input_bannerLink": "バナーリンクを入力してください",
        "Please_input_shortLink": "ショートリンクを入力してください",
        "Please_input_buttonLinkDesktop": "ボタンリンク（デスクトップ）を入力してください",
        "Please_input_buttonLinkAndroid": "ボタンリンク（Android）を入力してください",
        "Please_input_buttonLinkiOS": "ボタンリンク（iOS）を入力してください",
        "the_range_1_to_5": "%{name} の値は 1 から 5 の範囲で入力してください！",
        "the_range_1_to_100": "%{name} の値は 1 から 100 の範囲で入力してください！",
        "task_is_empty": "%{name} のタスクを入力してください！",
        "task_limit_50_characters": "%{name} のタスクの最大長は 50 文字です！",
        "please_input_points": "%{name} のポイントを入力してください！",
        "please_input_value": "%{name} の値を入力してください！",
        "value_is_invalid": "%{name} の値は数値である必要があります",
        "please_upload_image": "アイテム %{name} の画像をアップロードしてください！",
        "rank_invalid": "ランク %{name} が無効です！",
        "rank_invalid_to": "ランク「終了」は数値でなければなりません",
        "deepLink": "ディープリンク",
        "point_per_task": "タスクごとのポイント",
        "points": "ポイント",
        "points_per_some_think": "%{unit} あたりのポイント",
        "criterias": "条件（オプション）",
        "criterias_list": "条件",
        "criteriaType": "タイプ",
        "criteriaValue": "値",
        "acceptance_rating": "受諾評価（以上）",
        "cancellation_rating": "キャンセル評価（以下）",
        "driver_rating": "ドライバー評価（以上）",
        "please_upload_image_rank": "ランク %{name} の画像をアップロードしてください！",
        "Update_event_success": "クエストが正常に更新されました",
        "Update_event_fail": "クエストの更新中にエラーが発生しました",
        "Create_event_success": "新しいクエストが正常に作成されました",
        "Create_event_fail": "クエストの作成中にエラーが発生しました",
        "suspend_list": "停止リスト"
    },
    "voucherSettings": {
        "Search_here": "ここで検索",
        "Voucher_code": "コード #",
        "Campaign": "キャンペーン",
        "Type": "タイプ",
        "Value": "値",
        "Valid_from": "有効開始日",
        "Valid_to": "有効終了日",
        "isActive": "ステータス",
        "Activate": "有効化",
        "Deactivate": "無効化",
        "Actions": "操作",
        "Active": "アクティブ",
        "Inactive": "非アクティブ",
        "redeemed": "引き換え済み",
        "expired": "期限切れ",
        "Add_Voucher_code": "コードを追加",
        "Add": "追加",
        "Show_per_page": "ページごとのアイテム数",
        "Page": "ページ",
        "Name": "名前",
        "show_pages": "{0} - {1} / {2} 表示中",
        "Edit": "編集",
        "Delete": "削除",
        "Save": "保存",
        "Duplicate": "複製",
        "Cancel": "キャンセル",
        "Required_fields": "必須項目",
        "Detail_voucher_code": "バウチャーコードの詳細",
        "Delete_voucher_code": "バウチャーコードを削除",
        "Confirmation": "確認",
        "Delete_confirm_voucher_code": "選択したバウチャー コードを削除しますか?",
        "Delete_voucher_code_confirmation": "これらのバウチャー コードを削除しますか?",
        "Just_delete_inactive": "** 非アクティブ ステータスのバウチャー コードのみ削除できます。",
        "Loading_voucher_code_fail": "バウチャー コードの読み込み中にエラーが発生しました",
        "Select_campaign": "キャンペーンを選択",
        "Create_voucher_code_success": "新しいバウチャー コードが正常に作成されました",
        "Create_voucher_code_fail": "バウチャー コードの作成中にエラーが発生しました",
        "Update_voucher_code_status_success": "バウチャー コードのステータスが正常に更新されました",
        "Update_voucher_code_status_fail": "バウチャー コードのステータスの更新中にエラーが発生しました",
        "Delete_voucher_codes_success": "バウチャー コードは正常に削除されました",
        "Delete_voucher_codes_fail": "バウチャー コードの削除中にエラーが発生しました",
        "Delete_multiple_voucher_codes_success": "バウチャー コードは正常に削除されました",
        "Delete_multiple_voucher_codes_fail": "バウチャー コードの削除中にエラーが発生しました",
        "Delete_voucher_code_success": "バウチャー コードは正常に削除されました",
        "Delete_voucher_code_fail": "バウチャー コードの削除中にエラーが発生しました",
        "Update_multiple_voucher_code_status_success": "バウチャー コードのステータスが正常に更新されました",
        "Update_multiple_voucher_code_status_fail": "バウチャー コードのステータスの削除中にエラーが発生しました",
        "Please_input_voucher_code": "バウチャーコードを入力してください",
        "Please_input_voucher_code_at_least_4_characters": "少なくとも4文字入力してください。",
        "Please_select_campaign": "キャンペーンを選択してください",
        "Please_input_value": "値を入力してください",
        "Please_select_date": "日付を選択してください",
        "Invalid_file": "ファイルの内容が無効です",
        "File_size_larger_than_5Mb": "ファイルサイズが5MBを超えています",
        "Import_vouchers_success": "ファイルが正常にインポートされました",
        "Import_vouchers": "ファイルをインポート",
        "Number_successful_imports": "インポートされたアイテム",
        "Number_skipped": "スキップされたアイテム",
        "File": "ファイル",
        "Phone_Numbers": "電話番号",
        "Import_voucher_code_success": "バウチャーコード <b>{0}</b> が正常にインポートされました",
        "Import_voucher_code_fail": "バウチャーコード <b>{0}</b> のインポートに失敗しました。もう一度お試しください",
        "All": "すべて"
    },
    "pointConfig": {
        "point_config": "ポイント設定",
        "Convert_spent_amount_to_points": "支出金額をポイントに変換",
        "Convert_topup_amount_to_points": "チャージ金額をポイントに変換",
        "Redeem_points_to_voucher": "ポイントをバウチャーに交換",
        "others": "その他",
        "Online_Payment": "オンライン決済",
        "transport": "輸送",
        "parcel": "宅配",
        "food": "フード",
        "mart": "マート",
        "delivery": "配送",
        "intercity": "都市間",
        "applyCash": "現金適用",
        "point": "ポイント",
        "vourcherName": "名前",
        "pointsToRedeem": "引き換えポイント数",
        "vourcherValue": "バウチャー価値",
        "validTime": "有効期間",
        "month": "月",
        "day": "日",
        "termAndCondition": "利用規約",
        "Actions": "操作",
        "add_voucher": "追加",
        "instructionURL": "説明リンク",
        "displayName": "スーパーアプリに名前を表示",
        "isActive": "アクティブ",
        "EDIT_VOUCHER": "バウチャーを更新",
        "DETAIL_VOUCHER": "バウチャーの詳細",
        "CREATE_VOUCHER": "バウチャーを追加",
        "update_successfully": "ポイント設定が正常に更新されました",
        "Create_voucher_successfully": "新しいバウチャーが正常に作成されました",
        "Create_voucher_fail": "バウチャーの作成中にエラーが発生しました",
        "Update_voucher_successfully": "バウチャーが正常に更新されました",
        "Update_voucher_fail": "バウチャーの更新中にエラーが発生しました",
        "Delete_voucher_successfully": "バウチャーが正常に削除されました",
        "Delete_voucher_fail": "バウチャーの削除中にエラーが発生しました",
        "Confirmation": "確認",
        "Delete_voucher_confirmation": "このバウチャーを削除しますか？",
        "Please_enter_value_least_one": "換算率が必要です！支出額またはチャージ額を設定して続行してください。",
        "Please_enter_value_is_number_spentAmount": "サービス \"{0}\" の「支出額を変換」に有効な金額を入力してください。",
        "Please_enter_value_is_number_topUp": "方法 \"{0}\" の「チャージ額を変換」に有効な金額を入力してください。"
    },
    "resetPassword": {
        "New_Password": "新しいパスワード",
        "New_Comfirm_Password": "新しいパスワードの確認",
        "Save": "保存",
        "INFO_SUCCESS": "パスワードリセットリンクがメールに送信されました",
        "TOKEN_EXPIRED": "リセットパスワードトークンの有効期限が切れました",
        "Back_To_Login_Page": "ログインページに戻る"
    },
    "permission": {
        "editBooking": "外部委託予約を編集",
        "companyOperator": "会社オペレーター",
        "Third_Party_Integration_Booking": "サードパーティ統合（Booking.com）",
        "Third_Party_Integration_HolidayTaxis": "サードパーティ統合（HolidayTaxis）",
        "supplierPermission": "サプライヤー権限",
        "Hint_edit_booking": "フリートオーナーが外部委託した予約を会社/サプライヤーが編集できるようにする。",
        "View": "表示",
        "Actions": "操作",
        "EditFare": "運賃を編集",
        "EditDriverEarning": "ドライバー収益を編集",
        "EditTotal": "合計金額を編集",
        "ImportBookings": "輸送予約をインポート",
        "ImportDeliveryBookings": "配送予約をインポート",
        "ShowFare": "運賃を表示",
        "delete_permission": "権限を削除",
        "DELETE_CONFIRM": "選択した権限を削除してもよろしいですか？",
        "Yes": "はい",
        "No": "いいえ",
        "Cancel": "キャンセル",
        "DRIVERS": "ドライバー",
        "Driver_list": "ドライバーリスト",
        "Enter_to_search": "検索するには入力",
        "Permitted_driver_list": "許可されたドライバーリスト",
        "Save": "保存",
        "CAR": "車両",
        "Car_list": "車両リスト",
        "Permitted_car_list": "許可された車両リスト",
        "Edit_permission": "権限を編集",
        "Add_permission": "権限を追加",
        "Detail_permission": "権限の詳細",
        "Name": "名前",
        "Driver_Car": "ドライバー/車両",
        "All": "すべて",
        "Company": "会社",
        "By_specific": "特定の",
        "Drivers": "ドライバー",
        "Cars": "車",
        "Show_per_page": "ページごとのアイテム数",
        "Page": "ページ",
        "show_pages": "{0} - {1} / {2} 表示中",
        "Delete": "削除",
        "Edit": "編集",
        "Status": "ステータス",
        "Action": "操作",
        "Inactive": "非アクティブ",
        "Active": "アクティブ",
        "Update_status_error": "ステータス更新エラー",
        "All_tooltip": "すべてのドライバーはマップ、キュー、ドライバー、オペレーション、レポートに表示されます。すべての車両は車両管理に表示されます。",
        "Company_tooltip": "この会社で許可されたドライバーはマップ、キュー、ドライバー、オペレーション、レポートに表示されます。この会社で許可された車両は車両管理に表示されます。",
        "By_specific_tooltip": "このリストにある許可されたドライバーはドライバー、オペレーションに表示されます。このリストにある許可された車両は車両管理に表示されます。",
        "Full_permissions": "フル権限",
        "ChangeBalance": "クレジット残高を変更",
        "ChangeCashBalance": "現金残高を変更",
        "TotalBalance": "合計残高",
        "WriteOffDebt": "債務を帳消し",
        "topupPaxWallet": "残高を変更",
        "SeFleetCommission": "フリートの手数料を設定（全ドライバー）",
        "EditFleetCommission": "フリート手数料を編集",
        "EditBalance": "残高を編集",
        "ViewHeatMap": "ヒートマップを表示",
        "Delete_permission_success": "権限が正常に削除されました",
        "Delete_permission_fail": "権限の削除中にエラーが発生しました",
        "Create_permission_fail": "新しい権限の作成中にエラーが発生しました",
        "Create_permission_success": "新しい権限が正常に作成されました",
        "Update_permission_fail": "権限の更新中にエラーが発生しました",
        "Update_permission_success": "権限が正常に更新されました",
        "No_action_selected": "操作リストで少なくとも1つのアクションを選択してください",
        "Required_fields": "必須項目",
        "sendSMS": "メッセージを送信（SMS）",
        "sendInbox": "メッセージを送信（受信トレイ）",
        "PayToDriver": "「ドライバーへの支払い」を表示",
        "PayoutHistory": "「支払い履歴」を表示",
        "Pay": "ドライバーに支払い",
        "ActivateDriver": "ドライバーを有効化",
        "Export": "エクスポート",
        "Export_csv": "エクスポート .csv",
        "Street-sharing_rate": "ストリートシェアリング料金"
    },
    "thirdPartySettings": {
        "category": "カテゴリ",
        "Not_over_limit_subs": "10個以上のサブロケーションを作成することはできません",
        "Show_per_page": "ページごとのアイテム数",
        "Page": "ページ",
        "show_pages": "{0} - {1} / {2} 表示中",
        "Delete": "削除",
        "Edit": "編集",
        "Status": "ステータス",
        "Actions": "操作",
        "Action": "アクション",
        "Inactive": "非アクティブ",
        "Active": "アクティブ",
        "website": "ウェブサイト",
        "Check_Google_Address": "緯度・経度からGoogleアドレスを確認",
        "Delete_Category_success": "カテゴリが正常に削除されました",
        "Delete_category_fail": "カテゴリの削除中にエラーが発生しました",
        "availableTime": "利用可能時間",
        "Please_choose_at_least_one_category": "少なくとも1つのカテゴリを選択してください",
        "Please_input_category_name": "カテゴリ名を入力してください",
        "Create_category_success": "カテゴリが正常に作成されました",
        "Create_category_fail": "カテゴリの作成中にエラーが発生しました",
        "Update_third_party_success": "ロケーションが正常に更新されました",
        "Update_category_fail": "カテゴリの更新中にエラーが発生しました",
        "All_Category": "すべてのカテゴリ",
        "Delete_Category": "カテゴリを編集",
        "Edit_Category": "カテゴリを編集",
        "Add_Category": "カテゴリを追加",
        "Add_Third_Party_location": "サードパーティロケーションを追加",
        "Add": "追加",
        "Edit_Third_Party_location": "サードパーティロケーションを編集",
        "Location_Not_Found": "ロケーションが見つかりません",
        "Create_third_party_success": "ロケーションが正常に作成されました",
        "Create_third_party_fail": "ロケーションの作成中にエラーが発生しました",
        "Update_third_party_fail": "ロケーションの更新中にエラーが発生しました",
        "Alias": "別名",
        "Address": "住所",
        "Latitude": "緯度",
        "Longitude": "経度",
        "Please_input_address": "住所を入力してください",
        "Please_input_name": "ロケーション名を入力してください",
        "Please_input_latitude": "緯度を入力してください",
        "Please_input_longitude": "経度を入力してください",
        "Save": "保存",
        "Cancel": "キャンセル",
        "Required_fields": "必須項目",
        "Last_update": "最終更新",
        "Username": "ユーザー名",
        "locationType": "ロケーションタイプ",
        "single": "単一ロケーション",
        "multiple": "複数ロケーション",
        "AddMore": "さらに追加",
        "locationList": "ロケーションリスト",
        "instructionLink": "説明リンク",
        "location": "ロケーション",
        "checkOnMap": "地図で確認",
        "Remove": "削除",
        "placeType": "タイプ",
        "all": "すべて",
        "PU": "ピックアップ",
        "DO": "降車",
        "namePlaceholder": "例: ターミナル1",
        "instructionLinkPlaceholder": "完全な説明付きのHTMLページ",
        "instructionLinkTooltip": "説明ページのリンクを入力してください。乗客はこのリンクを確認してピックアップロケーションを見つけることができます。",
        "longitudePlaceholder": 108.1837969,
        "latitudePlaceholder": 16.0393381,
        "Error_message_lat_in_range": "緯度は-90.0から90.0の間でなければなりません",
        "Error_message_lng_in_range": "経度は-180.0から180.0の間でなければなりません",
        "Delete_third_party_success": "ロケーションが正常に削除されました",
        "Delete_third_party_fail": "ロケーションの削除中にエラーが発生しました",
        "Delete_location": "ロケーションを削除",
        "Note_guide": "注: マップで検索すると、ロケーションはピンで素早くマークされます。",
        "PostalCode": "Postal Code"
    },
    "city": {
        "DELETE_CITY": "都市を削除",
        "DELETE_CONFIRM": "選択した都市を削除してもよろしいですか？",
        "Yes": "はい",
        "No": "いいえ",
        "Cancel": "キャンセル",
        "EDIT_CITY": "都市を編集",
        "ADD_CITY": "都市を追加",
        "Name": "名前",
        "Required_fields": "必須項目",
        "Save": "保存",
        "Add": "追加",
        "Show_per_page": "ページごとのアイテム数",
        "Page": "ページ",
        "show_pages": "{0} - {1} / {2} 表示中",
        "Delete": "削除",
        "Edit": "編集",
        "DETAIL_CITY": "都市の詳細",
        "Update_city_success": "都市が正常に更新されました",
        "Create_city_success": "新しい都市が正常に作成されました",
        "Delete_city_success": "都市が正常に削除されました",
        "cityName": "都市名",
        "Service": "サービス",
        "serviceTransportation": "輸送",
        "serviceAssistant": "アシスタント",
        "serviceDelivery": "配送",
        "isShowDriverSignUp": "プロバイダーのサインアップで表示",
        "note_isShowDriverSignUp": "（4.6.46以降の新規サインアップモジュールに適用）"
    },
    "jobTab": {
        "DELETE_JOBTAB": "ジョブタブを削除",
        "DELETE_CONFIRM": "選択したジョブタブを削除してもよろしいですか？",
        "Yes": "はい",
        "No": "いいえ",
        "Cancel": "キャンセル",
        "EDIT_JOBTAB": "ジョブタブを編集",
        "ADD_JOBTAB": "ジョブタブを追加",
        "Name": "名前",
        "Required_fields": "必須フィールド",
        "Save": "保存",
        "Add": "追加",
        "Show_per_page": "ページごとのアイテム数",
        "Page": "ページ",
        "show_pages": "{0} - {1} / {2} 表示",
        "Delete": "削除",
        "Edit": "編集",
        "DETAIL_JOBTAB": "ジョブタブの詳細",
        "Update_JOBTAB_success": "ジョブタブが正常に更新されました",
        "Create_JOBTAB_success": "新しいジョブタブが正常に作成されました",
        "Delete_JOBTAB_success": "ジョブタブが正常に削除されました",
        "JOBTABName": "ジョブタブ名",
        "jobsLink": "リンク"
    },
    "driverNote": {
        "name": "ドライバーノート",
        "DELETE": "ドライバーノートを削除",
        "DELETE_CONFIRM": "選択したドライバーノートを削除してもよろしいですか？",
        "EDIT": "ドライバーノートを編集",
        "ADD": "ドライバーノートを追加",
        "DETAIL": "ドライバーノートの詳細",
        "Update_success": "ドライバーノートが正常に更新されました",
        "Create_success": "新しいドライバーノートが正常に作成されました",
        "Delete_success": "ドライバーノートが正常に削除されました",
        "Delete_fail": "ドライバーノートの削除中にエラーが発生しました",
        "Load_fail": "ドライバーノートの読み込み中にエラーが発生しました",
        "Update_fail": "ドライバーノートの更新中にエラーが発生しました",
        "Create_fail": "ドライバーノートの作成中にエラーが発生しました"
    },
    "flightIntegration": {
        "Username": "ユーザー名",
        "provider": "フライトプロバイダー",
        "Key": "APIキー",
        "apiId": "API ID",
        "Save": "保存",
        "Update_flight_integration_success": "フライト統合が正常に更新されました",
        "Update_flight_integration_fail": "フライト統合の更新中にエラーが発生しました"
    },
    "smsIntegration": {
        "provider": "SMSプロバイダー",
        "apiToken": "APIトークン",
        "subAccountId": "サブアカウントID",
        "senderIdSource": "送信者ID（ソース）：",
        "apiKey": "APIキー",
        "username": "ユーザー名",
        "shortCode": "ショートコード",
        "key": "キー",
        "webService": "ウェブサービス",
        "bearerToken": "ベアラートークン",
        "keyId": "キーID",
        "email": "メール",
        "appKey": "アプリキー",
        "sender": "送信者",
        "password": "パスワード",
        "maskedPhone": "マスクされた電話番号",
        "senderId": "送信者ID",
        "webservice": "ウェブサービス",
        "brandname": "ブランド名",
        "appSecret": "アプリシークレット",
        "consumerKey": "コンシューマーキー",
        "requestUrl": "リクエストURL",
        "authId": "認証ID",
        "authToken": "認証トークン",
        "authKey": "認証キー",
        "templateId": "テンプレートID",
        "templateKey": "テンプレートキー",
        "phloId": "PHLO ID",
        "dlcNumber": "10DLC番号",
        "app_key": "アプリキー",
        "api_key": "APIキー",
        "accountSID": "アカウントSID",
        "applicationId": "アプリケーションID",
        "phoneNumber": "SMS番号1",
        "phoneNumber2": "SMS番号2",
        "SenderID_1": "送信者ID 1",
        "SenderID_2": "送信者ID 2",
        "smsMarketingNumber": "SMSマーケティング電話番号",
        "updateSMSSuccess": "SMS統合が正常に更新されました",
        "Deleted_success": "SMSが正常に削除されました",
        "receiverCountries": "受信国",
        "error": {
            "ERROR_CREATE": "SMSの作成中にエラーが発生しました",
            "COUNTRY_EXISTED": "国がすでにシステムに存在します",
            "ERROR_DELETE": "SMSの削除に失敗しました",
            "ERROR_UPDATE": "SMSの更新に失敗しました"
        },
        "identifierId": "識別子ID"
    },
    "mDispatcher": {
        "mDispatchers": "パートナー",
        "Active": "アクティブ",
        "Inactive": "非アクティブ",
        "Deactivate": "非アクティブ化",
        "Activate": "アクティブ化",
        "Delete": "削除",
        "Save": "保存",
        "Edit": "編集",
        "ADD_MDISPATCHER": "パートナーを追加",
        "EDIT_MDISPATCHER": "パートナーを編集",
        "Phone_number": "電話番号",
        "first_name": "名",
        "last_name": "姓",
        "email": "メール",
        "address": "住所",
        "mDispatcher_type": "パートナータイプ",
        "mDispatcher_type_toolTip": "さらにパートナータイプを追加するには、設定 > パートナータイプに進んでください",
        "queuing_area_toolTip": "さらに待機エリアを追加するには、待機エリアメニューに進んでください。",
        "mDispatcher": "パートナー",
        "card_management": "カード管理",
        "Select_mDispatcher_type": "パートナータイプを選択",
        "Amount": "金額",
        "Percent": "パーセント",
        "Yes": "はい",
        "Required_fields": "必須フィールド",
        "Cancel": "キャンセル",
        "commission_type": "手数料タイプ",
        "commission_value": "手数料値",
        "Name": "名前",
        "Phone_Number": "電話番号",
        "queuing_area": "待機エリア",
        "mdispatcher_type": "パートナータイプ",
        "registered_from": "登録元",
        "registration_date": "登録日",
        "status": "ステータス",
        "actions": "アクション",
        "Select_queue_area": "待機エリアを選択",
        "for_new_mDipatcher_account_password_default_is_password": "新しいパートナーアカウントの場合、デフォルトパスワードは「12345678」です",
        "Create_mDispatcher_success": "新しいパートナーが正常に作成されました",
        "Create_mDispatcher_fail": "パートナー作成中にエラーが発生しました",
        "Load_mDispatcher_fail": "パートナー読み込み中にエラーが発生しました",
        "Update_mDispatcher_success": "パートナーが正常に更新されました",
        "Update_mDispatcher_fail": "パートナー更新中にエラーが発生しました",
        "Update_mDispatcher_status_success": "パートナーステータスが正常に更新されました",
        "Update_mDispatcher_status_fail": "パートナーステータス更新中にエラーが発生しました",
        "Update_mDispatcher_statuses_success": "パートナーステータスが正常に更新されました",
        "Update_mDispatcher_statuses_fail": "パートナーステータス更新中にエラーが発生しました",
        "Delete_mDispatcher_success": "パートナーが正常に削除されました",
        "Delete_mDispatcher_fail": "パートナー削除中にエラーが発生しました",
        "Delete_mDispatchers_success": "パートナーが正常に削除されました",
        "Delete_mDispatchers_fail": "パートナー削除中にエラーが発生しました",
        "Do_you_want_to_delete_these_mDipatchers": "これらのパートナーを削除しますか？",
        "Only_mDistcher_in_the_inactive_status_can_be_deleted": "***非アクティブ状態のmDistcherのみ削除できます",
        "No_card": "カードが見つかりません",
        "Reset_Password": "パスワードをリセット",
        "Reset_password_success": "パートナーのパスワードが正常にリセットされました",
        "Reset_password_fail": "パートナーのパスワードリセット中にエラーが発生しました",
        "Do_you_want_to_reset_password": "パスワードをリセットしますか？",
        "ERROR_EXISTED_PHONE": "この電話番号はシステムに既に存在します。別のものを入力してください",
        "ERROR_INPUT_VALIDPHONE": "有効な電話番号を入力してください。",
        "Partner_detail": "パートナー詳細",
        "ALERT_SELECT_ACTIVATE": "アクティブにするパートナーを選択してください。",
        "ALERT_SELECT_DEACTIVATE": "非アクティブにするパートナーを選択してください。",
        "CONFIRM_DEACTIVATE_MULTI": "これらのパートナーを非アクティブにしますか？",
        "CONFIRM_ACTIVATE_MULTI": "これらのパートナーをアクティブにしますか？",
        "ERROR_COMMISSION_NUMBERS": "有効な番号を入力してください",
        "No_Support": "このエリアでは現在新しいカードの追加はサポートされていません",
        "Add": "追加",
        "Default_zone": "デフォルトゾーン",
        "required_email_payment": "パートナーのメールが必要です。続行するにはプロフィールを更新してください。",
        "titleGuestQR": "パートナーゲストQRリンク",
        "questQRContent": "あなた専用のパートナー予約リンクはこちらです！バー、ホテルの受付、客室、ウェブサイトなど、顧客が乗車や配達を必要とする可能性のある場所に目立つように表示してください。独自のQRコード付きのスタンディをデザインして、顧客が簡単にスキャンして旅を予約できるようにすることを検討してください！",
        "View_Guest_QR": "ゲストQRを表示"
    },
    "mDispatcherType": {
        "Name": "名前",
        "Required_fields": "必須フィールド",
        "Save": "保存",
        "Cancel": "キャンセル",
        "No": "いいえ",
        "Add": "追加",
        "Show_per_page": "ページごとのアイテム数",
        "Page": "ページ",
        "show_pages": "{0} - {1} / {2} 表示",
        "Delete": "削除",
        "Edit": "編集",
        "DELETE_MDISPATCHER_TYPE": "パートナータイプを削除",
        "DELETE_CONFIRM": "パートナータイプを削除してもよろしいですか？",
        "Yes": "はい",
        "EDIT_MDISPATCHER_TYPE": "パートナータイプを編集",
        "ADD_MDISPATCHER_TYPE": "パートナータイプを追加",
        "DETAIL_MDISPATCHER_TYPE": "パートナータイプの詳細",
        "Actions": "アクション",
        "Delete_mDispatcherType_success": "パートナータイプが正常に削除されました",
        "Delete_mDispatcherType_fail": "パートナータイプ削除中にエラーが発生しました",
        "Load_mDispatcherType_fail": "パートナータイプ読み込み中にエラーが発生しました",
        "Create_mDispatcherType_success": "新しいパートナータイプが正常に作成されました",
        "Create_mDispatcherType_fail": "パートナータイプ作成中にエラーが発生しました",
        "Update_mDispatcherType_success": "パートナータイプが正常に更新されました",
        "Update_mDispatcherType_fail": "パートナータイプ更新中にエラーが発生しました"
    },
    "queuingArea": {
        "queuing_area_info": "待機エリア情報",
        "queuingDrivers": "アクティブドライバー",
        "Name": "名前",
        "Required_fields": "必須フィールド",
        "Save": "保存",
        "Cancel": "キャンセル",
        "Yes": "はい",
        "No": "いいえ",
        "Add": "追加",
        "Show_per_page": "ページごとのアイテム数",
        "Enter_to_search": "検索するには入力",
        "Page": "ページ",
        "show_pages": "{0} - {1} / {2} 表示",
        "Delete": "削除",
        "Edit": "編集",
        "Active": "アクティブ",
        "Inactive": "非アクティブ",
        "Deactivate": "非アクティブ化",
        "Activate": "アクティブ化",
        "drivers": {
            "order": "順序",
            "Driver_name": "ドライバー名",
            "Phone_Number": "電話番号",
            "driverLicenseNumber": "ナンバープレート",
            "Car_Type": "車両タイプ",
            "checkInTime": "チェックイン時間",
            "duration": "滞在時間",
            "timestampScore": "タイムスタンプスコア",
            "changeOrderDriver": "順序を変更",
            "detailOrderDriver": "ドライバーの順序",
            "Yes": "はい",
            "No": "いいえ",
            "insert": "挿入",
            "refresh": "更新",
            "insertDriver": "ドライバーをキューに挿入",
            "orderDriver": "注文番号",
            "reason": "理由",
            "removeDriver": "ドライバーをキューから削除",
            "removeDriverConfirm": "このドライバーをキューから削除しますか？",
            "driverNotStay": "このドライバーは待機エリア内にいません。後で再度お試しください。",
            "overOrder": "注文番号はキュー内のドライバーの総数を超えることはできません。",
            "noDriverFound": "ドライバーが見つかりません",
            "driverUpdateErrRs": "ドライバーが待機エリアにいません。後で再度お試しください。",
            "orderUpdateErrRs": "注文番号はキュー内のドライバーの総数を超えることはできません。",
            "removeDriverSuccess": "ドライバーが待機エリアから正常に削除されました。",
            "removeDriverFail": "ドライバーを待機エリアから削除中にエラーが発生しました。",
            "operationInsertFailRs": "ドライバーが存在しないか、利用できません。",
            "versionInsertFailRs": "この機能を適用するには、ドライバーが最新バージョンのドライバーアプリにアップグレードする必要があります。",
            "queueInsertFailRs": "キューが存在しないか、ドライバーが待機エリアにいません。後で再度お試しください。",
            "vehicleInsertFailRs": "キューがドライバーの車両タイプをサポートしていません。",
            "orderInsertFailRs": "注文番号はキュー内のドライバーの総数を超えることはできません。",
            "positionInsertFailRs": "ドライバーを待機エリアに挿入中にエラーが発生しました。",
            "insertDriverSuccess": "ドライバーが待機エリアに正常に挿入されました。",
            "changeOrderSuccess": "ドライバーの位置が正常に変更されました。",
            "insertDriverFail": "ドライバーを待機エリアから挿入中にエラーが発生しました。",
            "changeOrderFail": "ドライバーの位置変更中にエラーが発生しました。",
            "selectToInsert": "検索：ドライバー名、電話番号",
            "updateBy": "オペレーターによる更新",
            "changeOrder": "順序を変更",
            "remove": "削除"
        },
        "keepDriverPlaceCancelNoShow": "予約がキャンセル/ノーショーの場合、ドライバーの場所を保持",
        "keepDriverPlaceUnavailable": "ドライバーが利用できない場合、ドライバーの場所を保持",
        "range": "キュー範囲",
        "queueRangeTooltip": "「これがドライバーがドライバーアプリで自分のキュー位置番号を確認する方法です。例えば：\n- キュー範囲を1に設定：ドライバーはキュー内の位置を単一の番号で確認します：1、2、3、4など。\n- キュー範囲を5に設定：ドライバーはキュー内の位置を範囲で確認します：1-5、6-10、11-15、16-20など。\n- キュー範囲を10に設定：ドライバーはキュー内の位置を範囲で確認します：1-10、11-20、21-30、31-40など。」",
        "queueCanceledTooltip": "- 乗客がキャンセルまたは現れない場合、ドライバーが設定時間内にキューに戻れば、ドライバーは最上位の位置を維持します。\n- 短距離予約を完了した後、ドライバーが設定時間内にキューに戻れば、ドライバーの位置は高優先度に設定されます。",
        "DELETE_QUEUING_AREA": "待機エリアを削除",
        "DELETE_CONFIRM": "待機エリアを削除しますか？",
        "EDIT_QUEUING_AREA": "待機エリアを編集",
        "ADD_QUEUING_AREA": "待機エリアを追加",
        "DETAIL_QUEUING_AREA": "待機エリアの詳細",
        "Queuing_area": "待機エリア",
        "Address": "住所",
        "Enter_a_location": "場所を入力",
        "Entered_Address_is_incorrect": "システムがあなたの位置を特定できません。提案された住所を使用してください。",
        "Not_supported_zone": "この待機エリアはサポートされているゾーンにありません",
        "Active_mDispatcher_list": "アクティブパートナーリスト",
        "Queue_list": "キューリスト",
        "Allow_radius": "許可半径",
        "Min_drivers_required": "必要最低ドライバー数",
        "Open_queuing_area": "待機エリアを開く",
        "Auto_dispatch": "キュー内のドライバーへの自動ディスパッチ（パートナーアプリを使用する必要なし）",
        "Active_car_list": "アクティブ車両リスト",
        "mDispatcher": "パートナー",
        "Google_Address": "Google住所",
        "Status": "ステータス",
        "Update_status_error": "ステータス更新エラー",
        "driver_checkin_area": "ドライバーチェックインエリア",
        "EDIT_POLYGONS": "ポリゴンを編集",
        "pax_pickup_area": "乗客ピックアップエリア",
        "pickup_area": "ピックアップエリア",
        "your_chosen_polygon": "選択したポリゴン",
        "other_queues": "他のキュー",
        "operation_zones": "運用ゾーン",
        "delete_pickup_area_confirm": "このピックアップエリアを削除してもよろしいですか？",
        "delete_pickup_area": "ピックアップエリアを削除",
        "please_choose_driver_check_in_area": "ドライバーチェックインエリアを選択してください。",
        "please_choose_pick_up_area": "ピックアップエリアを選択してください。",
        "Load_queuing_area_fail": "待機エリアの読み込み中にエラーが発生しました",
        "Create_queuingArea_success": "待機エリアが正常に作成されました",
        "Update_queuingArea_success": "待機エリアが正常に更新されました",
        "Delete_queuingArea_success": "待機エリアが正常に削除されました",
        "Delete_queuingArea_fail": "待機エリアの削除中にエラーが発生しました",
        "Create_queuingArea_fail": "待機エリアの作成中にエラーが発生しました",
        "Load_queuingArea_fail": "待機エリアの読み込み中にエラーが発生しました",
        "Update_queuingArea_fail": "待機エリアの更新中にエラーが発生しました"
    },
    "appBanner": {
        "Save": "保存",
        "Pax_app": "乗客アプリ",
        "Apply_to": "適用先",
        "Please_select_view": "ビューを選択してください",
        "Select_Type": "バナーの種類を選択してください",
        "Home_view": "ホームビュー",
        "Other_view": "その他のビュー",
        "Banner_1": "バナー #1",
        "Upload_an_image": "画像が選択されていません",
        "Action": "アクション",
        "NA": "該当なし",
        "Link_to_website": "ウェブサイトへのリンク",
        "Make_a_call": "電話をかける",
        "Send_an_email": "メールを送信",
        "Banner_2": "バナー #2",
        "Banner_3": "バナー #3",
        "Driver_app": "ドライバーアプリ",
        "pax": "乗客アプリ",
        "driver": "ドライバーアプリ",
        "Name": "名前",
        "viewType": "タイプ",
        "status": "ステータス",
        "image": "画像",
        "html": "HTML",
        "Delete_Banner": "バナーを削除",
        "Delete_confirm_banner": "選択したバナーを削除してもよろしいですか？",
        "Delete_banner_success": "バナーが正常に削除されました",
        "Delete_banner_fail": "バナーの削除中にエラーが発生しました",
        "edit_banner": "バナーを編集",
        "detail_banner": "バナーの詳細",
        "create_banner": "バナーを追加",
        "note_1": "* ファイル拡張子は.jpg、.jpeg、.png、.gifで、%{limit}MB未満である必要があります。\n",
        "note_2": "* 画像は16:9のアスペクト比である必要があります。",
        "note_3": "* その他のビューには、受信トレイ、友達紹介、レシート、マイ予約、プロモコード、クレジットカード/支払い方法の追加、プロフィール、処理待ち/予約確認済み、完了ビューが含まれます。",
        "Update_app_banner_success": "アプリバナーが正常に更新されました",
        "Update_app_banner_fail": "アプリバナーの更新に失敗しました！",
        "Create_app_banner_success": "アプリバナーが正常に作成されました",
        "Create_app_banner_fail": "アプリバナーの作成に失敗しました！",
        "Note": "アプリのパフォーマンスを向上させるため、.png形式（透明背景、サイズ400:200px）のファイルを使用してください",
        "Limit_size_upload": "ファイルサイズは8MB未満である必要があります。",
        "display_duration": "表示期間",
        "playback_settings": "再生設定",
        "display_banner_again_after": "再度バナーを表示する間隔",
        "stop_display_banner_after": "バナーの表示を停止するタイミング",
        "enable_playback": "再生を有効化",
        "Update_playback_success": "再生設定が正常に更新されました",
        "Update_playback_fail": "再生設定の更新に失敗しました！",
        "language": "言語",
        "single": "単一言語",
        "multi": "複数言語",
        "es-ES": "スペイン語",
        "en-US": "英語",
        "fr-FR": "フランス語",
        "id-ID": "インドネシア語",
        "vi-VN": "ベトナム語",
        "ur-PK": "ウルドゥー語",
        "ms-MY": "マレーシア語",
        "ar-AR": "アラビア語",
        "nb-NO": "ノルウェー語",
        "en-GB": "英語（イギリス）",
        "am-ET": "アムハラ語",
        "ro-RO": "ルーマニア語",
        "version": "アプリバージョン",
        "comparisonVersionApp": {
            "is": "である",
            "is_not": "でない",
            "is_one_of": "以下のいずれかである",
            "is_not_one_of": "以下のいずれかでない",
            "greater_or_equal": "以上",
            "less_or_equal": "以下"
        },
        "platform": "プラットフォーム",
        "platformItem": {
            "all": "すべて",
            "iOS": "iOS",
            "android": "Android"
        },
        "userStatus": "ユーザーステータス",
        "Active": "アクティブ",
        "Inactive": "非アクティブ",
        "in_review_completed": "審査中（プロフィール完了）",
        "in_review_incomplete": "審査中（プロフィール未完了）",
        "in_review_inProgress": "審査中（プロフィール進行中）",
        "zones": "ゾーン"
    },
    "reservation": {
        "name": "予約",
        "company": "会社",
        "carType": "車両タイプ",
        "licensePlate": "ナンバープレート：%{name}",
        "driver": "ドライバー：%{name}",
        "showAllDrivers": "すべてのドライバーを表示",
        "available": "利用可能",
        "reserved": "予約済み",
        "search_holder": "会社、ドライバー名、車両タイプ、ナンバープレート",
        "all": "すべて",
        "today": "今日",
        "thisWeek": "今週",
        "day": "毎日",
        "week": "毎週",
        "bookingID": "予約ID",
        "tripId": "トリップID",
        "pickupTime": "ピックアップ時間",
        "pickupLocation": "ピックアップ場所",
        "monday": "月曜日",
        "tuesday": "火曜日",
        "wednesday": "水曜日",
        "thursday": "木曜日",
        "friday": "金曜日",
        "saturday": "土曜日",
        "sunday": "日曜日",
        "overlapped": "重複",
        "noData": "データが見つかりませんでした。",
        "bookingDetails": "予約詳細",
        "reservedHint": "予約が確認済みです",
        "availableHint": "確認済みの予約がありません",
        "overlappedHint": "2つ以上の確認済み予約が重複しています"
    },
    "dashboard": {
        "API": "API",
        "cc": "クレジットカード",
        "dmc": "予約ボード",
        "streetSharing": "ストリートシェアリング",
        "kiosk": "キオスク",
        "mDispatcher": "パートナー",
        "paxApp": "乗客アプリ",
        "webBooking": "ウェブ予約",
        "carHailing": "カーヘイリング",
        "acceptance": "受付",
        "booking_com": "Booking.com",
        "completed": "完了",
        "noShow": "現れなかった",
        "canceled": "キャンセル",
        "incident": "インシデント",
        "rate1": "1つ星",
        "rate2": "2つ星",
        "rate3": "3つ星",
        "rate4": "4つ星",
        "rate5": "5つ星",
        "Export_to_Excel": "Excelにエクスポート",
        "Profit": "利益",
        "day_change": "日次変動",
        "week_change": "週次変動",
        "month_change": "月次変動",
        "Booking_requests": "予約リクエスト",
        "Complete_bookings": "完了した予約",
        "New_customers": "新規顧客",
        "Booking_dashboard": "予約ボード",
        "others": "その他",
        "Revenue_booking": "利益 & 完了した予約",
        "Profit_finish_booking": "利益 & 完了した予約",
        "Booking_request": "予約リクエスト",
        "Finished_booking": "完了した予約",
        "failed": "失敗",
        "partialCompleted": "部分完了",
        "Finished_booking_char_header": "完了した予約 ({0})",
        "Bookings": "予約 ({0})",
        "Ratings": "評価 ({0})",
        "Daily": "日次",
        "Weekly": "週次",
        "Monthly": "月次",
        "Total_customer": "合計顧客数 {0}",
        "ProfitToolTips": "すべての経費を除いた後のフリートの利益",
        "RevenueToolTips": "選択した期間中にフリートが実際に受け取る金額（プロモーション、ドライバーの控除、税金などを含む）",
        "ProfitChangeToolTips": "前期間と比較したフリートの利益の変動率",
        "BookingRequestsToolTips": "選択した期間中に作成されたオンデマンド予約と予約の総数",
        "PercentageOfAcceptanceToolTips": "選択した期間中に受理された予約の割合",
        "BookingChangeHint": "前期間と比較した作成された予約の変動率",
        "CompletedBookingHint": "選択した期間中に完了したオンデマンド予約と予約の総数",
        "PercentageOfSatisfactionHint": "選択した期間中の満足度の割合",
        "CompleteBookingDailyChangeHint": "前期間と比較した完了した予約の変動率",
        "NewCustomersHint": "選択した期間中に登録された新規顧客の総数",
        "CustomerChangeHint": "前期間と比較した顧客数および変動率",
        "TotalCustomer": "システム内の顧客の総数",
        "ProfitAndFinishedBookingHint": "選択した期間中のフリートの利益 & 完了した予約数",
        "BookingRequestHint": "選択した期間中に作成されたオンデマンド予約と予約の総数",
        "FinishedBookingChartsHint": "選択した期間中にクローズされたオンデマンド予約と予約の総数（キャンセル、ノーショー、インシデント、および完了を含む）",
        "Customer_count": "{0} 顧客数",
        "Revenue": "収益"
    },
    "referral": {
        "first_package": "第1パッケージ（最初に完了したPaxの予約）",
        "driver_refers_to_pax": "ドライバーが紹介した顧客",
        "driver_will_get": "ドライバーが受け取る金額",
        "money": "金額",
        "amount": "割合",
        "percent": "手数料（各完了した予約の小計額の%）",
        "settlement": "Paxが受け取る金額",
        "pax_will_get": "プロモーションコード",
        "promotion_code": "プロモーションコードを選択",
        "select_a_promotion": "最大",
        "maximum": "第2パッケージ",
        "second_package": "開始",
        "from": "終了",
        "to": "第2パッケージ後",
        "After_2nd_package": "上記の最後のパッケージの値 + 1",
        "from_end_value_of_above_package": "有効期限",
        "Expiration_date": "なし",
        "Never": "カスタム日付",
        "Customized_date": "制限付き",
        "limited": "無制限",
        "unlimited": "有効開始日",
        "Valid_from": "有効終了日",
        "Valid_to": "紹介者の制限/ドライバー",
        "Invitation_limit": "ステータス",
        "Status": "アクティブ",
        "Active": "非アクティブ",
        "Inactive": "非アクティブ",
        "note": "注意: 一度キャンペーンがアクティブになると、報酬の値を更新しても新規顧客にのみ適用されます。",
        "Required_fields": "必須項目",
        "refferal_update_successfully": "紹介設定が正常に更新されました",
        "distributeIncentiveToDriver": "ドライバーへのインセンティブを配布",
        "Immediate": "即時",
        "Periodic": "定期（手動）",
        "Period": "期間",
        "startDate": "開始日",
        "nextDistributionDate": "次回配布日",
        "oneMonth": "1ヶ月",
        "threeMonth": "3ヶ月",
        "sixMonth": "6ヶ月",
        "passenger_refers_to_pax": "顧客が顧客を紹介",
        "referer_will_get": "紹介者が受け取る金額",
        "referee_will_get": "被紹介者が受け取る金額",
        "promo": "プロモーション",
        "Pax_invitation_limit": "ユーザーごとの紹介制限",
        "promoPaidBy": "プロモーションの支払い元",
        "fleet": "フリート",
        "driver": "ドライバー",
        "promoCode": "プロモーションコード",
        "isSendInbox": "受信トレイに送信",
        "isDefaultCode": "アプリで自動適用",
        "autoApplyOnWeb": "ウェブで自動適用",
        "isDefaultCode_hint": "顧客のリストにプロモーションコードを追加し、顧客が注文を作成した際に自動適用",
        "Actions": "操作",
        "add_promoCode": "追加",
        "Send_an_in_box_automatically_to_new_customers": "顧客に受信トレイを送信",
        "set_default_promo_code": "アプリで自動適用",
        "EDIT_PROMO_CODE": "プロモーションコードを編集",
        "DETAIL_PROMO_CODE": "プロモーションコードの詳細",
        "CREATE_PROMO_CODE": "プロモーションコードを作成",
        "DELETE_PROMO_CODE": "プロモーションコードを削除",
        "DELETE_CONFIRM_PROMO_CODE": "このプロモーションコードを削除しますか？",
        "ERROR_INPUT_SOURCE": "少なくとも1つ選択してください。"
    },
    "upload_document": {
        "NO_FILE_CHOSEN": "ファイルが選択されていません",
        "Limit_size_upload": "ファイルサイズが許可された制限{0}MBを超えています。"
    },
    "payment_info": {
        "member_since": "メンバー登録日",
        "package_name": "パッケージ名",
        "last_payment": "最終支払い",
        "credit_card": "クレジットカード",
        "last_digits": "下4桁",
        "default": "デフォルト",
        "add_new_card": "新しいカードを追加",
        "history": "履歴",
        "transaction_date": "取引日",
        "transaction_id": "取引ID",
        "billing_cycle": "請求サイクル",
        "amount": "金額",
        "payment_history": "請求履歴",
        "card_information": "カード情報",
        "card_holder": "カード保有者",
        "card_number": "カード番号",
        "expiration_date": "有効期限",
        "cvv": "CVV"
    },
    "search_by": {
        "group_id": "グループID",
        "booking_id": "予約番号",
        "recurring_id": "定期番号",
        "ExternalID": "外部番号",
        "tripId": "トリップ番号",
        "driver_name": "ドライバー名",
        "driver_phone_number": "ドライバー電話番号",
        "license_plate": "ナンバープレート",
        "vehicle": "車両",
        "operator": "オペレーター",
        "passenger_email": "乗客メール",
        "passenger_name": "乗客名",
        "passenger_phone_number": "乗客電話番号",
        "destination": "目的地",
        "pick_up": "ピックアップ",
        "flight_number": "フライト番号",
        "book_from": "予約元",
        "note": "メモ",
        "email": "メール",
        "phone": "電話"
    },
    "withdrawal": {
        "transferToEWallet": "外部電子ウォレットに送金 *{0}",
        "channelCode": "チャンネルコード",
        "channelName": "チャンネル名",
        "reversed": "取り消されました",
        "requestTime": "リクエスト時間",
        "referenceId": "参照番号",
        "driverName": "ドライバー名",
        "phoneNumber": "電話番号",
        "company": "会社",
        "description": "説明",
        "requestAmount": "リクエスト金額",
        "transferAmount": "送金額",
        "currentBalance": "現在の残高",
        "currency": "通貨",
        "action": "アクション",
        "approve": "承認",
        "reject": "拒否",
        "transferToBankAccount": "銀行口座*{0}に送金済み",
        "transferToWingBank": "Wing銀行ID*{0}に送金済み",
        "transferToCreditWallet": "クレジットウォレットに送金済み",
        "reason": "理由",
        "search": "ドライバー名、電話、会社、取引ID、参照番号",
        "message": {
            "approve": "このドライバーに{0}の金額を支払ってもよろしいですか？",
            "approveMulti": "選択したドライバーに支払ってもよろしいですか？",
            "approveSuccess": "選択したリクエストが支払われました",
            "approveFail": "選択したリクエストが成功しませんでした。再度お試しください。",
            "approveMultiSuccess": "選択したリクエスト{1}件のうち{0}件が支払われました。",
            "approveMultiFail": "選択したリクエスト{1}件のうち{0}件が成功しませんでした。再度お試しください。",
            "buttonConfirmTitle": "はい",
            "closeButtonText": "いいえ",
            "pleaseInputReason": "理由を入力してください",
            "rejectSuccess": "ドライバーのリクエストが拒否されました",
            "rejectFail": "選択したリクエストの拒否に失敗しました"
        },
        "getDataFailure": "データを取得できません",
        "rejectRequest": "リクエストを拒否"
    },
    "dynamicSurcharge": {
        "operationZone": "運用ゾーン",
        "activeSurcharge": "アクティブなサーチャージ",
        "surcharge": "サーチャージ",
        "surchargeName": "サーチャージ名",
        "parameter": "パラメータ（例：1.x）",
        "pickupPoint": "ピックアップゾーン",
        "dropOffPoint": "降車ゾーン",
        "delete_surcharge_success": "サーチャージが正常に削除されました",
        "delete_surcharge_fail": "サーチャージの削除中にエラーが発生しました",
        "update_surcharge_fail": "サーチャージの更新中にエラーが発生しました",
        "update_surcharge_success": "サーチャージが正常に更新されました",
        "create_surcharge_fail": "サーチャージの作成中にエラーが発生しました",
        "create_surcharge_success": "サーチャージが正常に作成されました",
        "active_surcharge_fail": "サーチャージのアクティブ化中にエラーが発生しました",
        "active_surcharge_success": "サーチャージが正常にアクティブ化されました",
        "deactive_surcharge_fail": "サーチャージの非アクティブ化中にエラーが発生しました",
        "deactive_surcharge_success": "サーチャージが正常に非アクティブ化されました",
        "delete_surcharge": "サーチャージを削除",
        "delete_confirm_message": "選択したサーチャージを削除してもよろしいですか？",
        "note": "*注：これが基本運賃に乗算される値です。例：パラメータが1.5xの場合、運賃$20は$30になります"
    },
    "dynamicFare": {
        "operationZone": "運用ゾーン",
        "activeFare": "アクティブな運賃",
        "fare": "運賃",
        "fareName": "運賃名",
        "factor": "乗算係数",
        "fix_amount": "固定金額",
        "parameter": "パラメータ（例：1.x）",
        "delete_fare_success": "運賃が正常に削除されました",
        "delete_fare_fail": "運賃の削除中にエラーが発生しました",
        "update_fare_fail": "運賃の更新中にエラーが発生しました",
        "update_fare_success": "運賃が正常に更新されました",
        "create_fare_fail": "運賃の作成中にエラーが発生しました",
        "create_fare_success": "運賃が正常に作成されました",
        "active_fare_fail": "運賃のアクティブ化中にエラーが発生しました",
        "active_fare_success": "運賃が正常にアクティブ化されました",
        "deactive_fare_fail": "運賃の非アクティブ化中にエラーが発生しました",
        "deactive_fare_success": "運賃が正常に非アクティブ化されました",
        "delete_fare": "運賃を削除",
        "delete_confirm_message": "選択した運賃を削除してもよろしいですか？",
        "note": "*注：これが基本運賃に乗算される値です。例：パラメータが1.5xの場合、運賃$20は$30になります",
        "note_amount": "*注：これが基本運賃に加算される値です。例：設定金額が$5の場合、運賃$20は$25になります"
    },
    "notifications": {
        "search": "予約ID、外部IDを検索",
        "searchProviderName": "プロバイダー名を検索",
        "statusItem": {
            "all": "すべての通知",
            "canceledByDriver": "輸送 - ドライバーによるキャンセル",
            "canceledByPartner": "アフィリエイト - パートナーによるキャンセル",
            "reservationReminder": "予約リマインダー",
            "localPending": "ローカル - 保留中",
            "reservationIsNotStarted": "予約が開始されていません",
            "partnerRequestsCars": "パートナーが車両をリクエスト",
            "timeout": "タイムアウト",
            "noShow": "アフィリエイト - 現れなかった",
            "affiliatePending": "アフィリエイト - 保留中",
            "affiliatePaymentFailed": "アフィリエイト支払い失敗",
            "affiliateTimeout": "アフィリエイトタイムアウト",
            "flightApi": "フライトAPI",
            "DebtWriteOff": "債務帳消し",
            "driverCancelTrip": "都市間 - ドライバーによるキャンセル",
            "minimumSeatRequired": "必要最低座席数",
            "newFarmInJobFrom3rd": "Booking.com - 新しい予約",
            "canceledJobBy3rd": "Booking.com - キャンセル",
            "updatedJobBy3rd": "Booking.com - 更新",
            "newFarmInJobFromHolidayTaxis": "HolidayTaxis - 新しい予約",
            "canceledJobByHolidayTaxis": "HolidayTaxis - キャンセル",
            "updatedJobByHolidayTaxis": "HolidayTaxis - 更新",
            "newFarmInJobFromKarhoo": "Karhoo - 新しい予約",
            "canceledJobByKarhoo": "Karhoo - キャンセル",
            "updatedJobByKarhoo": "Karhoo - 更新",
            "newFarmInJobFromHoppa": "Hoppa - 新しい予約",
            "canceledJobByHoppa": "Hoppa - キャンセル",
            "updatedJobByHoppa": "Hoppa - 更新"
        },
        "sos": {
            "sosSearch": "予約ID、乗客名、ドライバー名を検索",
            "statusItem": {
                "all": "すべて",
                "driver": "ドライバー",
                "passenger": "乗客",
                "crew": "クルー"
            }
        }
    },
    "smartData": {
        "No": "番号",
        "driverName": "名前",
        "phone": "電話",
        "rating": "評価",
        "completed": "完了",
        "noShow": "現れなかった",
        "incident": "インシデント",
        "canceled": "ドライバーによるキャンセル",
        "accepted": "受付済み",
        "reject": "拒否",
        "ignore": "無視",
        "earning": "収益",
        "query": {
            "top20": "上位20",
            "top50": "上位50",
            "top100": "上位100",
            "bottom20": "下位20",
            "bottom50": "下位50",
            "bottom100": "下位100"
        },
        "status": {
            "canceled": "ドライバーによるキャンセル",
            "noShow": "現れなかった",
            "incident": "インシデント",
            "completed": "完了",
            "earning": "収益",
            "reject": "拒否",
            "ignore": "無視",
            "accepted": "受付済み"
        }
    },
    "Reject": "現れなかった",
    "Do not allow Driver to start reservat": "提携",
    "intercityRate": {
        "Delete_intercity_rate": "都市間料金を削除",
        "edit_intercity_rate": "都市間料金を編集",
        "view_intercity_rate": "都市間料金を表示",
        "add_intercity_rate": "都市間料金を追加",
        "Delete_confirm_intercity_rate": "このアイテムを削除しますか？",
        "Update_intercity_rate_success": "都市間料金が正常に更新されました",
        "Update_intercity_rate_fail": "都市間料金の更新中にエラーが発生しました",
        "Created_intercity_rate_success": "都市間料金が正常に作成されました",
        "Created_intercity_rate_fail": "都市間料金の作成中にエラーが発生しました",
        "Update_intercity_rate_route_success": "都市間料金ルートが正常に更新されました",
        "Update_intercity_rate_route_fail": "都市間料金ルートの更新中にエラーが発生しました",
        "Created_intercity_rate_route_success": "都市間料金ルートが正常に作成されました",
        "Created_intercity_rate_route_fail": "都市間料金ルートの作成中にエラーが発生しました",
        "Loading_intercity_rate_fail": "都市間料金詳細の読み込み中にエラーが発生しました",
        "Delete_intercity_rate_route_fail": "都市間料金ルートの削除中にエラーが発生しました",
        "Delete_intercity_rate_success": "都市間料金が正常に削除されました",
        "Delete_intercity_rate_fail": "都市間料金の削除中にエラーが発生しました"
    },
    "intercityRoute": {
        "alias": "エイリアス",
        "routeNameHint": "ルート名を変更するには、設定 > ゾーン > 都市間に進み、都市ゾーンの名前を適切に変更してください。",
        "pricePerSeat": "座席ごとの価格（税抜き）",
        "distance": "距離",
        "duration": "期間",
        "cancellationPenalty": "座席ごとのペナルティ額",
        "noShow": "Pax/パートナーのノーショー",
        "penalty": "座席ごとのペナルティ額",
        "no_show_tooltip": "顧客が座席ごとに請求される金額。ペナルティに加えて、適用される場合は税金や技術料金も支払う必要があります。",
        "firstLocation": "最初のロケーション",
        "secondLocation": "2番目のロケーション",
        "routeOneSetup": "ルート1を設定",
        "routetwoSetup": "ルート2を設定",
        "schedules": "スケジュール",
        "date": "日付",
        "time": "時間",
        "editSchedule": "スケジュールを編集",
        "addSchedule": "スケジュールを追加",
        "requestTimeEnable": "顧客がリクエスト時間を設定できるようにする",
        "extraFee": "追加料金",
        "hideRouteEnable": "このルートを非表示にする",
        "timeRange": "時間範囲",
        "workingTime": "勤務時間",
        "applyForOtherDays": "他の日にも適用",
        "limitSeatLuggage": "座席/荷物の制限",
        "warningMinimumSeats": "必要最低座席数",
        "warningMinimumSeatsHint": "旅行を実施するために必要な最低座席数",
        "allowStartLessMinRequiredSeats": "最低座席数の要件に関係なくドライバーが出発できる",
        "allowStartLessMinRequiredSeatsHint": "要求された座席数が最低座席数より少なくても、ドライバーが出発できるようにする",
        "freeLuggagePerSeat": "座席ごとの無料荷物",
        "freeLuggagePerSeatHint": "座席ごとに含まれる無料荷物の数",
        "allowToAddExtraLuggage": "追加荷物を追加",
        "allowToAddExtraLuggageHint": "乗客が追加の荷物を追加できるようにする",
        "feePerExtraLuggage": "追加荷物ごとの料金",
        "Delete_intercity_route": "都市間ルートを削除",
        "Delete_confirm_intercity_route": "このルートを削除しますか？",
        "Update_intercity_route_fail": "都市間ルートの更新中にエラーが発生しました",
        "Update_intercity_route_success": "都市間ルートが正常に更新されました",
        "created_intercity_route_success": "都市間ルートが正常に作成されました",
        "created_intercity_route_fail": "都市間ルートの作成中にエラーが発生しました",
        "Delete_intercity_route_success": "都市間ルートが正常に削除されました",
        "Delete_intercity_route_fail": "都市間ルートの削除中にエラーが発生しました",
        "penalty_less_than_or_equal_to_price": "座席単価以下の値を入力してください。",
        "scheduleTimeOverlap": "スケジュール時間が重複しないようにしてください",
        "allows_adding_extra": "フリートがスケジュールしていない時間帯に顧客がリクエストした場合、追加料金を設定できます。",
        "this_is_to_limit_users": "このルートで新しい予約や旅行を作成できないように制限します（顧客、ドライバー、オペレーター対象）。既存の予約や旅行は予約済みとして保持されます。"
    },
    "dayOfWeek": {
        "0": "日曜日",
        "1": "月曜日",
        "2": "火曜日",
        "3": "水曜日",
        "4": "木曜日",
        "5": "金曜日",
        "6": "土曜日"
    },
    "intercityBooking": {
        "route": "ルート",
        "departureDate": "出発日",
        "departureTime": "出発時間",
        "Departure": "出発",
        "Arrival": "到着",
        "RefundFare": "払い戻し運賃",
        "intercityZoneNote": "ここで作成したゾーンは都市間料金の設定にのみ使用されます。",
        "zoneNameHint": "顧客が検索して選択しやすいように、ゾーン名は短く分かりやすくしてください。",
        "zoneUploadNote_2": "* 画像の解像度は1095x360、ファイルサイズは5MB未満が推奨されます。",
        "Complete_booking": "予約を完了",
        "ConfirmCancel": "キャンセルを確認"
    },
    "deliveryRate": {
        "Delete_delivery_rate": "配送料金を削除",
        "edit_delivery_rate": "配送料金を編集",
        "view_delivery_rate": "配送料金を表示",
        "add_delivery_rate": "配送料金を追加",
        "Delete_confirm_delivery_rate": "このアイテムを削除しますか？",
        "Update_delivery_rate_success": "配送料金が正常に更新されました",
        "Update_delivery_rate_fail": "配送料金の更新中にエラーが発生しました",
        "Created_delivery_rate_success": "配送料金が正常に作成されました",
        "Created_delivery_rate_fail": "配送料金の作成中にエラーが発生しました",
        "Update_delivery_rate_route_success": "配送料金ルートが正常に更新されました",
        "Update_delivery_rate_route_fail": "配送料金ルートの更新中にエラーが発生しました",
        "Created_delivery_rate_route_success": "配送料金ルートが正常に作成されました",
        "Created_delivery_rate_route_fail": "配送料金ルートの作成中にエラーが発生しました",
        "Loading_delivery_rate_fail": "配送料金の詳細を読み込む際にエラーが発生しました",
        "Delete_delivery_rate_route_fail": "配送料金ルートの削除中にエラーが発生しました",
        "Delete_delivery_rate_success": "配送料金が正常に削除されました",
        "Delete_delivery_rate_fail": "配送料金の削除中にエラーが発生しました",
        "distance_km": "1kmあたりの料金",
        "distance_mile": "1マイルあたりの料金",
        "flat": "定額料金",
        "startingFee": "初乗り料金",
        "feeFirstDistance": "最初の距離の料金",
        "feeSecondDistance": "2番目の距離の料金",
        "feeThirdDistance": "3番目の距離の料金",
        "feeAfterThirdDistance": "3番目以降の距離の料金",
        "feePerMinute": "1分あたりの運賃",
        "feeMinimum": "最低料金",
        "feeFirstStop": "最初の停留所の料金",
        "feeExtraStop": "追加停留所ごとの料金"
    },
    "pwaSettings": {
        "pwa": "PWA",
        "iconName": "アイコン & 名前",
        "logoBackgroundColor": "ロゴ、背景 & カラー",
        "longName": "長い名前",
        "shortName": "短い名前",
        "icon192": "ホーム画面のアイコン (192x192)",
        "icon512": "スプラッシュ画面のアイコン",
        "logo": "ロゴ (300x300)",
        "logoMenu": "メニューロゴ (300x300)",
        "backgroundImage": "背景画像 - 輸送 (1024x500)",
        "backgroundImageDelivery": "背景画像 - 配送 (1024x500)",
        "mainColor": "メインカラー",
        "secondaryColor": "セカンダリカラー",
        "showMainColor": "背景画像の代わりにメインカラーを表示",
        "success": "PWA設定が正常に更新されました",
        "failed": "PWA設定の更新に失敗しました。再度お試しください！",
        "errorUploadFileSize": "必要なサイズは{0}x{0}pxです。",
        "hideWBInstallAppBtn": "アプリインストールボタンを非表示",
        "enableBookNow": "今すぐ",
        "enableGuestCheckout": "ゲストチェックアウト",
        "enableBookLater": "後で",
        "enableHideRecipient": "受取人情報を非表示（パッケージ内容、配達指示）",
        "pwaLink": "乗客向けPWA",
        "partnerLink": "パートナー向けPWA",
        "pwaDriverLink": "ドライバー向けPWA",
        "providerSignUp": "ドライバー登録リンク",
        "AdsTracking": "広告トラッキング",
        "googleId": "GoogleタグID",
        "placeholdergoogleId": "GoogleタグIDを入力",
        "metaId": "MetaピクセルID",
        "placeholdermetaId": "MetaピクセルIDを入力"
    },
    "emailConfig": {
        "emailServer": "メールサーバー",
        "default": "デフォルト",
        "customized": "カスタマイズ",
        "fromName": "送信者名",
        "email": "送信者メール",
        "replyTo": "返信先",
        "userAuth": "ユーザー名",
        "password": "パスワード",
        "smtpServer": "SMTPサーバー",
        "port": "SMTPポート",
        "isAuthenticated": "認証済み",
        "option": "オプションを有効化",
        "none": "なし",
        "tls": "TLS",
        "ssl": "SSL",
        "save": "保存",
        "cancel": "キャンセル",
        "success": "メールサーバーの更新に成功",
        "fail": "メールサーバーの更新に失敗",
        "loadFail": "メールサーバーの詳細読み込みに失敗"
    },
    "driverFields": {
        "Load_fail": "",
        "Edit": "編集",
        "View": "表示",
        "Cancel": "キャンセル",
        "show": "表示",
        "hide": "非表示",
        "EDIT_DRIVER_FIELDS": "ドライバーフィールドを編集",
        "DETAIL_DRIVER_FIELDS": "ドライバーフィールドの詳細",
        "ADD_DRIVER_FIELDS": "ドライバーフィールドを追加",
        "fieldKey": "フィールドキー",
        "fieldName": "フィールド名",
        "availableCC": "コマンドセンターで利用可能",
        "availableDrvSignUp": "ドライバー登録で利用可能",
        "availableCompanyCar": "会社車両 - 輸送ドライバー",
        "deliveryCompany": "会社車両 - 配達ドライバー",
        "availableIndividualCar": "個人車両 - 輸送ドライバー",
        "deliveryIndividual": "個人車両 - 配達ドライバー",
        "required": "必須",
        "Update_fail": "ドライバーフィールドの更新中にエラーが発生しました",
        "Update_success": "ドライバーフィールドが正常に更新されました",
        "FieldKey": "フィールドキー",
        "FieldName": "フィールド名（アメリカ英語）",
        "CommandCenter": "コマンドセンターで利用可能",
        "SignUp": "ドライバー登録で利用可能",
        "CompanyTransport": "会社車両 - 輸送",
        "CompanyDelivery": "会社車両 - 配達",
        "IndividualTransport": "個人車両 - 輸送",
        "IndividualDelivery": "個人車両 - 配達",
        "Require": "必須",
        "Actions": "アクション",
        "fieldType": "フィールドタイプ",
        "default": "デフォルト",
        "customized": "カスタマイズ"
    },
    "driverDocument": {
        "Load_fail": "",
        "Edit": "編集",
        "View": "表示",
        "Cancel": "キャンセル",
        "show": "表示",
        "hide": "非表示",
        "EDIT_DRIVER_DOCUMENT": "ドライバー書類を編集",
        "DETAIL_DRIVER_DOCUMENT": "ドライバー書類の詳細",
        "ADD_DRIVER_DOCUMENT": "ドライバー書類を追加",
        "documentKey": "書類キー",
        "documentName": "書類名",
        "documentNameDetail": {
            "zh-HK": "繁体字中国語",
            "it-IT": "標準イタリア語",
            "es-ES": "スペイン語",
            "en-US": "アメリカ英語",
            "fr-FR": "標準フランス語",
            "zh-CN": "簡体字中国語",
            "id-ID": "インドネシア語",
            "vi-VN": "ベトナム語",
            "ur-PK": "ウルドゥー語（パキスタン・イスラム共和国）",
            "ms-MY": "マレー語（マレーシア）",
            "ar-AR": "アラビア語",
            "en-GB": "英語（イギリス）",
            "nb-NO": "ノルウェー語",
            "am-ET": "アムハラ語",
            "ro-RO": "ルーマニア語",
            "th-TH": "タイ語",
            "ta-IN": "タミル語",
            "hi-IN": "ヒンディー語",
            "te-IN": "テルグ語",
            "ka-GE": "ジョージア語",
            "ru-RU": "ロシア語",
            "kn-IN": "カンナダ語"
        },
        "isIndividual": "会社車両 - 輸送ドライバー",
        "deliveryCompany": "会社車両 - 配達ドライバー",
        "isOwner": "個人ドライバー - 輸送ドライバー",
        "deliveryIndividual": "個人ドライバー - 配達ドライバー",
        "availableCC": "コマンドセンターで利用可能",
        "availableDrvSignUp": "ドライバー登録で利用可能",
        "isRequired": "必須",
        "isExpiredDate": "有効期限 - コマンドセンター",
        "expiredDateForDrvSignUp": "有効期限 - ドライバー登録",
        "requireExpiredDate": "有効期限の必須化",
        "requireExpiredDateForDrvSignUp": "有効期限の必須化",
        "requireDocumentId": "書類IDの必須化",
        "documentId": "書類ID - コマンドセンター",
        "documentIdForDrvSignUp": "書類ID - ドライバー登録",
        "Update_fail": "ドライバー書類の更新中にエラーが発生しました",
        "Update_success": "ドライバー書類が正常に更新されました",
        "DocumentKey": "書類キー",
        "DocumentName": "書類名（アメリカ英語）",
        "CommandCenter": "コマンドセンターで利用可能",
        "SignUp": "ドライバー登録で利用可能",
        "CompanyTransport": "会社車両 - 輸送",
        "CompanyDelivery": "会社車両 - 配達",
        "IndividualTransport": "個人車両 - 輸送",
        "IndividualDelivery": "個人車両 - 配達",
        "Require": "必須",
        "Actions": "アクション"
    },
    "holidayTaxis": {
        "title": "HolidayTaxis API認証情報",
        "note": "HolidayTaxisの技術チームと連絡を取り、APIキーを提供してもらってシステムに接続してください。",
        "apiKey": "APIキー",
        "placeHolderApiKey": "APIキーを入力"
    },
    "trailNotes": {
        "maxLengthFiles": "コメントごとに最大3ファイル",
        "maxSizeFiles": "ファイルごとに最大10MB",
        "typeFiles": "サポートされているファイル形式：pdf、doc、docx、csv、xls、xlsx、jpeg、jpg、png、webp、txt、note",
        "maxComments": "予約ごとに最大20コメント",
        "bookingActivity": "予約アクティビティ",
        "viewAll": "すべて表示",
        "operator": "オペレーター",
        "driver": "ドライバー",
        "passenger": "顧客",
        "pHInput": "新しいコメントを追加。保存をクリックしてメモを保存できます。ここに複数のファイルをドラッグ＆ドロップすることもできます。",
        "addFiles": "ファイルを追加",
        "save": "保存",
        "inputNotes": "テキストを入力してください",
        "successDeleteTrailNotes": "追跡メモの削除に成功",
        "successCreateTrailNotes": "追跡メモの作成に成功",
        "deleteTrailNote": "追跡メモを削除",
        "msgDeleteTrailNote": "これを削除してもよろしいですか？"
    },
    "supplier": {
        "permissionRequired": "オペレーターは権限を選択する必要があります",
        "offerDetail": "オファーの詳細",
        "orderDetail": "注文の詳細",
        "bookingNumber": "予約番号",
        "date": "日付",
        "pickupLocation": "ピックアップ場所",
        "dropoffLocation": "降車場所",
        "passenger": "乗客",
        "luggage": "荷物",
        "pickupService": "ピックアップサービス",
        "pickupSignage": "ピックアップ看板",
        "flightNumber": "フライト番号",
        "departure": "出発",
        "arrival": "到着",
        "terminal": "ターミナル",
        "gate": "ゲート",
        "meetGreet": "ミート＆グリート",
        "specialRequests": "特別リクエスト",
        "additionalServices": "追加サービス",
        "price": "価格",
        "prices": "価格",
        "guest": "ゲスト",
        "serviceClass": "サービスクラス",
        "distance": "距離",
        "duration": "所要時間",
        "acceptOffer": "オファーを受ける",
        "updateBooking": "予約を更新",
        "package": "パッケージ",
        "route": "ルート",
        "assignedChauffer": "割り当てられた運転手",
        "assignedVehicle": "割り当てられた車両",
        "logs": "ログ",
        "link": "リンク",
        "desLink": "リンクをコピーするにはクリック",
        "trackLink": "トラックリンク",
        "driverLink": "ドライバーリンク",
        "close": "閉じる",
        "links": "リンク",
        "linksDes": "リンクをコピーするにはクリック",
        "status": "ステータス",
        "rating": "評価",
        "permissions": "権限",
        "permissionsNote": "チームに異なるレベルの承認を割り当てます。オペレーターがオープンオファーの可視性を持つか、すでに受け入れられた割り当てのみを表示するかを選択できます。",
        "owner": "オーナー",
        "ownerNote": "フルアクセス",
        "manageUsers": "ユーザー管理",
        "manageUsersNote": "新しいオペレーターの追加/削除",
        "manageDrivers": "ドライバー管理",
        "manageDriversNote": "ドライバーの追加/削除",
        "manageVehicles": "車両管理",
        "manageVehiclesNote": "車両の追加/削除",
        "mapView": "ライブマップ",
        "mapViewNote": "ドライバーとアクティブなトリップを地図上でリアルタイムに追跡。",
        "calendarView": "カレンダービュー",
        "calendarViewNote": "割り当てられたすべてのトリップのカレンダーを表示",
        "bookingView": "予約ビュー",
        "bookingViewNote": "現在の予約を表示および管理し、ドライバーを割り当て、車両を割り当てる。",
        "offersView": "オファービュー",
        "offersViewNote": "新しい仕事のオファーを受け入れる。",
        "pricing": "価格",
        "pricingNote": "トリップの価格を表示",
        "report": "レポート",
        "reportNote": "予約および財務レポートを表示",
        "reportExport": "レポートをエクスポート",
        "reportExportNote": "レポートを.csvにエクスポート",
        "userDetail": "ユーザーの詳細",
        "addOperator": "オペレーターを追加",
        "updateOperator": "オペレーターを更新",
        "supplier": "サプライヤー",
        "zone": "ゾーン",
        "emails": "メール",
        "email": "メール",
        "phoneNumber": "電話番号",
        "Deactivate": "非アクティブ化",
        "Activate": "アクティブ化",
        "ALERT_SELECT_DEACTIVATE": "非アクティブにするサプライヤーを選択してください",
        "ALERT_SELECT_ACTIVATE": "アクティブにするサプライヤーを選択してください",
        "CONFIRM_DEACTIVATE_MULTI": "これらのサプライヤーを非アクティブにしますか？",
        "CONFIRM_ACTIVATE_MULTI": "これらのサプライヤーをアクティブにしますか？",
        "Update_supplier_user_status_success": "サプライヤーステータスが正常に更新されました",
        "Update_supplier_user_status_fail": "サプライヤーステータスの更新中にエラーが発生しました",
        "agent": "エージェント",
        "Supplier": "サプライヤー",
        "addSupplier": "サプライヤーを追加",
        "detailSupplier": "サプライヤーの詳細",
        "addUser": "ユーザーを追加",
        "Add": "追加",
        "ContactInfo": "連絡先情報",
        "policy": "ポリシー",
        "users": "ユーザー",
        "setting": "設定",
        "zones": "ゾーン",
        "actions": "アクション",
        "companyName": "会社名",
        "corporationType": "法人タイプ",
        "country": "国",
        "ADMIN_INFORMATION": "管理者情報",
        "firstName": "名",
        "lastName": "姓",
        "commissionValue": "フリート手数料",
        "commissionValueDes": "すべての完了した注文に対してフリートが稼ぐ手数料を設定。",
        "SupplierType": "サプライヤータイプ",
        "SupplierTypeDes": "サプライヤータイプを選択。",
        "paymentMethodsApplyDes": "サプライヤーが処理できるジョブ固有の支払い方法：",
        "bookingSettings": "予約設定",
        "bookingSettingsDes": "ジョブの可視性、リリース時間、遅延を設定してサプライヤーの効率的な運用を実現。",
        "advanceBookingVisibilityTooltip": "サプライヤーが注文を何日前に見ることができるかを指定。",
        "delayBroadcastToolTip": "リリース時間またはその日の開始（00:00）からの遅延時間を設定。この遅延は、初期リリース時間後にジョブがサプライヤーに表示されるタイミングを制御します。",
        "releaseTimeToolTip": "サプライヤーにジョブがリリースされる時刻を1日目から設定。サプライヤーに十分な準備時間を確保してください。",
        "otherSettings": "その他の設定",
        "hideFare": "ドライバー - 運賃を常に非表示",
        "hideFareDes": "このサプライヤーのすべてのドライバーに対して運賃を非表示にします。",
        "supplierJobRequired": "サプライヤーのジョブ受付必須",
        "supplierJobRequiredDes": "サプライヤーは、ドライバー/車両を割り当てるか、マーケットプレイスでドライバーにジョブを表示させる前に、ジョブを認識して受け入れる必要があります。",
        "supplierApproval": "サプライヤーのドライバー/車両承認",
        "supplierApprovalDes": "サプライヤーオペレーターが自社フリート内でドライバーと車両をアクティブ化できるようにします。",
        "emailsApply": "メール通知",
        "emailsApplyDes": "新しいジョブや予約更新を送信するメール。",
        "address": "住所",
        "zipCode": "郵便番号",
        "city": "都市",
        "state": "州/県",
        "tax": "税務識別番号",
        "businessRegistration": "事業登録番号",
        "vatNumber": "VAT ID/番号",
        "mobile": "モバイル",
        "editOperator": "オペレーターを編集",
        "searchEmail": "メールを検索",
        "acceptOfferSuccess": "オファーの受付に成功",
        "acceptOfferFail": "予約が見つからないか、別のサプライヤーが予約を受け入れました",
        "driverPayout": "ドライバーの支払い",
        "payout": "支払い",
        "totalPayout": "合計支払い",
        "totalPayoutDes": "合計支払いには、基本支払い金額に加えて、プロバイダーに割り当てることを選択した追加料金が含まれます。",
        "supplierPayout": "サプライヤーの支払い",
        "driverTip": "ドライバーのチップ",
        "cancel": "キャンセル",
        "save": "保存",
        "activeSupplier": "アクティブサプライヤー",
        "userName": "ユーザー名",
        "bookingCompletedView": "完了した予約",
        "bookingCompletedViewNote": "すべての完了した予約の履歴を表示。",
        "driverEarning": "ドライバーの収益",
        "payoutCurrency": "支払通貨",
        "payoutCurrencyDes": "サプライヤー/ドライバーに支払う通貨",
        "commissionSupplier": "コミッション",
        "commissionSupplierDes": "完了した注文ごとにサプライヤーが稼ぐコミッションを設定します。",
        "amount": "固定金額",
        "percent": "割合",
        "commissionSupplierValue": "コミッション値を入力"
    },
    "noResultFound": "結果が見つかりません",
    "invalidDate": "日付が無効です。mm/dd/yyyy形式で日付を入力してください。"
};
