import React, { Component } from 'react';
import { Image } from "react-bootstrap";
import map_icon from "./../../assets/images/icons/map_autocomplete.svg";

const placeTypes = {
    pu: ['all', 'pu'],
    do: ['all', 'do']
};

export default class AutoCompleteItem extends Component {
    render() {
        const {
            suggestItem,
            onMouseDown,
            suggestion,
            pickUp,
            pointOfInterest
        } = this.props || {};
        const { addressSearch = suggestion, alias, locationType, location, locations } = suggestItem || {};
        const { address = addressSearch } = location || {};
        return (
            (!locationType || locationType !== 'multiple' || !locations || locations.length < 1) ?
                <div className={"place-item single"} onMouseDown={() => onMouseDown(`${alias ? `${alias} - ` : ``}${address}`, suggestItem, pointOfInterest)}>
                    {/* onMouseMove={() => onMouseOver(suggestItem.index)}  */}
                    <a>
                        <p className="place-item__allias">
                            <Image src={map_icon} className={"places-autocomplete-icon"} />
                            <span>{alias || address}</span>
                        </p>
                        {
                            alias && <p className="place-item__addressSearch">
                                {address}
                            </p>
                        }
                    </a>
                </div>
                :
                <div className={"place-item multiple"}>
                    <div>
                        <p className="place-item__allias">
                            <Image src={map_icon} className={"places-autocomplete-icon"} />
                            <span>{alias}</span>
                        </p>
                        <p className="place-item__addressSearch">
                            {address}
                        </p>
                    </div>
                    <ul className="place-item__list">
                        {
                            locations.map((l, index) => {
                                return ((pickUp && placeTypes.pu.indexOf(l.placeType) > -1) || (!pickUp && placeTypes.do.indexOf(l.placeType) > -1) || true) && (
                                    <li
                                        key={index}
                                        onMouseDown={() => onMouseDown(`${alias} - ${l.name}`, {
                                            location: {
                                                ...l,
                                                city: location.city,
                                                zipCode: l.zipCode || location.zipCode,
                                            }
                                        }, pointOfInterest)}
                                    >
                                        <p className="place-item__allias">
                                            <span>{l.name}</span>
                                        </p>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
        )
    }
}
