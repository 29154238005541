import _ from 'lodash';
import {
  CARTYPE_REQUEST,
  CARTYPE_SUCCESS,
  CARTYPE_FAILURE,
  OPERATOR_REQUEST,
  OPERATOR_SUCCESS,
  OPERATOR_FAILURE,
  BOOKING_FROM_REQUEST,
  BOOKING_FROM_SUCCESS,
  BOOKING_FROM_FAILURE,
  FETCH_CITIES_REQUEST,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_FAILURE,
  FETCH_BOOKING_TEMPLATE_REQUEST,
  FETCH_BOOKING_TEMPLATE_SUCCESS,
  FETCH_BOOKING_TEMPLATE_FAILURE,
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  FETCH_VEHICLES_REQUEST,
  FETCH_VEHICLES_SUCCESS,
  FETCH_VEHICLES_FAILURE,
  FETCH_DRIVERS_REQUEST,
  FETCH_DRIVERS_SUCCESS,
  FETCH_DRIVERS_FAILURE,
  LOAD_MORE_VEHICLES_REQUEST,
  LOAD_MORE_VEHICLES_SUCCESS,
  LOAD_MORE_VEHICLES_FAILURE,
  LOAD_MORE_VEHICLES_BY_TYPE_REQUEST,
  LOAD_MORE_VEHICLES_BY_TYPE_SUCCESS,
  LOAD_MORE_VEHICLES_BY_TYPE_FAILURE,
  MAP_ZONE_REQUEST,
  MAP_ZONE_SUCCESS,
  MAP_ZONE_FAILURE,
  GATEWAY_ZONE_REQUEST,
  GATEWAY_ZONE_SUCCESS,
  GATEWAY_ZONE_FAILURE,
  FETCH_RELATIVE_INFO_REQUEST,
  FETCH_RELATIVE_INFO_SUCCESS,
  FETCH_RELATIVE_INFO_FAILURE,
  FETCH_CURRENCIES_REQUEST,
  FETCH_CURRENCIES_SUCCESS,
  FETCH_CURRENCIES_FAILURE,
  GET_ALLOWABLE_DATA_SPECIFIED_PERMISSION_FAILED,
  GET_ALLOWABLE_DATA_SPECIFIED_PERMISSION_SUCCESS,
  GET_ALLOWABLE_DATA_SPECIFIED_PERMISSION_REQUEST,
  UPDATE_AUTO_REFRESH_CUE_AND_MAP,
  LOAD_MORE_DRIVERS_REQUEST,
  LOAD_MORE_DRIVERS_SUCCESS,
  LOAD_MORE_DRIVERS_FAILURE,
  INTERCITYROUTEWITHCARTYPE_REQUEST,
  INTERCITYROUTEWITHCARTYPE_SUCCESS,
  INTERCITYROUTEWITHCARTYPE_FAILURE,
  FETCH_PAYOUTTIME_REQUEST,
  AIRLINE_REQUEST,
  AIRLINE_SUCCESS,
  AIRLINE_FAILURE,
  SET_IS_CHINA,
  MAP_ZONE_SUCCESS_FULL_GEO
} from '../actions/commonDataAction';

function initializeState() {
  return {
    carType: [],
    operator: [],
    airline: [],
    bookingFrom: [],
    cities: [],
    companies: [],
    suppliers: [],
    supplierAndAgent: [],
    vehicles: [],
    drivers: [],
    currencies: [],
    loadedCarType: false,
    loadedOperator: false,
    loadedAirline: false,
    isAutoRefreshCueAndMap: false,
    isZoneLoadFullGeo: false,
    fetchTime: {
      driver: null,
      vehicle: null
    },
    location: {
      isChina: false
    },
    intercityRoutes: []
  };
}
export default function commonData(state = initializeState(), action) {
  switch (action.type) {
    case CARTYPE_REQUEST: {
      return state;
    }
    case CARTYPE_SUCCESS: {
      return Object.assign({}, state, {
        carType: action.data,
        // carTypeCity: action.data.filter(o => !o.intercity && !o.delivery && !o.superHelper),
        carTypeCity: action.data.filter(o => {
          let data = false;
          if (!o.intercity && !o.delivery && !o.superHelper) {
            data = true;
          };
          if (o.superHelper && o.bookNow) {
            data = true;
          };
          return data;
        }
        ),
        carTypeTransport: action.data.filter(o => o.bookNow || o.bookLater),
        carTypeIntercity: action.data.filter(o => o.intercity),
        carTypeDelivery: action.data.filter(o => o.delivery || (o.superHelper && !o.bookNow) || o.food || o.mart),
        carTypeStreetSharing: action.data.filter(o => o.streetSharing),
        carTypeFood: action.data.filter(o => o.food && !o.bookNow),
        carTypeMart: action.data.filter(o => o.mart && !o.bookNow)
      }, { loadedCarType: true });
    }
    case CARTYPE_FAILURE: {
      return Object.assign({}, state, { loadedCarType: true });
    }

    case OPERATOR_REQUEST: {
      return state;
    }
    case OPERATOR_SUCCESS: {
      return Object.assign({}, state, { operator: action.data }, { loadedOperator: true });
    }
    case OPERATOR_FAILURE: {
      return Object.assign({}, state, { loadedOperator: true });
    }

    case BOOKING_FROM_REQUEST: {
      return state;
    }
    case BOOKING_FROM_SUCCESS: {
      return { ...state, bookingFrom: action.data };
    }
    case BOOKING_FROM_FAILURE: {
      return state;
    }
    case FETCH_CITIES_REQUEST: {
      return state;
    }
    case FETCH_CITIES_SUCCESS: {
      return { ...state, cities: action.data };
    }
    case FETCH_CITIES_FAILURE: {
      return state;
    }
    case FETCH_COMPANIES_REQUEST: {
      return state;
    }
    case FETCH_COMPANIES_SUCCESS: {
      return { 
        ...state, 
        companies: action.data, 
        suppliers: action.suppliers || [],
        supplierAndAgent: [...(action.data || []), ...(action.suppliers || [])]
      };
    }
    case FETCH_COMPANIES_FAILURE: {
      return state;
    }
    case FETCH_VEHICLES_REQUEST: {
      return state;
    }
    case FETCH_VEHICLES_SUCCESS: {
      const fetchTime = {
        vehicle: action.fetchTime,
        driver: state.fetchTime.driver
      };
      if (Array.isArray(action.data) && action.data.length) {
        const vehicles = Object.assign(_.keyBy(state.vehicles, '_id'), _.keyBy(action.data, '_id'));
        return {
          ...state,
          fetchTime,
          vehicles: _.sortBy(_.values(vehicles), 'plateNumber')
        };
      }
      return { ...state, fetchTime };
    }
    case FETCH_VEHICLES_FAILURE: {
      return state;
    }
    case LOAD_MORE_VEHICLES_REQUEST:
    case LOAD_MORE_VEHICLES_SUCCESS:
    case LOAD_MORE_VEHICLES_FAILURE: {
      return state;
    }
    case LOAD_MORE_VEHICLES_BY_TYPE_REQUEST:
    case LOAD_MORE_VEHICLES_BY_TYPE_SUCCESS:
    case LOAD_MORE_VEHICLES_BY_TYPE_FAILURE: {
      return state;
    }
    case FETCH_CURRENCIES_REQUEST: {
      return state;
    }
    case FETCH_CURRENCIES_SUCCESS: {
      return { ...state, currencies: action.data };
    }
    case FETCH_CURRENCIES_FAILURE: {
      return state;
    }
    case FETCH_DRIVERS_REQUEST: {
      return state;
    }
    case FETCH_DRIVERS_SUCCESS: {
      const fetchTime = {
        driver: action.fetchTime,
        vehicle: state.fetchTime.vehicle
      };
      if (Array.isArray(action.data) && action.data.length) {
        const drivers = Object.assign(_.keyBy(state.drivers, '_id'), _.keyBy(action.data, '_id'));
        return { ...state, fetchTime, drivers: _.sortBy(_.values(drivers), 'fullName') };
      }
      return { ...state, fetchTime };
    }
    case FETCH_DRIVERS_FAILURE: {
      return state;
    }
    case LOAD_MORE_DRIVERS_REQUEST: {
      return state;
    }
    case LOAD_MORE_DRIVERS_SUCCESS: {
      return state;
    }
    case LOAD_MORE_DRIVERS_FAILURE: {
      return state;
    }
    case MAP_ZONE_REQUEST: {
      return state;
    }
    case MAP_ZONE_SUCCESS: {
      return { ...state, mapZone: action.data };
    }
    case MAP_ZONE_SUCCESS_FULL_GEO: {
      return { 
        ...state, 
        mapZone: action.data,
        isZoneLoadFullGeo: true
      };
    }
    case MAP_ZONE_FAILURE: {
      return state;
    }
    case GATEWAY_ZONE_REQUEST: {
      return state;
    }
    case GATEWAY_ZONE_SUCCESS: {
      return { ...state, gatewayZones: action.data };
    }
    case GATEWAY_ZONE_FAILURE: {
      return state;
    }

    case FETCH_RELATIVE_INFO_REQUEST: {
      return state;
    }
    case FETCH_RELATIVE_INFO_SUCCESS: {
      return { ...state, relativeInfo: action.data };
    }
    case FETCH_RELATIVE_INFO_FAILURE: {
      return state;
    }
    case GET_ALLOWABLE_DATA_SPECIFIED_PERMISSION_FAILED:
    case GET_ALLOWABLE_DATA_SPECIFIED_PERMISSION_REQUEST:
      return state;
    case GET_ALLOWABLE_DATA_SPECIFIED_PERMISSION_SUCCESS:
      return {
        ...state,
        allowableData: action.data
      };
    case UPDATE_AUTO_REFRESH_CUE_AND_MAP: {
      return {
        ...state,
        isAutoRefreshCueAndMap: action.data
      };
    }
    case SET_IS_CHINA: {
      console.log('SET_IS_CHINA', action.data)
      if (state.location) {
        state.location.isChina = action.data || false;
      }
      return { ...state };
    }
    case INTERCITYROUTEWITHCARTYPE_REQUEST: {
      return state;
    }
    case INTERCITYROUTEWITHCARTYPE_SUCCESS: {
      return Object.assign({}, state, {
        intercityRoutes: action.data,
      }, { loadedCarType: true });
    }
    case INTERCITYROUTEWITHCARTYPE_FAILURE: {
      return Object.assign({}, state, { loadedCarType: true });
    }


    case AIRLINE_REQUEST: {
      return state;
    }
    case AIRLINE_SUCCESS: {
      return Object.assign({}, state, { airline: action.data }, { loadedAirline: true });
    }
    case AIRLINE_FAILURE: {
      return Object.assign({}, state, { loadedAirline: true });
    }


    default:
      return { ...state };
  }
  return state;
}
