/* global google */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helmet from 'react-helmet';
import { setLocale, I18n, loadTranslations } from 'react-redux-i18n';
import { Promise } from 'bluebird';

// import Sidebar from '../../components/sidebar/Sidebar';
// import Loading from '../../components/loadingBar/loading';
// import LoadingSpiner from '../../components/loadingSpiner/LoadingSpiner';
import { HotKeys } from 'react-hotkeys';
import { keyMap } from '../../constants/hotkeys';
// import NewBooking from '../../containers/newbooking/NewBooking';
// import NewIntercityBooking from '../../containers/newIntercityBooking';
// import NewDeliveryBooking from "../../containers/newDeliveryBooking/NewBookingDelivery";
// import BookingDetail from '../../components/bookingDetail/BookingDetail';
// import IntercityBookingDetail from '../newIntercityBooking/IntercityBookingDetail';
// import DeliveryBookingDetail from '../newDeliveryBooking/DeliveryBookingDetail';
// import FoodOrderDetail from '../newFoodOrder/FoodOrderDetail';

const Sidebar = React.lazy(() => import('../../components/sidebar/Sidebar'));
const Loading = React.lazy(() => import('../../components/loadingBar/loading'));
const LoadingSpiner = React.lazy(() => import('../../components/loadingSpiner/LoadingSpiner'));
const NewBooking = React.lazy(() => import('../../containers/newbooking/NewBooking'));
const NewIntercityBooking = React.lazy(() => import('../../containers/newIntercityBooking'));
const NewDeliveryBooking = React.lazy(() => import("../../containers/newDeliveryBooking/NewBookingDelivery"));
const BookingDetail = React.lazy(() => import("../../components/bookingDetail/BookingDetail"));
const IntercityBookingDetail = React.lazy(() => import("../newIntercityBooking/IntercityBookingDetail"));
const DeliveryBookingDetail = React.lazy(() => import("../newDeliveryBooking/DeliveryBookingDetail"));
const FoodOrderDetail = React.lazy(() => import("../newFoodOrder/FoodOrderDetail"));

import { logout, loadUserFleet } from '../../actions/auth';
import 'react-notifications/lib/notifications.css';
import './App.scss';
import './App-mobile.scss';
import './react-datetime.scss';
import '../../assets/styles/custom.scss';
import "../newbooking/newbooking.scss";
import "../../components/AutoSuggestAirport/index.scss";
import * as menuActions from '../../actions/sidebarCollapsedAction';
import * as commonActions from '../../actions/commonDataAction';
import packageJson from '../../../package.json'
import * as socketActions from '../../actions/socketActions';
import * as mapActions from '../../actions/mapActions';
import * as cueActions from '../../actions/cueActions';
import * as intercityBookingActions from '../../actions/intercityBookingActions';
import * as shuttleAction from '../../actions/shuttleAction';
import { socketDispatchApi } from '../../utils/socketUtils';
import {
  newBookingRequest,
  closeNewBookingForm,
  resetDataNewBook
} from '../../actions/newbookingAction';
import * as bookingDetailActions from '../../actions/bookingDetailAction';
import * as reportActions from '../../actions/reportActions';
import * as paymentMethodActions from '../../actions/paymentMethodActions';
import * as notificationActions from '../../actions/notificationActions';
import * as loadingActions from '../../actions/loadingBarActions';
import * as reservationActions from '../../actions/reservationActions';
import * as callCenterSupportActions from '../../actions/callCenterSupportActions';
import * as newbookingActions from '../../actions/newbookingAction';
import * as deliveryAction from "../../actions/deliveryAction";
import * as bookingActions from '../../actions/bookingActions';
const AppNotifArea = React.lazy(() => import("./AppNotifArea"));
// import AppNotifArea from './AppNotifArea';
// import BookingDetail from '../../components/bookingDetail/BookingDetail';
import ErrorPage from '../misc/ErrorPage';
import PropTypes from 'prop-types';
import _, {cloneDeep, get, forEach, isArray, remove} from 'lodash';
import { translations } from '../../constants/languages/languages';
import { toggleSidebarEvent } from '../../constants/customEvent';
import * as settingActions from '../../actions/settingActions';
// import { ipCheck, notifyCrashClientAPI } from '../../constants/ApiConfigs';
import {
  isMobile,
  dispatchOuterTableChange,
  getPermissionViewByName,
  notifyCrashToServer,
  postDataCrash
} from '../../utils/commonFunctions';
import {
  langaugeCC
} from '../../constants/commondata';
// import IntercityBookingDetail from '../newIntercityBooking/IntercityBookingDetail';
// import DeliveryBookingDetail from '../newDeliveryBooking/DeliveryBookingDetail';
// import FoodOrderDetail from '../newFoodOrder/FoodOrderDetail';
import moment from 'moment';
import { Image } from 'react-bootstrap';
import collapseMenuIcon from "../../assets/images/icons/menu_collapsed.svg";

// import axios from 'axios';
// import { loadUserProfile } from '../../utils/apiUtils';
require('react-intl-tel-input/dist/libphonenumber.js');
require('react-intl-tel-input/dist/main.css');

notifyCrashToServer()

function new_script(src) {
  return new Promise(function (resolve) {
    try {
      var script = document.createElement('script');
      script.src = src;
      script.defer = 'defer';
      script.addEventListener('load', function () {
        resolve();
      });
      script.addEventListener('error', function () {
      });
      document.body.appendChild(script);
    } catch (error) {
      console.log(error);
    }
  });
}
// Promise Interface can ensure load the script only once.
var my_script = new_script('');

class App extends Component {
  constructor() {
    super();
    this.state = {
      AddNew: false,
      notification: [],
      sosNotification: [],
      sos: null,
      appNotification: [],
      isShowComfirm: false,
      isShowMobileSideBar: false,
    };
  }

  UNSAFE_componentWillMount() {
    const { auth } = this.props;
    const { router } = this.context;
    const {pathname, search} = this.props.location;

    // If this page is restricted, go to loginPage first.
    // (But pass on this page's path in order to redirect back upon login)
    if (!auth.user) {
      if(search) {
        router.push(`/login${search}&redirect=${pathname}`);
      } else {
        router.push(`/login?redirect=${pathname}`);
      }
    } else {
      if (auth.user.defaultPw) {
        router.push(`/change-password`);
      } else {
        this.initSystemData();
      }
    }
  }

  handleOpenDetailPage = (params) => {
    this.props.closeNewBookingRequest();
    this.props.intercityBookingActions.closeIntercityBookingForm();
    this.props.deliveryAction.closeDeliveryBookingForm();
    this.props.router.push(params);
  }

  shouldComponentUpdate(nextProps) {
    const { language: { locale: currentLanguage = 'en-US' } = {} } =
      this.props || {};
    const { language: { locale: nextLanguage = 'en-US' } = {} } =
      nextProps || {};
    const { localeCode: currentLocaleCode } = this.props.selectedFleet || {};
    const { localeCode: nextLocaleCode } = nextProps.selectedFleet || {};
    if (
      nextLanguage !== currentLanguage ||
      currentLocaleCode !== nextLocaleCode
    )
      this.updateDatetimeByLanguage(nextLanguage, nextLocaleCode);
    return true;
  }

  componentDidMount() {
    const { language: { locale: language = 'en-US' } = {} } = this.props || {};
    this.updateDatetimeByLanguage(language);
    console.log('Version: ', packageJson.version);
  }

  componentDidUpdate(prevProps) {
    if (this.props.sidebarCollapsed !== prevProps.sidebarCollapsed) {
      window.dispatchEvent(toggleSidebarEvent);
    }
  }

  updateDatetimeByLanguage = (language, nextLocaleCode) => {
    const localeData =
      moment.localeData(
        'vi'
      ); /* Get VN weekday to fix week is from Mon to Sun */
    const week = localeData._week;
    const localeDatabyLangugage = moment.localeData(language);
    const localeDateDefault = moment.localeData('en');
    moment.locale(language, {
      months: localeDatabyLangugage._months || localeDateDefault._moths,
      monthsShort:
        localeDatabyLangugage._monthsShort || localeDateDefault._monthsShort,
      monthsParseExact: true,
      weekdays: localeDatabyLangugage._weekdays || localeDateDefault._weekdays,
      weekdaysShort:
        localeDatabyLangugage._weekdaysShort ||
        localeDateDefault._weekdaysShort,
      weekdaysMin:
        localeDatabyLangugage._weekdaysMin || localeDateDefault._weekdaysMin,
      weekdaysParseExact: true,
      longDateFormat:
        localeDatabyLangugage._longDateFormat ||
        localeDateDefault._longDateFormat,
      calendar: localeDatabyLangugage._calendar || localeDateDefault._calendar,
      relativeTime:
        localeDatabyLangugage._relativeTime || localeDateDefault._relativeTime,
      dayOfMonthOrdinalParse:
        localeDatabyLangugage._dayOfMonthOrdinalParse ||
        localeDateDefault._dayOfMonthOrdinalParse,
      ordinal: localeDatabyLangugage.ordinal || localeDateDefault.ordinal,
      meridiemParse:
        localeDatabyLangugage._meridiemParse ||
        localeDateDefault._meridiemParse,
      isPM: localeDatabyLangugage.isPM || localeDateDefault.isPM,
      meridiem: localeDatabyLangugage.meridiem || localeDateDefault.meridiem,
      meridiemHour:
        localeDatabyLangugage.meridiemHour || localeDateDefault.meridiemHour,
      week,
    });
  };

  loadedMapCallback = () => {
    console.log('Map script is loaded');
    this.setState({ mapLoad: true });
  }

  doLoad = (link) => {
    my_script = new_script(link);
    window.loadedMapCallback = this.loadedMapCallback;
    my_script
      .then(function () {
      })
      .catch(function () {
        console.log('error on load map', link);
      });
  };

  validateLanguages = (languagesCode) => {
    if (languagesCode && languagesCode.length > 0) {
      let languages = {};
      Object.keys(translations).map((key) => {
        if (languagesCode.includes(key) || key == 'en-US') {
          languages[key] = translations[key];
        }
      });
      this.props.loadTranslations(languages);
      if (!languages[localStorage.getItem('language')]) {
        this.changeLanguage('en-US');
      } else {
        this.changeLanguage(localStorage.getItem('language'));
      }
    }
  };

  mobileHambergerClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ isShowMobileSideBar: !this.state.isShowMobileSideBar });
  };

  pageContentClickHandle = () => {
    let mobile = isMobile();
    if (mobile) {
      this.setState({ isShowMobileSideBar: false });
    }
  };

  getDefaultLanguage = (settingLanguage, ccLangauge) => {
    const langaugeI18n  = langaugeCC[settingLanguage]
    if(ccLangauge.includes(langaugeI18n)) this.props.setLanguage(langaugeI18n)
  }

  checkCountryIsChina = async (data) => {
    const urlParams = new URLSearchParams(window.location.search);
    if (
      urlParams.get('locale') === 'CN' ||
      data?.res?.generalSetting?.useTencentMapOnCC
    ) {
      return true;
    }
    return false;
  }

  getFleetIdFromLocalStorage = () => {
    let fleetObject;
    try {
      fleetObject = JSON.parse(localStorage.getItem('fleet')) || {};
    } catch (err) {
      return ''
    }
    return fleetObject?.fleetId || ''
  }

  initSystemData = () => {
    let fleetObject;
    try {
      fleetObject = JSON.parse(localStorage.getItem('fleet')) || {};
    } catch (err) {
      fleetObject = {};
    }
    const path = this.props.location.pathname;
    const { menuHandle, commonActions } = this.props;
    const { router } = this.context;

    if (!fleetObject.fleetId) {
      fleetObject = this.props.auth.user.fleetList[0];
      let fleetOwner = this.props.auth.user.fleetList.filter((fl) => {
        return fl.isOwner;
      })[0];
      if (fleetOwner) {
        fleetObject = fleetOwner;
      }
    }

    this.props.loadUserFleet({ fleetId: fleetObject.fleetId }).then( async (data) => {
      if (data.ok && data.res) {
        localStorage.setItem('fleet', JSON.stringify(fleetObject));
        localStorage.setItem('fleet_country_code', data.res.countryCode);
        const fleetId = fleetObject.fleetId;
        const isChina = await this.checkCountryIsChina(data, fleetObject.fleetId)
        /**
         * localstorage: indexSelectedKey, limitedKey
         * switch key when Over Quota Limit
         */
        this.props.commonActions.setIsChina(isChina)
        if(isChina) {
          this.doLoad(`https://map.qq.com/api/js?key=${process.env.REACT_APP_TENCENT_API_KEY}&v=2.exp&libraries=place,drawing,geometry,autocomplete&callback=loadedMapCallback`)
        } else if (data.res.googleCredential) {
          this.cachingKeyGoogleServices(
            data.res.googleCredential.clientId,
            data.res.googleCredential.apiKey,
            fleetId,
            isChina
          );
        }
        const defaultLanguage = data.res.language
        if(!localStorage.getItem('language') && defaultLanguage) {
          this.props.setLanguage('es-ES')
          this.getDefaultLanguage(defaultLanguage, data.res.ccLanguages)
        } 
        //Check location for map provider
        if (data != null) {
          this.validateLanguages(data.res.ccLanguages);

          commonActions.autoRefreshCueAndMapChange(
            data.res.map && data.res.map.isAutoRefresh
          );

          // Hide submodule Total adjustment in report when disable edit total fare in Cue
          let menuRoles = cloneDeep(data.res.modules);

          if (
            !data.res.generalSetting ||
            (!data.res.generalSetting.editBookingFare &&
              !data.res.generalSetting.markupPrice)
          ) {
            forEach(menuRoles, (menu) => {
              if (menu.key === 'Report') {
                if (isArray(menu.actions)) {
                  remove(
                    menu.actions,
                    (sub) => sub.name === 'Total adjustment'
                  );
                }
                return false;
              }
            });
          }

          this.props.menuActions.buildMenu(
            this.props.auth.user.isAdmin,
            [
              ...menuRoles,
              {
                key: 'Notifications',
                name: 'Notifications',
                actions: [
                  {
                    name: 'View',
                    isActive: true,
                  },
                  {
                    name: 'Actions',
                    isActive: true,
                  },
                ],
              },
              {
                key: 'Sos',
                name: 'Sos',
                actions: [
                  {
                    name: 'View',
                    isActive: true,
                  },
                  {
                    name: 'Actions',
                    isActive: true,
                  },
                ],
              },
              {
                key: 'SignUpNotifications',
                name: 'SignUpNotifications',
                actions: [
                  {
                    name: 'View',
                    isActive: true,
                  },
                  {
                    name: 'Actions',
                    isActive: true,
                  },
                ],
              },
            ],
            {
              ...data.res.moduleSettings,
              affiliate: data.res.affiliate || {},
              importChauffeurBooking:
                (data.res &&
                  data.res.generalSetting &&
                  data.res.generalSetting.importChauffeurBooking) ||
                false,
            },
            this.props.auth.user.roles.moduleAdmin,
            this.props.auth,
          );

          commonActions
            .loadBookingFrom(fleetId)
            .then((data) => {
              if (data.ok && data.res) {
                this.props.cueActions.cueBookingFromFilterList(data.res);
              }
            })
            .catch((error) => {
              console.log(error.error);
            });
          Promise.all([
            commonActions.getGatewayZones(fleetId),
            commonActions.loadCarType(fleetId),
            commonActions.fetchCurrencies(),
            commonActions.loadOperator(fleetId),
            commonActions.loadMapZone(fleetId, false),
            commonActions.fetchCities(fleetId, false),
            commonActions.fetchCompanies(fleetId, false),
            commonActions.getAllowableDriversAndCars({ fleetId }, false),
            commonActions.loadIntercityRoutesFetchWithCarType(fleetId),
            this.props.paymentMethodActions.paymentMethod(fleetId)
          ])
            .then(() => {
              this.setState({
                dataLoaded: true,
              });
            })
            .catch(() => {
              this.setState({
                dataLoaded: true,
              });
            });

          window.onpopstate = this.backButtonHandle; //browser back button handle
        }
        if (path.length == 0 || path == '/') {
          let menu = null;
          let mapRouter = menuHandle.menuList?.find(item => item.name == 'Map' && item.show); 
          if (mapRouter) {
            router.push(mapRouter.link);
            this.sideBarmenuClick(mapRouter.key);
            return; 
          }
          menuHandle.menuList.every((m) => {
            if (m.show && m.name !== 'New booking') {
              menu = m;
              return false;
            }
            return true;
          });
          if (menu) {
            router.push(menu.link);
            this.sideBarmenuClick(menu.key);
          }
        }
      } else {
        this.notification(
          'error',
          I18n.t('messages.booking.Can_not_connect_to_server'),
          I18n.t('messages.booking.Check_connection_or_contact_system_support')
        );
      }
    });
  };

  cachingKeyGoogleServices = (clientID, apiKey, fleetId, isChina = false) => {
    const storedlimitKey = localStorage.getItem('limitedKey');
    if (isChina) {
      this.setState({ mapLoad: true });
      return;
    }
    let chanelID = `CC-${fleetId}`;
    if (!storedlimitKey) {
      if (clientID) {
        this.storeClientIDGoogleServices(clientID, chanelID);
      } else if (apiKey) {
        this.storeAPIKeyGoogleServices(apiKey, chanelID);
      }
    } else {
      if (storedlimitKey == 'keyGoogleServices') {
        if (clientID) {
          this.storeClientIDGoogleServices(clientID, chanelID);
        }
      } else if (storedlimitKey == 'clientIDGoogleServices') {
        if (apiKey) {
          this.storeAPIKeyGoogleServices(apiKey, chanelID);
        }
      }
    }
  };

  storeClientIDGoogleServices(clientID, chanelID) {
    this.doLoad(
      `https://maps.googleapis.com/maps/api/js?client=${clientID}&channel=${chanelID}&libraries=places,visualization,marker&language=en&callback=loadedMapCallback`
    );
    localStorage.setItem('indexSelectedKey', 'clientIDGoogleServices');
  }

  storeAPIKeyGoogleServices(apiKey, chanelID) {
    this.doLoad(
      `https://maps.googleapis.com/maps/api/js?key=${apiKey}&channel=${chanelID}&libraries=places,visualization,marker&language=en&callback=loadedMapCallback`
    );
    localStorage.setItem('indexSelectedKey', 'keyGoogleServices');
  }

  backButtonHandle = () => {
    if (window.location.pathname != '/') {
      let path = window.location.pathname.split('/');
      this.props.menuActions.updateMenu(path);
    } else {
      let menu = null;
      this.props.menuHandle.menuList.every((m) => {
        if (m.show && m.name !== 'New booking') {
          menu = m;
          return false;
        }
        return true;
      });
      if (menu) {
        this.sideBarmenuClick(menu.key);
      }
    }
  };

  closeBookingDialog = () => {
    this.props.resetDataNewBook();
    this.props.bookingDetailActions.bookingDetailClosed();
  };

  notification = (type, title, message, clickHandler, time = 5000) => {
    if (!this.appNotifArea) return;
    this.appNotifArea.notification(type, title, message, clickHandler, time);
  };

  /* handleBookingDetailView = (bookId) => {
        this.props.bookingDetailActions.getBookingDetail(bookId).then(data => {
            if (!data.res || data.error) {
                this.props.bookingDetailActions
                    .getBookingDetail(bookId, true)
                    .then(data => {
                        if (!data.res || data.error) {
                            this.notification(
                                "error",
                                I18n.t("messages.booking.Booking_detail_not_found")
                            );
                        }
                    });
            }
        });
    } */

  getChildContext() {
    return {
      notification: this.notification,
      currenLanguage: this.props.language,
      user: this.props.auth.user,
      reloadCueAnhMap: this.initCueAndDriverData,
    };
  }

  sidebarCollapsedClickHandle = () => {
    this.props.menuActions.sidebarCollapsedAction(!this.props.sidebarCollapsed);
  };

  AddNewBooking = (bookType = 'city') => {
    console.log('AddNewBooking ', bookType);
    this.pageContentClickHandle();
    const { newBooking, intercityBooking, shuttleBooking, deliveryBooking } = this.props;
    if (bookType === 'intercity') {
      if (intercityBooking.isShow) {
        this.props.intercityBookingActions.closeIntercityBookingForm();
      } else {
        this.props.intercityBookingActions.newIntercityBookingRequest();
      }
      this.props.closeNewBookingRequest();
      this.props.shuttleAction.closeShuttleBookingForm();
      this.props.deliveryAction.closeDeliveryBookingForm();
    } else if (bookType === 'shuttle') {
      if (shuttleBooking.isShow) {
        this.props.shuttleAction.closeShuttleBookingForm();
      } else {
        this.props.shuttleAction.newShuttleBookingRequest();
      }
      this.props.intercityBookingActions.closeIntercityBookingForm();
      this.props.deliveryAction.closeDeliveryBookingForm();
      this.props.closeNewBookingRequest();
    } else if (bookType === 'delivery') {
      if (deliveryBooking.isShow) {
        this.props.deliveryAction.closeDeliveryBookingForm();
      } else {
        this.props.deliveryAction.newDeliveryBookingRequest();
      }
      this.props.intercityBookingActions.closeIntercityBookingForm();
      this.props.shuttleAction.closeShuttleBookingForm();
      this.props.closeNewBookingRequest();
    } else {
      if (newBooking.bookingFormShow) {
        this.props.closeNewBookingRequest();
      } else {
        this.props.newBookingRequest();
      }
      this.props.intercityBookingActions.closeIntercityBookingForm();
      this.props.deliveryAction.closeDeliveryBookingForm();
      this.props.shuttleAction.closeShuttleBookingForm();
    }
  };

  sideBarmenuClick = (key, hasSub = false) => {
    this.props.menuActions.sidebarLinkClickAction(
      key,
      this.state.isShowMobileSideBar
    );
    this.props.closeNewBookingRequest();
    this.props.intercityBookingActions.closeIntercityBookingForm();
    this.props.deliveryAction.closeDeliveryBookingForm();
    this.props.shuttleAction.closeShuttleBookingForm();
    if (!hasSub) {
      this.pageContentClickHandle();
    }
  };

  quickSaveAction() {
    this.sidebarCollapsedClickHandle();
  }

  changeLanguage = (lang) => {
    this.props.setLanguage(lang);
    dispatchOuterTableChange();
  };

  handleLogout = () => {
    const { user } = this.props;
    socketDispatchApi.close();
    this.props.logout(user);
  };

  buildPageTitle = (menu) => {
    let title = '';
    if (this.props.newBooking.bookingFormShow) {
      title = I18n.t('Sidebar.New_booking');
    } else {
      if (menu) {
        title += I18n.t(menu.text);
        if (menu.sub && menu.sub.length > 0) {
          let sub = menu.sub.filter((mn) => {
            return mn.active && mn.show;
          });
          sub = sub[0] || menu.sub[0];
          title += '/' + this.buildPageTitle(sub);
        }
      }
    }

    return title;
  };


  CheckAccessablePermission(menu) {
    let isAccessable = false;
    if (menu.sub) {
      menu.sub.every((m) => {
        if (m.active) {
          isAccessable = true;
          return false;
        }
        return true;
      });
    }
    return isAccessable;
  }

  componentDidCatch(error, errorInfo) {
    postDataCrash({
      errorMessage: error.message,
      errorStack: error.stack,
    })
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render() {
    if (this.state?.errorInfo) {
      return (
        <ErrorPage 
          errorType={"Crash"} 
          errorMessage={I18n.t('General.ServerError')}
        />
      );
    }
    const { dataLoaded } = this.state;
    const { router } = this.context;
    let isViewProfile;
    let isViewChangePass, isNewBookingFromUrl;
    let isViewNotifications, isViewSOS, isViewImportBooking;
    let importChauffeurBooking = get(
      this.props.auth,
      'selectedFleet.generalSetting.importChauffeurBooking',
      false
    );
    if (this.props.location.pathname === '/newbooking'
      || this.props.location.pathname === '/cue'
      || this.props.location.pathname === '/booking-detail'
    ) {
      if(getPermissionViewByName(this.props.auth.user, 'New booking', 'Actions')) {
        isNewBookingFromUrl = true
      }
    }
    if (this.props.location.pathname === '/profile') {
      isViewProfile = true;
    }
    if (this.props.location.pathname === '/change-password') {
      isViewChangePass = true;
    }
    if (this.props.location.pathname === '/notifications') {
      isViewNotifications = true;
    }
    if (this.props.location.pathname === '/sos') {
      isViewSOS = true;
    }
    if (
      this.props.location.pathname === '/import_bookings' &&
      importChauffeurBooking
    ) {
      isViewImportBooking = true;
    }
    const handles = {
      newBooking: this.AddNewBooking,
      quickSave: this.quickSaveAction.bind(this),
    };

    const isValidPages =
      this.props.menuHandle.selectedMainMenu ||
      isViewChangePass ||
      isViewProfile ||
      isViewNotifications ||
      isViewSOS ||
      isNewBookingFromUrl ||
      isViewImportBooking || true
    const isShowSubmenu =
      this.props.menuHandle.selectedMainMenu &&
      this.props.menuHandle.selectedMainMenu.sub &&
      this.props.menuHandle.selectedMainMenu.sub.length > 0;
    const pageContentClass =
      '' +
      (this.props.sidebarCollapsed ? 'collapsed' : '') +
      (isValidPages &&
        isShowSubmenu &&
        !this.props.newBooking.bookingFormShow &&
        !this.props.intercityBooking.isShow &&
        !this.props.deliveryBooking.isShow &&
        !this.props.shuttleBooking.isShow
        ? ' have-submenu'
        : '');
    let isAirlineBusiness = get(
      this.props.selectedFleet,
      'airlineBusiness.enable'
    );
    let isShuttleNewBooking = get(
      this.props.newBooking.bookingData,
      'isShuttle',
      false
    );
    let isIntercityBooking = false;
    let isParcelBooking = false;
    let isFoodBooking = false;
    let isMartBooking = false;
    let isTransportBooking = false;
    let isCrewBooking =
      get(this.props.bookingDetail, 'data.travelerType') === 2;
    if (this.props.bookingDetail.isShowing && this.props.bookingDetail.data && !this.props.auth?.user?.roles?.isSupplier) {
      let booking = this.props.bookingDetail.data || {};
      if (booking.intercity) {
        isIntercityBooking = true;
      } else if (booking.superHelper) {
        if (booking.jobType === 'superHelper') {
          isTransportBooking = true;
        } else {
          isParcelBooking = true;
        }
      } else if (booking.delivery) {
        if (
          booking.jobType === 'delivery' ||
          booking.jobType === 'shopForYou'
        ) {
          isParcelBooking = true;
        } else if (booking.jobType === 'food') {
          isFoodBooking = true;
        } else if (booking.jobType === 'mart') {
          isMartBooking = true;
        }
      } else {
        isTransportBooking = true;
      }
    }
    if (this.props.auth?.user?.roles?.isSupplier && this.props?.location?.pathname === '/booking-detail') {
      this.props.router.push({pathname:`/supplier-booking-detail/${this.props.location?.query?.bookId}`, 
        state: {selectedTab: this.props.location?.query?.isFinished !== 'FALSE' ? 1 : 0}
      })
    }
    return (dataLoaded &&
      this.props.selectedFleet &&
      this.props.menuHandle.menuList.length > 0 &&
      this.state.mapLoad) ||
      this.props.defaultPw ? (
      <React.Fragment>
        <HotKeys
          keyMap={keyMap}
          handlers={handles}
          className="fill app-wrapper"
          tabIndex="-1"
        >
          <Helmet
            title={this.buildPageTitle(this.props.menuHandle.selectedMainMenu)}
          />

          <Loading
            show={this.props.loadingBar.total > 0}
            color="red"
            change={true}
            showSpinner={true}
          />

          <div
            className={this.state.isShowMobileSideBar ? 'fill mobile' : 'fill'}
          >
            {!isMobile() && <div style={{display: this.props.sidebarCollapsed ? 'none' : 'block'}} className="collapse-icon" onClick={this.sidebarCollapsedClickHandle}>
              <Image src={collapseMenuIcon} />
            </div>}
            <Sidebar
              collapsed={this.props.sidebarCollapsed}
              collapedClickHandle={this.sidebarCollapsedClickHandle}
              isShowSubmenu={isShowSubmenu}
              menuList={
                this.props.defaultPw ? [] : this.props.menuHandle.menuList
              }
              onMenuClick={this.sideBarmenuClick}
              addNewClick={this.AddNewBooking}
              bookingFormShow={this.props.newBooking.bookingFormShow}
              language={this.props.language}
              languageSelect={this.changeLanguage}
              logout={this.handleLogout}
              translations={this.props.language.translations}
              selectedFleet={this.props.selectedFleet}
              isShuttleNewBooking={isShuttleNewBooking}
            />
            <div
              id="page-content"
              className={pageContentClass}
              onClick={this.pageContentClickHandle}
            >
              {isIntercityBooking && (
                <IntercityBookingDetail
                  show={this.props.bookingDetail.isShowing}
                  closeBookingDialog={this.closeBookingDialog}
                  data={this.props.bookingDetail.data}
                />
              )}
              {isParcelBooking && (
                <DeliveryBookingDetail
                  show={this.props.bookingDetail.isShowing}
                  closeBookingDialog={this.closeBookingDialog}
                  data={this.props.bookingDetail.data}
                  router={this.props.router}
                />
              )}
              {isFoodBooking && (
                <FoodOrderDetail
                  show={this.props.bookingDetail.isShowing}
                  closeBookingDialog={this.closeBookingDialog}
                  data={this.props.bookingDetail.data}
                />
              )}
              {isMartBooking && (
                <FoodOrderDetail
                  show={this.props.bookingDetail.isShowing}
                  closeBookingDialog={this.closeBookingDialog}
                  data={this.props.bookingDetail.data}
                />
              )}
              { 
                isTransportBooking &&
                <BookingDetail
                  show={this.props.bookingDetail.isShowing}
                  closeBookingDialog={this.closeBookingDialog}
                  data={this.props.bookingDetail.data}
                  router={this.props.router}
                  isTransportBooking
                />
              }
              {this.props.newBooking.bookingFormShow ? (
                isShuttleNewBooking ? (
                  <NewBooking
                    onClose={() => {
                      this.props.closeNewBookingRequest();
                    }}
                    isShuttle
                    />
                ) : (
                  <NewBooking
                    onClose={() => {
                      this.props.closeNewBookingRequest();
                    }}
                    isShuttle={false}
                    location={this.props.location}
                    router={router}
                    isTransportBooking
                  />
                )
              ) : (
                ''
              )}
              {this.props.intercityBooking.isShow ? (
                <NewIntercityBooking
                  onClose={() => {
                    this.props.closeNewBookingRequest();
                  }}
                />
              ) : (
                ''
              )}
              {this.props.deliveryBooking.isShow ? (
                <NewDeliveryBooking
                  onClose={() => {
                    this.props.deliveryAction.closeDeliveryBookingForm();
                  }}
                />
              ) : (
                ""
              )}

              {this.props.shuttleBooking.isShow && (
                <NewBooking
                  onClose={() => {
                    this.props.shuttleAction.closeShuttleBookingForm();
                  }}
                  isShuttle
                />
              )}
              <div
                className="fill-height"
                style={{
                  display: 'block',
                  // this.props.newBooking.bookingFormShow
                  //   ? "none"
                  //   : "block"
                }}
              >
                <AppNotifArea
                  ref={(appNotifArea) => {
                    if (appNotifArea)
                      this.appNotifArea = appNotifArea.getWrappedInstance();
                  }}
                  location={this.props.location}
                  mobileHambergerClick={this.mobileHambergerClick}
                  sidebarCollapsedClickHandle={this.sidebarCollapsedClickHandle}
                  changeLanguage={this.languageSelect}
                  handleLogout={this.handleLogout}
                  sideBarmenuClick={this.sideBarmenuClick}
                  handleOpenDetailPage={this.handleOpenDetailPage}
                  router={this.props.router}
                />
                {isValidPages ? (
                  isShowSubmenu ? (
                    <div
                      className="fill"
                      style={{
                        visibility:
                          this.props.newBooking.bookingFormShow ||
                            this.props.intercityBooking.isShow ||
                            this.props.shuttleBooking.isShow ||
                            this.props.deliveryBooking.isShow
                            ? 'hidden'
                            : 'visible',
                        opacity:
                          this.props.newBooking.bookingFormShow ||
                            this.props.intercityBooking.isShow ||
                            this.props.shuttleBooking.isShow ||
                            this.props.deliveryBooking.isShow
                            ? 0
                            : 1,
                      }}
                    >
                      <Sidebar
                        sub={true}
                        collapedClickHandle={this.sidebarCollapsedClickHandle}
                        menuList={this.props.menuHandle.selectedMainMenu.sub}
                        bookingFormShow={this.props.newBooking.bookingFormShow}
                        onMenuClick={this.sideBarmenuClick}
                        isMobileShow={this.state.isShowMobileSideBar}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      />
                      <div className="content-with-submenu main-content">
                        {this.CheckAccessablePermission(
                          this.props.menuHandle.selectedMainMenu
                        ) ? (
                          this.props.children
                        ) : (
                          <ErrorPage />
                        )}
                      </div>
                    </div>
                  ) : (
                    !this.props.newBooking.bookingFormShow &&
                    !this.props.intercityBooking.isShow &&
                    !this.props.shuttleBooking.isShow &&
                    !this.props.deliveryBooking.isShow && (
                      <div
                        className="main-content fill"
                        style={{
                          display: 'block',
                        }}
                      >
                        {this.props.children}
                      </div>
                    )
                  )
                ) : (
                  <ErrorPage errorType="AccessDenied" />
                )}
              </div>
            </div>
          </div>
          <LoadingSpiner show={this.props.loadingBar.isShowSpiner} />
        </HotKeys>
      </React.Fragment>
    ) : (
      <div className="cssload-thecube">
        <div className="pin" />
        <div className="pulse" />
      </div>
    );
  }
}

App.propTypes = {};

App.contextTypes = {
  router: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
};

App.childContextTypes = {
  notification: PropTypes.func,
  currenLanguage: PropTypes.object,
  user: PropTypes.object,
  socket: PropTypes.object,
  reloadCueAnhMap: PropTypes.func,
};

function mapStateToProps(state) {
  const {
    menuHandle,
    i18n,
    auth,
    newBooking,
    loadingBar,
    notification,
    intercityBooking,
    shuttleBooking,
    importBookings,
    deliveryBooking,
  } = state;
  if (menuHandle) {
    return {
      sidebarCollapsed: menuHandle.sidebarCollapsed,
      menuHandle: menuHandle,
      pagetitle: menuHandle.pagetitle,
      language: i18n,
      newbookingFormShowed: menuHandle.newbookingFormShowed,
      defaultPw: auth.user && auth.user.defaultPw,
      auth: auth,
      selectedFleet: auth.selectedFleet,
      newBooking: newBooking,
      intercityBooking: intercityBooking,
      shuttleBooking: shuttleBooking,
      deliveryBooking: deliveryBooking,
      importBookings: importBookings,
      loadingBar: loadingBar,
      notis: notification,
      bookingDetail: state.bookingDetail,
      callcenterSupport: state.callcenterSupport,
    };
  } else {
    return {
      sidebarCollapsed: false,
      menuHandle: {},
      pagetitle: 'Home',
      language: 'en-US',
      selectedFleet: auth.selectedFleet,
    };
  }
}

function mapDispatchToProps(dispatch) {
  return {
    menuActions: bindActionCreators(menuActions, dispatch),
    setLanguage: (lang) => {
      localStorage.setItem('language', lang);
      dispatch(setLocale(lang));
    },
    loadTranslations: (data) => dispatch(loadTranslations(data)),
    logout: () => dispatch(logout()),
    loadUserFleet: (options) => dispatch(loadUserFleet(options)),
    commonActions: bindActionCreators(commonActions, dispatch),
    socketActions: bindActionCreators(socketActions, dispatch),
    intercityBookingActions: bindActionCreators(
      intercityBookingActions,
      dispatch
    ),
    shuttleAction: bindActionCreators(shuttleAction, dispatch),
    deliveryAction: bindActionCreators(deliveryAction, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
    newBookingRequest: () => dispatch(newBookingRequest()),
    closeNewBookingRequest: () => dispatch(closeNewBookingForm()),
    resetDataNewBook: () => dispatch(resetDataNewBook()),
    mapActions: bindActionCreators(mapActions, dispatch),
    cueActions: bindActionCreators(cueActions, dispatch),
    bookingDetailActions: bindActionCreators(bookingDetailActions, dispatch),
    reportActions: bindActionCreators(reportActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    loadingActions: bindActionCreators(loadingActions, dispatch),
    reservationActions: bindActionCreators(reservationActions, dispatch),
    paymentMethodActions: bindActionCreators(paymentMethodActions, dispatch),
    callCenterSupportActions: bindActionCreators(
      callCenterSupportActions,
      dispatch
    ),
    newbookingActions: bindActionCreators(newbookingActions, dispatch),
    bookingActions: bindActionCreators(bookingActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
