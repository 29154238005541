import React, { Component, useEffect, useState } from 'react';
import { FormGroup, FormControl } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import FormGroupTitle from './../../formGroupTitile/FormGroupTitle';
import CcCheckbox from './../../ccCheckbox/CcCheckbox';
import { calculatorFleetORAdditionalService } from '../../../utils/commonFunctions';
import { FEE_TYPE, STATUS_FLEET_SERVICE } from '../../../constants/commondata';
import OverlayCC from '../../OverlayCC';

var currencyFormatter = require('currency-formatter');

const ServiceType = {
  Optional: 'Optional',
  Compulsory: 'Compulsory',
};

// only use for booking form
class ExtraServiceInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showServices: false,
      selectedIndex: 0,
      lightboxIsOpen: false,
    };
  }

  render() {
    const {
      newBooking,
      data,
      etaFare,
      locationPickUp,
      cartypeSelected,
      selected,
      onSelectAdditionalService,
      disabled,
    } = this.props;

    const { showServices } = this.state;
    const showAdditional =
      locationPickUp &&
      cartypeSelected &&
      (newBooking?.locationService?.fleetServices ||
        newBooking?.locationService?.additionalServicesActive) &&
      data?.extraServices?.length > 0;
    const extraFee = calculatorFleetORAdditionalService({
      services: data.extraServices,
      subTotal: etaFare?.subTotal,
      bookCompleted: data,
      currencyISO: etaFare?.currencyISO
    });

    return (
      <div>
        {showAdditional && (
          <FormGroupTitle>
            {data.isNewSettingAdditionFee ? (
              <Translate value="report.result.bookingDetails.fleetServiceFee" />
            ) : (
              <Translate value="newbooking.Addl_Services" />
            )}
          </FormGroupTitle>
        )}

        {showAdditional && (
          <GroupFleetServiceSelected
            data={data}
            extraFee={extraFee}
            services={selected}
            onSelectService={onSelectAdditionalService}
            disabled={disabled}
            currencyISO={etaFare?.currencyISO}
          />
        )}
      </div>
    );
  }
}



// can use for create booking/booking detail and completed with payment
export const GroupFleetServiceSelected = ({
  extraFee,
  services = [],
  onSelectService,
  disabled = false,
  currencyISO,
  data,
  isCompletedPayment,
}) => {
  const [showOverlay, setShowOverlay] = useState(false);

  const getListServerByStatusBooking = () => {
    if(STATUS_FLEET_SERVICE.includes(data.status)) {
      // when booking completed when only show service active
      return services.filter(sv => sv.active) || []
    }
    return services
  }

  const getInfoService = (service) => {
    let fee = 0,
      name = service.serviceName,
      active = false,
      type = service.serviceType,
      isTypePercent =
        service?.serviceFeeType === FEE_TYPE.Percent ||
        service?.infoService?.serviceFeeType === FEE_TYPE.Percent;

    // if is booking completed then get data saved in booking
    if(STATUS_FLEET_SERVICE.includes(data.status)) {
      return {
        isTypePercent: false,
        fee: service.fee,
        name: service.serviceName,
        active: service.active
      }
    }

    if (isCompletedPayment) {
      // show on form complete with payment
      fee = isTypePercent
        ? service.serviceFeePercent
        : _.get(
            service?.serviceFeeByCurrencies?.find(
              (c) => c.currencyISO == currencyISO
            ),
            'value',
            0
          );
      active = service.active;
    } else {
      // show on form new or detail booking
      fee = isTypePercent
        ? service.serviceFeePercent
        : service?.serviceFeeByCurrencies?.find(
            (ob) => ob.currencyISO === currencyISO
          )?.value || 0;
      active = service.active;
    }
    return {
      name,
      fee,
      active,
      isTypePercent,
      type
    };
  };

  const checkAdditionalServiceSelected = (id) =>
    !!_.find(data?.request?.services, (o) => o.active && o.serviceId === id);

  return (
    <FormGroup className="additional-service-form">
      <OverlayCC
        showOverlay={showOverlay}
        setShowOverlay={setShowOverlay}
        childrenAction={
          <FormControl
            readOnly
            value={currencyFormatter.format(extraFee, {
              code: currencyISO,
            })}
            className={
              'form-custom additional-service-input' + (disabled ? ' disabled' : '')
            }
          />
        }
        childrenOverlay={
          <div className={'additional-services-item'}>
            {getListServerByStatusBooking()?.map((obj) => {
              const { active, fee, name, isTypePercent, type } = getInfoService(obj);
              const disabledSelect = type == ServiceType.Compulsory || disabled

              return (
                <div
                  key={obj?._id}
                  className={`item${disabledSelect ? ' disable' : ''}`}
                  onClick={() => {
                    if (type == ServiceType.Compulsory || disabled) return;
                    onSelectService(
                      isCompletedPayment ? obj : obj?.infoService?._id
                    );
                  }}
                >
                  <div className="overlay-div" />
                  <CcCheckbox
                    checked={type == ServiceType.Compulsory || active}
                    disabled={type == ServiceType.Compulsory || disabled}
                    onChange={() => {
                      return;
                    }}
                    readOnly
                  />
                  <div className={'item-name text-ellipsis'}>{name}</div>
                  <div className={'item-value'}>
                    {isTypePercent
                      ? `${fee}%`
                      : currencyFormatter.format(fee, {
                          code: currencyISO,
                        })}
                  </div>
                </div>
              );
            })}
          </div>
        }
      />
    </FormGroup>
  );
};

export default ExtraServiceInfo;
