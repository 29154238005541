import {
  payoutSingleBookingApi,
  payoutSingleBookingSupplierApi,
  payoutMultipleBookingApi,
} from '../../constants/ApiConfigs';
import {
  callApi,
  UrlBuilder,
  UrlBuilderWithParams,
} from '../../utils/apiUtils';

export function payoutSingleBooking(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(payoutSingleBookingApi, config, null, null, null);
}

export function payoutSingleBookingSupplier(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(payoutSingleBookingSupplierApi, config, null, null, null);
}

export function payoutMultipleBooking(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(payoutMultipleBookingApi, config, null, null, null);
}
