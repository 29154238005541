import {
  TRAVELER_TYPE,
  BOOK_FROM_CC_MENU_ID,
  BOOK_FROM_CC_CPT_TEXT_AREA_ROW,
  BOOK_FROM_CC_CPT_NOTES_ROW,
  BOOK_FROM_CC_CPT_CHECKBOX_AMOUNT_ROW,
  CPT_NOTES_ROW,
  CPT_TEXT_AREA_ROW,
  CPT_CHECKBOX_AMOUNT_ROW,
  bookFrom,
  thirdPartyIntegration,
} from './../../../constants/commondata';
import _, { reject } from 'lodash';
import moment from 'moment';
import { CCLiteCommonFunc, checkForceRateType, getCompanyIdForETA, parserIntNumberInString } from '../../../utils/commonFunctions';
import { QQUltis } from '../../qqMap';
import { resolve } from 'bluebird';
import { data } from 'jquery';
import uuidv4 from 'uuid/v4';
import { getAllPointNotEmpty } from './bookingInfo';

function buildParamsPayoutBooking(params = {}) {
  const { isNewBooking = false } = params;
  const { commonData = {} } = this.props;
  const paramsQuery = {
    fleetId: this.props?.auth?.selectedFleet?.fleetId,
  };

  const data = this.state.data || {};

  if (data.bookId) paramsQuery.bookId = data.bookId;

  if (this.state?.supplierCompanies?.length === 1) {
    const supplier = commonData?.suppliers?.find(sup => sup._id === this.state?.supplierCompanies[0]);
    if (supplier) paramsQuery.supplierId = supplier._id;
  }

  const supplierIdDelivery = getCompanyIdForETA(this.state.driverCompany, this.state.driver)
  if (supplierIdDelivery) paramsQuery.supplierId = supplierIdDelivery;


  let isFareEdited = data?.request?.estimate?.isFareEdited;
  let etaFare = data?.request?.estimate?.fare;
  let originFare = data?.request?.estimate?.originFare;
  const driverId = _.get(this.state.driver, 'driver._id' , '') || _.get(this.state.data, 'drvInfo.userId', '');
  
  if (isNewBooking) {
    isFareEdited = this.state.isFareEdited;
    etaFare = this.state.etaFare;
    originFare = this.state.originFare;
  }

  if (driverId) paramsQuery.driverId = driverId;

  if ( isFareEdited) {
    const differenceFare = JSON.parse(JSON.stringify(etaFare));
    delete differenceFare.totalPayout;

    paramsQuery.fare = differenceFare;
  }
  return paramsQuery;
}

export function calPayoutSingleBookingDetail(paramsQueryPayout) {
  paramsQueryPayout = paramsQueryPayout ? paramsQueryPayout : buildParamsPayoutBooking.call(this, { isNewBooking: false });
  
  return this.props.bookingActions.payoutSingleBooking(paramsQueryPayout).then((res) => {
    if (res && res.ok) {
      if (res.res.bookId) {
        let newFare = { ...this.state.data?.request?.estimate?.fare }
        if (this.state.data?.request?.estimate?.fare?.supplierEarningType === 'default') {
          newFare = {...this.state.data.request?.estimate?.fare, ...res.res};
        } else {
          newFare.feePayToSupplier = res.res.transferData?.feePayToSupplier || 0;
        }
        
        this.setState({
          data: {
            ...this.state.data,
            request: {
              ...this.state.data.request,
              estimate: {
                ...(this.state?.data?.request?.estimate || {}),
                fare: newFare,
              },
            },
          },
        });
      }
    }
  }) 
}

function bookingDetailsEtaFareMultiCarCalculator(callback, actualFare = 0) {
  
  if(this.state.isSupplierPermission) return;

  const paramsQueryPayout = buildParamsPayoutBooking.call(this, { isNewBooking: false });

  if (
    this.state.data?.request?.estimate?.isFareEdited
  ) {
      calPayoutSingleBookingDetail.call(this, paramsQueryPayout)
  }

  if (
    this.state.data?.request?.estimate?.isFareEdited ||
    (this.state.data?.request?.estimate?.fare?.supplierEarningType &&
      this.state.data?.request?.estimate?.fare?.supplierEarningType != 'default')
  )
    return;  
  if (this.state.showConfirmComplete) {
    actualFare = 1;
  }
  if (
    !this.state.locationPickUp ||
    !this.state.locationPickUp.address ||
    this.state.locationPickUp.address.length == 0
  ) {
    this.state.data.request.estimate.fare = null;
    this.setState({
      data: this.state.data,
      disableCompletePaymentNextClick: false,
      disabledCompleteWithoutServiceButton: false,
    });
    return;
  }

  if (!this.state.cartypeSelected && !this.state.data.pricingType) {
    this.state.data.request.estimate.fare = null;
    this.setState({
      data: this.state.data,
      disableCompletePaymentNextClick: false,
      disabledCompleteWithoutServiceButton: false,
    });
    return;
  }
  if (!this.props.newBooking.locationService) {
    return;
  }

  let { locationPickUp } = this.state || {};
  let pickupGeo = [];
  if (locationPickUp.geo) {
    pickupGeo = locationPickUp.geo;
  } else {
    pickupGeo = [locationPickUp.lng, locationPickUp.lat];
  }
  const jobType = _.get(this.props.bookingDetail, 'data.jobType', '');
  let options = {
    fleetId:
      this.state.isHydraBooking || this.state.farmIn
        ? this.props.auth.selectedFleet.fleetId
        : this.state.data.request.psgFleetId,
    bookFrom: this.state.data.bookFrom ? this.state.data.bookFrom : 'CC',
    bookId: this.state.data.bookId,
    zipCodeFrom: this.state.locationPickUp.zipCode,
    bookId: this.state.data.bookId,
    pickup: pickupGeo,
    pickupTime:
      this.state.data.request.pickUpTime == 'Now'
        ? 'Now'
        : moment(this.state.data.request.pickUpTime).format('YYYY-MM-DD HH:mm'),
    city: this.state.locationPickUp.city,
    timezone: this.state.locationPickUp.timezone,
    promoCode: '',
    actualFare,
    zipCodeTo: '',
    bookType: 0,
    typeRate: 0,
    tip: this.state.data.request.tip
      ? parseInt(this.state.data.request.tip)
      : 0,
    phone:
      this.state.data.psgInfo.phone == 'No Phone'
        ? ''
        : this.state.data.psgInfo.phone,
    userId: '',
    driverId: _.get(this.state.driver, 'driver._id' , '') || _.get(this.state.data, 'drvInfo.userId', ''),
    meetDriver: -1,
    pricingType: this.state.isHydraBooking ? 1 : 0,
    rv: _.get(this.state.data, 'psgInfo.rv'),
    addOnPrice: this.state.addOnPrice || 0,
    paymentMethod: this.state.data.request.paymentType,
    serviceType: jobType === 'shuttle' ? 'shuttle' : 'transport',
    jobType: jobType,
    zoneId: this.state?.data?.request?.pickup?.zoneId || ''
  };

  if(this.state.is3rdBooking) {
    options.thirdPartyAmount =  this.state?.data?.request?.estimate?.fare?.etaFare,
    options.currencyISO =  this.state?.data?.request?.estimate?.fare?.currencyISO
  }

  if (this.state.promo) {
    options.promoCode =
      this.state.promo.promotionCode || this.state.promo.promo;
  }

  if(this.state.supplierCompanies[0]) {
    options.companyId = this.state.supplierCompanies[0]
  }
  
  if (this.state.locationDestination) {
    let { locationDestination } = this.state || {};
    if (locationDestination.geo) {
      options.destination = locationDestination.geo;
    } else {
      options.destination = [
        this.state.locationDestination.lng,
        this.state.locationDestination.lat,
      ];
    }
    options.zipCodeTo = this.state.locationDestination.zipCode;
    options.extraDestination = getExtraDestinationEtaFare({
      puPoints: this.state.data?.puPoints,
      doPoints: this.state.data?.doPoints,
      roundTrip: this.state?.data?.roundTrip,
    });
  }

  if (this.state.cartypeSelected) {
    options.vehicleTypeId = this.state.cartypeSelected.vehicleType;
  }

  if (this.state.isHydraBooking) {
    let airport = this.props.newBooking.locationService.airport;
    if (airport && airport.length && !!airport[0]) {
      options.bookType = 1;
    }

    if (airport && airport.length && !!airport[1]) {
      options.bookType = 2;
    }
  } else {
    if (
      this.props.newBooking.locationService &&
      this.props.newBooking.locationService.additionalService &&
      this.props.newBooking.locationService.additionalService.fromAirport &&
      this.props.newBooking.locationService.additionalService.fromAirport
        .isActive &&
      !_.isEmpty(this.props.newBooking.locationService.fromAirport)
    ) {
      options.bookType = 1;
    }

    if (
      options.bookType !== 1 &&
      this.props.newBooking.locationService &&
      this.props.newBooking.locationService.additionalService &&
      this.props.newBooking.locationService.additionalService.toAirportNew &&
      this.props.newBooking.locationService.additionalService.toAirportNew
        .isActive &&
      !_.isEmpty(this.props.newBooking.locationService.toAirport)
    ) {
      options.bookType = 2;
    }
  }

  if (
    this.state.data.duration &&
    this.props.newBooking.locationService.packagesRate
  ) {
    options.typeRate = 1;
    if (!(options.bookType == 1 || options.bookType == 2)) {
      options.bookType = 3;
    }
    options.packageRateId = this.state.package
      ? this.state.package._id
      : this.state.data.request.packageRateId;
  }
  if (
    this.state.data.roundTrip ||
    (this.state.data.request.type == 4 &&
      this.state.data.status == 'droppedOff')
  ) {
    options.typeRate = 2;
    if (!(options.bookType == 1 || options.bookType == 2)) {
      options.bookType = 4;
    }
  }

  if (
    options.bookType == 4 &&
    !['pre', 'action', 'confirmed'].includes(this.state.data.status) &&
    this.state.data.request.origin &&
    this.state.data.request.origin.destination &&
    this.state.data.request.origin.destination.geo
  ) {
    options.destination = this.state.data.request.origin.destination.geo;
  }

  if (
    (options.bookType == 1 || options.bookType == 2) &&
    this.state.data.request.moreInfo.flightInfo.type == -1
  ) {
    this.state.data.request.moreInfo.flightInfo.type = 0;
  }

  if (_.get(this.state.data, 'mDispatcherInfo.userId')) {
    options.userId = _.get(this.state.data, 'mDispatcherInfo.userId');
  } else {
    options.userId = _.get(this.state, 'customer.userId');
  }

  if (!options.userId) {
    options.phone =
      this.state.data.psgInfo.phone == 'No Phone'
        ? ''
        : this.state.data.psgInfo.phone;
  }

  if (
    (this.state.data.travelerType == 1 || this.state.data.travelerType == 2) &&
    this.state.company
  ) {
    options.corporateId = this.state.company._id;
  }

  if (this.state.data.duration && this.state.package) {
    options.packageRateId = this.state.package._id;
  }

  if (
    this.props.newBooking.locationService.additionalService &&
    this.props.newBooking.locationService.additionalService.fromAirport &&
    this.props.newBooking.locationService.additionalService.fromAirport
      .flightInfo &&
    this.props.newBooking.locationService.additionalService.fromAirport
      .isActive &&
    this.props.newBooking.locationService.fromAirport &&
    this.props.newBooking.locationService.fromAirport._id
  ) {
    options.meetDriver = parseInt(_.get(this.state.data, 'request.moreInfo.flightInfo.type', 0))
      ? 1
      : 0;
  }

  if (this.state.locationPickUp && this.state.disDur) {
    options.distance = this.state.disDur.distance.value;
    // SL-30651 Support "Calculate fare for multiple point" feature
    options.duration = this.state.disDur.duration.value;
  }
  if (
    this.state.data.extraServices &&
    this.state.data.extraServices.length > 0
  ) {
    options.services = this.state.data.extraServices
      .filter((obj) => obj.active)
      .map((obj) => obj.serviceId);
  }
  // this.updateExtraServiceForEta({ res: this.props.newBooking.locationService });

  options.editFare = this.state.editFare;
  let vehicleAvailable = _.get(this.props.newBooking, 'locationService.vehicleType', [])
  if(this.state.isHydraBooking) {
    vehicleAvailable =_.get(this.props.newBooking, 'locationService.affiliateService.vehicleTypeHydraSplited', [])
  }
  options.vehicleSelecting = this.state.cartypeSelected // use for etaFareReducer.js
  if (vehicleAvailable && vehicleAvailable.length > 0) {
    if(this.state.showAllVehilceBtn) {
      options.rateDetails = [{
        vehicleTypeId: this.state.cartypeSelected?.vehicleType,
        pickupTime: options.pickupTime,
        typeRate: options.typeRate,
        bookType: options.bookType,
        distance: options.distance,
        seat: this.state.data?.request?.paxNumber,
        duration: options.duration,
        services: options.services,
        packageRateId: options.packageRateId,
        extraDestination: options.extraDestination,
        editFare: this.state.editFare,
      }]
    } else {
      options.rateDetails = vehicleAvailable.map((vhc) => {
        const vhcResult = {
          vehicleTypeId: vhc.vehicleType,
          pickupTime: options.pickupTime,
          typeRate: options.typeRate,
          bookType: options.bookType,
          distance: options.distance,
          duration: options.duration,
          services: options.services,
          packageRateId: options.packageRateId,
          seat: this.state.data?.request?.paxNumber,
          extraDestination: options.extraDestination,
        }
        if (vhc.vehicleType === this.state.cartypeSelected?.vehicleType) {
          vhcResult.editFare = this.state.editFare
        }
        return vhcResult;
      })
    }
  }
  // Nếu là farm in thì không gọi ETA lại, lấy nguyên giá trị trong booking
  if(this.state.farmIn) {
    const fareDefault = _.get(this.state.data, 'request.estimate.fare', {})
    fareDefault.vehicleType = this.state.data.request.vehicleTypeRequest
    return this.handleResultETA(fareDefault, [fareDefault], options, callback)
  }
  options.sessionKey = uuidv4()
  if(this.state.isHydraBooking) {
    return etaFareMultiCarHydra(
      vehicleAvailable, 
      options,
      this.state.cartypeSelected,
      this.props.etaFareActions.etaFareMultiCarHydra,
      this.props.etaFareActions.etaFareMultiCarHydraSuccess
    ).then(data => {
      if (data && data.length > 0) {
        const { cartypeSelected = {} } = this.state
        const fareCarSelecting = data.find(vhc =>
          vhc && 
          vhc.vehicleType === cartypeSelected.vehicleType &&
          vhc.vehicleTypeLocal === cartypeSelected.providerOfVhc.vehicleType &&
          vhc.localFleetId === cartypeSelected.providerOfVhc.fleetId
        )
        this.handleResultETA(fareCarSelecting, data, options, callback)
      } else {
        this.setState({ 
          etaFare: null, 
          options: options ,
          etaFareMultiCar: [],
          sessionKeyETAFare: ''
        });
      }
    })
    
  } else {
    return this.props.etaFareActions.etaFareMultiCar(options).then((response) => {
      if (response?.length > 0) {
        const fareCarSelecting = response.find(vhc => 
          vhc &&
          vhc.vehicleType === this.state.cartypeSelected.vehicleType
        )
        this.handleResultETA(fareCarSelecting, response, options, callback)
      } else {
        this.setState({
          disableCompletePaymentNextClick: false,
          disabledCompleteWithoutServiceButton: false,
        });
      }
    });
  }
}

async function etaFareMultiCarHydra (
  carList = [], 
  options = {}, 
  cartypeSelected = {},
  etaFareMultiCarHydra = () => {},
  etaFareMultiCarHydraSuccess = () => {}
) {
  const carGroup = _.groupBy(carList, (car) => car.providerOfVhc.fleetId)
  let promiseList = []
  if(!_.isEmpty(carGroup)) {
    for (const key in carGroup) {
      const vehicleAvailable =  carGroup[key]
      if (vehicleAvailable && vehicleAvailable.length > 0) {
        let rateDetails = []
        vehicleAvailable.map((vhc) => {
          if(!_.some(rateDetails, (ob) => ob.vehicleTypeId === vhc.vehicleType)) {
            rateDetails.push({
              vehicleTypeId: vhc.vehicleType,
              pickupTime: options.pickupTime,
              typeRate: options.typeRate,
              bookType: options.bookType,
              distance: options.distance,
              duration: options.duration,
              services: options.services  
            })
          }
        })
        const optionCar = {
          ...options,
          fleetId: key,
          rateDetails: rateDetails
        }
        promiseList.push(new Promise((resolve, reject) => {
            etaFareMultiCarHydra(optionCar)
            .then(data => {
              resolve(data)
            })
          })
        )
      }
    }
    return Promise.all(promiseList)
      .then(results => {
        let etaHydraVehicle = []
        results.forEach(element => {
          const data = _.get(element, 'res.response', null)
          if(data) etaHydraVehicle = [...etaHydraVehicle, ...data]
        });
        etaFareMultiCarHydraSuccess(etaHydraVehicle, cartypeSelected)
        return etaHydraVehicle
      })
  }
}

function newBookingEtaFareMultiCarCalculator() {
  if (!this.state.locationPickUp) {
    this.setState({ etaFare: null });
    return;
  }

  // Đã edit fare thì không ETA lại.
  if(this.state.isFareEdited && this.state.etaFare?.supplierEarningType !== 'default') return;

  // const isPayoutTBD = this.state.supplierCompanies?.length != 1 
  //   && 
  //   !(_.get(this.state.driver, 'driver._id' , '') 
  //     || _.get(this.state.data, 'drvInfo.userId',  '')) 
  //   && this.state.etaFare?.supplierEarningType === 'default';
  
  // if (isPayoutTBD) {
  //   return;
  // }

  
  if (!this.state.cartypeSelected) {
    this.setState({ etaFare: null });
    return;
  }
  if (!this.props.newBooking.locationService) {
    return;
  }

  let { locationPickUp } = this.state || {};
  let pickupGeo = [];
  if (locationPickUp.geo) {
    pickupGeo = locationPickUp.geo;
  } else {
    pickupGeo = [locationPickUp.lng, locationPickUp.lat];
  }
  const { 
    isHydraBooking,
    supplierVehicle 
  } = this.state
  const options = {
    fleetId: isHydraBooking ? supplierVehicle : this.props.auth.selectedFleet.fleetId,
    bookFrom: 'CC',
    zipCodeFrom: this.state.locationPickUp.zipCode,
    pickup: pickupGeo,
    pickupTime:
      this.state.data.time == 'Now'
        ? 'Now'
        : moment(this.state.data.time).format('YYYY-MM-DD HH:mm'),
    city: this.state.locationPickUp.city,
    timezone: this.state.locationPickUp.timezone,
    actualFare: 0,
    zipCodeTo: '',
    promoCode: '',
    bookType: 0,
    typeRate: 0,
    tip: this.state.data.tips ? parseInt(this.state.data.tips) : 0,
    phone: this.state.data.phone,
    pricingType: isHydraBooking
        ? 1
        : 0,
    userId: '',
    driverId: _.get(this.state.driver, 'driver._id' , '') || _.get(this.state.data, 'drvInfo.userId', ''),
    meetDriver: -1,
    addOnPrice: this.state.addOnPrice || 0,
    paymentMethod: this.state.data.paymentMethod,
    serviceType: this.props.isShuttle ? 'shuttle' : 'transport',
    jobType: this.props.isShuttle ? 'shuttle' : 'rideHailing',
  };
  if (this.state.promo) {
    options.promoCode = this.state.promo.promotionCode;
  }
  if (this.state.locationDestination) {
    let { locationDestination } = this.state || {};
    let destinationGeo = [];
    if (locationDestination.geo) {
      destinationGeo = locationDestination.geo;
    } else {
      destinationGeo = [locationDestination.lng, locationDestination.lat];
    }
    options.destination = destinationGeo;
    options.zipCodeTo = this.state.locationDestination.zipCode;
    options.extraDestination = getExtraDestinationEtaFare({
      puPoints: this.state.puPoints, 
      doPoints: this.state.doPoints, 
      roundTrip: this.state.data.roundTrip
    });
  }

  options.companyId = getCompanyIdForETA(this.state.driverCompany, this.state.driver)
  if(!options.companyId && this.state.supplierCompanies?.length === 1) {
    options.companyId = this.state.supplierCompanies[0]
  }

  if (isHydraBooking) {
    let airport = _.get(this.props.newBooking, 'locationService.affiliateService.airport', []);
    if (airport && airport.length && !!airport[0]) {
      options.bookType = 1;
    }

    if (airport && airport.length && !!airport[1]) {
      options.bookType = 2;
    }
  } else {
    if (
      this.props.newBooking.locationService &&
      this.props.newBooking.locationService.additionalService &&
      this.props.newBooking.locationService.additionalService.fromAirport &&
      this.props.newBooking.locationService.additionalService.fromAirport
        .isActive &&
      !_.isEmpty(this.props.newBooking.locationService.fromAirport)
    ) {
      options.bookType = 1;
    }

    if (
      options.bookType !== 1 &&
      this.props.newBooking.locationService &&
      this.props.newBooking.locationService.additionalService &&
      this.props.newBooking.locationService.additionalService.toAirportNew &&
      this.props.newBooking.locationService.additionalService.toAirportNew
        .isActive &&
      !_.isEmpty(this.props.newBooking.locationService.toAirport)
    ) {
      options.bookType = 2;
    }
  }

  if (
    this.state.data.duration &&
    this.props.newBooking.locationService &&
    this.props.newBooking.locationService.packagesRate
  ) {
    options.typeRate = 1;
    if (!(options.bookType == 1 || options.bookType == 2)) {
      options.bookType = 3;
    }
  }
  if (this.state.data.roundTrip) {
    options.typeRate = 2;
    if (!(options.bookType == 1 || options.bookType == 2)) {
      options.bookType = 4;
    }
  }

  if (this.state.customer && this.state.customer.userId) {
    options.userId = this.state.customer.userId;
  }

  if (
    (this.state.data.traveler_type == TRAVELER_TYPE.Corporate ||
      this.state.data.traveler_type == TRAVELER_TYPE.Airline) &&
    this.state.company
  ) {
    options.corporateId = this.state.company._id;
  }

  if (this.state.data.traveler_type == 2) {
    let airline = this.state.company || null;
    if (!airline) {
      airline =
        this.props.corporateCompany && this.props.corporateCompany.length > 0
          ? this.props.corporateCompany[0]
          : null;
    }
    options.corporateId = airline._id;
  }

  if (this.state.data.duration && this.state.package) {
    options.packageRateId = this.state.package._id;
  }

  if (options.bookType == 1) {
    options.meetDriver = this.state.data.meetDriver
      ? parseInt(this.state.data.meetDriver)
      : 0;
  }

  if (this.state.dateMode == 'recurring') {
    options.recurring = 'recurring';
  } else {
    options.recurring = 'single';
  }

  if (this.state.extraDestination && this.state.extraDestination.lat) {
    options.typeRate = 0;
  }

  if (this.state.locationPickUp && this.state.disDur) {
    options.distance = this.state.disDur.distance.value;
    // SL-30651 Support "Calculate fare for multiple point" feature
    options.duration = this.state.disDur.duration.value;
  }
  if (
    this.state.data.extraServices &&
    this.state.data.extraServices.length > 0
  ) {
    options.services = this.state.data.extraServices
      .filter((obj) => obj.active)
      .map((obj) => obj.serviceId);
  }
  const vehicleAvailable = _.get(this.props.newBooking, 'locationService.vehicleType', [])
  options.sessionKey = uuidv4()
  // if hydra booking
  if(isHydraBooking) {
    const vehicleAvailable =_.get(this.props.newBooking, 'locationService.affiliateService.vehicleTypeHydraSplited', [])
    etaFareMultiCarHydra(
      vehicleAvailable, 
      options,
      this.state.cartypeSelected,
      this.props.etaFareActions.etaFareMultiCarHydra,
      this.props.etaFareActions.etaFareMultiCarHydraSuccess
    ).then(data => {
      if (data && data.length > 0) {
        const fareCarSelecting = data.find(vhc =>
          vhc && 
          vhc.vehicleType === this.state.cartypeSelected.vehicleType &&
          vhc.vehicleTypeLocal === this.state.cartypeSelected.providerOfVhc.vehicleType &&
          vhc.localFleetId === this.state.cartypeSelected.providerOfVhc.fleetId
        )
        const isRoundTrip =
          fareCarSelecting &&
          fareCarSelecting.normalFare == false &&
          (fareCarSelecting.route || '').trim().length > 0;
        let isReCal = false;
  
        if (!isRoundTrip && this.state.data.roundTrip) {
          this.state.data.roundTrip = false;
          // isReCal = true;
        }
  
        if (this.state.data.duration) {
          // set default for package
          if (
            fareCarSelecting.packages &&
            fareCarSelecting.packages.length > 0
          ) {
            let packageDefault = fareCarSelecting.packages.sort(function (a, b) {
              // sort for the best price
              return a.value - b.value;
            })[0];
            if (!this.state.data.packageRateId && packageDefault) {
              this.state.data.packageRateId = packageDefault._id;
              this.state.package = packageDefault;
              // isReCal = true;
            }
          }
        }
  
        if (isReCal) {
          this.setState({ 
            etaFare: fareCarSelecting, 
            etaFareMultiCar: data || [],
            options: options 
          },
            this.etaFareCalculator
          );
        } else {
          this.setState({ 
            etaFare: fareCarSelecting, 
            etaFareMultiCar: data || [],
            options: options, 
        });
        }
      } else {
        this.setState({ 
          etaFare: null, 
          options: options ,
          etaFareMultiCar: []
        });
      }
    })
    
  } else {
    const paramsQueryPayout = buildParamsPayoutBooking.call(this, { isNewBooking: true });
  
    options.vehicleSelecting = this.state.cartypeSelected // use for etaFareRebuder.js
    if (vehicleAvailable && vehicleAvailable.length > 0) {
      options.rateDetails = vehicleAvailable.map((vhc) => {
        const resultRateDetail = {
          vehicleTypeId: vhc.vehicleType,
          pickupTime: options.pickupTime,
          typeRate: options.typeRate,
          bookType: options.bookType,
          distance: options.distance,
          duration: options.duration,
          seat: this.state?.data?.paxNumber,
          services: options.services,
          extraDestination: options.extraDestination,
          packageRateId: options.packageRateId,
        }
        if (vhc.vehicleType === this.state.cartypeSelected.vehicleType) {
          resultRateDetail.editFare = paramsQueryPayout.fare
        }
        return resultRateDetail
      })
    }
    return this.props.etaFareActions.etaFareMultiCar(options).then((response) => {
      if (response?.length > 0) {
        let fareCarSelecting = response && response.find(vhc => 
          vhc &&
          vhc.vehicleType === this.state.cartypeSelected.vehicleType
        )

        fareCarSelecting = {...fareCarSelecting, ...paramsQueryPayout.fare}

        const isRoundTrip =
          fareCarSelecting &&
          fareCarSelecting.normalFare == false &&
          (fareCarSelecting.route || '').trim().length > 0;
        let isReCal = false;
  
        if (!isRoundTrip && this.state.data.roundTrip) {
          this.state.data.roundTrip = false;
          isReCal = true;
        }
  
        if (this.state.data.duration) {
          // set default for package
          if (
            fareCarSelecting &&
            fareCarSelecting.packages &&
            fareCarSelecting.packages.length > 0
          ) {
            let packageDefault = fareCarSelecting.packages.sort(function (a, b) {
              // sort for the best price
              return a.value - b.value;
            })[0];
            if (!this.state.data.packageRateId && packageDefault) {
              this.state.data.packageRateId = packageDefault._id;
              this.state.package = packageDefault;
              isReCal = true;
            }
          }
        }
  
        if (isReCal) {
          this.setState({ 
            etaFare: fareCarSelecting, 
            etaFareMultiCar: response || [],
            options: options,
            sessionKeyETAFare: options.sessionKey
          },
            this.etaFareCalculator
          );
        } else {
          this.setState({ 
            etaFare: fareCarSelecting, 
            etaFareMultiCar: response || [],
            options: options,
            sessionKeyETAFare: options.sessionKey
        });
        }
      } else {
        this.setState({ 
          etaFare: null, 
          options: options ,
          etaFareMultiCar: [],
          sessionKeyETAFare: ''
        });
      }
    });
  }
}

function getExtraDestinationFromDirections(routes = []) {
  let routesPuDoPoints = _.get(routes, 'routes[0].legs', []);
  let extraDestination = [];
  if (routesPuDoPoints && routesPuDoPoints.length > 1) {
    routesPuDoPoints.forEach((route, id) => {
      if (id < routesPuDoPoints.length - 1) {
        let end_location = route.end_location || {};
        let geo = [];
        if (_.isNumber(end_location.lng)) {
          geo = [end_location.lng, end_location.lat];
        } else {
          geo = [end_location.lng(), end_location.lat()];
        }
        extraDestination.push({
          geo: geo,
          duration: (route.duration && route.duration.value) || 0,
          distance: (route.distance && route.distance.value) || 0,
        });
      }
    });
  }
  return extraDestination;
}

export const resetService = (isReseted, extraServices = [], originFare) => {
  if (!extraServices || extraServices.length === 0) {
    return [];
  }
  
  if (!isReseted) {
    return extraServices;
  }
  
  return extraServices.map(item => {
    if (originFare?.services && !originFare?.services?.includes(item?.serviceId)) {
      item.active = false;
    }
    return item;
  });
}

function handleFinalServicesRequest(extraServices = [], isNewSettingAdditionFee, originFare, isFareEdited) {
  const keyService = isNewSettingAdditionFee ? 'fleetServices' : 'services';

  if (extraServices.length === 0) {
    return { serviceName: keyService, value: [] };
  }

  let finalExtraServices = extraServices.map((service) => ({
    ...service,
    ...service.infoService,
  }));

  if (isFareEdited && finalExtraServices?.length > 0) {
    const serviceIdsAfterEditFare = originFare?.services || [];
    finalExtraServices = finalExtraServices.map((service) => {
      return {
        ...service,
        fareAlreadyEdited: 
          !serviceIdsAfterEditFare.includes(service.serviceId)
          && service.active,
      };
    });
  }

  return {
    serviceName: keyService,
    value: finalExtraServices,
  };
}

async function getServiceAndCalculateLogicBookingDetail () {
  if (this.state.data.dispatch3rd) {
    this.state.isCompleteWithPaymentShow3rdBooking = false;
    this.props.bookingDetailActions
      .getRetryCompleteBooking({
        fleetId: this.props.auth.selectedFleet.fleetId,
        bookId: this.state.data.bookId,
      })
      .then((dataRes) => {
        if (dataRes && dataRes.ok) {
          if (dataRes.res.returnCode == 200) {
            this.setState({
              isCompleteWithPaymentShow3rdBooking: true,
              retryCompleteBookingInfo: dataRes.res.response,
            });
          }
        }
      });
  }

  if (this.state.data.psgInfo && this.state.data.psgInfo.userId) {
    this.props.customerActions
      .findOneCustomer({
        userId: this.state.data.psgInfo.userId,
        fleetId: this.props.auth.selectedFleet.fleetId,
      })
      .then((data) => {
        if (
          this.state.data.psgInfo.creditInfo &&
          !_.isEmpty(this.state.data.psgInfo.creditInfo) &&
          data.res
        ) {
          let psgCreditInfo = this.state.data.psgInfo.creditInfo;
          let existCard = data.res.credits.find((cre) => {
            return (
              (cre.localToken && cre.localToken == psgCreditInfo.localToken) ||
              (cre.crossToken && cre.crossToken == psgCreditInfo.crossToken)
            );
          });
          if (data.res.credits.length == 0 || !existCard) {
            // SL-17711 - Need to clear credit token when rebooking
            // data.res.credits.push(this.state.data.psgInfo.creditInfo);
          }

          this.setState({ customer: data.res });
        }
      });
  }
  if (
    this.state.data.request.promo &&
    this.state.data.request.promo.length > 0
  ) {
    // Initialize Promotion Data
    this.state.promo = {
      value: this.state.data.request.promoValue,
      promo: this.state.data.request.promo,
      maximumValue:
        this.state.data.request.promoInfo &&
        this.state.data.request.promoInfo.maximumValue
          ? this.state.data.request.promoInfo.maximumValue
          : {},
      keepMinFee:
        (this.state.data.request.promoInfo &&
          this.state.data.request.promoInfo.keepMinFee) ||
        false,
    };
  } else {
    this.state.promo = null;
  }
  if (
    (this.state.data.travelerType == TRAVELER_TYPE.Corporate ||
      this.state.data.travelerType == TRAVELER_TYPE.Airline) &&
    this.state.data.corporateInfo
  ) {
    this.props.bookingDetailActions
      .getCorporateForBooking({
        fleetId: this.props.auth.selectedFleet.fleetId,
        bookId: this.state.data.bookId,
        completed: CCLiteCommonFunc.isBookingStatusCompleted(
          this.state.data.status
        ),
      })
      .then((data) => {
        if (data.ok) {
          this.setState({ company: data.res });
        }
      });
  }
  if (
    this.state.data.drvInfo &&
    this.state.data.drvInfo.userId
  ) {
    this.props.driverActions
      .findOneDriver({ userId: this.state.data.drvInfo.userId })
      .then((data) => {
        if (data.ok && data.res) {
          this.state.driver = data.res;
        }
      });
  }
  if (CCLiteCommonFunc.isBookingStatusCompleted(this.state.data.status)) {
    const cartypeSelected =
      this.props.commonData &&
      this.props.commonData.carType &&
      this.props.commonData.carType.filter((v) => {
        return v.vehicleType == this.state.data.request.vehicleTypeRequest;
      })[0];
    if (this.state.locationPickUp) {
      this.carTypeBaseLocation().then((data) => {
        if (!this.state.cartypeSelected && data && data.ok) {
          let newCartypeSelected = {}
          if(this.state.isHydraBooking) {
            const vhcHydra = _.get(this.props.newBooking, 'locationService.affiliateService.vehicleTypeHydraSplited', [])
            const vhcHydraSelected = vhcHydra.find((vh) => 
                (vh.providerOfVhc.vehicleType) === this.state.data.request.vehicleTypeRequest
              )
              newCartypeSelected = vhcHydraSelected
          } else {

            data.res?.vehicleType?.map((vh) => {
              if (
                (vh.vehicleType || vh.ty) ==
                this.state.data.request.vehicleTypeRequest
              ) {
                // this.setState({ cartypeSelected: vh }, this.carTypeBaseLocation);
                newCartypeSelected = vh
              }
            });
          }
          this.setState({
            cartypeSelected: newCartypeSelected,
            disDur: getDistanceAndDuration(this.state.data)
          }, () => {
            if (this.state.data.pricingType && this.state.data.farmOut) {
              this.getCrossZoneBaseLocation();
            }
          })
          this.carTypeBaseLocation()
        } else {
          this.setState({ cartypeSelected });
        }
      });
    }
  } else if (this.state.locationPickUp) {
    this.carTypeBaseLocation().then((data) => {
      let cartypeSelected = {}
      if (!this.state.cartypeSelected && data && data.ok) {
        if(this.state.isHydraBooking) {
          const vhcHydra = _.get(this.props.newBooking, 'locationService.affiliateService.vehicleTypeHydraSplited', [])
          const vhcHydraSelected = vhcHydra.find((vh) => 
              (vh.providerOfVhc.vehicleType) === this.state.data.request.vehicleTypeRequest &&
              (vh.providerOfVhc.fleetId) === this.state.data.fleetId
            )
          cartypeSelected = vhcHydraSelected
        } else {
          data.res?.vehicleType?.map((vh) => {
            if (
              (vh.vehicleType || vh.ty) ==
              this.state.data.request.vehicleTypeRequest
            ) {
              cartypeSelected = vh
            }
          });
        }
        this.setState({ 
          cartypeSelected: cartypeSelected
        },
        () => {
          this.setState(
            {
              disDur: getDistanceAndDuration(this.state.data),
              etaFareMultiCar: [
                {
                  vehicleType: cartypeSelected.vehicleType,
                  ...(this.state.data?.request?.estimate?.fare || {})
                }
              ]
            },
            () => {
              callBack();
            }
          );
          if (this.state.data.pricingType && this.state.data.farmOut) {
            this.getCrossZoneBaseLocation();
          }
          const callBack = () => {
            this.carTypeBaseLocation().then(() => {
            });
          };
        } 
      );
        if (this.state.data.pricingType) {
          this.getCrossZoneBaseLocation();
        }
      }
    });
  }
}

const getDistanceAndDuration = (booking) => {
  const estimate = booking?.request?.estimate || {}
  if(estimate.distanceValue && estimate.estimateValue) {
    return {
      distance: {
        value: estimate.distanceValue,
        text: estimate.distance
      },
      duration: {
        value: estimate.estimateValue,
        text: parserIntNumberInString(estimate.time)
      }
    }
  }
  return null
}

function carTypeBaseLocation() {
  let options = {
    fleetId: this.props.auth.selectedFleet.fleetId,
    pickupTime: this.state?.data?.request?.pickUpTime,
  };

  /** 
   * if book from booking.com and holidaytaxis then 
   * doesn't add filter suppliercompany
  **/
  if (
    ![
      thirdPartyIntegration.bookingAPI,
      thirdPartyIntegration.holidaytaxis,
      thirdPartyIntegration.hoppa
    ].includes(this.props.bookingDetail?.data?.externalInfo?.thirdParty)
  ) {
    options.supplierCompanies = this.state.supplierCompanies;
  }

  if (this.state.locationPickUp) {
    let locationPickup = this.state.locationPickUp;
    let geo = [];
    if (locationPickup.lng && locationPickup.lat) {
      geo = [locationPickup.lng, locationPickup.lat];
    } else {
      geo = locationPickup.geo || [];
    }
    if (this.state.locationPickUp.zipCode) {
      options = {
        ...options,
        from: {
          geo: geo,
          zipCode: this.state.locationPickUp.zipCode,
        },
        zipCodeFrom: this.state.locationPickUp.zipCode,
      };
    } else {
      options = {
        ...options,
        from: {
          geo: geo,
        },
      };
    }
  }
  if (this.state.locationDestination) {
    let locationDestination = this.state.locationDestination;
    let geo = [];
    if (locationDestination.lng && locationDestination.lat) {
      geo = [locationDestination.lng, locationDestination.lat];
    } else {
      geo = locationDestination.geo || [];
    }
    if (this.state.locationDestination.zipCode) {
      options = {
        ...options,
        to: {
          geo: geo,
          zipCode: this.state.locationDestination.zipCode,
        },
        zipCodeTo: this.state.locationDestination.zipCode,
      };
    } else {
      options = {
        ...options,
        to: {
          geo: geo,
        },
      };
    }
    if (this.state.extraDestination && this.state.extraDestination.lat) {
      options = {
        ...options,
        to: {
          geo: [
            this.state.extraDestination.lng,
            this.state.extraDestination.lat,
          ],
        },
        extra: [
          {
            geo: [
              this.state.locationDestination.lng,
              this.state.locationDestination.lat,
            ],
          },
        ],
      };
    }
  }
  if (this.state.cartypeSelected && this.state.data.carType != '') {
    options = {
      ...options,
      vehicleType: this.state.cartypeSelected.vehicleType,
    };
  }
  if (this.state.data.travelerType == 1 || this.state.data.travelerType == 2) {
    options.corporateId = this.state.data.corporateInfo.corporateId;
    if (this.state.company) {
      let supplier = (this.state.company && this.state.company.supplier) || {};
      if (supplier.type == 'custom') {
        options.limitCompany = supplier.value;
      }
    }
  }

  if (this.props.isTransportBooking) {
    options.bookingType = 'transport';
    if (this.state.data.bookFrom === 'Car-hailing') {
      options.bookingType = 'car-hailing';
    }
  }
  return this.props.newbookingActions
  .getCarTypeBaseLocation(options)
  .then((data) => {
    if (data.ok && data.res) {
        this.initExtraService(data, this.state.data);
        if (
          !(
            data.res.additionalService &&
            data.res.additionalService.fromAirport &&
            data.res.additionalService.fromAirport.isActive &&
            data.res.additionalService.toAirportNew &&
            data.res.additionalService.toAirportNew.isActive &&
            ((data.res.fromAirport && data.res.fromAirport._id) ||
              (data.res.toAirport && data.res.toAirport._id) ||
              (data.res.extraAirport &&
                data.res.extraAirport[0] &&
                data.res.extraAirport[0]._id))
          ) &&
          (this.state.data.request.type == 1 ||
            this.state.data.request.type == 2)
        ) {
          this.state.data.request.type = 0;
        }
        if (!this.state.data.pricingType && data.res.currency) {
          this.state.data.currencyISO = data.res.currency.iso;
        }
        const disableHourlyRate = _.get(
          this.props.auth,
          'selectedFleet.generalSetting.disableHourlyRate',
          false
        );
        this.setState({
          data: {
            ...this.state.data,
            duration: checkForceRateType(data.res, this.state.data.duration, disableHourlyRate),
            isNewSettingAdditionFee: !!data.res?.fleetInfo?.additionalFees
          },
          isCheckSupportLocation: true,
        });
        return data;
      }
    });
}

function getTravelerModeByCarType(carType, isChina) {
  const travelMode = _.get(carType, 'travelMode', 'driving');
  if (isChina) {
    return travelMode === 'driving' ? travelMode.toLowerCase() : 'bicycling';
  }
  return travelMode === 'driving' ? travelMode.toUpperCase() : 'BICYCLING';
}

function isCanAssignOfflineDriver(selectedFleet, time) {
  // const process = _.get(selectedFleet, 'process', {});
  // if (!process.assignReservationForOfflineDriver) {
  //   return false;
  // }
  // if (process.isAutoDispatch && process.processBefore > 0) {
  //   return false;
  // }

  return time !== 'Now';
}

function getRecipientFromMenuData(menuData) {
  let returnData = {};
  if (menuData && menuData.selectedItems && menuData.selectedItems.length) {
    let packageContent = menuData.selectedItems.find(
      (item) => item.fieldKey === 'packageInfo'
    );
    if (packageContent) {
      returnData.packageContent = packageContent.fieldValue;
    }
    let instructions = menuData.selectedItems.find(
      (item) => item.fieldKey === 'request.notes'
    );
    if (instructions) {
      returnData.instructions = instructions.fieldValue;
    }
    let orderId = menuData.selectedItems.find(
      (item) => item.fieldKey === 'request.orderId'
    );
    if (orderId) {
      returnData.orderId = orderId.fieldValue;
    }
    let amount = menuData.selectedItems.find(
      (item) => item.fieldKey === 'cashOnDelivery'
    );
    if (amount && amount.fieldValue) {
      returnData.amount = amount.fieldValue.value || '';
    }
  }
  return returnData;
}

function getMenuDataBookingDetailFromRecipient(recipient, currency) {
  if (recipient.menuData) {
    let menuId = recipient.menuData.menuId;
    let newSelectedItems = recipient.menuData.selectedItems.map((item) => {
      if (
        item.componentType === CPT_TEXT_AREA_ROW &&
        item.fieldKey === 'packageInfo'
      ) {
        item.fieldValue = recipient.packageContent;
      } else if (
        item.componentType === CPT_NOTES_ROW &&
        item.fieldKey === 'request.notes'
      ) {
        item.fieldValue = recipient.instructions;
      } else if (
        item.componentType === CPT_NOTES_ROW &&
        item.fieldKey === 'request.orderId'
      ) {
        item.fieldValue = recipient.orderId;
      } else if (
        item.componentType === CPT_CHECKBOX_AMOUNT_ROW &&
        item.fieldKey === 'cashOnDelivery' &&
        (item.fieldValue || recipient.amount)
      ) {
        item.fieldValue = {
          iso: currency.iso,
          symbol: currency.symbol,
          value: parseFloat(recipient.amount) || 0,
        };
      }
      return item;
    });
    if (
      recipient.packageContent &&
      !newSelectedItems.some(
        (item) =>
          item.componentType === CPT_TEXT_AREA_ROW &&
          item.fieldKey === 'packageInfo'
      )
    ) {
      newSelectedItems.push({
        componentType: CPT_TEXT_AREA_ROW,
        parentItemId: BOOK_FROM_CC_CPT_TEXT_AREA_ROW,
        fieldKey: 'packageInfo',
        fieldType: 'String',
        fieldValue: recipient.packageContent,
      });
    }
    if (
      recipient.instructions &&
      !newSelectedItems.some(
        (item) =>
          item.componentType === CPT_NOTES_ROW &&
          item.fieldKey === 'request.notes'
      )
    ) {
      newSelectedItems.push({
        componentType: CPT_NOTES_ROW,
        parentItemId: BOOK_FROM_CC_CPT_NOTES_ROW,
        fieldKey: 'request.notes',
        fieldType: 'String',
        fieldValue: recipient.instructions,
      });
    }
    if (
      recipient.orderId &&
      !newSelectedItems.some(
        (item) =>
          item.componentType === CPT_NOTES_ROW &&
          item.fieldKey === 'request.orderId'
      )
    ) {
      newSelectedItems.push({
        componentType: CPT_NOTES_ROW,
        parentItemId: BOOK_FROM_CC_CPT_NOTES_ROW,
        fieldKey: 'request.orderId',
        fieldType: 'String',
        fieldValue: recipient.orderId,
      });
    }
    if (
      recipient.amount &&
      !newSelectedItems.some(
        (item) =>
          item.componentType === CPT_CHECKBOX_AMOUNT_ROW &&
          item.fieldKey === 'cashOnDelivery'
      )
    ) {
      newSelectedItems.push({
        componentType: CPT_CHECKBOX_AMOUNT_ROW,
        parentItemId: BOOK_FROM_CC_CPT_CHECKBOX_AMOUNT_ROW,
        fieldKey: 'cashOnDelivery',
        fieldType: 'PriceCurrency',
        fieldValue: {
          iso: currency.iso,
          symbol: currency.symbol,
          value: parseFloat(recipient.amount) || 0,
        },
      });
    }
    return {
      menuId,
      selectedItems: newSelectedItems,
    };
  }
  return recipient.menuData;
}

function getMenuDataFromRecipient(recipient, currency) {
  let data = [
    {
      componentType: CPT_TEXT_AREA_ROW,
      parentItemId: BOOK_FROM_CC_CPT_TEXT_AREA_ROW,
      fieldKey: 'packageInfo',
      fieldType: 'String',
      fieldValue: recipient.packageContent,
    },
    {
      componentType: CPT_NOTES_ROW,
      parentItemId: BOOK_FROM_CC_CPT_NOTES_ROW,
      fieldKey: 'request.notes',
      fieldType: 'String',
      fieldValue: recipient.instructions,
    },
    {
      componentType: CPT_NOTES_ROW,
      parentItemId: BOOK_FROM_CC_CPT_NOTES_ROW,
      fieldKey: 'request.orderId',
      fieldType: 'String',
      fieldValue: recipient.orderId,
    },
  ];
  const selectedItems =
    (recipient.menuData && recipient.menuData.selectedItems) || [];
  const itemSize = selectedItems.find(
    (item) =>
      item.fieldKey === 'package.size' && item.componentType === 'button-row'
  );
  if (!_.isEmpty(itemSize)) data.push(itemSize);
  if (recipient.amount && currency) {
    data.push({
      componentType: CPT_CHECKBOX_AMOUNT_ROW,
      parentItemId: BOOK_FROM_CC_CPT_CHECKBOX_AMOUNT_ROW,
      fieldKey: 'cashOnDelivery',
      fieldType: 'PriceCurrency',
      fieldValue: {
        iso: currency.iso,
        symbol: currency.symbol,
        value: parseFloat(recipient.amount) || 0,
      },
    });
  }
  return data;
}


function newDeliveryBookingEtaMultiFareCalculator() {
  if(this.state.isFareEdited && this.state?.etaFare?.supplierEarningType !== 'default') return;

  // const isPayoutTBD = this.state.supplierCompanies?.length != 1 
  //   && 
  //   !(_.get(this.state.driver, 'driver._id' , '') 
  //     || _.get(this.state.data, 'drvInfo.userId', ''))
  //   && this.state?.etaFare?.supplierEarningType === 'default';
  
  // if (isPayoutTBD) {
  //   return;
  // }

  if (!this.state.locationPickUp) {
    this.setState({ etaFare: null });
    return;
  }
  if (!this.state.locationDestination) {
    return;
  }
  if (!this.state.cartypeSelected) {
    this.setState({ etaFare: null });
    return;
  }
  if (!this.props.newBooking.locationService) {
    return;
  }
  if (!this.state.directions) {
    return;
  }
  if (!this.state.doPoints[0].address || !this.state.doPoints[0].address.geo) {
    return;
  }
  const doPointsNotEmpty = this.state.doPoints.filter(
    (ob) => !_.isEmpty(_.get(ob, 'address.address', ''))
  );
  const options = {
    fleetId: this.props.auth.selectedFleet.fleetId,
    bookFrom: 'CC',
    zipCodeFrom: this.state.locationPickUp.zipCode,
    pickupTime:
      this.state.data.time == 'Now'
        ? 'Now'
        : moment(this.state.data.time).format('YYYY-MM-DD HH:mm'),
    city: this.state.locationPickUp.city,
    timezone: this.state.locationPickUp.timezone,
    recipients: [],
    zipCodeTo: '',
    promoCode: '',
    tip: this.state.data.tips ? parseInt(this.state.data.tips) : 0,
    phone: this.state.data.phone,
    driverId: _.get(this.state.driver, 'driver._id' , '') || _.get(this.state.data, 'drvInfo.userId', ''),
    userId: '',
    currencyISO: this.props.newBooking.locationService.currency.iso,
    deliveryType: 0,
    cashOnPickup: false,
    serviceType: 'parcel',
    cashOnDelivery: doPointsNotEmpty.some(
      (item) =>
        !!(
          item.recipient &&
          item.recipient.amount &&
          parseFloat(item.recipient.amount)
        )
    ),
    menuId: BOOK_FROM_CC_MENU_ID,
    paymentMethod: this.state.data.paymentMethod,
  };
  if(this.state.supplierCompanies?.[0]) {
    options.companyId = this.state.supplierCompanies[0]
  }

  let { locationPickUp } = this.state || {};
  if (locationPickUp.geo) {
    options.pickup = { geo: locationPickUp.geo };
  } else {
    options.pickup = { geo: [locationPickUp.lng, locationPickUp.lat] };
  }
  if(this.state?.directionsRecipients?.length === doPointsNotEmpty.length) {
    doPointsNotEmpty.map((item, id) => {
      const recipientDirection = this.state?.directionsRecipients[id]
      options.recipients.push({
        geo: recipientDirection.geo,
        distance: recipientDirection.distance,
        duration: recipientDirection.duration,
        order: recipientDirection.order,
        menuData: getMenuDataFromRecipient(
          item.recipient,
          this.props.newBooking.locationService.currency
        ),
      });
    });
  }
  if (this.state.promo) {
    options.promoCode = this.state.promo.promotionCode;
  }

  if (this.state.customer && this.state.customer.userId) {
    options.userId = this.state.customer.userId;
  }

  if (
    (this.state.data.traveler_type == TRAVELER_TYPE.Corporate ||
      this.state.data.traveler_type == TRAVELER_TYPE.Airline) &&
    this.state.company
  ) {
    options.corporateId = this.state.company._id;
  }

  if (this.state.dateMode == 'recurring') {
    options.recurring = 'recurring';
  } else {
    options.recurring = 'single';
  }

  let vehicleAvailable = _.get(this.props.newBooking, 'locationService.vehicleType', [])
  vehicleAvailable = vehicleAvailable.filter(item => item.delivery) || []
  const paramsQueryPayout = buildParamsPayoutBooking.call(this, { isNewBooking: true });

  if (vehicleAvailable && vehicleAvailable.length > 0) {
    options.rateDetails = vehicleAvailable.map((vhc) => {
      const resultRateDetail = {
        vehicleTypeId: vhc.vehicleType,
      };
      if (vhc.vehicleType === this.state.cartypeSelected.vehicleType) {
        resultRateDetail.editFare = paramsQueryPayout.fare;
      }
      return resultRateDetail;
    })
  }
  options.sessionKey = uuidv4()

  return this.props.etaFareActions.etaFareMultiCarDelivery(options).then((response) => {
    if (response?.length > 0) {
      let fareCarSelecting = response && response.find(vhc => 
        vhc &&
        vhc.vehicleType === this.state.cartypeSelected.vehicleType
      )

      fareCarSelecting = {...fareCarSelecting, ...paramsQueryPayout.fare}

      this.setState({ 
        etaFare: fareCarSelecting || {}, 
        options: options,
        etaFareMultiCar:  response,
        sessionKeyETAFare: options.sessionKey
      });
    } else {
      this.setState({ 
        etaFare: null, 
        options: options,
        etaFareMultiCar:  [],
        sessionKeyETAFare: ''
      });
    }
  });
}

function deliveryBookingDetailEtaMultiFareCalculator(callback) {

  // const isPayoutTBD = this.state.supplierCompanies?.length != 1 
  //   && 
  //   !(_.get(this.state.driver, 'driver._id' , '') 
  //     || _.get(this.state.data, 'drvInfo.userId', '')) 
  //   && this.state.data?.request?.estimate?.fare?.supplierEarningType === 'default';

  // if (isPayoutTBD) {
  //   return;
  // }

  if (
    this.state.data?.request?.estimate?.isFareEdited 
  ) {
      const paramsQueryPayout = buildParamsPayoutBooking.call(this, { isNewBooking: false });

      return this.props.bookingActions.payoutSingleBooking(paramsQueryPayout).then((res) => {

        if (res && res.ok) {
          if (res.res.bookId) {
            let newFare = { ...this.state.data.request.estimate.fare }
            if (this.state.data?.request?.estimate?.fare?.supplierEarningType === 'default') {
              newFare = {...this.state.data.request.estimate.fare, ...res.res};
            } else {
              newFare.feePayToSupplier = res.res.transferData?.feePayToSupplier || 0;
            }
            
            this.setState({
              data: {
                ...this.state.data,
                request: {
                  ...this.state.data.request,
                  estimate: {
                    ...this.state.data.request.estimate,
                    fare: newFare,
                  },
                },
              },
            });
          }
        }
      })
  }

  if (
    this.state.data?.request?.estimate?.isFareEdited ||
    (this.state.data?.request?.estimate?.fare?.supplierEarningType &&
      this.state.data?.request?.estimate?.fare?.supplierEarningType != 'default')
  )
  return;
  if (!this.state.locationPickUp) {
    this.state.data.request.estimate.fare = null;
    this.setState({ etaFare: null, data: this.state.data });
    return;
  }
  if (!this.state.locationDestination) {
    return;
  }
  if (!this.state.cartypeSelected) {
    this.state.data.request.estimate.fare = null;
    this.setState({ etaFare: null, data: this.state.data });
    return;
  }
  if (!this.props.newBooking.locationService) {
    return;
  }
  if (
    !this.state.data.doPoints[0].address ||
    !this.state.data.doPoints[0].address.geo
  ) {
    return;
  }
  const doPointsNotEmpty = this.state.data.doPoints.filter(
    (ob) => !_.isEmpty(_.get(ob, 'address.address', ''))
  );
  const options = {
    fleetId: this.props.auth.selectedFleet.fleetId,
    bookFrom: this.state.data.bookFrom ? this.state.data.bookFrom : 'CC',
    bookId: this.state.data.bookId,
    zipCodeFrom: this.state.locationPickUp.zipCode,
    pickupTime:
      this.state.data.time == 'Now'
        ? 'Now'
        : moment(this.state.data.time).format('YYYY-MM-DD HH:mm'),
    city: this.state.locationPickUp.city,
    timezone: this.state.locationPickUp.timezone,
    recipients: [],
    zipCodeTo: '',
    promoCode: '',
    tip: this.state.data.tips ? parseInt(this.state.data.tips) : 0,
    phone: this.state.data.phone,
    userId: '',
    driverId: _.get(this.state.driver, 'driver._id' , '') || _.get(this.state.data, 'drvInfo.userId', ''),
    currencyISO: this.props.newBooking.locationService.currency.iso,
    deliveryType: 0,
    cashOnPickup: false,
    serviceType: 'parcel',
    cashOnDelivery: checkCashOnDelivery(doPointsNotEmpty),
    menuId: BOOK_FROM_CC_MENU_ID,
    editFare: this.state.editFare,
    paymentMethod: this.state.data.request.paymentType,
  };

  options.companyId = _.get(this.state.data, 'request.companyId', null)
  options.companyId = getCompanyIdForETA(this.state.driverCompany, this.state.driver)
  
  let { locationPickUp } = this.state || {};
  if (locationPickUp.geo) {
    options.pickup = { geo: locationPickUp.geo };
  } else {
    options.pickup = { geo: [locationPickUp.lng, locationPickUp.lat] };
  }
  doPointsNotEmpty.map((item) => {
    options.recipients.push({
      geo: item.address.geo,
      distance: this.state.disDur?.distance?.value || 0,
      duration: this.state.disDur?.duration?.value/60 || 0,
      order: item.order - this.state.data.puPoints.length,
      menuData: getMenuDataFromRecipient(
        item.recipient,
        this.props.newBooking.locationService.currency
      ),
    });
  });
  if (this.state.promo) {
    options.promoCode =
      this.state.promo.promotionCode || this.state.promo.promo;
  }
  if (this.state.cartypeSelected) {
    options.vehicleTypeId =
      this.state.cartypeSelected.vehicleType || this.state.cartypeSelected.ty;
  }

  if (this.state.customer && this.state.customer.userId) {
    options.userId = this.state.customer.userId;
  }

  if (
    this.state.data.travelerType == TRAVELER_TYPE.Corporate &&
    this.state.company
  ) {
    options.corporateId = this.state.company._id;
  }

  if (this.state.dateMode == 'recurring') {
    options.recurring = 'recurring';
  } else {
    options.recurring = 'single';
  }

  let vehicleAvailable = _.get(this.props.newBooking, 'locationService.vehicleType', [])
  vehicleAvailable = vehicleAvailable.filter(item => item.delivery) || []

  if (vehicleAvailable && vehicleAvailable.length > 0) {
    if(this.state.showAllVehilceBtn) {
      options.rateDetails = [{
        vehicleTypeId: this.state.cartypeSelected?.vehicleType,
        editFare: this.state.editFare,
      }]
    } else {
      options.rateDetails = vehicleAvailable.map((vhc) => {
        const vhcResult = {
          vehicleTypeId: vhc.vehicleType,
        }
        if (vhc.vehicleType === this.state.cartypeSelected?.vehicleType) {
          vhcResult.editFare = this.state.editFare
        }
        return vhcResult
      })
    }
  }
  options.sessionKey = uuidv4()
  return this.props.etaFareActions.etaFareMultiCarDelivery(options).then((response) => {
    if (response?.length > 0) {
      const fareCarSelecting = response.find(vhc => 
        vhc &&
        vhc.vehicleType === this.state.cartypeSelected.vehicleType
      )
      this.state.data.request.estimate.fare = fareCarSelecting || [];
      this.setState({
        etaFare: fareCarSelecting,
        data: this.state.data,
        options: options,
        etaFareMultiCar: response,
        sessionKeyETAFare: options.sessionKey
      });
      if (callback) {
        callback(data);
      }
    } else {
      this.state.data.request.estimate.fare = null;
      this.setState({
        disableCompletePaymentNextClick: false,
        disabledCompleteWithoutServiceButton: false,
        data: this.state.data,
        etaFare: null,
        options: options,
        sessionKeyETAFare: ''
      });
    }
  });
}

const getExtraDestinationEtaFare = ({ puPoints = [], doPoints = [] }) => {
  const pointsNotEmpty = getAllPointNotEmpty({ puPoints, doPoints })
  return pointsNotEmpty.slice(1, -1)
      .filter(pos => pos.lat && pos.lng)
      .map(pos => ({ geo: [pos.lng, pos.lat] }))
}

export const checkCashOnDelivery = (points = []) => {
  return points?.some(
    (item) =>
      !!(
        item.recipient &&
        item.recipient.amount &&
        parseFloat(item.recipient.amount)
      )
  )
}

export {
  newBookingEtaFareMultiCarCalculator,
  bookingDetailsEtaFareMultiCarCalculator,
  newDeliveryBookingEtaMultiFareCalculator,
  deliveryBookingDetailEtaMultiFareCalculator,
  getMenuDataFromRecipient,
  getMenuDataBookingDetailFromRecipient,
  carTypeBaseLocation,
  getServiceAndCalculateLogicBookingDetail,
  getTravelerModeByCarType,
  isCanAssignOfflineDriver,
  getRecipientFromMenuData,
  handleFinalServicesRequest
};
