import _ from 'lodash'
import { i } from 'react-dom-factories';
import {
  CAR_TYPE_BASE_LOCATION_REQUEST,
  CAR_TYPE_BASE_LOCATION_SUCCESS,
  CAR_TYPE_BASE_LOCATION_FAILURE,
  NEW_BOOKING_FROM_EXISTED,
  NEWBOOKING_FORM_REQUEST,
  CLOSE_NEWBOOKING_FORM,
  GET_CROSSZONE_INFO_SUCCESS,
  RESET_NEW_BOOK
} from '../actions/newbookingAction';

const initialState = {
  locationService: null,
  bookingFormShow: false,
  isShuttle: false
};

export default function newBooking(state = initialState, action = {}) {
  switch (action.type) {
    case CAR_TYPE_BASE_LOCATION_REQUEST: {
      return state;
    }
    case CAR_TYPE_BASE_LOCATION_SUCCESS: {
      const vehicleTypeHydraInit = _.get(action.data, 'affiliateService.vehicleType', [])
      let vhcHydraSplit = []
      if(vehicleTypeHydraInit.length > 0) {
        vehicleTypeHydraInit.map(car => {
          if(car.providers) {
            car.providers.map(provider => {
              vhcHydraSplit.push({
                ...car,
                providerOfVhc: provider
              })
            })
          }
        })
        action.data.affiliateService.vehicleTypeHydraSplited = vhcHydraSplit
      }
      if (action.data && state.locationService) {
        return {
          ...state,
          locationService:
            action.data.crossZone != state.locationService.crossZone
              ? action.data
              : Object.assign(state.locationService, action.data)
        };
      }
      return { ...state, locationService: action.data };
    }
    case CAR_TYPE_BASE_LOCATION_FAILURE: {
      return state;
    }
    case CLOSE_NEWBOOKING_FORM: {
      return {...initialState};
    }
    case RESET_NEW_BOOK: {
      return {
        ...state,
        locationService: null
      };
    }
    case NEWBOOKING_FORM_REQUEST: {
      return { locationService: null, bookingFormShow: true };
    }
    case NEW_BOOKING_FROM_EXISTED: {
      return { ...state, bookingData: action.data, bookingFormShow: true };
    }
    case GET_CROSSZONE_INFO_SUCCESS: {
      return { ...state, locationService: Object.assign(state.locationService, action.data) };
    }
    default:
      return state;
  }
}
