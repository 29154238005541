import newbooking from '../assets/images/icons/newbooking.svg';
import newbooking_active from '../assets/images/icons/newbooking_active.svg';
import dashboard from '../assets/images/icons/dashboard.svg';
import dashboard_active from '../assets/images/icons/dashboard_active.svg';
import map from '../assets/images/icons/map.svg';
import map_active from '../assets/images/icons/map_active.svg';
import cue from '../assets/images/icons/cue.svg';
import cue_active from '../assets/images/icons/cue_active.svg';
import customer from '../assets/images/icons/customer.svg';
import customer_active from '../assets/images/icons/customer_active.svg';
import driver from '../assets/images/icons/driver.svg';
import driver_active from '../assets/images/icons/driver_active.svg';
import corporation from '../assets/images/icons/corporation.svg';
import corporation_active from '../assets/images/icons/corporation_active.svg';
import mdispatch from '../assets/images/icons/mdispatch.svg';
import mdispatch_active from '../assets/images/icons/mdispatch_active.svg';
import report from '../assets/images/icons/report.svg';
import report_active from '../assets/images/icons/report_active.svg';
import setting from '../assets/images/icons/setting.svg';
import setting_stripe from '../assets/images/icons/setting_dot.svg';
import setting_active_stripe from '../assets/images/icons/setting_active_dot.svg';
import iconAfter from '../assets/images/icons/iconAfter.svg';
import setting_active from '../assets/images/icons/setting_active.svg';
import settlement from '../assets/images/icons/settlement.svg';
import settlement_active from '../assets/images/icons/settlement_active.svg';
import withdrawal from '../assets/images/icons/withdrawal.svg';
import withdrawal_active from '../assets/images/icons/withdrawal_active.svg';
import queuingArea from '../assets/images/icons/queuingArea.svg';
import queuingArea_active from '../assets/images/icons/queuingArea_active.svg';
import trip from '../assets/images/icons/trip.svg';
import trip_active from '../assets/images/icons/trip_active.svg';
import payout from '../assets/images/icons/driver_payout.svg';
import payout_active from '../assets/images/icons/driver_payout_active.svg';
import merchant from '../assets/images/icons/merchant.svg';
import merchant_active from '../assets/images/icons/merchant_active.svg';
import supplier from '../assets/images/icons/supplier.svg';
import supplier_active from '../assets/images/icons/supplier_active.svg';
import invoice from '../assets/images/icons/merchant.svg';
import invoice_active from '../assets/images/icons/invoice_active.svg';
import vehicle from '../assets/images/icons/vehicle.svg';
import vehicle_active from '../assets/images/icons/vehicle_active.svg';

const airline = corporation;
const airline_active = corporation_active;

export const sidebarMenuConfigs = [
  {
    text: 'Sidebar.Notifications',
    name: 'Notifications',
    active: false,
    show: false,
    enabled: true,
    key: 'Notifications',
    link: '/notifications',
  },
  {
    text: 'Sidebar.Sos',
    name: 'Sos',
    active: false,
    show: false,
    enabled: true,
    key: 'Sos',
    link: '/sos',
  },
  {
    text: 'Sidebar.SignUpNotifications',
    name: 'SignUpNotifications',
    active: false,
    show: false,
    enabled: true,
    key: 'SignUpNotifications',
    link: '/signupnotifications',
  },
  {
    icon: newbooking,
    icon_active: newbooking_active,
    text: 'Sidebar.New_booking',
    name: 'New booking',
    active: false,
    show: false,
    enabled: true,
    key: 'Booking',
  },
  {
    icon: dashboard,
    icon_active: dashboard_active,
    text: 'Sidebar.Dashboard',
    name: 'Dashboard',
    link: '/analytics',
    active: false,
    show: false,
    enabled: true,
    key: 'Analytics',
  },
  {
    text: 'Sidebar.SmartData',
    name: 'Smart Data',
    link: '/smartdata',
    active: false,
    show: false,
    enabled: true,
    key: 'SmartData',
  },
  {
    icon: map,
    icon_active: map_active,
    text: 'Sidebar.Map',
    name: 'Map',
    link: '/map',
    active: false,
    show: false,
    enabled: true,
    key: 'Map',
  },
  {
    icon: cue,
    icon_active: cue_active,
    text: 'Sidebar.Cue',
    name: 'Cue',
    link: '/cue',
    active: false,
    show: false,
    enabled: true,
    key: 'Cue',
  },
  {
    icon: invoice,
    icon_active: invoice_active,
    text: 'Sidebar.Settings.Invoice_Setting',
    name: 'Invoice',
    link: '/invoice',
    active: false,
    show: false,
    enabled: true,
    key: 'Invoice',
  },
  {
    icon: trip,
    icon_active: trip_active,
    text: 'Sidebar.Trip',
    name: 'Trip',
    link: '/trip',
    active: false,
    show: false,
    enabled: true,
    key: 'Trip',
  },
  {
    icon:
      // eslint-disable-next-line max-len
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAeCAYAAADU8sWcAAAACXBIWXMAAAsSAAALEgHS3X78AAABV0lEQVRIx+2WQU7DQAxFX6vZw5JlbtAcITkJ5QblBExPAEcIJ2F6g7Bn0SOkEnuzwIms0SS0UCZFwpI1iieeH3v87SAiJNTLp/iR/VM06FlVvLdkRlmICEAJBOAqE+4rUC1EpADajMDDBzigMMC1rmvgFngGmh+CPAEr4F6DLIFHYOWiF4Oula57Y/uudLq28VmzFhxKgV7IoAPerJE74MY8+wyYxRj4Q+7I38xznQGzpxoOeE9QLVvkx4rl/h641ijsR5dq75TX1q81nD+ZahJNupRfP8FCwq+6OKodK7VJe5/GuEA3Ju2xX3tRHc5NFFelUTZRAwqqhU4/u7dWe8qvMVmbjNxPFM7ZCm4s8j2wi+5pF915Z2xE95ry6/6n2lc/Ex6QqN2KqjdF2dvOCj5rkwlRcQFsE3vbP/sb5UYm12/P8yHtln8vGa/8sNSGcJe51g5A9QGBnAStKET4lQAAAABJRU5ErkJggg==)',
    text: 'Sidebar.Reservation',
    name: 'Reservation',
    link: '/calendar',
    active: false,
    show: false,
    enabled: true,
    key: 'Calendar',
  },
  {
    icon: driver,
    icon_active: driver_active,
    text: 'Sidebar.Driver',
    name: 'Driver',
    link: '/driver',
    active: false,
    show: false,
    enabled: true,
    key: 'Driver',
  },
  {
    icon: customer,
    icon_active: customer_active,
    text: 'Sidebar.Customer',
    name: 'Customer',
    link: '/customer',
    active: false,
    show: false,
    enabled: true,
    key: 'Customer',
  },
  {
    icon: supplier,
    icon_active: supplier_active,
    text: 'Sidebar.Supplier',
    name: 'Supplier',
    link: '/supplier',
    active: false,
    show: false,
    enabled: true,
    key: 'Supplier',
  },
  {
    icon: merchant,
    icon_active: merchant_active,
    text: 'Sidebar.Merchant',
    name: 'Merchant',
    link: '/merchant',
    active: false,
    show: false,
    enabled: true,
    key: 'Merchant',
  },
  {
    icon: vehicle,
    icon_active: vehicle_active,
    text: 'Sidebar.Vehicle',
    name: 'Vehicle',
    link: '/vehicle',
    active: false,
    show: false,
    enabled: true,
    key: 'Vehicle',
  },
  {
    icon: corporation,
    icon_active: corporation_active,
    text: 'Sidebar.Corporate',
    name: 'Corporate',
    link: '/corporate',
    active: false,
    show: false,
    enabled: true,
    key: 'Corporate',
    moduleSettingField: 'corporate',
  },
  // {
  //   icon: airline,
  //   icon_active: airline_active,
  //   text: 'Sidebar.Airline',
  //   name: 'Airline Business',
  //   link: '/airlineBusiness',
  //   active: false,
  //   show: false,
  //   enabled: true,
  //   key: 'AirlineBusiness',
  //   moduleSettingField: 'airlineBusiness',
  // },
  {
    icon: mdispatch,
    icon_active: mdispatch_active,
    text: 'Sidebar.mDispatcher',
    name: 'mDispatcher',
    link: '/mDispatcher',
    active: false,
    show: false,
    enabled: true,
    key: 'mDispatcher',
  },
  {
    icon: payout,
    icon_active: payout_active,
    text: 'Sidebar.Payout_label',
    name: 'Payout',
    link: '/Payout/pay_to_driver',
    active: false,
    show: false,
    enabled: true,
    key: 'Payout',
    sub: [
      {
        text: 'Sidebar.payout.Pay_To_Driver',
        name: 'Pay To Driver',
        key: 'Payout.Pay_To_Driver',
        link: '/Payout/pay_to_driver',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.payout.Payout_History_For_Driver',
        name: 'Payout History For Driver',
        key: 'Payout.Payout_History_For_Driver',
        link: '/Payout/payout_history_for_driver',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.payout.Pay_To_Merchant',
        name: 'Pay To Merchant',
        key: 'Payout.Pay_To_Merchant',
        link: '/Payout/pay_to_merchant',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.payout.Payout_History_For_Merchant',
        name: 'Payout History For Merchant',
        key: 'Payout.Payout_History_For_Merchant',
        link: '/Payout/payout_history_for_merchant',
        active: false,
        show: false,
        enabled: true,
      },
    ],
  },
  {
    icon: queuingArea,
    icon_active: queuingArea_active,
    text: 'Sidebar.Queuing_area',
    name: 'Queuing Area',
    link: '/Queuingarea',
    active: false,
    show: false,
    enabled: true,
    key: 'QueuingArea',
  },
  {
    icon: withdrawal,
    icon_active: withdrawal_active,
    text: 'Sidebar.withdrawalRequest',
    name: 'Withdrawal Requests',
    link: '/withdrawal_requests',
    active: false,
    show: false,
    enabled: true,
    key: 'Withdrawal_Requests',
  },
  {
    icon: settlement,
    icon_active: settlement_active,
    text: 'Sidebar.Driver_Settlement',
    name: 'Driver Settlement',
    link: '/settlement/pay_to_driver',
    active: false,
    show: false,
    enabled: true,
    key: 'Settlement',
    subShowAll: true,
    moduleSettingField: 'driverSettlement',
    sub: [
      {
        text: 'Sidebar.Reports.Pay_to_driver',
        name: 'Pay to driver',
        key: 'Settlement.Pay_to_driver',
        link: '/settlement/pay_to_driver',
        active: false,
        show: true,
        enabled: true,
      },
      {
        text: 'Sidebar.Reports.Settlement_history',
        name: 'Settlement history',
        key: 'Settlement.Settlement_history',
        link: '/settlement/settlement_history',
        active: false,
        show: true,
        enabled: true,
      },
    ],
  },
  {
    icon: report,
    icon_active: report_active,
    text: 'Sidebar.REPORTS',
    name: 'Reports',
    link: '/reports/Booking_details',
    active: false,
    show: false,
    enabled: true,
    key: 'Reports',
    sub: [
      {
        text: 'Sidebar.Reports.Export',
        name: 'Export',
        key: 'Reports.Export',
        link: '/reports/export',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Reports.Booking_details',
        name: 'Booking details',
        key: 'Reports.Booking_details',
        link: '/reports/Booking_details',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Reports.Financial',
        name: 'Financial',
        key: 'Reports.financial',
        link: '/reports/financial',
        active: false,
        show: false,
        enabled: true,
        sub: [
          {
            text: 'Sidebar.Reports.Financial_driver',
            name: 'Financial (Driver)',
            key: 'Reports.financial.driver',
            link: '/reports/financial',
            tooltip: 'Sidebar.Reports.Financial_Driver_ToolTip',
            active: false,
            show: true,
            enabled: true,
          },
          {
            text: 'Sidebar.Reports.Financial_company',
            name: 'Financial (Company)',
            key: 'Reports.financial.company',
            link: '/reports/financial/company',
            tooltip: 'Sidebar.Reports.Financial_Company_Tooltip',
            active: false,
            show: true,
            enabled: true,
          },
          {
            text: 'Sidebar.Reports.Financial_fleet',
            name: 'Financial (Fleet)',
            key: 'Reports.financial.profit',
            link: '/reports/financial/profit',
            tooltip: 'Sidebar.Reports.Financial_Fleet_Profit_ToolTip',
            active: false,
            show: true,
            enabled: true,
          },
        ],
      },
      {
        text: 'Sidebar.Reports.Revenue_Summary',
        name: 'Revenue Summary',
        key: 'Reports.revenue',
        link: '/reports/revenue/fleet',
        active: false,
        show: false,
        enabled: true,
        sub: [
          {
            text: 'Sidebar.Reports.Revenue_fleet',
            name: 'Revenue Summary (Fleet)',
            key: 'Reports.revenue.fleet',
            link: '/reports/revenue/fleet',
            active: false,
            show: true,
            enabled: true,
          },
          {
            text: 'Sidebar.Reports.Revenue_driver',
            name: 'Revenue Summary (Driver)',
            key: 'Reports.revenue.driver',
            link: '/reports/revenue/driver',
            active: false,
            show: true,
            enabled: true,
          },
          {
            text: 'Sidebar.Reports.Revenue_monthly',
            name: 'Revenue Summary (Monthly)',
            key: 'Reports.revenue.monthly',
            link: '/reports/revenue/monthly',
            active: false,
            show: true,
            enabled: true,
          },
        ],
      },
      {
        text: 'Sidebar.Reports.Company_settlement',
        name: 'Company settlement',
        key: 'Reports.company_settlement',
        link: '/reports/company_settlement',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Reports.Rating',
        name: 'Rating',
        key: 'Reports.Rating',
        link: '/reports/rating',
        active: false,
        show: false,
        enabled: true,
        sub: [
          {
            text: 'Sidebar.Reports.Driver_Rating',
            name: 'Rating',
            key: 'Reports.rating.driver',
            link: '/reports/rating',
            tooltip: 'Sidebar.Reports.Driver_Rating',
            active: false,
            show: true,
            enabled: true,
          },
          {
            text: 'Sidebar.Reports.Passenger_Rating',
            name: 'Rating',
            key: 'Reports.rating.passenger',
            link: '/reports/rating/passenger',
            tooltip: 'Sidebar.Reports.Passenger_Rating',
            active: false,
            show: true,
            enabled: true,
            moduleSettingField: 'driverRatesCustomer',
          },
        ],
      },
      {
        text: 'Sidebar.Reports.Accept_Cancel_rate',
        name: 'Acceptance/Cancellation rate',
        key: 'Reports.acceptcancel_rate',
        link: '/reports/acceptcancel_rate',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Reports.Total_adjustment',
        name: 'Total adjustment',
        key: 'Reports.Total_adjustment',
        link: '/reports/Total_adjustment',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Reports.Promotion',
        name: 'Promotion',
        key: 'Reports.Promotion',
        link: '/reports/Promotion',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Reports.Incomplete_payment',
        name: 'Incomplete payment',
        key: 'Reports.Incomplete_Payment',
        link: '/reports/incomplete_payment',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Reports.Incident_Cancellation',
        name: 'Incident & Cancellation',
        key: 'Reports.Incident_Cancellation',
        link: '/reports/Incident_Cancellation',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Reports.Refund',
        name: 'Refund',
        key: 'Reports.Refund',
        link: '/reports/Refund',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Reports.Deletion_Request',
        name: 'Deletion request',
        key: 'Reports.deletion_request',
        link: '/reports/deletion_request',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Reports.Merchant_Report',
        name: 'Merchant Report',
        key: 'Reports.merchant',
        link: '/reports/merchant/financial',
        active: false,
        show: false,
        enabled: true,
        sub: [
          {
            text: 'Sidebar.Reports.Merchant_Financial_Report',
            name: 'Merchant Report',
            key: 'Reports.merchant.financial',
            link: '/reports/merchant/financial',
            tooltip: 'Sidebar.Reports.Merchant_Financial_Report',
            active: false,
            show: true,
            enabled: true,
          },
          {
            text: 'Sidebar.Reports.Merchant_Credit_Report',
            name: 'Merchant Report',
            key: 'Reports.merchant.credit',
            link: '/reports/merchant/credit',
            tooltip: 'Sidebar.Reports.Merchant_Credit_Report',
            active: false,
            show: true,
            enabled: true,
          },
          {
            text: 'Sidebar.Reports.Merchant_Cash_Report',
            name: 'Merchant Report',
            key: 'Reports.merchant.cash',
            link: '/reports/merchant/cash',
            tooltip: 'Sidebar.Reports.Merchant_Cash_Report',
            active: false,
            show: true,
            enabled: true,
          },
        ],
      },
      {
        text: 'Sidebar.Reports.Partner',
        name: 'Partner',
        key: 'Reports.Partner',
        link: '/reports/Partner',
        active: false,
        show: false,
        enabled: false,
      },
      {
        text: 'Sidebar.Reports.mDispatcher',
        name: 'mDispatcher',
        key: 'Reports.mDispatcher',
        link: '/reports/mDispatcher',
        active: false,
        show: false,
        enabled: true,
      },

      {
        text: 'Sidebar.Reports.Corporate',
        name: 'Corporate ',
        key: 'Reports.Corporate',
        link: '/reports/corporate',
        active: false,
        show: false,
        enabled: true,
        moduleSettingField: 'corporate',
      },
      {
        text: 'Sidebar.Reports.Prepaid_top_up',
        name: 'Prepaid top up',
        key: 'Reports.Prepaid',
        link: '/reports/prepaid',
        active: false,
        show: false,
        enabled: true,
        moduleSettingField: 'corporate',
      },
      {
        text: 'Sidebar.Reports.Booking_logs',
        name: 'Booking logs',
        key: 'Reports.Booking_logs',
        link: '/reports/Booking_logs',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Reports.Third_Party_Booking',
        name: 'Third Party Booking (Booking.com)',
        key: 'Reports.thirdParty',
        link: '/reports/3rdBooking',
        active: false,
        show: false,
        enabled: true,
        sub: [
          {
            text: 'Sidebar.Reports.BookingDotCom',
            name: 'Third Party Booking (Booking.com)',
            key: 'Reports.thirdParty.3rdBooking',
            link: '/reports/3rdBooking',
            active: false,
            show: false,
            enabled: true,
          },
          {
            text: 'Sidebar.Reports.HolidayTaxis',
            name: 'Third Party Booking (HolidayTaxis)',
            key: 'Reports.thirdParty.holidaytaxis',
            link: '/reports/holidaytaxis',
            active: false,
            show: false,
            enabled: true,
          },
          {
            text: 'Sidebar.Reports.Karhoo',
            name: 'Third Party Booking (Karhoo)',
            key: 'Reports.thirdParty.karhoo',
            link: '/reports/karhoo',
            active: false,
            show: false,
            enabled: true,
          },
          {
            text: 'Sidebar.Reports.Hoppa',
            name: 'Third Party Booking (Hoppa)',
            key: 'Reports.thirdParty.hoppa',
            link: '/reports/hoppa',
            active: false,
            show: false,
            enabled: true,
          },
        ],
      },
      {
        text: 'Sidebar.Reports.Booking_summary',
        name: 'Booking summary',
        key: 'Reports.Booking_summary',
        link: '/reports/Booking_summary',
        active: false,
        show: true,
        enabled: true,
      },
      {
        text: 'Sidebar.Reports.Operator_logs',
        name: 'Operator logs',
        key: 'Reports.Operator_logs',
        link: '/reports/Operator_logs',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Reports.Settlement',
        name: 'Settlement',
        key: 'Reports.Settlement',
        link: '/reports/settlement',
        active: false,
        show: false,
        enabled: true,
        subShowAll: true,
        sub: [
          {
            text: 'Sidebar.Reports.Unapproved_Driver',
            name: 'Daily Driver',
            key: 'Reports.Settlement.Unapproved',
            link: '/reports/settlement/unapproved',
            active: false,
            show: true,
            enabled: true,
          },
          {
            text: 'Sidebar.Reports.Approved_Driver',
            name: 'Daily Driver Car',
            key: 'Reports.Settlement.Approved',
            link: '/reports/settlement/approved',
            active: false,
            show: true,
            enabled: true,
          },
        ],
      },
      {
        text: 'Sidebar.Reports.Daily',
        name: 'Daily',
        key: 'Reports.Daily',
        link: '/reports/Daily',
        active: false,
        show: false,
        enabled: true,
        sub: [
          {
            text: 'Sidebar.Reports.Daily_Driver',
            name: 'Daily Driver',
            key: 'Reports.Daily.Daily_Driver',
            link: '/reports/daily/Daily_Driver',
            active: false,
            show: true,
            enabled: true,
          },
          {
            text: 'Sidebar.Reports.Daily_Car',
            name: 'Daily Car',
            key: 'Reports.Daily.Daily_Car',
            link: '/reports/daily/Daily_Car',
            active: false,
            show: true,
            enabled: true,
          },
          {
            text: 'Sidebar.Reports.Daily_Driver_Car',
            name: 'Daily Driver Car',
            key: 'Reports.Daily.Daily_Driver_Car',
            link: '/reports/daily/Daily_Driver_Car',
            active: false,
            show: true,
            enabled: true,
          },
        ],
      },
      {
        text: 'Sidebar.Reports.Driver_login_status',
        name: "Driver's login status",
        key: 'Reports.Driver_login_status',
        link: '/reports/Driver_login_status',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Reports.Car_activity',
        name: "Car's activity",
        key: 'Reports.Car_activity',
        link: '/reports/Car_activity',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Reports.Affiliation',
        name: 'Affiliation',
        key: 'Reports.Affiliation',
        link: '/reports/affiliation',
        active: false,
        show: false,
        enabled: true,
        subShowAll: true,
        sub: [
          {
            text: 'Sidebar.Reports.Affiliation_bookings',
            name: 'Affiliation Bookings',
            key: 'Reports.Affiliation.Bookings',
            link: '/reports/affiliation/bookings',
            active: false,
            show: true,
            enabled: true,
          },
          {
            text: 'Sidebar.Reports.Affiliation_penalty_compensation',
            name: 'Affiliation Penalty',
            key: 'Reports.Affiliation.Penalty_Compensation',
            link: '/reports/affiliation/penalty_compensation',
            active: false,
            show: true,
            enabled: true,
          },
          {
            text: 'Sidebar.Reports.Affiliation_payout_history',
            name: 'Affiliation Payout History',
            key: 'Reports.Affiliation.Payout_History',
            link: '/reports/affiliation/payout_history',
            active: false,
            show: true,
            enabled: true,
          },
        ],
      },
      {
        text: 'Sidebar.Reports.Driver_wallet',
        name: 'Driver Wallet',
        key: 'Reports.Driver_wallet',
        link: '/reports/Driver_wallet',
        active: false,
        show: false,
        enabled: true,
        subShowAll: false,
        sub: [
          {
            text: 'Sidebar.Reports.Credit_Wallet',
            name: 'Driver Deposit',
            key: 'Reports.Driver_wallet.Credit_Wallet',
            link: '/reports/Driver_wallet/Credit_Wallet',
            active: false,
            show: true,
            enabled: true,
          },
          {
            text: 'Sidebar.Reports.Cash_Wallet',
            name: 'Driver Cash Wallet',
            key: 'Reports.Driver_wallet.Cash_Wallet',
            link: '/reports/Driver_wallet/Cash_Wallet',
            active: false,
            show: true,
            enabled: true,
          },
        ],
      },
      {
        text: 'Sidebar.Reports.Passenger_wallet',
        name: 'Passenger Wallet',
        key: 'Reports.Passenger_wallet',
        link: '/reports/Passenger_wallet',
        active: false,
        show: true,
        enabled: true,
      },
      {
        text: 'Sidebar.Reports.Withdrawal_History',
        name: 'Driver withdrawal',
        key: 'Reports.Driver_withdrawal',
        link: '/reports/Driver_withdrawal',
        active: false,
        show: true,
        enabled: true,
      },
      {
        text: 'Sidebar.Reports.Document_expiry',
        name: 'Document expiry',
        key: 'Reports.Document_expiry',
        link: '/reports/Document_expiry',
        active: false,
        show: true,
        enabled: true,
      },
      {
        text: 'Sidebar.Reports.Driver_Referral_history',
        name: 'Driver Referral history',
        key: 'Reports.Driver_Referral_history',
        link: '/reports/Driver_Referral_history',
        active: false,
        show: false,
        enabled: true,
        subShowAll: false,
        sub: [
          {
            text: 'Sidebar.Reports.Driver_refers_Pax',
            name: 'Driver refers customer (Drivers)',
            key: 'Reports.Driver_Referral_history.Driver_refers_Pax',
            link: '/reports/Driver_Referral_history/Driver_refers_Pax',
            active: false,
            show: true,
            enabled: true,
          },
          {
            text: 'Sidebar.Reports.Driver_refers_Pax_Customers',
            name: 'Driver refers customer (Customers)',
            key: 'Reports.Driver_Referral_history.customers',
            link: '/reports/Driver_Referral_history/customers',
            active: false,
            show: true,
            enabled: true,
          },
          {
            text: 'Sidebar.Reports.Driver_refers_Pax_Bookings',
            name: 'Driver refers customer (Bookings)',
            key: 'Reports.Driver_Referral_history.bookings',
            link: '/reports/Driver_Referral_history/bookings',
            active: false,
            show: true,
            enabled: true,
          },
        ],
      },
      {
        text: 'Sidebar.Reports.Passenger_refers_Passengers',
        name: 'Passenger refers Passengers',
        key: 'Reports.Passenger_refers_Passengers',
        link: '/reports/Passenger_refers_Passengers',
        active: false,
        show: true,
        enabled: true,
      },
      {
        text: 'Sidebar.Reports.Driver_refers_Driver',
        name: 'Driver refers drivers',
        key: 'Reports.Driver_refers_drivers',
        link: '/reports/Driver_refers_drivers',
        active: false,
        show: false,
        enabled: true,
        subShowAll: false,
        sub: [
          {
            text: 'Sidebar.Reports.Driver_refers_driver_Referrer',
            name: 'Driver refers drivers (Referrer)',
            key: 'Reports.Driver_refers_drivers.referrer',
            link: '/reports/Driver_refers_drivers/Referrer',
            active: false,
            show: true,
            enabled: true,
          },
          {
            text: 'Sidebar.Reports.Driver_refers_driver_Referee',
            name: 'Driver refers drivers (Referee)',
            key: 'Reports.Driver_refers_drivers.referee',
            link: '/reports/Driver_refers_drivers/Referee',
            active: false,
            show: true,
            enabled: true,
          },
        ],
      },
      {
        text: 'Sidebar.Reports.Quests',
        name: 'Quests',
        key: 'Reports.quest',
        link: '/reports/quest',
        active: false,
        show: true,
        enabled: true,
      },
      {
        text: 'Sidebar.Reports.redeemed',
        name: 'Code #',
        key: 'Reports.redeemed',
        link: '/reports/redeemed',
        active: false,
        show: true,
        enabled: true,
      }
    ],
  },
  {
    icon: setting,
    icon_active: setting_active,
    text: 'Sidebar.Setting',
    name: 'SupplierSetting',
    link: '/supplier',
    active: false,
    show: false,
    enabled: true,
    key: 'SupplierSetting',
  },
  {
    icon: setting,
    icon_active: setting_active,
    // Trường hợp fleet bật hydra và Stripe connect thì dùng icon khác
    customeIcon: true,
    icon_stripe: setting_stripe,
    icon_active_Stripe: setting_active_stripe,
    text: 'Sidebar.Setting',
    name: 'Settings',
    link: '/settings/Fleet_info',
    active: false,
    show: false,
    enabled: true,
    key: 'Settings',
    sub: [
      {
        text: 'Sidebar.Settings.Fleet_info',
        name: 'Fleet info',
        key: 'Settings.Fleet_info',
        link: '/settings/Fleet_info',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Stripe_connect',
        name: 'Stripe Connect',
        key: 'Settings.Stripe_connect',
        link: '/settings/Stripe_connect',
        iconAfter: iconAfter,
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Permission',
        name: 'Permission',
        key: 'Settings.Permission',
        link: '/settings/Permission',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.User',
        name: 'User',
        key: 'Settings.User',
        link: '/settings/User',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.General',
        name: 'General',
        key: 'Settings.General',
        link: '/settings/General',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Dynamic_Surcharge',
        name: 'Dynamic surcharge',
        key: 'Settings.Dynamic_surcharge',
        link: '/settings/Dynamic_surcharge',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Dynamic_Fare',
        name: 'Dynamic fare',
        key: 'Settings.Dynamic_fare',
        link: '/settings/Dynamic_fare',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Rate_Templates',
        name: 'Rate',
        key: 'Settings.Rate_Template',
        link: '/settings/rate_template',
        isTemplateNewRate: true,
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Supplier_Plan',
        name: 'Rate',
        key: 'Settings.Supplier_plan',
        link: '/settings/supplier_plan',
        // isTemplateNewRate: true,
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Surchage_Policy',
        name: 'Rate',
        key: 'Settings.Surcharge_policy',
        link: '/settings/surcharge_policy',
        isTemplateNewRate: true,
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Penalty_Policy_Customer',
        name: 'Rate',
        key: 'Settings.Penalty_Policy_Customer',
        link: '/settings/penalty_policy_customer',
        isTemplateNewRate: true,
        active: true,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Penalty_Policy_Supplier',
        name: 'Rate',
        key: 'Settings.Penalty_Policy_Supplier',
        link: '/settings/penalty_policy_supplier',
        isTemplateNewRate: true,
        active: true,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Car',
        name: 'Car',
        key: 'Settings.Car',
        link: '/settings/Car/Car_mgmt',
        active: false,
        show: false,
        enabled: true,
        sub: [
          {
            text: 'Sidebar.Settings.Car_mgmt',
            name: 'Car mgmt',
            key: 'Settings.Car.Car_mgmt',
            link: '/settings/Car/Car_mgmt',
            active: false,
            show: false,
            enabled: true,
          },
          {
            text: 'Sidebar.Settings.Car_type',
            name: 'Car type',
            key: 'Settings.Car.Car_type',
            link: '/settings/Car/Car_type',
            active: false,
            show: false,
            enabled: true,
          },
          {
            text: 'Sidebar.Settings.Car_Make',
            name: 'Car Make',
            key: 'Settings.Car.Car_Make',
            link: '/settings/Car/Car_Make',
            active: false,
            show: false,
            enabled: true,
          },
          {
            text: 'Sidebar.Settings.Car_Color',
            name: 'Car Color',
            key: 'Settings.Car.Car_Color',
            link: '/settings/Car/Car_Color',
            active: false,
            show: false,
            enabled: true,
          },
        ],
      },
      {
        text: 'Sidebar.Settings.RateNew',
        name: 'Rate',
        key: 'Settings.Rate',
        link: '/settings/rate/regular-rate',
        active: false,
        show: false,
        enabled: true,
        isNewRate: true,
        sub: [
          /* 6 */
          {
            text: 'Sidebar.Settings.Regular',
            name: 'Rate',
            key: 'settings.rate.regular-rate',
            link: '/settings/rate/regular-rate',
            active: false,
            show: false,
            enabled: true,
          },
          /* 8 */ {
            text: 'Sidebar.Settings.Flat',
            name: 'Rate',
            key: 'Settings.Rate.Flat-rate',
            link: '/settings/rate/flat-rate',
            active: false,
            show: false,
            enabled: true,
          },
          {
            text: 'Sidebar.Settings.Hourly',
            name: 'Rate',
            key: 'Settings.Rate.Hourly-rate',
            link: '/settings/rate/hourly-rate',
            active: false,
            show: false,
            enabled: true,
          },
          {
            text: 'Sidebar.Settings.Sharing',
            name: 'Street-sharing rate',
            key: 'Settings.Rate.Sharing',
            link: '/settings/rate/street_sharing',
            active: false,
            show: false,
            enabled: true,
          },
          {
            text: 'Sidebar.Settings.Intercity_Rate',
            name: 'Intercity Rate',
            key: 'Settings.Rate.Intercity',
            link: '/settings/rate/intercity',
            active: false,
            show: false,
            enabled: true,
          },
        ],
      },
      {
        text: 'Sidebar.Settings.Zone',
        name: 'Zone',
        key: 'Settings.Zone',
        link: '/settings/Zone/FlatZone',
        active: false,
        show: false,
        enabled: true,
        sub: [
          {
            text: 'Sidebar.Settings.FlatZone',
            name: 'Zone',
            key: 'Settings.Zone.FlatZone',
            link: '/settings/Zone/FlatZone',
            active: false,
            show: false,
            enabled: true,
          },
          {
            text: 'Sidebar.Settings.IntercityZone',
            name: 'Intercity Zone',
            key: 'Settings.Zone.IntercityZone',
            link: '/settings/Zone/IntercityZone',
            active: false,
            show: false,
            enabled: true,
          },
        ],
      },
      {
        text: 'Sidebar.Settings.PWA',
        name: 'PWA',
        key: 'Settings.PWA',
        link: '/settings/PWA',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Reports.Promotion',
        name: 'Promotions',
        key: 'Settings.Promotion',
        link: '/settings/Promotion/Campaign',
        active: false,
        show: false,
        enabled: true,
        sub: [
          {
            /* 17 */
            text: 'Sidebar.Settings.Campaign',
            name: 'Campaign',
            key: 'Settings.Promotion.Campaign',
            link: '/settings/Promotion/Campaign',
            active: false,
            show: false,
            enabled: true,
          },
          {
            /* 16 */
            text: 'Sidebar.Settings.Promotion_code',
            name: 'Promotion code',
            key: 'Settings.Promotion.Promotion_code',
            link: '/settings/Promotion/Promotion_code',
            active: false,
            show: false,
            enabled: true,
          },
        ],
      },
      {
        text: 'Sidebar.Settings.Voucher_code',
        name: 'Voucher code',
        key: 'Settings.Voucher_code',
        link: '/settings/Voucher_code',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Quest',
        name: 'Quest',
        key: 'Settings.Quest',
        link: '/settings/Quest',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Point_config',
        name: 'Point config',
        key: 'Settings.Point_config',
        link: '/settings/Point_config',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Referral',
        name: 'Referral',
        key: 'Settings.Referral',
        link: '/settings/Referral/Driver_refers_customer',
        active: false,
        show: false,
        enabled: true,
        sub: [
          {
            text: 'Sidebar.Settings.Driver_refers_customer',
            name: 'Driver to pax',
            key: 'Settings.Referral.Driver_refers_customer',
            link: '/settings/Referral/Driver_refers_customer',
            active: false,
            show: false,
            enabled: true,
          },
          {
            text: 'Sidebar.Settings.Customer_refers_customer',
            name: 'Pax refers Pax',
            key: 'Settings.Referral.Customer_refers_customer',
            link: '/settings/Referral/Customer_refers_customer',
            active: false,
            show: false,
            enabled: true,
          },
          {
            text: 'Sidebar.Settings.Driver_refer_driver',
            name: 'Driver to pax',
            key: 'Settings.Referral.Driver_refer_driver',
            link: '/settings/Referral/Driver_refer_driver',
            active: false,
            show: false,
            enabled: true,
          },
        ],
      },
      {
        text: 'Sidebar.Settings.Partner_type',
        name: 'Partner type',
        key: 'Settings.Partner_type',
        link: '/settings/Partner_type',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Company',
        name: 'Company',
        key: 'Settings.Company',
        link: '/settings/Company',
        active: false,
        show: false,
        enabled: true,
        tooltipContent: 'Sidebar.Settings.companyTooltip',
      },
      {
        text: 'Sidebar.Settings.City',
        name: 'City',
        key: 'Settings.City',
        link: '/settings/City',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.3rd_party_location',
        name: '3rd party location',
        key: 'Settings.3rd_party_location',
        link: '/settings/3rd_party_location',
        active: false,
        show: false,
        enabled: true,
        moduleSettingField: 'partyLocation',
        sub: [
          {
            text: 'Sidebar.Settings.3rd_party_location',
            name: '3rd party location',
            key: 'Settings.3rd_party_location',
            link: '/settings/3rd_party_location',
            active: false,
            show: false,
            enabled: true,
          },
          {
            text: 'Sidebar.Settings.category',
            name: 'category',
            key: 'Settings.3rd_party_location.category',
            link: '/settings/3rd_party_location/category',
            active: false,
            show: false,
            enabled: true,
          },
        ],
      },
      {
        text: 'Sidebar.Settings.App_banner',
        name: 'App banner',
        key: 'Settings.App_banner',
        link: '/settings/App_banner',
        moduleSettingField: 'addBannerAds',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.driver_app',
        name: 'Driver App',
        key: 'Settings.driver_app',
        link: '/settings/Driver_app',
        active: false,
        show: false,
        enabled: true,
        sub: [
          {
            text: 'Sidebar.Settings.Jobs_tab',
            name: 'Driver App',
            key: 'Settings.driver_app.jobs-list',
            link: '/settings/Driver_app',
            active: false,
            show: false,
            enabled: true,
          }
        ],
      },
      {
        text: 'Sidebar.Settings.App_banner_pegasus',
        name: 'App banner pegasus',
        key: 'Settings.App_banner_pegasus',
        link: '/settings/App_banner_pegasus',
        // moduleSettingField: 'appBannerPegasus',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Shift',
        name: 'Shift',
        key: 'Settings.Shift',
        link: '/settings/Shift/Shift_settings',
        active: false,
        show: false,
        enabled: true,
        sub: [
          /* 13 */
          {
            text: 'Sidebar.Settings.Shift_settings',
            name: 'Shift settings',
            key: 'Settings.Shift.Shift_settings',
            link: '/settings/Shift/Shift_settings',
            active: false,
            show: false,
            enabled: true,
          },
          /* 14 */ {
            text: 'Sidebar.Settings.Shift_template',
            name: 'Shift template',
            key: 'Settings.Shift.Shift_template',
            link: '/settings/Shift/Shift_template',
            active: false,
            show: false,
            enabled: true,
          },
        ],
      },
      {
        text: 'Sidebar.Settings.Operation',
        name: 'Operation',
        key: 'Settings.Operation',
        link: '/settings/Operation',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Driver_Note',
        name: 'Driver Note',
        key: 'Settings.Driver_Note',
        link: '/settings/Driver_note',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Driver_document',
        name: 'Driver document',
        key: 'Settings.Driver_document',
        link: '/settings/Driver_document',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Driver_fields',
        name: 'Driver fields',
        key: 'Settings.Driver_fields',
        link: '/settings/Driver_fields',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Dispatch',
        name: 'Dispatch',
        key: 'Settings.Dispatch',
        link: '/settings/Dispatch',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Voice_SMS',
        name: 'Voice & SMS',
        key: 'Settings.Voice_SMS',
        link: '/settings/Voice_SMS',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Referral_code',
        name: 'Referral code',
        key: 'Settings.Referral_code',
        link: '/settings/Referral_code',
        active: false,
        show: false,
        enabled: false,
      },
      {
        text: 'Sidebar.Settings.Flight_integration',
        name: 'Flight integration',
        key: 'Settings.Flight_integration',
        link: '/settings/Flight_integration',
        active: false,
        show: false,
        enabled: true,
        moduleSettingField: 'flightAPIIntegration',
      },
      {
        text: 'Sidebar.Settings.API_Management',
        name: 'API Management',
        key: 'Settings.API_Management',
        link: '/settings/API_Management',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.SMS_integration',
        name: 'SMS integration',
        key: 'Settings.SMS_integration',
        link: '/settings/SMS_integration',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Invoice_Setting',
        name: 'Invoice Setting',
        key: 'Settings.Invoice_Setting',
        link: '/settings/Invoice_Setting',
        active: false,
        show: false,
        enabled: true
      },
      {
        text: 'Sidebar.Settings.third_party_integration',
        name: 'Third Party Integration',
        key: 'Settings.Third_Party_Integration',
        link: '/settings/third_party_integration/booking',
        active: false,
        show: false,
        enabled: true,
        sub: [
          {
            text: 'Sidebar.Settings.bookingCom',
            name: 'Third Party Integration Booking',
            key: 'Settings.third_party_integration.booking',
            link: '/settings/third_party_integration/booking',
            active: false,
            show: false,
            enabled: true,
          },
          {
            text: 'Sidebar.Settings.HolidayTaxis',
            name: 'Third Party Integration HolidayTaxis',
            key: 'Settings.third_party_integration.holidayTaxis',
            link: '/settings/third_party_integration/holidaytaxis',
            active: false,
            show: false,
            enabled: true,
          },
          {
            text: 'Sidebar.Settings.Karhoo',
            name: 'Third Party Integration Karhoo',
            key: 'Settings.third_party_integration.karhoo',
            link: '/settings/third_party_integration/karhoo',
            active: false,
            show: false,
            enabled: true,
          },
          {
            text: 'Sidebar.Settings.Hoppa',
            name: 'Third Party Integration Hoppa',
            key: 'Settings.third_party_integration.hoppa',
            link: '/settings/third_party_integration/hoppa',
            active: false,
            show: false,
            enabled: true,
          },
        ],
      },
      {
        text: 'Sidebar.Settings.Payment_info',
        name: 'Payment info',
        key: 'Settings.Payment_info',
        link: '/settings/payment_info',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Email_config',
        name: 'Email config',
        key: 'Settings.Email_config',
        link: '/settings/email_config',
        active: false,
        show: false,
        enabled: true,
      },
      {
        text: 'Sidebar.Settings.Social',
        name: 'Social',
        key: 'Settings.Social',
        link: '/settings/social',
        active: false,
        show: false,
        enabled: true,
      },
    ],
  },
];
