export const tr = {
    "name": "Turkish",
    "flagCode": "tr",
    "locale": "tr-tr",
    "project": {
        "name": "QUP Yeni CC"
    },
    "Sidebar": {
        "Setting": "Ayarlar",
        "Map": "Harita",
        "Cue": "İşaret",
        "Invoice": "Invoice",
        "Supplier": "Supplier",
        "Vehicle": "Vehicle",
        "Merchant": "Merchant",
        "ImportBookings": "Taşıma rezervasyonlarını içe aktarın",
        "ImportDeliveryBookings": "Teslimat rezervasyonlarını içe aktarın",
        "Trip": "Seyahat",
        "New_booking": "Yeni Rezervasyon",
        "City": "Şehir",
        "Chauffeur": "Taşıma",
        "Delivery": "Teslimat",
        "Shuttle": "Servis",
        "Intercity": "Şehirlerarası",
        "Customer": "Müşteriler",
        "Driver": "Sürücüler",
        "REPORTS": "Raporlar",
        "Corporate": "Kurumsal",
        "mDispatcher": "Ortak",
        "Reservation": "Takvim",
        "withdrawalRequest": "Para çekme talepleri",
        "Driver_Settlement": "Sürücü yerleşimi",
        "Dashboard": "Analitik",
        "SmartData": "Akıllı veriler",
        "Smart_Data": "Akıllı veriler",
        "Campaign_Builder": "Kampanya kurucu",
        "Company_operator": "Şirket operatörü",
        "Queuing_area": "Kuyruk alanı",
        "Withdrawal_Requests": "Para çekme talepleri",
        "pickUp": "Toplamak",
        "dropOff": "Boşa harcamak",
        "SignUpNotifications": "Bildirimleri kaydet",
        "Payout": "Ödeme",
        "Payout_label": "Ödeme",
        "payout": {
            "Pay_To_Driver": "Sürücülere Ödeme",
            "Payout_History_For_Driver": "Ödeme sürücü geçmişi",
            "Pay_To_Merchant": "Tüccarlara Öde",
            "Payout_History_For_Merchant": "Ödeme Tüccar Geçmişi"
        },
        "number_of_records_to_export": "Dışa aktarılacak kayıt sayısı",
        "export": "İhracat",
        "Airline": "Havayolu",
        "driverPayout": {
            "Pay_to_driver": "Sürücülere Ödeme",
            "Payout_history": "Ödeme geçmişi"
        },
        "Reports": {
            "Driver_Referral_history": "Sürücü müşteriyi ifade eder",
            "Driver_refers_Pax": "Sürücüler",
            "Refund": "Refund",
            "Driver_refers_Pax_Customers": "Müşteriler",
            "Driver_refers_Pax_Bookings": "Rezervasyonlar",
            "Driver_refers_customer_Drivers": "Sürücü Müşteri (Sürücüler)",
            "Driver_refers_customer_Customers": "Sürücü Müşteri (Müşteriler)",
            "Driver_refers_customer_Bookings": "Sürücü Müşteri (Rezervasyonlar)",
            "Passenger_refers_Passengers": "Müşteri müşteri",
            "Driver_refers_drivers_Referrer": "Sürücü sürücüleri (yönlendirici) ifade eder",
            "Deletion_request": "Silme isteği",
            "Driver_refers_drivers_Referee": "Sürücü sürücüleri ifade eder (hakem)",
            "Driver_refers_Driver": "Sürücü sürücüyü ifade eder",
            "Driver_refers_driver_Referrer": "Yönlendiren kimse",
            "Driver_refers_driver_Referee": "Hakem",
            "Referral_history": "Tavsiye Geçmişi",
            "Booking_details": "Rezervasyon Ayrıntıları",
            "Financial": "Mali",
            "Financial_driver": "Sürücü",
            "Financial_fleet": "Filo",
            "Financial_company": "Şirket",
            "Financial_Driver": "Finansal (Sürücü)",
            "Financial_Fleet": "Finansal (Filo)",
            "Financial_Company": "Finans (Şirket)",
            "Financial_fleet_profit": "Filo karı",
            "Financial_Company_Tooltip": "Şirket tarafından gruplandırılmış sürücülerin net kazanma raporu",
            "Financial_Driver_ToolTip": "Her sürücünün net kazanma raporu",
            "Financial_Fleet_Profit_ToolTip": "Filo karı",
            "Driver_activity": "Sürücünün etkinliği",
            "Revenue_Summary": "Gelir özeti",
            "Revenue_fleet": "Filo",
            "Revenue_driver": "Sürücü",
            "Revenue_monthly": "Aylık",
            "Revenue_Summary_Fleet": "Gelir Özeti (Filo)",
            "Revenue_Summary_Driver": "Gelir Özeti (Sürücü)",
            "Revenue_Summary_Monthly": "Gelir Özeti (Aylık)",
            "Total_adjustment": "Toplam Ayarlama",
            "Promotion": "Terfi",
            "Incident_Cancellation": "Olay ve İptal",
            "Booking_logs": "Rezervasyon günlükleri",
            "Rating": "Derecelendirme",
            "Thumbs_rating": "Derecelendirme",
            "Stars_rating": "Derecelendirme",
            "Operator_logs": "Operatör günlükleri",
            "Daily": "Günlük",
            "Daily_Driver": "Günlük sürücü",
            "Daily_Car": "Günlük araba",
            "Daily_Driver_Car": "Günlük Sürücü Araba",
            "mDispatcher": "Ortak",
            "Corporate": "Corporate booking",
            "Driver_login_status": "Sürücü Giriş Durumu",
            "Car_activity": "Otomobilin Aktivitesi",
            "Partner": "Ortak",
            "Prepaid_top_up": "Kurumsal ön ödemeli",
            "Affiliation": "Bağlılık",
            "Affiliation_penalty_compensation": "Ceza/tazminat",
            "Affiliation_payout_history": "Yerleşim tarihi",
            "Affiliation_bookings": "Rezervasyonlar",
            "Booking_summary": "Rezervasyon Özeti",
            "Cash_Wallet": "Nakit cüzdan",
            "Credit_Wallet": "Kredi cüzdanı",
            "Driver_wallet": "Sürücü cüzdanı",
            "Driver_Deposit": "Sürücü depozitosu",
            "Driver_cash_wallet": "Nakit cüzdan",
            "Driver_Deposit_old": "Sürücü depozitosu",
            "Document_expiry": "Belge sona erme",
            "Settlement": "Yerleşim",
            "Unapproved_Driver": "Onaylanmamış sürücü",
            "Approved_Driver": "Onaylanmış sürücü",
            "Pay_to_driver": "Sürücüye Ödeme",
            "Settlement_history": "Yerleşim tarihi",
            "Withdrawal_History": "Sürücü Çekilmesi",
            "creditWalletTooltip": "Kredi cüzdanına sürücü depozitoları",
            "Incomplete_payment": "Eksik ödeme",
            "Driver_withdrawal": "Sürücü Çekilmesi",
            "Incident_&_Cancellation": "Olay ve İptal",
            "Passenger_wallet": "Yolcu cüzdanı",
            "Accept_Cancel_rate": "Sürücü performansı",
            "Acceptance/Cancellation_rate": "Sürücü performansı",
            "driverDepositTooltip": "Kredi cüzdanına sürücü depozitoları",
            "Customer_quest": "Arayış",
            "First_will_win": "Birincisi kazanacak",
            "Largest_will_win": "En büyük kazanacak",
            "redeemed": "Kod #",
            "Code_#": "Kod #",
            "Quests": "Arayış",
            "Merchant_Report": "Tüccar",
            "Merchant_Financial_Report": "Mali",
            "Merchant_Cash_Report": "Nakit cüzdan",
            "Merchant_Credit_Report": "Kredi cüzdanı",
            "Deletion_Request": "Silme isteği",
            "Driver_Rating": "Müşteri Fiyatları Sürücü",
            "Passenger_Rating": "Sürücü Oranları Müşteri",
            "Company_settlement": "Şirket Yerleşimi",
            "Export": "İhracat Geçmişi",
            "Third_Party_Booking": "3. Parti Rezervasyonu",
            "BookingDotCom": "Booking.com",
            "Third_Party_Booking_Bookingcom": "Üçüncü taraf rezervasyon (Booking.com)",
            "Third_Party_Booking_HolidayTaxis": "Üçüncü taraf rezervasyon (HolidayTaxis)",
            "Third_Party_Booking_Karhoo": "Üçüncü taraf rezervasyon (karhoo)",
            "Third_Party_Booking_Hoppa": "Third Party Booking (Hoppa)"
        },
        "Settings": {
            "Fleet_info": "Filo Bilgisi",
            "Surchage_Policy": "Surcharge Policy",
            "RateLegacy": "Rate (Legacy)",
            "Driver": "Driver",
            "Merchant": "Merchant",
            "Calendar": "Calendar",
            "Socaial": "Socaial",
            "Quest": "Quest",
            "Referral": "Referral",
            "Social": "Social",
            "Shift": "Shift",
            "Rate_Templates": "Rate Templates",
            "Supplier_Plan": "Supplier Plan",
            "Penalty_Policy": "Penalty Policy",
            "Stripe_connect": "Stripe Connect",
            "stripe_title_connect": "Ödemenizi otomatik olarak almak için şerit bağlantı hesabınızı etkinleştirin.",
            "Payment_info": "Faturalandırma bilgileri",
            "Permission": "İzin",
            "Driver_refer_driver": "Sürücü, sürücüye atıfta bulunur",
            "Show_referal_history": "Sürücü uygulamasında tavsiye geçmişini göster/gizle",
            "companyTooltip": "Tedarikçi Şirketi",
            "User": "Kullanıcı",
            "General": "Genel",
            "Regular": "Düzenli",
            "Sharing": "Sokak paylaşımı",
            "Flat": "Düz",
            "Hourly": "Saatlik - Günlük",
            "Company": "Şirket",
            "Dispatch": "Sevk etmek",
            "Voice_SMS": "Ses ve SMS",
            "Rate": "Oran",
            "RateNew": "Rate",
            "Car": "Araba",
            "Car_type": "Araba tipi",
            "Car_mgmt": "Araba mgmt",
            "Car_Make": "Yap ve Model",
            "Stripe_Connect": "Stripe Connect",
            "Voice_&_SMS": "Ses ve SMS",
            "Street-sharing_rate": "Sokak Paylaşım Oranı",
            "Partner_type": "Ortak tür",
            "Shift_template": "Vardiya Şablonu",
            "Driver_App": "Sürücü uygulaması",
            "Shift_settings": "Vardiya Ayarları",
            "Operation": "Ameliyat",
            "Driver_Note": "Sürücü notu",
            "Promotion_code": "Promosyon kodu",
            "Point_config": "Nokta yapılandırma",
            "Code_#": "Kod #",
            "Voucher_code": "Kod #",
            "Campaign": "Kampanya",
            "Queuing_area": "Kuyruk alanı",
            "Referral_code": "Tavsiye Kodu",
            "City": "Şehir",
            "category": "Kategori",
            "3rd_party_location": "3. Parti Konumu",
            "Zone": "Alan",
            "App_banner": "Uygulama afişi",
            "App_banner_pegasus": "Uygulama Banner (Pegasus)",
            "Flight_integration": "Uçuş entegrasyonu",
            "SMS_integration": "SMS Entegrasyonu",
            "Invoice_Setting": "Fatura",
            "Driver_refers_customer": "Sürücü müşteriyi ifade eder",
            "Pax_refers_Pax": "Müşteri müşteri",
            "Customer_refers_customer": "Müşteri müşteri",
            "Dynamic_Surcharge": "Dinamik ek ücret",
            "Dynamic_Fare": "Dinamik yemek",
            "FlatZone": "Sabit oran",
            "IntercityZone": "Şehirlerarası",
            "Intercity_Zone": "Şehirlerarası",
            "Intercity_Rate": "Şehirlerarası",
            "Car_Color": "Araba rengi",
            "third_party_integration": "3. taraf entegrasyonu",
            "bookingCom": "Booking.com",
            "HolidayTaxis": "Tatil",
            "Karhoo": "Karhoo",
            "Corporate": "Kurumsal",
            "KarhooIntegration": "Karhoo entegrasyonu",
            "holidaytaxis": "Tatil",
            "karhoo": "Karhoo",
            "hoppa": "Hoppa",
            "Hoppa": "Hoppa",
            "HoppaIntegration": "Hoppa Integration",
            "Third_Party_Integration_Hoppa": "3rd party Integration (Hoppa.com)",
            "titleHoppa": "Hoppa API Credentials",
            "noteHoppa": "Communicate with Hoppa’s technical team to provide you with client, and secret key to connect to their system.",
            "titleHoppaWebhook": "Webhook Configuration",
            "noteHoppaWebhook": "Provide the parameters below for Hoppa to configure. These credentials are used to communicate between both platforms. For any issues please contact your tech provider.",
            "Third_Party_Integration_Booking": "3. Parti Entegrasyonu (Booking.com)",
            "Third_Party_Integration_HolidayTaxis": "3. Parti Entegrasyonu (HolidayTaxis)",
            "Third_Party_Integration_Karhoo": "3. Parti Entegrasyonu (karhoo.com)",
            "Third_Party_Integration_Mozio": "3. Parti Entegrasyonu (Mozio.com)",
            "invoiceBranding": "Fatura markası",
            "titleInvoice": "Şirket başlığınızı ve altbilgi bilgilerinizi özelleştirin.",
            "titleheader": "Başlık başlığı",
            "descriptionHeader": "Başlık Açıklaması",
            "placehodelHeader": "Örneğin, e -posta, telefon numarası, adres, vergi numarası vb. Gibi şirket ayrıntıları ekleyin.",
            "titleFooter": "Altbilgi başlığı",
            "placehodelFooter": "Örneğin, banka teli bilgileri veya herhangi bir politika vb.",
            "descriptionFooter": "Altbilgi Açıklama",
            "titleBookingCom": "Booking.com API kimlik bilgileri",
            "noteBookingCom": "Size müşteri sağlamak için Booking.com’un teknik ekibi ve sistemlerine bağlanmak için gizli anahtarla iletişim kurun.",
            "clientKey": "Müşteri anahtarı",
            "placeHolderClientKey": "İstemci Anahtarını Girin",
            "secretKey": "Gizli anahtar",
            "placeHolderSecretKey": "Müşteri sırrını girin",
            "titleBookingComWebhook": "WebHook Yapılandırması",
            "noteBookingComWebhook": "Booking.com'un yapılandırılması için aşağıdaki parametreleri sağlayın.Bu kimlik bilgileri her iki platform arasında iletişim kurmak için kullanılır.Herhangi bir sorun için lütfen teknoloji sağlayıcınızla iletişime geçin.",
            "webhook": "Filo Webhook URL",
            "clientId": "Filo İstemcisi Anahtarı",
            "clientSecret": "Filo Gizli Anahtarı",
            "driver_app": "Sürücü uygulaması",
            "Jobs_tab": "İşler Sekmesi",
            "Email_config": "E -posta Yapılandırması",
            "Driver_fields": "Sürücü alanları",
            "Driver_document": "Sürücü belgesi",
            "Selected": "Seçilmiş",
            "API_Management": "API Yönetimi",
            "Penalty_Policy_Customer": "Customer Penalty Policy",
            "Penalty_Policy_Supplier": "Supplier Penalty Policy"
        }
    },
    "APIKeySetting": {
        "APIKey": "API anahtarları",
        "APIKey_title": "API anahtarınızı başkalarıyla paylaşmayın veya tarayıcıda veya diğer istemci tarafı kodunda açığa çıkarmayın.Hesabınızın güvenliğini korumak için, iyi bir yolculuk, kamuya açık bir şekilde sızdığını tespit ettiğimiz herhangi bir API anahtarını otomatik olarak devre dışı bırakabilir.",
        "newKeyBtn": "Yeni Anahtar Oluştur",
        "updateApplication": "Uygulamayı güncelle",
        "RegisterApp": "Yeni bir başvuru kaydedin",
        "RegisterBtn": "Uygulama Kaydolun",
        "appDescription": "Uygulama Tanımı",
        "appName": "Uygulama Adı",
        "clientKey": "Müşteri anahtarı",
        "secretKey": "Gizli anahtar",
        "hostedEndPoint": "Barındırılan uç noktalar",
        "maxEndpoints": "Maksimum 5 uç noktaya sahip olabilirsiniz",
        "addEndpoint": "Son nokta ekle",
        "updateEndpoint": "Update Bitiş Noktası",
        "ManageWebhooks": "Webhooks'u yönetin",
        "CreateWebhook": "Webhooks Oluştur",
        "subscriptionID": "Abonelik kimliği",
        "webhookURL": "Webhook URL'si",
        "signingSecret": "Sekiz İmza",
        "eventsToSend": "Gönderilecek Etkinlikler",
        "listeningFor": "Dinleme",
        "assignedDriver": "Atanan sürücü",
        "bookingStatus": "Rezervasyon Durumu",
        "driverLocation": "Sürücü Konumu",
        "titleSigningSecret": "Bu sır partneriniz tarafından sağlanır.Herhangi bir nedenle sırrı tehlikeye atılmışsa, sırrı güncelleyebilir veya web kancasını silebilirsiniz.",
        "notUsingHTTPS": "WebHook URL'si HTTPS kullanmıyor.",
        "invalidURL": "WebHook URL'si geçersiz.",
        "clientType": "Müşteri türü",
        "individual": "Bireysel",
        "corporate": "Kurumsal",
        "signingSecretLength": "Signing secret must be at least 30 characters long.",
        "bookingCreated": "Booking Created",
        "partner": "Partner",
        "fleetManager": "Fleet Manager"
    },
    "messages": {
        "credits": {
            "406": "Eylem başarısız oldu",
            "407": "Eylem başarısız oldu",
            "416": "Kupon kullanıldı",
            "417": "Hesabınız aktif değil.Lütfen yöneticinizle iletişime geçin",
            "418": "Ödeme yaparken hatalar vardı",
            "419": "Promosyon kodu geçersiz!",
            "420": "Bu promosyon kodu başka bir sürüş için uygulandı.Lütfen başka bir tane kullanın.",
            "421": "Kart numarası geçerli değil.",
            "422": "Süresi dolmuş tarih geçerli değil.",
            "429": "Posta kodu geçerli değil.",
            "430": "AVS posta kodu eşleşmiyor",
            "431": "AVS Posta Kodu doğrulanmadı",
            "432": "CVV geçerli değil.",
            "433": "CVV eşleşmiyor",
            "434": "CVV doğrulanmadı",
            "435": "CVV düzenleyicisi katılmıyor",
            "436": "Kartınız reddedildi",
            "437": "Kredi kartı doğrulaması başarısız oldu.Lütfen bilgilerinizi kontrol edin ve tekrar deneyin.",
            "439": "CVV işlenmedi",
            "441": "İhraççıdan CVV verisi yok",
            "443": "İhraççı/Banknet Switch'ten veri yok",
            "445": "AVS Sistemi İşleyemiyor",
            "446": "Banka ihraç etmek AV'leri desteklemez",
            "447": "AV'ler işletmeniz için desteklenmiyor",
            "448": "Bu kart, tekrarlanan başarısız ödeme girişimleri nedeniyle engellendi.Lütfen ödeme yönteminizi değiştirin veya daha sonra tekrar deneyin",
            "452": "Kartınız reddedildi.Lütfen telefon numaranızla aynı alanda kayıtlı başka bir kredi kartı girin",
            "453": "Bu ödeme.Gateway desteklenmiyor",
            "454": "AVS adresi eşleşmiyor",
            "455": "AVS hem posta kodu hem de adres eşleşmiyor",
            "458": "Kimlik doğrulama başarısız oldu",
            "459": "Banka kartları desteklemiyor",
            "461": "Bu kart desteklenmiyor",
            "462": "Kart sahibi adı geçersiz",
            "463": "Doğrulama kodu geçersiz",
            "464": "Banka, doğrulama kodunun oluşturulmasını ve gönderilmesini desteklemiyor",
            "470": "Kredi kartı ile rezervasyon oluşturmadan önce lütfen gezgin e -posta adresini girin",
            "474": "Kredi kartı ile rezervasyon oluşturmadan önce lütfen gezgin adını girin",
            "475": "Telefon numarası gerekli",
            "476": "Amount is invalid",
            "524": "Insufficient Funds. The account did not have sufficient funds to cover the transaction amount.",
            "911": "Ödeme yönteminizi doğrulayamıyoruz.Lütfen farklı bir ödeme yöntemi seçin ve tekrar deneyin.",
            "2000": "Onurlandırma",
            "2001": "Yetersiz fonlar.Hesabın işlem tutarını karşılayacak yeterli fonları yoktu.",
            "2002": "Sınır aşıldı",
            "2003": "Kart Sahibinin faaliyet sınırı aştı",
            "2004": "Süresi dolmuş kart",
            "2005": "Geçersiz kredi kartı numarası",
            "2006": "Geçersiz son kullanma tarihi",
            "2007": "Hesap yok",
            "2008": "Kart hesabı uzunluğu hatası",
            "2009": "Böyle bir ihraççı yok",
            "2010": "Kart ihraççısı CVV'yi reddetti",
            "2011": "Ses yetkisi gerekli.Kart sahibinin bankası, satıcının bu işlemi tamamlamak için özel bir yetkilendirme kodu almasını talep ediyor.",
            "2012": "İşlemci reddetti - Olası Kayıp Kartı",
            "2013": "İşlemci reddetti - olası çalıntı kart",
            "2014": "İşlemci düştü - şüpheli şüpheli",
            "2015": "İşlem izin verilmiyor",
            "2016": "Yinelenen işlem",
            "2017": "Kart sahibi faturalandırmayı durdurdu",
            "2018": "Kart sahibi tüm faturalandırmayı durdurdu",
            "2019": "Geçersiz İşlem",
            "2020": "İhlal etme",
            "2021": "Güvenlik ihlali",
            "2022": "Rededildi - Güncellenmiş Kart Sahibi Mevcut",
            "2023": "İşlemci bu özelliği desteklemiyor",
            "2024": "Kart Türü Etkin değil",
            "2025": "Ayarlama Hatası - Satıcı",
            "2026": "Geçersiz Satıcı Kimliği",
            "2027": "Ayarlama Hatası - Miktar",
            "2028": "Ayarlama Hatası - Hiyerarşi",
            "2029": "Ayarlama Hatası - Kart",
            "2030": "Ayarlama Hatası - Terminal",
            "2031": "Şifreleme hatası",
            "2032": "Ek ücrete izin verilmez",
            "2033": "Tutarsız veriler",
            "2034": "İşlem yok",
            "2035": "Grup III sürümündeki miktar için kısmi onay",
            "2036": "Yetkilendirme tersine dönemedi",
            "2037": "Zaten tersine döndü",
            "2038": "İşlemci düştü.Müşterinin bankası işlemi kabul etmek istemiyor, daha fazla ayrıntı için bankalarıyla iletişim kurması gerekiyor.",
            "2039": "Geçersiz yetkilendirme kodu",
            "2040": "Geçersiz Mağaza",
            "2041": "Reddedildi - onay için çağrı",
            "2042": "Geçersiz İstemci Kimliği",
            "2043": "Hata - yeniden denemeyin, düzenleyiciyi arayın",
            "2044": "Reddedildi - Çağrı İhraççı",
            "2045": "Geçersiz Satıcı Numarası",
            "2046": "Düştü.Müşterinin bankası işlemi kabul etmek istemiyor, daha fazla ayrıntı için bankalarıyla iletişim kurması gerekiyor.",
            "2047": "İhraççı arayın.Kart alın.Bu hata, kartın kart sahibi tarafından kaybedildiği veya çalındığı bildirildiğini gösterir.",
            "2048": "Geçersiz miktar",
            "2049": "Geçersiz SKU Numarası",
            "2050": "Geçersiz kredi planı",
            "2051": "Kredi kartı numarası ödeme yöntemiyle eşleşmiyor",
            "2052": "Geçersiz Seviye III Satın Alma",
            "2053": "Kayıp veya çalınan kart",
            "2054": "Ters tutar yetkilendirme tutarıyla eşleşmiyor",
            "2055": "Geçersiz İşlem Bölümü Numarası",
            "2056": "İşlem miktarı işlem bölümü sınırını aşıyor",
            "2057": "İhraççı veya kart sahibi karta bir kısıtlama koydu",
            "2058": "Satıcı MasterCard SecureCode etkin değil.",
            "2060": "Adres Doğrulama ve Kart Güvenlik Kodu Başarısız Oldu",
            "2061": "Geçersiz işlem verileri",
            "2062": "Geçersiz vergi tutarı",
            "2064": "Geçersiz para kodu",
            "2078": "Geçersiz güvenli ödeme verileri",
            "2080": "Geçersiz Kullanıcı Kimlik Bilgileri",
            "3000": "İşlemci Ağı Kullanılamıyor - Tekrar deneyin",
            "4001": "Yerleşim düştü",
            "4006": "Yakalama miktarı izin verilen sınırı aştı",
            "81703": "Kredi kartı türü bu satıcı hesabı tarafından kabul edilmez.",
            "81706": "CVV gereklidir",
            "81707": "CVV 3 veya 4 hane olmalıdır.",
            "81709": "Son kullanma tarihi gereklidir.",
            "81710": "Son kullanma tarihi geçersiz.",
            "81714": "Kredi kartı numarası gereklidir.",
            "81715": "Kredi kartı numarası geçersiz.",
            "81716": "Kredi kartı numarası 12-19 basamak olmalıdır.",
            "81717": "Kredi kartı numarası kabul edilen bir test numarası değildir.",
            "81718": "Kredi kartı numarası, aboneliklerle ilişkili olduğunda desteklenmeyen bir kart türüne güncellenemez.",
            "81723": "Kart sahibi adı çok uzun.Maksimum 175 karakter.",
            "81809": "Posta kodu, 9 harfi veya sayı karakter içermeyebilir",
            "81813": "Posta kodu yalnızca harfler, sayılar, boşluklar ve tire içerebilir",
            "91722": "Ödeme yöntemi belirteci gereklidir.",
            "91723": "Güncelleme Mevcut jeton geçersiz",
            "91730": "Doğrulama bu satıcı hesabında desteklenmiyor.",
            "91734": "Kredi kartı türü bu satıcı hesabı tarafından kabul edilmez.",
            "91738": "Belirtilen ödeme yöntemi kredi kartı değildir.",
            "91745": "Kredi kartı güncellemesi için geçersiz paramler.",
            "91826": "Posta kodu geçersiz.Posta kodu, isteğe bağlı olarak bir çizgi veya boşluk ile ayrılmış 5 veya 9 basamaklı bir dize olmalıdır.",
            "-1": "Bu müşteri hesabı devre dışı bırakıldı.Lütfen yöneticimizle iletişime geçin."
        },
        "reBookingMsg": {
            "303": "Seçtiğiniz zaman mevcut saatten daha geç olmalıdır!",
            "304": "Bu rezervasyon bulunamadı",
            "500": "Bazı rezervasyon bilgileri geçerli veya eksik değildir.Lütfen tekrar kontrol edin ve deneyin!"
        },
        "promoMsg": {
            "304": "Promosyon kodu geçerli değil",
            "306": "Geçersiz Promosyon Kodu.Promosyonun diğer bölgelere uygulandığı anlaşılıyor.",
            "413": "Üzgünüm, bu promosyon kodu şu anda mevcut değil",
            "414": "Promosyon kodu geçerli değil",
            "415": "Promosyon kodu geçerli değil",
            "416": "Bu promosyon kodu zaten kullanılmıştır.",
            "419": "Üzgünüm, bu promosyon kodu şu anda mevcut değil",
            "420": "Üzgünüm, sınırlı promosyon kullanımlarınıza ulaştınız",
            "421": "Üzgünüm, bu promosyon kodu yayınlanan kullanım sınırına ulaştı",
            "422": "Bu promosyon kodu hesabınız altında kullanılamaz",
            "423": "Üzgünüm, sınırlı promosyon bütçenize ulaştınız",
            "424": "Geçersiz Promosyon Kodu.Promosyonun yalnızca yönlendirilen müşterilere uygulandığı anlaşılıyor",
            "425": "Bu promosyon kodu günde kullanım sınırına ulaştı.",
            "426": "Günde sınırlı promosyon kullanımlarınıza ulaştınız.",
            "427": "Promosyon kodu uygulanamaz.Bu müşterinin profili eksik.",
            "428": "Bu müşteri aylık promosyon kodunu kullandı.",
            "430": "Yıllık promosyon kodlarınızı kullandınız.",
            "431": "Promosyon kodu bu ödeme yöntemi için kullanılamaz.",
            "432": "Bu ücret tutarı promosyon kodu için uygun değildir.",
            "433": "Promosyon kodu seçilen zamanda kullanılmaya uygun değildir.",
            "434": "Üzgünüm, sınırlı promosyon bütçenize ulaştınız.",
            "phoneInvalid": "Promosyon kodunu uygulamadan önce lütfen yolcu telefon numarasını girin"
        },
        "updateBookingMsg": {
            "303": "Rezervasyonu güncelleyemedi.Lütfen tekrar deneyin!",
            "304": "Bu rezervasyon bulunamadı",
            "pickUpTime": "Seçtiğiniz zaman mevcut saatten daha geç olmalıdır!",
            "pickup": "Bu alanda rezervasyon hizmeti yok",
            "destination": "Bazı rezervasyon bilgileri geçerli veya eksik değildir.Lütfen tekrar kontrol edin ve deneyin!",
            "dispatchType": "Bazı rezervasyon bilgileri geçerli veya eksik değildir.Lütfen tekrar kontrol edin ve deneyin!"
        },
        "sessionExpired": {
            "403": "Hesabınız başka bir cihazdan oturum açtığı veya oturumunuzun süresi dolduğundan oturum açtınız"
        },
        "not_found": {
            "404": "İstediğiniz sayfa bulunamadı"
        },
        "booking": {
            "303": "Seçtiğiniz zaman mevcut saatten daha geç olmalıdır!",
            "304": "Update failed for Booking {0} due to an ongoing action. Please try again later.",
            "305": "Rezervasyon iptal edildi veya Booking.com tarafından tamamlandı;Lütfen sıradan kaldırmak için işi reddedin.",
            "306": "Seçilen araba türüne oran yok.Herhangi bir araba türü için bir oran uygulamak için lütfen ayarlar> araba> araba türüne gidin.",
            "307": "Rezervasyonu güncelleyemez.Lütfen daha sonra tekrar deneyin.",
            "407": "Tip Hızı KO Destek Ekstra Konumu",
            "500": "Bazı rezervasyon bilgileri geçerli veya eksik değildir.Lütfen tekrar kontrol edin ve deneyin!",
            "upgroup_success": "Rezervasyon başarıyla gruplanmamış",
            "Please_Select_driver": "Lütfen Sürücüyü Seçin",
            "assign_driver_vehicle_success": "Driver/Vehicle has been assigned successfully",
            "assign_driver_success": "Driver has been assigned successfully",
            "detach_driver_success": "Rezervasyon atanmamış sürücüler başarılı bir şekilde",
            "detach_vehicle_success": "Vehicle has been detached successfully",
            "Group_success": "Rezervasyon başarıyla gruplandırıldı",
            "change_supplier_success": "Rezervasyon tedarikçileri başarılı bir şekilde değiştirildi",
            "change_supplier_partial": "Kısmi başarı: Devam eden eylemler nedeniyle aşağıdaki geziler (rezervasyon kimlikleri) için toplu atama başarısız oldu: {0}.",
            "detach_driver_partial": "Kısmi başarı: Devam eden eylemler nedeniyle aşağıdaki geziler (rezervasyon kimlikleri) için toplu atama başarısız oldu: {0}.",
            "assign_driver_partial": "Kısmi başarı: Devam eden eylemler nedeniyle aşağıdaki geziler (rezervasyon kimlikleri) için toplu atama başarısız oldu: {0}.",
            "update_dispatch": "Güncelleme ve Dispat",
            "remove_booking_false": "Rezervasyonu kaldıramadı",
            "remove_booking_success": "Rezervasyon başarıyla kaldırıldı",
            "GROUP_NOT_FOUND": "Grup kimliği bulunamadı",
            "NO_SHARING_BOOKINGS": "Yalnızca müşterinin rezervasyonu sürüş paylaşımına izin veriyorsa rezervasyonları gruplandırabilirsiniz.",
            "DIFFERENT_CAR_TYPES": "Grup etmeye çalıştığınız rezervasyonun farklı bir araba tipi var",
            "LIMIT_EXCEEDED_ON_SEATS_OR_LUGGAGE": "Rezervasyon gruplandırılamaz: koltuk veya bagaj sınırı aşıldı.",
            "INVALID_CAR_TYPE": "Sürücü atayamaz, sürücünün araç koltuğunu ve bagaj kapasitesini kontrol edemez.",
            "VALIDATION_ERROR": "Doğrulama hatası",
            "INTERNAL": "İç Sunucu Hatası",
            "fare_gt_zero": "Ücret 0'dan büyük olmalı!",
            "edit_total_fail": "Toplam Değer Düzenle Başarısız!",
            "complete_fail": "Rezervasyon tamamlanamaz!",
            "cancle_fail": "Bu rezervasyonu iptal ederken bir şeyler ters gitti.Lütfen tekrar deneyin!",
            "incident_fail": "Olay Başarısız",
            "phone_invalid": "Lütfen geçerli bir telefon numarası girin!",
            "add_psg_fail": "Yeni yolcu ekleyemiyorum",
            "REMOVE_RECIPIENT_DELIVERED": "Alıcının kaldırılamaması",
            "REMOVE_LAST_ACTIVE_RECIPIENT": "Son etkin alıcıyı kaldırılamıyor",
            "ORDER_HAS_BEEN_FINISHED": "Sipariş zaten bitti",
            "end_booking_leave": "Bu sayfadan ayrılmak rezervasyonunuzu sona erdirecektir.",
            "update_booking_fail": "Rezervasyonu güncelleyemedi",
            "EXTERNAL_ID_EXISTED": "Sistemde zaten harici kimlik var.",
            "area_service_unavailable": "Bu alanda rezervasyon hizmeti yok",
            "pickupSameDestination": "Pick-up adresi hedeften farklı olmalıdır.",
            "time_invalid": "Seçtiğiniz zaman mevcut saatten daha geç olmalıdır!",
            "psg_inactivate": "Bu müşteri hesabı devre dışı bırakıldı.Lütfen Yöneticimizle iletişime geçin",
            "data_incorect": "Bir şeyler ters gitti.Lütfen daha sonra tekrar deneyin.",
            "preAuthorize": "Rezervasyonunuzu garanti etmek için lütfen hesabınızı {0} gerektiğinden kontrol edin",
            "booking_limited": "Üzgünüm, günde maksimum rezervasyon sayısına ulaştınız.Lütfen daha sonra tekrar deneyin.",
            "minimumBookAhead": "Rezervasyon alma süresi, mevcut saatten itibaren en az {0} saat (ler) {1} dakikalar olmalıdır",
            "exDoDiffDo": "Ekstra hedef hedeften farklı olmalıdır.",
            "recurringTimeRange": "Menzil dışında.Yalnızca {0} ay önceden rezervasyon oluşturabilirsiniz.Herhangi bir sorun için lütfen Yönetici ile iletişime geçin.",
            "recipientsLimit": "Maksimum alıcı sayısı {0}.",
            "maximumBookAhead": "Rezervasyon alma süresi, mevcut saatten itibaren {0} gün (ler) 'den daha geç olamaz",
            "Please_select_or_add_a_card": "Lütfen bir kart seçin veya ekleyin!",
            "Unable_to_process_by_fare_zero": "İsteğinizi işleyemiyoruz, lütfen araba türünü veya rezervasyon türünü değiştirin ve tekrar deneyin.Herhangi bir yardıma ihtiyacınız varsa, lütfen destek ekibimizle iletişime geçin",
            "Can_not_connect_to_server": "Sunucuya bağlanılamıyor!",
            "Check_connection_or_contact_system_support": "Yardım için lütfen bağlantınızı kontrol edin veya Sistem Desteği ile iletişim kurun!",
            "Create_new_booking_success": "Yeni bir rezervasyon başarıyla yaratıldı!",
            "Create_new_recurring_booking_success": "Yeni rezervasyon (lar) başarıyla yaratıldı",
            "Update_booking_success": "Bu rezervasyon başarıyla güncellendi!",
            "Booking_drop_of_success": "Rezervasyon Başarılı Başarılı",
            "Cancel_booking_success": "Bu rezervasyon başarıyla iptal edildi!",
            "adjust_fare_booking_success": "Fare has been adjusted successfully!",
            "adjust_fare_booking_fail": "Fare adjustment failed!",
            "Booking_detail_not_found": "Rezervasyon detayı bulunamadı!",
            "Check_promo_error": "Promokod hatasını kontrol edin!",
            "Invalid_format": "Geçersiz Biçim",
            "Flight_not_found": "Uçuş bulunamadı!",
            "Add_new_credit_error": "Yeni kredi hatası ekleyin!",
            "Socket_server_disconnected": "Soket sunucusu bağlantısı kesildi!",
            "Please_reload_page_or_check_your_connection": "Üzgünüm.İsteğiniz işlenemez.Lütfen tekrar deneyin.",
            "request_timeout": "Üzgünüm.Talep Zaman Aşımı.Lütfen tekrar deneyin.",
            "Check_customer_error": "Müşteri hatasını kontrol edin!",
            "Please_select_car_type": "Lütfen Araba Türünü Seçin",
            "DropOff_country": "Bu araba türü için bırakma noktası mevcut değil.Lütfen başka bir araba türü seçin veya rotayı ayarlayın.",
            "Different_Country": "Toplama ve bırakma yerleri 2 farklı ülkede olmalıdır.",
            "Please_enter_destination_for_ridesharing_booking": "Lütfen sürüş paylaşımı rezervasyonu için hedef girin",
            "Extra_detination_must_be_difference_from_destination": "Ekstra hedef mevcut hedeften fark olmalı",
            "Pickup_address_must_be_different_from_destination": "Pick-up adresi hedeften farklı olmalı",
            "Best_price": "En iyi fiyat",
            "You_may_be_charged_extra_fee_if_your_trip_excceds": "Geziniz aşarsa ekstra bir ücret alabilirsiniz",
            "You_may_be_charged_extra_fee_if_your_trip_excceds_with_params": "Geziniz {0} {1}, {2} {3}",
            "Reject_booking_success": "Bu rezervasyon başarıyla reddedildi!",
            "Reject_fail": "Bu rezervasyonu reddederken bir şeyler ters gitti.Lütfen tekrar deneyin!",
            "Please_select_payment_method": "Lütfen ödeme yöntemini seçin",
            "Driver_signed_out_or_deactivated": "Bu sürücü yeni çıktı veya devre dışı bırakıldı",
            "reservation_booking_overlaps": "Üzgünüm, bu rezervasyon rezervasyonu sürücünün kabul ettiği başka biriyle örtüşüyor.",
            "driver_is_not_available": "Üzgünüm, bu sürücü şu anda mevcut değil.",
            "seats_luggage_limit": "Sürücüye rezervasyon atayamaz.Koltuk veya bagaj sınırın üzerindedir.",
            "full_capacity": "Bu rezervasyon sürücüye atanamaz çünkü yolculuk maksimum kapasitesine ulaşmıştır.",
            "marked_failed_ok": "Sipariş başarısız olarak işaretlendi.",
            "warning_markFail_lastRecipient": "Bu rezervasyonun son alıcısıdır.Bu siparişi başarısız olarak işaretlemeye devam ederseniz, bu rezervasyonu tamamlamanız gerekir.",
            "BOOKINGS_LIMIT_EXCEEDED": "Her gruba yalnızca en fazla 5 sipariş atayabilirsiniz",
            "bookingCanceled": "Rezervasyon iptal edildi veya Booking.com tarafından tamamlandı;Lütfen sıradan kaldırmak için işi reddedin."
        },
        "item_no_found": "Öğe bulunamadı",
        "commonMessages": {
            "greater_than_0": "Lütfen 0'dan büyük bir değer girin.",
            "Required_field": "Bu alan gerekli",
            "bankPhone_is_required": "Telefon numarası geçersiz",
            "bankPhone": "Telefon numarası",
            "distance_from_equa_or_smaller": "' Den uzaklıktan daha küçük veya mesafeden daha küçük olmalıdır 'mesafesi",
            "must_be_number": "Lütfen geçerli numarayı girin",
            "equal_or_greater_than_0": "Lütfen 0'dan eşit veya daha büyük bir değer girin.",
            "must_be_integer": "Lütfen tamsayı numarasını girin",
            "invalid_email_format": "Lütfen geçerli e -postayı girin.",
            "greater_or_equa": "Lütfen {0} 'dan büyük veya eşit bir değer girin.",
            "value_must_between": "Lütfen {0} ve {1} arasında bir değer girin.",
            "large_10x_markup_price": "Düzenlenen ücret 10x tahmini ücretin geçmemesi gerekir.",
            "greater_than": "Lütfen {0} 'dan büyük bir değer girin.",
            "less_than": "Lütfen {0} 'dan daha az bir değer girin.",
            "less_or_equal": "Lütfen {0} 'dan daha az veya eşit bir değer girin.",
            "driver_earnings_warning": "Çevrimdışı Ödemeler: Sürücü kazançları toplanan ücreti aşmamalıdır.",
            "limit_amount": "Lütfen sınır tutarından daha az veya eşit bir değer girin",
            "Integer_from_range": "Lütfen {0} - {1} numaralı tam sayı numarasını girin",
            "integer_greater_than_0": "Lütfen 0'dan büyük bir tamsayı numarası girin. (Yüzde 100'den az ve ondalık ayırıcı 1 karakter)",
            "Error_message": "Hata!",
            "callcenter_newbooking_message": "Telefonla Yeni Rezervasyon Talebi <Span Class = 'Bold'> {0} </span>",
            "callcenter_existed_booking_message": "Bir aktif rezervasyon <span class = 'bold'>#{0} </span> telefonla var <span class = 'bold'> {1} </span>",
            "callcenter_close_newbooking_form_warning": "Lütfen yeni müşteri isteği almadan önce mevcut kitabınızı kapatın/tamamlayın",
            "Emergency_SOS_Title": "Acil SOS",
            "Please_enter_a_future_date": "Lütfen gelecek tarihi girin",
            "Card_holder_is_required": "Kart sahibi gereklidir",
            "Card_number_is_required": "Kart numarası gerekli",
            "Expired_date_is_required": "Süresi dolmuş tarih gerekli",
            "Credit_code_is_required": "Lütfen Kredi Kodunu Girin (Son Doğrulama)",
            "Expired_date_is_invalid": "Son kullanma tarihi geçersiz.Lütfen MM/YY formatında son kullanma tarihini girin",
            "cvv_is_required": "CVV gereklidir",
            "City_is_required": "Şehir gereklidir",
            "State_is_required": "Eyalet gereklidir",
            "Address_is_required": "Adres gerekli",
            "Zipcode_is_required": "Posta kodu gerekli",
            "Country_is_required": "Ülke gereklidir",
            "Over_query_limit": "Google API, geçici hata nedeniyle yüklenemez.Lütfen daha sonra tekrar deneyin.",
            "outOfInputQueueOrder": "# Sipariş, kuyruktaki toplam sürücü sayısından daha büyük olamaz.",
            "Same_pickup_destination": "Pick-up adresi hedef adresten farklı olmalıdır.",
            "Max_length": "Maksimum uzunluk {0} karakterlerdir.",
            "choose_at_least_one": "Lütfen en az bir seçenek seçin.",
            "choose_at_least_one_intercity_schedule": "Lütfen program ekleyin veya müşterinin tercih ettikleri zaman istemesine izin verin.",
            "startDate_greater_than_endDate": "Başlangıç ​​tarihi bitiş tarihinden daha büyük"
        },
        "trip": {
            "cancel_trip_success": "Bu gezi başarıyla iptal edildi!",
            "data_incorect": "Hiçbir sürücü atanmadı.Açılır listeden bir sürücü seçin.<br/> Sürücü mevcut değilse, daha sonra atamak için Otomatik Dağıtım'ı seçin.",
            "INTERNAL": "İç Sunucu Hatası.",
            "NOT_FOUND": "Gezi bulunamadı veya geçersiz durum.",
            "SAME_DRIVER": "Atanan mevcut sürücüden farklı olmalıdır.",
            "OVERLAP": "Üzgünüm, bu gezi bu sürücü tarafından kabul edilen başka bir gezi ile örtüşüyor.",
            "CANNOT_ASSIGN": "Bu sürücü geçersizdir (yani sürücü bulunamamıştır, çevrimiçi değildir veya giriş yapmamıştır)."
        },
        "car_mgmt": {
            "Search_car_color": "Araba Rengi Arama",
            "Searching": "Arama"
        },
        "driver_note": {
            "Search_driver_note": "Arama Sürücü Notu",
            "Searching": "Arama"
        }
    },
    "errors": {
        "303": "Kullanıcı bulunamadı",
        "453": "Gateway Desteklenmemiş",
        "553": "Üzgünüm!Müşteri devam eden bir rezervasyon var, lütfen daha sonra tekrar deneyin.",
        "1007": "Başlangıç ​​tarihi ile bitiş tarihi arasındaki süre 60 günden az olmalıdır !!",
        "3001": "Telefon numarası vardı",
        "3002": "Geçersiz doğum tarihi",
        "3003": "Süresi Doldurma Tarihi Geçersiz",
        "3004": "Sürücü bulunamadı",
        "3005": "Eksik Sürücü Yerleşim Bilgisi (Banka Adresi, Şehir, Eyalet, Zipcode)",
        "3006": "Eksik banka hesabı bilgileri (NameOfaccount, TypeOfaccount, RoutingNumber, HesapNumber, SSN)",
        "3007": "Bir işi olan bu sürücüyü düzenleyemezsiniz.",
        "3008": "Bu sürücü şu anda giriş yapıyor. Lütfen bilgilerini düzenlemek için oturum açmasını isteyin.",
        "3009": "Bu sürücü bir veya daha fazla rezervasyon (lar) ı kabul etti.Sürücünün bilgilerini güncellemeden/ değiştirmeden önce lütfen rezervasyon (lar) ı diğer sürücülere atayın.",
        "3010": "Bağlantı gönderimi başarısız oldu.",
        "3011": "Bu sürücü, etkinleştirilmeden önce çalışma için ayarlanmalıdır.",
        "3012": "Bu sürücü, etkinleştirilmeden önce çalışma için ayarlanmalıdır.",
        "3013": "Yalnızca aktif olmayan durumdaki öğe silinebilir.",
        "3014": "Gerekli alanlar eksik.Lütfen kontrol edin.",
        "3015": "Geçersiz doğum yılı.Lütfen kontrol edin.",
        "3016": "Geçersiz posta kodu.Lütfen kontrol edin.",
        "3017": "Geçersiz Devlet/İl.Lütfen kontrol edin.",
        "3018": "Hesapın geçersiz adı.Lütfen kontrol edin.",
        "3019": "Geçersiz yönlendirme numarası.Lütfen kontrol edin.",
        "3020": "Doğum günü gereklidir.Lütfen kontrol edin.",
        "3021": "Geçersiz SSN numarası.Lütfen kontrol edin.",
        "3022": "Sürücü banka hesabı fon alamaz.",
        "3023": "Sürücülerde oturum açılan çalıştırılamaz.Lütfen daha sonra tekrar deneyin.",
        "3026": "Sürücü artık yok.",
        "3027": "Sürücü banka hesabı kurulmamıştır.Banka hesabını ayarlamak için lütfen sürücüler modülüne gidin.",
        "3028": "Seçilen sürücü (ler) i ödeyemedi.Lütfen tekrar deneyin.",
        "3029": "Sürücünün kazanç verilerini temizleyemedi.Lütfen tekrar deneyin.",
        "3032": "Bu kullanıcı adı zaten sistemimizde var.Lütfen başka bir tane girin.",
        "3033": "Geçersiz BSB.XXXXXX biçiminde sayı 6 basamak olmalıdır.",
        "3039": "Grup kimliği gereklidir.",
        "3040": "Bu telefon numarası zaten %{brandname} sürücüsü tarafından kullanılmaktadır (yani telefon numarası kopyalanamaz).Lütfen başka bir tane girin",
        "3041": "Kullanıcı adı vardı.",
        "3044": "Ulusal Kimlik çoğaltılmıştır.",
        "3045": "Driver already has a vehicle, Please refresh page",
        "3046": "The company of the vehicle and the company of the driver are different.",
        "4001": "Plaka numaranız sistemde vardı.Lütfen başka bir tane girin.",
        "4002": "Geçersiz Süresi Dolmuş Tarih",
        "4003": "Silinmeden önce araç devre dışı bırakılmalıdır",
        "4004": "Araç bulunamadı",
        "4005": "Bu araç zaten aktif değil",
        "4006": "Bu araç kullanımda ve silinemiyor veya devre dışı bırakılamıyor",
        "4007": "Plaka numarası gerekli",
        "4009": "The vehicle is being assigned to a booking.",
        "4010": "The vehicle is being assigned to a driver.",
        "5001": "Şirket vardı",
        "5004": "Şirket bulunamadı",
        "6001": "Araç modeli vardı",
        "6004": "Araç modeli bulunamadı",
        "6005": "Araç modeli kullanımda",
        "7001": "Arabanızın adı sistemde var.Lütfen başka bir tane girin.",
        "7004": "Araç Bulunmadı",
        "8001": "Araç tipi vardı",
        "8004": "Araç tipi bulunamadı",
        "9001": "Düz rota vardı",
        "9004": "Düz rota bulunamadı",
        "10001": "Bölge vardı",
        "10004": "Bölge bulunamadı",
        "11001": "Bölge vardı",
        "11002": "Bu alan başka bir alanla örtüşüyor.Lütfen tekrar çizin.",
        "11003": "Bölge bir çokgen nesne olmalı",
        "11004": "Bölge bulunamadı",
        "11005": "Bölge kullanımda",
        "12001": "Gelen kutusu vardı",
        "12002": "Lütfen SMS Twilio'yu Yapılandırın",
        "12004": "Gelen Kutusu bulunamadı",
        "12005": "Filtrelerinizle eşleşen alıcı yoktur.Lütfen listeyi kontrol edin ve tekrar deneyin.",
        "12006": "Dosyada hiçbir müşteri yoktur.",
        "13001": "Bu promosyon kodu sistemde mevcuttu.Lütfen başka bir tane girin",
        "13002": "Geçersiz Tarih",
        "13003": "Özel",
        "13004": "Promosyon kodu bulunamadı",
        "13005": "Sadece yeni müşteri",
        "13006": "Miktar sınırı",
        "13007": "Bütçe Sınırı Üzerine",
        "13008": "Toplam kullanım sınırı üzerinde",
        "13011": "Bu promosyon kampanyası devre dışı bırakıldı veya silindi",
        "13012": "Tarih aralığı geçersiz",
        "13013": "Geçersiz Promosyon Kodu.Promosyonun diğer bölgelere uygulandığı anlaşılıyor",
        "13014": "Seçtiğiniz bölge devre dışı bırakıldı.",
        "14004": "Rezervasyon bulunamadı",
        "15002": "Filonuz devre dışı bırakıldı.Lütfen Yöneticimizle iletişime geçin",
        "15004": "Filo bulunamadı",
        "16001": "Sistemde sabit oranlı adınız mevcuttu.Lütfen başka bir tane girin",
        "16004": "Ücret bulunamadı",
        "17001": "İsim var",
        "17004": "Paket oranı bulunamadı",
        "18001": "İsim var",
        "18002": "Kullanımda Ücret",
        "18004": "Ücret bulunamadı",
        "19001": "Paket adınız sistemde mevcuttu.Lütfen başka bir tane girin.",
        "19002": "Kullanımda Ücret",
        "19004": "Ücret bulunamadı",
        "20001": "Bu kullanıcı adı zaten sistemimizde var.Lütfen başka bir tane girin.",
        "20002": "Hesabınız aktif değil.Lütfen Yöneticimizle iletişime geçin",
        "20003": "Girdiğiniz kullanıcı adı veya şifre hatalı.Lütfen tekrar deneyin",
        "20004": "Kullanıcı adı sistemde kayıtlı değildir.",
        "20005": "Bu bağlantı süresi doldu",
        "20006": "Mevcut şifre eşleşmiyor.Lütfen tekrar deneyin.",
        "20007": "Kullanıcı # var oldu",
        "20008": "This email already exists on our system. Please enter another one.",
        "21001": "İzin adınız sistemde mevcuttu.Lütfen başka bir tane girin.",
        "21002": "Şu anda kullanıcılara atandığı için bu izni devre dışı bırakamaz.",
        "21003": "Rol aktif",
        "21004": "Rol bulunamadı",
        "22001": "Müşteri var",
        "22004": "Müşteri bulunamadı",
        "22005": "Geçersiz dosya biçimi.",
        "24001": "Telefon numarası geçersiz",
        "25001": "Bu isim sistemde mevcuttu.Lütfen başka bir tane girin.",
        "25002": "Geçersiz Zaman Aralığı",
        "25004": "Vardiya bulunamadı",
        "26001": "Bu isim sistemde mevcuttu.Lütfen başka bir tane girin.",
        "26002": "Bu değişim şu anda çalışırken kullanılmaktadır, lütfen silmeden önce atamayı bırakın",
        "26004": "Vardiya Şablonu bulunamadı",
        "27001": "Kampanya var",
        "27002": "Kampanya etkinleştirildi",
        "27004": "Kampanya bulunamadı",
        "28001": "Şehir var",
        "28002": "Şehir etkinleştiriliyor",
        "28003": "Şehir kullanımda",
        "28004": "Şehir bulunamadı",
        "29002": "Telefon numarası var",
        "30001": "Kuyruk alanı bulunamadı",
        "30002": "Kullanımda kuyruk alanı",
        "30003": "Kuyruk alanı adınız sistemde mevcuttu.Lütfen başka bir tane girin",
        "30004": "Kuyruk alanı birbirleriyle örtüşmemelidir",
        "30005": "Kuyruk alanı koordinatları geçersiz",
        "30006": "Pick-up alanları geçersiz",
        "31001": "Şirket kimliğiniz sistemde mevcuttu.Lütfen başka bir tane girin.",
        "31002": "Bu kullanıcı adı zaten sistemimizde var.Lütfen başka bir tane girin.",
        "31003": "Kurumsal bulunamadı.",
        "31004": "Kurumsal kullanıcı bulunamadı.",
        "31005": "Yalnızca aktif olmayan şirketler silinebilir.",
        "31006": "Bu telefon Corp kullanıcılarında mevcut.Lütfen başka bir tane girin.",
        "31007": "Kurumsal gezgin bulunamadı.",
        "31008": "Kart bulunamadı.",
        "31009": "Bu telefon sistemde mevcuttu.Lütfen başka bir tane girin.",
        "31010": "Bu şirket devre dışı bırakıldı.Daha fazla bilgi için lütfen Yönetici ile iletişime geçin",
        "31011": "Bu gezgin etkinleştirilemez çünkü etkin olmayan bir kurumsal hesaba aittir",
        "31013": "Bu isme sahip bir işletme zaten var",
        "31014": "Please assign rate for at least one vehicle type",
        "31015": "This email/phone is already linked to another account.",
        "31016": "This email/phone is linked to another corporate account. Please update the profile or remove the user from the existing account before assigning them to a new one.",
        "31017": "This email/phone is empty. Please enter a valid email/phone.",
        "32001": "Sistemde hizmet adınız mevcuttu.Lütfen başka bir tane girin.",
        "32004": "Hizmet bulunamadı",
        "33002": "Zaman aralıkları birbirleriyle örtüşmemelidir",
        "34001": "Kod var",
        "34013": "Tarih aralığı geçersiz",
        "36001": "İsim var",
        "39001": "The document is required",
        "43001": "Penalty Template existed",
        "43002": "Penalty Template not found",
        "44001": "Rate Template existed",
        "44002": "Rate Template not found",
        "44003": "The Rate Template is currently assigned to a corporate. Please unassign it before deleting.",
        "45001": "Surcharge Policy existed",
        "45002": "Surcharge Policy not found",
        "45003": "Surcharge Item not found",
        "46001": "Name is existed",
        "46002": "Item not found",
        "71001": "Dinamik ek ücret vardı",
        "71002": "Bu alan başka bir alanla örtüşüyor.Lütfen tekrar çizin.",
        "71003": "Dinamik ek ücret, çokgen bir nesne olmalı",
        "71004": "Dinamik ek ücret bulunamadı",
        "72001": "Dinamik ücret vardı",
        "72002": "Bu alan başka bir alanla örtüşüyor.Lütfen tekrar çizin.",
        "72003": "Dinamik ücret bir çokgen nesne olmalı",
        "72004": "Dinamik Ücret bulunamadı",
        "74001": "Bölge vardı",
        "74002": "Bu alan başka bir alanla örtüşüyor.Lütfen tekrar çizin.",
        "74003": "Bölge bir çokgen nesne olmalı",
        "74004": "Bölge ücreti bulunamadı",
        "74005": "Bölge ücreti bulunamadı",
        "80001": "Uygulama afişi vardı.",
        "80002": "Uygulama banner bulunamadı.",
        "80003": "Yalnızca aktif olmayan durumdaki öğe silinebilir.",
        "81001": "Dosya yüklemesi boş.",
        "81002": "Dosya yükleme sınır boyutudur.",
        "82002": "FlightAware'i kurarken bir şeyler ters gitti.Lütfen daha sonra tekrar deneyin.",
        "90001": "Şehirlerarası bölgesi vardı",
        "90002": "Bu alan başka bir alanla örtüşüyor.Lütfen tekrar çizin.",
        "90003": "Şehirlerarası bölgesi bir çokgen nesne olmalı",
        "90004": "Şehirlerarası bölge bulunamadı",
        "90005": "Şehirlerarası bölgede kullanılıyor",
        "91001": "Şehirlerarası oranı var",
        "91002": "Şehirlerarası oranı kullanımda",
        "91003": "Şehirlerarası oranı bulunamadı",
        "92001": "Rota adı var",
        "92002": "Rota bulunamadı",
        "92003": "Bu rota zaten başka bir rotada var, lütfen kontrol edin ve tekrar deneyin",
        "92004": "Bu rotada aktif bir yolculuk var.",
        "93001": "Sistemde sürücü notu vardı.Lütfen başka bir tane girin.",
        "93002": "Sürücü notu aktif olmayan silinebilir.",
        "93003": "Sürücü notu kullanımda.",
        "93004": "Sürücü notu bulunamadı.",
        "94001": "Araba renk adınız sistemde mevcuttu.Lütfen başka bir tane girin.",
        "94002": "Araba rengi bulunamadı",
        "400000": "Telefon numarası geçerli değil!",
        "400002": "A validation error has occurred.",
        "400003": "İzin reddedildi",
        "400005": "Dosya içeriği geçersiz",
        "500000": "Dahili sistem hatası.",
        "500001": "Lütfen SMS Twilio'yu Yapılandırın",
        "500002": "Zaman aşımı isteyin.Lütfen daha sonra tekrar deneyin",
        "600001": "Konum bulunamadı",
        "600002": "Bu boylam ve enlem koordinatları sistemde mevcuttu.Lütfen başka bir tane ekleyin",
        "610001": "Kategori vardı",
        "610002": "Kategori bulunamadı",
        "610003": "Kategori parti yerde vardı",
        "700001": "Tür kullanımda",
        "700002": "Ortak bulunamadı",
        "700003": "İş ortağı türü adınız sistemde mevcuttu.Lütfen başka bir tane girin.",
        "800000": "Bu mağaza başka bir satıcı hesabına atandı.Lütfen başka bir tane seçin.",
        "800001": "Tüccarın banka hesabı fon alamaz.",
        "800002": "Tüccarın banka hesabı fon alamaz.",
        "31016_2": "Message: This email/phone is already linked to another account. Please contact your account manager for assistance.",
        "undefined": "Sunucumuzda bir şeyler ters gitti, lütfen tekrar deneyin.",
        "completeBooking": {
            "113": "Ödeme yönteminizi doğrulayamıyoruz.Lütfen farklı bir ödeme yöntemi seçin ve tekrar deneyin.",
            "450": "The balance is insufficient to pay. Please select another method",
            "451": "The balance is insufficient to pay. Please select another method",
            "521": "Cüzdanın yeterli parası yoktur."
        },
        "upload_file_fail": "Geçersiz dosya, lütfen kontrol edin ve tekrar deneyin.",
        "upload_photo_fail": "Geçersiz dosya, lütfen kontrol edin ve tekrar deneyin.",
        "missing_some_field": "Geçersiz alan, lütfen kontrol edin ve tekrar deneyin.",
        "validAddress": "Please select a valid address."
    },
    "statusDisplay": {
        "supplierAccepted": "Supplier accepted",
        "delivery_supplierAccepted": "Supplier accepted",
        "completedWithoutService": "Sürücü olmadan tamamlandı",
        "confirmed": "Onaylanmış rezervasyon",
        "pending": "Askıda olması",
        "queue": "Gönderme",
        "offered": "Gönderme",
        "booked": "Yolda Sürücü",
        "engaged": "P.O.B",
        "droppedOff": "Düştü",
        "pre": "Kabul Edildi Sipariş",
        "recurrent": "Tekrarlayan",
        "vacant": "",
        "vacantTime": "",
        "arrived": "Geldi ve Bekleme",
        "completed": "Tamamlanmış",
        "canceled": "İptal edilmiş",
        "noShow": "Gösteri Yok",
        "action": "Süreci Beklemek",
        "incident": "Olay",
        "pickupTimeExceeded": "Zaman aşımı",
        "canceledByCC": "CC tarafından iptal edildi",
        "canceledByPassenger": "Yolcu tarafından iptal edildi",
        "canceledByDriver": "Sürücü tarafından iptal edildi",
        "canceledByPartner": "Ortak tarafından iptal edildi",
        "canceledBymDispatcher": "Ortak tarafından iptal edildi",
        "canceledByCorporateAdmin": "Kurumsal yönetici tarafından iptal edildi",
        "canceledByAPI": "API tarafından iptal edildi",
        "canceledByWebBooking": "Web rezervasyonu ile iptal edildi",
        "canceledByTimeout": "Zaman aşımı ile iptal edildi",
        "accepted": "Kabul edilmiş",
        "rejected": "Reddedilmiş",
        "canceledByCorpAd": "Kurumsal yönetici tarafından iptal edildi",
        "started": "Başlayan",
        "intransit": "Transit olarak",
        "empty": "Boş",
        "notLive": "Kısmi - Canlı Değil",
        "live": "Kısmi - Canlı",
        "full": "Tam dolu",
        "delivering": "Teslimat",
        "delivery_booked": "Gönderen yolunda sürücü",
        "delivery_arrived": "Geldi ve gönderen için bekliyorum",
        "shopForYou_booked": "Nakit toplama yolunda sürücü",
        "shopForYou_arrived": "Geldi ve nakit toplamak için bekliyor",
        "collecting": "Mal toplama yolunda sürücü",
        "confirmed_delivery": "Onaylanmış",
        "food_booked": "Tüccar yolunda sürücü",
        "food_collecting": "Geldi ve eşyayı toplamak için bekliyor",
        "mart_collecting": "Geldi ve eşyayı toplamak için bekliyor",
        "otwMerchant": "Tüccar yolunda sürücü",
        "arrivedAndWaitingToCollectItem": "Geldi ve eşyayı toplamak için bekliyor",
        "canceledByAirlineAdmin": "Havayolu Yöneticisi tarafından iptal edildi",
        "partialCompleted": "Kısmi tamamlandı",
        "failed": "Arızalı",
        "allocated": "Tahsis edilmiş"
    },
    "tripTypes": {
        "scheduled": "Planlanan Geziler",
        "requested": "Geziler isteyin",
        "preferred": "Tercih edilen"
    },
    "login": {
        "Username": "Kullanıcı adı",
        "Password": "Şifre",
        "Remember_me": "Beni Hatırla",
        "Forgot_your_password": "Şifrenizi unuttum",
        "Login": "Giriş yapmak"
    },
    "invoice": {
        "createBtn": "Fatura Oluştur",
        "searchPlInvoice": "İstemci, iletişim, fatura numarası arama",
        "searchPl": "BookID'de arama, müşteri adı",
        "createInvoice": "Fatura Oluştur",
        "invoicePeriod": "Fatura dönemi",
        "invoicePeriodNote": "Aydan aya tarih aralıklarını seçebilirsiniz.",
        "viewEdit": "Görüntüle/Düzenle",
        "billingContact": "Faturalandırma kontağı",
        "memo": "Hafıza",
        "memoMessage": "Müşteriye mesaj bırakın",
        "dueDate": "Bitiş tarihi",
        "updateInvoice": "Faturayı güncelle",
        "chargeCustomer": "Müşteriye",
        "invoiceNumber": "Fatura numarası",
        "noItem": "Hayır. Öğeler",
        "issuedDate": "Verilen tarih",
        "refId": "Ref #",
        "pickupTime": "Zaman al",
        "pickup": "Konumu Al",
        "destination": "Bırakma Konumu",
        "customerName": "Müşteri Adı",
        "totalAmount": "Total Amount",
        "amountDue": "Ödenmesi gereken miktar",
        "sendInvoice": "Fatura Gönder",
        "copyInvoice": "Faturayı kopyala",
        "downloadPDF": "PDF olarak indirin",
        "paymentLink": "Ödeme bağlantısı",
        "deleteInvoice": "Faturayı sil",
        "onlySave": "Fatura Kaydet",
        "saveSend": "Faturayı Kaydet ve Gönder",
        "sendEmail": "E -posta Gönder",
        "addRecipients": "Alıcılar ekle",
        "editInvoice": "Faturayı düzenle",
        "check": "Kontrol etmek",
        "wire": "Tel",
        "digitalWallet": "Dijital cüzdan",
        "cash": "Peşin",
        "completedTime": "Tamamlanmış zaman",
        "Status": "Durum",
        "bookingStatus": "Rezervasyon Durumu",
        "chargeBtn": "Şarj {0}",
        "pastDue": "Geçmiş",
        "payCompleted": "Bu fatura başarıyla ödendi!",
        "Delete": "Faturayı Sil?",
        "Delete_content": "Bu eylem bu faturayı kalıcı olarak kaldıracaktır.Gerekirse, bu sipariş (ler) için yeni bir tane oluşturabilirsiniz.",
        "viewInvoice": "Faturayı Görüntüle",
        "pending": "Askıda olması",
        "paid": "Paralı",
        "CopyLink": "Kopyala bağlantısı",
        "partial": "Partial",
        "corporatePrepaid": "Corporate Prepaid"
    },
    "cue": {
        "bookingServiceType": "Service type",
        "pointToPoint": "Point To Point",
        "fromAirport": "From Airport",
        "toAirport": "To Airport",
        "hourly": "Hourly",
        "roundTrip": "Round Trip",
        "jobVisibility": "Job Visibility",
        "jobPubic": "Public",
        "jobPrivate": "Private",
        "jobSetPubic": "Set Public",
        "jobSetPrivate": "Set Private",
        "supplierAccepted": "Supplier accepted",
        "creationTime": "Yaratılış Süresi",
        "Pax": "PAX #",
        "Luggage": "Bagaj",
        "allocated": "Tahsis edilmiş",
        "completedWithoutService": "Sürücü olmadan tamamlandı",
        "shuttle": "Servis",
        "farmIn": "Çiftçilik yapmak",
        "chargeCode": "Ücret Kodu",
        "clientCaseMatter": "Gezi Açıklama",
        "operatorNote": "İç notlar",
        "farmOut": "Çiftçilik yapmak",
        "roaming": "Roaming",
        "FlightUpdate": "Uçuş güncellemesi",
        "networkType": "Ağ türü",
        "InHouse": "Kurum içi",
        "carHailing": "Araba",
        "uploadBookings": "Rezervasyonlar Yükle",
        "moreActions": "Daha Fazla Eylemler",
        "rideSharing": "Sürüş paylaşımı",
        "Rearrange": "Yeniden düzenlemek",
        "GroupId": "Grup Kimliği #",
        "Active": "Aktif",
        "Finished": "Bitmiş",
        "Home_affiliate": "Ev / iştirak",
        "Booking_type": "Rezervasyon türü",
        "From": "İtibaren",
        "balanceWallet": "{0}: {1} kalan",
        "partialWallet": "Cüzdan ile kısmi ödeme",
        "To": "İle",
        "Status": "Durum",
        "Migration_Status": "Göç statüsü",
        "Migrated": "göç etti",
        "Select_Status": "Durumu seçin",
        "Operator": "Operatör",
        "Car_types": "Araba türleri",
        "Service_class": "Service class",
        "Booking_from": "Rezervasyon",
        "All": "Tüm",
        "Pending": "Askıda olması",
        "Dispatching": "Gönderme",
        "Waiting_for_process": "Süreci Beklemek",
        "Confirmed_reservation": "Onaylanmış rezervasyon",
        "Driver_on_the_way": "Yolda Sürücü",
        "Arrived_and_waiting": "Geldi ve Bekleme",
        "Passenger_on_board": "Gemide Yolcu",
        "Dropped_off": "Düştü",
        "combobox_select": "Seçme...",
        "Search_here": "BURADA ARAYIN",
        "Bookingid": "Rezervasyon #",
        "Pickup_Drop_Off": "Pikap/ Bırak",
        "Pickup_Drop_Off_Crew": "Pikap/bırakma zamanı",
        "Pickup_Drop_Off_Local_Time": "Operatör süresi",
        "Pickup_Location_Destination": "Pikap Konumu/ Hedef",
        "Passenger": "Yolcu",
        "Intercity_Route": "Şehirlerarası yol",
        "Airport_Pickup": "Havaalanı toplama",
        "Airport_Pickup_Crew": "Havalimanı",
        "Flight_Status": "Durum",
        "Payment": "Ödeme",
        "Vehicle": "Araç",
        "Car_type": "Araba tipi",
        "Driver": "Sürücü",
        "Estimated_Fare": "Tahmini ücret",
        "Notes": "Notalar",
        "Note": "Not",
        "Type": "Tip",
        "Home": "Ev",
        "Affiliate": "Bağlı kuruluş",
        "now": "Şimdi (ulaşım)",
        "reservation": "Rezervasyon (ulaşım)",
        "recurring": "Tekrarlayan (ulaşım)",
        "No_show": "Gösteri Yok",
        "Canceled_by_CC": "CC tarafından iptal edildi",
        "Canceled_by_passenger": "Yolcu tarafından iptal edildi",
        "Canceled_by_partner": "Ortak tarafından iptal edildi",
        "Canceled_by_mDispatcher": "Ortak tarafından iptal edildi",
        "Canceled_by_Corporate_Admin": "Kurumsal yönetici tarafından iptal edildi",
        "Canceled_by_API": "API tarafından iptal edildi",
        "canceled_By_Web_Booking": "Web rezervasyonu ile iptal edildi",
        "canceled_By_Time_Out": "Zaman aşımı ile iptal edildi",
        "Canceled": "İptal edilmiş",
        "Completed": "Tamamlanmış",
        "Incident": "Olay",
        "Payment_Method": "Ödeme yöntemi",
        "No_Phone": "Telefon Yok",
        "VIP": "vip",
        "Non_VIP": "VIP olmayan",
        "Passenger_type": "Yolcu türü",
        "Error_load_booking": "Rezervasyonu yükleyemiyorum, lütfen tekrar deneyin veya sayfa yenileyin!",
        "Edit_total_success": "Toplam başarıyla düzenlendi!",
        "Edit_total_fail": "Toplam ücret {0} 'dan eşit veya daha büyük olmalıdır",
        "Time_out": "Zaman aşımı",
        "Ride_Service": "Sürüş hizmeti",
        "Regulation": "Düzenleme",
        "Ride_Sharing": "Sürüş paylaşımı",
        "Unmatching": "Eşsiz",
        "Matching": "Eşleşen",
        "all": "Tüm",
        "matching": "Eşleşen",
        "unmatching": "Eşsiz",
        "regulation": "Düzenleme",
        "Support_service": "Destek hizmeti",
        "SOS": "s.o.s.",
        "Non_SOS": "Sos olmayan",
        "Corporate": "Kurumsal",
        "Individual": "Bireysel",
        "On_curb": "Kaldırımla",
        "Meet_Greet": "Buluş ve selam",
        "Close": "Kapalı",
        "auto_refrest_cue": "Auto-Refresh ({0} saniye)",
        "Ride_type": "Sürüş tipi",
        "Edit_total_note": "Not: Toplam tutarı değiştirirken subtotal, teknoloji ücreti, rezervasyon ücreti, vergi ve ipucu güncellenecek",
        "Booking": "Rezervasyon",
        "Edit_fare": "Ücreti Düzenle",
        "Adjust_price": "Fiyatı Ayarlayın",
        "Total": "Toplam",
        "Reason": "Sebep",
        "Please_enter_reason": "Lütfen Sebep girin.",
        "Save": "Kaydetmek",
        "Cancel": "İptal etmek",
        "dmc": "Rezervasyon kurulu",
        "corp": "Rezervasyon Kurulu (CTM)",
        "Zone": "Pikap bölgesi",
        "mDispatcher": "Ortak",
        "streetSharing": "Sokak paylaşımı",
        "Driver_No_Job_found": "Ödevleri bekliyorum ...",
        "expandFilter": "Filtreyi Göster",
        "collapseFilter": "Filtreyi gizle",
        "clearSelectedItems": "Seçilen öğeleri temizleyin",
        "Accepted": "Kabul edilmiş",
        "Yes": "Evet",
        "No": "HAYIR",
        "Order_accepted": "Kabul Edildi Sipariş",
        "Confirmed": "Onaylanmış",
        "spotTime": "Spot zamanı",
        "ETA": "ETA",
        "spareTime": "Boş zaman",
        "expectedTime": "Beklenen Toplama Süresi",
        "Driver_on_the_way_to_sender": "Gönderen yolunda sürücü",
        "Driver_on_the_way_to_recipient": "Alıcı yolunda sürücü",
        "Driver_on_the_way_to_collect_cash": "Nakit toplama yolunda sürücü",
        "Driver_on_the_way_to_collect_goods": "Mal toplama yolunda sürücü",
        "Arrived_waiting_for_sender": "Geldi ve gönderen için bekliyorum",
        "Arrived_waiting_for_recipient": "Geldi ve alıcı için bekliyor",
        "Arrived_waiting_to_collect_cash": "Geldi ve nakit toplamak için bekliyor",
        "Arrived_waiting_to_deliver_goods": "Geldi ve mal teslim etmek için bekliyor",
        "Delivered": "Teslimat",
        "delivery": "Teslimat",
        "Service": "Hizmet vermek",
        "intercity": "Şehirlerarası",
        "Transport": "Taşıma",
        "About_Sender": "Gönderen hakkında",
        "About_Merchant": "Tüccar Hakkında",
        "About_Recipient": "Alıcı hakkında",
        "photos": "Fotoğraf",
        "listOfItems": "Öğenin Listesi",
        "cashOnPickUp": "POLİS",
        "Package_information": "Paket bilgileri",
        "otwMerchant": "Tüccar yolunda sürücü",
        "arrivedAndWaitingToCollectItem": "Geldi ve eşyayı toplamak için bekliyor",
        "Save_Filter": "Filtreyi Kaydet",
        "add_favorite_filter": "Filtre ekle",
        "favorite_filter_name": "İsim",
        "list_favorite_filter": "Filtreleri Yönetin",
        "favorite_filter_actions": "Aksiyon",
        "clear_all_filters": "Tüm filtreleri temizle",
        "Delete_Filter": "Filtreyi sil",
        "CONFIRM_DELETE_FILTER_TEMPLATE": "Bu filtreyi silmek istediğinizden emin misiniz?",
        "batchDelivery": "Toplu teslimat",
        "editFare": {
            "creditTransactionFee": "Kredi Kartı Ücretleri",
            "preferredAdjustment": "Tercih edilen ayarlama",
            "editFareFee": "Ücret ve Ücretleri Düzenle",
            "markupPrice": "İşaretleme fiyatı",
            "quotedPrice": "Alıntı Fiyatı",
            "basicFare": "Temel Ücret",
            "preferredEarning": "Tercih Edilen Kazanma",
            "difference": "Fark",
            "rushHourFee": "Fazla yüklemek",
            "serviceFee": "Hizmet ekle",
            "otherFees": "Diğer Ücretler",
            "subTotal": "Alt Toplam",
            "airportFee": "Havaalanı ücreti",
            "meetDriverFee": "Şoförle tanışmak",
            "deliveryFee": "Teslimat ücreti",
            "tollFee": "Ücret ücreti",
            "parkingFee": "Otopark ücreti",
            "gasFee": "Gaz ücreti",
            "techFee": "Teknoloji ücreti",
            "tip": "Uç",
            "bookingFee": "Rezervasyon ücreti",
            "tax": "Vergi",
            "promoAmount": "Promosyon",
            "etaFare": "Toplam",
            "fleetService": "Filo hizmeti",
            "Note_serviceFee": "(Ek hizmeti düzenlemek için dış listeyi ziyaret edin)",
            "startingFee": "Starting",
            "moveFeeInJourney": "Moving",
            "waitFeeInJourney": "Waiting (in-journey)",
            "total": "Total",
            "totalPayout": "Payout",
            "updatedTotal": "Updated Total",
            "updatedPayout": "Updated Payout",
            "profit": "Profit",
            "extraWaitFee": "Waiting fee Initial",
            "heavyTraffic": "Heavy traffic"
        },
        "partialCompleted": "Kısmi tamamlandı",
        "failed": "Arızalı",
        "Recurring": "Tekrarlayan #",
        "DragAndDrop": "Sütunları yeniden sıralamak için sürükleyin ve bırakın.",
        "bookingCom": "Booking.com",
        "syncingCompleted": "Senkronizasyon tamamlandı",
        "groupManifest": "Grup tezahürü",
        "assignSuppliers": "Tedarikçiler atayın",
        "viewActions": "Eylemleri Görüntüle",
        "holidaytaxis": "Tatil",
        "karhoo": "Karhoo",
        "pickupTime": "Toplama süresi",
        "AssignSupplier": "Tedarikçi atayın",
        "sharedTransfer": "Paylaşılan transfer (%{id})",
        "batchDeliveryGroup": "Toplu Teslimat (%{id})",
        "foundersFund": "Kurucular Fonu",
        "zeroCommission": "Sıfır Komisyonu",
        "unassignDriver": "Atama sürücü",
        "notUnassignDriver": "Atama sürücüsünü atamıyorum, lütfen tekrar deneyin",
        "notAssignDriver": "Sürücü atayamıyorum, lütfen tekrar deneyin",
        "selectDriver": "Sürücü seçin",
        "unassignVehicle": "Unassign Vehicle",
        "unassignAll": "Unassign All",
        "notUnassignAll": "Cannot unassign all, Please try again",
        "unassignAllSuccess": "The vehicle and driver have been successfully detached.",
        "unassign_all_partial": "Partial Success: Batch unassignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
        "notUnassignVehicle": "Cannot unassign vehicle, Please try again",
        "notAssignVehicle": "Cannot assign vehicle, Please try again",
        "selectVehicle": "Select Vehicle",
        "confirmUnassign": "Are you sure you want to unassign?"
    },
    "trip": {
        "Driver_vehicle": "Sürücü aracı",
        "tripId": "Seyahat #",
        "tripTypes": "Gezi Türleri",
        "Start_Time": "Başlangıç ​​Zamanı",
        "Seats_Status": "Koltuk Durumu",
        "No_Of_Booking": "Rezervasyon Sayısı",
        "Vehicle": "Sürücü aracı",
        "Estimated_Fare": "Ücret",
        "capacityStatus": "Koltuk durumu",
        "Trip_Types": "Gezi Türleri",
        "scheduled": "Planlanan Geziler",
        "requested": "Geziler isteyin",
        "preferred": "Tercih edilen",
        "All": "Tüm",
        "Confirmed": "Onaylanmış",
        "Started": "Başlayan",
        "In_Transit": "Transit olarak",
        "Empty": "Boş",
        "Partial_not_live": "Kısmi - Canlı Değil",
        "Partial_live": "Kısmi - Canlı",
        "Full": "Tam dolu",
        "Error_load_trip": "Geziyi yükleyemiyorum, lütfen tekrar deneyin veya sayfa yenileyin!"
    },
    "tripDetails": {
        "Trip_ID": "Şehirlerarası gezi",
        "DRIVER_JOB": "Sürücü bilgileri",
        "BOOKINGS": "Rezervasyonlar",
        "updateTrip": "Güncelleme gezisi",
        "cancelTrip": "Geziyi İptal Et",
        "note_cancel": "Lütfen bu geziyi iptal etmeden önce tüm rezervasyonları başka bir sürücüye atayın.",
        "TRIP_INFORMATION": "Gezi Bilgisi",
        "route": "Rota",
        "startTime": "Başlangıç ​​Zamanı",
        "distance": "Mesafe",
        "duration": "Süre",
        "fare": "Ücret",
        "cartype": "Şehirlerarası kartip",
        "seatStatus": "Koltuk durumu",
        "started": "Başlayan",
        "intransit": "Transit olarak",
        "empty": "Boş",
        "notLive": "Kısmi - Canlı Değil",
        "live": "Kısmi - Canlı",
        "full": "Tam dolu",
        "noOfBooking": "Rezervasyon Sayısı",
        "luggage": "Bagaj",
        "nameDriver": "İsim",
        "vehicleType": "Araç",
        "assignDriver": "Sürücü atayın",
        "pickUpAddress": "Toplamak",
        "destinationAddress": "Boşa harcamak",
        "customerName": "İsim",
        "customerPhone": "Telefon",
        "customerNumber": "Koltuklar",
        "customerLuggageNumber": "Bagaj",
        "additionalService": "Ek Hizmetler",
        "paymentBooking": "Ödeme",
        "note": "Not",
        "CONFIRM_UPDATE": "Güncellemeyi onaylayın",
        "CONFIRM_UPDATE_MESSAGE": "Bu geziyi güncellemek istediğinizden emin misiniz?",
        "Yes": "Evet",
        "No": "HAYIR",
        "Update_info": "Evet, Bilgi Güncelle"
    },
    "newbooking": {
        "Search_Vehicle": "Search for vehicle",
        "rideSharing_multi_point": "Sürüş paylaşımı rezervasyonu için sadece 1 pick-up ve 1 bırakma noktasına izin verilir.",
        "Pick_up_time": "Zaman al",
        "Drop_off_time": "Dereceli Zaman",
        "All_Suppliers": "Tüm Tedarikçiler",
        "Recipient": "Alıcı",
        "All": "Tüm",
        "New_Booking": "Yeni Rezervasyon",
        "GoogleMapRoute": "Google Haritası",
        "BOOKING_INFORMATION": "Rezervasyon Bilgileri",
        "TIP_PROMO": "İpucu - Promosyon",
        "Pick_up": "Toplamak",
        "instructions": "Talimatlar",
        "instructions_placeholder": "Talimatlarınızı buradan listeleyin ...",
        "Enter_a_location": "Bir konum girin",
        "Enter_a_location_delivery": "Bir konum girin",
        "Enter_3rd_party_location": "3. taraf konumuna girin",
        "Enter_a_sender": "Bir gönderen girin",
        "Enter_a_recipient": "Bir Alıcı Girin",
        "Vehicle_Selection": "Araç seçimi",
        "Destination": "Varış noktası",
        "Pickup_time": "Zaman al",
        "Note": "Not",
        "note_for_driver": "Sürücü için not",
        "affiliate_note": "Satış Ortağı için Not",
        "affiliate_note_placeholder": "Operatörler için ortaklık notları girin",
        "operator_note": "Operatörler için dahili notlar girin",
        "TRAVELER_INFORMATION": "Gezgin bilgisi",
        "Traveler_type": "Gezgin türü",
        "Individual": "Bireysel",
        "Corporation": "Şirket",
        "Phone_number": "Telefon numarası",
        "First_name": "İlk adı",
        "Last_name": "Soy isim",
        "Email_address": "E -posta adresi",
        "Corp_id": "Corp ID",
        "Manager_name": "Yöneticinin Adı",
        "Manager_email": "Yöneticinin e -postası",
        "Cost_centre": "Maliyet merkezi",
        "Department": "Departman",
        "Corp_division": "Corp bölümü",
        "Tip": "Uç",
        "Promo_code": "Promosyon kodu",
        "Apply": "Uygula",
        "Promo": "Promosyon:",
        "DISPATCH": "Sevk etmek",
        "Auto_dispatch": "Otomatik Dağıtım",
        "Assign_driver": "Sürücü atayın",
        "FarmOut": "Çiftlik",
        "Driver": "Sürücü",
        "Driver_Supplier": "Supplier/Driver",
        "PAYMENT_METHOD": "Ödeme yöntemi",
        "OPERATOR_NOTE": "İç notlar",
        "TRIP_ESTIMATE": "Gezi Tahmini",
        "Choose_a_stored_card": "Saklanan bir kart seçin",
        "Choose_a_payment_method": "Bir Ödeme Yöntemi Seçin",
        "Use_new_card": "Yeni kart kullanın",
        "Card_holder": "Kart sahibi",
        "Card_number": "Kart numarası",
        "Expired_date": "Süresi Doldurma Tarihi (mm/yy)",
        "ccv": "CVV",
        "Distance": "Mesafe",
        "Duration": "Süre",
        "Fare": "Ücret",
        "Route": "Rota",
        "Create": "Yaratmak",
        "Cancel": "İptal etmek",
        "Meet_driver": "Şoförle tanışmak",
        "Airline": "Havayolu",
        "Flight": "Uçuş #",
        "FLY_INFO": "Uçuş bilgisi",
        "Ride_Sharing": "Sürüş paylaşımı",
        "Round_trip": "Gidiş",
        "Car_type": "Araba tipi",
        "Address": "Adres",
        "City": "Şehir",
        "State": "Durum",
        "Zip_code": "Posta kodu",
        "Remove": "Kaldırmak",
        "Client_case_matter": "Müşteri Örneği Madde / Gezi Açıklama",
        "Charge_code": "Ücret Kodu / Gezi Kodu",
        "CANCEL_BOOKING": "Yakın Rezervasyon Onayı",
        "CANCEL_BOOKING_CONFIRM": "Bu formdan ayrılmak ister misin?Tüm rezervasyon verilerini kaybedeceksiniz.",
        "Search_here": "BURADA ARAYIN",
        "No_support_location": "Ne yazık ki, hizmet bu alanda mevcut değil",
        "Closest_driver_message": "En yakın sürücü yaklaşık {0} uzakta",
        "No_cars_available": "Dağıtım yarıçapında araba yok.",
        "processed_by_affiliate": "Talepleriniz bağlı kuruluş ortaklarımız tarafından işlenecek",
        "FlightExample": "Uçuş #, örneğin AA1097",
        "Yes": "Evet",
        "No": "HAYIR",
        "dispatch_3rd": "3. taraf aracılığıyla gönder",
        "Company": "Şirket",
        "Card_required": "Lütfen bir kart seçin veya ekleyin!",
        "Tip_value": "N/A",
        "Calendar": "Takvim",
        "Extra_Destination": "Ek Hedef",
        "Driver_credit_limited_balance_warning": "Bu sürücünün bakiyesi sınırlı miktarda veya sınırlı miktarda",
        "Meet_Greet_option": "Buluş ve selam",
        "On_Curb_option": "Kaldırımla",
        "Extra_Services": "Ek Hizmetler",
        "Addl_Services": "Ek Hizmetler",
        "Save": "Kaydetmek",
        "Regular": "Düzenli",
        "Please_fill_in_all_the_required_fields": "Lütfen gerekli tüm alanları doldurun.",
        "Route_names_not_dup": "Route names must not be duplicated.",
        "Please_select_weekdays": "Lütfen bu rezervasyonun olmasını istediğiniz günlerde tekrar seçin",
        "Flat_rate": "Sabit oran",
        "All_company": "Tüm Şirket",
        "Best_price": "En iyi fiyat",
        "PromoTitle": "Promosyon",
        "Show_more": "Daha Fazla Göster",
        "Warning_pending_payment": "Bu müşteri geçmiş rezervasyonlardan eksik ödeme var: %{fare}",
        "Warning_outstanding_balance": "Gezinin sonunda ödenmemiş bakiye uygulanacaktır.",
        "Not_available": "Müsait değil",
        "Warning_assigned_rate": "Devam etmek için lütfen seçilen araba türü için oranı ayarlayın ve atayın.",
        "PaxLuggageNumber": "Yolcu/bagaj numarası",
        "paxNumber": "Yolcu",
        "luggageNumber": "Bagaj",
        "Date": "Tarih",
        "Single": "Bekar",
        "Recurring": "Tekrarlayan",
        "Date_range": "Özelleştirilmiş Günler",
        "WeekDays": "Günlerde tekrarlayın",
        "Create_Route": "Rota Oluştur",
        "Pickup_Point": "Toplamak",
        "Drag_To_Render": "Yeniden Sırıştırma",
        "DropOff_Point": "Puanları bırak",
        "Sender": "Gönderen kimse",
        "Recipients": "Alıcılar",
        "Sender_Infomation": "Gönderen bilgileri",
        "Recipient_Infomation": "Alıcı bilgileri",
        "Recipient_Name": "Alıcı adı *",
        "Recipient_Phone": "Telefon numarası *",
        "Room_floor_block": "Oda, zemin ve blok (isteğe bağlı)",
        "Details": "Detaylar",
        "Package_Content": "Paket İçeriği *",
        "Order_ID": "Sipariş # (Örn. Shopify Sipariş #)",
        "Instructions_for_driver": "Sürücü için talimatlar",
        "Cost_of_Goods": "Teslimatta Nakit (COD)",
        "Enter_amount": "Miktar Gir",
        "Additional_Information": "Ek Bilgiler",
        "Insurance": "Sigorta",
        "Special_Care": "Özel bakım",
        "Delivery_Fee": "Teslimat ücreti",
        "Item_fees": "Ürün Ücretleri",
        "Tech_Fee": "Teknoloji ücreti",
        "Tax": "Vergi",
        "driverNotes": "Sürücü Notları",
        "rejectModal": "Rezervasyonu Reddet",
        "rejectModalBody": "Bu rezervasyonu reddetmek istediğinizden emin misiniz?",
        "rejectButton": "Rezervasyonu Reddet",
        "acceptModal": "Rezervasyonu kabul et",
        "acceptModalBody": "Bu rezervasyonu kabul etmek istediğinizden emin misiniz?",
        "ConfirmedTo": "({0}) teyit edildi",
        "proceedAction": "Bu eyleme devam etmek istediğinden emin misiniz?",
        "acceptButton": "Rezervasyonu kabul et",
        "denyModal": "Rezervasyon",
        "denyModalBody": "Bu rezervasyonu reddetmek istediğinizden emin misiniz?",
        "denyButton": "Rezervasyon",
        "Accept_booking_success": "Bu rezervasyon başarıyla kabul edildi!",
        "Deny_booking_success": "Bu rezervasyon başarıyla reddedildi!",
        "adjustFare": "Ücret ayarlayın",
        "totalFare": "Toplam yemek",
        "Profit": "Kâr",
        "DriverEarning": "Sürücü kazanma",
        "Payout": "Payout",
        "totalPayout": "Total payout",
        "DriverPayOut": "Sürücü ödemesi",
        "confirmReject": "Reddetmeyi onaylayın",
        "confirmCancelMSG": "Bu rezervasyonu reddetmek istediğinizden emin misiniz?Sağlayıcınızdan bir ceza alabilirsiniz.",
        "reasonCancel3rd": "Reddetme Nedeni",
        "welcome_psg": "Hoşgeldin Yolcu",
        "AmountDue": "Ödenmesi gereken miktar",
        "Fleet_Company": "Filo/Şirket",
        "Search_driver_hydra": "Yakında bir sürücü bulmaya çalışacağız.",
        "ExternalID": "Harici kimlik",
        "viewRate": "Görünüm Oranı",
        "PointToPoint": "Noktadan noktaya",
        "Offer_driver": "Sürücü teklif et",
        "Assign_driver_vehicle": "Assign Driver / Vehicle",
        "notify_via_email": "Notify Customer via Email",
        "fleetFare": "Fleet Fare",
        "onlySave": "Save",
        "saveCharge": "Save & Charge",
        "saveRefund": "Save & Refund",
        "lateCancelConfirm": "Are you sure you want to complete this booking as",
        "canceledDriverConfirm": "Are you sure you want to cancel this booking for the driver? A penalty fee will be applied.",
        "canceledDriverNoChargeConfirm": "Are you sure you want to cancel this booking for the driver",
        "NoShowConfirm": "No show policy will be applied.",
        "completeConfirm": "Are you sure you want to Complete without driver?",
        "completeChargeConfirm": "Full charge will be applied to customer. Driver will not receive any commission from this booking.",
        "PricingPlan": "Pricing Plan",
        "editFerviceFees": "to edit service fees, visit outer list",
        "ungroupAll": "Ungroup All",
        "satisfaction": "satisfaction",
        "Reassign": "Reassign",
        "addNote": "Add note",
        "noteMagicSend": "Select the magic wand or type [ to instantly access booking keywords suggestions.",
        "items": "Items",
        "PaidAmount": "Paid Amount",
        "ExternalPartner": "External Partner",
        "ExternalPartnerNote": " Independent partners who manage their own drivers and vehicles. Payouts are handled directly with the supplier.",
        "InHousePartner": "In-house Partner",
        "InHousePartnerNote": " Directly managed by your fleet. Payouts are made directly to the drivers.",
        "Role": "Role",
        "Client": "Client",
        "ApplyCancellation": "Apply cancellation policy",
        "unassigned": "unassigned",
        "bookingSelected": "booking selected",
        "Update": "Update",
        "emailInvoice": "Email Invoice",
        "Receipt": "Receipt",
        "addItem": "Add Item"
    },
    "bookingDetailLogs": {
        "recipient": "Recipient",
        "updatedInfo": "Updated info",
        "updatedField": "Updated field",
        "valueBefore": "Value before",
        "valueAfter": "Value after",
        "location": "Location",
        "etaFare": "Fare",
        "dispatchType": "Dispatch",
        "drvInfo": "Driver",
        "vehicleTypeRequest": "Vehicle Request",
        "clientCaseMatter": "Trip description",
        "chargeCode": "Trip code",
        "driverEarningType": "Driver Earning Type",
        "editedDriverEarning": "Driver earning",
        "reasonEditFare": "Reason",
        "bookingReference": "External ID",
        "tip": "Tip",
        "type": "Service type",
        "promo": "Promo",
        "dispatch3rd": "Dispatch 3rd",
        "rideSharing": "Ride-Sharing",
        "notes": "Driver notes",
        "operatorNote": "Internal notes",
        "welcomeMessage": "Welcome Message",
        "flightNumber": "Flight Number",
        "fullName": "Full Name",
        "email": "Email",
        "cardMask": "Card mask",
        "paymentType": "Payment method",
        "recipients": "Recipients",
        "sender": "Sender",
        "pickupName": "Pickup Name",
        "pickupPhone": "Pickup Phone",
        "pickupEmail": "Pickup Email",
        "supplierEarningType": "Payout type",
        "editedSupplierEarning": "Payout value",
        "route": "Route name",
        "markupDifference": "Markup Difference",
        "selectedDates": "Selected Dates",
        "services": "Fleet services",
        "destinationAddress": "Destination location",
        "pickupAddress": "Pick-up location",
        "tripType": "Trip Type",
        "pickUpTime": "Pickup time",
        "paxNumber": "Passenger",
        "luggageNumber": "Luggage",
        "vehicle": "Vehicle",
        "minutesToArrive": "Expected driver arrival",
        "affiliateNote": "Affiliate Note",
        "packageRateName": "Package name",
        "supplierCompanies": "Company",
        "puPoints": "Pick-up location",
        "doPoints": "Destination location",
        "promoValue": "Promo value",
        "packageInfo": "Package info",
        "orderId": "Order Id",
        "cashOnDelivery": "Cash on delivery",
        "externalId": "External Id",
        "recurring": "Recurring",
        "batchDelivery": "Batch Delivery",
        "payout": "Payout",
        "locationDetails": "Location Details",
        "name": "Name",
        "phone": "Phone",
        "address": "Address",
        "basicFare": "Basic fare",
        "airportFee": "Airport fee",
        "bookingFee": "Booking fee",
        "creditTransactionFee": "Credit card fees",
        "fleetService": "Fleet service",
        "fleetServices": "Fleet services",
        "gasFee": "Gas fee",
        "meetDriverFee": "Meet driver fee",
        "moveFeeInJourney": "Moving fee",
        "otherFees": "Other fee",
        "parkingFee": "Parking fee",
        "promoAmount": "Promo amt",
        "rushHourFee": "Rush hour fee",
        "serviceFee": "Service fee",
        "startingFee": "Starting fee",
        "subTotal": "Sub total",
        "tax": "Tax",
        "techFee": "Tech fee",
        "tollFee": "Toll fee",
        "waitFeeInJourney": "Waiting (in-journey) fee",
        "psgPhone": "Passenger phone"
    },
    "bookingdetail": {
        "settings": "Settings",
        "updateSuccessJobVisibilitySingle": "Update visibility successfully",
        "updateSuccessJobVisibilityMulti": "%{number} bookings has been updated successfully",
        "failApi": "Something went wrong!",
        "settingsTooltip": "Driver already assigned or trip is currently in progress.",
        "shareJobPublicly": "Share Job Publicly",
        "groupOder": "Grup siparişi",
        "ExistingGroup": "Mevcut Grup",
        "NewGroup": "Yeni grup",
        "Search_trip": "Arama gezisi #",
        "All": "Tüm",
        "creditTransactionFee": "Kredi Kartı Ücretleri",
        "partial_payment": "Kısmi ödeme",
        "Write_off_debt": "Borç yaz",
        "Booking_detail": "Rezervasyon",
        "Order_detail": "Emir",
        "Completed_Booking": "Tamamlanan rezervasyon",
        "Late_Cancel": "Geç İptal",
        "Cancel_Driver": "Sürücüye göre iptal et",
        "No_Show": "Gösteri Yok",
        "Without_driver": "Sürücü olmadan tamamlayın",
        "Complete_driver": "Sürücü ile tamamlayın",
        "BOOKING_INFORMATION": "Rezervasyon bilgileri",
        "TIP_PROMO": "İpucu - Promosyon",
        "Rearrange_Column": "Sütunu yeniden düzenleyin",
        "Pick_up": "Toplamak",
        "Enter_a_location": "Bir konum girin",
        "Destination": "Varış noktası",
        "Pickup_time": "Zaman al",
        "Notes": "Notalar",
        "TRAVELER_INFORMATION": "Gezgin bilgileri",
        "Traveler_type": "Gezgin türü",
        "Individual": "Bireysel",
        "Corporation": "Şirket",
        "Phone_number": "Telefon numarası",
        "First_name": "İlk adı",
        "Last_name": "Soy isim",
        "Email_address": "E -posta adresi",
        "Corp_id": "Corp ID",
        "Manager_name": "Yöneticinin Adı",
        "Manager_email": "Yöneticinin e -postası",
        "Cost_centre": "Maliyet merkezi",
        "Department": "Departman",
        "Corp_division": "Corp bölümü",
        "Tip": "Uç",
        "Promo_code": "Promosyon kodu",
        "Apply": "Uygula",
        "Promo": "Promosyon",
        "DISPATCH": "Sevk etmek",
        "Auto_dispatch": "Otomatik Dağıtım",
        "Assign_driver": "Sürücü atayın",
        "Driver": "Sürücü",
        "PAYMENT_METHOD": "Ödeme yöntemi",
        "Payment_methods": "Ödeme yöntemleri",
        "pending_payment": "Bekleyen ödeme",
        "TRIP_ESTIMATE": "Gezi Tahmini",
        "Choose_a_stored_card": "Saklanan bir kart seçin",
        "Choose_a_payment_method": "Bir Ödeme Yöntemi Seçin",
        "OPERATOR_NOTE": "İç notlar",
        "Use_new_card": "Yeni kart kullanın",
        "Card_holder": "Kart sahibi",
        "Card_number": "Kart numarası",
        "Expired_date": "Süresi Doldurma Tarihi (mm/yy)",
        "ccv": "CVV",
        "Distance": "Mesafe",
        "Duration": "Süre",
        "Fare": "Ücret",
        "Route": "Rota",
        "Meet_driver": "Şoförle tanışmak",
        "Airline": "Havayolu",
        "Flight": "Uçuş #",
        "FLY_INFO": "Uçuş bilgisi",
        "Ride_Sharing": "Sürüş paylaşımı",
        "Round_trip": "Gidiş",
        "Car_type": "Araba tipi",
        "Address": "Adres",
        "City": "Şehir",
        "State": "Durum",
        "Zip_code": "Posta kodu",
        "Remove": "Kaldırmak",
        "New_Booking": "Yeni Rezervasyon",
        "Clone_Booking": "Clone Booking",
        "New_Order": "Yeni Sipariş",
        "ViewTrackLink": "Parça Bağlantısını Görüntüle",
        "driverLink": "Driver link",
        "ClickCopy": "Click to copy link",
        "ViewLog": "Dağıtım Günlüklerini Görüntüle",
        "Message": "Message",
        "Update_Booking": "Güncelleme Rezervasyonu",
        "Cancel_Booking": "Rezervasyonu İptal Et",
        "Complete_with_payment": "Ödeme ile tamamlayın",
        "Incident": "Olay",
        "CONFIRM_CANCEL": "iptal et",
        "CONFIRM_CANCEL_MESSAGE": "Bu rezervasyonu iptal etmek istediğinizden emin misiniz?",
        "CONFIRM_UPDATE": "Güncellemeyi onaylayın",
        "CONFIRM_UPDATE_MESSAGE": "Bu rezervasyonu güncellemek istediğinizden emin misiniz?",
        "CONFIRM_RESET_EDIT_FARE_MESSAGE": "Düzenlenen ücretiniz sıfırlandı, lütfen devam etmeden önce inceleyin.",
        "Close": "Kapalı",
        "Update_info": "Evet, Bilgi Güncelle",
        "Update_info_dispatch": "Evet, Bilgi ve Dispatch'i güncelle",
        "Update_info_tooltip": "Bu rezervasyonu yeni bir sürücüye yeniden atayacaksanız, lütfen \"Evet, Bilgi Güncelle ve Dispatch\" düğmesini seçin.",
        "BOOKING": "Rezervasyon",
        "DROPPED_OFF": "Düştü",
        "Payment_Type": "Ödeme türü",
        "Total": "Toplam",
        "Next": "Sonraki",
        "Pay_here": "burada ödeyin",
        "Complete_booking": "Tam Rezervasyon",
        "Complete_booking_tooltip": "Devam etmeden önce değişikliklerinizi kaydetmek için lütfen \"Rezervasyon Güncelleme\" yi tıklayın.",
        "Cancel": "İptal etmek",
        "Back": "Geri",
        "Tax": "Vergi",
        "Booking_fees": "Rezervasyon Ücretleri",
        "Tech_fees": "Teknoloji ücretleri",
        "Subtotal": "Alt kat",
        "Other_fees": "Diğer Ücretler",
        "Waiting_fee": "Bekleme ücreti",
        "Toll_fees": "Ücret ücretleri",
        "Heavy_traffic": "Yoğun trafik",
        "Rush_hour": "Fazla yüklemek",
        "Basic_fare": "Temel Ücret",
        "Ok": "TAMAM",
        "Input_reason": "Giriş Sebebi",
        "Client_case_matter": "Müşteri Örneği Madde / Gezi Açıklama",
        "Charge_code": "Ücret Kodu / Gezi Kodu",
        "Enter_3rd_party_location": "3. taraf konumuna girin",
        "Company": "Şirket",
        "Check_promo_error": "Promokod hatasını kontrol edin!",
        "Add_new_credit_error": "Yeni kredi hatası ekleyin!",
        "Can_not_get_fare_setting": "Ücret ayarı alamıyorum!",
        "Can_not_get_affilate_fare_setting": "Satış Ortaklık Ücret Ayarları alamıyorum!",
        "Can_not_check_commisson": "Komisyonu kontrol edemiyorum!",
        "No_flight_info": "Uçuş bilgisi yok!",
        "Socket_server_disconnected": "Soket sunucusu bağlantısı kesildi!",
        "Please_reload_page_or_check_your_connection": "Üzgünüm.İsteğiniz işlenemez.Lütfen tekrar deneyin.",
        "View_Logs": "Günlükleri Görüntüle",
        "Bookingid": "Rezervasyon #",
        "Calendar": "Takvim",
        "Extra_Destination": "Ek Hedef",
        "Meet_Greet_option": "Buluş ve selam",
        "On_Curb_option": "Kaldırımla",
        "Credit_Card": "Kredi kartı",
        "Charge": "Şarj",
        "No_charge": "Ücretsiz",
        "QR_Code_method_not_allow_error_message": "QRCode kontrol panelinde ödeme yapamadığından lütfen başka bir ödeme türü seçin!",
        "change_payment_method_note": "Ödeme yöntemini değiştirmek istiyorsanız, lütfen rezervasyon formuna geri dönün.",
        "Airport_Fee": "Havaalanı ücreti",
        "Addi_service": "Hizmetler Ekle",
        "Additional_Services": "Ek Hizmetler",
        "Minimum": "Minimum",
        "Search_here": "BURADA ARAYIN",
        "No_Driver_Yet": "Henüz atanmadı",
        "Personal_Card": "Kişisel kart",
        "Reject_booking": "Rezervasyonu Reddet",
        "Confirm_reject": "Reddet'i onaylayın",
        "Confirm_reject_message": "Bu rezervasyonu reddetmek istediğinizden emin misiniz?",
        "Confirm_reject_booking_with_charge": "İptal politikamıza göre alıntılanan ücretin% {0} {0} ücretlendirilecektir.Bu rezervasyonu reddetmek istediğinizden emin misiniz?",
        "Yes": "Evet",
        "No": "HAYIR",
        "shortTrip": "Kısa gezi",
        "Add_new_card": "Yeni kart ekle",
        "Require_customer_info": "Devam etmek için lütfen müşteri bilgilerini girin.",
        "confirm_force_overlap": "Onaylama",
        "confirm_force_overlap_message": "Bu rezervasyon sürücünün kabul edilen rezervasyonlarıyla örtüşmektedir.Bu sürücüye atamak istediğinizden emin misiniz?",
        "select": "Seçme",
        "PICKUP_TIME_RANGE": "{0} 'da tahmini pikap",
        "food": "Yiyecek",
        "mart": "Müthiş",
        "Order_summary": "Sipariş Özeti",
        "addOnPrice": "Düzeltilmiş Fiyat",
        "applicableFee": "Toplam (uygulanabilir)",
        "itemValue": "Öğe değeri",
        "promotion_note_paymentMethod": "Bu sipariş sınırlı ödeme yöntemi ile promosyon kodu uygulanmaktadır: %{Method}.",
        "promotion_note_minimum_fare": "Bu sipariş sınırlı asgari ücretle promosyon kodu uygulanmaktadır: %{ücret}.",
        "promotion_note_schedules": "Bu sipariş sınırlı toplama süresi ile promosyon kodu uygulanmaktadır.",
        "edited_fare_note": "Bu, yalnızca referans için ücret ayarlama günlükleridir.Nihai ücret ve ücreti kontrol etmek için lütfen raporları ziyaret edin.",
        "view_good_receipt": "Mal makbuzunu görüntüleyin",
        "confirmRemoveGroupTransport": "Mevcut rezervasyonu bu manifestten kaldırmak istediğinizden emin misiniz?",
        "confirmRemoveGroupDelivery": "Mevcut rezervasyonu bu gruptan kaldırmak istediğinizden emin misiniz?",
        "confirmUngroupTransport": "Bu manifestten tüm rezervasyonları çözmek istediğinizden emin misiniz?",
        "confirmUngroupDelivery": "Bu gruptaki tüm rezervasyonları çözmek istediğinizden emin misiniz?",
        "view_delivery_receipt": "Teslimat fotoğrafını görüntüleyin",
        "select_time": "Zaman seçin",
        "InputReason": "Giriş Sebebi",
        "viewOrder": "Siparişi Görüntüle",
        "markFailed": "Başarısız Olarak İşaretle",
        "corporateName": "Kurumsal isim",
        "sendMessage": "Mesaj Gönder",
        "AmountDueBefore": "Önceden ödenmesi gereken miktar",
        "AmountDueAfter": "Sonra ödenmesi gereken miktar",
        "chargeCustomer": "Müşteriye",
        "pending": "Ödenmemiş",
        "refundPayment": "Refund Customer",
        "refundableAmount": "Refundable amount",
        "refundReason": "Add refund reason",
        "refundBtn": "Refund {0}",
        "partial": "Kısmi",
        "paid": "Paralı",
        "ViewPaymentLogs": "Ödeme Günlüklerini Görüntüle",
        "pmActivity": "Ödeme Etkinliği",
        "paidStatus": "Ücretli Durum",
        "paymentLog": "Ödeme günlüğü",
        "extraWaitFee": "Bekleme Ücreti Başlangıç",
        "extraWaitTime": "Bekleme süresi (başlangıç)",
        "copied": "Copied",
        "warnningCancelCorporate": "Please note, you may incur a charge of amount in accordance with our cancellation policy.",
        "customerAdjustments": "Customer Adjustments",
        "customerAdjustmentsTooltip": "Customer Adjustments",
        "supplierDriverAdjustments": "Supplier/Driver Adjustments",
        "updateSummary": "Update Summary",
        "orderDetails": "Order Details",
        "pastDetacted": "Past Booking Detected",
        "pastDetactedContent": "You are about to create a booking in the past"
    },
    "map": {
        "Enter_a_location": "Bir konum girin",
        "All_company": "Tüm Şirket",
        "All_cars": "Tüm Arabalar",
        "Show_all_cars": "Tüm arabaları göster",
        "Hide_all_cars": "Tüm Arabaları Gize",
        "Vacant": "Mevcut",
        "Dispatching": "Gönderme",
        "Driver_on_the_way": "Yolda Sürücü",
        "Arrived_and_waiting": "Geldi ve Bekleme",
        "P_O_B": "P.O.B",
        "Dropped_off": "Düştü",
        "Waiting_for_process": "Süreci Beklemek",
        "Unavailable": "Müsait olmayan",
        "Total_Drivers": "Toplam Sürücüler",
        "Estimate": "Tahmin etmek",
        "Destination": "Varış noktası",
        "Pickup": "Toplamak",
        "Start_point": "Başlangıç ​​noktası",
        "Passenger": "Yolcu",
        "Driver_name": "Sürücü adı",
        "Search_car_here": "Araba/Sürücü Adı Arama",
        "Error_load_booking": "Rezervasyonu yükleyemiyorum, lütfen tekrar deneyin veya sayfa yenileyin!",
        "Show_all": "Hepsini göster",
        "driversLiveMap": "Sürücü Haritası",
        "heatMap": "Talep haritası",
        "Trip": "Seyahat",
        "traffic": "Trafik",
        "bicyclingLayers": "Bisiklet",
        "layer": "Katman",
        "trafficBicycling": "Trafik, bisiklet",
        "All_airline": "Tüm Havayolu"
    },
    "customer": {
        "Customers": "Müşteriler",
        "Points": "Puan",
        "Migration_Status": "Göç statüsü",
        "ADD_CUSTOMER": "Müşteri Ekle",
        "EDIT_CUSTOMER": "Müşteriyi Düzenle",
        "Add": "Eklemek",
        "Activate": "Etkinleştirmek",
        "Deactivate": "Devre dışı bırakmak",
        "Delete": "Silmek",
        "Import": "İçe aktarmak",
        "Download_template": "Şablonu indir",
        "View_Message": "Mesaj",
        "Send_Message": "Mesaj Gönder",
        "Export_to_excel": "Excel'e İhracat",
        "Filter": "Filtre",
        "Apply": "Uygula",
        "Clear": "Temizlemek",
        "Name": "İsim",
        "Phone_Number": "Telefon numarası",
        "Email": "E -posta",
        "Vip": "Vip",
        "companies": "Şirket",
        "File": "Dosya",
        "Number_successful_imports": "Başarılı ithalat sayısı:",
        "Number_skipped": "Atlanan sayı:",
        "Number_duplicate_phone_numbers": "Yinelenen telefon numaralarının sayısı:",
        "Number_wrong_phone_numbers": "Yanlış telefon numaralarının sayısı:",
        "Corporate_Name": "Şirket",
        "Registered_From": "Kayıtlı",
        "Registration_Date": "Kayıt tarihi",
        "Registration_date_from": "Kayıt Tarihi",
        "Registration_date_to": "Kayıt tarihi",
        "IOS_Last_Login_Version": "İOS Son login versiyonu",
        "Android_Last_Login_Version": "Android son login versiyonu",
        "Last_Active_Date": "Son aktif tarih",
        "Verification": "Doğrulama",
        "Support_ID": "Destek Kimliği",
        "Days_Since_Last_Active": "Son aktiften bu yana geçen günler",
        "Completed_Bookings": "Tamamlanan rezervasyonlar",
        "Outstanding_amount": "Ödenmemiş tutar",
        "Status": "Durum",
        "Actions": "Eylem",
        "Action": "Aksiyon",
        "Inactive": "Aktif olmayan",
        "Active": "Aktif",
        "Edit": "Düzenlemek",
        "View": "Görüş",
        "ALERT_SELECT_ACTIVATE": "Lütfen etkinleştirmek için müşteri seçin.",
        "ALERT_SELECT_DEACTIVATE": "Lütfen devre dışı bırakmak için müşteri seçin.",
        "ALERT_SELECT_DELETE": "Lütfen silmek için müşteri seçin.",
        "CONFIRM_DELETE": "Bu hesabı silmek istediğinizden emin misiniz?Müşteri, hesap kaldırdıktan sonra boş verilerle yeni bir profil kaydetebilir.",
        "CONFIRM_DELETE_MULTI": "Bu müşterileri silmek istediğinizden emin misiniz?Uygulama aracılığıyla yalnızca aktif olmayan ve kayıtlı kullanıcı olmayan müşteriler silinebilir.",
        "CONFIRM_DEACTIVATE_MULTI": "Bu müşterileri devre dışı bırakmak istiyor musunuz?",
        "CONFIRM_ACTIVATE_MULTI": "Bu müşterileri etkinleştirmek istiyor musunuz?",
        "CONFIRM_EXPORT_TO_EXCEL": "Tüm verileri .xlsx dosyası olarak dışa aktarmak istediğinizden emin misiniz?",
        "Add_card_top_up_success": "Ön ödemeli kart başarıyla tepesinde",
        "Add_card_success": "Kart başarıyla eklendi!",
        "Add_customer_success": "Yeni müşteri başarıyla yaratıldı",
        "Update_customer_success": "Müşteri başarıyla güncellendi",
        "Deleted_customer_success": "Müşteri başarıyla silindi",
        "Create_customer_fail": "Müşteri oluşturamadı",
        "Update_customer_fail": "Müşteriyi güncelleyemedi",
        "ERROR_EXISTED_PHONE": "Bu telefon sistemde mevcuttu.Lütfen başka bir tane girin",
        "ERROR_INPUT_FIRSTNAME": "Lütfen ad girin.",
        "ERROR_INPUT_PHONE": "Lütfen telefon numarası girin.",
        "ERROR_INPUT_VALIDPHONE": "Lütfen geçerli bir telefon numarası girin.",
        "ERROR_INPUT_VALIDEMAIL": "Geçerli bir e.",
        "CONFIRM_DELETE_CARD": "Bu kartları silmek istediğinden emin misiniz?",
        "First_name": "İlk adı",
        "Last_name": "Soy isim",
        "Phone_number": "Telefon numarası",
        "Email_address": "E -posta adresi",
        "Notes": "Notalar",
        "Account": "Hesap",
        "Card_management": "Kart yönetimi",
        "Home": "Ev",
        "Affiliate": "Bağlı kuruluş",
        "SHOW_PERSONAL_CARD": "Kişisel Kartı Göster",
        "ADD_PERSONAL_CARD": "Kişisel Kart Ekle",
        "SHOW_mDISPATCHER_CARD": "Ortak kartını gösterin",
        "ADD_mDISPATCHER_CARD": "Ortak kartı ekle",
        "Personal_card": "Kişisel kart",
        "mDispatcher_card": "Ortak kartı",
        "Card_holder": "Kart sahibi",
        "Card_number": "Kart numarası",
        "Credit_type": "Kredi türü",
        "Credit_code": "Kredi kodu",
        "Sms_verification": "SMS Doğrulama",
        "Sms_verification_placeholder": "Doğrulama kodunu girin",
        "Expired_date": "Süresi dolmuş tarih",
        "CVV": "CVV",
        "Address": "Adres",
        "Country": "Ülke",
        "City": "Şehir",
        "State": "Durum",
        "Zip_code": "Posta kodu",
        "postal_code": "Posta Kodu",
        "Required_fields": "Gerekli Alanlar",
        "Cancel": "İptal etmek",
        "Save": "Kaydetmek",
        "ERROR_INPUT_CARD_HOLDER": "Lütfen Giriş Kartı Tutucu",
        "ERROR_INPUT_CARD_NUMBER": "Lütfen Giriş Kartı Numarası",
        "ERROR_INPUT_EXPIRED_DATE": "Lütfen süresi dolmuş tarih girin",
        "ERROR_VAILD_EXPIRED_DATE": "Süresi dolmuş tarih geçerli değil",
        "ERROR_INPUT_CVV": "Lütfen CVV girin",
        "ERROR_INPUT_ADDRESS": "Lütfen adresi girin",
        "ERROR_INPUT_CITY": "Lütfen Şehri Giriş",
        "ERROR_INPUT_ZIPCODE": "Lütfen posta kodu girin",
        "ERROR_INPUT_COUNTRY": "Lütfen Ülke Seçin",
        "Info_edit_error": "Hata!",
        "Gender": "Cinsiyet",
        "Select_Gender": "Seçme",
        "Select_Idtype": "Kimlik Türünü Seçin",
        "Male": "Erkek",
        "Female": "Dişi",
        "Other": "Diğer",
        "Date_of_birth": "Doğum tarihi",
        "Street_address": "Açık adres",
        "Select_Zone": "Bölge Seçin",
        "ERROR_GET_GATEWAYS": "Hata !!!Ağ Geçidi Alın",
        "Require_email": "Lütfen yeni kart eklemeden önce e -postayı girin",
        "Require_lastName": "Lütfen yeni kart eklemeden önce soyadı girin",
        "Require_phone_number": "Lütfen kredi kartı ile rezervasyon yapmadan önce seyahat eden telefon numarasını girin",
        "IMPORT_CUSTOMER_SUCCESS": "Müşterileri başarılı bir şekilde içe aktarın",
        "No_card": "Kart bulunamadı",
        "No_Support": "Bu alanda yeni kart ekleme şu anda desteklenmiyor",
        "Card_Management": "Kart yönetimi",
        "Emergency_contacts": "Acil durum kontakları",
        "DETAIL_CUSTOMER": "Müşteri Detayları",
        "Select_Country": "Ülke Seçin",
        "Customer_ID": "ID# / National IC#",
        "Credit_balance": "Kredi bakiyesi",
        "required_email_payment": "Müşterinin e -postası gereklidir.Devam etmek için lütfen profili güncelleyin.",
        "Yes": "Evet",
        "No": "HAYIR",
        "WriteOffDebt": "Borç yaz",
        "selectPayment": "Lütfen ödenmemiş ödeme yapan tüm rezervasyonları kapatmak için bir ödeme yöntemi seçin",
        "writeOffDebtSuccess": "Borç başarıyla yazın",
        "writeOffDebtNotes": "Not: Bu yazma işlemi yalnızca tüm ödenmemiş tutar tam olarak ödendikten sonra doğrulanmalıdır.",
        "totalCustomer": "Toplam Müşteriler",
        "Personal_Card": "Kişisel kart",
        "INFO_ADD_BALANCE_SUCCESS": "Yolcu bakiyesi başarıyla güncellendi",
        "INFO_UPDATE_POINT_SUCCESS": "Yolcu Point başarıyla güncellendi",
        "currency": "Para birimi",
        "balance": "Denge",
        "changeBalance": "Dengeyi Değiştir",
        "change": "Değiştirmek",
        "WALLET_DEPOSIT": "Cüzdan",
        "AccessDenied": "Bu modüle erişme izniniz yok!",
        "referral_code": "Tavsiye Kodu",
        "loginMethod": "Login method"
    },
    "importBookings": {
        "no": "HAYIR.",
        "status": "Durum",
        "pickupDate": "Pikap tarihi",
        "pickup": "Pikap adresi",
        "dropoff": "Bırakma adresi",
        "passenger": "Yolcu",
        "phone": "Telefon numarası",
        "seats": "Koltuklar",
        "luggages": "Bagaj",
        "rideSharing": "Sürüş",
        "flightNumber": "Uçuş No.",
        "notes": "Notalar",
        "externalId": "Harici kimlik",
        "operatorNote": "İç notlar",
        "clientCaseMatter": "Gezi Açıklama",
        "chargeCode": "Gezi kodu",
        "price": "Price",
        "payout": "Payout",
        "paymentType": "Ödeme",
        "vehicleTypeRequest": "Araba tipi",
        "importBookings": "İthalat rezervasyonları",
        "corporation": "Şirket",
        "clickOrDropCsvFile": "Tıklayın veya drop.csv dosyası",
        "importingFileForReview": "İnceleme için dosya içe aktarma ...",
        "import": "İçe aktarmak",
        "submit": "Göndermek",
        "downloadTemplate": "Şablonu indir",
        "learnMore": "Daha fazla bilgi edin",
        "total": "Toplam",
        "valid": "Geçerli",
        "invalid": "Geçersiz",
        "failed": "Arızalı",
        "processing": "İşleme",
        "success": "Başarı",
        "confirm": "Onaylamak",
        "confirmMessage": "siparişler oluşturulacak.Devam etmek istediğinden emin misin?",
        "create": "Yaratmak",
        "cancel": "İptal etmek",
        "close": "Kapalı",
        "leavingConfirmMessage": "İşiniz kurtarılmadı!Ayrılmak istediğinden emin misin?",
        "resultMessage": "$ {başarı} rezervasyon (lar) başarıyla oluşturuldu, $ {başarısız} başarısız oldu.",
        "limitExceededBookings": "Maksimum İçe Aktarma Dosya Boyutu: 100 Rezervasyon",
        "delivery": {
            "no": "#",
            "pickupDate": "Pikap tarihi",
            "senderName": "Gönderen adı",
            "senderPhone": "Gönderen telefon",
            "senderMail": "Gönderen e -posta",
            "senderAddress": "Gönderen adresi",
            "recipients": "Alıcılar",
            "senderDistrictOptional": "Semt",
            "senderLocationDetails": "Konum Ayrıntıları",
            "recipientName": "Alıcı adı",
            "recipientPhone": "Alıcı telefon",
            "recipientAddress": "Alıcı adresi",
            "recipientDistrictOptional": "Semt",
            "recipientLocationDetailsOptional": "Konum Ayrıntıları",
            "packageContent": "Paket içeriği",
            "orderNumber": "Emir #",
            "instructions": "Sürücü için talimatlar",
            "cod": "COD öğe değeri",
            "rideShare": "Sürüş",
            "paymentType": "Ödeme türü",
            "carType": "Araba tipi",
            "recipientLocationDetails": "Konum Ayrıntıları",
            "operatorNote": "İç notlar",
            "rideSharing": "Toplu teslimat"
        }
    },
    "merchant": {
        "INFO_ADD_BALANCE_SUCCESS": "Satıcı Dengesi başarıyla güncellendi",
        "ADD_MERCHANT_USER": "Satıcı hesabı ekle",
        "EDIT_MERCHANT_USER": "Satıcı Hesabını Düzenle",
        "MERCHANT_USER_DETAILS": "Satıcı Hesabı Ayrıntıları",
        "CASH_WALLET": "Nakit cüzdan",
        "CREDIT_WALLET": "Kredi cüzdanı",
        "ADD_MERCHANT": "Satıcı Ekle",
        "ACCOUNT_INFO": "Hesap bilgileri",
        "BILLING_ADDRESS": "Banka bilgileri",
        "user": {
            "fullName": "Satıcı Kullanıcısı",
            "Phone_Number": "Telefon numarası",
            "Email": "E -posta",
            "storeName": "Tüccar",
            "status": "Durum",
            "actions": "Aksiyon",
            "First_name": "İlk adı",
            "Last_name": "Soy isim",
            "Select_store": "Satıcı Seçin",
            "accountHolder": "Hesap sahibinin adı",
            "First_M_Last": "İlk M. Son",
            "accountNumber": "Hesap numarası",
            "ibanNumber": "Iban Numarası",
            "bankName": "Bankanın adı",
            "IDtype": "Kimlik türü",
            "IDnumber": "ID# / National IC#",
            "routingNumber": "Yönlendirme/Transit/Şube Numarası",
            "checkNumber": "Kontrol Numarası",
            "ssn": "SSN/NI",
            "sortCode": "Sıralama kodu",
            "transitNumber": "Transit numarası",
            "institutionNumber": "Kurum numarası",
            "birthDay": "Doğum günü",
            "address": "Adres",
            "city": "Şehir",
            "state": "Durum",
            "postalCode": "Posta kodu",
            "beneficiaryIDIC": "Yararlanıcı Kimliği# / IC#",
            "relationshipOtherName": "Diğerleriyle ilişki?",
            "verificationDocumentFront": "Doğrulama belgesi (ön)",
            "verificationDocumentBack": "Doğrulama belgesi (geri)",
            "Verification_document_tooltip": "Kimlik için kabul edilebilir formlar:\n- Pasaport\n- Sürücü Lisansı\n- Yerleşik İzin Kimliği\n- Vatandaş kartı\n- Kimlik kartı",
            "additionalDocumentFront": "Ek belge (ön)",
            "additionalDocumentBack": "Ek Belge (Geri)",
            "Additional_document_tooltip": "Kabul edilebilir adres doğrulama biçimleri:\n- Sürücü Lisansı\n- Fayda faturası\n- Hükümet tarafından verilen mektup\n- Bir finans kurumundan ifade\n- Seçim Kayıt Girişi",
            "please_select": "Lütfen seçin",
            "commission": "Komisyon",
            "ERROR_INPUT_VALIDPHONE": "Lütfen geçerli bir telefon numarası girin.",
            "ERROR_EXISTED_PHONE": "Bu telefon sistemde mevcuttu.Lütfen başka bir tane girin",
            "Activate": "Etkinleştirmek",
            "Deactivate": "Devre dışı bırakmak",
            "ALERT_SELECT_ACTIVATE": "Lütfen etkinleştirmek için kullanıcı seçin.",
            "ALERT_SELECT_DEACTIVATE": "Lütfen devre dışı bırakmak için kullanıcı seçin.",
            "CONFIRM_ACTIVATE_MULTI": "Bu kullanıcıları etkinleştirmek istiyor musunuz?",
            "CONFIRM_DEACTIVATE_MULTI": "Bu kullanıcıları devre dışı bırakmak istiyor musunuz?",
            "RESET_PASSWORD": "Parolayı sıfırlamak istiyor musunuz?",
            "CONFIRM_RESET_PASSWORD": "Parolayı sıfırlamak istiyor musunuz?",
            "REMOVE_BANK_INFO": "Banka bilgilerini kaldırın",
            "CONFIRM_REMOVE_BANK_INFO": "Banka bilgilerini kaldırmak istiyor musunuz?",
            "note_account_password_default": "Not: Yeni satıcı hesabı için varsayılan şifre \"12345678\" dır.",
            "Cash_Balance": "Nakit bakiyesi",
            "Credit_Balance": "Kredi bakiyesi"
        },
        "Update_merchant_user_success": "Satıcı kullanıcısı başarıyla güncellendi",
        "Update_merchant_user_fail": "Satıcı kullanıcısını güncelleyemedi",
        "Create_merchant_user_success": "Yeni satıcı kullanıcısı başarıyla oluşturuldu",
        "Create_merchant_user_fail": "Satıcı Kullanıcısı Oluşturamadı",
        "Update_merchant_user_status_success": "Satıcı kullanıcısı başarıyla güncellendi",
        "Update_merchant_user_status_fail": "Satıcı kullanıcısını güncelleyemedi",
        "Reset_password_merchant_user_success": "Parolayı başarıyla sıfırlayın",
        "Reset_password_merchant_user_fail": "Şifre Sıfırla Başarısız",
        "Deleted_merchant_bank_info_success": "Banka bilgilerini başarıyla kaldırın",
        "Deleted_merchant_bank_info_fail": "Banka Bilgilerini Kaldır başarısız"
    },
    "table_header": {
        "Items_per_page": "Sayfa başına öğeler",
        "Page": "Sayfa",
        "Search_here": "BURADA ARAYIN",
        "Shown_Active": "%{start} - %{end} %{total} gösterilen ",
        "Active": "%{active} aktif",
        "OF": "ile ilgili"
    },
    "driver": {
        "Driver": "Driver",
        "Add": "Eklemek",
        "addDriver": "Add driver",
        "Detail": "Detail",
        "detailDriver": "Driver Details",
        "addNewVehicle": "Add Vehicle",
        "serviceType": "Service Type",
        "transport": "Taşıma",
        "delivery": "Delivery",
        "stripeConnect": "Stripe Connect",
        "stripeConnectDes": "Add a stripe connect account for easier payouts",
        "bankAccount": "Banking Info",
        "bankAccountDes": "Drivers bank info to handle payouts",
        "supplierComission": "Supplier Commission",
        "supplierComissionDes": "Set commissions supplier earns for every complete order.",
        "fleetCommission": "Fleet/Supplier commission",
        "fleetCommissionDes": "Set commissions the fleet or supplier earns for every complete order. If the driver is a supplier’s driver, the commission will be deducted from the supplier’s earning.",
        "operationZones": "Operation Zones",
        "operationZonesDes": "Zones that suppliers can receive jobs from:",
        "notes": "Notes",
        "notesDes": "Internal notes about this driver:",
        "searchNotes": "Add notes for driver",
        "marketplace": "Marketplace",
        "marketplaceDes": "Empower drivers to accept jobs they want to do.",
        "otherSettings": "Other Settings",
        "topDriver": "TOP Driver",
        "topDriverDes": "Prioritize top performers with the \"Top Driver\" tag, ensuring they get more rides.",
        "walletTransferBetweenDriver": "Wallet Transfers Between Drivers",
        "walletTransferBetweenDriverDes": "Enable drivers to transfer funds directly to each other’s wallets, facilitating easy distribution of credits by team leaders or ambassadors.",
        "rideSharing": "Ride-sharing",
        "rideSharingDes": "Enable drivers to perform ride-sharing jobs. Contact your account manager for this advance feature.",
        "individual_vehicle": "Individual vehicle",
        "company_vehicle": "Company vehicle",
        "ContactInfo": "Contact Info",
        "name": "Name",
        "expiryDate": "Expiry Date",
        "action": "Action",
        "editDocument": "Edit Document",
        "documentToUpload": "Document to upload",
        "addFiles": "Click or drag file to this area to upload.",
        "fileSize": "Maximum file size 10 MB",
        "fileSizeDes": "The file extension must be .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip",
        "removeOldFile": "Please remove the old file before uploading a new one.",
        "maxLengthFiles": "Please upload max length 1 file",
        "maxSizeFiles": "Please upload file size less than 10MBs",
        "typeFiles": "Please upload file type .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip",
        "maxAvatarSizeFiles": "Please upload avatar size less than 8MBs",
        "IFSCCode": "IFSC Kodu",
        "Drivers": "Sürücüler",
        "default": "Varsayılan",
        "customize": "Özelleştirilmiş",
        "Activate": "Etkinleştirmek",
        "Stripe_connect": "Stripe Connect",
        "status_stripe": "Durum: {0}",
        "stripeConnectStatus": "Stripe Connect",
        "stripeStatus": "Stripe Hesap Durumu",
        "Deactivate": "Devre dışı bırakmak",
        "Delete": "Silmek",
        "acceptInDay": "Maksimum rezervasyon sürücüsü bir günde kabul edebilir",
        "acceptATime": "Bir sürücünün bir seferde kabul edebileceği toplam rezervasyon sayısı",
        "Commission": "Filo Komisyonu Set",
        "Title_Commission": "Tüm sürücüler için filo komisyonunu ayarlayın",
        "Confirm_Set_Commission": "Bu komisyon değerini tüm sürücüler için ayarlamak istediğinizden emin misiniz?",
        "Commission_Successfully": "Sürücü Komisyonu başarıyla güncellendi",
        "Apply": "Uygula",
        "Type": "Tip",
        "Currency": "Para birimi",
        "View_Message": "Mesaj",
        "Send_Message": "Mesaj Gönder",
        "Driver_Name": "Sürücü adı",
        "Driver_ID": "ID# / National IC#",
        "Phone_Number": "Telefon numarası",
        "Driver_Type": "Sürücü türü",
        "TOP": "TEPE",
        "Company": "Şirket",
        "City": "Şehir",
        "Driver_License_Number": "Ehliyet numarası",
        "License_Plate": "License Plate",
        "Car_Type": "Araba tipi",
        "Zone": "Alan",
        "Registered_From": "Kayıtlı",
        "Registration_Date": "Kayıt tarihi",
        "IOS_Last_Login_Version": "İOS Son login versiyonu",
        "Android_Last_Login_Version": "Android son login versiyonu",
        "Last_Active_Date": "Son aktif tarih",
        "Days_Since_Last_Active": "Son aktiften bu yana geçen günler",
        "Completed_bookings": "Tamamlanan rezervasyonlar",
        "Status": "Durum",
        "Actions": "Eylem",
        "Action": "Aksiyon",
        "Inactive": "Aktif olmayan",
        "Active": "Aktif",
        "Edit": "Düzenlemek",
        "View": "Görüş",
        "ALERT_SELECT_ACTIVATE": "Lütfen etkinleştirmek için sürücüyü seçin.",
        "ALERT_SELECT_DEACTIVATE": "Deactivate için lütfen sürücüyü seçin.",
        "ALERT_SELECT_DELETE": "Lütfen silmek için sürücüyü seçin.",
        "CONFIRM_DELETE": "Bu sürücüyü silmek istiyor musunuz?",
        "CONFIRM_DEACTIVATE_MULTI": "Seçilen sürücüyü (IS) devre dışı bırakmak istediğinizden emin misiniz?Bağlı donanım sayaçları) da devre dışı bırakılacaktır.Yalnızca mevcut bir yolculuk veya rezervasyonu olmayan sürücü (ler) devre dışı bırakılabilir.",
        "CONFIRM_ACTIVATE_MULTI": "Bu sürücüleri etkinleştirmek istiyor musunuz?Yalnızca çalışma için kurulum olan sürücü (ler) etkinleştirilebilir.",
        "CONFIRM_DELETE_MULTI": "Bu sürücüleri silmek ister misiniz?Yalnızca aktif olmayan durumdaki sürücü silinebilir.",
        "CONFIRM_REMOVE_BANKING_INFOR": "Bankacılık bilgilerini kaldırmak istediğinizden emin misiniz?",
        "REMOVE_BANKING_INFOR": "Bankacılık bilgilerini kaldırın",
        "ADD_DRIVER": "Sürücü Ekle",
        "Avatar": "Avatar",
        "Individual_Driver": "Ulaşım - Şirket Aracı",
        "Owner_Operator": "Taşımacılık - Bireysel Araç",
        "deliveryCompany": "Teslimat - Şirket Aracı",
        "deliveryIndividual": "Teslimat - Bireysel Araç",
        "CONTACT_INFO": "İletişim Bilgileri",
        "Home_address": "Ev adresi",
        "Username": "Kullanıcı adı",
        "First_name": "İlk adı",
        "Last_name": "Soy isim",
        "Phone_number": "Telefon numarası",
        "Email_address": "E -posta adresi",
        "Select_city": "Şehir seçin",
        "State": "Durum",
        "Zip_code": "Posta kodu",
        "Date_of_birth": "Doğum tarihi",
        "placeholderDate": "mm/dd/yyyy",
        "City_driver_want_to_drive": "Şehir sürücüsü sürmek istiyor",
        "Driver_license_number": "Ehliyet numarası",
        "Driver_license_state": "Sürücü Lisansı Eyaleti/İl",
        "Driver_license_expiry": "Sürücü Lisans Sona Eritme",
        "FOR_INSPECTOR": "Müfettiş için",
        "Document": "Belge",
        "Document_Id": "Belge Kimliği",
        "Link": "Bağlantı",
        "Notes": "Notalar",
        "UPLOAD_DOCUMENT": "Belgeyi Yükle",
        "Click_here_to_download": "İndirmek için tıklayın",
        "Click_here_to_view": "Görüntülemek için tıklayın",
        "EXTENSION_REQUIREMENT": "Dosya uzantısı .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip ve 5MB'den az olmalıdır.",
        "Invalid_file": "Dosya içeriği geçersiz",
        "File_size_larger_than_5Mb": "Dosya boyutu 5MB'den büyük olmalı",
        "BANK_ACCOUNT": "Banka hesabı",
        "Name_of_bank": "Bankanın adı",
        "Name_of_account": "Hesap sahibinin adı",
        "First_M_Last": "İlk M. Son",
        "RTBnumber": "Yönlendirme/Transit/Şube Numarası",
        "bankName": "Banka adı",
        "Select_a_bank": "Bir Banka Seçin",
        "Account_number": "Hesap numarası",
        "IBAN_number": "Iban Numarası",
        "Check_number": "Kontrol Numarası",
        "Swift_code_number": "Swift Kod Numarası",
        "SSNNI": "SSN/ NI",
        "sortCode": "Sıralama kodu",
        "Verification_document": "Doğrulama belgesi (ön)",
        "Additional_document": "Ek belge (ön)",
        "Verification_document_back": "Doğrulama belgesi (geri)",
        "Additional_document_back": "Ek Belge (Geri)",
        "others": "Diğerleri",
        "signedW9Form": "İmzalı W-9 formu",
        "driverLicense": "Ehliyet",
        "Verification_document_tooltip": "Kimlik için kabul edilebilir formlar:\n- Pasaport\n- Sürücü Lisansı\n- Yerleşik İzin Kimliği\n- Vatandaş kartı\n- Kimlik kartı",
        "Additional_document_tootip": "Kabul edilebilir adres doğrulama biçimleri:\n- Sürücü Lisansı\n- Fayda faturası\n- Hükümet tarafından verilen mektup\n- Bir finans kurumundan ifade\n- Seçim Kayıt Girişi",
        "isBankAccountOwner": "Bu senin hesabın mı?",
        "yes": "Evet",
        "no": "HAYIR",
        "beneficiary_name": "Yararlanıcı adı",
        "beneficiary_idic": "Yararlanıcı Kimliği/IC",
        "relationship": "İlişki",
        "select_relationship": "İlişki Durumunu Seçin",
        "spouse": "Eş",
        "daughterOrSon": "Kızı/oğul",
        "brotherOrSister": "Erkek kardeş/kız kardeş",
        "relative": "Akraba",
        "friends": "Arkadaşlar",
        "other": "Diğer",
        "relationshipOtherName": "Diğerleriyle ilişki?",
        "OPERATION": "Ameliyat",
        "CAR_INSURANCE": "Araba sigortası",
        "Policy_number": "Politika numarası",
        "Effective_date": "Yürürlük tarihi",
        "Expired_date": "Süresi dolmuş tarih",
        "Select_company": "Şirket seçin",
        "All_company": "Tüm Şirket",
        "All_Car_Type": "Tüm Araba Türü",
        "Car": "Araba",
        "Existing_car": "Mevcut araba",
        "New_car": "Yeni araba",
        "License_plate": "License plate",
        "Select_license_plate": "Select license plate",
        "Select_Car_Type": "Araba Türünü Seçin",
        "vehicleOwner": "Araç Sahibinin Adı",
        "Make": "Yapmak",
        "Select_Car_Make": "Araba Markası'nı seçin",
        "Model": "Model",
        "Select_Car_Model": "Araba Modelini Seçin",
        "Year": "Yıl",
        "Select_Year": "Seçim Yıl",
        "Color": "Renk",
        "Passengers": "Yolcular",
        "Luggage": "Bagaj",
        "License_expiry": "Lisans sona erme",
        "Shift": "Vardiya",
        "Select_Shift_Template": "Vites Şablonu'nu seçin",
        "Fleet_commission": "Filo Komisyonu (Rapor)",
        "Company_commission": "Şirket Komisyonu (Rapor)",
        "rideHailing": "Sokak selamlama",
        "intercity": "Şehirlerarası",
        "food": "Yiyecek",
        "mart": "Müthiş",
        "streetSharing": "Sokak paylaşımı",
        "Shuttle": "Servis",
        "parcel": "Parsel",
        "driverInsurance": "Sürücü sigortası",
        "Percentage": "Yüzdesi",
        "Amount": "Miktar",
        "transaction": "İşlem",
        "Terminal_ID": "Terminal kimliği",
        "Auth_key": "Anahtar",
        "CREDIT_DEPOSIT": "Kredi cüzdanı",
        "Credit_Balance": "Kredi bakiyesi",
        "Balance": "Denge",
        "Change_balance": "Dengeyi Değiştir",
        "Select_balance": "Dengeyi seçin",
        "Adjust_amount": "Miktarı Ayarla",
        "Adjust_value": "Değeri Ayarla",
        "New_balance": "Yeni denge",
        "New_point": "Yeni nokta",
        "Enter_reason": "Neden olmak",
        "ERROR_INPUT_BALANCE": "Lütfen bakiye seçin.",
        "ERROR_INPUT_AMOUNT": "Lütfen bu değeri girin.",
        "ERROR_INPUT_REASON": "Lütfen Sebep girin.",
        "ERROR_INPUT_VALID_AMOUNT": "Lütfen 0'dan büyük veya eşit bir değer girin.",
        "INFO_ADD_BALANCE_SUCCESS": "Sürücü bakiyesi başarıyla güncellendi",
        "INFO_ADD_CASH_BALANCE_SUCCESS": "Sürücü nakit bakiyesi başarıyla güncellendi",
        "Force_meter": "Metre",
        "Force_meter_tooltip": "Sürücü uygulaması çalışmadan önce sayaça bağlanmalıdır.Pulsar & Centrodyne'nin metre için başvurdu",
        "ERROR_EXISTED_PHONE": "Bu telefon sistemde mevcuttu.Lütfen başka bir tane girin",
        "TOP_driver": "En iyi sürücü",
        "Ride_sharing": "Sürüş paylaşımı",
        "Note_password": "Not: Yeni sürücü hesabı için varsayılan şifre \"12345678\" dır.",
        "Required_fields": "Gerekli Alanlar",
        "Cancel": "İptal etmek",
        "Save": "Kaydetmek",
        "SaveAndInProgress": "Kaydet ve Devam Edin",
        "SaveAndDeactivate": "Kaydet ve Deactivate",
        "ERROR_REQUIRE_": "Lütfen girin",
        "ERROR_INPUT_AVATAR": "Lütfen Avatar'ı yükleyin.",
        "ERROR_INPUT_DRIVER_TYPE": "Lütfen Sürücü Türünü seçin.",
        "ERROR_INPUT_DOCUMENT": "Lütfen Yükle",
        "ERROR_INPUT_USERNAME": "Lütfen kullanıcı adı girin.",
        "ERROR_INPUT_VALID_USERNAME": "Kullanıcı adı yalnızca daha düşük harfler ve sayılar içerebilir ve 3 ila 20 karakter uzunluğunda olmalıdır",
        "ERROR_INPUT_PHONE": "Lütfen geçerli bir telefon numarası belirtin.",
        "ERROR_INPUT_VALID_EMAIL": "Lütfen geçerli e -postayı girin.",
        "ERROR_INPUT_EMAIL": "Lütfen e -posta girin.",
        "ERROR_INPUT_FIRSTNAME": "Lütfen ad girin.",
        "ERROR_INPUT_LASTNAME": "Lütfen soyadı girin.",
        "ERROR_INPUT_ADDRESS": "Lütfen ev adresi girin.",
        "ERROR_INPUT_CITY": "Lütfen şehri girin.",
        "ERROR_INPUT_STATE": "Lütfen durum girin.",
        "ERROR_INPUT_ZIPCODE": "Lütfen Zipcode girin.",
        "ERROR_INPUT_BIRTHDAY": "Lütfen doğum günü girin.",
        "ERROR_INPUT_NAME_OF_BANK": "Lütfen bankanın adını girin.",
        "ERROR_INPUT_NAME_OF_ACCOUNT": "Lütfen hesap sahibinin adını girin.",
        "ERROR_INPUT_ROUTING_NUMBER": "Lütfen yönlendirme numarası girin.",
        "ERROR_INPUT_BANK_NAME": "Lütfen bir banka seçin.",
        "ERROR_INPUT_ACCOUNT_NUMBER": "Lütfen bir hesap numarası girin",
        "ERROR_INPUT_CHECK_NUMBER": "Lütfen kontrol numarasını girin.",
        "ERROR_INPUT_SSN": "Lütfen SSN girin.",
        "ERROR_INPUT_DocumentId": "Lütfen belge kimliğini girin.",
        "ERROR_INPUT_SORTCODE": "Lütfen sıralama kodu girin.",
        "ERROR_INPUT_COMPANY": "Lütfen etkinleştirmek için Şirket'i seçin.",
        "ERROR_INPUT_LICENSE_PLATE": "Lütfen plaka plakasını seçin.",
        "ERROR_INPUT_ZONE": "Lütfen etkinleştirmek için bölgeyi seçin.",
        "ERROR_INPUT_SHIFT": "Lütfen etkinleştirmek için Shift'i seçin.",
        "ERROR_ACCOUNT_NUMBER_ONLY_NUMBERS": "Bu sadece sayılar içermelidir",
        "ERROR_SSN_ONLY_NUMBERS": "Bu sadece sayılar içermelidir",
        "ERROR_SSN_LENGTH": "SSN en az 4 karakter olmalı",
        "ERROR_COMMISSION_NUMBERS": "Lütfen geçerli bir numara girin",
        "INFO_DEACTIVAED_SUCCESS": "Sürücü başarıyla devre dışı bırakıldı.",
        "INFO_ACTIVAED_SUCCESS": "Sürücü başarıyla etkinleştirildi.",
        "INFO_DEACTIVAED_SUCCESS_BUT_HARDWARE": "Sürücü devre dışı bırakıldı, ancak entegre donanım ölçer beklenmedik nedenlerle kapatılamaz.",
        "INFO_MULTIDEACTIVAED_SUCCESS": "{0} seçilen sürücüler başarıyla devre dışı bırakıldı.",
        "INFO_MULTIDEACTIVAED_HARDWARE_SUCCESS": "{0} seçilen sürücüler başarıyla devre dışı bırakıldı.{1} bağlı donanım ölçer (ler) devre dışı bırakıldı.",
        "INFO_MULTIDEACTIVAED_SUCCESS_BUT_HARDWARE": "{0} seçilen sürücüler başarıyla devre dışı bırakıldı.{1} bağlı donanım ölçer (ler) devre dışı bırakıldı.{2} beklenmedik nedenlerden kaynaklanmadı.",
        "INFO_MULTIACTIVAED_SUCCESS": "{0} seçilen sürücüler başarıyla etkinleştirildi.",
        "DRIVER_UPDATE_SUCCESS": "Sürücü başarıyla güncellendi.",
        "DRIVER_CREATE_SUCCESS": "Sürücü bilgileri başarıyla eklendi",
        "Expiry_Date": "Son kullanma tarihi",
        "Case_number": "Vaka numarası",
        "Export_to_csv": "CSV'ye dışa aktarma",
        "CONFIRM_EXPORT_TO_CSV": "Tüm verileri .csv biçimi altında dışa aktarmak istediğinizden emin misiniz?",
        "Ride_sharing_tooltip": "Sürüş paylaşımı gelişmiş bir özelliktir, daha fazla bilgi almak için lütfen destek ekibimizle iletişime geçin",
        "In_Review": "İncelemede",
        "Reset_password_success": "Parolayı başarıyla sıfırlayın",
        "Topup_note": "(Sürücüyü etkinleştirdikten sonra sürücünüzün bakiyesini doldurmasına izin verilir)",
        "cash_balance": "Nakit bakiyesi",
        "cash_wallet": "Nakit cüzdan",
        "total_balance": "Toplam Denge",
        "total_cash_balance": "Toplam Nakit Bakiyesi",
        "total_credit_balance": "Toplam kredi bakiyesi",
        "banking_status": "Bankacılık Durumu",
        "bankingStatus": {
            "verified": "Doğrulanmış",
            "not_verified": "Doğrulanmadı"
        },
        "INVALID_FILE_SIZE": "Dosya boyutu 8MB'den az olmalıdır.",
        "totalDrivers": "Toplam sürücü",
        "profileStatus": "Profil Durumu",
        "profile_completed": "Profil tamamlandı",
        "profile_incomplete": "Profil eksik",
        "profile_inProgress": "Devam eden profil",
        "in_review_completed": "İncelemede (profil tamamlandı)",
        "in_review_incomplete": "İncelemede (profil eksik)",
        "in_review_inProgress": "İncelemede (devam eden profil)",
        "CASH_WALLET_DEPOSIT": "Nakit cüzdan",
        "payoutSelect": "Ödeme (bir gelen kutusu ve e -posta, ödeme sonrası sürücüye gönderilecektir)",
        "Confirm_update_cash_balance": "Nakit bakiyesini değiştir",
        "Confirm_update_cash_balance_msg": "Sürücü bakiyesini değiştirmek istediğinizden emin misiniz?",
        "cashBalance": "Nakit bakiyesi",
        "cashBalance_unavailable": "0.00",
        "cashBalance_active": "Aktif",
        "cashBalance_negative": "Negatif",
        "creditBalance": "Kredi bakiyesi",
        "ERROR_INPUT_DOCUMETID": "Lütfen belge kimliğini girin.",
        "ERROR_INPUT_EXPIRYDATE": "Lütfen son kullanma tarihini girin.",
        "driverType": "Sürücü türü",
        "tooltip_in_review_completed": "Profilini tamamlayan sürücülere 'İncelemede' gelen kutusunu gönderin.",
        "tooltip_in_review_incomplete": "Profilini tamamlamayan sürücülere 'İncelemede' gelen kutusunu gönderin.",
        "tooltip_in_review_inProgress": "Profilini tamamlayan ancak ekstra doğrulamaya ihtiyaç duyan sürücülere 'İncelemede' gelen kutusunu gönderin.",
        "tooltip_active": "Gelen kutusunu 'etkin' sürücülere gönderin.",
        "tooltip_inactive": "Hesabı yeniden etkinleştirilmiş sürücülere gelen kutusunu gönderin.",
        "active": "Etkinleştirmek",
        "inactive": "Devre dışı bırakmak",
        "inReviewInProgress": "İncelemede",
        "Market_place": "Pazar",
        "Market_place_tooltip": "Sürücüleri isteğe bağlı veya rezervasyon rezervasyonlarını kabul etmek için güçlendirin",
        "selectDriverType": "Sürücü Türünü Seçin",
        "PlsSelectDriverType": "Lütfen sürücü türünü seçin",
        "emergencyContacts": "Emergency Contacts",
        "switchVehicle": "Switch to any vehicle",
        "switchVehicleNote": "Allow driver to switch to any vehicle of their assigned supplier company.",
        "vehicleModel": "Model",
        "licensePlateNumber": "Vehicle Plate",
        "addVehicle": "Add Vehicle",
        "newVehicle": "New Vehicle",
        "linkVehicle": "Link Vehicle",
        "modal": "Modal",
        "selectVehicle": "Select Vehicle",
        "selectVehicleNote": "Enable driver to select or change vehicle for a booking.",
        "Warning_change_company": "Warning: Reassigning this driver to another company will unlink all associated vehicles."
    },
    "corporate": {
        "User_Name": "Kullanıcı adı",
        "Edit_Balance": "Bakiyeyi Düzenle",
        "Corporates": "Şirket",
        "Supplier": "Tedarikçi",
        "CarTypes": "Araba türleri",
        "Add": "Eklemek",
        "Balance": "Denge",
        "Activate": "Etkinleştirmek",
        "Deactivate": "Devre dışı bırakmak",
        "Delete": "Silmek",
        "Company_Name": "Firma Adı",
        "Admin_Name": "Yönetici Adı",
        "Username": "Kullanıcı adı",
        "Phone_Number": "Telefon numarası",
        "importBooking": "Rezervasyonları içe aktarabilir",
        "Email": "E -posta",
        "Registered_From": "Kayıtlı",
        "Registration_Date": "Kayıt tarihi",
        "Status": "Durum",
        "Actions": "Eylem",
        "Action": "Aksiyon",
        "Inactive": "Aktif olmayan",
        "Active": "Aktif",
        "In_Review": "İncelemede",
        "Edit": "Düzenlemek",
        "View": "Görüş",
        "ADD_CORPORATE_CARD": "Kurumsal kart ekleyin",
        "selectCountry": "Ülke Seçin",
        "CONFIRM_DELETE_CARD": "Bu kartları silmek istediğinden emin misiniz?",
        "ALERT_SELECT_ACTIVATE": "Lütfen etkinleştirmek için Kurumsal'ı seçin.",
        "ALERT_SELECT_DEACTIVATE": "Deactivate için lütfen Kurumsal'ı seçin.",
        "ALERT_SELECT_DELETE": "Lütfen silmek için Corporate'i seçin.",
        "CONFIRM_DELETE": "Bu şirketi silmek istiyor musunuz?",
        "CONFIRM_DELETE_USER": "Bu Corp kullanıcısını silmek istiyor musunuz?",
        "CONFIRM_DEACTIVATE_MULTI": "Bu şirketleri devre dışı bırakmak istiyor musunuz?",
        "CONFIRM_ACTIVATE_MULTI": "Bu şirketleri etkinleştirmek istiyor musunuz?",
        "CONFIRM_DELETE_MULTI": "Bu şirketleri silmek istiyor musunuz?\n** Yalnızca aktif olmayan şirketler silinebilir.",
        "ERROR_INPUT_PHONE": "Lütfen geçerli bir telefon numarası belirtin.",
        "ERROR_INPUT_VALIDPHONE": "Lütfen geçerli bir telefon numarası girin.",
        "ERROR_EXISTED_PHONE": "Bu telefon sistemde mevcuttu.Lütfen başka bir tane girin",
        "Account": "Hesap",
        "Corp_traveler": "Corp kullanıcıları",
        "Payment_methods": "Ödeme yöntemleri",
        "Corp_operators": "Corp operatörleri",
        "Pricing": "Fiyatlandırma",
        "COMPANY_INFORMATION": "Şirket bilgileri",
        "Company_name": "Firma Adı",
        "Company_ID": "Şirket Kimliği (Corp kullanıcıları kaydı için)",
        "Company_address": "Şirket adresi",
        "Invoice_email": "Fatura e -postası",
        "Confirmation_email": "Onay e -postası",
        "colorCodeBooking": "Renk kodu",
        "ADMIN_ACCOUNT": "Yönetici hesabı",
        "First_name": "İlk adı",
        "Last_name": "Soy isim",
        "Commission": "Komisyon",
        "commissionBooking": "Uygulama/PWA'dan rezervasyonlar için komisyon uygulayın",
        "Required_fields": "Gerekli Alanlar",
        "Save": "Kaydetmek",
        "Cancel": "İptal etmek",
        "Reset_password": "Parolayı sıfırla",
        "Employee_Name": "İsim",
        "Created_Date": "Oluşturulan Tarih",
        "Add_corporate_card": "Kurumsal kart ekleyin",
        "Corp_Users": "Corp kullanıcıları",
        "Pricing_Default": "Varsayılan (Normal PAX ile aynı fiyatlandırma planı)",
        "Pricing_Discount": "Yüzde göre indirim (yalnızca CORP kullanıcıları için uygulanır)",
        "Markup_By_Percent": "Yüzde göre işaretleyin (yalnızca CORP kullanıcıları için uygulanır)",
        "Pricing_Discount_new": "Discount by percentage (based on default rate)",
        "Markup_By_Percent_new": "Mark-up by percentage (based on default rate)",
        "Pricing_Add_different_rates": "Farklı oranlar ekleyin",
        "ERROR_INPUT_COMPANYNAME": "Lütfen şirket adı girin.",
        "ERROR_INPUT_USERNAME": "Lütfen kullanıcı adı girin.",
        "ERROR_INPUT_FIRSTNAME": "Lütfen ad girin.",
        "ERROR_INPUT_EMAIL": "Lütfen e -posta girin.",
        "ERROR_INPUT_VALID_EMAIL": "Geçerli bir e.",
        "ERROR_INPUT_VALID_NUMBER": "Lütfen geçerli numarayı girin",
        "ERROR_INPUT_VALID_RANGE_AMOUNT": "Lütfen {0} ve {1} arasında bir değer girin.",
        "INFO_ADD_CORPORATE_SUCCESS": "{0} hesabı başarıyla oluşturuldu.Giriş kimlik bilgileri ayrıntıları için lütfen {1} e -postasını kontrol edin.",
        "INFO_UPDATE_CORPORATE_SUCCESS": "Bilgiler başarıyla güncellendi.",
        "INFO_UPDATE_PRICING_SUCCESS": "Fiyatlandırma başarıyla güncellendi",
        "INFO_RESET_PASSWORD_SUCCESS": "{0} hesabı başarıyla sıfırlandı.Giriş kimlik bilgileri ayrıntıları için lütfen {1} e -postasını kontrol edin.",
        "COMFIRM_RESET_PASSWORD": "Parolayı sıfırlamak istiyor musunuz?",
        "Traveler_signature": "Gezgin imzası",
        "Parcel_Delivery": "Parsel Teslimatını Etkinleştir",
        "Tracking_log": "İzleme günlüğü",
        "Rating": "Derecelendirme",
        "Email_address": "E -posta adresi",
        "Assistant_email": "Asistan e -posta",
        "Corp_PO": "Corp PO",
        "Cost_centre": "Maliyet merkezi",
        "Corp_division": "Corp bölümü",
        "Corp_id": "Corp ID",
        "Department": "Departman",
        "Manager_email": "Yöneticinin e -postası",
        "Manager_name": "Yöneticinin Adı",
        "Corporate_card": "Kurumsal kart",
        "Direct_invoicing": "Doğrudan faturalandırma",
        "Corporate_Prepaid": "Kurumsal ön ödemeli",
        "Cash": "Peşin",
        "Car_Type_ID": "Araba Tipi Kimliği",
        "App_Display_Name": "Uygulama Ekran Adı",
        "Normal_Fare": "Düzenli",
        "Flat_Fare": "Sabit oran",
        "Hourly_Daily_Rate": "Saatlik/ günlük",
        "Delivery_Rate": "Teslimat",
        "Assign_rate": "Atama oranı",
        "Zone": "Alan",
        "Rate": "Oran",
        "Zone_Rate": "Bölge - Oran",
        "Please_select_rate": "Lütfen fiyat seçin",
        "Paid_by": "Ödenen",
        "Enter_new_credit_card": "Yeni kredi kartı girin",
        "TOP_UP": "Doldurmak",
        "Top_up": "Doldurmak",
        "Topup_amount": "Topup Miktarı",
        "Currency": "Para birimi",
        "ERROR_INPUT_AMOUNT": "Lütfen geçerli miktar girin.",
        "VAT_number": "KDV numarası",
        "Gender": "Cinsiyet",
        "Select_Gender": "Seçme",
        "Male": "Erkek",
        "Female": "Dişi",
        "Date_of_birth": "Doğum tarihi",
        "Street_address": "Açık adres",
        "City": "Şehir",
        "State": "Durum",
        "Zip_code": "Posta kodu",
        "Country": "Ülke",
        "adding_new_card": "%{Zonename} alanında hizmet kullanmak için yeni bir kart ekliyorsunuz",
        "No_Support": "Bu alanda yeni kart ekleme şu anda desteklenmiyor",
        "Job_title": "İş unvanı",
        "required_phone_number_payment": "Şirketin telefon numarası gereklidir.Devam etmek için lütfen profili güncelleyin.",
        "Yes": "Evet",
        "No": "HAYIR",
        "AddCardBeforeTopup": "Kredi kartı bulunamadı.Devam etmek için lütfen yeni bir kart ekleyin.",
        "flightNumber": "Uçuş numarası",
        "Company_Type": "Şirket türü",
        "Standard": "Standart kurumsal",
        "Airline": "Havayolu",
        "Branding": "Marka",
        "bgImage": "Arka plan görüntüsü (1600x900)",
        "logoImage": "Logo görüntüsü",
        "aliasTitle": "Takma ad (url) parametresi",
        "importChauffeurBooking": "Taşımacılık rezervasyonlarını içe aktarabilir",
        "isCorporateVip": "Kurumsal VIP",
        "isCorporateVipToolTip": "Bu ayarı, oluşturulduktan sonra tüm kurumsal gezginlere VIP durumu otomatik olarak atamak için etkinleştirin, her bir kullanıcı için kapatabilirsiniz.",
        "qrTitle": "QR Web booking link",
        "qrNote": "Your personalized partner booking link is here! Display it prominently in bars, hotel receptions, guest rooms, and on your website—anywhere your customers might need a ride or delivery. Consider designing standees with your unique QR code, allowing customers a quick and easy scan to book their journey!",
        "PricingLegacy": "Pricing (Legacy)",
        "defaultTemplate": "Default Template",
        "Select_Rate_Template": "Select Rate Template"
    },
    "airline": {
        "Airlines": "Havayolları",
        "Add": "Eklemek",
        "Activate": "Etkinleştirmek",
        "Deactivate": "Devre dışı bırakmak",
        "Delete": "Silmek",
        "Company_Name": "Firma Adı",
        "Admin_Name": "Yönetici Adı",
        "Username": "Kullanıcı adı",
        "Phone_Number": "Telefon numarası",
        "Email": "E -posta",
        "Registered_From": "Kayıtlı",
        "Registration_Date": "Kayıt tarihi",
        "Status": "Durum",
        "Actions": "Eylem",
        "Action": "Aksiyon",
        "Inactive": "Aktif olmayan",
        "Active": "Aktif",
        "In_Review": "İncelemede",
        "Edit": "Düzenlemek",
        "View": "Görüş",
        "ADD_CORPORATE_CARD": "Havayolu Kartı Ekle",
        "selectCountry": "Ülke Seçin",
        "CONFIRM_DELETE_CARD": "Bu kartları silmek istediğinden emin misiniz?",
        "ALERT_SELECT_ACTIVATE": "Lütfen etkinleştirmek için havayolu seçin.",
        "ALERT_SELECT_DEACTIVATE": "Lütfen devre dışı bırakmak için havayolu seçin.",
        "ALERT_SELECT_DELETE": "Lütfen silmek için havayolu seçin.",
        "CONFIRM_DELETE": "Bu havayolunu silmek ister misiniz?",
        "CONFIRM_DELETE_USER": "Bu havayolu kullanıcısını silmek istiyor musunuz?",
        "CONFIRM_DEACTIVATE_MULTI": "Bu şirketi devre dışı bırakmak istiyorsunuz?",
        "CONFIRM_ACTIVATE_MULTI": "Bu şirketi aktif olmak istediğinden emin misiniz?",
        "CONFIRM_DELETE_MULTI": "Bu şirketi silmek istediğinizden emin misiniz?\n** Yalnızca aktif olmayan şirket silinebilir.",
        "ERROR_INPUT_PHONE": "Lütfen geçerli bir telefon numarası belirtin.",
        "ERROR_INPUT_VALIDPHONE": "Lütfen geçerli bir telefon numarası girin.",
        "ERROR_EXISTED_PHONE": "Bu telefon sistemde mevcuttu.Lütfen başka bir tane girin",
        "Airline": "Havayolu",
        "Crew": "Mürettebat",
        "Payment_methods": "Ödeme yöntemleri",
        "Airline_operators": "Havayolu operatörleri",
        "Pricing": "Fiyatlandırma",
        "COMPANY_INFORMATION": "Şirket bilgileri",
        "Company_name": "Firma Adı",
        "Company_ID": "Şirket Kimliği",
        "Company_address": "Şirket adresi",
        "Invoice_email": "Fatura e -postası",
        "Confirmation_email": "Onay e -postası",
        "colorCodeBooking": "Renk kodu",
        "ADMIN_ACCOUNT": "Yönetici hesabı",
        "First_name": "İlk adı",
        "Last_name": "Soy isim",
        "Commission": "Komisyon",
        "Required_fields": "Gerekli Alanlar",
        "Required_fields_crews": "Not: Yeni Mürettebat hesabı için varsayılan şifre \"12345678\" dir",
        "Save": "Kaydetmek",
        "Cancel": "İptal etmek",
        "Reset_password": "Parolayı sıfırla",
        "Crew_Name": "Mürettebat",
        "Created_Date": "Oluşturulan Tarih",
        "Add_corporate_card": "Havayolu Kartı Ekle",
        "Corp_Users": "Operatörler",
        "Pricing_Default": "Varsayılan",
        "Pricing_Discount": "Yüzde İndirim",
        "Markup_By_Percent": "Yüzde göre işaretleme",
        "Pricing_Add_different_rates": "Farklı oranlar ekleyin",
        "ERROR_INPUT_COMPANYNAME": "Lütfen şirket adı girin.",
        "ERROR_INPUT_USERNAME": "Lütfen kullanıcı adı girin.",
        "ERROR_INPUT_FIRSTNAME": "Lütfen ad girin.",
        "ERROR_INPUT_EMAIL": "Lütfen e -posta girin.",
        "ERROR_INPUT_VALID_EMAIL": "Geçerli bir e.",
        "ERROR_INPUT_VALID_NUMBER": "Lütfen geçerli numarayı girin",
        "ERROR_INPUT_VALID_RANGE_AMOUNT": "Lütfen {0} ve {1} arasında bir değer girin.",
        "INFO_ADD_CORPORATE_SUCCESS": "{0} hesabı başarıyla oluşturuldu.",
        "INFO_UPDATE_CORPORATE_SUCCESS": "Bilgiler başarıyla güncellendi.",
        "INFO_UPDATE_PRICING_SUCCESS": "Fiyatlandırma başarıyla güncellendi",
        "INFO_RESET_PASSWORD_SUCCESS": "{0} hesabı başarıyla sıfırlandı.",
        "COMFIRM_RESET_PASSWORD": "Parolayı sıfırlamak istiyor musunuz?",
        "Traveler_signature": "Gezgin imzası",
        "Tracking_log": "İzleme günlüğü",
        "Rating": "Derecelendirme",
        "Email_address": "E -posta adresi",
        "Assistant_email": "Asistan e -posta",
        "Corp_PO": "Corp PO",
        "Cost_centre": "Maliyet merkezi",
        "Corp_division": "Corp bölümü",
        "Corp_id": "Corp ID",
        "Department": "Departman",
        "Manager_email": "Yöneticinin e -postası",
        "Manager_name": "Yöneticinin Adı",
        "Corporate_card": "Havayolu kartı",
        "Direct_invoicing": "Doğrudan faturalandırma",
        "Corporate_Prepaid": "Havayolu ön ödemeli",
        "Cash": "Peşin",
        "Car_Type_ID": "Araba Tipi Kimliği",
        "App_Display_Name": "Uygulama Ekran Adı",
        "Normal_Fare": "Düzenli",
        "Flat_Fare": "Sabit oran",
        "Hourly_Daily_Rate": "Saatlik/ günlük",
        "Delivery_Rate": "Teslimat",
        "Assign_rate": "Atama oranı",
        "Zone": "Alan",
        "Rate": "Oran",
        "Zone_Rate": "Bölge - Oran",
        "Please_select_rate": "Lütfen fiyat seçin",
        "Paid_by": "Ödenen",
        "Enter_new_credit_card": "Yeni kredi kartı girin",
        "TOP_UP": "Doldurmak",
        "Top_up": "Doldurmak",
        "Topup_amount": "Topup Miktarı",
        "Currency": "Para birimi",
        "ERROR_INPUT_AMOUNT": "Lütfen geçerli miktar girin.",
        "VAT_number": "KDV numarası",
        "Gender": "Cinsiyet",
        "Select_Gender": "Seçme",
        "Male": "Erkek",
        "Female": "Dişi",
        "Date_of_birth": "Doğum tarihi",
        "Street_address": "Açık adres",
        "City": "Şehir",
        "State": "Durum",
        "Zip_code": "Posta kodu",
        "Country": "Ülke",
        "adding_new_card": "%{Zonename} alanında hizmet kullanmak için yeni bir kart ekliyorsunuz",
        "No_Support": "Bu alanda yeni kart ekleme şu anda desteklenmiyor",
        "Job_title": "İş unvanı",
        "required_phone_number_payment": "Şirketin telefon numarası gereklidir.Devam etmek için lütfen profili güncelleyin.",
        "Yes": "Evet",
        "No": "HAYIR",
        "AddCardBeforeTopup": "Kredi kartı bulunamadı.Devam etmek için lütfen yeni bir kart ekleyin."
    },
    "message": {
        "Add": "Eklemek",
        "Delete": "Silmek",
        "Items_per_page": "Sayfa başına öğeler",
        "Page": "Sayfa",
        "Search_here": "BURADA ARAYIN",
        "Shown_Active": "{0} - {1} {2} gösterilen",
        "Date": "Tarih",
        "Subject": "Ders",
        "To": "İle",
        "Type": "Tip",
        "Result": "Sonuç",
        "Actions": "Eylem",
        "New_message": "Yeni Mesaj",
        "MESSAGE_DETAILS": "Mesaj Detayları",
        "All": "Tüm",
        "Company": "Şirket",
        "Active": "Aktif",
        "In_Active": "Aktif olmayan",
        "In_Review": "İncelemede",
        "Send_to": "Göndermek",
        "All_drivers": "Tüm Sürücüler",
        "All_customers": "Tüm Müşteriler",
        "All_merchants": "Tüm tüccarlar",
        "Customized_driver": "Özelleştirilmiş sürücü",
        "Customized_customer": "Özelleştirilmiş müşteri",
        "Customized_merchant": "Özelleştirilmiş tüccar",
        "Receiver_list": "Alıcı listesi",
        "Content": "İçerik",
        "Required_fields": "Gerekli Alanlar",
        "Search_driver_name_driver_phone_number": "Arama Sürücü Adı, Sürücü Telefon Numarası",
        "Search_category_name": "Arama kategorisi adı",
        "Search_customer_name_customer_phone_number": "Müşteri Adını Arayın, Müşteri Telefon Numarası",
        "Search_merchant_name_merchant_phone_number": "Satıcı adı, satıcı telefon numarasını arayın",
        "Search_car_type": "Araba Tipi Arama",
        "Searching": "Arama...",
        "No_results": "Sonuç yok",
        "Send": "Göndermek",
        "Cancel": "İptal etmek",
        "SMS_failed_Inbox_succeeded": "SMS başarısız oldu, gelen kutusu başarılı oldu",
        "Failed": "Arızalı",
        "Succeeded": "Başarılı oldu",
        "CONFIRM_DELETE": "Bu mesajı silmek istiyor musunuz?",
        "CONFIRM_DELETE_MULTI": "Bu mesajları silmek istiyor musunuz?",
        "ALERT_SELECT_DELETE": "Lütfen silmek için mesajı seçin.",
        "ERROR_INPUT_RECEIVERLIST": "Lütfen mesaj göndermek için en az bir sürücü seçin",
        "ERROR_INPUT_CUSTOMER_RECEIVERLIST": "Lütfen mesaj göndermek için en az bir müşteri seçin",
        "ERROR_INPUT_SUBJECT": "Lütfen Konu Giriş",
        "ERROR_INPUT_CONTENT": "Lütfen içerik girin",
        "ERROR_INPUT_LEAST_ONE_SUBJECT_CONTENT": "Lütfen devam etmek için en az bir dil ayarlayın",
        "ERROR_INPUT_SUBJECT_MULTI_LANGUAGE": "Lütfen {0} dili için konuyu girin",
        "ERROR_INPUT_CONTENT_MULTI_LANGUAGE": "Lütfen {0} dili için içerik girin",
        "ERROR_INPUT_URL_MULTI_LANGUAGE": "{0} dili için lütfen URL girin",
        "ERROR_INPUT_TITLE_MULTI_LANGUAGE": "{0} dili için lütfen başlık girin",
        "ERROR_INPUT_BODY_MULTI_LANGUAGE": "{0} dil için lütfen gövde girin",
        "import_fail_success_driver": "%{başarı} sürücü (ler) başarıyla içe aktarıldı, %{başarısız} başarısız oldu.",
        "import_fail_success_passenger": "%{başarı} Müşteri (ler) başarıyla içe aktarıldı, %{başarısız} başarısız oldu.",
        "import_fail_success_merchant": "%{başarı} satıcı (lar) başarıyla ithal edildi, %{başarısız} başarısız oldu.",
        "CONFIRM_SEND_SUCCESS": "Mesajınız gönderildi.Bu birkaç dakika sürebilir.Sistem isteğinizi işlerken lütfen bekleyin.",
        "Load_message_and_sms_fail": "Hatalar, mesaj ve SMS ayrıntılarını yüklerken meydana geldi",
        "Update_message_and_sms_fail": "Hatalar, mesaj ve SMS ayrıntılarını güncellerken meydana geldi",
        "Update_message_and_sms_success": "Mesaj ve SMS ayrıntıları başarıyla güncellendi",
        "Driver_List": "Sürücü listesi",
        "Car_Type_List": "Araba Türü Listesi",
        "Search_vehicle_type_name": "Araç Türü Adı Arama",
        "ALERT_PROCESSING_DELETE": "İsteğiniz işleniyor.Bu birkaç saniye sürebilir",
        "Customer": "Müşteri",
        "Driver": "Sürücü",
        "StatusOfCustomer": "Müşterinin Durumu",
        "StatusOfDriver": "Sürücünün Durumu",
        "StatusOfMerchant": "Tüccarın Durumu",
        "SendTo": "Göndermek",
        "DownloadReceiverList": "Alıcı Listesini İndir",
        "warningInputChangeOver": "Son 2 girdi arasında% 20'den fazla göreceli bir değişiklik var.Lütfen düşünün!",
        "NoteDownloadReveverList": "1 ay sonra alıcılar listesini indirme devre dışı bırakılacaktır.",
        "Load_voip_fail": "Hatalar VoIP ayrıntıları yüklenirken meydana geldi.",
        "Update_voip_success": "VoIP ayrıntıları başarıyla güncellendi.",
        "Update_voip_fail": "Hatalar VoIP ayrıntılarını güncellerken meydana geldi.",
        "expiryDate": "Yeni kullanıcıya kadar gönderin",
        "expiryDate_placeholder": "Son kullanma tarihi (mm/dd/yy)",
        "NA": "N/A",
        "PleaseSelectStatusOfDriver": "Lütfen Sürücünün Durumunu Seçin",
        "versionApp": "Uygulama sürümü",
        "platform": "Platform",
        "contentType": "İçerik Türü",
        "url": "Url",
        "title": "Başlık",
        "contentId": "İçeriksiz",
        "body": "Vücut",
        "image_notification": "İmaj",
        "platformItem": {
            "all": "Tüm",
            "iOS": "iOS",
            "android": "Android"
        },
        "comparisonVersionApp": {
            "is": "Ki",
            "is_not": "Değil",
            "is_one_of": "Biri",
            "is_not_one_of": "Biri değil",
            "greater_or_equal": "Daha büyük veya eşit",
            "less_or_equal": "Daha az veya eşit"
        },
        "contentTypeItem": {
            "html": "HTML",
            "link": "Bağlantı"
        },
        "Please_input": "Bu alan gerekli"
    },
    "fleetInfo": {
        "Fleet_name": "Filo adı",
        "Phone_number": "Telefon numarası",
        "Email_address": "E -posta adresi",
        "Country": "Ülke",
        "Time_zone": "Zaman dilimi",
        "Address": "Adres",
        "Website": "Web sitesi",
        "Currency": "Para birimi",
        "Distance_unit": "Mesafe ünitesi",
        "Load_fleet_info_fail": "Hatalar filo bilgilerini yüklerken meydana geldi"
    },
    "surchargePolicy": {
        "Surchage_Policy": "Surcharge Policy",
        "SurgePolicy": "Surge Policy",
        "surgeType": "Surge Type",
        "bookingTimeCoverage": "Booking Time Coverage",
        "Delete_surcharge": "Are you sure you want to delete the selected surcharge?",
        "Delete_surcharge_item": "Are you sure you want to delete the selected surcharge item?",
        "Delete_surcharge_item_success": "Surcharge has been deactivated successfully",
        "Delete_surcharge_item_fail": "The errors occurred while deactivating surcharge item",
        "linkNewPolicy": "Link a new policy",
        "Link": "Link",
        "ViewPolicy": "View Policy",
        "Unlink": "Unlink",
        "Create_Surcharge_Item_success": "Surcharge item has been created successfully",
        "Create_Surcharge_Item_fail": "The errors occurred while creating surcharge item",
        "Update_Surcharge_Item_success": "Surcharge item has been updated successfully",
        "Update_Surcharge_Item_fail": "The errors occurred while updating surcharge item",
        "single": "Single Day",
        "yearly": "Yearly",
        "weekly": "Day of week",
        "Please_select_at_least_one_day": "Please select at least one day"
    },
    "penaltyPolicy": {
        "penaltyPolicy": "Penalty Policy",
        "Reservation": "Reservation",
        "onDemand": "On-demand",
        "noShow": "No Show",
        "freeCancellation": "Free cancellation buffer",
        "fieldExclusions": "Included Fields",
        "penaltyActive": "Cancel Duration Tier - before pickup time",
        "penaltyType": "Penalty Type",
        "Compensation": "Supplier/Driver Compensation",
        "Create_penalty_success": "Penalty template has been created successfully",
        "Create_penalty_fail": "The errors occurred while creating penalty template",
        "Update_penalty_success": "Penalty template has been updated successfully",
        "Update_penalty_fail": "The errors occurred while updating penalty template",
        "confirmDelete": "Confirm Delete",
        "confirmDeleteContent": "Are you sure you want to delete this penalty policy? Deleting this policy will unlink from all the booking rates.",
        "Delete_success": "Penalty template has been deleted successfully",
        "Delete_fail": "The errors occurred while deleting penalty template",
        "payoutPolicy": "Payout Policy",
        "commission": "Percentage of Customer Penalty",
        "commissionPayout": "Percentage of Supplier Payout",
        "fixAmount": "Amount",
        "amount": "Amount",
        "percent": "Percentage",
        "percentPayout": "% of Payout ",
        "percentFare": "% of Fare",
        "penaltyFee": "Cancellation Fee",
        "commissionType": "Comp Type",
        "commissionFee": "Compensation",
        "tax": "Tax",
        "techFee": "Tech Fee",
        "minute": "minute",
        "hour": "hour"
    },
    "rateTemplates": {
        "Rate_Templates": "Rate Templates",
        "newRate": "New Rate Template",
        "placeHolderName": "Enter Rate Template Name",
        "Assign_Rate": "Assign Rate",
        "carTypeName": "Car Type Name",
        "appDisplayName": "App Display Name",
        "assign_rate_success": "Car Type has been assigned rate successfully",
        "assign_rate_fail": "The errors occurred while assigning rate",
        "confirmDeleteTitle": "Confirm Delete",
        "confirmDelete": "Are you sure you want to delete this template? Deleting this template will unlink from all the partners/corp it links to.",
        "setDefaultSuccess": "Default rate template has been set successfully",
        "setDefaultFail": "The errors occurred while setting default rate template",
        "Delete_rate_template_success": "Rate template has been deleted successfully",
        "Delete_rate_template_fail": "The errors occurred while deleting rate template",
        "setDefault": "Set as Default",
        "Regular": "Regular rate",
        "Sharing": "Street Sharing Rate",
        "Flat": "Flat Rate",
        "Hourly": "Hourly/ Daily",
        "Delete_default": "If you try to delete the default template, we should not allow till they set another default rate."
    },
    "supplierPlan": {
        "Supplier_Plan": "Supplier Plan",
        "rateTemplate": "Rate Schema",
        "rateTemplateNote": "the fare calculation used to determine the amount paid to the driver or supplier.",
        "percent": "Fleet Commission",
        "percentNote": " the percentage charged by the fleet on each booking.",
        "amount": "Fixed Amount",
        "amountNote": " the amount the fleet deducts for each booking.",
        "supplierPenaltyId": "Supplier Penalty Policy",
        "payoutType": "Type",
        "Select_penalty_policy": "Select Penalty Policy",
        "Select_supplier_plan": "Select Supplier Plan",
        "confirm_delete": "Are you sure you want to delete this plan? Deleting this plan will unlink from all the suppliers it links to and use the default.",
        "Create_supplier_plan_success": "Supplier plan has been created successfully",
        "Create_supplier_plan_fail": "The errors occurred while creating supplier plan",
        "Update_supplier_plan_success": "Supplier plan has been updated successfully",
        "Update_supplier_plan_fail": "The errors occurred while updating supplier plan",
        "Payout_Plan": "Payout Plan",
        "Payout_Plan_note": "Set up how much the supplier is paid out.",
        "default": "Default",
        "custom": "Custom Plan",
        "cannotSetPayout": "Cannot set payout plan for external partner’s driver."
    },
    "generalSetting": {
        "alertNotifications": {
            "title": "Alert notifications",
            "emails": "Emails",
            "emailsPlaceholder": "Enter email",
            "slack": "Slack Channel (Instructions)",
            "slackPlaceholder": "Slack POST URL",
            "discord": "Discord Channel (Instructions)",
            "discordPlaceholder": "Enter Discord Channel",
            "telegram": "Telegram Channel (Instructions)",
            "telegramPlaceholder": "Enter Telegram Channel"
        },
        "showFare": {
            "title": "Sürücüye Ücret Göster",
            "totalFare": "Toplam yemek",
            "estimateGross": "Brüt Kazanç Tahmin Et"
        },
        "carHailing": "Sokak selamlama",
        "regular": "Düzenli",
        "cancelTimeout": "Zaman aşımı rezervasyonlarını otomatik olarak iptal edin",
        "allowStripePaymentLink": "Stripe ödeme bağlantısı oluşturmaya izin ver",
        "flat": "Düz",
        "techFee": "İşlem başına teknoloji ücreti",
        "customizeLogo": "Gösterge Tablosu Logosunu Özelleştir",
        "logo": "Logo",
        "customerApp": "Müşteri Uygulaması",
        "hourly": "Saatlik",
        "shuttle": "Servis",
        "parcel": "Parsel",
        "freeCancelTime": "Günde Ücretsiz İptal Süreleri",
        "AM": "Ben",
        "PM": "ÖĞLEDEN SONRA",
        "RUSH_HOUR": "Fazla yüklemek",
        "Name": "İsim",
        "Date": "Tarih",
        "Mon": "Mon",
        "Tue": "Salı",
        "Wed": "Evlenmek",
        "Thu": "Thu",
        "Fri": "Cum",
        "Sat": "Doygunluk",
        "Sun": "Güneş",
        "Time_range": "Zaman aralığı",
        "From": "İtibaren",
        "To": "İle",
        "Rush_hour_time_calculation": "Ek ücret süresi hesaplaması",
        "Rush_hour_surcharge": "Fazla yüklemek",
        "Required_fields": "Gerekli Alanlar",
        "Save": "Kaydetmek",
        "Cancel": "İptal etmek",
        "MDISPATCHER_CANCELLATION": "PAX / Partner / Web Rezervasyon İptali",
        "Book_now": "Şimdi kitap",
        "MDISPATCHER_CANCELLATION_TOOLTIP": "PAX / Partner / Web Rezervasyon İptali",
        "Book_now_toolTip": "İsteğe bağlı rezervasyonlar içinde iptal edilirse iptal ücreti uygulanabilir",
        "Book_later_toolTip": "Bir rezervasyon, toplama süresinden önce iptal edilirse iptal ücreti uygulanabilir",
        "Drv_Book_now_toolTip": "İsteğe bağlı rezervasyonlar belirlenen süre içinde iptal edilirse, kabul süresinden iptal edilirse iptal ücreti uygulanır",
        "Drv_Book_later_toolTip": "Önceden rezervasyonlar belirli bir süre içinde, beklenen alma süresinden önce iptal edilirse iptal ücreti uygulanabilir",
        "Free_cancel": "Ücretsiz İptal Süresi",
        "Hide_cancel_btn": "Ayarlanan zamanlardan sonra iptal düğmesini gizle",
        "Driver_cancellation": "Sürücü İptali",
        "Time_day": "Günde Kez",
        "Duration": "Süre",
        "Times": "Kez",
        "Book_later": "Daha sonra kitap",
        "Intercity": "Şehirlerarası",
        "Intercity_tooltip": "İptal ücreti, bir şehirlerarası rezervasyon, toplama süresinden önce belirlenen süre içinde iptal edilirse geçerlidir.",
        "MDISPATCHER_NO_SHOW": "Gösteri Yok",
        "AIRPORT_FEE": "Havaalanı ücreti",
        "From_airport": "Havaalanından",
        "To_airport": "Havaalanına",
        "MEET_DRIVER": "Şoförle tanışmak",
        "On_curb": "Kaldırımla",
        "Meet_Greet": "Buluş ve selam",
        "Add": "Eklemek",
        "Edit": "Düzenlemek",
        "DELETE_SURCHARGE": "Ek ücret silme",
        "DELETE_SERVICE": "Hizmeti Sil",
        "CHANGE_APPLY_SERVICE_FEES": "Hizmet Ücretlerini Değiştir",
        "DELETE_CONFIRM": "Bu öğeyi silmek istediğinizden emin misiniz?",
        "DELETE_SURCHARGE_CONFIRM": "Seçilen ek ücretini silmek istediğinizden emin misiniz?",
        "DELETE_ADDITIONAL_SERVICES_CONFIRM": "Seçilen hizmeti silmek istediğinizden emin misiniz?",
        "CHANGE_APPLY_SERVICE_FEES_CONFIRM_CUSTOM": "Ayrılmış bölgeler için farklı ücretler uygulamak istediğinizden emin misiniz?",
        "CHANGE_APPLY_SERVICE_FEES_CONFIRM_ALL": "Tüm bölgeler için aynı ücretleri uygulamak istediğinizden emin misiniz?",
        "EDIT_SERVICE_FEES": "Hizmet Ücretlerini Düzenle",
        "EDIT_SERVICE_FEES_CONFIRM": "%{CurrentZone} için ayarları kaydetmeden ayrılmak istediğinizden emin misiniz?",
        "HEAVY_TRAFFIC": "Yoğun trafik",
        "Heavy_traffic_surcharge": "Yoğun trafik ek ücret",
        "TOLL_FEE": "Ücret ücreti",
        "parkingFee": "Otopark ücreti",
        "gasFee": "Gaz ücreti",
        "OTHER_FEES": "Diğer Ücretler",
        "Can_edit": "Düzenleyebilir",
        "Can_add_note": "Not ekleyebilir",
        "Other_fees_amount": "Diğer Ücret Tutarı",
        "TAX": "VERGİ",
        "Tax_surcharge": "Vergi ek ücret",
        "TIP": "Uç",
        "Default_tip": "Varsayılan ipucu",
        "RESERVATION_REMINDER": "Rezervasyon Hatırlatıcısı",
        "Driver_app_SMS_alert_before": "Sürücü Uygulaması - SMS ve Alert Önce",
        "notifyWhenDriverFailStartTrip": "Notify operator if driver fails to start trip before pickup time",
        "notifyWhenDriverFailStartTripTooltip": "Notifications will be displayed in the platform’s notifications section. You can also configure alerts to be sent via email, Slack, Discord, or Telegram for timely updates.",
        "Pax_app_alert_before": "PAX uygulaması - daha önce uyarı",
        "Confirm_driver_info_via_email_before": "Daha önce müşteriye e -posta / SMS rezervasyonu onayı gönderin",
        "Confirm_driver_info_via_email_before_hint": "Sistem, toplama süresinden önce, belirlenen zamanınıza göre müşteriye bir rezervasyon onayı gönderecektir.",
        "Show_request_tab_on_driver_app": "Sürücü uygulamasında 'istek' sekmesini göster",
        "Show_preferred_tab_on_driver_app": "Sürücü uygulamasında 'Tercih Edilen' sekmesini göster",
        "Sound_notification": "Ses Bildirimi",
        "Advance_information": "İleri bilgi kurumsal kullanıcılar",
        "Load_general_fail": "Hatalar genel ayarı yüklerken meydana geldi",
        "Update_general_success": "Genel ayar başarıyla güncellendi",
        "Update_general_fail": "Genel ayarı güncellerken hatalar meydana geldi",
        "Time_over_lap": "Zaman aralıkları birbirleriyle örtüşmemelidir",
        "Allow_passenger_to_register_as_corp_traveler": "Yolcunun Corp kullanıcısı olarak kaydolmasına izin verin",
        "Not_Allow_Make_Booking_Without_Assign_Fare": "Ücret atamıyorsa PWA ve uygulamada araba türü göstermeyin (v4.6.60 ve üstü)",
        "Not_Allow_show_car_type_on_cc": "Ücret atamıyorsa, CC'de araba türü göstermeyin",
        "Disable_Direct_Invoicing_Receipt_Email": "Doğrudan faturalandırma makbuzu e -postasını devre dışı bırakın",
        "Additional_services": "Ek Hizmetler",
        "Edit_additional_services": "Ek Hizmetleri Düzenle",
        "Service_Fee_By_Currencies": "Hizmet ücreti",
        "Service_type": "Hizmet türü",
        "Optional": "İsteğe bağlı",
        "Compulsory": "Zorunlu",
        "Vehicle_type": "Araç tipi",
        "Adding_additional_service_success": "Yeni hizmet başarıyla yaratıldı",
        "Update_additional_service_success": "Hizmet başarıyla güncellendi",
        "Delete_additional_service_success": "Hizmet başarıyla silindi",
        "Adding_additional_service_fail": "Hizmet ekleyemedi",
        "Update_additional_service_fail": "Hizmeti güncelleyemedi",
        "Other_settings": "Diğer Ayarlar",
        "Operator_assign_error": "Operatör atarken hatalar vardı",
        "Create_surcharge_success": "Yeni ek ücret başarıyla yaratıldı",
        "Update_surcharge_success": "Ek ücret başarıyla güncellendi",
        "Delete_surcharge_success": "Ek ücret başarıyla silindi",
        "Create_surcharge_fail": "Ek yükü yeniden oluşturamadı",
        "Update_surcharge_fail": "Ek ücrete güncellenemedi",
        "Delete_surcharge_fail": "Ek ücret silmemedi",
        "Get_surcharge_detail_fail": "Ek ücret detay alamadı",
        "Repeat": "Tekrarlamak",
        "DayOfWeek": "Haftanın Günü",
        "SingleDay": "Tek gün",
        "Yearly": "Yıllık",
        "Backup_phone_number": "Yedek numara",
        "Backup_phone_number_hint": "Sürücü ve yolcu operasyon bölgelerinden çıktığında doğrudan bu numaraya",
        "Apply_countdown_clock": "Geri Sayım Saati Uygula",
        "No_show_duration_tooltip": "Sürücüye Dokunma Düğmesine Dokunduktan Sonra Sürücü Uygulamasına Dokunma Düğmesi'ni görüntülemek için zaman ayarlayın",
        "Limit_radius": "Sınır yarıçapı",
        "Limit_radius_tooltip": "Sürücü bir rezervasyonu bu yarıçap içinde gösterişsiz olarak bildirebilir",
        "Limit_radius_arrive_pickup": "Limit Radius sürücüsü gelebilir ve ulaşım rezervasyonu için pikap",
        "Booking_validation": "Rezervasyon Doğrulamaları",
        "Invalid_period_of_time": "Geçersiz zaman aralığı",
        "hide_Provider_Filter_From_Corporate_Board": "Kurum Kurulu'ndan Sağlayıcı Filtresini Gizle",
        "customer_email_is_optional": "Müşterinin e -postası Yolcu uygulamasında isteğe bağlıdır",
        "disable_hourly_rate": "Hem saatlik hem de sabit oran mevcut olduğunda saatlik fiyatı devre dışı bırakın",
        "customer_gender_is_compulsory": "Müşterinin Cinsiyeti Yolcu Uygulamasında Zorunlu",
        "SHOW_MAP_STATUS": "Harita Durumunu Göster",
        "Pay_to": "Ödemek",
        "EMERGENCY_SOS": "Acil durum S.O.S",
        "customer_address_is_compulsory": "Müşterinin Adresi Yolcu Uygulamasında Zorunlu",
        "customer_id_is_compulsory": "Müşterinin Kimliği# / National IC# Yolcu uygulamasında zorunludur",
        "allowPassengerToUploadDocument": "Yolcunun kimlik yüklemesine izin verin# / National IC# belgesi",
        "compulsoryNationalIcDocumentInPaxApp": "Müşterinin Kimliği# / National IC# belgesi PAX uygulamasında zorunludur",
        "doNotAllowAddDriverWhenNationalIdDuplicated": "Ulusal kimlik çoğaltıldığında operatörün sürücü eklemesine izin vermeyin",
        "forceCustomerToUseLiveLocation": "Müşteriyi canlı konumu kullanmaya zorlayın",
        "disableMarketPlace": "Yeni sürücüler için pazar yerini devre dışı bırakın",
        "dontShowSecondStageImmediately": "Aşama 2 Kayıtlarını Gizle",
        "compulsoryCarInsurance": "Sürücü Araba Sigortası Zorunlu",
        "marketPlace": "Pazar yeri",
        "Enable": "Olanak vermek",
        "Disable": "Devre dışı bırakmak",
        "showTotalFareWithoutPromotionOnDriverApp": "Sürücü uygulamasında tanıtım yapmadan toplam ücret göster",
        "showTotalFareWithoutPromotionOnDriverApp_hint": "- Taşıma: Sürücü ödeme yapmadan önce bu ayarı uygulayın.<br/> - Teslimat ve Şehirlerarası: Bu ayarı sürücü başlatmadan önce uygulayın.",
        "hideFareOnPaxAppForAllCorporateBookings": "Tüm Kurumsal Rezervasyonlar için Pax Uygulamasında Ücreti Gizle",
        "hidePaxNameAvatarOnDispatchScreen": "Ekranda müşterinin adını ve avatarını gizle",
        "showFullCustomerNameOnDriverApp": "Sürücü uygulamasında tam müşteri adını göster",
        "hideDriverLogoutBtn": "Sürücü uygulamasında sürücü oturum açma düğmesini gizle",
        "showMeetAndGreetButtonForEveryBooking": "Her rezervasyon için buluşma ve selamlama düğmesini göster",
        "settings": "Ayarlar",
        "Default_value": "Varsayılan değer",
        "limitTopupAmount": {
            "valueByCurrencies": "Uyarı miktarı",
            "minimumByCurrencies": "Minimum Top Yukarı Miktar",
            "maximumByCurrencies": "Maksimum Top Yukarı Miktar",
            "title": "Minimum ve maksimum doldurma miktarı"
        },
        "driverApp": "Sürücü uygulaması",
        "passengerApp": "Yolcu uygulaması",
        "Default_Topup_Values": "Varsayılan Top Up Değerleri",
        "service_fees": "Hizmet ücretleri",
        "commission_fleet": "Varsayılan Filo Komisyonu",
        "apply_all": "Tüm çalışma bölgeleri için aynı",
        "apply_custom_zone": "Tüm çalışma bölgeleri arasında farklılaşmış",
        "apply": "Uygula",
        "tollFeeDriverCanInput": "Sınır Miktar Sürücüsü Girebilir",
        "unlimited": "Sınırsız",
        "limited": "Sınırlı",
        "sameFee": "Aynı ücret",
        "customizedAirportfee": "Özelleştirilmiş Havaalanı Ücreti",
        "noteAirportFeeModal": "Not: Havaalanı ücreti yalnızca listedeki havaalanları için uygulanır.",
        "intercity_schedule_trip_time_range": "Rezervasyon süresinin kronolojik sırasını sağlamak için lütfen tercih edilen/istek gezilerinin zaman aralığının program gezilerinden daha büyük olduğundan emin olun",
        "airportFee": {
            "tooltipApply": "Varsayılan olarak / havaalanına her seyahat için varsayılan havaalanı ücreti uygulanacaktır.Havaalanı ücreti özelleştirilirse, o yer için yeni ücret uygulanacaktır.",
            "titleSearchAiportZone": "Lütfen havaalanınızı arayın ve ücretinizi ekleyin",
            "airport": "Havalimanı",
            "currency": "Para birimi",
            "fromAirportFee": "Havaalanı ücretinden",
            "toAirportFee": "Havaalanı ücretine",
            "action": "Eylem"
        },
        "Yes": "Evet",
        "No": "HAYIR",
        "tipPaymentMethod": "Müşterinin siparişten sonra sürücüsüne bahşiş vermesi için ödeme yöntemi",
        "allowPaxUpdateTip": "Allow passenger update tip percent during ride ",
        "tipDefaultValues": "Sürüşten sonra ipucu için varsayılan değerler",
        "creadit": "Kredi Kartı (Kişisel Kart / Kurumsal Kartı)",
        "wallet": "Cüzdan",
        "tnGeWallet": "Tng ewallet",
        "vippseWallet": "Vippler",
        "DurationRegularBooking": "Ücretsiz bekleme süresi",
        "googlePay": "Google Pay",
        "applePay": "Elma ödemesi",
        "DurationRegularBookingToolTip": "Bu, sürücünün müşteriyi beklemesi gereken ücretsiz bekleme süresinin süresidir.Ücretsiz bekleme süresi geçene kadar rezervasyonu şov olarak etiketleyemezler.",
        "noShowCountDown": {
            "customizedNoShow": "Belirli yerler için özelleştirme (örneğin havaalanı ...)",
            "customizedNoShowTitle": "Belirli yerler için gösterişsiz sayma süresi uygulayın (örneğin havaalanı ...)"
        },
        "maxWaitTime": "Maksimum ekstra bekleme süresi",
        "maxWaitTimeToolTip": "Bu, müşteriye tahsil edilecek maksimum ekstra bekleme süresidir.Oran ayarlarınızda dakika başına ücreti özelleştirme esnekliğine sahipsiniz.",
        "maxWaitTimePlaceholder": "Maksimum şarj bekleme süresi",
        "HEAT_MAP": "Isı haritası",
        "heatMap": {
            "defaultMode": "Varsayılan mod",
            "radius": "Yarıçap",
            "intensity": "Yoğunluk",
            "opacity": "Açıklık",
            "requestedBookings": "İstenen rezervasyonlar",
            "activePassengers": "Aktif yolcular",
            "completedBookings": "Tamamlanan rezervasyonlar",
            "timeoutBookings": "Zaman Kapısı Rezervasyonları",
            "tooltipRadius": "Her veri noktası için, piksel cinsinden etki yarıçapı.",
            "tooltipIntensity": "Isı haritasının maksimum yoğunluğu.",
            "tooltipOpacity": "0 ile 1 arasında bir sayı olarak ifade edilen ısı haritasının opaklığı."
        },
        "schedule_trip": "Plan gezisi",
        "driver_starts_trip_before_set_time": "Sürücü, ayarlamadan önce yolculuğa başlar",
        "request_trip": "Talep gezisi",
        "preferred_trip": "Tercih Edilen Gezi",
        "time_range": "Talep ve tercih edilen yolculuk için zaman aralığı",
        "working_time": "Çalışma süresi",
        "hour": "saat",
        "hours": "saat",
        "minutes": "dakikalar",
        "indicate_pickup_time_in_advance_to_customer": "Müşteriye önceden pikap süresini belirtin.",
        "customers_can_select_start_time_and_request_intercity_trip": "Müşteriler başlangıç ​​zamanını seçebilir ve şehirlerarası gezi isteyebilir.Sürücüler ayrıca kendi programlarıyla tercih ettikleri gezi oluşturabilirler.Örnek: 1 saatlik bir zaman aralığı, müşterilerin ve sürücülerin bir şehirlerarası gezisi oluşturmalarına izin verecektir - 09:00, 09:00 - 10:00 veya 10:00 - 11:00.",
        "this_setting_limits_customers_to_create_request_trip_within_working_hours_only": "Bu ayar, müşterileri yalnızca çalışma saatleri içinde istek gezisi oluşturmaları için sınırlar.",
        "face_mask_verify": "Yüz Maske Doğrulaması",
        "faceMask_allZones": "Tüm Bölgeler",
        "faceMask_customized": "Özelleştirilmiş bölge",
        "turn_on_verify": "Doğrulamayı aç",
        "force_to_wear_face_mask": "Yüz Maskesi Giyme Gücü",
        "time_range_to_verify": "İçinde tekrar doğrulama",
        "customizedFaceMaskZone": "Özelleştirilmiş yüz maskesi doğrulaması",
        "zoneOperation": "Operasyon bölgesi",
        "FaceMaskCustomized": "Özelleştirilmiş",
        "time": "zaman",
        "day": "gün",
        "days": "günler",
        "allow_driver_to_skip_verification_after": "Sürücünün doğrulamayı atlamasına izin verin",
        "driver_need_to_verify": "Sürücünün doğrulaması gerektiğinde",
        "open_app": "Açık Uygulama",
        "start_reservation": "Rezervasyona Başla",
        "choose_at_least_one": "Devam etmek için en az bir eylem seçmelisiniz.",
        "face_mask_customized_fail": "Devam etmek için en az bir eylem seçmelisiniz ({0}).",
        "vehicleAgeLimit": "Araç Yaş Sınırı",
        "vehicleAgeLimit_years": "yıl",
        "founderFund_firstBooking": "Kurucu Fonu: Yalnızca İlk Rezervasyon",
        "distance_diversity": "Mesafe çeşitliliği",
        "Add_Surcharge_Distance": "Eklemek",
        "from": "İtibaren",
        "to": "İle",
        "surchargeValue": "Fazla yüklemek",
        "actions": "Aksiyon",
        "please_enter_distance_range_valid": "Mesafe %{name} geçersiz!",
        "please_enter_distance_to": "Lütfen \"",
        "please_enter_surchargeValue": "Mesafe aralığı için lütfen \"ek ücret\" mesafesini girin {name}",
        "please_enter_surchargeValue_is_number": "Lütfen mesafe aralığı %{name} için geçerli numarayı girin",
        "Update_service_fee_success": "Hizmet ücretleri başarıyla güncellendi",
        "Update_service_fee_fail": "Hizmet ücretlerini güncellerken hatalar meydana geldi",
        "Update_fleet_commission_success": "Varsayılan filo komisyonu başarıyla güncellendi",
        "Update_fleet_commission_fail": "Varsayılan filo komisyonunu güncellerken hatalar meydana geldi",
        "RESERVATION_POLICY": "Rezervasyon politikası",
        "default_expected_pickup_time": "Varsayılan beklenen toplama süresi",
        "default_expected_pickup_time_hint": "Bir sürücünün toplama süresinden gelmesini beklediğiniz varsayılan değer.",
        "same_original_pickup_time": "Orijinal toplama süresi ile aynı",
        "quarter_before": "15 dakika önce",
        "half_before": "30 dakika önce",
        "quarter_after": "15 dakika sonra",
        "half_after": "30 dakika sonra",
        "custom": "Gelenek",
        "min_before": "Min Önce",
        "min_after": "Min sonra",
        "TransactionFee": "İşlem ücreti",
        "creditCardPercent": "Kredi kartı/ ön ödemeli",
        "directBilling": "Doğrudan faturalandırma",
        "externalCard": "External card"
    },
    "social": {
        "pax_app": "Yolcu uygulaması",
        "driver_app": "Sürücü uygulaması",
        "default": "Varsayılan",
        "customized": "Özelleştirilmiş",
        "html": "HTML",
        "about": "Hakkında",
        "email": "E -posta Desteği",
        "enablePhone": "Telefon numarası",
        "phone": "",
        "policy": "Link Gizlilik Politikası",
        "termOfUse": "Bağlantı Kullanım Koşulları",
        "site": "Web sitesi",
        "logoDriverIOS": "Logo - iOS",
        "logoDriverAndroid": "Logo - Android",
        "htmlUrl": "Html dosyası",
        "Update_fail": "Sosyal güncellenirken hatalar meydana geldi",
        "Update_success": "Sosyal başarıyla güncellendi"
    },
    "user": {
        "Show_per_page": "Sayfa başına öğeler",
        "Page": "Sayfa",
        "Search_here": "BURADA ARAYIN",
        "show_pages": "{0} - {1} {2} gösterilen",
        "Username": "Kullanıcı adı",
        "Name": "İsim",
        "Phone_number": "Telefon numarası",
        "Email": "E -posta",
        "Permission": "İzin",
        "Status": "Durum",
        "Actions": "Eylem",
        "Active": "Aktif",
        "Inactive": "Aktif olmayan",
        "Edit": "Düzenlemek",
        "Deactivate": "Devre dışı bırakmak",
        "Delete": "Silmek",
        "Add": "Eklemek",
        "ADD_USER": "Kullanıcı ekle",
        "First_name": "İlk adı",
        "Last_name": "Soy isim",
        "AgendId": "Kullanıcı #",
        "Email_address": "E -posta adresi",
        "Address": "Adres",
        "Required_fields": "Gerekli Alanlar",
        "Save": "Kaydetmek",
        "Cancel": "İptal etmek",
        "EDIT_USER": "Kullanıcı Düzenle",
        "Yes": "Evet",
        "DELETE_USER": "Kullanıcıyı sil",
        "DETAIL_USER": "Detay kullanıcısı",
        "DELETE_CONFIRM": "Seçilen kullanıcıyı silmek istediğinizden emin misiniz?",
        "Update_user_fail": "Hatalar kullanıcı güncellenirken meydana geldi",
        "Update_user_success": "Kullanıcı başarıyla güncellendi",
        "Create_user_fail": "Hatalar kullanıcı oluştururken meydana geldi",
        "Create_user_success": "{0} hesabı başarıyla oluşturuldu.Giriş kimlik bilgileri ayrıntıları için lütfen {1} e -postasını kontrol edin.",
        "Load_user_fail": "Hatalar kullanıcıyı yüklerken meydana geldi",
        "Update_user_status_fail": "Hatalar kullanıcı durumunu güncellerken meydana geldi",
        "Delete_user_fail": "Hatalar kullanıcı silinirken meydana geldi",
        "Delete_user_success": "Kullanıcı başarıyla silindi",
        "CONFIRM_RESET_PASSWORD": "Parolayı sıfırlamak istiyor musunuz?",
        "RESET_PASSWORD": "Parolayı sıfırlamak istiyor musunuz?",
        "Reset_password": "Parolayı sıfırla",
        "Select_permission": "İzni seçin",
        "Reset_password_success": "Parolayı başarıyla sıfırlayın"
    },
    "report": {
        "query": {
            "errorMessage": {
                "bookFrom": "Lütfen rezervasyon yapın",
                "paymentMethod": "Lütfen ödeme yöntemini seçin",
                "bookingType": "Lütfen rezervasyon türünü seçin",
                "ratingType": "Lütfen derecelendirme türünü seçin",
                "stars": "Lütfen derecelendirme türünü seçin",
                "type": "Tip",
                "transactionType": "Lütfen İşlem Türünü Seçin",
                "serviceType": "Lütfen Hizmet Türünü Seçin",
                "rideService": "Lütfen Sürüş Servisi'ni seçin",
                "invalidEmail": "Geçerli bir e."
            },
            "search": {
                "driverReferalPax": "Arama: Sürücü Adı, Telefon Numarası",
                "passengerRefersPassengers": "Arama: Müşteri Adı, Telefon Numarası",
                "driverReferalPaxDetail": "Arama: Yolcu Adı",
                "bookingDetails": "Search: Booking ID, Operator, Customer phone, Customer name, Driver name, ID# / National IC#, License plate, Queuing area, Note, Trip, Support ID",
                "financial": "Arama: Sürücü Adı, Telefon",
                "completedTime": "Tamamlanmış zaman",
                "collectedTime": "Toplanan Zaman",
                "financialDriver": "Arama: Rezervasyon Kimliği, Sürücü Adı, Kimlik# / National IC#",
                "carActivity": "Arama: Plaka",
                "affiliation": "Arama: Rezervasyon Kimliği",
                "prepaidTopUp": "Arama: Şirket Adı, Top Up Kimliği, İşlem Kimliği",
                "operatorLogs": "Arama: operatör adı, kullanıcı adı, açıklama",
                "corporate": "Arama: Rezervasyon Kimliği, Sürücü Adı, Corp Kullanıcıları, Müşteri Kılıfı Maddesi, Ücret Kodu",
                "bookingLogs": "Arama: Rezervasyon Kimliği, Müşteri Adı, Destek Kimliği, Sürücü Adı, Kimlik# / National IC#",
                "rating": "Arama: Rezervasyon Kimliği, Müşteri Telefonu, Müşteri Adı, Plaka Numarası",
                "mDispatcher": "Arama: Rezervasyon Kimliği, Ortak Adı",
                "totalAdjustment": "Arama: Rezervasyon Kimliği",
                "incidentCancellation": "Arama: Rezervasyon Kimliği, Müşteri Telefonu, Destek Kimliği, Müşteri Adı",
                "acceptanceCancellationRate": "Arama: Sürücü Adı, Telefon Numarası",
                "revenueDriver": "Arama: Sürücü Adı",
                "dailyDriver": "Arama: Sürücü Telefonu, Sürücü Adı, Kimlik# / National IC#",
                "dailyCar": "Arama: Plaka, İzin",
                "dailyDriverCar": "Search: Driver phone, Driver name, ID# / National IC#, License plate",
                "driverLoginStatus": "Search: Driver phone, Driver name, ID# / National IC#, License plate",
                "bookingSummary": "Arama: Operatör adı, kullanıcı adı, rezervasyon kimliği",
                "creditWallet": "Arama: Şirket Adı, Sürücü Adı, Rezervasyon Kimliği, İşlem Kimliği, Operatör",
                "cashWallet": "Arama: Şirket Adı, Sürücü Adı, Rezervasyon Kimliği, Telefon Numarası",
                "settlement": "Arama: Sürücü Adı, Kimlik# / National IC#",
                "driverRating": "Arama: Rezervasyon Kimliği, Müşteri Telefonu, Müşteri Adı, Plaka Numarası, Destek Kimliği",
                "driverWithdrawal": "Sürücü Adı, Telefon, Şirket, İşlem Kimliği, Referans #",
                "penaltyCompensation": "Arama: Rezervasyon Kimliği, Sürücü Adı, Yolcu Adı",
                "incompletePayment": "Arama: Rezervasyon Kimliği, Destek Kimliği, Müşteri Adı",
                "passengerWallet": "Arama: Rezervasyon Kimliği, İşlem Kimliği, Müşteri Adı",
                "driverDeposit": "Arama: Şirket adı, sürücü adı, rezervasyon kimliği, operatör",
                "refund": "Arama: Rezervasyon Kimliği, Seyahat Kimliği, Destek Kimliği, Müşteri Adı",
                "driverRefersCustomerBookings": "Arama: Tavsiye Kodu, Destek Kimliği",
                "driverHistory": "Arama: Ödeme Kimliği",
                "merchantHistory": "Arama: Ödeme Kimliği",
                "firstWillWin": "Arama: Müşteri Adı, Müşteri Telefonu, Müşteri Kimliği",
                "firstWillWinDriver": "Arama: Sürücü Adı, Sürücü Telefonu, Kullanıcı Adı",
                "merchant": "Arama: Mağaza",
                "merchantCashTransactionHistory": "Arama: Rezervasyon Kimliği, İşlem Kimliği, Satıcı Adı",
                "merchantCreditTransactionHistory": "Arama: Rezervasyon Kimliği, İşlem Kimliği, Satıcı Adı",
                "deleteAccountRequest": "Arama: Telefon",
                "affiliationPayoutHistory": "Arama: Ödeme Kimliği",
                "thirdPartyBooking": "Arama: Rezervasyon Kimliği, Harici Kimlik"
            },
            "common": {
                "all": "Tüm"
            },
            "today": "Bugün",
            "sendDownloadLink": "Alıcı E -posta Adresi",
            "generaltingYourExcel": "Excel dosyanızı oluşturmak biraz zaman alabilir.Hazır olur olmaz size e -posta göndereceğiz.",
            "messageSuccess": "Dosya devam ediyor.Hazır olduğunda bir e -posta alacaksınız.İlerlemeyi izleyin veya doğrudan raporlardan/ihracat geçmişinden indirin",
            "thisMonth": "Bu ay",
            "lastMonth": "Geçen ay",
            "past30Days": "Son 30 gün",
            "thisYear": "Bu yıl",
            "custom": "Gelenek",
            "customize": "Özelleştirmek",
            "thisWeek": "Bu hafta",
            "past7Days": "Son 7 Gün",
            "weekly": "Haftalık",
            "monthly": "Aylık",
            "allTime": "Her zaman",
            "tomorrow": "Yarın",
            "nextWeek": "Gelecek hafta",
            "lastWeek": "Geçen hafta",
            "nextMonth": "Gelecek ay",
            "yesterday": "Dün",
            "fromDate": "Tarihten itibaren",
            "toDate": "Bugüne kadar",
            "driver": "Sürücü: %{name}",
            "customer": "Müşteri: %{name}",
            "referralCode": "Tavsiye Kodu: %{Name}",
            "referer": "Yönlendirici: %{name}",
            "referee": "Hakem: %{Name}",
            "usedPromoCode": "Promosyon: %{name}",
            "voucherCode": "Kod adı}",
            "city": "Şehir",
            "company": "Şirket",
            "company_report": "Firma Adı}",
            "corporate_report": "Corporate: %{name}",
            "campaign_report": "Kampanya: %{name}",
            "operator": "Operatör",
            "farmType": "Çiftlik tipi",
            "carLicensePlate": "Araba Plakası: %{Name}",
            "settlementType": "Tip",
            "bookingService": "Ağ türü",
            "payoutOption": "Ödeme seçeneği",
            "currency": "Para birimi",
            "bookingFrom": "Rezervasyon",
            "thirdPartyAction": "Aksiyon",
            "paymentMethod": "Ödeme yöntemi",
            "paymentStatus": "Ödeme durumu",
            "bookingType": "Rezervasyon türü",
            "adjustPrice": "Fiyatı Ayarlayın",
            "intercityRoute": "Şehirlerarası yol",
            "ratingType": "Derecelendirme türü",
            "stars": "Derecelendirme",
            "driverRating": "Sürücü derecesi",
            "vehicleRating": "Araç derecesi",
            "mDispatcherType": "Ortak tür",
            "mDispatcher": "Ortak Adı",
            "campaign": "Kampanya",
            "promocode": "Promosyon kodu",
            "canceledBy": "İptal edildi",
            "timezone": "Zaman dilimi",
            "month": "Ay",
            "year": "Yıl",
            "view": "Görüş",
            "action": "Aksiyon",
            "status": "Durum",
            "module": "Modül",
            "driverType": "Tip",
            "vehicleType": "Araba tipi",
            "serviceType": "Hizmet türü",
            "expireDate": "Son kullanma tarihi",
            "rideSharing": "Sürüş paylaşımı",
            "rideService": "Sürüş hizmeti",
            "transactionType": "İşlem türü",
            "completeFromCC": "CC'den tamamlandı",
            "withDrawalTransactionType": "İşlem türü",
            "bookingStatus": "Rezervasyon Durumu",
            "withdrawalStatus": "Durum",
            "originalStatus": "Orijinal Ödeme Durumu",
            "currentStatus": "Mevcut Ödeme Durumu",
            "tip": "Uç",
            "type": "Tip",
            "userType": "Kullanıcı türü",
            "eventType": "Olay türü",
            "eventState": "Olay durumu",
            "eventName": "Etkinlik Adı",
            "merchantType": "Tüccar türü",
            "merchantService": "Tüccar hizmeti",
            "storeName": "Mağaza",
            "merchant": "Tüccar",
            "event": "Olay Adı: %{Name}",
            "store": "Mağaza Adı: %{Name}",
            "selectedMerchant": "Satıcı: %{name}",
            "associatedApps": "İlişkili uygulamalar",
            "settlement": "Yerleşim",
            "rideServiceItem": {
                "all": "Tüm",
                "regulation": "Düzenleme",
                "rideSharing": "Sürüş paylaşımı"
            },
            "actionItem": {
                "all": "Tüm",
                "login": "Giriş yapmak",
                "logout": "Çıkış yapmak",
                "bookIn": "Mevcut",
                "bookOff": "Müsait değil",
                "deny": "Reddetmek",
                "ignore": "Görmezden gelmek",
                "accept": "Kabul etmek",
                "startTrip": "Yolumun üzerinde",
                "updateBooking": "Güncelleme",
                "arrive": "Varmak",
                "pickup": "Toplamak",
                "drop": "Boşa harcamak",
                "complete": "Tamamlamak",
                "noShow": "Gösteri Yok",
                "rqJob": "Gönderme",
                "cancel": "İptal etmek"
            },
            "loginStatusItem": {
                "all": "Tüm",
                "online": "Giriş yapmak",
                "offline": "Açılmamış"
            },
            "payoutType": "Ödeme türü",
            "affiliationPayoutTypeItem": {
                "auto": "Otomatik Ödeme",
                "manual": "Manuel ödeme"
            },
            "affiliationSettlementItem": {
                "payable": "Ödenecek",
                "receivable": "Alacak"
            },
            "affiliationPaymentMethod": {
                "online": "Çevrimiçi",
                "directInvoicing": "Doğrudan faturalandırma"
            },
            "payoutPaymentStatusItem": {
                "paid": "Paralı",
                "pending": "Askıda olması"
            },
            "farmTypeItem": {
                "all": "Tüm",
                "farmIn": "Çiftçilik yapmak",
                "farmOut": "Çiftçilik yapmak",
                "roaming": "Roaming",
                "affiliationOwner": "Bağlılık Sahibi",
                "farmInAndAffiliationOwner": "Çiftlik ve Bağlılık Sahibi",
                "farmOutAndAffiliationOwner": "Çiftlik Çıkış ve Bağlılık Sahibi"
            },
            "settlementDriverTypeItem": {
                "all": "Tüm sürücü",
                "individual": "Şirket arabası"
            },
            "shortTrip": {
                "all": "Tüm",
                "shortTrip": "Kısa gezi",
                "nonShortTrip": "Kısacası olmayan gezi"
            },
            "paymentMethodItem": {
                "B2BTerminal": "B2Bterminal",
                "webBooking": "Web rezervasyonu",
                "pendingPayment": "Bekleyen ödeme",
                "all": "Tüm",
                "cash": "Peşin",
                "cashBySender": "Gönderen tarafından nakit",
                "cashByRecipient": "Alıcı tarafından nakit",
                "personalCard": "Kişisel kart",
                "corporateCard": "Kurumsal kart",
                "qrCodePayment": "QR Kod Ödemesi",
                "directBilling": "Doğrudan faturalandırma",
                "externalCard": "Harici kart",
                "mDispatcherCard": "Ortak kartı",
                "corporatePrepaid": "Kurumsal ön ödemeli",
                "canceled": "İptal edilmiş",
                "noShow": "Gösteri Yok",
                "incident": "Olay",
                "fleetCard": "Harici kart",
                "applePay": "Elma ödemesi",
                "creditCardExternal": "Kredi kartı",
                "paxWallet": "Cüzdan",
                "tngeWallet": "Tng ewallet",
                "vippseWallet": "Vippler",
                "zainCash": "Zaincash",
                "xendit": "Xendit",
                "FIB": "FIB",
                "SantimPay": "SantimPay",
                "gCash": "Gcash",
                "completedWithoutService": "Sürücü olmadan tamamlandı",
                "BankOfGeorgia": "Gürcistan Bankası",
                "partialCompleted": "Kısmi tamamlandı",
                "failed": "Arızalı",
                "googlePay": "Google Pay",
                "paymentLink": "Ödeme bağlantısı",
                "easyPaisa": "Easy Paisa",
                "jazzCash": "Jazz Cash"
            },
            "paymentStatusItem": {
                "all": "Tüm",
                "fullPayment": "Tam ödeme",
                "partialPayment": "Kısmi ödeme",
                "pendingPayment": "Bekleyen ödeme",
                "partialRefund": "Kısmi geri ödeme",
                "fullRefund": "Tam Geri Ödeme"
            },
            "companySettlementTypeItem": {
                "all": "Tüm",
                "fleetOweCompany": "Filo Borçlu Şirket",
                "companyOweFleet": "Şirket filo borçlu"
            },
            "bookingStatusItem": {
                "all": "Tüm",
                "completed": "Tamamlanmış",
                "incident": "Olay",
                "paxNoShow": "Pax Gösteri Yok",
                "driverNoShow": "Sürücü yok şov",
                "rejected": "Reddedilmiş",
                "canceledByTimeOut": "Zaman aşımı ile iptal edildi",
                "canceledByTimeOutRejected": "Zaman aşımı ile iptal edildi (reddedildi)",
                "canceledByBookingBoard": "Rezervasyon Kurulu tarafından iptal edildi",
                "canceledByCustomer": "Müşteri tarafından iptal edildi",
                "canceledBySupplier": "Tedarikçi tarafından iptal edildi"
            },
            "bookingFromItem": {
                "all": "Tüm",
                "paxApp": "PAX uygulaması",
                "mDispatcher": "Ortak",
                "kiosk": "Köşk",
                "cc": "Komuta merkezi",
                "webBooking": "Web rezervasyonu",
                "carHailing": "Araba",
                "streetSharing": "Sokak paylaşımı",
                "dashboard": "Rezervasyon kurulu",
                "corp": "Rezervasyon Kurulu (CTM)",
                "dmc": "Rezervasyon Kurulu (DMC)",
                "Boost_web_link": "Web bağlantısını artırın"
            },
            "thirdPartyActionItem": {
                "all": "Tüm",
                "searched": "Aranmış",
                "allocated": "Tahsis edilmiş",
                "updated": "Güncellenmiş",
                "cancelled": "İptal edilmiş"
            },
            "bookingTypeItem": {
                "batchDelivery": "Toplu teslimat",
                "all": "Tüm",
                "now": "Şimdi (ulaşım)",
                "reservation": "Rezervasyon (ulaşım)",
                "intercity": "Şehirlerarası",
                "delivery": "Teslimat",
                "shuttle": "Servis"
            },
            "adjustPrices": {
                "all": "Tüm",
                "normalPrice": "Normal fiyat",
                "addonPrice": "Eklenti fiyatı"
            },
            "bookingServiceItem": {
                "all": "Tüm",
                "roaming": "Roaming",
                "homeFleet": "Çiftçilik yapmak",
                "localBooking": "Kurum içi",
                "farmIn": "Çiftçilik yapmak",
                "farmInAndAffiliationOwner": "Çiftlik ve Bağlılık Sahibi",
                "provideService": "Çiftçilik yapmak"
            },
            "ratingTypeItem": {
                "all": "Tüm",
                "like": "Beğenmek",
                "dislike": "Sevmemek"
            },
            "starsItem": {
                "all": "Tüm",
                "star1": "1 Yıldız",
                "star2": "2 Yıldız",
                "star3": "3 Yıldız",
                "star4": "4 Yıldız",
                "star5": "5 Yıldız"
            },
            "incidentCanceledTypes": {
                "all": "Tüm",
                "incident": "Olay",
                "canceled": "İptal edilmiş"
            },
            "canceledByItem": {
                "all": "Tüm",
                "CorpAD": "Kurumsal yönetici",
                "passenger": "Yolcu",
                "driver": "Sürücü",
                "merchant": "Tüccar",
                "CC": "CC",
                "mDispatcher": "Ortak",
                "Partner": "Ortak",
                "webBooking": "Web rezervasyonu",
                "timeout": "Zaman aşımı"
            },
            "bookingSummaryActionItem": {
                "all": "Tüm",
                "create": "Yaratmak",
                "update": "Güncelleme",
                "cancel": "İptal etmek",
                "complete": "Tamamlamak",
                "incident": "Olay"
            },
            "transactionTypeItem": {
                "bookingAdjustment": "Booking Adjustment",
                "bankAccount": "Banka hesabına aktarıldı",
                "sentToCustomer": "Müşteriye Gönder",
                "receivedFromDriver": "Sürücüden alındı",
                "sentToDriver": "Sürücüye gönderildi",
                "creditWallet": "Kredi cüzdanına aktarıldı",
                "netEarning": "Net kazanç",
                "referralEarning": "Tavsiye Kazanç",
                "all": "Tüm",
                "topUp": "Doldurmak",
                "editBalance": "Bakiyeyi Düzenle",
                "bookingDeduction": "Rezervasyon kesintisi",
                "bookingPromo": "Rezervasyon promosyonu",
                "topUpGCash": "Gcash tarafından hazır",
                "cashWallet": "Nakit cüzdan",
                "topUpMOLPay": "Razer Pay tarafından doldur",
                "topUpZainCash": "Zaincash",
                "topUpXendit": "Xendit tarafından doldur",
                "topUpFIB": "Top up by FIB",
                "topUpSantimPay": "Top up by SantimPay",
                "topUpTelebirr": "Telebirr tarafından doldurulmuş",
                "topUpKsher": "Ksher tarafından doldurulmuş",
                "topUpWallet": "{0}",
                "TnG": "Tng tarafından doldur",
                "credit": "Kredi kartı ile doldurun",
                "ride": "Sürmek",
                "tipAfterCompleted": "Sürüşten sonra ipucu",
                "debtWriteOff": "Borçlanma",
                "refund": "Geri ödemek",
                "excessOffsetAmount": "Fazla nakit kredi",
                "excessDeductionAmount": "Fazla Nakit Borç",
                "cashExcess": "Fazla Nakit Borç",
                "gateway": "Gateway'den doldurun",
                "driverTopUpForPax": "Fazla Nakit Borç",
                "updatedBalance": "Güncellenmiş bakiye",
                "partialEarning": "Kazanç",
                "payout": "Ödeme",
                "payoutManual": "Ödeme kılavuzu",
                "payoutDriver": "Ödeme",
                "itemValue": "Öğe Değerleri",
                "redeemVoucher": "Kodu kullanın #",
                "topUpReferrer": "Tavsiye Ödülü",
                "driverInsurance": "Sürücü sigortası",
                "cancellationPenalty": "İptal cezası",
                "WingBank": "Wingbank hesabına transfer",
                "customerDebt": "Müşteri borcu",
                "googlePay": "Google Pay tarafından doldurulun",
                "applePay": "Apple Pay tarafından doldur",
                "merchantCommission": "Tüccar Komisyonu",
                "eWallet": "Transferred to external e-wallet",
                "reversed": "Withdrawal reversed"
            },
            "moduleItem": {
                "all": "Tüm",
                "bookings": "Rezervasyonlar",
                "customers": "Müşteriler",
                "dispatch": "Sevk etmek",
                "general": "Genel",
                "promotion": "Terfi",
                "quest": "Arayış",
                "rate": "Oran",
                "car": "Araba",
                "city": "Şehir",
                "company": "Şirket",
                "drivers": "Sürücüler",
                "driverSettlement": "Sürücü yerleşimi",
                "login": "Giriş yapmak",
                "logout": "Oturum açma",
                "mDispatcher": "Ortak",
                "mDispatcherType": "Ortak tür",
                "operation": "Ameliyat",
                "partners": "Ortaklar",
                "permission": "İzin",
                "queuingArea": "Kuyruk alanı",
                "report": "Rapor",
                "shift": "Vardiya",
                "user": "Kullanıcı",
                "voiceSms": "Ses ve SMS",
                "intercityZone": "Bölge (şehirlerarası)",
                "Payout": "Ödeme",
                "code#": "Kod #",
                "ApiManagement": "API Yönetimi"
            },
            "serviceTypeItem": {
                "all": "Tüm",
                "oneWay": "Tek Yön",
                "fromAirport": "Havaalanından",
                "toAirport": "Havaalanına",
                "hourly": "Saatlik/günlük",
                "roundTrip": "Gidiş",
                "fromAirportHourlyDaily": "Havaalanından - Saatlik/Günlük",
                "fromAirportRoundTrip": "Havaalanı'ndan - gidiş -dönüş",
                "toAirportHourlyDaily": "Havaalanına - Saatlik/Günlük",
                "toAirportRoundTrip": "Havaalanı'na - gidiş -dönüş"
            },
            "merchantTransactionTypeItem": {
                "all": "Tüm",
                "editBalance": "Bakiyeyi Düzenle",
                "payout": "Ödeme",
                "earning": "Kazanç",
                "itemValue": "Öğe değeri",
                "orderDeduction": "Sipariş kesintisi"
            },
            "withdrawalTransactionType": {
                "WingBank": "Wingbank hesabına aktarıldı",
                "bankAccount": "Banka hesabına aktarıldı",
                "cashWallet": "Nakit cüzdana aktarıldı",
                "creditWallet": "Kredi cüzdanına aktarıldı"
            },
            "payoutDriver": {
                "driversHaveBankAccount": "Sürücülerin banka hesabı var",
                "driversDontHaveBankAccount": "Sürücülerin banka hesabı yok",
                "payoutEndTime": "Ödeme Bitiş Süresi",
                "driverHaveInvalidBankAccount": "Sürücülerin geçersiz banka hesabı var"
            },
            "payoutMerchant": {
                "HaveBankAccount": "Tüccarların banka hesabı var",
                "DontHaveBankAccount": "Tüccarların banka hesabı yok",
                "HaveInvalidBankAccount": "Tüccarların geçersiz banka hesabı var",
                "payoutEndTime": "Ödeme Bitiş Süresi"
            }
        },
        "error": {
            "23005": "Sistem performansı için raporları yalnızca %{aralık} %{UnitLabel} olarak görüntüleyebilirsiniz, lütfen tarih aralığınızı ayarlayın"
        },
        "result": {
            "idPermitType": "Kimlik türü",
            "noResultFound": "Sonuç bulunamadı",
            "page": "Sayfa",
            "itemsPerPage": "Sayfa başına öğeler",
            "showPages": "%{From} - %{total}",
            "exportToExcel": "Excel'e İhracat",
            "exportToCSV": "CSV'ye dışa aktarma",
            "exportToPaymentFile": "Ödeme Dosyasına Dışa Aktar",
            "pay": "Ödemek",
            "paySelected": "Ödeme Seçili",
            "payAll": "Hepsini ödemek",
            "invalidBankAccount": "Geçersiz banka hesabı",
            "validBankAccount": "Geçerli banka hesabı",
            "bookingDetails": {
                "supplierPayout": "Supplier payout",
                "driverPayout": "Driver payout",
                "profit": "Profit",
                "customerAdjustmentsFee": "Customer Adjustment",
                "supplierDriverAdjustmentsFee": "Supplier/Driver Adjustment",
                "successSendMessage": "Mesajınız gönderildi",
                "packageName": "Paket adı",
                "transactionId": "İşlem kimliği",
                "proofOfFailure": "Başarısızlık kanıtı",
                "recipientsNumber": "Alıcı sayısı",
                "recipientsInfo": "Alıcı Bilgisi",
                "creditTransactionFee": "Kredi Kartı Ücretleri",
                "carTypeRequest": "İstenen Cartype",
                "bookingId": "Rezervasyon #",
                "bookingStatus": "Rezervasyon Durumu",
                "tripId": "Seyahat #",
                "bookFrom": "Rezervasyon",
                "bookingService": "Ağ türü",
                "bookingType": "Rezervasyon türü",
                "serviceType": "Hizmet türü",
                "operator": "Operatör",
                "customerPhone": "Yolcu telefon numarası",
                "customerName": "Yolcu adı",
                "customerIdNumber": "Yolcu Kimliği# / IC#",
                "corpDivision": "Bölüm",
                "corpDept": "Görevli",
                "chargeCode": "Ücret Kodu",
                "tripDescription": "Gezi Açıklama",
                "rideSharing": "Sürüş paylaşımı",
                "queueName": "Kuyruk alanı",
                "bookingTime": "Yaratılış Süresi",
                "pickupTime": "Pikap zamanı",
                "expectedPickupTime": "Beklenen Toplama Süresi",
                "dispatchTime": "Dağıtım Zamanı",
                "onBoardTime": "Tahtada",
                "droppedOffTime": "Dereceli Zaman",
                "onBoardDuration": "Gemi süresinde",
                "dispatch": "Sevk etmek #",
                "completedTime": "Tamamlanmış zaman",
                "driverName": "Sürücü adı",
                "driverIdPermit": "ID# / National IC#",
                "driverLicenseNumber": "Ehliyet numarası",
                "companyName": "Şirket",
                "carType": "Araba tipi",
                "licensePlate": "Plaka",
                "intercityRoute": "Şehirlerarası yol",
                "pickupLocation": "Pikap Konumu",
                "geoPickup": "Pikap Konumu Lat/Uzun",
                "extraDestination": "Ekstra destinasyonlar",
                "originDestination": "Orijinal Hedef",
                "originEstDistance": "Orijinal EST mesafesi",
                "destination": "Gerçek hedef",
                "geoDestination": "Gerçek hedef lat/uzun",
                "pickupFrom": "Pikap yeri",
                "distanceTour": "P.O.B mesafesi",
                "internalNotes": "İç notlar",
                "notes": "Notalar",
                "basicFareCalculator": "Temel Ücret Hesap Makinesi",
                "basicFare": "Temel Ücret",
                "editFare": "Temel Ücreti Düzenle",
                "oldBasicFare": "Eski Temel Ücret",
                "airportFee": "Havaalanı ücreti",
                "meetDriver": "Şoförle tanışmak",
                "rushHour": "Fazla yüklemek",
                "surchargeParameter": "Dinamik ek ücret",
                "dynamicFare": "Dinamik yemek",
                "heavyTraffic": "Yoğun trafik",
                "tollFee": "Ücret ücreti",
                "parkingFee": "Otopark ücreti",
                "gasFee": "Gaz ücreti",
                "otherFees": "Diğer Ücretler",
                "subtotal": "Alt kat",
                "subTotalFF": "Subtotal ff",
                "techFee": "Teknoloji ücreti",
                "serviceFee": "Ek Hizmetler",
                "fleetServiceFee": "Filo Hizmetleri",
                "bookingFee": "Rezervasyon ücreti",
                "salesTax": "Satış vergisi",
                "tipAmt": "İpucu amt",
                "promoCode": "Promosyon kodu",
                "promoAmt": "Promosyon",
                "requestedTotalAmount": "Talep edilen toplam tutar",
                "total": "Toplam AMT",
                "totalNotRounded": "Yuvarlak (Toplam AMT)",
                "actualReceived": "Gerçek Alındı",
                "differenceAmt": "Fark (alındı)",
                "customerDebt": "Müşteri borcu",
                "markupDifference": "İşaretleme Farkı",
                "receivedWhenDropOff": "Bırakıldığında alındı",
                "refundAmount": "Geri ödeme amt",
                "requestedPaymentMethod": "Talep edilen ödeme yöntemi",
                "paidBy": "Ödenen",
                "splitPayment": "Bölünmüş ödeme",
                "paidByWallet": "Cüzdan tarafından ödenmiş",
                "paidByOtherMethod": "Başka bir yöntemle ödenen",
                "cashReceived": "Nakit Alındı",
                "transferredChangeByWallet": "Değişikliği cüzdanla transfer",
                "returnedChangeByCash": "Nakit \"geri dönüş değişikliği",
                "instructions": "Talimatlar",
                "additionalServiceName": "İsim",
                "additionalServiceValue": "Değer",
                "sendReceiptHeader": "",
                "sendReceipt": "Makbuzu Gönder",
                "send": "Göndermek",
                "cancel": "İptal etmek",
                "sentReceiptSuccess": "Makbuz başarıyla gönderildi",
                "sentReceiptFailed": "Makbuz şu anda gönderilemez.Lütfen tekrar deneyin.",
                "coRider": "Ortak kurucu",
                "coriderName": "İsim",
                "stopAddress": "Ek Hedef",
                "stopGeo": "Lat/Uzun",
                "addonPrice": "Müşteri Ayar Fiyatı",
                "oldSubTotal": "Ayarlamadan önce subtotal",
                "view_good_receipt": "Mal makbuzu",
                "view_delivery_receipt": "Teslimat fotoğrafı",
                "view": "Görüş",
                "tipAfterCompletedDetails": "Aktarılan: {0} {1};İşlem ücreti: {0} {2}",
                "fleetMarkup": "Kâr",
                "editedDriverEarning": "Düzenlenen Sürücü Kazanç",
                "driverEarning": "Sürücü kazanma",
                "supplierAmount": "Supplier earning",
                "currencyISO": "Currency",
                "pickUpZoneName": "Pikap bölgesi",
                "dynamicFactor": "Dynamic fare (factor)",
                "supplierEarning": "Supplier earning",
                "passengerName": "Passenger name",
                "totalPayout": "Total payout",
                "numberOfTrips": "Number of trips"
            },
            "financial": {
                "supplierPayout": "Supplier payout",
                "driverPayout": "Driver payout",
                "profit": "Profit",
                "currencyISO": "Currency",
                "supplierAmount": "Supplier earning",
                "driverEarning": "Driver earning",
                "supplierEarning": "Supplier earning",
                "passengerName": "Passenger name",
                "totalPayout": "Total payout",
                "numberOfTrips": "Number of trips",
                "driverName": "Sürücü adı",
                "driverPhone": "Telefon numarası",
                "driverUserName": "Kullanıcı adı",
                "companyName": "Şirket",
                "bookingList": "Rezervasyon listesi",
                "totalFare": "Promosyonu olmadan toplam ücret",
                "netEarning": "Net kazanç",
                "showBookingList": "Göstermek",
                "referralEarning": "Tavsiye Kazanç",
                "totalEarningNet": "Toplam Kazanç (Net)",
                "totalEarningNetTooltip": "Tüm ücretleri filoya iade ettikten sonra kazandığınız para.Toplam Kazanç (Net) = Net Kazanç + Sürüşten Sonra İpucu",
                "totalEarningNetTooltipRefferal": "Tüm ücretleri filoya iade ettikten sonra kazandığınız para.Toplam Kazanç (Net) = Tavsiye Kazanç + Net Kazanç + Tip Rider",
                "totalEarningGross": "Toplam Kazanç (Brüt)",
                "totalEarning": "Toplam Kazanç",
                "totalEarningGrossTooltip": "Rezervasyon ücreti ve ipuçlarından kazandığınız para.Toplam Kazanç (brüt) = Gross kazanma + sürüşten sonra ipucu",
                "totalEarningGrossTooltipRefferal": "Rezervasyon ücret ve ipucu kazandığınız para.Toplam Kazanç (brüt) = Tavsiye Kazanç + Bütçe Kazanma + Tip Rider",
                "note": "Not",
                "shortTrip": "Kısa gezi",
                "deduction": "Deduction",
                "totalDriverEarning": "Total driver earnings",
                "totalDriverEarningTooltip": "Includes bookings with both online and offline payment",
                "totalDriverPayout": "Total driver payout",
                "totalDriverPayoutTooltip": "Does not include bookings with cash or offline payment"
            },
            "financialDriver": {
                "supplierAmount": "Supplier commission",
                "payoutType": "Ödeme türü",
                "date": "Tarih",
                "driverTipAfterCompleted": "Sürüşten sonra ipucu",
                "bookingId": "Rezervasyon kimliği",
                "paidBy": "Ödenen",
                "bookFrom": "Rezervasyon",
                "bookingType": "Rezervasyon türü",
                "companyName": "Şirket",
                "driverName": "Sürücü",
                "driverIdPermit": "ID# / National IC#",
                "totalFare": "Promosyon olmadan toplam ücret",
                "subtotal": "Alt kat",
                "airportFee": "Havaalanı ücreti",
                "meetDriver": "Şoförle tanışmak",
                "tollFee": "Ücret ücreti",
                "parkingFee": "Otopark ücreti",
                "gasFee": "Gaz ücreti",
                "rushHour": "Acele saat",
                "transactionFee": "İşlem ücreti",
                "techFee": "Teknoloji ücreti",
                "salesTax": "Satış vergisi",
                "tipAmt": "İpucu amt",
                "bookingFee": "Rezervasyon ücreti",
                "commission": "Komisyon",
                "deduction": "Kesinti",
                "promoAmt": "Promosyon",
                "ridePayment": "Ride Ödeme",
                "driverTax": "Sürücü vergisi",
                "grossEarning": "Brüt kazanç",
                "driverDeduction": "Sürücü kesintisi",
                "netEarning": "Net kazanç",
                "payToDriver": "Sürücüye Ödeme",
                "payToFleet": "Filoya Ödeme",
                "totalNotRounded": "Yuvarlak (Toplam AMT)",
                "fleetServiceFee": "Filo Hizmet Ücretleri",
                "editedDriverEarning": "Düzenlenen Sürücü Kazanç",
                "stripeFee": "Stripe Fee",
                "currencyISO": "Currency"
            },
            "financialCompany": {
                "companyName": "Şirket",
                "homeFleetNetEarning": "Ana Filo Net Kazanç",
                "affiliateNetEarning": "Ortaklık Net Kazanç",
                "totalNetEarning": "Toplam Sürücü Net Kazanç",
                "status": "Durum",
                "companyAmount": "Şirket karı",
                "numberOfTrips": "Number of trips"
            },
            "companySettlement": {
                "companyName": "Şirket",
                "pendingSettlement": "Bekleyen Yerleşim",
                "viewDetails": "Ayrıntıları Görüntüle",
                "settlementType": "Tip"
            },
            "companySettlementDetails": {
                "bookId": "Rezervasyon kimliği",
                "dateTime": "Tarih saati",
                "originalPaymentStatus": "Orijinal Ödeme Durumu",
                "subTotal": "Alt kat",
                "fleetAmount": "Filo Miktarı",
                "driverAmount": "Sürücü miktarı",
                "companyAmount": "Şirket Tutarı",
                "companyOweFleet": "Şirket filo borçlu",
                "fleetOweCompany": "Filo Borçlu Şirket",
                "notes": "Notalar"
            },
            "financialProfit": {
                "profit": "Profit",
                "bookingId": "Rezervasyon #",
                "subTotal": "Alt kat",
                "subTotalFF": "Subtotal ff",
                "fleetCommission": "Filo Komisyonu",
                "techFee": "Teknoloji ücreti",
                "promotion": "Terfi",
                "markupDifference": "İşaretleme Farkı",
                "Profit": "Kâr",
                "currency": "Para birimi",
                "fleetServiceFee": "Filo Servisi (Filo Take)",
                "fleetServiceFeeTooltip": "Miktar Filosu hizmet ücretlerinden komisyon alır"
            },
            "promotion": {
                "date": "Tarih",
                "promoCode": "Promosyon kodu",
                "users": "Kullanıcılar",
                "uses": "Kullanma",
                "checked": "Kontrollü",
                "applied": "Uygulanmış",
                "totalPromoAmount": "Toplam Promosyon Tutarı",
                "totalReceipt": "Toplam makbuz",
                "numberOfUsers": "Kullanıcı sayısı",
                "numberOfPromoCodeAppliance": "Promosyon kodu cihazı sayısı",
                "numberOfSuccessfulBookings": "Başarılı rezervasyonların sayısı",
                "numberOfCompletedBookings": "Tamamlanan rezervasyon sayısı",
                "numberOfCompletedUsers": "Tamamlanan kullanıcıların sayısı",
                "numberOfUsersApplyPromotion": "Kullanıcı sayısı promosyon uygular",
                "numberOfUsersApplyPromotionAndCompleteBooking": "Kullanıcı sayısı promosyon ve tam rezervasyon uygular",
                "numberOfPromoCodeApplianceTooltip": "Tüm kullanıcıların promosyon kodu cihazı sayısı",
                "numberOfSuccessfulBookingsTooltip": "Promosyon kodu ile başarılı bir şekilde oluşturulan rezervasyon sayısı",
                "numberOfCompletedBookingsTooltip": "Promosyon kodu uygulayan tamamlanmış rezervasyon sayısı"
            },
            "export": {
                "createdDate": "Tarih",
                "sessionName": "Oturum Adı",
                "download": "İndirmek",
                "delete": "Silmek",
                "percent": "Yüzde",
                "status": "Durum",
                "name": "Modül adı"
            },
            "redeemed": {
                "date": "Tarih",
                "code": "Kodlamak",
                "customers": "Müşteri",
                "phone": "Telefon numarası",
                "value": "Değer",
                "currency": "Para birimi",
                "campaignName": "Kampanya"
            },
            "carActivity": {
                "plateNumber": "Araba plakası",
                "vehicleType": "Araba tipi",
                "companyName": "Şirket",
                "day1st": "1.",
                "day2nd": "2.",
                "day3rd": "3.",
                "day4th": 4,
                "day5th": "5.",
                "day6th": "6.",
                "day7th": "7.",
                "day8th": "8'inci",
                "day9th": "9th",
                "day10th": "10.",
                "day11th": "11.",
                "day12th": "12.",
                "day13th": "13.",
                "day14th": "14.",
                "day15th": "15.",
                "day16th": "16.",
                "day17th": "17.",
                "day18th": "18'inci",
                "day19th": "19.",
                "day20th": "20.",
                "day21st": "21.",
                "day22nd": "22.",
                "day23rd": "23.",
                "day24th": "24.",
                "day25th": "25.",
                "day26th": "26.",
                "day27th": "27.",
                "day28th": 28,
                "day29th": "29.",
                "day30th": "30.",
                "day31st": "31.",
                "operationDays": "Operasyon günleri",
                "activeCars": "Toplam aktif araba:"
            },
            "carActivityLogsTitle": "Kütükler",
            "carActivityLogs": {
                "licensePlate": "Araba plakası",
                "companyName": "Şirket",
                "bookingId": "Rezervasyon kimliği",
                "driverName": "Sürücü adı",
                "driverIDNumber": "ID# / National IC#",
                "date": "Kabul Edilen Zaman",
                "bookFrom": "Rezervasyon"
            },
            "affiliation": {
                "date": "Pikap zamanı",
                "bookingId": "Rezervasyon kimliği",
                "bookingType": "Rezervasyon türü",
                "homeFleet": "Ev filosu",
                "providerFleet": "Sağlayıcı filo",
                "driver": "Sürücü",
                "passenger": "Yolcu",
                "paidBy": "Ödenen",
                "airportFee": "Havaalanı ücreti",
                "meetDriver": "Şoförle tanışmak",
                "rushHour": "Fazla yüklemek",
                "tollFee": "Ücret ücreti",
                "parkingFee": "Otopark ücreti",
                "gasFee": "Gaz ücreti",
                "heavyTraffic": "Yoğun trafik",
                "otherFees": "Diğer Ücretler",
                "provider": "Tedarikçi",
                "basicFareProvider": "Temel Ücret Sağlayıcısı",
                "subTotalProvider": "Alt Toplam Sağlayıcı",
                "techFeeProvider": "Teknik ücreti sağlayıcısı",
                "bookingFeeProvider": "Rezervasyon ücreti sağlayıcısı",
                "taxValueProvider": "Vergi Değeri Sağlayıcısı",
                "tipProvider": "İpucu sağlayıcısı",
                "totalFareProvider": "Toplam Ücret Sağlayıcısı",
                "basicFare": "Temel Ücret",
                "subtotal": "Alt kat",
                "techFee": "Teknoloji ücreti",
                "bookingFee": "Rezervasyon ücreti",
                "tax": "Vergi",
                "tip": "Uç",
                "totalFare": "Toplam yemek",
                "payoutInLocalCurrency": "Yerel para biriminde sürüş",
                "payoutInUSD": "USD'de Kazanma",
                "fleetCommInUSD": "USD'de Filo Comm",
                "bookingFeeInUSD": "USD'de rezervasyon ücreti",
                "totalPayoutInUSD": "USD'de toplam ödeme",
                "subTotal": "Alt kat",
                "bookingStatus": "Rezervasyon Durumu",
                "vehicle": "Araç",
                "customerPhone": "Müşteri Telefon Numarası",
                "driverPhone": "Sürücü Telefon Numarası",
                "total": "Toplam",
                "totalInUSD": "Toplam Ücret (USD)",
                "supplierPayout": "Tedarikçi ödemesi",
                "supplierPayoutInUSD": "Tedarikçi Ödeme (USD)",
                "buyerPayout": "Alıcı komisyonu",
                "buyerPayoutInUSD": "Komisyon (USD)",
                "buyerCommission": "Komisyon",
                "buyerCommissionInUSD": "Komisyon (USD)",
                "receivableInUSD": "Alacak (USD)",
                "payableInUSD": "Ödenecek (USD)",
                "fleetMarkupInUSD": "Kâr (USD)",
                "paymentMethod": "Ödeme yöntemi",
                "paymentStatus": "Ödeme durumu"
            },
            "prepaidTopUp": {
                "date": "Tarih ve Saat",
                "topUpId": "Topup Kimlik",
                "transactionId": "İşlem kimliği",
                "name": "İsim",
                "company": "Şirket",
                "paidBy": "Ödenen",
                "amount": "Miktar",
                "newAmount": "Yeni denge",
                "transactionType": "İşlem türü"
            },
            "operatorLogs": {
                "date": "Tarih",
                "operator": "Operatör",
                "username": "Kullanıcı adı",
                "module": "Modül",
                "description": "Tanım"
            },
            "corporate": {
                "date": "Tarih",
                "bookingId": "Rezervasyon kimliği",
                "operatorName": "Corp operatörü",
                "corporateName": "Şirket",
                "corpTraveler": "Corp kullanıcıları",
                "travelerSignature": "Gezgin imzası",
                "corpId": "Corp ID",
                "corpDivision": "Corp bölümü",
                "costCentre": "Maliyet merkezi",
                "corpPO": "Corp PO",
                "managerName": "Yöneticinin Adı",
                "managerEmail": "Yöneticinin e -postası",
                "title": "Başlık",
                "department": "Departman",
                "clientCaseMatter": "Müşteri Örneği Madde / Gezi Açıklama",
                "chargeCode": "Ücret Kodu / Gezi Kodu",
                "driverName": "Sürücü",
                "pickupLocation": "Pikap Konumu",
                "destination": "Varış noktası",
                "total": "Toplam AMT",
                "subtotal": "Alt kat",
                "subTotalFF": "Subtotal ff",
                "commission": "Komisyon",
                "commissionBooking": "Uygulama/PWA'dan rezervasyonlar için komisyon uygulayın",
                "paidBy": "Ödenen",
                "statusDisplay": "Durum",
                "flightNumber": "Uçuş numarası"
            },
            "bookingLogs": {
                "bookingId": "Rezervasyon kimliği",
                "bookingService": "Ağ türü",
                "bookingType": "Rezervasyon türü",
                "driverName": "Sürücü",
                "driverIdPermit": "ID# / National IC#",
                "customerName": "Yolcu",
                "pickupTime": "Pikap zamanı",
                "onBoardTime": "Tahtada",
                "droppedOffTime": "Dereceli Zaman",
                "pickupLocation": "Pikap Konumu",
                "destination": "Varış noktası",
                "dispatchHistory": "Dağıtım Tarihi",
                "otwDistance": "O.T.W mesafesi",
                "distanceTour": "P.O.B mesafesi",
                "duration": "P.O.B Süresi",
                "paidBy": "Ödenen",
                "bookFrom": "Rezervasyon",
                "map": "Harita",
                "showDispatchHistory": "Göstermek",
                "showMap": "Göstermek",
                "paymentActivities": "Ödeme faaliyetleri"
            },
            "rating": {
                "date": "Tarih",
                "bookingId": "Rezervasyon kimliği",
                "driverName": "Sürücü",
                "driverIdPermit": "ID# / National IC#",
                "customerName": "Müşteri Adı",
                "customerPhone": "Müşteri Telefonu",
                "like": "Beğenmek",
                "dislike": "Sevmemek",
                "notes": "Yorum",
                "stars": "Derecelendirme",
                "vehicleRating": "Araç derecesi",
                "driverRating": "Sürücü derecesi",
                "plateNumber": "Plaka numarası"
            },
            "mDispatcher": {
                "date": "Tarih",
                "bookingId": "Rezervasyon kimliği",
                "mDispatcherName": "Ortak Adı",
                "mDispatcherType": "Ortak tür",
                "subtotal": "Alt kat",
                "commission": "Komisyon",
                "paidBy": "Ödenen"
            },
            "totalAdjustment": {
                "date": "Tarih",
                "bookingId": "Rezervasyon kimliği",
                "oldAmount": "Toplam",
                "type": "Tip",
                "markupDifference": "İşaretleme Farkı",
                "newAmount": "Düzenlenen Toplam/İşaretleme",
                "username": "Operatör",
                "reason": "Sebep"
            },
            "incidentCancellation": {
                "date": "Tarih",
                "bookingId": "Rezervasyon kimliği",
                "customerName": "Müşteri Adı",
                "customerPhone": "Müşteri Telefonu",
                "bookFrom": "Rezervasyon",
                "canceledBy": "İptal edildi",
                "type": "Tip",
                "reason": "Sebep",
                "reasonCode": {
                    "0": "Başka bir seyahat seçeneği buldum",
                    "1": "Sürücü çok uzak",
                    "2": "Yanlış yere girdim",
                    "3": "Çok uzun süre bekledim",
                    "4": "Sürücü iptal etmek istedi",
                    "5": "Planlarımı değiştirdim",
                    "6": "Konumu değiştirmek istedim",
                    "101": "Çoğu veya tüm öğe mevcut değil",
                    "102": "Şu anda çok meşgulüz",
                    "103": "Şu anda kapalıyız",
                    "104": "Yakında kapanıyoruz"
                },
                "canceller": "İptal eden kimse"
            },
            "acceptanceCancellationRate": {
                "companyId": "Şirket Kimliği",
                "driverId": "Sürücü kimliği",
                "driverName": "Sürücü adı",
                "phoneNumber": "Telefon numarası",
                "company": "Şirket",
                "acceptanceRate": "Kabul oranı",
                "cancellationRate": "İptal oranı",
                "rating": "Derecelendirme"
            },
            "revenueFleet": {
                "date": "Tarih",
                "fareRevenue": "Ücret geliri",
                "trip": "Seyahat",
                "avgFareTrip": "AVG Ücreti/Gezi",
                "drivers": "# Sürücüler",
                "avgTripPerDriver": "AVG gezisi/sürücü",
                "dispatcher": "# Dağıtıcı"
            },
            "revenueDriver": {
                "date": "Tarih",
                "driverName": "Sürücü",
                "fareRevenue": "Ücret geliri",
                "trip": "# Seyahat",
                "avgFareTrip": "AVG Ücreti/Gezi"
            },
            "revenueMonthly": {
                "month": "Ay",
                "trip": "Bitmiş Geziler",
                "subtotal": "Alt kat",
                "tipAmt": "Uç",
                "techFee": "Teknoloji ücreti",
                "salesTax": "Vergi",
                "partnerCommission": "Ortak Komisyonu/ Odd",
                "totalFare": "Promosyon olmadan toplam ücret",
                "netEarning": "Net kazanç",
                "fleetServiceFee": "Filo Hizmet Ücretleri"
            },
            "dailyDriver": {
                "driverName": "Sürücü",
                "driverIDPermit": "ID# / National IC#",
                "driverPhone": "Telefon",
                "companyName": "Şirket",
                "workingHoursOnline": "Çevrimiçi (saat)",
                "workingHoursAvailable": "Mevcut (saat)",
                "driverWorkingHours": "Sürücü Çalışma Saatleri",
                "distance": "Sürücü seyahat mesafesi",
                "CC_activity": "CC aktivitesi",
                "fare_summary": "CC aktivitesi",
                "workingHoursNotAvailable": "Mevcut değil (saat)",
                "workingHoursPob": "P.O.B (saat)",
                "workingHoursPobToolTip": "Gemide yolcu",
                "workingHoursAccepted": "Kabul edilmiş",
                "workingHoursReject": "Reddetmek",
                "workingHoursIgnore": "Görmezden gelmek",
                "workingHoursCancel": "İptal etmek",
                "completedTripsTotal": "Toplam",
                "completedTripsCc": "CC'den",
                "completedTripsCarHailing": "Arabadan",
                "completedTripsStreetSharing": "Sokak paylaşımından",
                "completedTripsPaxApp": "PAX uygulamasından",
                "completedTripsWebBooking": "Web rezervasyonundan",
                "completedTripsMDispatcher": "Ortaktan",
                "completedTripsKiosk": "Kiosk'tan",
                "completedTripsApi": "API'den",
                "ccActivityDispatchedTrips": "Gönderilen geziler",
                "ccActivityAccepted": "Kabul edilmiş",
                "ccActivityReject": "Reddetmek",
                "ccActivityIgnore": "Görmezden gelmek",
                "ccActivityCancelByPassenger": "Yolcu tarafından iptal edildi",
                "ccActivityCancelByDriver": "Sürücü tarafından iptal edildi",
                "ccActivityCancelByCc": "CC tarafından iptal edildi",
                "ccActivityCancelByAPI": "API tarafından iptal edildi",
                "ccActivityNoShow": "Gösteri Yok",
                "fareSummaryTotalCash": "Peşin",
                "fareSummaryTotalCreditCard": "Kredi kartı",
                "fareSummaryTotalExternalCard": "Harici kart",
                "fareSummaryTotalPaxWallet": "Cüzdan",
                "fareSummaryTotalDirectBilling": "Doğrudan faturalandırma",
                "fareSummaryTotalQrCode": "QR kodu",
                "fareSummaryTotalPrePaid": "Kurumsal ön ödemeli",
                "fareSummaryTotalApplePay": "Elma ödemesi",
                "fareSummaryTotalTngeWallet": "Tng ewallet",
                "fareSummaryTotalVippseWallet": "Vippler",
                "fareSummaryTotal": "Toplam",
                "ratingNotes": "Hayır. Notlar",
                "ratingDislike": "Hayır. Sevilmiyor",
                "ratingLike": "Hayır.",
                "fareDetailsBasicFare": "Temel Ücret",
                "fareDetailsAirportFee": "Havaalanı ücreti",
                "fareDetailsRushHour": "Fazla yüklemek",
                "fareDetailsHeavyTraffic": "Yoğun trafik",
                "fareDetailsOtherFees": "Diğer Ücretler",
                "fareDetailsSubTotal": "Alt kat",
                "fareDetailsSubTotalFF": "Subtotal ff",
                "fareDetailsTechFee": "Teknoloji ücreti",
                "fareDetailsBookingFee": "Rezervasyon ücreti",
                "fareDetailsSalesTax": "Satış vergisi",
                "fareDetailsTip": "İpucu amt",
                "fareDetailsPromoAmount": "Promosyon",
                "fareDetailsTotalAmt": "Toplam AMT",
                "availableDistance": "Mevcut",
                "otwDistance": "O.T.W",
                "otwDistanceToolTip": "Yolda mesafede",
                "pobDistance": "P.O.B",
                "pobDistanceToolTip": "Gemide yolcu",
                "ratingStars1": "1 Yıldız",
                "ratingStars2": "No. 2 Yıldız",
                "ratingStars3": "3 Yıldızlar",
                "ratingStars4": "4 Yıldız",
                "ratingStars5": "No. 5 Yıldız",
                "ratingAvg": "Ortalama",
                "ssnNumber": "SSN #",
                "driverEmail": "E -posta adresi",
                "driverAddress": "Ev adresi",
                "fareDetailsAddonPrice": "Müşteri Ayar Fiyatı",
                "fareDetailsOldSubTotal": "Ayarlamadan önce subtotal"
            },
            "dailyCar": {
                "carInfo": "Araba bilgisi",
                "fareDetails": "Ücret Ayrıntıları",
                "completedTrips": "Tamamlanmış geziler",
                "carInfoCompanyName": "Şirket",
                "carInfoPermission": "İzin",
                "carInfoVehiclePlateNumber": "Plaka",
                "carInfoVhcId": "Araba #",
                "carInfoCarType": "Araba tipi",
                "completedTripsTotal": "Toplam",
                "completedTripsCc": "CC'den",
                "completedTripsCarHailing": "Arabadan",
                "completedTripsStreetSharing": "Sokak paylaşımından",
                "completedTripsPaxApp": "PAX uygulamasından",
                "completedTripsWebBooking": "Web rezervasyonundan",
                "completedTripsMDispatcher": "Ortaktan",
                "completedTripsKiosk": "Kiosk'tan",
                "completedTripsAPI": "API'den",
                "fareDetailsBasicFare": "Temel Ücret",
                "fareDetailsAirportFee": "Havaalanı ücreti",
                "fareDetailsMeetDriver": "Şoförle tanışmak",
                "fareDetailsRushHour": "Fazla yüklemek",
                "fareDetailsHeavyTraffic": "Yoğun trafik",
                "fareDetailsTollFee": "Ücret ücreti",
                "fareDetailsParkingFee": "Otopark ücreti",
                "fareDetailsGasFee": "Gaz ücreti",
                "fareDetailsOtherFees": "Diğer Ücretler",
                "fareDetailsSubTotal": "Alt Toplam",
                "fareDetailsSubTotalFF": "Subtotal ff",
                "fareDetailsTechFee": "Teknoloji ücreti",
                "fareDetailsBookingFee": "Rezervasyon ücreti",
                "fareDetailsSalesTax": "Satış vergisi",
                "fareDetailsTip": "Uç",
                "fareDetailsPromoAmount": "Promosyon miktarı",
                "fareDetailsTotalAmt": "Toplam AMT",
                "fareDetailsAddonPrice": "Müşteri Ayar Fiyatı",
                "fareDetailsOldSubTotal": "Ayarlamadan önce subtotal"
            },
            "dailyDriverCar": {
                "driverInfo": "Sürücü bilgileri",
                "driverName": "Sürücü adı",
                "driverIDPermit": "ID# / National IC#",
                "driverPhone": "Telefon numarası",
                "companyName": "Şirket",
                "actionInfo": "Eylem",
                "date": "Tarih",
                "action": "Aksiyon",
                "bookingId": "Rezervasyon kimliği",
                "carInfo": "Araba bilgisi",
                "permission": "İzin",
                "licensePlate": "Plaka",
                "carId": "Araba #",
                "carType": "Araba tipi"
            },
            "driverLoginStatus": {
                "driver": "Sürücü",
                "driverIdPermit": "ID# / National IC#",
                "phone": "Telefon numarası",
                "username": "Kullanıcı adı",
                "company": "Şirket",
                "carType": "Araba tipi",
                "licensePlate": "Plaka",
                "status": "Durum"
            },
            "bookingSummary": {
                "date": "Tarih",
                "operator": "Operatör",
                "username": "Kullanıcı adı",
                "action": "Aksiyon",
                "bookingId": "Rezervasyon kimliği"
            },
            "cashWallet": {
                "bankAccount": "Banka hesabına aktarıldı",
                "creditWallet": "Kredi cüzdanına aktarıldı",
                "tipAfterCompleted": "Tamamlandıktan sonra ipucu",
                "netEarning": "Net kazanç",
                "referralEarning": "Tavsiye Kazanç",
                "receiverAccount": "Alıcı hesabı",
                "dateAndTime": "Tarih ve Saat",
                "driverName": "Sürücü adı",
                "phoneNumber": "Telefon numarası",
                "userName": "Kullanıcı adı",
                "company": "Şirket",
                "type": "Tip",
                "bookingId": "Rezervasyon #",
                "operator": "Operatör",
                "reason": "Sebep",
                "amount": "Miktar",
                "newAmount": "Yeni denge",
                "currency": "Para birimi",
                "topUpGCashReason": "Gcash tarafından hazır",
                "topUpReason": "Kredi kartından doldurun *{0}",
                "cashWalletReason": "Nakit cüzdandan doldurun",
                "topUpMOLPayReason": "Razer Pay [{0}]",
                "chargedAmount": "Ücret tutarı",
                "differentPercent": "İndirim / Ekstra Ücret (%)",
                "operatorName": "Operatör",
                "sendPayout": "Aksiyon",
                "sendPayoutTitle": "Ödeme e -postasını yeniden bitir",
                "payoutReason": "{0} (ödeme {1} {2})"
            },
            "creditWallet": {
                "receiverType": "Alıcı türü",
                "receiverName": "Alıcı Adı",
                "receiverPhone": "Alıcı Telefon Numarası",
                "senderPhone": "Gönderen telefon numarası",
                "senderName": "Gönderen adı",
                "sentToCustomer": "Müşteriye Gönder",
                "sentToDriver": "Sürücüye Gönder",
                "dateAndTime": "Tarih ve Saat",
                "driverName": "Sürücü adı",
                "phoneNumber": "Telefon numarası",
                "userName": "Kullanıcı adı",
                "company": "Şirket",
                "type": "Tip",
                "bookingId": "Rezervasyon #",
                "operator": "Operatör",
                "reason": "Sebep",
                "amount": "Miktar",
                "newAmount": "Yeni denge",
                "currency": "Para birimi",
                "transactionId": "İşlem kimliği",
                "topUpGCashReason": "Gcash tarafından hazır",
                "topUpReason": "Kredi kartından doldurun *{0}",
                "cashWalletReason": "Nakit cüzdandan doldurun",
                "topUpMOLPayReason": "Razer Pay [{0}]",
                "topUpWalletReason": "{0} [{1}]",
                "chargedAmount": "Ücret tutarı",
                "differentPercent": "İndirim / Ekstra Ücret (%)",
                "cashExcessReason": "Müşteri {0} ödemelidir;Nakit alındı ​​{1}",
                "referralEarningReason": "Müşteri {0} {1} İlk rezervasyonu tamamladı {2}",
                "referralEarningReason_2": "Müşteri {0} {1} Tamamlandı Rezervasyon {2}",
                "insurance": "Sürücü sigortası"
            },
            "passengerWallet": {
                "dateTime": "Tarih ve Saat",
                "customerName": "Müşteri Adı",
                "phoneNumber": "Telefon numarası",
                "type": "Tip",
                "bookingId": "Rezervasyon #",
                "changedAmount": "Değişen miktar",
                "amount": "Değişen miktar",
                "newBalance": "Yeni denge",
                "currency": "Para birimi",
                "transactionId": "İşlem kimliği",
                "Note": "Not",
                "credit": "Kredi Kartı ile Topup *{0}",
                "TnG": "Tng #{0}",
                "MOLPay": "[Razerpay - #{0}]",
                "gatewayName": "[{0} - #{1}]",
                "cashExcess": "Müşteri {0} ödemeli, {1} verilen nakit ödemeli",
                "editBalance": "Bakiyeyi Düzenle",
                "ride": "Sürmek",
                "topUp": "Doldurmak",
                "topUpReferee": "Tavsiye Ödülü ({0})",
                "topUpReferrer": "Yeni hakem (#{0}): {1} ({2})"
            },
            "recipientsInfo": {
                "order": "HAYIR.",
                "orderId": "Emir #",
                "name": "Alıcı adı",
                "phone": "Alıcı Telefon #",
                "address": "Alıcı adresi",
                "deliveryFee": "Teslimat ücreti",
                "ItemValue": "Öğe Değeri (COD)",
                "status": "Durum",
                "Performer": "İcracı",
                "Reason": "Sebep",
                "proofOfFailure": "Başarısızlık kanıtı",
                "referenceNumber": "Referans numarası"
            },
            "dispatchHistory": {
                "performer": "İcracı",
                "name": "İsim",
                "action": "Aksiyon",
                "date": "Tarih",
                "location": "Sürücü Konumu",
                "estimate": "Araç-Pikap Konumu Tahmini",
                "passenger": "Müşteri",
                "detachVehicle": "Detach Vehicle",
                "assignVehicle": "Assign Vehicle"
            },
            "payToDriver": {
                "driverName": "Sürücü",
                "totalUnsettledTransactions": "Toplamda yerleşmemiş işlemler",
                "totalUnsettledAmount": "Toplamda yerleşmemiş miktar",
                "actions": "Eylem",
                "pay": "Ödemek",
                "remove": "Kaldırmak",
                "clearSuccess": "Seçilen sürücünün kazanılması başarıyla temizlendi.",
                "clearFailed": "Sürücünün kazanç verilerini temizleyemedi.Lütfen tekrar deneyin.",
                "selectDriver": {
                    "title": "",
                    "body": "Lütfen ödemek için sürücüyü seçin.",
                    "buttonTitle": "TAMAM"
                },
                "confirmClear": {
                    "title": "",
                    "body": "Bu sürücünün kazanç verilerini temizlemek istediğinizden emin misiniz?Bu eylem geri alınamaz.",
                    "buttonTitle": "Evet",
                    "closeButtonText": "HAYIR"
                },
                "confirmPay": {
                    "body": "Seçilen sürücü (ler) 'e ödeme yapmak istediğinizden emin misiniz?",
                    "buttonTitle": "Evet",
                    "closeButtonText": "HAYIR"
                },
                "paymentSuccessfully": "Ödemeler sürücü (ler) 'e başarıyla yerleşti"
            },
            "customerQuest": {
                "name": "İsim",
                "phoneNumber": "Telefon numarası",
                "supportId": "Destek Kimliği",
                "ranking": "Sıralama",
                "points": "Puan",
                "numberOfReservation": "Rezervasyon Sayısı",
                "driver": "Sürücü",
                "customer": "Müşteri",
                "firstWillWin": "Birincisi kazanacak",
                "firstWillWinLuckyDraw": "İlk Kazanacak (Lucky Draw)",
                "largestWillWin": "En Büyük Kazanacak (Lider Taht)",
                "qualified": "Nitelikli",
                "notQualified": "Nitelikli değil",
                "userName": "Kullanıcı adı",
                "completedDate": "Tamamlanmış tarih",
                "isQualified": "Durum",
                "ticket": "Bilet",
                "numOfComplete": "Tamamlanan sayı",
                "bookingDistance": "P.O.B mesafesi",
                "numberOfIntercity": "Şehirlerarası sayısı",
                "numberOfDelivery": "Teslimat Sayısı",
                "pleaseSelectEventName": "Lütfen Etkinlik Adını seçin.",
                "numberOfQualifiedSetting": "Kalifiye Sayısı (ayar):",
                "numberOfActualQualified": "Gerçek nitelikli sayı:",
                "driverRating": "Sürücü derecesi",
                "acceptanceRating": "Kabul derecesi",
                "cancellationRating": "İptal notu",
                "period": "Dönem:"
            },
            "payoutToDriver": {
                "IFSCCode": "IFSC Kodu",
                "payoutID": "Ödeme Kimliği",
                "driverName": "Sürücü",
                "driverPhone": "Telefon numarası",
                "companyName": "Şirket",
                "period": "Dönem",
                "driverId": "ID# / National IC#",
                "cashWalletBalance": "Nakit cüzdan bakiyesi",
                "creditBalance": "Kredi bakiyesi",
                "holdAmount": "Tutma miktarı",
                "minPayout": "Min Ödeme",
                "enterMinimumPayoutAmount": "Minimum ödeme tutarı girin",
                "payoutAmount": "Ödeme tutarı",
                "payoutDate": "Ödeme tarihi",
                "actions": "Eylem",
                "pay": "Ödemek",
                "remove": "Kaldırmak",
                "payoutEndTime": "Ödeme Bitiş Süresi",
                "clearSuccess": "Seçilen sürücünün kazanılması başarıyla temizlendi.",
                "clearFailed": "Sürücünün kazanç verilerini temizleyemedi.Lütfen tekrar deneyin.",
                "amountKeepInDriverCashWallet": "Tutar Sürücünün nakit cüzdanında saklayın",
                "selectDriver": {
                    "title": "",
                    "body": "Lütfen ödemek için sürücüyü seçin.",
                    "buttonTitle": "TAMAM"
                },
                "confirmClear": {
                    "title": "",
                    "body": "Bu sürücünün kazanç verilerini temizlemek istediğinizden emin misiniz?Bu eylem geri alınamaz.",
                    "buttonTitle": "Evet",
                    "closeButtonText": "HAYIR"
                },
                "confirmPay": {
                    "body": "Seçilen sürücü (ler) 'e ödeme yapmak istediğinizden emin misiniz?<br> - Sürücü Sayısı: <b>%{Drivers} </b> <br> - Her sürücü için min ödeme:%{para birimi} <b>%{min} </b> <br> - BekletmeMiktar: %{currence} <b> %{Hold} </b> <br> - Toplam ödeme: %{currency} <b> %{toplam} </b>",
                    "buttonTitle": "Evet",
                    "closeButtonText": "HAYIR"
                },
                "paymentSuccessfully": "Ödemeler sürücü (ler) 'e başarıyla yerleşti",
                "paymentCompleted": "Ödeme tamamlandı: <br> - Başarılı bir şekilde ödeme: %{başarı} <br> - Başarısız: %{başarısız} <br> - Toplam Ödeme Başarılı: %{Para Birimi} %{Toplam}",
                "noteSelectDrivers": "Not: Ödemek için banka hesabı olan sürücüleri seçin.",
                "sendPayout": "E -posta Adresine Ödeme Başarılı Bildirimi Gönderin",
                "accountNumber": "Hesap numarası",
                "iBanNumber": "Iban Numarası",
                "accountHoldName": "Hesap sahibinin adı",
                "bankName": "Banka adı",
                "selelctedDriverHasBeenPaid": "Seçilen sürücü (ler) ödendi",
                "selelctedDriverHasBeenFailed": "Seçilen sürücü (ler) başarısız oldu",
                "selelctedDriverHasBeenUpdateAsInvalid": "Seçilen sürücü (ler) 'geçersiz banka hesabı' olarak güncellendi",
                "selelctedDriverHasBeenUpdateAsValid": "Seçilen sürücü (ler) 'geçerli banka hesabı' olarak güncellendi",
                "isBankAccountOwner": "Sürücü ile aynı yararlanıcı",
                "isBankAccountOwner_true": "Evet",
                "isBankAccountOwner_false": "HAYIR",
                "beneficiaryIDIC": "Yararlanıcı Kimliği# / IC#",
                "bankRelationship": "İlişki",
                "bankRelationship_1": "Eş",
                "bankRelationship_2": "Kızı/oğul",
                "bankRelationship_3": "Erkek kardeş/kız kardeş",
                "bankRelationship_4": "Akraba",
                "bankRelationship_5": "Arkadaşlar",
                "bankRelationship_6": "Diğer: {0}"
            },
            "payoutToMerchant": {
                "payoutId": "Ödeme Kimliği",
                "merchantName": "Tüccar adı",
                "phone": "Telefon numarası",
                "accountNumber": "Hesap numarası",
                "iBanNumber": "Iban Numarası",
                "bankAccountHolder": "Hesap sahibinin adı",
                "bankName": "Banka adı",
                "pendingAmount": "Bekleyen tutar",
                "payoutAmount": "Ödeme tutarı",
                "payoutDate": "Ödeme tarihi",
                "holdAmount": "Tutma miktarı",
                "minPayout": "Min Ödeme",
                "selelctedMerchantHasBeenPaid": "Seçilen satıcı (lar) ödendi",
                "selelctedMerchantHasBeenFailed": "Seçilen satıcı (lar) başarısız oldu",
                "pay": "Ödemek",
                "selectMerchant": {
                    "title": "Uyarı",
                    "body": "Lütfen ödemek için satıcı seçin.",
                    "buttonTitle": "TAMAM"
                },
                "confirmPay": {
                    "body": "Seçilen satıcılara ödeme yapmak istediğinden emin misiniz?<br> - tüccar sayısı: <b>%{tüccarlar} </b> <br> - Her sürücü için min ödeme:%{currics}%{min} </b> <br> - BekletmeMiktar: %{currence} <b> %{Hold} </b> <br> - Toplam ödeme: %{currency} <b> %{toplam} </b>",
                    "buttonTitle": "Evet",
                    "closeButtonText": "HAYIR"
                },
                "sendPayout": "E -posta Adresine Ödeme Başarılı Bildirimi Gönderin",
                "HasBeenUpdateAsInvalid": "Seçilen satıcı (lar) 'geçersiz banka hesabı' olarak güncellendi",
                "HasBeenUpdateAsValid": "Seçilen satıcı (lar) 'geçerli banka hesabı' olarak güncellendi"
            },
            "settlementHistory": {
                "date": "Tarih",
                "driverName": "Sürücü adı",
                "dateRange": "Tarih aralığı",
                "totalTransaction": "Toplam Yerleşik İşlemler",
                "paidAmount": "Toplam Yerleşik Miktar"
            },
            "settlement": {
                "driverName": "Sürücü",
                "driverIdPermit": "ID# / National IC#",
                "totalBookings": "Toplam rezervasyon",
                "total": "Toplam AMT",
                "approved": "Onaylı",
                "acct": "Acct",
                "gross": "BRÜT",
                "percent": "0.03",
                "net": "AÇIK"
            },
            "documentExpiry": {
                "driverName": "Sürücü adı",
                "driverPhone": "Sürücü telefonu",
                "company": "Şirket",
                "documentName": "Belge adı",
                "expiredDate": "Süresi dolmuş tarih",
                "status": "Durum",
                "statusStr": {
                    "NA": "N/A",
                    "expired": "Günü geçmiş",
                    "active": "Aktif",
                    "expireSoon": "Yakında sona ermek"
                }
            },
            "referralDetail": {
                "bookingId": "Kitap #",
                "completedDate": "Tamamlanmış zaman",
                "paidBy": "Ödenen",
                "bookFrom": "Rezervasyon",
                "driverName": "Sürücü adı",
                "driverIdPermit": "ID# / National IC#",
                "companyName": "Şirket",
                "Passenger": "Yolcu",
                "Customer": "Müşteri",
                "subtotal": "Alt kat",
                "subTotalFF": "Subtotal ff",
                "subtotalWithPromo": "Promosyonla Alt Total",
                "referralEarning": "Tavsiye Kazanç",
                "Amount": "Miktar",
                "Note": "Not",
                "Currency": "Para birimi",
                "PercentOfSubTotal": "{0}% subtotal",
                "max": "Max {0}",
                "referrerInfo": "Sürücü müşteriyi ifade eder",
                "phoneReferral": "Sürücü Telefon #",
                "driverNumber": "ID# / National IC#",
                "referralCode": "Tavsiye Kodu"
            },
            "referralHistory": {
                "driverName": "Sürücü adı",
                "phoneNumber": "Telefon numarası",
                "companyName": "Şirket",
                "referralCode": "Tavsiye Kodu",
                "commission": "Toplam Komisyon",
                "expectedReferralEarning": "Tavsiye Kazanç Bekleyin",
                "activePaxTooltip": "En az bir rezervasyon ile sürücüden tavsiye kodunu kullanan yolcular başarıyla yapılmıştır.",
                "referredPaxTooltip": "Tavsiye kodunu sürücüden kurtaran ancak henüz herhangi bir rezervasyon yapmayan yolcular.",
                "activePax": "Toplam aktif müşteriler",
                "referredPax": "Total referanslı müşteriler",
                "detailFields": {
                    "passengerId": "Yolcu kimliği",
                    "passengerName": "Müşteri Adı",
                    "firstBookingDate": "1. tamamlanmış rezervasyon tarihi",
                    "firstBookingDateGMT": "1. Tamamlanan Rezervasyon Tarihi (GMT)",
                    "passengerPhone": "Müşteri Telefon Numarası",
                    "appliedDate": "Yönlendirilen tarih",
                    "referredByDriver": "Sürücü tarafından sevk edildi",
                    "driverPhone": "Sürücü Telefon Numarası",
                    "referralCode": "Tavsiye Kodu"
                },
                "showDetail": "Göstermek",
                "detail": "Detay"
            },
            "paxReferralHistory": {
                "customerName": "Müşteri Adı",
                "phoneNumber": "Telefon numarası",
                "referralCode": "Tavsiye Kodu",
                "commission": "Toplam Komisyon",
                "activePaxTooltip": "Tavsiye kodunu en az bir rezervasyonla kurtaran yolcular başarıyla yapılmıştır.",
                "referredPaxTooltip": "Tavsiye kodunu kullanan ancak henüz herhangi bir rezervasyon yapmayan yolcular.",
                "activePax": "Toplam aktif müşteriler",
                "referredPax": "Total referanslı müşteriler",
                "detailFields": {
                    "passengerId": "Yolcu kimliği",
                    "passengerName": "Yolcu adı",
                    "firstBookingDate": "1. tamamlanmış rezervasyon tarihi",
                    "firstBookingDateGMT": "1. Tamamlanan Rezervasyon Tarihi (GMT)"
                },
                "showDetail": "Göstermek",
                "detail": "Detay"
            },
            "driverRefersDriver": {
                "companyName": "Şirket",
                "phoneNumber": "Telefon numarası",
                "username": "Kullanıcı adı",
                "referralCode": "Tavsiye Kodu",
                "referrer": "Yönlendiren kimse",
                "numberOfReferees": "Hakem sayısı",
                "activeReferees": "Aktif hakemler",
                "referee": "Hakem",
                "refereePhone": "Hakem telefon numarası",
                "refereeUsername": "Hakem kullanıcı adı",
                "referredDate": "Yönlendirilen tarih",
                "activatedDate": "Etkin tarih",
                "referrerByDriver": "Yönlendiren kimse",
                "referrerPhone": "Yönlendirici Telefon Numarası",
                "referralStatus": "Tavsiye Durumu",
                "lifeTimeBookings": "Yaşam Zamanı Rezervasyonları",
                "inReview": "İncelemede",
                "activated": "Aktif"
            },
            "driverWithdrawal": {
                "transferToEWallet": "Transferred to external e-wallet *{0}",
                "channelCode": "Channel code",
                "channelName": "Channel name",
                "reversed": "Reversed",
                "currentBalanceTooltip": "Para çekme talebinden önce sürücünün nakit cüzdanının mevcut bakiyesi",
                "paymentTime": "Ödeme süresi",
                "requestTime": "Talep Zamanı",
                "referenceId": "Referans #",
                "transactionId": "İşlem kimliği",
                "description": "Tanım",
                "transferAmount": "Transfer miktarı",
                "currency": "Para birimi",
                "approved": "Onaylı",
                "rejected": "Reddedilmiş",
                "failed": "Arızalı",
                "reason": "Sebep"
            },
            "penaltyCompensation": {
                "penaltyAmount": "Penaltı",
                "totalCollect": "Yerel para biriminde ödeme yapmanız gerekiyor",
                "totalCollectInUSD": "USD olarak ödeme yapmanız gerekiyor",
                "compensationAmount": "Telâfi etmek",
                "compensationAmountInUSD": "Telafi (USD)",
                "penaltyReason": "Ceza nedeni",
                "estimatedPrice": "Tahmini fiyat",
                "estimatedPriceInUSD": "Tahmini fiyat (USD)",
                "penaltyAmountInUSD": "Ceza (USD)",
                "amount": "Miktar",
                "amountInUSD": "Miktar (USD)"
            },
            "imcompletePayment": {
                "bookingId": "Rezervasyon kimliği",
                "bookFrom": "Rezervasyon",
                "bookingTime": "Tarih/Saat Rezervasyonu",
                "completedTime": "Tarih/saat rezervasyonu tamamlandı",
                "customerName": "Müşteri Adı",
                "requestedPaymentMethod": "Talep edilen ödeme yöntemi",
                "originalPaymentStatus": "Orijinal Ödeme Durumu",
                "currentPaymentStatus": "Mevcut Ödeme Durumu",
                "updatedDateTime": "Toplanan tarih/saat bakiyesi",
                "totalAmt": "Toplam AMT",
                "originalOutstandingAmount": "Orijinal olağanüstü tutar",
                "currentOutstandingAmount": "Mevcut olağanüstü tutar",
                "outstandingAmountPaidBy": "Üstün Tutar Tahsilat",
                "writeOffDebtMethod": "Üstün Tutar Tahsilat",
                "reason": "Sebep",
                "customerPhone": "Telefon numarası",
                "amountCollected": "Toplanan miktar",
                "partialPaymentAmount": "Bırakıldığında kısmi ödeme"
            },
            "driverDeposit": {
                "dateAndTime": "Tarih ve Saat",
                "userName": "Kullanıcı adı",
                "type": "Tip",
                "newAmount": "Yeni denge",
                "topUpGCashReason": "Gcash tarafından hazır",
                "topUpReason": "Kredi kartından doldurun *{0}",
                "cashWalletReason": "Nakit cüzdandan doldurun",
                "topUpMOLPayReason": "Razer Pay [{0}]"
            },
            "refund": {
                "fullRefund": "Tam Geri Ödeme",
                "partialRefund": "Kısmi geri ödeme",
                "tripId": "Gezi kimliği",
                "route": "Rota",
                "refundAmount": "Geri Ödeme Tutarı",
                "refundTime": "Geri ödeme süresi",
                "paymentStatus": "Ödeme durumu"
            },
            "merchant": {
                "store": "Mağaza",
                "name": "İsim",
                "phoneNumber": "Telefon numarası",
                "bookingId": "Rezervasyon #",
                "totalOrders": "Toplam Siparişler",
                "completedOrders": "Tamamlanmış siparişler",
                "canceledOrders": "İptal edilen siparişler",
                "incidentOrders": "Olay",
                "revenue": "Hasılat",
                "commission": "Komisyon",
                "earning": "Kazanç",
                "food": "Yiyecek",
                "mart": "Müthiş",
                "preferredMerchant": "Tercih edilen tüccar",
                "regularMerchant": "Düzenli Tüccar",
                "companyName": "Company"
            },
            "merchantCashTransactionHistory": {
                "companyName": "Company",
                "dateTime": "Tarih ve Saat",
                "merchantUserName": "Satıcı Kullanıcısı",
                "merchantUserPhone": "Telefon numarası",
                "merchantUserEmail": "E -posta",
                "merchantName": "Tüccar",
                "transactionType": "Tip",
                "bookId": "Rezervasyon #",
                "transactionId": "İşlem kimliği",
                "operatorName": "Operatör",
                "reason": "Sebep",
                "amount": "Miktar",
                "newBalance": "Yeni denge",
                "currencyISO": "Para birimi"
            },
            "merchantCreditTransactionHistory": {
                "dateTime": "Tarih ve Saat",
                "merchantUserName": "Satıcı Kullanıcısı",
                "merchantUserPhone": "Telefon numarası",
                "merchantUserEmail": "E -posta",
                "merchantName": "Tüccar",
                "transactionType": "Tip",
                "bookId": "Rezervasyon #",
                "transactionId": "İşlem kimliği",
                "operatorName": "Operatör",
                "reason": "Sebep",
                "amount": "Miktar",
                "newBalance": "Yeni denge",
                "currencyISO": "Para birimi"
            },
            "deleteAccountRequest": {
                "date": "Tarih",
                "phone": "Telefon",
                "username": "Kullanıcı adı",
                "supportId": "Destek Kimliği",
                "requestFrom": "İstemek",
                "associatedApps": "İlişkili uygulamalar",
                "reason": "Sebep"
            },
            "affiliationPayoutHistory": {
                "payoutId": "Ödeme kimliği",
                "dateTime": "Tarih ve Saat",
                "payoutType": "Tip",
                "accountHolderName": "Hesap sahibinin adı",
                "bankName": "Banka adı",
                "accountNumber": "Hesap numarası",
                "totalPayout": "Toplam Ödeme (USD)",
                "viewDetails": "Ayrıntıları Görüntüle",
                "notes": "Not",
                "bookId": "Rezervasyon #",
                "transactionType": "İşlem türü",
                "transactionId": "İşlem Kimliği (Otomatik Aktarım)",
                "settlement": "Yerleşim",
                "paymentMethod": "Tip",
                "networkType": "Ağ türü"
            },
            "thirdPartyBooking": {
                "date": "Tarih",
                "bookingId": "Rezervasyon kimliği",
                "externalId": "Harici kimlik",
                "psgPhone": "Yolcu telefon numarası",
                "psgName": "Yolcu adı",
                "pickupLocation": "Pikap Konumu",
                "pickupLocationLatLong": "Pikap Konumu Lat/Uzun",
                "dropoffLocation": "Dropoff Location",
                "dropoffLocationLatLong": "Lat/uzun",
                "pickupTime": "Pikap zamanı",
                "numOfPassenger": "Yolcu Sayısı",
                "action": "Aksiyon"
            }
        }
    },
    "zoneSetting": {
        "Add": "Eklemek",
        "Import": "İçe aktarmak",
        "Name": "İsim",
        "Status": "Durum",
        "Active": "Aktif",
        "Inactive": "Aktif olmayan",
        "Deactivate": "Devre dışı bırakmak",
        "Activate": "Etkinleştirmek",
        "Delete": "Silmek",
        "Cancel": "İptal etmek",
        "Input_zone_name": "Giriş Bölgesi Adı",
        "Save_zone": "Tasarruf Bölgesi",
        "Clear_draw": "Net çekiliş",
        "Edit": "Düzenlemek",
        "Delete_zone_success": "Bölge başarıyla silindi",
        "Delete_zone_fail": "Hatalar silme bölgesi sırasında meydana geldi",
        "Update_zone_fail": "Bölgeyi güncellerken hatalar meydana geldi",
        "Update_zone_success": "Bölge başarıyla güncellendi",
        "Create_zone_fail": "Bölge oluştururken hatalar meydana geldi",
        "Create_zone_success": "Yeni Bölge başarıyla yaratıldı",
        "Draw_zone": "Lütfen haritaya bölge çizin",
        "Import_zones_successful": "İthalat Bölgeleri Başarılı",
        "Import_zones_fail": "Bölgeleri içe aktaramadı",
        "DELETE_ZONE": "Bölgeyi Sil",
        "ZONE_CONFIRM": "Seçilen bölgeyi silmek istediğinizden emin misiniz?",
        "Name_zone_required": "İsim ve bölge gereklidir!",
        "Select_file": "Dosyayı seçin",
        "No": "HAYIR",
        "Yes": "Evet",
        "Import_zones_intercity_successful": "[{0}] Bölge (ler) başarıyla ithal edildi, [{1}] başarısız oldu.",
        "Import_zones_intercity_successful_failed": "[{0}] Bölge (ler) başarıyla ithal edildi, [{1}] başarısız oldu.",
        "Flat_zone": "Düz bölge",
        "Json_file": "JSON DOSYASI",
        "Import_zones": "İthalat bölgeleri",
        "timezone": "Zaman dilimi"
    },
    "companySetting": {
        "DELETE_COMPANY": "Şirketi Sil",
        "DELETE_CONFIRM": "Şirketi silmek istiyor musunuz?",
        "DETAIL_COMPANY": "Detay Şirketi",
        "commissionValue": "Şirket Komisyonu",
        "connectStatus": "Stripe Connect",
        "connectStripe": "Bağlamak",
        "stripeConnectId": "Stripe Hesap Kimliği",
        "loginStripe": "Gösterge paneli",
        "disconnectStripe": "Koparmak",
        "Yes": "Evet",
        "No": "HAYIR",
        "Cancel": "İptal etmek",
        "EDIT_COMPANY": "Şirket Tedarikçisini Düzenle",
        "ADD_COMPANY": "Şirket Tedarikçisi Ekle",
        "Name": "İsim",
        "Required_fields": "Gerekli Alanlar",
        "Save": "Kaydetmek",
        "Actions": "Eylem",
        "Edit": "Düzenlemek",
        "Add": "Eklemek",
        "Show_per_page": "Sayfa başına öğeler",
        "Page": "Sayfa",
        "show_pages": "{0} - {1} {2} gösterilen",
        "Create_company_success": "Yeni şirket başarıyla yaratıldı",
        "Create_company_fail": "Hatalar şirket oluştururken meydana geldi",
        "Update_company_success": "Şirket başarıyla güncellendi",
        "Update_company_fail": "Hatalar şirketi güncellerken meydana geldi",
        "Load_company_fail": "Hatalar şirket yüklerken meydana geldi",
        "Delete_company_success": "Şirket başarıyla silindi",
        "Delete_company_fail": "Hatalar şirketi silerken meydana geldi",
        "hideFare": "Her zaman ücret",
        "Broadcast_orders": "Tüm siparişleri yayınlayın",
        "paymentMethodsApply": "Destek Ödeme Yöntemi",
        "emailsNotification": "E -posta Bildirimi",
        "CopySignUpLink": "Kopyala Kayıt Bağlantısı",
        "operationZone": "Operasyon bölgesi",
        "delayBroadcast": "Sonra sipariş göster",
        "releaseTime": "Release Time",
        "advanceBookingVisibility": "Advance booking visibility",
        "advanceBookingVisibilityTooltip": "Set the number of days before the pickup date that a supplier can see the booking. This ensures that bookings are released to drivers in a timely manner, based on each supplier."
    },
    "dispatchSetting": {
        "GENERAL": "Genel",
        "Offer_interval": "Teklif Aralığı (Sürücü)",
        "Offer_interval_merchant": "Teklif Aralığı (Satıcı)",
        "Offer_interval_merchant_toolTip": "Bir tüccarın siparişi kabul etmesi için maksimum süre",
        "Offer_interval_toolTip": "Bir sürücünün rezervasyonu kabul etmesi için maksimum süre",
        "soundMarketplace": "Ses",
        "sound": "Ses",
        "Auto_re_dispatch_interval_toolTip": "2 tur gönderme süresi",
        "Maximum_auto_dispatch_duration_toolTip": "Sürücülere rezervasyon göndermenin maksimum süresi",
        "Seconds": "Saniye",
        "Auto_re_dispatch_interval": "Otomatik Yeniden Dispatch Aralığı",
        "Maximum_auto_dispatch_duration": "Maksimum Otomatik Dispatch Süresi",
        "Time_out": "Zaman aşımı",
        "mins": "doyurma",
        "minTimeBeforeReservation": "Sürücünün pikap zamanından önce rezervasyon başlatmasına izin vermeyin",
        "IN_ADVANCE": "PEŞİN",
        "Assign_reservation_to_driver": "Sürücüye rezervasyon atayın",
        "Dispatcher_confirms_job_for_driver": "Dispatcher, sürücü için işi onaylıyor",
        "Dispatcher_confirms_job_for_offline_driver": "Dispatcher, çevrimdışı sürücü için rezervasyonu onayladı",
        "Broadcast_orders": "Tüm şirketlere yayın siparişleri",
        "Broadcast_orders_tooltip_1": "Tüm gezileri tüm şirket tedarikçilerine yayınlamak istiyorsanız bu ayarı etkinleştirin.\nHer şirketin profilinde yayın modunu etkinleştirmeniz gerektiğini unutmayın.",
        "Minimum_book_ahead": "Minimum Kitap İleri",
        "Maximum_book_ahead": "Maksimum Kitap İleri - Tek Sipariş",
        "Maximum_period_recurring_oders_fleet": "Yinelenen siparişler için maksimum dönem (filo operatörü)",
        "Maximum_period_recurring_oders_corp": "Yinelenen siparişler için maksimum dönem (kurumsal)",
        "Day": "Gün",
        "Week": "Hafta",
        "Month": "Ay",
        "hrs": "saat",
        "Driver_cancellation_duration": "Sürücü iptal süresi",
        "Mins": "doyurma",
        "Maximum_Day": "Maksimum rezervasyon sürücüsü bir günde kabul edebilir.",
        "Maximum_Accept_Reservation": "Bir sürücünün bir seferde kabul edebileceği toplam rezervasyon sayısı.",
        "Local_notification_before": "Daha önce yerel bildirim",
        "Dispatch_mode": "Dağıtım modu",
        "Manual": "Manuel",
        "Immediate_auto_dispatch": "Anında otomatik dispatch",
        "Delayed_auto_dispatch": "Gecikmeli Otomatik Dispatch",
        "Auto_dispatch_before_pickup_time": "Pikap zamanından önce otomatik gönderme",
        "Required_fields": "Gerekli Alanlar",
        "Shuttle": "Servis",
        "Shuttle_Dispatch_mode": "Dağıtım modu",
        "Dispatch_setting_Shuttle_Dispatch_mode_tooltip": "- Manuel Modu: Rezervasyon oluşturulduktan sonra “bekleyen” durumu altında komuta merkezinizin ipucuna kaydedilecek ve kalacaktır.Rezervasyon ayrıntısında “Güncelleme Rezervasyonu” na tıkladıktan sonra, atanan sürücülerinize veya sürücü atamaması durumunda tüm sürücülere rezervasyon gönderilecektir.Sürücü cihazı takvimine entegre edilecek ve hatırlatma sunulacak.<br>- Gecikmeli Mod: Rezervasyon, rezervasyon oluşturulduktan sonra “bekleyen” durumu altında komuta merkezinizin ipucuna kaydedilecek ve kalacaktır.Rezervasyon, alma süresinden atanan sürücüye veya sürücü atamaması durumunda “önceden işlem” i zaman değeri veya mevcut olana kadar otomatik olarak gönderilecektir.<br>- Anında Otomatik Dispatch Modu: Rezervasyon oluşturulduktan sonra rezervasyon otomatik olarak tüm sürücülere gönderilecektir.Sürücü cihazı takvimine entegre edilecek ve hatırlatma sunulacak.",
        "Save": "Kaydetmek",
        "Process_in_advance": "Önceden işlemek",
        "Save_dispatch_success": "Dispatch başarıyla güncellendi",
        "Save_dispatch_fail": "Dispatch'i güncellerken hatalar vardı",
        "Dispatch_setting_general_tooltip": "Gecikmeli Otomatik Discatch ile önceden rezervasyon yaparak talep üzerine rezervasyon için başvurun",
        "Dispatch_setting_Assign_reservation_to_driver_tooltip": "Bu seçenek etkinleştirildiğinde rezervasyon otomatik olarak atanır ve kabul edilir",
        "Dispatch_setting_Driver_cancellation_duration_tooltip": "Süre sürücünün pikap süresinin rezervasyonu iptal ettiği zamandır",
        "Dispatch_setting_Dispatch_mode_tooltip": "- Manuel Modu: Rezervasyon oluşturulduktan sonra “bekleyen” durumu altında komuta merkezinizin ipucuna kaydedilecek ve kalacaktır.Rezervasyon ayrıntısında “Güncelleme Rezervasyonu” na tıkladıktan sonra, atanan sürücülerinize veya sürücü atamaması durumunda tüm sürücülere rezervasyon gönderilecektir.Sürücü cihazı takvimine entegre edilecek ve hatırlatma sunulacak.<br>- Gecikmeli Mod: Rezervasyon, rezervasyon oluşturulduktan sonra “bekleyen” durumu altında komuta merkezinizin ipucuna kaydedilecek ve kalacaktır.Rezervasyon, alma süresinden atanan sürücüye veya sürücü atamaması durumunda “önceden işlem” i zaman değeri veya mevcut olana kadar otomatik olarak gönderilecektir.<br>- Anında Otomatik Dispatch Modu: Rezervasyon oluşturulduktan sonra rezervasyon otomatik olarak tüm sürücülere gönderilecektir.Sürücü cihazı takvimine entegre edilecek ve hatırlatma sunulacak.",
        "Notification_nearby_drivers_before_pickup_time": "Pikap saatinden önce yakındaki sürücüleri bilgilendirin",
        "Dispatch_setting_Maximum_Day_tooltip": "Bu, sürücülerin aynı gün çok fazla çekinceye sahip olmasını sınırlamaktır.Pazar ve şehirlerarası için geçerlidir.",
        "Maximum_Accept_Reservation_tooltip": "Sürücü tarafından kabul edilen rezervasyon sayısı bu sayıyı aşamaz.Bu, sürücünün her seferinde çok fazla kabul etmesini sınırlar.Pazar ve şehirlerarası için geçerlidir.",
        "Notification_nearby_drivers_before_pickup_time_tooltip": "Bir müşterinin rezervasyonu belirlenen zamandan önce herhangi bir sürücü tarafından kabul edilmediyse, pikap süresinden önce, sistem yakındaki sürücüleri bilgilendirecektir.Bu, umarım rezervasyonu kabul etmek için pazarları kontrol etmelerini isteyecektir.",
        "marketPlace": "Pazar yeri",
        "howFarDriverSeeBookingMarketplace": "Sürücü pazardaki rezervasyonları ne kadar görebilir?",
        "shortNoticeOrder": "Short notice order",
        "shortNoticeOrderTooltip": "Set the time frame for bookings to be considered urgent and visible to all tiers immediately."
    },
    "voiceSMSSetting": {
        "ACCOUNT_SETTINGS": "Hesap Ayarları",
        "Account_SID": "Hesap Sid",
        "Auth_Token": "Auth Jeton",
        "Application_ID": "Uygulama Kimliği",
        "SMS_number_1": "SMS numarası 1",
        "SMS_number_2": "SMS numarası 2",
        "SMS_SETTINGS": "SMS Ayarları",
        "MASKED_PHONE_SETTINGS": "Maskeli Telefon Ayarları",
        "SMS_MARKETING_SETTINGS": "SMS Pazarlama Ayarları",
        "Phone_number": "Telefon numarası",
        "Save": "Kaydetmek",
        "voiceSMS": "Twilio",
        "DRIVER_APP": "Sürücü uygulaması",
        "PASSENGER_APP": "Yolcu uygulaması",
        "allowDriverToCallCC": "Sürücünün yardım masasını aramasına izin verin:",
        "allowDriverToCallHelpdesk": "Sürücünün yardım masasını aramasına izin verin:",
        "allowDriverToCallPassenger": "Sürücünün yolcuları aramasına izin verin:",
        "allowPassengerToCallCC": "Yolcunun Yardım Masası'nı aramasına izin verin:",
        "allowPassengerToCallHelpdesk": "Yolcunun Yardım Masası'nı aramasına izin verin:",
        "allowPassengerToCallDriver": "Yolcunun sürücüyü aramasına izin verin:",
        "voip": "Voip",
        "mobile": "Hareketli",
        "bookingSMS": "Mesaj şablonu",
        "bookingSMSTemplate": {
            "name": "İsim",
            "content": "İçerik",
            "action": "Aksiyon",
            "DELETE": "Mesaj şablonunu sil",
            "DELETE_CONFIRM": "Seçilen mesaj şablonunu silmek istediğinizden emin misiniz?",
            "EDIT": "Mesaj şablonunu düzenle",
            "ADD": "Mesaj şablonu ekle",
            "DETAIL": "Detay mesaj şablonu",
            "Update_success": "Mesaj şablonu başarıyla güncellendi",
            "Create_success": "Mesaj şablonu başarıyla oluşturuldu",
            "Delete_success": "Mesaj şablonu başarıyla silindi",
            "Delete_fail": "Mesaj şablonu silinirken hatalar meydana geldi",
            "Load_fail": "Mesaj şablonu yüklenirken hatalar meydana geldi",
            "Update_fail": "Mesaj şablonunu güncellerken hatalar meydana geldi",
            "Create_fail": "Mesaj şablonu oluştururken hatalar meydana geldi"
        },
        "bookingSMSModal": {
            "title": "SMS şablonu ekle",
            "save": "Kaydetmek",
            "cancel": "İptal etmek",
            "titleMessage": "Mesaj Gönder",
            "template": "Şablon",
            "phoneNumber": "Telefon numarası",
            "content": "İçerik",
            "messageType": "Mesaj türü",
            "inbox": "Gelen kutusu",
            "sms": "SMS",
            "sendTo": "Gönderildi",
            "passenger": "Yolcu",
            "driver": "Sürücü"
        }
    },
    "regularSetting": {
        "extraWaitTimeTooltip": "Müşteri için gemiye kadar ek süre beklemek zorunda kaldığında sürücüyü telafi etmek için ücret.",
        "fareWaitTimeLater": "Dakikada ücret (rezervasyon)",
        "fareWaitTimeNow": "Dakikada ücret (şimdi)",
        "transport": "Taşıma",
        "delivery": "Teslimat",
        "Fer_Stop": "Ücret Fer Durdur",
        "First_Stop": "İlk Durak Ücreti",
        "Each_Stop": "Her ekstra durak için ücret",
        "Name": "İsim",
        "Pay_to_driver_Cash": "Sürücüye Ödeme (nakit rezervasyon)",
        "Last_Update": "Son Güncelleme",
        "Status": "Durum",
        "Required_fields": "Gerekli Alanlar",
        "Yes": "Evet",
        "No": "HAYIR",
        "Cancel": "İptal etmek",
        "Save": "Kaydetmek",
        "Actions": "Eylem",
        "Edit": "Düzenlemek",
        "Add": "Eklemek",
        "Show_per_page": "Sayfa başına öğeler",
        "Page": "Sayfa",
        "show_pages": "{0} - {1} {2} gösterilen",
        "DELETE_CONFIRM": "Silmek ister misin?",
        "ADD_REGULAR_RATE": "Normal oran ekle",
        "EDIT_REGULAR_RATE": "Normal oranı düzenle",
        "DETAIL_REGULAR_RATE": "Detay Düzenli Oran",
        "DELETE_REGULAR_RATE": "Normal oranı sil",
        "Delete_confirm_regular_rate": "Bu öğeyi silmek istiyor musunuz?",
        "STARTING": "Başlangıç",
        "Starting_Now": "Başlangıç ​​(şimdi)",
        "Starting_Reservation": "Başlangıç ​​(Rezervasyon)",
        "FIRST_DISTANCE_FEE": "İlk mesafe ücreti",
        "First_distance_fee": "İlk mesafe",
        "From": "İtibaren",
        "To": "İle",
        "Fee_for_first_distance": "İlk mesafe için ücret",
        "SECOND_DISTANCE_FEE": "İkinci mesafe ücreti",
        "Second_distance_fee": "İkinci mesafe",
        "AFTER_SECOND_DISTANCE": "İkinci mesafeden sonra",
        "Fee_after_second_distance": "İkinci mesafeden sonra ücret",
        "PER_MINUTE": "Dakikada",
        "Fee_per_minute": "Dakikada ücret",
        "mi": "mi",
        "hour": "saat",
        "MINIMUM": "Minimum",
        "Minimum_Now": "Minimum (şimdi)",
        "Minimum_Reservation": "Minimum (Rezervasyon)",
        "PAX_MDISPATCHER_CANCELLATION": "PAX/ Ortak İptali",
        "On_demand": "PAX/Partner İptali - Talep Üzerine",
        "Penalty": "Ceza tutarı",
        "In_advance": "PAX/Partner İptali - Önceden",
        "PAX_MDISPATCHER_NO_SHOW": "PAX/ Partner Gösteri Yok",
        "Driver_cancel_demand": "Sürücü İptal Politikası - Talep Üzerine",
        "Driver_cancel_advance": "Sürücü İptal Politikası - Önceden",
        "Assign_car_type": "Araba Türü atayın",
        "Select_car_type": "Araba Türünü Seçin",
        "Active": "Aktif",
        "Inactive": "Aktif olmayan",
        "Deactivate": "Devre dışı bırakmak",
        "DELETE_FLATE_RATE": "Sabit oranı sil",
        "Delete_confirm_flat_rate": "Bu öğeyi silmek istiyor musunuz?",
        "EDIT_FLAT_RATE": "Sabit Hızı Düzenle",
        "ADD_FLAT_RATE": "Sabit oran ekle",
        "DELETE_FLAT_RATE": "Sabit oranı sil",
        "DELETE_HOURLY_RATE": "Saatlik/ Günlük Oran Sil",
        "Delete_confirm_hour_rate": "Bu öğeyi silmek istiyor musunuz?",
        "EDIT_HOURLY_RATE": "Saatlik/ Günlük Oran'ı Düzenle",
        "ADD_HOURLY_RATE": "Saatlik/ günlük oran ekleyin",
        "DELETE_FLAT_ROUTE": "Düz rotayı sil",
        "DELETE_ROUTE_CONFIRM": "Rotayı sileceğinizden emin misiniz?",
        "EDIT_ROUTE": "Rota Düzenle",
        "ADD_ROUTE": "Rota ekle",
        "Route_name": "Rota adı",
        "Route_type": "Rota türü",
        "Zone_to_zone": "Bölge",
        "Zipcode_to_zipcode": "Zipcode'a zipcode",
        "First_location": "İlk Konum",
        "Second_location": "İkinci Konum",
        "First_location_zip_note": "*Notlar: ',' sembolü kullanarak birçok posta kodu aynı anda girebilirsiniz.Ardışık kodlar için lütfen tek tek girmek yerine ' ->' sembolü kullanın (Örn: 90001 -> 90099, 90101, 90102, 90103, 90174, 90185)",
        "Second_location_zip_note": "*Notlar: ',' sembolü kullanarak birçok posta kodu aynı anda girebilirsiniz.Ardışık kodlar için lütfen tek tek girmek yerine ' ->' sembolü kullanın (Örn: 90001 -> 90099, 90101, 90102, 90103, 90174, 90185)",
        "SINGLE_TRIP_SETUP": "Tek Gezi Kurulumu",
        "Route_1": "Rota 1 (İlk Konum -> İkinci Konum)",
        "Route_2": "Rota 2 (İkinci Konum -> İlk Konum)",
        "Limitation": "Sınırlama",
        "Unlimited": "Sınırsız",
        "Limited": "Sınırlı",
        "Time_coverage": "Zaman kapsamı",
        "Extra_time": "Ekstra zaman",
        "ROUND_TRIP_SETUP": "Gidiş -dönüş kurulumu",
        "Round_trip": "Gidiş",
        "ROUTES": "Rotalar",
        "Route_1_Fare": "Rota 1 Ücreti",
        "Route_2_Fare": "Rota 2 Ücreti",
        "Round_Trip_Fare": "Gidiş -dönüş ücreti",
        "Select_a_zone": "Bir Bölge Seçin",
        "minute": "dakika",
        "VIEW_REGULAR_RATE": "Ayrıntıyı görüntüle Normal oranı",
        "VIEW_FLAT_RATE": "Sabit oranı görüntüle",
        "VIEW_HOURLY_RATE": "Saatlik Fiyatı Görüntüle",
        "HOURLY_PACKAGE_RATE": "Saatlik paket oranı",
        "Package_name": "Paket adı",
        "Package_duration": "Paket süresi",
        "PackageId": "Paket kimliği",
        "Base_rate": "Taban oranı",
        "Distance_coverage": "Mesafe kapsamı",
        "Extra_duration": "Ekstra süre",
        "Extra_distance": "Ekstra mesafe",
        "DELETE_HOURLY_PACKAGE": "Saatlik paketi sil",
        "DELETE_PACKAGE_CONFIRM": "Paket oranını silmek istiyor musunuz?",
        "EDIT_PACKAGE": "Paketi Düzenle",
        "VIEW_PACKAGE": "Paket Detayını Görüntüle",
        "ADD_PACKAGE": "Paket ekle",
        "Fee_per_extra_duration": "Ekstra süre başına ücret",
        "Fee_per_extra_distance": "Ekstra mesafe başına ücret",
        "hours": "saat)",
        "days": "Gün (ler)",
        "Update_flat_rate_success": "Sabit oran başarıyla güncellendi",
        "Update_flat_rate_fail": "Hatalar sabit oranını güncellerken meydana geldi",
        "Created_flat_rate_success": "Sabit oran başarıyla yaratıldı",
        "Created_flat_rate_fail": "Hatalar sabit oran oluştururken meydana geldi",
        "Update_flat_rate_route_success": "Düz fiyatlı rota başarıyla güncellendi",
        "Update_flat_rate_route_fail": "Hatalar düz oranlı rotayı güncellerken meydana geldi",
        "Created_flat_rate_route_success": "Düz oranlı rota başarıyla oluşturuldu",
        "Created_flat_rate_route_fail": "Hatalar düz oranlı rota oluştururken meydana geldi",
        "Loading_flat_rate_fail": "Hatalar düz oran detayı yüklenirken meydana geldi",
        "Delete_flat_rate_route_fail": "Hatalar düz oranlı rota silinirken meydana geldi",
        "Delete_flat_rate_success": "Sabit oran başarıyla silindi",
        "Delete_flat_rate_fail": "Hatalar sabit hız silinirken meydana geldi",
        "Update_intercity_rate_success": "Şehirlerarası oranı başarıyla güncellendi",
        "Update_intercity_rate_fail": "Şehirlerarası oranı güncellenirken hatalar meydana geldi",
        "Created_intercity_rate_success": "Şehirlerarası oranı başarıyla yaratıldı",
        "Created_intercity_rate_fail": "Şehirlerarası oranı oluşturulurken meydana geldi",
        "Update_intercity_rate_route_success": "Şehirlerarası oranı rotası başarıyla güncellendi",
        "Update_intercity_rate_route_fail": "Hatalar şehirlerarası oranı rotası güncellenirken meydana geldi",
        "Created_intercity_rate_route_success": "Şehirlerarası oranı rotası başarıyla yaratıldı",
        "Created_intercity_rate_route_fail": "Hatalar şehirlerarası oranı oluştururken meydana geldi",
        "Loading_intercity_rate_fail": "Hatalar şehirlerarası oranı detayı yüklenirken meydana geldi",
        "Delete_intercity_rate_route_fail": "Hatalar şehirlerarası oranı rotası silinirken meydana geldi",
        "Delete_intercity_rate_success": "Şehirlerarası oranı başarıyla silindi",
        "Delete_intercity_rate_fail": "Etkileşim oranı silinirken hatalar meydana geldi",
        "Update_regular_rate_success": "Normal oran başarıyla güncellendi",
        "Update_regular_rate_fail": "Hatalar normal oranı güncellenirken meydana geldi",
        "Create_regular_rate_success": "Normal oran başarıyla yaratıldı",
        "Create_regular_rate_fail": "Hatalar düzenli oran oluştururken meydana geldi",
        "Update_hourly_rate_success": "Saatlik oran başarıyla güncellendi",
        "Update_hourly_rate_fail": "Hatalar saatlik fiyatı güncellerken meydana geldi",
        "Create_hourly_rate_success": "Saatlik oran başarıyla yaratıldı",
        "Create_hourly_rate_fail": "Hatalar saatlik oran oluştururken meydana geldi",
        "Update_hourly_rate_package_success": "Saatlik ücret paketi başarıyla güncellendi",
        "Update_hourly_rate_package_fail": "Hatalar saatlik ücret paketi güncellenirken meydana geldi",
        "Create_hourly_rate_package_success": "Saatlik ücret paketi başarıyla oluşturuldu",
        "Create_hourly_rate_package_fail": "Hatalar saatlik ücret paketi oluştururken meydana geldi",
        "Load_hourly_rate_fail": "Hatalar saatlik hız detaylarını yüklerken meydana geldi",
        "Update_hourly_status": "Hatalar saatlik oran durumunu güncellerken meydana geldi",
        "Delete_hourly_fail": "Hatalar saatlik ücret silinirken meydana geldi",
        "Delete_hourly_success": "Saatlik oran başarıyla silindi",
        "Loading_regular_rate_fail": "Hatalar normal oran yüklenirken meydana geldi",
        "Update_regular_status_fail": "Hatalar düzenli oran durumunu güncellerken meydana geldi",
        "Delete_regular_rate_success": "Normal oran başarıyla silindi",
        "Delete_regular_rate_fail": "Hatalar düzenli oran silinirken meydana geldi",
        "Please_go_to_Car_Type_settings": "Bu oranı herhangi bir araba türüne uygulamak için lütfen ayarlar> araba> araba türüne gidin.",
        "Car_type_filter": "Araba Tipi Filtresi",
        "Delete_package_error": "Paketi silerken bir hata vardı!",
        "Pax_mDispatcher_cancellation_tooltip": "Yolcular için uygulanan iptal politikası",
        "Penalty_tooltip": "Müşteri tutarı tahsil edilecektir.Cezaya ek olarak vergi veya teknoloji ücreti ekleyebilirsiniz.",
        "Pax_mDispatcher_no_show_tooltip": "Yolcular için herhangi bir gösteri politikası uygulanmadı.Sürücüler, pikap konumunda yolcuları görmediklerinde \"Gösteri Yok\" a dokunursa bu ücret tahsil edilecektir.",
        "detail_route": "Detay yolu",
        "detail_package": "Detay paketi",
        "THIRD_DISTANCE_FEE": "Üçüncü mesafe ücreti",
        "AFTER_DISTANCE_FEE": "Üçüncü mesafe ücretinden sonra",
        "Loading_delivery_rate_fail": "Hatalar, teslimat hızı detayı yüklenirken meydana geldi",
        "Update_delivery_rate_fail": "Teslimat oranını güncellerken hatalar meydana geldi",
        "Update_delivery_rate_success": "Teslimat oranı başarıyla güncellendi",
        "Created_delivery_rate_success": "Teslimat oranı başarıyla oluşturuldu",
        "Created_delivery_rate_fail": "Hatalar, teslimat oranı oluştururken meydana geldi",
        "Starting_fee": "Başlangıç ​​ücreti",
        "First_distance": "İlk mesafe",
        "Second_distance": "İkinci mesafe",
        "Third_distance": "Üçüncü mesafe",
        "Fee_for_second_distance": "İkinci mesafe için ücret",
        "Fee_for_third_distance": "Üçüncü mesafe için ücret",
        "Fee_after_third_distance": "Üçüncü mesafeden sonra ücret",
        "Assign_rate_to_car_type": "Cartype'a oranı atayın",
        "car_type": "Kıkırdak",
        "PRICE_ADJUSTABLE": "Fiyat ayarlanabilir",
        "Amount_Step": "Fiyat ayarlanabilir",
        "Tax": "Vergi",
        "TechFee": "Teknoloji ücreti",
        "Pay_to_driver": "Sürücüye Ödeme",
        "Pay_to_driver_tooltip": "Sürücüleri telafi etme seçenekleri:\n- Sabit miktar: Sürücünün alacağı sabit bir miktar.\n- Komisyon: Driver’ın Set Komisyonu ceza tutarına uygulandı.",
        "Fix_amount": "Sabit miktar",
        "Commission": "Komisyon",
        "fareFlat": "Ücret Dairesi",
        "flatRoute": "Düz yol",
        "Mode": "Moda",
        "speedThresholdKM": "Hız eşiği (km/s)",
        "speedThresholdMI": "Hız eşiği (mi/s)",
        "timeAndMileage": "Zaman ve kilometre",
        "timeOrMileage": "Zaman veya kilometre",
        "perDistanceKM": "Kilometre başına",
        "perDistanceMI": "Mil başına",
        "perTime": "Dakikada",
        "regularRates": "Regular Rates",
        "newRate": "New Rate",
        "enterName": "Enter Rate Name",
        "actualMode": "Actual fare",
        "estimatedMode": "Quoted fare",
        "feePerStop": "Fee per Additional Stops",
        "extraWaitTime": "Ekstra bekleme süresi",
        "startingFee": "Starting",
        "minimumFee": "Minimum",
        "minAppend": "/min",
        "kmAppend": "/km",
        "miAppend": "/mi",
        "kmhrAppend": "km/h",
        "mihrAppend": "mi/h",
        "feePerKm": "Per kilometer",
        "feePerMi": "Per mile",
        "feeSpeedThreshold": "Speed threshold",
        "serviceType": "Hizmet türü",
        "fareMode": "Formula",
        "calculationMode": "Calculation Mode",
        "timeChangeMode": "Time Charge Mode",
        "minimum_Percent_Adjust": "Tahmini ücretten minimum %",
        "maximum_Percent_Adjust": "Tahmini ücretten maksimum %",
        "AllowAdjustPrice": "Allow Customer to Adjust Price",
        "Calculation": "Calculation",
        "distanceTier": "Distance Tier",
        "perKm": "Fee per km",
        "perMi": "Fee per mi",
        "flatFee": "Flat Fee",
        "defaultRate": "Default Rate",
        "timeRange": "Time Range",
        "bookingType": "Booking Type",
        "rateDetail": "Rate Detail",
        "subRate": "Sub Rate",
        "specialRates": "Special Rates",
        "surcharge": "Surcharge",
        "penalty": "Penalty",
        "basedOriginMode": "Based on original pick up time",
        "basedDriverMode": "Based on driver pick up time",
        "hourlyRate": "Hourly Rate",
        "flat_Fare": "Flat Rate",
        "flatFeeOption": "Flat fee",
        "fareModeTooltip": "Time or mileage only applies to customer rates, this will not apply for supplier/driver payout rate.",
        "cloneRate": "Clone Rate",
        "rateName": "Rate Name",
        "Infinity": "Infinity",
        "Fee": "Fee",
        "headerTile": "Enter header title",
        "enterFooter": "Enter footer title",
        "invoiceNo": "Invoice No.",
        "paidOn": "Paid On",
        "amountPaid": "Amount Paid",
        "isFeePerSeatActive": "Charge per passenger",
        "minSeat": "Minimum number of passengers",
        "minSeatTooltip": "This is the minimum number of seats to charge for a booking."
    },
    "flatRateSettings": {
        "flatZoneNote": "Burada oluşturduğunuz bölgeler yalnızca sabit oranlarınızı ayarlamak için kullanılır."
    },
    "deliverySetting": {
        "Name": "İsim",
        "Last_Update": "Son Güncelleme",
        "Status": "Durum",
        "Actions": "Eylem"
    },
    "sharingSetting": {
        "EDIT_RATE": "Sokak paylaşım oranını düzenle",
        "ADD_RATE": "Sokak Paylaşım Oranı Ekle",
        "VIEW_RATE": "Sokak paylaşım oranını görüntüleyin",
        "Name": "İsim",
        "startingFee": "Başlangıç ​​Ücreti",
        "minimumFee": "Asgari ücret",
        "Update_streetSharing_rate_fail": "Sokak paylaşım oranını güncellerken hatalar meydana geldi",
        "Update_streetSharing_rate_success": "Sokak paylaşım oranı başarıyla güncellendi",
        "Created_streetSharing_rate_success": "Sokak paylaşım oranı başarıyla yaratıldı",
        "Created_streetSharing_rate_fail": "Sokak paylaşım oranı oluşturulurken hatalar meydana geldi",
        "Loading_streetSharing_rate_fail": "Sokak paylaşım oranı detayı yüklenirken hatalar meydana geldi",
        "Delete_streetSharing_rate_success": "Sokak paylaşım oranı başarıyla silindi",
        "Delete_streetSharing_rate_fail": "Sokak paylaşım oranı silinirken hatalar meydana geldi",
        "Delete_streetSharing_rate": "Sokak paylaşım oranını sil",
        "Assign_Car": "Araba Türü atayın"
    },
    "carTypeSetting": {
        "newService": "Yeni Hizmet",
        "editService": "Hizmeti Düzenle",
        "serviceInfo": "Hizmet Bilgileri",
        "name": "İsim",
        "namePH": "Araba tipi isim",
        "enterNumber": "Bir numara girin",
        "appDisplayName": "Uygulama Ekran Adı",
        "shortDescription": "Kısa Açıklama",
        "maxPassengers": "Max Yolcular",
        "maxLuggage": "Max Bagajlar",
        "vehiclePhoto": "Araç fotoğrafları",
        "iconShowList": "Listede gösterilecek simge",
        "imageShowWeb": "Web'de gösterilecek resim",
        "carPinOnMap": "Haritada araba pimi",
        "recommendedSize": "Önerilen boyut:",
        "serviceSetting": "Hizmet Ayarları",
        "serviceType": "Hizmet türü",
        "reservation": "Rezervasyon",
        "onDemand": "Talep üzerine",
        "destinationSetting": "Hedef ayarı",
        "requireDestination": "Varış yeri gerektirir",
        "extraDestination": "Ekstra hedef",
        "calculatorFareMode": "Ücret modunu hesapla",
        "hideDestinationOnDemand": "Talep üzerine",
        "meteredFare": "Ölçülü yemek",
        "quotedFare": "Alıntı yapmak",
        "availableZones": "Mevcut bölgeler",
        "dispatchAssociation": "Dağıtım derneği",
        "Save": "Kaydetmek",
        "Edit": "Düzenlemek",
        "Cancel": "İptal etmek",
        "validateName": "Lütfen Ad Giriş",
        "validateAppDisplayName": "Lütfen uygulama ekran adı girin",
        "validateSelectImage": "Lütfen bir resim seçin",
        "updateSuccess": "Başarıyla Güncelle",
        "createSuccess": "Başarıyla Oluştur",
        "failApi": "Yanlış olduğunda bir şey!",
        "validateRequire": "Lütfen yolcu sayısını belirtin ve bagajı çift kontrol edin."
    },
    "carSetting": {
        "Details": "Detaylar",
        "vehicleInfo": "Araç Bilgisi",
        "Documents": "Belgeler",
        "vehicle": "Araç",
        "addVehicle": "Araç ekle",
        "detailVehicle": "Araç Detayları",
        "additionalVehicleTitle": "Ek Araç Bilgileri",
        "Phone_Number_Tooltip": "Müşteri kullanımı için araca özgü bir telefon numarası girin.Sağlanmazsa, aramalar varsayılan olarak sürücünün kişisel numarasına kadar olacaktır.Araçları özel iletişim telefonlarıyla donatan tedarikçiler için idealdir.",
        "discardChange": "Değişiklikleri at",
        "model_name_is_required": "Model Adı gereklidir",
        "DELETE_CAR": "Arabayı Sil",
        "DELETE_CONFIRM": "Seçilen arabayı silmek ister misiniz?",
        "Yes": "Evet",
        "Cancel": "İptal etmek",
        "EDIT_CAR": "Arabayı düzenle",
        "CREATE_CAR": "Araba Oluştur",
        "Plate_Number": "Plaka numarası",
        "Car_Type": "Araba tipi",
        "Select_car_type": "Araba Türünü Seçin",
        "Make": "Yapmak",
        "Select_car_make": "Araba Markası'nı seçin",
        "Model": "Model",
        "Select_a_car_model": "Bir Araba Modeli Seçin",
        "Year": "Üretim yılı",
        "Select_year": "Seçim Yıl",
        "Hardware_Meter": "Donanım Ölçer",
        "VIN": "Araç Kimlik Numarası (VIN)",
        "Company": "Şirket",
        "Select_company": "Şirket seçin",
        "Max_Passengers": "Max Yolcular",
        "Max_Luggage": "Max Bagaj",
        "Color": "Renk",
        "License_expiry": "Lisans sona erme",
        "Required_fields": "Gerekli Alanlar",
        "Save": "Kaydetmek",
        "Add": "Eklemek",
        "Car_Model": "Araba modeli",
        "Created_Date": "Oluşturulan Tarih",
        "Status": "Durum",
        "Actions": "Eylem",
        "Show_per_page": "Sayfa başına öğeler",
        "Page": "Sayfa",
        "show_pages": "{0} - {1} {2} gösterilen",
        "Active": "Aktif",
        "activeVehicle": "Active vehicle",
        "Inactive": "Aktif olmayan",
        "Edit": "Düzenlemek",
        "Deactivate": "Devre dışı bırakmak",
        "Activate": "Etkinleştirmek",
        "Delete": "Silmek",
        "Name": "İsim",
        "MODEL_LIST": "Model listesi",
        "Model_Name": "Model adı",
        "Add_Model": "Model ekle",
        "DELETE_CAR_MODEL": "Araba Modelini Sil",
        "DELETE_CONFIRM_MODEL": "Araba modelini silmek ister misiniz?",
        "DELETE_CAR_MAKE": "Araba Markası Sil",
        "DELETE_CONFIRM_CAR_MAKE": "Bu arabayı silmek istiyor musunuz?",
        "EDIT_CAR_MAKE": "Araba Markası düzenle",
        "CREATE_CAR_MAKE": "Araba Markası Ekle",
        "Assign_Rate": "Atama oranı",
        "ASSIGN_RATE": "Atama oranı",
        "Car_type_ID": "Araba Tipi Kimliği",
        "App_Display_Name": "Uygulama Ekran Adı",
        "Affiliate_car_type": "Satış Ortaklık Araç Türü",
        "Regular_rate": "Düzenli Oran",
        "Zone": "Alan",
        "Rate": "Oran",
        "Zone_Rate": "Bölge - Oran",
        "Flat_Fare": "Sabit oran",
        "Flat_Delivery": "Teslimat",
        "Select_flat_rate": "Sabit oran seçin",
        "Hourly_daily_rate": "Saatlik/ günlük",
        "Dispatch_To": "Göndermek",
        "Normal_Fare": "Düzenli",
        "Upload_an_image": "Bir Resim Yükle",
        "DETAIL_CAR": "Araba Detayı",
        "DELETE_MULTI_CAR": "Seçilen arabaları sil",
        "DELETE_MULTI_CAR_CONFIRM": "Bu araçları silmek istiyor musunuz, sadece sadece etkin olmayan durumdaki araçlar silinebilir mi?",
        "DETAIL_CAR_MAKE": "Araba yapımı",
        "Car_Make": "Araba yapımı",
        "Create_car_success": "Yeni araba başarıyla yaratıldı",
        "Update_car_success": "Bu araba başarıyla güncellendi.",
        "Update_car_fail": "Araba güncellenirken hatalar meydana geldi",
        "Create_car_fail": "Araba oluştururken hatalar meydana geldi",
        "Load_car_fail": "Araba yüklenirken hatalar meydana geldi",
        "Delete_car_fail": "Araba silinirken hatalar meydana geldi",
        "Delete_car_success": "Araba başarıyla silindi",
        "Change_cars_status_success": "Tüm araba durumları başarıyla değiştirildi",
        "Change_cars_status_fail": "Araba durumlarını değiştirirken hatalar meydana geldi",
        "Delete_cars_success": "Seçilen tüm arabalar başarıyla silindi",
        "Delete_cars_fail": "Arabalar silinirken hatalar meydana geldi",
        "Update_car_type_success": "Araba türü başarıyla güncellendi",
        "Update_car_type_fail": "Araba türü güncellenirken hatalar meydana geldi",
        "Load_car_type_details_fail": "Araba tipi detayları yükleyemedi",
        "Update_car_make_fail": "Araba yapımını güncellerken hatalar meydana geldi",
        "Update_car_make_success": "Araba Markası başarıyla güncellendi",
        "Create_car_make_fail": "Araba yapımı oluştururken hatalar meydana geldi",
        "Create_car_make_success": "Yeni araba yapmak başarıyla yaratıldı",
        "Update_car_model_fail": "Araba modeli güncellenirken hatalar meydana geldi",
        "Update_car_model_success": "Araba modeli başarıyla güncellendi",
        "Create_car_model_fail": "Araba modeli oluştururken hatalar meydana geldi",
        "Create_car_model_success": "Yeni araba modeli başarıyla yaratıldı",
        "Loading_car_model_fail": "Araba modeli yüklenirken hatalar meydana geldi",
        "Delete_car_make_success": "Araba Markası başarıyla silindi",
        "Delete_cat_make_fail": "Araba yapımı silinirken hatalar meydana geldi",
        "Delete_regular_rate_success": "Düzenli Oran Başarılı Sil",
        "Delete_regular_rate_fail": "Normal oran hatasını sil",
        "Update_status_error": "Durum hatasını güncelle",
        "Case_number": "Vaka numarası",
        "Phone_Number": "Telefon numarası",
        "ERROR_INPUT_VALIDPHONE": "Lütfen geçerli bir telefon numarası girin.",
        "Car_type_filter": "Araba Tipi Filtresi",
        "No": "HAYIR",
        "Intercity_Rate": "Şehirlerarası",
        "Select_intercity_rate": "Şehirlerarası oranı seçin",
        "Delivery_Rate": "Teslimat oranı",
        "Select_delivery_rate": "Teslimat oranı seçin",
        "vehicleOwner": "Araç Sahibinin Adı",
        "EDIT_CAR_COLOR": "Araba rengini düzenle",
        "DETAIL_CAR_COLOR": "Araba Renk Detayı",
        "CREATE_CAR_COLOR": "Araba rengi ekle",
        "Car_Color": "Araba rengi",
        "Update_car_color_fail": "Araba rengini güncellerken hatalar meydana geldi.",
        "Update_car_color_success": "Araba rengi başarıyla güncellendi.",
        "Create_car_color_fail": "Araba rengi oluştururken hatalar meydana geldi.",
        "Create_car_color_success": "Yeni araba rengi başarıyla yaratıldı",
        "Delete_car_color_success": "Araba rengi başarıyla silindi.",
        "Delete_car_color_fail": "Araba rengi silinirken hatalar meydana geldi.",
        "DELETE_CAR_COLOR": "Araba rengini sil",
        "DELETE_CONFIRM_CAR_COLOR": "Bu araba rengini silmek ister misiniz?",
        "sharing_Rate": "Sokak Paylaşım Oranı",
        "bookingAffiliate": "Affiliate Booking.com Araba Türü",
        "streetSharing": "Sokak paylaşımı"
    },
    "operationSetting": {
        "Driver": "Sürücü",
        "Search_driver_name": "Arama Sürücü Adı",
        "Company": "Şirket",
        "License_plate": "Plaka",
        "Select_license_plate": "Plaka Seçin",
        "Shift": "Vardiya",
        "Zone": "Alan",
        "Required_fields": "Gerekli Alanlar",
        "Save": "Kaydetmek",
        "No": "HAYIR.",
        "Car_Type": "Araba tipi",
        "Plate_Number": "Plaka numarası",
        "Unassign": "Atanmak",
        "Please_choose_driver": "Lütfen sürücüyü seçin.",
        "Please_choose_company": "Lütfen Şirket'i seçin.",
        "Please_license_plate": "Lütfen plaka plakasını seçin.",
        "Please_choose_shift": "Lütfen Shift'i seçin.",
        "Please_choose_zone": "Lütfen Bölge Seçin.",
        "CONFIRM_UNASSIGN": "Bu sürücüyü atamak istiyor musunuz?",
        "Assign_success": "Operasyon başarıyla atandı",
        "Unassign_success": "Sürücü başarıyla atandı."
    },
    "paymentMethod": {
        "cash": "Peşin",
        "easyPaisa": "Easy Paisa",
        "jazzCash": "Jazz Cash",
        "cashBySender": "Gönderen tarafından nakit",
        "cashByRecipient": "Alıcı tarafından nakit",
        "personalCard": "Kişisel kart",
        "corporateCard": "Kurumsal kart",
        "qrCodePayment": "QR Kod Ödemesi",
        "directBilling": "Doğrudan faturalandırma",
        "externalCard": "Harici kart",
        "mDispatcherCard": "Ortak kartı",
        "corporatePrepaid": "Kurumsal ön ödemeli",
        "fleetCard": "Harici kart",
        "applePay": "Elma ödemesi",
        "creditExternalCard": "Kredi kartı",
        "googlePay": "Google Pay",
        "wallet": "Cüzdan",
        "BankOfGeorgia": "Gürcistan Bankası",
        "tnGeWalletlet": "Tng ewallet",
        "paymentMethod_1": "Peşin",
        "paymentMethod_2": "Kişisel kart",
        "paymentMethod_3": "Ortak kartı",
        "paymentMethod_4": "Kurumsal kart",
        "paymentMethod_5": "Doğrudan faturalandırma",
        "paymentMethod_6": "Harici kart",
        "paymentMethod_7": "Kurumsal ön ödemeli",
        "paymentMethod_8": "QR Kod Ödemesi",
        "paymentMethod_9": "Elma ödemesi",
        "paymentMethod_11": "Havai",
        "paymentMethod_12": "Kredi kartı",
        "paymentMethod_13": "Cüzdan",
        "paymentMethod_14": "Tng ewallet",
        "paymentMethod_16": "Gönderen tarafından nakit",
        "paymentMethod_17": "Alıcı tarafından nakit",
        "paymentMethod_19": "Ewallet'i artırmak",
        "paymentMethod_20": "Vippler",
        "paymentMethod_21": "Zaincash",
        "paymentMethod_23": "Google Pay",
        "paymentMethod_25": "Easy Paisa",
        "paymentMethod_26": "Jazz Cash",
        "vippseWallet": "Vippler",
        "paymentMethod_credit": "Kişisel kart",
        "paymentMethod_razerPay": "Ivır zıvır",
        "paymentMethod_gCash": "Gcash",
        "paymentMethod_zainCash": "Zaincash",
        "paymentMethod_xenDit": "Xendit",
        "paymentMethod_FIB": "FIB",
        "paymentMethod_SantimPay": "SantimPay",
        "paymentMethod_ksher": "KSHER",
        "paymentMethod_paydunya": "Paydunya",
        "paymentMethod_fawry": "Küstahlık",
        "paymentMethod_BankOfGeorgia": "Gürcistan Bankası",
        "paymentLink": "Ödeme bağlantısı",
        "paymentMethod_xendit": "Xendit",
        "paymentMethod_easyPaisa": "Easy Paisa",
        "paymentMethod_jazzCash": "Jazz Cash",
        "paymentMethod_flooz": "Flooz",
        "paymentMethod_tmoney": "Tmoney",
        "paymentMethod_corporateCard": "Corporate card"
    },
    "changePassword": {
        "Change_password": "Şifre değiştir",
        "Current_password": "Mevcut Şifre",
        "New_password": "Yeni Şifre",
        "Confirm_password": "Şifreyi onaylayın",
        "ERROR_INPUT_PASSWORD": "Lütfen şifre girin",
        "ERROR_INPUT_VALID_PASSWORD": "Parola en az 8 karakter içermelidir, bir sayısal basamak ve özel bir karakter",
        "ERROR_INPUT_MATCH_PASSWORD": "Parola eşleşmiyor.Lütfen tekrar deneyin",
        "Save": "Kaydetmek",
        "Cancel": "İptal etmek"
    },
    "userProfile": {
        "My_account": "Hesabım",
        "Username": "Kullanıcı adı",
        "First_name": "İlk adı",
        "Last_name": "Soy isim",
        "Phone_number": "Telefon numarası",
        "Email_address": "E -posta adresi",
        "Address": "Adres",
        "Save": "Kaydetmek",
        "Edit_profile": "Profili Düzenle",
        "Change_password": "Şifre değiştir",
        "ERROR_INPUT_USERNAME": "Lütfen kullanıcı adı girin.",
        "ERROR_INPUT_FIRSTNAME": "Lütfen ad girin.",
        "ERROR_INPUT_VALID_EMAIL": "Lütfen geçerli e -postayı girin.",
        "Required_fields": "Gerekli Alanlar"
    },
    "General": {
        "searchQueuingDriver": "Arama: Sürücü Adı, Telefon Numarası",
        "search": "Aramak",
        "User": "Kullanıcı",
        "My_Account": "Hesabım",
        "Sign_out": "oturumu Kapat",
        "My_site": "Sitem",
        "Command_center": "Komuta merkezi",
        "Notifications": "Bildirimler",
        "Show_all": "Hepsini göster",
        "SOS_Notifications": "SOS Bildirimleri",
        "You_dont_have_any_message": "Herhangi bir mesajın yok",
        "Mon": "Mon",
        "Tue": "Salı",
        "Wed": "Evlenmek",
        "Thu": "Thu",
        "Fri": "Cum",
        "Sat": "Doygunluk",
        "Sun": "Güneş",
        "Now": "Şimdi",
        "NowComplete": "Now (Acttual Pickup Time)",
        "hrs": "saat",
        "patmentMethod_1": "Peşin",
        "patmentMethod_2": "Kişisel kart",
        "patmentMethod_3": "Ortak kartı",
        "patmentMethod_4": "Kurumsal kart",
        "patmentMethod_5": "Doğrudan faturalandırma",
        "patmentMethod_6": "Harici kart",
        "patmentMethod_7": "Kurumsal ön ödemeli",
        "patmentMethod_8": "QR kodu",
        "patmentMethod_9": "Elma ödemesi",
        "patmentMethod_10": "Elma ödemesi",
        "patmentMethod_12": "Kredi kartı",
        "patmentMethod_13": "Cüzdan",
        "patmentMethod_14": "Tng ewallet",
        "patmentMethod_16": "Gönderen tarafından nakit",
        "patmentMethod_17": "Alıcı tarafından nakit",
        "patmentMethod_19": "Artırmak",
        "patmentMethod_20": "Vippler",
        "patmentMethod_23": "Google Play",
        "patmentMethod_24": "Ödeme bağlantısı",
        "patmentMethod_25": "Easy Paisa",
        "patmentMethod_26": "Jazz Cash",
        "patmentMethod_ZainCash": "Zaincash",
        "patmentMethod_Xendit": "Xendit",
        "patmentMethod_FIB": "FIB",
        "patmentMethod_SantimPay": "SantimPay",
        "patmentMethod_GCash": "Gcash",
        "patmentMethod_cash": "Peşin",
        "patmentMethod_credit": "Kişisel kart",
        "patmentMethod_mDispatcher": "Ortak kartı",
        "patmentMethod_corpCredit": "Kurumsal kart",
        "patmentMethod_directBilling": "Doğrudan faturalandırma",
        "patmentMethod_fleetCard": "Harici kart",
        "patmentMethod_prepaid": "Kurumsal ön ödemeli",
        "patmentMethod_QRCode": "QR kodu",
        "patmentMethod_applePay": "Apple Pay",
        "patmentMethod_easyPaisa": "Easy Paisa",
        "patmentMethod_jazzCash": "Jazz Cash",
        "patmentMethod_prePaid": "Corporate Prepaid",
        "patmentMethod_GooglePay": "Google Pay",
        "patmentMethod_creditCardExternal": "Kredi kartı",
        "patmentMethod_PaxWallet": "Cüzdan",
        "patmentMethod_TnGeWallet": "Tng ewallet",
        "patmentMethod_boosteWallet": "Artırmak",
        "patmentMethod_Vipps": "Vippler",
        "patmentMethod_exZainCash": "Zaincash",
        "patmentMethod_exGCash": "Gcash",
        "patmentMethod_exPayTM": "Paytm",
        "patmentMethod_exFlooz": "Flooz",
        "patmentMethod_exTmoney": "Tmoney",
        "patmentMethod_exTelebirr": "Gcash",
        "paymentMethod_exzainCash": "Zaincash",
        "paymentMethod_exXendit": "Xendit",
        "paymentMethod_exFIB": "FIB",
        "paymentMethod_exSantimPay": "SantimPay",
        "patmentMethod_exKsher": "KSHER",
        "paymentMethod_paydunya": "Paydunya",
        "paymentMethod_exfawry": "Küstahlık",
        "patmentMethod_exBankOfGeorgia": "Gürcistan Bankası",
        "patmentMethod_exPayDunya": "Paydunya",
        "patmentMethod_21": "Zaincash",
        "patmentMethod_paymentLink": "Ödeme bağlantısı",
        "patmentMethod_card": "Kişisel kart",
        "patmentMethod_corporateCard": "Kurumsal kart",
        "patmentMethod_paxWallet": "Wallet",
        "patmentMethod_externalWallet": "External Wallet",
        "patmentMethod_googlePay": "Google Pay",
        "patmentMethod_manual": "Manual",
        "patmentMethod_online": "Card/Online Payment",
        "patmentMethod_check": "Check",
        "patmentMethod_wire": "Wire",
        "patmentMethod_digitalWallet": "Digital Wallet",
        "patmentAirlineMethod_5": "Doğrudan faturalandırma",
        "patmentAirlineMethod_4": "Havayolu kartı",
        "patmentAirlineMethod_7": "Havayolu ön ödemeli",
        "Name": "İsim",
        "Delete": "Silmek",
        "App_SOS_dialog_form_message_1": "{0} Acil SOS!",
        "App_SOS_dialog_form_message_2": "Yardıma ihtiyacım var",
        "App_SOS_dialog_form_message_3": "Rezervasyon: {0}, sürücü: {1}, yolcu: {2}, plaka: {3} ({4})",
        "mins": "doyurma",
        "Credit_Card": "Kredi kartı",
        "ALL": "Tüm",
        "NotFound": "İstediğiniz sayfa bulunamadı",
        "ServerError": "Bir şeyler ters gitti",
        "AccessDenied": "Bu modüle erişme izniniz yok!",
        "Retry": "Yeniden yapmak",
        "Can_not_get_data": "Veri alamıyorum"
    },
    "shiftSettings": {
        "Add": "Eklemek",
        "Show_per_page": "Sayfa başına öğeler",
        "Page": "Sayfa",
        "Name": "İsim",
        "Time_Range": "Zaman aralığı",
        "Actions": "Eylem",
        "show_pages": "{0} - {1} {2} gösterilen",
        "Edit": "Düzenlemek",
        "Delete": "Silmek",
        "EDIT_SHIFT_SETTING": "Vites değiştirme ayarını düzenle",
        "ADD_SHIFT_SETTING": "Vardiya ayarı ekle",
        "DETAIL_SHIFT_SETTING": "Detay Vites Ayarı",
        "DELETE_SHIFT_SETTING": "Vites değiştirme ayarını sil",
        "Delete_confirm_shift_setting": "Bu öğeyi silmek istiyor musunuz?",
        "Required_fields": "Gerekli Alanlar",
        "Save": "Kaydetmek",
        "Cancel": "İptal etmek",
        "From": "İtibaren",
        "To": "İle",
        "Edit_shift_template": "Vites Şablonunu Düzenle",
        "Add_shift_template": "Vardiya Şablonu Ekle",
        "Detail_shift_template": "Detay Vites Şablonu",
        "Delete_shift_template": "Vites Şablonunu Sil",
        "Delete_confirm_shift_template": "Bu öğeyi silmek istiyor musunuz?",
        "Working_time": "Çalışma süresi",
        "Create_shift_settings_success": "Yeni kaydırma ayarı başarıyla oluşturuldu",
        "Create_shift_settings_fail": "Vardiya ayarı oluştururken hatalar meydana geldi",
        "Update_shift_settings_success": "Vardiya ayarı başarıyla güncellendi",
        "Update_shift_settings_fail": "Vites değiştirme ayarını güncellerken hatalar meydana geldi",
        "Delete_shift_settings_success": "Vardiya ayarı başarıyla silindi",
        "Delete_shift_settings_fail": "Vites değiştirme ayarını silerken hatalar meydana geldi",
        "Create_shift_template_success": "Yeni Vites Şablonu başarıyla oluşturuldu",
        "Create_shift_template_fail": "Vites şablonu oluştururken hatalar meydana geldi",
        "Update_shift_template_success": "Shift şablonu başarıyla güncellendi",
        "Update_shift_template_fail": "Vites şablonunu güncellerken hatalar meydana geldi",
        "Delete_shift_template_success": "Vites şablonu başarıyla silindi",
        "Delete_shift_template_fail": "Vites şablonunu silerken hatalar meydana geldi",
        "Yes": "Evet",
        "No": "HAYIR"
    },
    "promotionSettings": {
        "Detail_campaign": "Detay kampanyası",
        "Edit_campaign": "Düzenleme kampanyası",
        "Add_campaign": "Kampanya ekle",
        "Delete_campaign": "Kampanyayı Sil",
        "Delete_confirm_campaign": "Seçilen kampanyayı silmek istiyor musunuz?",
        "Add": "Eklemek",
        "Corporate_list": "Kurumsal liste",
        "permittedCorporate": "İzin Verilen Kurumsal Liste",
        "CorporateCustomers": "Kurumsal Müşteriler",
        "auto_add_corporate": "Kurumsal Kullanıcının Promosyon Listesine Otomatik Ekle",
        "Show_per_page": "Sayfa başına öğeler",
        "Page": "Sayfa",
        "Name": "İsim",
        "Actions": "Eylem",
        "Private": "Özel",
        "show_pages": "{0} - {1} {2} gösterilen",
        "Edit": "Düzenlemek",
        "Delete": "Silmek",
        "Send_to_inbox": "Gelen Kutusuna Gönder",
        "Save": "Kaydetmek",
        "Duplicate": "Kopyalamak",
        "Cancel": "İptal etmek",
        "Required_fields": "Gerekli Alanlar",
        "Promotion_code": "Promosyon kodu",
        "Promotion_type": "Promosyon Türü",
        "Campaign": "Kampanya",
        "Type": "Tip",
        "Value": "Değer",
        "keepMinFee": "Promosyondan sonra toplam ücret, asgari ücretten daha büyük olmalıdır",
        "Zone": "Bölgeler",
        "paymentMethodsApply": "Ödeme yöntemleri",
        "schedules": "Programlar",
        "minimumEstFareApply": "Kod uygulamak için asgari tahmini toplam ücret",
        "quantityLimitPerYear": "Maksimum kullanım/kullanıcı/yıl",
        "termAndCondition": "Terim ve Durum",
        "Valid_from": "Geçerli",
        "Valid_to": "Geçerli",
        "Total_uses_user": "Toplam Kullanımlar/Kullanıcı",
        "Released_quantity": "Serbest bırakılan miktar",
        "Number_of_sending_inbox": "Gönderen Gelen Kutusu Sayısı",
        "Status": "Durum",
        "Activate": "Etkinleştirmek",
        "Deactivate": "Devre dışı bırakmak",
        "Add_promotion_code": "Promosyon kodu ekle",
        "Edit_promotion_code": "Promosyon Kodunu Düzenle",
        "Detail_promotion_code": "Detay Promosyon Kodu",
        "Delete_promotion_code": "Promosyon Kodunu Sil",
        "Delete_confirm_promotion_code": "Seçilen promosyon kodunu silmek istiyor musunuz?",
        "New_customer": "Yeni Müşteriler",
        "Budget_per_user": "Bütçe/kullanıcı (tüm promosyon süresi için)",
        "Budget_per_user_day": "Bütçe/kullanıcı/gün",
        "Budget_per_user_month": "Bütçe/kullanıcı/ay",
        "Budget_per_user_year": "Bütçe/kullanıcı/yıl",
        "Unlimited": "Sınırsız",
        "Limited": "Sınırlı",
        "Total_uses_per_user": "Maksimum Kullanımlar/Kullanıcı (tüm promosyon süresi için)",
        "Send_an_in_box_automatically_to_new_customers": "Yeni müşterilere otomatik olarak bir gelen kutusu gönderin",
        "Notes": "Notalar",
        "Public": "Halk",
        "Create_campaign_success": "Yeni kampanya başarıyla yaratıldı",
        "Create_campaign_fail": "Kampanya oluştururken hatalar meydana geldi",
        "Update_campaign_success": "Kampanya başarıyla güncellendi",
        "Update_campaign_fail": "Kampanyayı güncellerken hatalar meydana geldi",
        "Delete_campaign_success": "Kampanya başarıyla silindi",
        "Delete_campaign_fail": "Hatalar kampanyayı silerken meydana geldi",
        "Loading_campaign_fail": "Hatalar yüklenirken meydana geldi",
        "Update_campaign_status_success": "Kampanya durumu başarıyla güncellendi",
        "Update_campaign_status_fail": "Kampanya durumunu güncellerken hatalar meydana geldi",
        "Loading_promotion_code_fail": "Promosyon kodu yüklenirken hatalar meydana geldi",
        "Select_campaign": "Kampanya seçin",
        "Create_promotion_code_success": "Yeni promosyon kodu başarıyla oluşturuldu",
        "Create_promotion_code_fail": "Promosyon kodu oluşturulurken hatalar meydana geldi",
        "Update_promotion_code_status_success": "Promosyon kodu durumu başarıyla güncellendi",
        "Update_promotion_code_status_fail": "Promosyon kodu durumunu güncellerken hatalar meydana geldi",
        "Delete_promotion_code_confirmation": "Bu promosyon kodlarını silmek istiyor musunuz?",
        "Just_delete_inactive": "** Yalnızca etkin olmayan durumdaki promosyon kodu silinebilir.",
        "Send_inbox_confirmation": "Bu promosyon kodunu yolcu gelen kutusuna göndermek istediğinizden emin misiniz?",
        "Send_inbox_success": "Promosyon kodu başarıyla gönderildi.",
        "Send_inbox_fail": "Promosyon kodu başarısız oldu.Lütfen tekrar deneyin!",
        "Confirmation": "Onaylama",
        "Delete_promotion_codes_success": "Promosyon kodu başarıyla silindi",
        "Delete_promotion_codes_fail": "Promosyon kodu silinirken hatalar meydana geldi",
        "Delete_multiple_promotion_codes_success": "Promosyon kodları başarıyla silindi",
        "Delete_multiple_promotion_codes_fail": "Promosyon kodlarını silerken hatalar meydana geldi",
        "Delete_promotion_code_success": "Promosyon kodu başarıyla silindi",
        "Delete_promotion_code_fail": "Promosyon kodu silinirken hatalar meydana geldi",
        "Update_multiple_promotion_code_status_success": "Promosyon kodu durumları başarıyla güncellendi",
        "Update_multiple_promotion_code_status_fail": "Promosyon kodu durumları silinirken hatalar meydana geldi",
        "Search_here": "BURADA ARAYIN",
        "Please_input_campaign_name": "Lütfen kampanya adı girin",
        "Please_input_promotion_code": "Lütfen promosyon kodu girin",
        "Please_input_promotion_code_at_least_4_characters": "Lütfen en az 4 karakter girin.",
        "Please_select_campaign": "Lütfen kampanyayı seçin",
        "Please_input_value": "Lütfen Giriş Değeri",
        "Please_select_date": "Lütfen Tarih'i seçin",
        "Please_add_schedules": "Lütfen program ekleyin",
        "Please_add_corporate": "Lütfen Kurumsal Ekleyin",
        "Please_choose_at_least_one_customer": "Lütfen en az bir müşteri seçin.",
        "This_campaign_name_has_been_existing_on_the_system_Please_input_another_one": "Bu kampanya adı sistemde mevcuttu.Lütfen başka bir tane girin.",
        "This_promotion_code_has_been_existing_on_the_system_Please_input_another_one": "Bu promosyon kodu sistemde mevcuttu.Lütfen başka bir tane girin.",
        "Invalid_file": "Dosya içeriği geçersiz",
        "File_size_larger_than_5Mb": "Dosya boyutu 5MB'den büyük",
        "Import_promotions_success": "Dosya başarıyla içe aktarıldı",
        "Import_Promotions": "İçe Aktarma Dosyası",
        "Number_successful_imports": "İthal Öğeler",
        "Number_skipped": "Öğeleri atla",
        "File": "Dosya",
        "Phone_Numbers": "Telefon Numarası (lar)",
        "Import_Promotion_code_success": "Promosyon kodu <b> {0} </b> başarıyla içe aktarıldı",
        "Import_Promotion_code_fail": "Promosyon kodu <b> {0} </b> içe aktarılamadı.Lütfen tekrar deneyin",
        "Customer_type": "Müşteri Türü",
        "All": "Tüm",
        "Referred_customers": "Yönlendirilen müşteriler",
        "Common_customers": "Ortak müşteriler",
        "Operation_zone": "Operasyon Bölgeleri",
        "totalUsesLimitPerDay": "Maksimum kullanım/kullanıcı/gün",
        "quantityLimitPerDay": "Maksimum miktar/gün",
        "quantityLimitPerMonth": "Maksimum miktar/kullanıcı/ay",
        "applyVerifiedCustomers": "Sadece doğrulanmış Malezya müşterileri için başvurun",
        "applyWhenBudgetLimit": "Kullanıcı bütçe sınırı bitene kadar kod uygulayabilir",
        "applyVerifiedCustomersTooltip": "Bu promosyon, ulusal kimliği doğrulayan ve kimlik kartlarını yalnızca sisteme yükleyen Malezya için geçerlidir.",
        "valueLimitPerUse": "Maksimum değer/kullanım (uygulama v48 ve üstü için başvurun)",
        "Promotion_Services": "Hizmet vermek",
        "transport": "Taşıma",
        "shuttle": "Servis",
        "parcel": "Parsel",
        "food": "Yiyecek",
        "mart": "Müthiş",
        "intercity": "Şehirlerarası",
        "addSchedule": "Program ekle",
        "editSchedule": "Düzenleme Çizelgesi",
        "date": "Tarih",
        "Time_range": "Zaman aralığı",
        "startTime": "Başlangıç ​​Zamanı",
        "endTime": "Son zaman",
        "applyForOtherDays": "Diğer günlere başvurun",
        "scheduleTimeOverlap": "Program süresi birbirleriyle örtüşmemelidir",
        "schedule_startDate_greater_than_endDate": "Başlangıç ​​tarihi bitiş tarihinden daha büyük",
        "scheduleTimeDuplicate": "Program süresi birbirleriyle çoğaltılmamalıdır",
        "promoIcon": "Promosyon simgesi",
        "bannerImage": "Banner görüntüsü",
        "Upload_an_image": "Bir Resim Yükle",
        "Click_here_to_view": "Görüntülemek için buraya tıklayın."
    },
    "quest_event": {
        "name": "İsim",
        "userType": "Kullanıcı türü",
        "carType": "Araba tipi",
        "operationZones": "Operasyon bölgesi",
        "type": "Olay türü",
        "isLuckyDraw": "Lucky Beraberlik",
        "start_date": "Başlangıç ​​Tarihi",
        "end_date": "Bitiş tarihi",
        "numOfQualify": "Nitelikli sayı",
        "event_progress": "Etkinlik İlerleme",
        "isActive": "Durum",
        "Actions": "Eylem",
        "passenger": "Müşteri",
        "driver": "Sürücü",
        "first_will_win": "Birincisi kazanacak",
        "largest_will_win": "En Büyük Kazanacak (Lider Taht)",
        "not_start": "Başlamamış",
        "active": "Aktif",
        "closed": "Kapalı",
        "event_details": "Görev Ayrıntıları",
        "add_event": "Görev ekle",
        "limited": "Sınırlı",
        "unlimited": "Sınırsız",
        "eventImage": "Etkinlik Görüntüsü",
        "sharedImage": "Paylaşılan Resim",
        "qualifiedImage": "Nitelikli görüntü",
        "expiredImage": "Süresi dolmuş resim",
        "luckyDrawImage": "Şanslı Draw resim",
        "Upload_an_image": "Bir Resim Yükle",
        "Required_Image": "Görüntü uzantıları (.jpg, .png, .gif)",
        "ok": "TAMAM",
        "eventId": "Olay kimliği",
        "passengerMetric": {
            "booking_distance": "Rezervasyon mesafesi",
            "booking_time": "Rezervasyon Süresi",
            "booking_amount_spent": "Rezervasyon tutarı harcandı",
            "num_of_ondemand": "Talep üzerine sayı",
            "num_of_reservation": "Rezervasyon Sayısı",
            "num_of_intercity": "Şehirlerarası sayısı",
            "num_of_delivery": "Teslimat Sayısı",
            "num_of_referral": "Tavsiye sayısı",
            "num_of_sharing": "Paylaşım Sayısı",
            "topup_wallet_amount": "Topup cüzdan miktarı",
            "num_of_complete_wallet": "Tam Rezervasyon Sayısı (Cüzdan)",
            "num_of_complete_booking_by_referee": "Tam rezervasyon sayısı (hakemle)",
            "number_of_complete_booking": "Tam rezervasyon sayısı",
            "number_of_food_mart": "Yiyecek / Mart sayısı"
        },
        "driverMetric": {
            "booking_distance": "İş mesafesi",
            "booking_time": "İş zamanı",
            "num_of_referral": "Tavsiye sayısı",
            "num_of_complete": "Tam rezervasyon sayısı (benzersiz müşteri)",
            "num_of_referral_driver": "Tavsiye Sayısı (Sürücü)",
            "num_of_accept": "Kabul rezervasyonu sayısı",
            "num_of_receive": "Rezervasyon Sayısı",
            "num_of_cancel": "Sürücüye göre iptal rezervasyonu sayısı",
            "sum_of_rating": "Toplam derecelendirme yıldızı sayısı",
            "total_rating": "Toplam derecelendirme süresi sayısı",
            "earnings_amount": "Kazanç Tutarı",
            "num_of_complete_wallet": "Tam Rezervasyon Sayısı (Cüzdan)",
            "num_of_complete_booking_by_referee": "Tam rezervasyon sayısı (hakemle)",
            "available_time": "Çevrimiçi Zaman"
        },
        "Click_here_to_view": "Görüntülemek için buraya tıklayın.",
        "view": "Görüntülemek için buraya tıklayın.",
        "metricType": "Tip",
        "task": "Görev",
        "value": "Değer",
        "quantity": "Miktar",
        "quests_list": "Görevler",
        "rankImages": "Sıralama Görüntüsü (nokta)",
        "qualifiedPrizeOrder": "Nitelikli ödüller",
        "luckyDrawPrizeOrder": "Şanslı Çizim Ödülleri",
        "reward": "Öğe",
        "from": "İtibaren",
        "to": "İle",
        "image": "İmaj",
        "Add_Prize": "Eklemek",
        "bannerText": "Banner metni",
        "buttonText": "Düğme metni",
        "bannerLink": "Banner bağlantısı",
        "shortLink": "Kısa bağlantı",
        "buttonLinkDesktop": "Düğme Bağlantısı (Masaüstü)",
        "buttonLinkAndroid": "Düğme Bağlantısı (Android)",
        "buttonLinkiOS": "Düğme Bağlantısı (iOS)",
        "termsURL": "Şartlar ve Koşullar Bağlantı",
        "metadataTitle": "Meta Veri Başlığı",
        "metadataDescription": "Meta veri açıklaması",
        "isDarkMode": "Karanlık mod",
        "isActive_details": "Aktif",
        "Required_fields": "Gerekli Alanlar",
        "change": "Değiştirmek",
        "Please_input_name": "Lütfen Ad Giriş",
        "Please_select_date": "Lütfen Tarih'i seçin",
        "endDate_invalid": "Bitiş tarihi geçersiz",
        "startDate_greater_than_endDate": "Başlangıç ​​tarihi bitiş tarihinden daha büyük",
        "Please_input_termsURL": "Lütfen Giriş Şartları ve Koşullar Bağlantı",
        "Please_input_metadataTitle": "Lütfen meta veri başlığı girin",
        "Please_input_metadataDescription": "Lütfen Meta Veri Açıklaması Giriş",
        "Please_input_bannerText": "Lütfen Banner Metin Giriş",
        "Please_input_buttonText": "Lütfen düğme metnini girin",
        "Please_input_bannerLink": "Lütfen Banner Bağlantısı Giriş",
        "Please_input_shortLink": "Lütfen kısa bağlantı girin",
        "Please_input_buttonLinkDesktop": "Lütfen Giriş Düğmesi Bağlantısı (Masaüstü)",
        "Please_input_buttonLinkAndroid": "Lütfen Giriş Düğmesi Bağlantısı (Android)",
        "Please_input_buttonLinkiOS": "Lütfen Giriş Düğmesi Bağlantısı (iOS)",
        "the_range_1_to_5": "Lütfen %{name} için 1 ila 5 aralığında değeri girin!",
        "the_range_1_to_100": "Lütfen %{name} için 1 ila 100 aralığında değeri girin!",
        "task_is_empty": "Lütfen %{name} için görevi girin!",
        "task_limit_50_characters": "Görev maksimum uzunluk %{name} için 50 karakter!",
        "please_input_points": "Lütfen %{name} için puanlar girin!",
        "please_input_value": "Lütfen %{name} için değeri girin!",
        "value_is_invalid": "%{name}, değer bir sayı olmalı",
        "please_upload_image": "Lütfen %{name} öğesi için resmi yükleyin!",
        "rank_invalid": "%{Name} rütbe geçersiz!",
        "rank_invalid_to": "\"To\" rütbe bir sayı olmalı",
        "deepLink": "Derinlik",
        "point_per_task": "Görev başına nokta",
        "points": "Puan",
        "points_per_some_think": "%Puan {birim}",
        "criterias": "Kriterler (isteğe bağlı)",
        "criterias_list": "Kriterler",
        "criteriaType": "Tip",
        "criteriaValue": "Değer",
        "acceptance_rating": "Kabul Derecesi (Eşit veya Eşit)",
        "cancellation_rating": "İptal derecesi (eşit veya eşit)",
        "driver_rating": "Sürücü Derecelendirmesi (Eşit veya Eşit)",
        "please_upload_image_rank": "Lütfen %{name} rütbe için resim yükleyin!",
        "Update_event_success": "Görev başarıyla güncellendi",
        "Update_event_fail": "Arayış güncellerken hatalar meydana geldi",
        "Create_event_success": "Yeni Görev başarıyla yaratıldı",
        "Create_event_fail": "Arayış oluştururken hatalar meydana geldi",
        "suspend_list": "Asma listesi"
    },
    "voucherSettings": {
        "Search_here": "BURADA ARAYIN",
        "Voucher_code": "Kod #",
        "Campaign": "Kampanya",
        "Type": "Tip",
        "Value": "Değer",
        "Valid_from": "Geçerli",
        "Valid_to": "Geçerli",
        "isActive": "Durum",
        "Activate": "Etkinleştirmek",
        "Deactivate": "Devre dışı bırakmak",
        "Actions": "Eylem",
        "Active": "Aktif",
        "Inactive": "Aktif olmayan",
        "redeemed": "Kurtarılmış",
        "expired": "Günü geçmiş",
        "Add_Voucher_code": "Kod ekle",
        "Add": "Eklemek",
        "Show_per_page": "Sayfa başına öğeler",
        "Page": "Sayfa",
        "Name": "İsim",
        "show_pages": "{0} - {1} {2} gösterilen",
        "Edit": "Düzenlemek",
        "Delete": "Silmek",
        "Save": "Kaydetmek",
        "Duplicate": "Kopyalamak",
        "Cancel": "İptal etmek",
        "Required_fields": "Gerekli Alanlar",
        "Detail_voucher_code": "Detay Kupon Kodu",
        "Delete_voucher_code": "Kupon kodunu sil",
        "Confirmation": "Onaylama",
        "Delete_confirm_voucher_code": "Seçilen kupon kodunu silmek ister misiniz?",
        "Delete_voucher_code_confirmation": "Bu kupon kodlarını silmek ister misiniz?",
        "Just_delete_inactive": "** Yalnızca aktif olmayan durumdaki kupon kodu silinebilir.",
        "Loading_voucher_code_fail": "Kupon kodu yüklenirken hatalar meydana geldi",
        "Select_campaign": "Kampanya seçin",
        "Create_voucher_code_success": "Yeni kupon kodu başarıyla oluşturuldu",
        "Create_voucher_code_fail": "Kupon kodu oluşturulurken hatalar meydana geldi",
        "Update_voucher_code_status_success": "Kupon kodu durumu başarıyla güncellendi",
        "Update_voucher_code_status_fail": "Kupon kodu durumunu güncellerken hatalar meydana geldi",
        "Delete_voucher_codes_success": "Kupon kodu başarıyla silindi",
        "Delete_voucher_codes_fail": "Kupon kodu silinirken hatalar meydana geldi",
        "Delete_multiple_voucher_codes_success": "Kupon kodları başarıyla silindi",
        "Delete_multiple_voucher_codes_fail": "Kupon kodlarını silerken hatalar meydana geldi",
        "Delete_voucher_code_success": "Kupon kodu başarıyla silindi",
        "Delete_voucher_code_fail": "Kupon kodu silinirken hatalar meydana geldi",
        "Update_multiple_voucher_code_status_success": "Kupon kodu durumları başarıyla güncellendi",
        "Update_multiple_voucher_code_status_fail": "Kupon kodu durumları silinirken hatalar meydana geldi",
        "Please_input_voucher_code": "Lütfen kupon kodu girin",
        "Please_input_voucher_code_at_least_4_characters": "Lütfen en az 4 karakter girin.",
        "Please_select_campaign": "Lütfen kampanyayı seçin",
        "Please_input_value": "Lütfen Giriş Değeri",
        "Please_select_date": "Lütfen Tarih'i seçin",
        "Invalid_file": "Dosya içeriği geçersiz",
        "File_size_larger_than_5Mb": "Dosya boyutu 5MB'den büyük",
        "Import_vouchers_success": "Dosya başarıyla içe aktarıldı",
        "Import_vouchers": "İçe Aktarma Dosyası",
        "Number_successful_imports": "İthal Öğeler",
        "Number_skipped": "Öğeleri atla",
        "File": "Dosya",
        "Phone_Numbers": "Telefon Numarası (lar)",
        "Import_voucher_code_success": "Kupon kodu <b> {0} </b> başarılı bir şekilde içe aktarıldı",
        "Import_voucher_code_fail": "Kupon kodu <b> {0} </b> içe aktarılamadı.Lütfen tekrar deneyin",
        "All": "Tüm"
    },
    "pointConfig": {
        "point_config": "Nokta yapılandırma",
        "Convert_spent_amount_to_points": "Harcanan miktarı puanlara dönüştürün",
        "Convert_topup_amount_to_points": "Topup miktarını puanlara dönüştürün",
        "Redeem_points_to_voucher": "Puanları Kupon",
        "others": "Diğer",
        "Online_Payment": "Çevrimiçi Ödeme",
        "transport": "Taşıma",
        "parcel": "Parsel",
        "food": "Yiyecek",
        "mart": "Müthiş",
        "delivery": "Teslimat",
        "intercity": "Şehirlerarası",
        "applyCash": "Nakit başvurusunda bulun",
        "point": "Nokta",
        "vourcherName": "İsim",
        "pointsToRedeem": "Kurtarmaya işaret etmek",
        "vourcherValue": "Kupon değeri",
        "validTime": "Geçerli zaman",
        "month": "Ay (lar)",
        "day": "Gün (ler)",
        "termAndCondition": "Şartlar ve Koşullar",
        "Actions": "Eylem",
        "add_voucher": "Eklemek",
        "instructionURL": "Talimat Bağlantısı",
        "displayName": "Süper Uygulamada Adı Göster",
        "isActive": "Aktif",
        "EDIT_VOUCHER": "Güncelle kuponu",
        "DETAIL_VOUCHER": "Kupon detayları",
        "CREATE_VOUCHER": "Kupon ekle",
        "update_successfully": "Nokta yapılandırma ayarı başarıyla güncellendi",
        "Create_voucher_successfully": "Yeni Kupon başarıyla yaratıldı",
        "Create_voucher_fail": "Kupon oluştururken hatalar meydana geldi",
        "Update_voucher_successfully": "Kupon başarıyla güncellendi",
        "Update_voucher_fail": "Kupon güncellenirken hatalar meydana geldi",
        "Delete_voucher_successfully": "Kupon başarıyla silindi",
        "Delete_voucher_fail": "Kupon silinirken hatalar meydana geldi",
        "Confirmation": "Onaylama",
        "Delete_voucher_confirmation": "Bu kuponu silmek ister misiniz?",
        "Please_enter_value_least_one": "Dönüşüm oranı gereklidir!Devam etmek için harcanan tutar veya Top-Up tutarını ayarlayın.",
        "Please_enter_value_is_number_spentAmount": "Lütfen \"harcanan miktarı dönüştür\" hizmetinden geçerli tutarı girin {0} \".",
        "Please_enter_value_is_number_topUp": "Lütfen \"Topup Tutarını Dönüştür\" yöntemi \"{0}\" adresinden geçerli miktarı girin."
    },
    "resetPassword": {
        "New_Password": "Yeni Şifre",
        "New_Comfirm_Password": "Yeni Onayla Şifre",
        "Save": "Kaydetmek",
        "INFO_SUCCESS": "E -postanıza bir sıfırlama şifre bağlantısı gönderildi",
        "TOKEN_EXPIRED": "Sıfırlama şifre jetonunuz süresi doldu",
        "Back_To_Login_Page": "Giriş sayfasına geri dön"
    },
    "permission": {
        "editBooking": "Çiftlik çıkış rezervasyonlarını düzenleyin",
        "companyOperator": "Şirket operatörü",
        "Third_Party_Integration_Booking": "3. Parti Entegrasyonu (Booking.com)",
        "Third_Party_Integration_HolidayTaxis": "3. Parti Entegrasyonu (HolidayTaxis)",
        "supplierPermission": "Tedarikçi İzni",
        "Hint_edit_booking": "Şirketlerin/tedarikçilerin filo sahibi tarafından yetiştirilen rezervasyonları düzenlemelerine izin verin.",
        "View": "Görüş",
        "Actions": "Eylem",
        "EditFare": "Ücreti Düzenle",
        "EditDriverEarning": "Sürücü Kazançını Düzenle",
        "EditTotal": "Toplam düzenle",
        "ImportBookings": "Taşıma rezervasyonlarını içe aktarın",
        "ImportDeliveryBookings": "Teslimat rezervasyonlarını içe aktarın",
        "ShowFare": "Ücretini Göster",
        "delete_permission": "İzni Sil",
        "DELETE_CONFIRM": "Seçilen izni silmek istediğinizden emin misiniz!",
        "Yes": "Evet",
        "No": "HAYIR",
        "Cancel": "İptal etmek",
        "DRIVERS": "Sürücüler",
        "Driver_list": "Sürücü listesi",
        "Enter_to_search": "Aramaya girin",
        "Permitted_driver_list": "İzin Verilen Sürücü Listesi",
        "Save": "Kaydetmek",
        "CAR": "ARABA",
        "Car_list": "Araba listesi",
        "Permitted_car_list": "İzin Verilen Araba Listesi",
        "Edit_permission": "İzni Düzenle",
        "Add_permission": "İzin Ekle",
        "Detail_permission": "Ayrıntı İzni",
        "Name": "İsim",
        "Driver_Car": "Sürücü/araba",
        "All": "Tüm",
        "Company": "Şirket",
        "By_specific": "Özel olarak",
        "Drivers": "Sürücüler",
        "Cars": "Arabalar",
        "Show_per_page": "Sayfa başına öğeler",
        "Page": "Sayfa",
        "show_pages": "{0} - {1} {2} gösterilen",
        "Delete": "Silmek",
        "Edit": "Düzenlemek",
        "Status": "Durum",
        "Action": "Aksiyon",
        "Inactive": "Aktif olmayan",
        "Active": "Aktif",
        "Update_status_error": "Durum hatasını güncelle",
        "All_tooltip": "Tüm sürücü (ler) harita, ipucu, sürücüler, operasyon ve raporlarda görüntülenecektir.Tüm otomobil (ler) araba mgmt'de görüntülenecek",
        "Company_tooltip": "Bu şirkette izin verilen sürücü (ler) harita, ipucu, sürücüler, operasyon ve raporlarda görüntülenecektir.Bu şirkette izin verilen otomobil (ler) araba mgmt'de gösterilecek",
        "By_specific_tooltip": "Bu listedeki izin verilen sürücü (ler) sürücüler, operasyonda görüntülenecektir.Bu listede izin verilen otomobil (ler) araba mgmt'de görüntülenecek",
        "Full_permissions": "Tam İzinler",
        "ChangeBalance": "Kredi bakiyesini değiştir",
        "ChangeCashBalance": "Nakit bakiyesini değiştir",
        "TotalBalance": "Toplam Denge",
        "WriteOffDebt": "Borç yaz",
        "topupPaxWallet": "Dengeyi Değiştir",
        "SeFleetCommission": "Filo Komisyonunu Set (tüm sürücüler)",
        "EditFleetCommission": "Filo Komisyonunu Düzenle",
        "EditBalance": "Bakiyeyi Düzenle",
        "ViewHeatMap": "Isı haritasını görüntüleyin",
        "Delete_permission_success": "İzin başarıyla silindi",
        "Delete_permission_fail": "Hatalar iznini silerken meydana geldi",
        "Create_permission_fail": "Hatalar yeni izin oluştururken meydana geldi",
        "Create_permission_success": "Yeni izin başarıyla yaratıldı",
        "Update_permission_fail": "Hatalar izni güncellerken meydana geldi",
        "Update_permission_success": "İzin başarıyla güncellendi",
        "No_action_selected": "Lütfen eylem listesinde en az bir eylemi işaretleyin",
        "Required_fields": "Gerekli Alanlar",
        "sendSMS": "Mesaj Gönder (SMS)",
        "sendInbox": "Mesaj Gönder (Gelen Kutusu)",
        "PayToDriver": "Sürücüye Ödeme' görüntüleyin",
        "PayoutHistory": "Ödeme Geçmişi'ni Görüntüle",
        "Pay": "Ücretli Şoför",
        "ActivateDriver": "Sürücüyü etkinleştir",
        "Export": "İhracat",
        "Export_csv": "Dışa aktarma .csv",
        "Street-sharing_rate": "Sokak Paylaşım Oranı"
    },
    "thirdPartySettings": {
        "category": "Kategori",
        "Not_over_limit_subs": "10'dan fazla alt lokasyon oluşturamazsınız",
        "Show_per_page": "Sayfa başına öğeler",
        "Page": "Sayfa",
        "show_pages": "{0} - {1} {2} gösterilen",
        "Delete": "Silmek",
        "Edit": "Düzenlemek",
        "Status": "Durum",
        "Actions": "Eylem",
        "Action": "Aksiyon",
        "Inactive": "Aktif olmayan",
        "Active": "Aktif",
        "website": "Web sitesi",
        "Check_Google_Address": "Latitude ve Boylamdan Google adresini kontrol edin",
        "Delete_Category_success": "Kategori başarıyla silindi",
        "Delete_category_fail": "Hatalar kategoriyi silerken meydana geldi",
        "availableTime": "Mevcut Zaman",
        "Please_choose_at_least_one_category": "Lütfen en az bir kategori seçin",
        "Please_input_category_name": "Lütfen kategori adını girin",
        "Create_category_success": "Kategori başarıyla yaratıldı",
        "Create_category_fail": "Hatalar kategori oluştururken meydana geldi",
        "Update_third_party_success": "Konum başarıyla güncellendi",
        "Update_category_fail": "Kategoriyi güncellerken hatalar meydana geldi",
        "All_Category": "Tüm kategori",
        "Delete_Category": "Düzenleme Kategorisi",
        "Edit_Category": "Düzenleme Kategorisi",
        "Add_Category": "Kategori Ekle",
        "Add_Third_Party_location": "3. taraf yeri ekleyin",
        "Add": "Eklemek",
        "Edit_Third_Party_location": "3. taraf konumunu düzenle",
        "Location_Not_Found": "Konum bulunamadı",
        "Create_third_party_success": "Konum başarıyla oluşturuldu",
        "Create_third_party_fail": "Hatalar yer oluştururken meydana geldi",
        "Update_third_party_fail": "Hatalar konumu güncellerken meydana geldi",
        "Alias": "Takma ad",
        "Address": "Adres",
        "Latitude": "Enlem",
        "Longitude": "Boylam",
        "Please_input_address": "Lütfen adresi girin",
        "Please_input_name": "Lütfen konum adı girin",
        "Please_input_latitude": "Lütfen enlem girin",
        "Please_input_longitude": "Lütfen boylam girin",
        "Save": "Kaydetmek",
        "Cancel": "İptal etmek",
        "Required_fields": "Gerekli Alanlar",
        "Last_update": "Son Güncelleme",
        "Username": "Kullanıcı adı",
        "locationType": "Konum Türü",
        "single": "Tek Konum",
        "multiple": "Birden fazla yer",
        "AddMore": "Daha Fazla Ekle",
        "locationList": "Konumlar Listesi",
        "instructionLink": "Talimat Bağlantısı",
        "location": "Konum",
        "checkOnMap": "Haritada kontrol edin",
        "Remove": "Kaldırmak",
        "placeType": "Tip",
        "all": "Tüm",
        "PU": "Toplamak",
        "DO": "Boşa harcamak",
        "namePlaceholder": "örn.Terminal 1",
        "instructionLinkPlaceholder": "Tam talimatla HTML sayfası",
        "instructionLinkTooltip": "Lütfen Talimat için Bir Bağlantı sayfası girin.Yolcu, pikap yeri bulmak için bu bağlantıdaki talimatı takip edebilir.",
        "longitudePlaceholder": "108.1837969",
        "latitudePlaceholder": "16.0393381",
        "Error_message_lat_in_range": "Enlem -90.0 ile 90.0 arasında olmalıdır",
        "Error_message_lng_in_range": "Boylam -180.0 ve 180.0 arasında olmalıdır.",
        "Delete_third_party_success": "Konum başarıyla silindi",
        "Delete_third_party_fail": "Hatalar konumu silme sırasında meydana geldi",
        "Delete_location": "Konumu Sil",
        "Note_guide": "Not: Haritada arama yaparken, konumunuz hızlı bir şekilde bir PIN ile işaretlenecektir.",
        "PostalCode": "Postal Code"
    },
    "city": {
        "DELETE_CITY": "Şehri Sil",
        "DELETE_CONFIRM": "Seçilen şehri silmek istediğinizden emin misiniz?",
        "Yes": "Evet",
        "No": "HAYIR",
        "Cancel": "İptal etmek",
        "EDIT_CITY": "City Düzenle",
        "ADD_CITY": "Şehir ekle",
        "Name": "İsim",
        "Required_fields": "Gerekli Alanlar",
        "Save": "Kaydetmek",
        "Add": "Eklemek",
        "Show_per_page": "Sayfa başına öğeler",
        "Page": "Sayfa",
        "show_pages": "{0} - {1} {2} gösterilen",
        "Delete": "Silmek",
        "Edit": "Düzenlemek",
        "DETAIL_CITY": "Detay Şehri",
        "Update_city_success": "Şehir başarıyla güncellendi",
        "Create_city_success": "Yeni Şehir başarıyla yaratıldı",
        "Delete_city_success": "Şehir başarıyla silindi",
        "cityName": "Şehir adı",
        "Service": "Hizmet vermek",
        "serviceTransportation": "Toplu taşıma",
        "serviceAssistant": "Asistan",
        "serviceDelivery": "Teslimat",
        "isShowDriverSignUp": "Sağlayıcı Kaydı'nda Göster",
        "note_isShowDriverSignUp": "(4.6.46 ve üstü yeni kayıt modülü için başvurun)"
    },
    "jobTab": {
        "DELETE_JOBTAB": "İşler sekmesini sil",
        "DELETE_CONFIRM": "Seçilen İşler sekmesini silmek istediğinizden emin misiniz?",
        "Yes": "Evet",
        "No": "HAYIR",
        "Cancel": "İptal etmek",
        "EDIT_JOBTAB": "İş sekmesini düzenle",
        "ADD_JOBTAB": "İşler sekmesi ekle",
        "Name": "İsim",
        "Required_fields": "Gerekli Alanlar",
        "Save": "Kaydetmek",
        "Add": "Eklemek",
        "Show_per_page": "Sayfa başına öğeler",
        "Page": "Sayfa",
        "show_pages": "{0} - {1} {2} gösterilen",
        "Delete": "Silmek",
        "Edit": "Düzenlemek",
        "DETAIL_JOBTAB": "Detay İşleri sekmesi",
        "Update_JOBTAB_success": "İş sekmesi başarıyla güncellendi",
        "Create_JOBTAB_success": "Yeni İşler sekmesi başarıyla oluşturuldu",
        "Delete_JOBTAB_success": "İş sekmesi başarıyla silindi",
        "JOBTABName": "İşler Sekmesi Adı",
        "jobsLink": "Bağlantı"
    },
    "driverNote": {
        "name": "Sürücü notu",
        "DELETE": "Sürücü notunu sil",
        "DELETE_CONFIRM": "Seçilen sürücü notunu silmek istediğinizden emin misiniz?",
        "EDIT": "Sürücü Notu Düzenle",
        "ADD": "Sürücü notu ekle",
        "DETAIL": "Detay Sürücü Notu",
        "Update_success": "Sürücü notu başarıyla güncellendi",
        "Create_success": "Yeni sürücü notu başarıyla oluşturuldu",
        "Delete_success": "Sürücü notu başarıyla silindi",
        "Delete_fail": "Hatalar sürücü notunu silerken meydana geldi",
        "Load_fail": "Hatalar sürücü notunu yüklerken meydana geldi",
        "Update_fail": "Sürücü notunu güncellerken hatalar meydana geldi",
        "Create_fail": "Hatalar sürücü notu oluştururken meydana geldi"
    },
    "flightIntegration": {
        "Username": "Kullanıcı adı",
        "provider": "Uçuş sağlayıcısı",
        "Key": "API Anahtarı",
        "apiId": "API Kimliği",
        "Save": "Kaydetmek",
        "Update_flight_integration_success": "Uçuş entegrasyonu başarıyla güncellendi",
        "Update_flight_integration_fail": "Uçuş entegrasyonunu güncellerken hatalar meydana geldi"
    },
    "smsIntegration": {
        "provider": "SMS sağlayıcısı",
        "apiToken": "API jetonu",
        "subAccountId": "Alt Hesap Kimliği",
        "senderIdSource": "Gönderen Kimliği (Kaynak):",
        "apiKey": "API Anahtarı",
        "username": "Kullanıcı adı",
        "shortCode": "Kısa Kod",
        "key": "Anahtar",
        "webService": "Web Hizmeti",
        "bearerToken": "Taşıyıcı jeton",
        "keyId": "Anahtar kimlik",
        "email": "E -posta",
        "appKey": "Uygulama anahtarı",
        "sender": "Gönderen kimse",
        "password": "Şifre",
        "maskedPhone": "Maskeli telefon numarası",
        "senderId": "Gönderen kimliği",
        "webservice": "Web Hizmeti",
        "brandname": "Marka",
        "appSecret": "Uygulama sırrı",
        "consumerKey": "Tüketici Anahtarı",
        "requestUrl": "URL talep",
        "authId": "Auth Auth ID",
        "authToken": "Auth Jeton",
        "authKey": "Auth Key",
        "templateId": "Template Id",
        "templateKey": "Template Key",
        "phloId": "Phlo kimliği",
        "dlcNumber": "10DLC numarası",
        "app_key": "Uygulama anahtarı",
        "api_key": "API Anahtarı",
        "accountSID": "Hesap Sid",
        "applicationId": "Uygulama Kimliği",
        "phoneNumber": "SMS numarası 1",
        "phoneNumber2": "SMS numarası 2",
        "SenderID_1": "SenderID 1",
        "SenderID_2": "SenderID 2",
        "smsMarketingNumber": "SMS Pazarlama Telefon Numarası",
        "updateSMSSuccess": "SMS entegrasyonu başarıyla güncellendi",
        "Deleted_success": "The SMS has been deleted successfully",
        "receiverCountries": "Receiver Countries",
        "error": {
            "ERROR_CREATE": "The errors occurred while creating sms",
            "COUNTRY_EXISTED": "Country already exist on system",
            "ERROR_DELETE": "Failed to delete SMS",
            "ERROR_UPDATE": "Failed to update SMS"
        },
        "identifierId": "Identifier ID"
    },
    "mDispatcher": {
        "mDispatchers": "Ortaklar",
        "Active": "Aktif",
        "Inactive": "Aktif olmayan",
        "Deactivate": "Devre dışı bırakmak",
        "Activate": "Etkinleştirmek",
        "Delete": "Silmek",
        "Save": "Kaydetmek",
        "Edit": "Düzenlemek",
        "ADD_MDISPATCHER": "Ortak ekle",
        "EDIT_MDISPATCHER": "Ortağı Düzenle",
        "Phone_number": "Telefon numarası",
        "first_name": "İlk adı",
        "last_name": "Soy isim",
        "email": "E -posta",
        "address": "Adres",
        "mDispatcher_type": "Ortak tür",
        "mDispatcher_type_toolTip": "Daha fazla ortak türü eklemek için lütfen Ayarlar> Ortak Türüne gidin",
        "queuing_area_toolTip": "Daha fazla kuyruk alanı eklemek için lütfen kuyruk alanı menüsüne gidin.",
        "mDispatcher": "Ortak",
        "card_management": "Kart yönetimi",
        "Select_mDispatcher_type": "Ortak Türü Seçin",
        "Amount": "Miktar",
        "Percent": "Yüzde",
        "Yes": "Evet",
        "Required_fields": "Gerekli Alanlar",
        "Cancel": "İptal etmek",
        "commission_type": "Komisyon türü",
        "commission_value": "Komisyon değeri",
        "Name": "İsim",
        "Phone_Number": "Telefon numarası",
        "queuing_area": "Kuyruk alanı",
        "mdispatcher_type": "Ortak tür",
        "registered_from": "Kayıtlı",
        "registration_date": "Kayıt tarihi",
        "status": "Durum",
        "actions": "Eylem",
        "Select_queue_area": "Kuyruk alanı seçin",
        "for_new_mDipatcher_account_password_default_is_password": "Yeni ortak hesabı için varsayılan şifre \"12345678\" dir.",
        "Create_mDispatcher_success": "Yeni ortak başarılı bir şekilde yaratıldı",
        "Create_mDispatcher_fail": "Ortak oluştururken hatalar meydana geldi",
        "Load_mDispatcher_fail": "Ortak yükleme sırasında meydana gelen hatalar",
        "Update_mDispatcher_success": "Ortak başarıyla güncellendi",
        "Update_mDispatcher_fail": "Ortağı güncellerken hatalar meydana geldi",
        "Update_mDispatcher_status_success": "Ortak durumu başarıyla güncellendi",
        "Update_mDispatcher_status_fail": "Ortak durumunu güncellerken hatalar meydana geldi",
        "Update_mDispatcher_statuses_success": "Ortak durumları başarıyla güncellendi",
        "Update_mDispatcher_statuses_fail": "Ortak durumlarını güncellerken hatalar meydana geldi",
        "Delete_mDispatcher_success": "Ortak başarıyla silindi",
        "Delete_mDispatcher_fail": "Hatalar ortağı silme sırasında meydana geldi",
        "Delete_mDispatchers_success": "Ortaklar başarıyla silindi",
        "Delete_mDispatchers_fail": "Ortaklar silinirken hatalar meydana geldi",
        "Do_you_want_to_delete_these_mDipatchers": "Bu ortakları silmek istiyor musunuz?",
        "Only_mDistcher_in_the_inactive_status_can_be_deleted": "*** Yalnızca aktif olmayan durumdaki mdistcher silinebilir",
        "No_card": "Kart bulunamadı",
        "Reset_Password": "Parolayı sıfırla",
        "Reset_password_success": "Ortak şifre başarıyla sıfırlandı",
        "Reset_password_fail": "Ortaklar şifresini sıfırlarken hatalar gerçekleşti",
        "Do_you_want_to_reset_password": "Parolayı sıfırlamak istiyor musunuz?",
        "ERROR_EXISTED_PHONE": "Bu telefon sistemde mevcuttu.Lütfen başka bir tane girin",
        "ERROR_INPUT_VALIDPHONE": "Lütfen geçerli bir telefon numarası girin.",
        "Partner_detail": "Ortak Detaylar",
        "ALERT_SELECT_ACTIVATE": "Lütfen etkinleştirmek için ortakları seçin.",
        "ALERT_SELECT_DEACTIVATE": "Lütfen devre dışı bırakmak için ortakları seçin.",
        "CONFIRM_DEACTIVATE_MULTI": "Bu ortakları devre dışı bırakmak istiyor musunuz?",
        "CONFIRM_ACTIVATE_MULTI": "Bu ortakları etkinleştirmek istiyor musunuz?",
        "ERROR_COMMISSION_NUMBERS": "Lütfen geçerli bir numara girin",
        "No_Support": "Bu alanda yeni kart ekleme şu anda desteklenmiyor",
        "Add": "Eklemek",
        "Default_zone": "Varsayılan bölge",
        "required_email_payment": "Ortağın e -postası gereklidir.Devam etmek için lütfen profili güncelleyin.",
        "titleGuestQR": "Ortak Konuk-QR bağlantısı",
        "questQRContent": "Kişiselleştirilmiş ortak rezervasyon bağlantınız burada!Barlarda, otel resepsiyonlarında, konuk odalarında ve web sitenizde belirgin bir şekilde görüntüleyin - müşterilerinizin bir yolculuğa veya teslimata ihtiyaç duyabileceği her yerde.Benzersiz QR kodunuzla stande tasarlamayı düşünün, müşterilere yolculuklarını rezerve etmeleri için hızlı ve kolay bir tarama sağlar!",
        "View_Guest_QR": "Konuk QR'yi görüntüleyin"
    },
    "mDispatcherType": {
        "Name": "İsim",
        "Required_fields": "Gerekli Alanlar",
        "Save": "Kaydetmek",
        "Cancel": "İptal etmek",
        "No": "HAYIR",
        "Add": "Eklemek",
        "Show_per_page": "Sayfa başına öğeler",
        "Page": "Sayfa",
        "show_pages": "{0} - {1} {2} gösterilen",
        "Delete": "Silmek",
        "Edit": "Düzenlemek",
        "DELETE_MDISPATCHER_TYPE": "Ortak türünü sil",
        "DELETE_CONFIRM": "Ortak türünü sildiğinizden emin misiniz?",
        "Yes": "Evet",
        "EDIT_MDISPATCHER_TYPE": "Ortak Türünü Düzenle",
        "ADD_MDISPATCHER_TYPE": "Ortak Türü Ekle",
        "DETAIL_MDISPATCHER_TYPE": "Detay Ortak Türü",
        "Actions": "Eylem",
        "Delete_mDispatcherType_success": "Ortak türü başarıyla silindi",
        "Delete_mDispatcherType_fail": "Ortak türü silme sırasında meydana gelen hatalar",
        "Load_mDispatcherType_fail": "Ortaklar türünü yüklerken hatalar meydana geldi",
        "Create_mDispatcherType_success": "Yeni ortak türü başarıyla yaratıldı",
        "Create_mDispatcherType_fail": "Ortaklar türü oluştururken hatalar meydana geldi",
        "Update_mDispatcherType_success": "Ortak Türü başarıyla güncellendi",
        "Update_mDispatcherType_fail": "Ortaklar türünü güncellerken hatalar meydana geldi"
    },
    "queuingArea": {
        "queuing_area_info": "Kuyruk alanı bilgisi",
        "queuingDrivers": "Aktif sürücüler",
        "Name": "İsim",
        "Required_fields": "Gerekli Alanlar",
        "Save": "Kaydetmek",
        "Cancel": "İptal etmek",
        "Yes": "Evet",
        "No": "HAYIR",
        "Add": "Eklemek",
        "Show_per_page": "Sayfa başına öğeler",
        "Enter_to_search": "Aramaya girin",
        "Page": "Sayfa",
        "show_pages": "{0} - {1} {2} gösterilen",
        "Delete": "Silmek",
        "Edit": "Düzenlemek",
        "Active": "Aktif",
        "Inactive": "Aktif olmayan",
        "Deactivate": "Devre dışı bırakmak",
        "Activate": "Etkinleştirmek",
        "drivers": {
            "order": "Emir",
            "Driver_name": "Sürücü adı",
            "Phone_Number": "Telefon numarası",
            "driverLicenseNumber": "Plaka",
            "Car_Type": "Araba tipi",
            "checkInTime": "Zamanı kontrol edin",
            "duration": "Süre",
            "timestampScore": "Zaman damgası puanı",
            "changeOrderDriver": "Siparişi Değiştir",
            "detailOrderDriver": "Sürücü siparişi",
            "Yes": "Evet",
            "No": "HAYIR",
            "insert": "Sokmak",
            "refresh": "Yenilemek",
            "insertDriver": "Kuyruğa bir sürücü ekleyin",
            "orderDriver": "Emir #",
            "reason": "Sebep",
            "removeDriver": "Sürücüyü kuyruktan kaldır",
            "removeDriverConfirm": "Bu sürücüyü kuyruktan çıkarmak istiyor musunuz?",
            "driverNotStay": "Bu sürücü kuyruk alanında kalmaz.Lütfen daha sonra tekrar deneyin.",
            "overOrder": "# Sipariş, kuyruktaki toplam sürücü sayısından daha büyük olamaz.",
            "noDriverFound": "Sürücü bulunamadı",
            "driverUpdateErrRs": "Sürücü kuyruk alanında değil.Lütfen daha sonra tekrar deneyin.",
            "orderUpdateErrRs": "# Sipariş, kuyruktaki toplam sürücü sayısından daha büyük olamaz.",
            "removeDriverSuccess": "Sürücü kuyruk alanından başarıyla kaldırıldı.",
            "removeDriverFail": "Sürücüyü kuyruk alanından çıkarılırken bir hata oluştu.",
            "operationInsertFailRs": "Sürücü mevcut değil veya kullanılamıyor.",
            "versionInsertFailRs": "Bu özelliği uygulamak için sürücünün sürücü uygulamasını en son sürüme yükseltmesi gerekiyor.",
            "queueInsertFailRs": "Kuyruk mevcut değil veya sürücü kuyruk alanında değil.Lütfen daha sonra tekrar deneyin.",
            "vehicleInsertFailRs": "Kuyruk, araç türünü desteklemez.",
            "orderInsertFailRs": "# Sipariş, kuyruktaki toplam sürücü sayısından daha büyük olamaz.",
            "positionInsertFailRs": "Kuyruk alanına sürücü eklenirken bir hata oluştu.",
            "insertDriverSuccess": "Sürücü kuyruk alanına başarıyla yerleştirildi.",
            "changeOrderSuccess": "Sürücünün pozisyonu başarıyla değiştirildi.",
            "insertDriverFail": "Kuyruk alanından sürücü eklenirken bir hata oluştu.",
            "changeOrderFail": "Sürücünün konumunu kuyruk alanından değiştirirken bir hata oluştu.",
            "selectToInsert": "Arama: Sürücü Adı, Telefon Numarası",
            "updateBy": "Operatör tarafından güncellendi",
            "changeOrder": "Siparişi Değiştir",
            "remove": "Kaldırmak"
        },
        "keepDriverPlaceCancelNoShow": "Rezervasyon iptal edildiğinde sürücünün yerini tut / gösterişsiz",
        "keepDriverPlaceUnavailable": "Sürücü mevcut olmadığında sürücünün yerini tutun",
        "range": "Kuyruk aralığı",
        "queueRangeTooltip": "Sürücüler, sürücü uygulamasında kuyruk pozisyon numaralarını böyle görecekler.Örneğin:\n- Kuyruk aralığını ayarlama 1: Sürücüler kuyruktaki konumlarını tek bir sayı olarak görecektir: 1, 2, 3, 4, vb.\n-Kuyruk aralığını ayarlama 5: Sürücüler kuyruktaki konumlarını aralık olarak görecekler: 1-5, 6-10, 11-15, 16-20, vb.\n-Kuyruk aralığını ayarlama 10: Sürücüler kuyruktaki konumlarını bir aralık olarak görecekler: 1-10, 11-20, 21-30, 31-40, vb.",
        "queueCanceledTooltip": "- Yolcu iptal ettiğinde veya gösterişsiz olduğunda, sürücü ayar süresi içinde kuyruğa geri dönerse sürücü en üst pozisyonda kalacaktır.\n- Sürücü kısa bir rezervasyon tamamladıktan sonra ayar süresi içinde kuyruğa geri dönerse, sürücünün konumu yüksek öncelik olarak belirlenir.",
        "DELETE_QUEUING_AREA": "Kuyruk alanını sil",
        "DELETE_CONFIRM": "Kuyruk alanını silmek ister misiniz?",
        "EDIT_QUEUING_AREA": "Kuyruk alanını düzenle",
        "ADD_QUEUING_AREA": "Kuyruk Alanı Ekle",
        "DETAIL_QUEUING_AREA": "Detay kuyruğu alanı",
        "Queuing_area": "Kuyruk alanı",
        "Address": "Adres",
        "Enter_a_location": "Bir konum girin",
        "Entered_Address_is_incorrect": "Sistem konumunuzu belirleyemez.Lütfen önerilen adresi kullanın.",
        "Not_supported_zone": "Bu kuyruk alanı desteklenen bölgede değil",
        "Active_mDispatcher_list": "Aktif Ortak Listesi",
        "Queue_list": "Kuyruk listesi",
        "Allow_radius": "Radius'a İzin Ver",
        "Min_drivers_required": "# Min sürücüler gerekli",
        "Open_queuing_area": "Açık Kuyruk Alanı",
        "Auto_dispatch": "Kuyruktaki sürücülere otomatik gönderme (Ortak uygulamasını kullanmanıza gerek yok)",
        "Active_car_list": "Aktif Araba Listesi",
        "mDispatcher": "Ortak",
        "Google_Address": "Google Adresi",
        "Status": "Durum",
        "Update_status_error": "Durum hatasını güncelle",
        "driver_checkin_area": "Sürücü check-in alanı",
        "EDIT_POLYGONS": "Çokgenleri Düzenle",
        "pax_pickup_area": "Yolcu alma alanı",
        "pickup_area": "Pikap alanı",
        "your_chosen_polygon": "Seçtiğiniz çokgen",
        "other_queues": "Diğer Kuyruklar",
        "operation_zones": "Operasyon Bölgeleri",
        "delete_pickup_area_confirm": "Bu pick-up alanını silmek istediğinizden emin misiniz?",
        "delete_pickup_area": "Pick-up alanını sil",
        "please_choose_driver_check_in_area": "Lütfen sürücü check-in alanını seçin.",
        "please_choose_pick_up_area": "Lütfen pick-up alanını seçin.",
        "Load_queuing_area_fail": "Kuyruk alanı yüklenirken hatalar meydana geldi",
        "Create_queuingArea_success": "Kuyruk alanı başarıyla yaratıldı",
        "Update_queuingArea_success": "Kuyruk alanı başarıyla güncellendi",
        "Delete_queuingArea_success": "Kuyruk alanı başarıyla silindi",
        "Delete_queuingArea_fail": "Kuyruk alanı silinirken hatalar meydana geldi",
        "Create_queuingArea_fail": "Kuyruk alanı oluştururken hatalar meydana geldi",
        "Load_queuingArea_fail": "Kuyruk alanı yüklenirken hatalar meydana geldi",
        "Update_queuingArea_fail": "Kuyruk alanını güncellerken hatalar meydana geldi"
    },
    "appBanner": {
        "Save": "Kaydetmek",
        "Pax_app": "PAX uygulaması",
        "Apply_to": "Başvurmak",
        "Please_select_view": "Lütfen görüntüyü seçin",
        "Select_Type": "Lütfen Banner Türü'nü seçin",
        "Home_view": "Ana görüş",
        "Other_view": "Diğer Görünüm",
        "Banner_1": "Banner #1",
        "Upload_an_image": "Görüntü seçilmedi",
        "Action": "Aksiyon",
        "NA": "N/A",
        "Link_to_website": "Web sitesine bağlantı",
        "Make_a_call": "Aranmak",
        "Send_an_email": "E -posta Gönder",
        "Banner_2": "Banner #2",
        "Banner_3": "Banner #3",
        "Driver_app": "Sürücü uygulaması",
        "pax": "Yolcu uygulaması",
        "driver": "Sürücü uygulaması",
        "Name": "İsim",
        "viewType": "Tip",
        "status": "Durum",
        "image": "İmaj",
        "html": "HTML",
        "Delete_Banner": "Banner'ı Sil",
        "Delete_confirm_banner": "Seçilen afişi silmek istediğinizden emin misiniz?",
        "Delete_banner_success": "Banner başarıyla silindi",
        "Delete_banner_fail": "Banner silinirken hatalar meydana geldi",
        "edit_banner": "Banner'ı düzenle",
        "detail_banner": "Detay afişi",
        "create_banner": "Banner Ekle",
        "note_1": "* Dosya uzantısı .jpg, .jpeg, .png, .gif ve %{limit} mb'den az olmalıdır.",
        "note_2": "* Görüntüler 16: 9 en boy oranı olmalıdır.",
        "note_3": "* Gelen kutusu, referans arkadaş, makbuz, rezervasyon, promosyon kodu, kredi kartı/ödeme yöntemleri ekleyin, profil, süreç/onaylanmış rezervasyon, tamamlanmış görünüm gibi diğer görünümler.",
        "Update_app_banner_success": "App Banner başarıyla güncellendi",
        "Update_app_banner_fail": "Uygulama afişini güncelleyemedi!",
        "Create_app_banner_success": "App Banner başarıyla yaratıldı",
        "Create_app_banner_fail": "Uygulama afişini oluşturamadı!",
        "Note": "Daha iyi uygulama performansı için lütfen dosyayı .png formatında (şeffaf arka plan, 400: 200px boyutunda) kullanın",
        "Limit_size_upload": "Dosya boyutu 8MB'den az olmalıdır.",
        "display_duration": "Ekran Süresi",
        "playback_settings": "Oynatma Ayarları",
        "display_banner_again_after": "Sonra tekrar pankartlar sergileyin",
        "stop_display_banner_after": "Daha sonra ekran afişini durdurun",
        "enable_playback": "Oynatmayı Etkinleştir",
        "Update_playback_success": "Playback ayarları başarıyla güncellendi",
        "Update_playback_fail": "Oynatma ayarlarını güncelleyemedi!",
        "language": "Dil",
        "single": "Tek dil",
        "multi": "Çok dil",
        "es-ES": "İspanyol",
        "en-US": "İngilizce",
        "fr-FR": "Fransızca",
        "id-ID": "Bahasa Endonezya",
        "vi-VN": "Vietnam",
        "ur-PK": "Urdu",
        "ms-MY": "Malezya",
        "ar-AR": "Arapça",
        "nb-NO": "Norveççe",
        "en-GB": "İngilizce (Birleşik Krallık)",
        "am-ET": "Amharik",
        "ro-RO": "Romanya",
        "version": "Uygulama sürümü",
        "comparisonVersionApp": {
            "is": "Ki",
            "is_not": "Değil",
            "is_one_of": "Biri",
            "is_not_one_of": "Biri değil",
            "greater_or_equal": "Daha büyük veya eşit",
            "less_or_equal": "Daha az veya eşit"
        },
        "platform": "Platform",
        "platformItem": {
            "all": "Tüm",
            "iOS": "iOS",
            "android": "Android"
        },
        "userStatus": "Kullanıcı durumu",
        "Active": "Aktif",
        "Inactive": "Aktif olmayan",
        "in_review_completed": "İncelemede (profil tamamlandı)",
        "in_review_incomplete": "İncelemede (profil eksik)",
        "in_review_inProgress": "İncelemede (devam eden profil)",
        "zones": "Bölgeler"
    },
    "reservation": {
        "name": "Rezervasyon",
        "company": "Şirket",
        "carType": "Araba tipi",
        "licensePlate": "Plaka: %{name}",
        "driver": "Sürücü: %{name}",
        "showAllDrivers": "Tüm Sürücüleri Göster",
        "available": "Mevcut",
        "reserved": "Rezerve",
        "search_holder": "Şirket, sürücü adı, araba türü, plaka",
        "all": "Tüm",
        "today": "Bugün",
        "thisWeek": "Bu hafta",
        "day": "Günlük",
        "week": "Haftalık",
        "bookingID": "Rezervasyon kimliği",
        "tripId": "Gezi kimliği",
        "pickupTime": "Pikap zamanı",
        "pickupLocation": "Pikap Konumu",
        "monday": "Pazartesi",
        "tuesday": "Salı",
        "wednesday": "Çarşamba",
        "thursday": "Perşembe",
        "friday": "Cuma",
        "saturday": "Cumartesi",
        "sunday": "Pazar",
        "overlapped": "Örtüşen",
        "noData": "Veri bulunamadı.",
        "bookingDetails": "Rezervasyon Ayrıntıları",
        "reservedHint": "Rezervasyon rezervasyonları onaylandı",
        "availableHint": "Onaylanmış rezervasyon rezervasyonları yok",
        "overlappedHint": "İki veya daha fazla onaylanmış rezervasyon rezervasyonu örtüşüyor"
    },
    "dashboard": {
        "API": "API",
        "cc": "CC",
        "dmc": "Rezervasyon kurulu",
        "streetSharing": "Sokak paylaşımı",
        "kiosk": "Köşk",
        "mDispatcher": "Ortak",
        "paxApp": "PAX uygulaması",
        "webBooking": "Web rezervasyonu",
        "carHailing": "Araba",
        "acceptance": "Kabul",
        "booking_com": "Booking.com",
        "completed": "Tamamlanmış",
        "noShow": "Gösteri Yok",
        "canceled": "İptal edilmiş",
        "incident": "Olay",
        "rate1": "1 Yıldız",
        "rate2": "2 Yıldız",
        "rate3": "3 Yıldız",
        "rate4": "4 Yıldız",
        "rate5": "5 Yıldız",
        "Export_to_Excel": "Excel'e İhracat",
        "Profit": "Kâr",
        "day_change": "Günlük Değişim",
        "week_change": "Haftalık Değişim",
        "month_change": "Aylık Değişim",
        "Booking_requests": "Rezervasyon istekleri",
        "Complete_bookings": "Tamamlanan rezervasyonlar",
        "New_customers": "Yeni Müşteriler",
        "Booking_dashboard": "Rezervasyon kurulu",
        "others": "Diğerleri",
        "Revenue_booking": "Kâr ve Bitmiş Rezervasyon",
        "Profit_finish_booking": "Kâr ve Bitmiş Rezervasyon",
        "Booking_request": "Rezervasyon Talebi",
        "Finished_booking": "Bitmiş rezervasyon",
        "failed": "Arızalı",
        "partialCompleted": "Kısmi tamamlandı",
        "Finished_booking_char_header": "Bitmiş rezervasyon ({0})",
        "Bookings": "Rezervasyonlar ({0})",
        "Ratings": "Derecelendirmeler ({0})",
        "Daily": "Günlük",
        "Weekly": "Haftalık",
        "Monthly": "Aylık",
        "Total_customer": "Toplam müşteri {0}",
        "ProfitToolTips": "Para Filosu tüm masrafları hariç tuttuktan sonra yapar",
        "RevenueToolTips": "Para filosu aslında seçilen dönemde, tanıtım, sürücüler için kesintiler, vergi vb.",
        "ProfitChangeToolTips": "Filo karındaki önceki döneme kıyasla değişimin yüzdesi",
        "BookingRequestsToolTips": "Seçilen dönemde oluşturulan toplam isteğe bağlı rezervasyon ve rezervasyon sayısı",
        "PercentageOfAcceptanceToolTips": "Seçilen dönemde kabul edilen rezervasyonların yüzdesi",
        "BookingChangeHint": "Önceki döneme kıyasla oluşturulan rezervasyonlardaki değişimin yüzdesi",
        "CompletedBookingHint": "Seçilen dönemde tamamlanan toplam isteğe bağlı rezervasyon ve rezervasyon sayısı",
        "PercentageOfSatisfactionHint": "Seçilen dönemde memnuniyet yüzdesi",
        "CompleteBookingDailyChangeHint": "Önceki döneme kıyasla tamamlanmış rezervasyonlardaki değişimin yüzdesi",
        "NewCustomersHint": "Seçilen dönemde kayıtlı toplam yeni müşteri sayısı",
        "CustomerChangeHint": "Müşterilerdeki değişimin önceki döneme kıyasla sayısı ve yüzdesi",
        "TotalCustomer": "Sistemdeki toplam müşteri sayısı",
        "ProfitAndFinishedBookingHint": "Seçilen dönemde Filo'nun kar ve bitmiş rezervasyonları",
        "BookingRequestHint": "Seçilen dönemde oluşturulan toplam isteğe bağlı rezervasyon ve rezervasyon sayısı",
        "FinishedBookingChartsHint": "İptal, gösteri yok, olay ve tamamlanan dahil olmak üzere seçilen dönemde kapalı olan toplam isteğe bağlı rezervasyon ve rezervasyon sayısı",
        "Customer_count": "{0} Müşteri Sayısı",
        "Revenue": "Hasılat"
    },
    "referral": {
        "first_package": "1. paket (yani PAX'ın ilk tamamlanmış rezervasyonu)",
        "driver_refers_to_pax": "Sürücü müşteriyi ifade eder",
        "driver_will_get": "Sürücü alacak",
        "money": "Para",
        "amount": "Miktar",
        "percent": "Yüzde",
        "settlement": "Komisyon (tamamlanmış her rezervasyonun% subtotal miktarı)",
        "pax_will_get": "Pax alacak",
        "promotion_code": "Promosyon kodu",
        "select_a_promotion": "Bir Promosyon Kodu Seçin",
        "maximum": "Maksimum",
        "second_package": "2. Paket",
        "from": "İtibaren",
        "to": "İle",
        "After_2nd_package": "2. paketten sonra",
        "from_end_value_of_above_package": "Yukarıdaki son paketin değerinden + 1",
        "Expiration_date": "Son kullanma tarihi",
        "Never": "Asla",
        "Customized_date": "Özelleştirilmiş tarih",
        "limited": "Sınırlı",
        "unlimited": "Sınırsız",
        "Valid_from": "Geçerli",
        "Valid_to": "Geçerli",
        "Invitation_limit": "Hakem limiti/sürücü",
        "Status": "Durum",
        "Active": "Aktif",
        "Inactive": "Aktif olmayan",
        "note": "* Kampanya etkin olduğunda, ödül değerleri hakkındaki herhangi bir güncellemenin yalnızca yeni müşterileri etkileyeceğini lütfen unutmayın.",
        "Required_fields": "Gerekli Alanlar",
        "refferal_update_successfully": "Tavsiye ayarı başarıyla güncellendi",
        "distributeIncentiveToDriver": "Sürücüye teşviki dağıtın",
        "Immediate": "Hemen",
        "Periodic": "Periyodik (manuel olarak)",
        "Period": "Dönem",
        "startDate": "Başlangıç ​​Tarihi",
        "nextDistributionDate": "Sonraki Dağıtım Tarihi",
        "oneMonth": "1 ay",
        "threeMonth": "3 ay",
        "sixMonth": "6 ay",
        "passenger_refers_to_pax": "Müşteri müşteri",
        "referer_will_get": "Referans alacak",
        "referee_will_get": "Hakem alacak",
        "promo": "Terfi",
        "Pax_invitation_limit": "Davet Sınırı/Kullanıcı",
        "promoPaidBy": "Tarafından ödenen promosyon",
        "fleet": "Filo",
        "driver": "Sürücü",
        "promoCode": "Promosyon kodu",
        "isSendInbox": "GÖNDER GÖNDER",
        "isDefaultCode": "Uygulamada Otomatik Uygula",
        "autoApplyOnWeb": "Web'de Otomatik Uygula",
        "isDefaultCode_hint": "Müşteri listesine promosyon kodu ekleyin ve müşteri sipariş oluşturduğunda rezervasyon için otomatik uygulayın",
        "Actions": "Eylem",
        "add_promoCode": "Eklemek",
        "Send_an_in_box_automatically_to_new_customers": "Müşteriye gelen kutusunu gönderin",
        "set_default_promo_code": "Uygulamada Otomatik Uygula",
        "EDIT_PROMO_CODE": "Promosyon Kodunu Düzenle",
        "DETAIL_PROMO_CODE": "Promosyon Kodu Ayrıntıları",
        "CREATE_PROMO_CODE": "Promosyon kodu oluştur",
        "DELETE_PROMO_CODE": "Promosyon Kodunu Sil",
        "DELETE_CONFIRM_PROMO_CODE": "Bu promosyon kodunu silmek istiyor musunuz?",
        "ERROR_INPUT_SOURCE": "Lütfen en az bir tane seçin."
    },
    "upload_document": {
        "NO_FILE_CHOSEN": "Dosya seçilmedi",
        "Limit_size_upload": "Dosya boyutu, izin verilen limiti aşar {0} MB."
    },
    "payment_info": {
        "member_since": "Üye",
        "package_name": "Paket adı",
        "last_payment": "Son ödeme",
        "credit_card": "Kredi kartı",
        "last_digits": "Son 4 hane",
        "default": "Varsayılan",
        "add_new_card": "Yeni kart ekle",
        "history": "Tarih",
        "transaction_date": "İşlem tarihi",
        "transaction_id": "İşlem kimliği",
        "billing_cycle": "Faturalandırma döngüsü",
        "amount": "Miktar",
        "payment_history": "Faturalandırma geçmişi",
        "card_information": "Kart bilgileri",
        "card_holder": "Kart sahibi",
        "card_number": "Kart numarası",
        "expiration_date": "Son kullanma tarihi",
        "cvv": "CVV"
    },
    "search_by": {
        "group_id": "Grup kimliği",
        "booking_id": "Rezervasyon #",
        "recurring_id": "Tekrarlayan #",
        "ExternalID": "Harici #",
        "tripId": "Seyahat #",
        "driver_name": "Sürücü adı",
        "driver_phone_number": "Sürücü Telefon Numarası",
        "license_plate": "Plaka",
        "vehicle": "Araç",
        "operator": "Operatör",
        "passenger_email": "Yolcu e -postası",
        "passenger_name": "Yolcu adı",
        "passenger_phone_number": "Yolcu telefon numarası",
        "destination": "Varış noktası",
        "pick_up": "Toplamak",
        "flight_number": "Uçuş #",
        "book_from": "Rezervasyon",
        "note": "Not",
        "email": "E -posta",
        "phone": "Telefon"
    },
    "withdrawal": {
        "transferToEWallet": "Transferred to external e-wallet *{0}",
        "channelCode": "Channel code",
        "channelName": "Channel name",
        "reversed": "Reversed",
        "requestTime": "Talep Zamanı",
        "referenceId": "Referans #",
        "driverName": "Sürücü adı",
        "phoneNumber": "Telefon numarası",
        "company": "Şirket",
        "description": "Tanım",
        "requestAmount": "Talep Tutarı",
        "transferAmount": "Transfer miktarı",
        "currentBalance": "Mevcut bakiye",
        "currency": "Para birimi",
        "action": "Aksiyon",
        "approve": "Onaylamak",
        "reject": "Reddetmek",
        "transferToBankAccount": "Banka hesabına aktarıldı *{0}",
        "transferToWingBank": "Kanat bankası kimliğine aktarıldı *{0}",
        "transferToCreditWallet": "Kredi cüzdanına aktarıldı",
        "reason": "Sebep",
        "search": "Sürücü Adı, Telefon, Şirket, İşlem Kimliği, Referans #",
        "message": {
            "approve": "Bu sürücüye bir miktar {0} ödemek istediğinden emin misiniz?",
            "approveMulti": "Seçim sürücülerini ödemek istediğinizden emin misiniz?",
            "approveSuccess": "Seçilen istek ödendi",
            "approveFail": "Seçilen istek başarılı değildi.Lütfen tekrar deneyin.",
            "approveMultiSuccess": "{0} {1} seçilen istek (ler) i ödenmiştir.",
            "approveMultiFail": "{1} Seçilen istek (ler) {0} başarılı olmadı.Lütfen tekrar deneyin.",
            "buttonConfirmTitle": "Evet",
            "closeButtonText": "HAYIR",
            "pleaseInputReason": "Lütfen neden girin",
            "rejectSuccess": "Sürücünün isteği reddedildi",
            "rejectFail": "Seçilen talebi reddedemiyor"
        },
        "getDataFailure": "Veri alamıyorum",
        "rejectRequest": "Reddetme isteği"
    },
    "dynamicSurcharge": {
        "operationZone": "Operasyon bölgesi",
        "activeSurcharge": "Aktif ek ücret",
        "surcharge": "Fazla yüklemek",
        "surchargeName": "Ek ücret adı",
        "parameter": "Parametre (örn. 1.x)",
        "pickupPoint": "Pikap bölgesi",
        "dropOffPoint": "Bırakma bölgesi",
        "delete_surcharge_success": "Ek ücret başarıyla silindi",
        "delete_surcharge_fail": "Hatalar ek ücret silinirken meydana geldi",
        "update_surcharge_fail": "Hatalar ek ücret güncellenirken meydana geldi",
        "update_surcharge_success": "Ek ücret başarıyla güncellendi",
        "create_surcharge_fail": "Hatalar ek ücret oluştururken meydana geldi",
        "create_surcharge_success": "Ek ücret başarıyla yaratıldı",
        "active_surcharge_fail": "Hatalar, ek ücret etkinleştirilirken meydana geldi",
        "active_surcharge_success": "Ek ücret başarıyla etkinleştirildi",
        "deactive_surcharge_fail": "Hatalar ek ücret devre dışı bırakılırken meydana geldi",
        "deactive_surcharge_success": "Ek ücret başarılı bir şekilde devre dışı bırakıldı",
        "delete_surcharge": "Ek ücret silme",
        "delete_confirm_message": "Seçilen ek ücretini silmek istediğinizden emin misiniz?",
        "note": "*Not: Temel ücretinizin ne kadar çarpılacağıdır.Örnek: Parametre 1,5x olduğunda 20 dolarlık bir ücret 30 dolar olur"
    },
    "dynamicFare": {
        "operationZone": "Operasyon bölgesi",
        "activeFare": "Aktif yemek",
        "fare": "Ücret",
        "fareName": "Ücret",
        "factor": "Faktörle çarpın",
        "fix_amount": "Sabit miktar",
        "parameter": "Parametre (örn. 1.x)",
        "delete_fare_success": "Ücret başarıyla silindi",
        "delete_fare_fail": "Hatalar ücret silinirken meydana geldi",
        "update_fare_fail": "Ücreti güncellerken hatalar meydana geldi",
        "update_fare_success": "Ücret başarıyla güncellendi",
        "create_fare_fail": "Hatalar ücret oluştururken meydana geldi",
        "create_fare_success": "Ücret başarıyla yaratıldı",
        "active_fare_fail": "Ücreti etkinleştirirken hatalar meydana geldi",
        "active_fare_success": "Ücret başarıyla etkinleştirildi",
        "deactive_fare_fail": "Ücreti devre dışı bırakırken hatalar meydana geldi",
        "deactive_fare_success": "Ücret başarıyla devre dışı bırakıldı",
        "delete_fare": "Ücreti Sil",
        "delete_confirm_message": "Seçilen ücreti silmek istediğinizden emin misiniz?",
        "note": "*Not: Temel ücretinizin ne kadar çarpılacağıdır.Örnek: Parametre 1,5x olduğunda 20 dolarlık bir ücret 30 dolar olur",
        "note_amount": "*Not: Temel ücretinizin ne kadar ekleneceği.Örnek: Ayarlanan tutar 5 $ olduğunda 20 dolarlık bir ücret 25 dolar olur"
    },
    "notifications": {
        "search": "Ara rezervasyon kimliği, harici kimlik",
        "searchProviderName": "Arama Sağlayıcı Adı",
        "statusItem": {
            "all": "Tüm Bildirimler",
            "canceledByDriver": "Taşımacılık - Sürücü tarafından iptal edildi",
            "canceledByPartner": "Satış Ortağı - Ortak tarafından iptal edildi",
            "reservationReminder": "Rezervasyon Hatırlatıcısı",
            "localPending": "Yerel - Beklemede",
            "reservationIsNotStarted": "Rezervasyon başlatılmadı",
            "partnerRequestsCars": "Ortak Talep Araçları",
            "timeout": "Zaman aşımı",
            "noShow": "Satış Ortağı - Gösteri Yok",
            "affiliatePending": "Satış Ortağı - Beklemede",
            "affiliatePaymentFailed": "Satış Ortağı Ödemesi Başarısız Oldu",
            "affiliateTimeout": "Ortaklık Zaman Aşımı",
            "flightApi": "Uçuş API",
            "DebtWriteOff": "Borçlanma",
            "driverCancelTrip": "Interity - Sürücü tarafından iptal edildi",
            "minimumSeatRequired": "Minimum koltuklar gerekli",
            "newFarmInJobFrom3rd": "Booking.com - Yeni rezervasyon",
            "canceledJobBy3rd": "Booking.com - İptal edildi",
            "updatedJobBy3rd": "Booking.com - Güncellendi",
            "newFarmInJobFromHolidayTaxis": "HolidayTaxis - Yeni Rezervasyon",
            "canceledJobByHolidayTaxis": "HolidayTaxis - İptal edildi",
            "updatedJobByHolidayTaxis": "HolidayTaxis - Güncellendi",
            "newFarmInJobFromKarhoo": "Karhoo - Yeni Rezervasyon",
            "canceledJobByKarhoo": "Karhoo - İptal edildi",
            "updatedJobByKarhoo": "Karhoo - Güncellendi",
            "newFarmInJobFromHoppa": "Hoppa - New booking",
            "canceledJobByHoppa": "Hoppa - Canceled",
            "updatedJobByHoppa": "Hoppa - Updated"
        },
        "sos": {
            "sosSearch": "Ara rezervasyon kimliği, PAX adı, sürücü adı",
            "statusItem": {
                "all": "Tüm",
                "driver": "Sürücüler",
                "passenger": "Yolcular",
                "crew": "Mürettebat"
            }
        }
    },
    "smartData": {
        "No": "HAYIR.",
        "driverName": "İsim",
        "phone": "Telefon",
        "rating": "Derecelendirme",
        "completed": "Tamamlanmış",
        "noShow": "Gösterişsiz",
        "incident": "Olay",
        "canceled": "Sürücü tarafından iptal edildi",
        "accepted": "Kabul edilmiş",
        "reject": "Reddetmek",
        "ignore": "Görmezden gelmek",
        "earning": "Kazanç",
        "query": {
            "top20": "Top 20",
            "top50": "İlk 50",
            "top100": "Top 100",
            "bottom20": "Alt 20",
            "bottom50": "Alt 50",
            "bottom100": "Alt 100"
        },
        "status": {
            "canceled": "Sürücü tarafından iptal edildi",
            "noShow": "Gösterişsiz",
            "incident": "Olay",
            "completed": "Tamamlanmış",
            "earning": "Kazanç",
            "reject": "Reddetmek",
            "ignore": "Görmezden gelmek",
            "accepted": "Kabul edilmiş"
        }
    },
    "Reject": "Gösteri Yok",
    "Do not allow Driver to start reservat": "Bağlılık",
    "intercityRate": {
        "Delete_intercity_rate": "Şehirlerarası oranı sil",
        "edit_intercity_rate": "Şehirlerarası oranını düzenle",
        "view_intercity_rate": "Şehirlerarası oranını görüntüle",
        "add_intercity_rate": "Şehirlerarası oranı ekle",
        "Delete_confirm_intercity_rate": "Bu öğeyi silmek istiyor musunuz?",
        "Update_intercity_rate_success": "Şehirlerarası oranı başarıyla güncellendi",
        "Update_intercity_rate_fail": "Şehirlerarası oranı güncellenirken hatalar meydana geldi",
        "Created_intercity_rate_success": "Şehirlerarası oranı başarıyla yaratıldı",
        "Created_intercity_rate_fail": "Şehirlerarası oranı oluşturulurken meydana geldi",
        "Update_intercity_rate_route_success": "Şehirlerarası oranı rotası başarıyla güncellendi",
        "Update_intercity_rate_route_fail": "Etkileşim Yolları Güzergahı Güncellenirken Hatalar meydana geldi",
        "Created_intercity_rate_route_success": "Şehirlerarası oranı rotası başarıyla yaratıldı",
        "Created_intercity_rate_route_fail": "Hatalar şehirlerarası rota oluştururken meydana geldi",
        "Loading_intercity_rate_fail": "Hatalar şehirlerarası oranı detayı yüklenirken meydana geldi",
        "Delete_intercity_rate_route_fail": "Hatalar şehirlerarası oranı rotası silinirken meydana geldi",
        "Delete_intercity_rate_success": "Şehirlerarası oranı başarıyla silindi",
        "Delete_intercity_rate_fail": "Etkileşim oranı silinirken hatalar meydana geldi"
    },
    "intercityRoute": {
        "alias": "Takma ad",
        "routeNameHint": "Rota adını değiştirmek için lütfen ayarlar> Bölge> şehirlerarası ve şehir bölgelerinin adını buna göre değiştirin.",
        "pricePerSeat": "Koltuk başına fiyat (vergi hariç)",
        "distance": "Mesafe",
        "duration": "Süre",
        "cancellationPenalty": "Koltuk başına ceza tutarı",
        "noShow": "PAX/Partner Gösteri Yok",
        "penalty": "Koltuk başına ceza tutarı",
        "no_show_tooltip": "Müşteri tutarı koltuk başına ücretlendirilecektir.Cezaya ek olarak, müşteri uygulanırsa vergi veya teknoloji ücreti ödeyecektir",
        "firstLocation": "İlk Konum",
        "secondLocation": "İkinci Konum",
        "routeOneSetup": "Kurulum rotası 1",
        "routetwoSetup": "Kurulum Rotası 2",
        "schedules": "Programlar",
        "date": "Tarih",
        "time": "Zaman",
        "editSchedule": "Düzenleme Çizelgesi",
        "addSchedule": "Program ekle",
        "requestTimeEnable": "Müşterinin istek zamanı ayarlamasına izin verin",
        "extraFee": "Ekstra ücret",
        "hideRouteEnable": "Bu rotayı gizle",
        "timeRange": "Zaman aralığı",
        "workingTime": "Çalışma süresi",
        "applyForOtherDays": "Diğer günlere başvurun",
        "limitSeatLuggage": "Koltuk / bagajı sınırlayın",
        "warningMinimumSeats": "Minimum koltuklar gerekli",
        "warningMinimumSeatsHint": "Bir yolculuk yapmak için gereken minimum koltuk sayısı",
        "allowStartLessMinRequiredSeats": "Minimum koltuk gereksinimine bakılmaksızın yolculuğa başlamak için sürücü",
        "allowStartLessMinRequiredSeatsHint": "Talep edilen koltuk sayısı gereken minimum koltuktan daha az olsa bile sürücünün yolculuğa başlamasına izin verin",
        "freeLuggagePerSeat": "Koltuk başına ücretsiz bagaj",
        "freeLuggagePerSeatHint": "Koltuk başına dahil ücretsiz bagaj sayısı",
        "allowToAddExtraLuggage": "Ek bagaj ekleyin",
        "allowToAddExtraLuggageHint": "Yolcunun ek bagajlar eklemesine izin verin",
        "feePerExtraLuggage": "Ekstra bagaj başına ücret",
        "Delete_intercity_route": "Şehirlerarası Güzergahı Sil",
        "Delete_confirm_intercity_route": "Bu rotayı silmek ister misiniz?",
        "Update_intercity_route_fail": "Etkileşim Yolları Güzergahı Güncellenirken Hatalar meydana geldi",
        "Update_intercity_route_success": "Intercity rotası başarıyla güncellendi",
        "created_intercity_route_success": "Intercity rotası başarıyla yaratıldı",
        "created_intercity_route_fail": "Hatalar şehirlerarası rota oluştururken meydana geldi",
        "Delete_intercity_route_success": "Şehirlerarası rota başarıyla silindi",
        "Delete_intercity_route_fail": "Hatalar şehirlerarası rota silinirken meydana geldi",
        "penalty_less_than_or_equal_to_price": "Lütfen koltuk değeri başına fiyattan daha az veya eşit bir değer girin.",
        "scheduleTimeOverlap": "Program süresi birbirleriyle örtüşmemelidir",
        "allows_adding_extra": "Filo tarafından planlanmayan müşteri istek süresi olduğunda ekstra ücret ekleme sağlar.",
        "this_is_to_limit_users": "Bu, kullanıcıları (yani müşteri, sürücü, operatör) bu rotada yeni rezervasyon veya gezi oluşturmakla sınırlamaktır.Mevcut rezervasyonlar veya geziler ayrıldığı gibi kaldı"
    },
    "dayOfWeek": {
        "0": "Pazar",
        "1": "Pazartesi",
        "2": "Salı",
        "3": "Çarşamba",
        "4": "Perşembe",
        "5": "Cuma",
        "6": "Cumartesi"
    },
    "intercityBooking": {
        "route": "Rota",
        "departureDate": "Kalkış tarihi",
        "departureTime": "Kalkış saati",
        "Departure": "Kalkış",
        "Arrival": "Varış",
        "RefundFare": "Geri ödeme ücreti",
        "intercityZoneNote": "Burada oluşturduğunuz bölgeler sadece şehirlerarası oranlarınızı ayarlamak için kullanılır.",
        "zoneNameHint": "Müşterilerinizin kolayca arama yapabilmeleri ve seçebilmesi için lütfen bölge adınızı kısa ve net tutun.",
        "zoneUploadNote_2": "* Görüntü çözünürlüğünün 1095x360 ve dosya boyutunun 5MB'den az olması önerilir.",
        "Complete_booking": "Tam Rezervasyon",
        "ConfirmCancel": "İptal et"
    },
    "deliveryRate": {
        "Delete_delivery_rate": "Teslimat oranını sil",
        "edit_delivery_rate": "Teslimat Hızını Düzenle",
        "view_delivery_rate": "Teslimat oranını görüntüle",
        "add_delivery_rate": "Teslimat oranı ekle",
        "Delete_confirm_delivery_rate": "Bu öğeyi silmek istiyor musunuz?",
        "Update_delivery_rate_success": "Teslimat oranı başarıyla güncellendi",
        "Update_delivery_rate_fail": "Teslimat oranını güncellerken hatalar meydana geldi",
        "Created_delivery_rate_success": "Teslimat oranı başarıyla oluşturuldu",
        "Created_delivery_rate_fail": "Hatalar, teslimat oranı oluştururken meydana geldi",
        "Update_delivery_rate_route_success": "Teslimat oranı rotası başarıyla güncellendi",
        "Update_delivery_rate_route_fail": "Teslimat rotasını güncellerken hatalar meydana geldi",
        "Created_delivery_rate_route_success": "Teslimat oranı rotası başarıyla oluşturuldu",
        "Created_delivery_rate_route_fail": "Hatalar, teslimat rotası oluştururken meydana geldi",
        "Loading_delivery_rate_fail": "Hatalar, teslimat hızı detayı yüklenirken meydana geldi",
        "Delete_delivery_rate_route_fail": "Hatalar, teslimat hızı rotası silinirken meydana geldi",
        "Delete_delivery_rate_success": "Teslimat oranı başarıyla silindi",
        "Delete_delivery_rate_fail": "Hatalar, teslimat hızı silinirken meydana geldi",
        "distance_km": "Km başına ücret",
        "distance_mile": "Mil başına ücret",
        "flat": "Sabit ücret",
        "startingFee": "Başlangıç ​​ücreti",
        "feeFirstDistance": "Ücret İlk mesafe",
        "feeSecondDistance": "Ücret ikinci mesafe",
        "feeThirdDistance": "Üçüncü mesafe",
        "feeAfterThirdDistance": "Üçüncü mesafeden sonra ücret",
        "feePerMinute": "Dakikada ücret",
        "feeMinimum": "Ücret Minimum",
        "feeFirstStop": "İlk Durak Ücreti",
        "feeExtraStop": "Her ekstra durak için ücret"
    },
    "pwaSettings": {
        "pwa": "Pwa",
        "iconName": "Simge ve isim",
        "logoBackgroundColor": "Logo, arka plan ve renk",
        "longName": "Uzun isim",
        "shortName": "Kısa isim",
        "icon192": "Ana Ekranda Simge (192x192)",
        "icon512": "Sıçrama ekranında simge",
        "logo": "Logo (300x300)",
        "logoMenu": "Logo menüsü (300x300)",
        "backgroundImage": "Arka Plan Görüntüsü - Taşıma (1024x500)",
        "backgroundImageDelivery": "Arka Plan Görüntüsü - Teslimat (1024x500)",
        "mainColor": "Ana renk",
        "secondaryColor": "İkincil renk",
        "showMainColor": "Arka plan yerine ana rengi göster",
        "success": "PWA ayarı başarıyla güncellendi",
        "failed": "PWA ayarını güncelleyemedi.Lütfen tekrar deneyin!",
        "errorUploadFileSize": "Gerekli boyutlar {0} x {0} px'dir.",
        "hideWBInstallAppBtn": "Uygulama Düğmesini Gizle",
        "enableBookNow": "Şimdi",
        "enableGuestCheckout": "Konuk Ödeme",
        "enableBookLater": "Daha sonra",
        "enableHideRecipient": "Alıcı Bilgilerini Gizle (Paket İçeriği, Teslim Talimatları)",
        "pwaLink": "Yolcu için PWA",
        "partnerLink": "Ortak için PWA",
        "pwaDriverLink": "PWA for Driver",
        "providerSignUp": "Sürücü kayıt bağlantısı",
        "AdsTracking": "Ads Tracking",
        "googleId": "Google Tag ID",
        "placeholdergoogleId": "Enter Google Tag ID",
        "metaId": "Meta Pixel ID",
        "placeholdermetaId": "Enter Meta Pixel ID"
    },
    "emailConfig": {
        "emailServer": "E -posta Sunucusu",
        "default": "Varsayılan",
        "customized": "Özelleştirilmiş",
        "fromName": "Adından",
        "email": "E -postadan",
        "replyTo": "Cevap vermek",
        "userAuth": "Kullanıcı adı",
        "password": "Şifre",
        "smtpServer": "SMTP Sunucusu",
        "port": "SMTP bağlantı noktası",
        "isAuthenticated": "Doğrulanmış",
        "option": "Etkinleştir seçeneği",
        "none": "None",
        "tls": "TLS",
        "ssl": "SSL",
        "save": "Kaydetmek",
        "cancel": "İptal etmek",
        "success": "E -posta Sunucusunu Güncelle Başarısı",
        "fail": "E -posta Sunucusunu Güncelleyin Başarısız",
        "loadFail": "E -posta Sunucusu Detayı Yükle"
    },
    "driverFields": {
        "Load_fail": "",
        "Edit": "Düzenlemek",
        "View": "Görüş",
        "Cancel": "İptal etmek",
        "show": "Göstermek",
        "hide": "Saklamak",
        "EDIT_DRIVER_FIELDS": "Sürücü alanlarını düzenle",
        "DETAIL_DRIVER_FIELDS": "Detay Sürücü Alanları",
        "ADD_DRIVER_FIELDS": "Sürücü Alanları Ekle",
        "fieldKey": "Fields Anahtarı",
        "fieldName": "Fields Adı",
        "availableCC": "Komuta merkezinde mevcut",
        "availableDrvSignUp": "Sürücü kayıtlarında mevcut",
        "availableCompanyCar": "Şirket Araba - Taşımacılık Sürücüleri",
        "deliveryCompany": "Şirket Araba - Teslimat Sürücüleri",
        "availableIndividualCar": "Bireysel Araba - Taşıma Sürücüleri",
        "deliveryIndividual": "Bireysel Araba - Teslimat Sürücüleri",
        "required": "Gerekli",
        "Update_fail": "Sürücü alanlarını güncellerken hatalar meydana geldi",
        "Update_success": "Sürücü alanları başarıyla güncellendi",
        "FieldKey": "Fields Anahtarı",
        "FieldName": "Fields Adı (Amerikan İngilizcesi)",
        "CommandCenter": "Komuta merkezinde mevcut",
        "SignUp": "Sürücü kayıtlarında mevcut",
        "CompanyTransport": "Şirket Araba - Ulaşım",
        "CompanyDelivery": "Şirket Araba - Teslimat",
        "IndividualTransport": "Bireysel Araba - Taşımacılık",
        "IndividualDelivery": "Bireysel Araba - Teslimat",
        "Require": "Gerekli",
        "Actions": "Eylem",
        "fieldType": "Alan Türü",
        "default": "Varsayılan",
        "customized": "Özelleştirilmiş"
    },
    "driverDocument": {
        "Load_fail": "",
        "Edit": "Düzenlemek",
        "View": "Görüş",
        "Cancel": "İptal etmek",
        "show": "Göstermek",
        "hide": "Saklamak",
        "EDIT_DRIVER_DOCUMENT": "Sürücü Belgesini Düzenle",
        "DETAIL_DRIVER_DOCUMENT": "Detay Sürücü Belgesi",
        "ADD_DRIVER_DOCUMENT": "Sürücü Belgesi Ekle",
        "documentKey": "Belge anahtarı",
        "documentName": "Belge adı",
        "documentNameDetail": {
            "zh-HK": "Geleneksel Çince",
            "it-IT": "Standart İtalyan",
            "es-ES": "İspanyol",
            "en-US": "Amerikan İngilizcesi",
            "fr-FR": "Standart Fransızca",
            "zh-CN": "Basitleştirilmiş Çince",
            "id-ID": "Bahasa Endonezya",
            "vi-VN": "Vietnam",
            "ur-PK": "Urduca (Pakistan İslam Cumhuriyeti)",
            "ms-MY": "Malay (Malezya)",
            "ar-AR": "Arapça",
            "en-GB": "İngilizce (Birleşik Krallık)",
            "nb-NO": "Norveççe",
            "am-ET": "Amharik",
            "ro-RO": "Romanya",
            "th-TH": "Tayland",
            "ta-IN": "Tamil",
            "hi-IN": "Hintçe",
            "te-IN": "Telugu",
            "ka-GE": "Gürcü",
            "ru-RU": "Rusça",
            "kn-IN": "Kannada"
        },
        "isIndividual": "Şirket Araba - Taşımacılık Sürücüleri",
        "deliveryCompany": "Şirket Araba - Teslimat Sürücüleri",
        "isOwner": "Bireysel Sürücü - Taşıma Sürücüleri",
        "deliveryIndividual": "Bireysel Sürücü - Teslimat Sürücüleri",
        "availableCC": "Komuta merkezinde mevcut",
        "availableDrvSignUp": "Sürücü kayıtlarında mevcut",
        "isRequired": "Gerekli",
        "isExpiredDate": "Son kullanma tarihi - Komuta Merkezi",
        "expiredDateForDrvSignUp": "Son kullanma tarihi - Sürücü Kayıt",
        "requireExpiredDate": "Son kullanma tarihi gerektirir",
        "requireExpiredDateForDrvSignUp": "Son kullanma tarihi gerektirir",
        "requireDocumentId": "Belge Kimliği Gerektirin",
        "documentId": "Belge Kimliği - Komut Merkezi",
        "documentIdForDrvSignUp": "Belge Kimliği - Sürücü Kaydı",
        "Update_fail": "Sürücü belgesini güncellerken hatalar meydana geldi",
        "Update_success": "Sürücü belgesi başarıyla güncellendi",
        "DocumentKey": "Belge anahtarı",
        "DocumentName": "Belge Adı (Amerikan İngilizcesi)",
        "CommandCenter": "Komuta merkezinde mevcut",
        "SignUp": "Sürücü kayıtlarında mevcut",
        "CompanyTransport": "Şirket Araba - Ulaşım",
        "CompanyDelivery": "Şirket Araba - Teslimat",
        "IndividualTransport": "Bireysel Araba - Taşımacılık",
        "IndividualDelivery": "Bireysel Araba - Teslimat",
        "Require": "Gerekli",
        "Actions": "Eylem"
    },
    "holidayTaxis": {
        "title": "HolidayTaxis API kimlik bilgileri",
        "note": "Sistemlerine bağlanmak için size API anahtarı sağlamak için HolidayTaxis'in teknik ekibiyle iletişim kurun.",
        "apiKey": "API Anahtarı",
        "placeHolderApiKey": "API anahtarını girin"
    },
    "trailNotes": {
        "maxLengthFiles": "Yorum başına maksimum 3 dosya",
        "maxSizeFiles": "Dosya başına maksimum 10 MB",
        "typeFiles": "Destek Türü Desteklenen: PDF, DOC, DOCX, CSV, XLS, XLSX, JPEG, JPG, PNG, WebP, TXT, NOT",
        "maxComments": "Rezervasyon başına maksimum 20 yorum",
        "bookingActivity": "Rezervasyon Etkinliği",
        "viewAll": "Hepsini görüntüle",
        "operator": "Operatör",
        "driver": "Sürücü",
        "passenger": "Müşteri",
        "pHInput": "Yeni bir yorum ekleyin.Notu kaydetmek için Kaydet'i tıklayabilirsiniz.Ayrıca buraya birden fazla dosyayı damla sürükleyebilirsiniz.",
        "addFiles": "Dosya ekle",
        "save": "Kaydetmek",
        "inputNotes": "Lütfen metin girin",
        "successDeleteTrailNotes": "İz notlarını başarıyla silin",
        "successCreateTrailNotes": "Trail Notes'u başarılı bir şekilde oluşturun",
        "deleteTrailNote": "Bir iz notunu sil",
        "msgDeleteTrailNote": "Bunu silmek istediğinden emin misiniz?"
    },
    "supplier": {
        "permissionRequired": "Operator need to select a permission",
        "offerDetail": "Offer Detail",
        "orderDetail": "Order Detail",
        "bookingNumber": "Booking Number",
        "date": "Date",
        "pickupLocation": "Pickup Location",
        "dropoffLocation": "Drop off Location",
        "passenger": "Passenger",
        "luggage": "Luggage",
        "pickupService": "Pickup Service",
        "pickupSignage": "Pickup Signage",
        "flightNumber": "Flight Number",
        "departure": "Departure",
        "arrival": "Arrival",
        "terminal": "Terminal",
        "gate": "Gate",
        "meetGreet": "Meet & Greet",
        "specialRequests": "Special Requests",
        "additionalServices": "Additional Services",
        "price": "Price",
        "prices": "Prices",
        "guest": "Guest",
        "serviceClass": "Service Class",
        "distance": "Distance",
        "duration": "Duration",
        "acceptOffer": "Accept Offer",
        "updateBooking": "Update Booking",
        "package": "Package",
        "route": "Route",
        "assignedChauffer": "Assigned Chauffeur",
        "assignedVehicle": "Assigned Vehicle",
        "logs": "Logs",
        "link": "Links",
        "desLink": "Click to copy link",
        "trackLink": "Track link",
        "driverLink": "Driver link",
        "close": "Close",
        "links": "Links",
        "linksDes": "Click to copy link",
        "status": "Status",
        "rating": "Rating",
        "permissions": "Permissions",
        "permissionsNote": "Assign different levels of authorization to your team. You can choose whether your operators have visibility of open offers or only assignments already accepted.",
        "owner": "Owner",
        "ownerNote": "Full Access",
        "manageUsers": "Manage Users",
        "manageUsersNote": "Add/remove new operators",
        "manageDrivers": "Manage Drivers",
        "manageDriversNote": "Add/remove drivers",
        "manageVehicles": "Manage Vehicles",
        "manageVehiclesNote": "Add/remove vehicles",
        "mapView": "Live Map",
        "mapViewNote": "Track drivers and active trips live on the map.",
        "calendarView": "Calendar View",
        "calendarViewNote": "View calendar of all assigned trips.",
        "bookingView": "Booking View",
        "bookingViewNote": "View and manage current bookings, assign drivers, and allocate vehicles.",
        "offersView": "Offers View",
        "offersViewNote": "Accept new job offers.",
        "pricing": "Pricing",
        "pricingNote": "View trip prices",
        "report": "Reports",
        "reportNote": "View booking and financial reports",
        "reportExport": "Export Reports",
        "reportExportNote": "Export reports to .csv",
        "userDetail": "User Detail",
        "addOperator": "Add Operator",
        "updateOperator": "Update Operator",
        "supplier": "Supplier",
        "zone": "Zone",
        "emails": "Emails",
        "email": "Email",
        "phoneNumber": "Phone Number",
        "Deactivate": "Deactivate",
        "Activate": "Activate",
        "ALERT_SELECT_DEACTIVATE": "Please select supplier to deactivate",
        "ALERT_SELECT_ACTIVATE": "Please select supplier to activate",
        "CONFIRM_DEACTIVATE_MULTI": "Do you want to deactivate these suppliers?",
        "CONFIRM_ACTIVATE_MULTI": "Do you want to activate these suppliers?",
        "Update_supplier_user_status_success": "Supplier status has been updated successfully",
        "Update_supplier_user_status_fail": "The errors occurred while updating supplier status",
        "agent": "Agent",
        "Supplier": "Supplier",
        "addSupplier": "Add Supplier",
        "detailSupplier": "Supplier Detail",
        "addUser": "Add User",
        "Add": "Add",
        "ContactInfo": "Contact Info",
        "policy": "Policy",
        "users": "Users",
        "setting": "Setting",
        "zones": "Zones",
        "actions": "Actions",
        "companyName": "Company Name",
        "corporationType": "Corporation Type",
        "country": "Country",
        "ADMIN_INFORMATION": "Admin Information",
        "firstName": "First Name",
        "lastName": "Last Name",
        "commissionValue": "Fleet commission",
        "commissionValueDes": "Set commissions the fleet earns for every complete order.",
        "SupplierType": "Supplier Type",
        "SupplierTypeDes": "Select a supplier type.",
        "paymentMethodsApplyDes": "Job specific payment methods that suppliers can process:",
        "bookingSettings": "Booking Settings",
        "bookingSettingsDes": "Configure job visibility, release times, and delays for efficient supplier operations.",
        "advanceBookingVisibilityTooltip": "Specify how many days in advance suppliers can see the orders.",
        "delayBroadcastToolTip": "Set a delay time from the release time if set or the start of the day (00:00). This delay controls when the jobs become visible to the suppliers after the initial release time.",
        "releaseTimeToolTip": "Set the time of day when jobs are released to suppliers, starting from the first day they can see bookings. Ensure this time allows suppliers enough preparation time.",
        "otherSettings": "Other Settings",
        "hideFare": "Driver - Always hide fare",
        "hideFareDes": "Hide the fare for all drivers of this supplier.",
        "supplierJobRequired": "Supplier Job Acceptance Required",
        "supplierJobRequiredDes": "Suppliers must acknowledge and accept jobs before they can assign a driver/vehicle or allow the job to be viewed by drivers in the marketplace.",
        "supplierApproval": "Suppliers Driver/Vehicle Approval",
        "supplierApprovalDes": "Allow supplier operators to activate drivers and vehicles within their fleet.",
        "emailsApply": "Email Notifications",
        "emailsApplyDes": "Emails to send new jobs, booking updates.",
        "address": "Address",
        "zipCode": "Zip/Postal Code",
        "city": "City",
        "state": "State/Province",
        "tax": "Tax Identification Number",
        "businessRegistration": "Business Registration Number",
        "vatNumber": "VAT ID/Number",
        "mobile": "Mobile",
        "editOperator": "Edit Operator",
        "searchEmail": "Search Email",
        "acceptOfferSuccess": "Accept offer successfully",
        "acceptOfferFail": "Booking not found or another supplier has accepted the booking",
        "driverPayout": "Driver payout",
        "payout": "Payout",
        "totalPayout": "Total payout",
        "totalPayoutDes": "The total payout includes the base payout amount plus any additional fees you have chosen to allocate to your providers.",
        "supplierPayout": "Supplier payout",
        "driverTip": "Driver tip",
        "cancel": "Cancel",
        "save": "Save",
        "activeSupplier": "Active supplier",
        "userName": "Username",
        "bookingCompletedView": "Completed Bookings",
        "bookingCompletedViewNote": "View the history of all completed bookings.",
        "driverEarning": "Driver earning",
        "payoutCurrency": "Payout Currency",
        "payoutCurrencyDes": "Currency to pay to supplier/driver",
        "commissionSupplier": "Commission",
        "commissionSupplierDes": "Set commissions the supplier earns for every complete order.",
        "amount": "Fixed amount",
        "percent": "Percentage",
        "commissionSupplierValue": "Input commission value"
    },
    "noResultFound": "No results found",
    "invalidDate": "Date is invalid. Please enter date in format mm/dd/yyyy."
};
