import React from 'react';
import {
  Modal,
  Button,
  FormGroup,
  Form,
  FormControl,
} from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import {
  CCLiteCommonFunc,
  Validation,
  handleResultExport
} from '../../utils/commonFunctions';
import {
  Validator,
  ValidCase,
} from '../../components/validator';
import Confirm from '../../components/confirm/Confirm';
import PropTypes from "prop-types";
import { debounce } from 'lodash';

class ExportComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      email: '',
      isSubmitted: false,
      showModalGetEmail: false,
      confirm: null,
      isSubmiting: false
    };
    this.handleResultExport = handleResultExport.bind(this)
    this.handleConfirmButtonClick = debounce(this.handleConfirmButtonClick, 100);
  }

  componentDidMount() {
    this.setState({
      email: this.props.user && this.props.user.email || ''
    })
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  ValidatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  };

  handleConfirmButtonClick = (e) => {
    this.setState({
      isSubmitted: true,
      isSubmiting: true
    });
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    // gọi thẳng tới export server
    this.doExport(false, this.state.email);
  };

  handleExportToExcelClick = () => {
    // chỉ check export đã tồn tại hay chưa
    this.doExport(true, null)
  }

  doExport = (isCheckExportExisted = false, email = '') => {
    const paramExport = {
      ...this.props.prepareParamExport(),
      isCheckExportExisted, // check export đã tồn tại hay chưa
      email      
    }

    this.props.urlExport(paramExport)
      .then((resp) => {
        this.setState({ isSubmiting: false })
        return this.handleResultExport(resp, isCheckExportExisted)
      })
      .catch((error) => {
        console.log('Export error', error);
      });
  }

  handleConfirmCloseClick = () => {
    this.setState({ confirm: null })
  }

  closeModalGetMail = () => {
    this.setState({ showModalGetEmail: false })
  }

  render() {
    const { valid, email, isSubmitted, showModalGetEmail, isSubmiting } = this.state;
    const { title } = this.props;
    return (
      <>
        <Button
          className="btn-header text-add-header"
          onClick={this.handleExportToExcelClick}
        >
          {title ? title : <Translate value="customer.Export_to_excel" />}
        </Button>

        <Modal
          show={showModalGetEmail}
          backdrop="static"
          className="confirm"
          onHide={this.closeModalGetMail}
        >
          <Modal.Header>
            <Modal.Title>{I18n.t('customer.Export_to_excel')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup
              className={
                isSubmitted ? (valid.email === false ? 'error' : null) : null
              }
            >
              <Form.Label>
                <Translate value="report.query.sendDownloadLink" />
                <span className="require">*</span>
              </Form.Label>
              <FormControl
                type="text"
                className="form-custom"
                onChange={this.handleEmailChange}
                value={email || ''}
                maxLength={500}
              />
              <Validator id="email" callback={this.ValidatorCallback}>
                <ValidCase
                  valid={email && !Validation.isStringEmpty(email)}
                  message={I18n.t('corporate.ERROR_INPUT_EMAIL')}
                  hide={!isSubmitted}
                />
                <ValidCase
                  valid={Validation.validateEmail(email)}
                  message={I18n.t('corporate.ERROR_INPUT_VALID_EMAIL')}
                  hide={!isSubmitted}
                />
              </Validator>
            </FormGroup>
            <p>{<Translate value="report.query.generaltingYourExcel" />}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-cancel" onClick={this.closeModalGetMail}>
              {I18n.t('cue.Close')}
            </Button>
            <Button 
              className="btn-save" 
              onClick={this.handleConfirmButtonClick}
              disabled={isSubmiting}
            >
              {I18n.t('cue.Save')}
            </Button>
          </Modal.Footer>
        </Modal>
        
        <Confirm
          confirm={this.state.confirm}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
        />
      </>
    );
  }
}

ExportComponent.contextTypes = {
  notification: PropTypes.func
};

ExportComponent.propTypes = {
  prepareParamExport: PropTypes.func.isRequired,
  urlExport: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
}

ExportComponent.defaultProps = {
  prepareParamExport: () => {},
  urlExport: "",
  title: "",
  user: {}
}

export default ExportComponent