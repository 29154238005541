import {
    getFleetInfoApi,
    saveGeneralSettingsApi,
    saveSettingsCommissionApi,
    getAllZonesApi,
    updateZoneApi,
    activeZoneApi,
    createZoneApi,
    deactiveZoneApi,
    deleteZoneApi,
    fetchAllZonesApi,
    importZoneApi,

    companyGetAllApi,
    companyFetchApi,
    companyCreateApi,
    companyUpdateApi,
    companyUpdateFromSupplierApi,
    companyDeleteApi,
    companyGetOneApi,
    activeCompanyApi,
    multiActiveCompanyApi,
    exportCompanyToExcelApi,

    apiKeyFindApi,
    apiKeyCreateApi,
    apiKeyDeleteApi,
    apiKeyUpdateApi,
    apiKeyDetailApi,
    createEndpointAPI,
    deleteEndpointAPI,
    updateEndpointAPI,

    getDispatchSettingDetailApi,
    saveDispatchSettingDetailApi,
    getVoiceSMSSettingDetailApi,
    saveVoiceSMSSettingDetailApi,

    getVoipSettingApi,
    saveVoipSettingApi,

    rateRegularFetchApi,
    rateRegularFindApi,
    rateRegularFindOneApi,
    rateRegularCreateApi,
    rateRegularUpdatedApi,
    rateRegularDeleteApi,
    rateRegularActiveApi,
    rateRegularDeactiveApi,

    rateFlatFetchApi,
    rateFlatFindApi,
    rateFlatImportApi,
    rateFlatExportApi,
    rateFlatFindOneApi,
    rateFlatCreateApi,
    rateFlatUpdatedApi,
    rateFlatDeleteApi,
    rateFlatActiveApi,
    rateFlatDeactiveApi,

    rateHourlyFetchApi,
    rateHourlyFindApi,
    rateHourlyFindOneApi,
    rateHourlyCreateApi,
    rateHourlyUpdatedApi,
    rateHourlyDeleteApi,
    rateHourlyActiveApi,
    rateHourlyDeactiveApi,

    hourlyRateFetchApi,
    hourlyRateFindApi,
    hourlyRateFindOneApi,
    hourlyRateCreateApi,
    hourlyRateUpdatedApi,
    hourlyRateDeleteApi,
    hourlyRateActiveApi,
    hourlyRateDeactiveApi,

    flatRoutesFindApi,
    flatRoutesAddApi,
    flatRoutesEditApi,
    flatRoutesDeleteApi,
    flatRoutesDetailApi,
    getDetailFlatRateRoutesByNameApi,

    vehicleFindApi,
    vehicleFetchApi,
    vehicleFindOneApi,
    vehicleCreateApi,
    vehicleUpdatedApi,
    vehicleDeleteApi,
    vehicleActiveApi,
    vehicleMultiActiveApi,
    vehicleMultiDeleteApi,
    exportVehicleToExcelApi,

    vehiclemakeFindApi,
    vehiclemakeFetchApi,
    vehiclemakeCreateApi,
    vehiclemakeUpdatedApi,
    vehiclemakeDeleteApi,

    vehiclecolorFindApi,
    vehiclecolorCreateApi,
    vehiclecolorUpdatedApi,
    vehiclecolorDeleteApi,
    vehiclecolorAutocompleteApi,

    vehiclemodelFindApi,
    vehiclemodelFetchApi,
    vehiclemodelCreateApi,
    vehiclemodelUpdatedApi,
    vehiclemodelDeleteApi,

    carTypeFindApi,
    carTypeUpdateConfigApi,
    carTypeFindOneApi,
    carTypeAssignrateApi,
    carTypeImportAssignRateApi,
    carTypeExportRateApi,
    carTypedApi,
    carTypeUpdateReOrderApi,

    hourlyPackagerateFindApi,
    hourlyPackagerateDetailApi,
    hourlyPackagerateDetailByNameApi,
    hourlyPackagerateAddApi,
    hourlyPackagerateEditApi,
    hourlyPackagerateDeleteApi,

    shiftSettingsCreateApi,
    shiftSettingsDeleteApi,
    shiftSettingsDetailsApi,
    shiftSettingsFind,
    shiftSettingsUpdateApi,

    shiftTemplateCreateApi,
    shiftTemplateDeleteApi,
    shiftTemplateDetailsApi,
    shiftTemplateFindApi,
    shiftTemplateUpdateApi,
    shiftTemplateLoadAllShiftSettingsApi,
    shiftOperationSettingsApi,

    promotionCampaignCreateApi,
    promotionCampaignDeleteApi,
    promotionCampaignDetailsApi,
    promotionCampaignFindApi,
    promotionCampaignUpdateApi,
    promotionCampaignActiveApi,

    promotionCodeActivateApi,
    promotionCodeCreateApi,
    promotionCodeUpdateApi,
    promotionCodeDeleteApi,
    promotionSendInboxApi,
    promotionCodeDetailsApi,
    promotionCodeFindApi,
    promotionCodeFetchForReportApi,
    promotionCodeMultiActivateApi,
    promotionCodeMultiDeleteApi,
    promotionCodeFetchCampaign,
    promotionCodeFetch,
    promotionCodeImports,
    promotionCodeFetchForReferral,

    eventFindApi,
    eventDetailApi,
    eventFetchApi,
    eventCreateApi,
    eventUpdateApi,
    eventUpdateStatusApi,
    eventFindAllApi,

    permissionSettingsDetailsApi,
    permissionSettingsDeleteApi,
    permissionSettingsUpdateApi,
    permissionSettingsCreateApi,
    permissionSettingsFind,
    permissionSettingsActive,
    permissionrolesDefaultApi,
    exportPermissionsToExcelApi,

    citySettingsDetailsApi,
    citySettingsDeleteApi,
    citySettingsUpdateApi,
    citySettingsCreateApi,
    citySettingsFind,

    jobTabSettingsDetailsApi,
    jobTabSettingsDeleteApi,
    jobTabSettingsUpdateApi,
    jobTabSettingsCreateApi,
    jobTabSettingsFind,

    driverNoteSettingsDetailsApi,
    driverNoteSettingsDeleteApi,
    driverNoteSettingsUpdateApi,
    driverNoteSettingsCreateApi,
    driverNoteSettingsFind,
    driverNoteAutocompleteApi,

    sendMessageBookingApi,
    getSMSBookingTemplateApi,
    createSMSBookingTemplateApi,
    updateSMSBookingTemplateApi,
    deleteSMSBookingTemplateApi,
    detailsSMSBookingTemplateApi,
    getAllSMSBookingTemplateApi,
    
    mDispatcherTypeSettingsDetailsApi,
    mDispatcherTypeSettingsDeleteApi,
    mDispatcherTypeSettingsUpdateApi,
    mDispatcherTypeSettingsCreateApi,
    mDispatcherTypeSettingsFind,
    mDispatcherTypeSettingsFetchApi,
    fetchMDispatcherNamesApi,

    flightAwareSettingsGetApi,
    flightAwareSettingsUpdateFind,

    queuingAreaSettingsGetAll,
    queuingAreaSettingsDetailsApi,
    queuingAreaSettingsDeleteApi,
    queuingAreaSettingsUpdateApi,
    queuingAreaSettingsCreateApi,
    queuingAreaSettingsFind,
    queuingAreaSettingsActive,
    queuingAreaSettingsCheckAddress,
    queuingAreaSettingsGetMDispatcherListForQueuing,

    operationCompanyApi,
    fetchOperationApi,
    assginOperationApi,
    unassginOperationApi,

    fetchCorporateApi,
    getAppBannerSettingsApi,
    setAppBannerSettingsApi,
    setPlaybackSettingsApi,
    exportCorporateToExcelApi,

    createThirdPartyLocationApi,
    findThirdPartyLocationApi,
    detailsThirdPartyLocationApi,
    updateThirdPartyLocationApi,
    deleteThirdPartyLocationApi,
    fetchAdditionalServicesApi,
    createAdditionalServicesApi,
    additionalServicesGetOneApi,
    additionalServicesUpdatedApi,
    deleteAdditionalServicesApi,

    createCategoryApi,
    findCategoryApi,
    detailsCategoryApi,
    updateCategoryApi,
    deleteCategoryApi,

    surchargeCreateApi,
    surchargeUpdateApi,
    surchargeDeleteApi,
    surchargeDetailGetApi,

    createFleetServiceApi,
    deleteFleetServiceApi,
    updateFleetServiceApi,
    getFleetServiceApi,

    getGatewaysApi,
    getStripeConnectStatusApi,
    stripeConnectOnboardingApi,
    stripeConnectLoginApi,
    disconnectStripeApi,
    checkExistingStripeAPI,

    getReferralDriverToPaxDetailsApi,
    saveReferralDriverToPaxDetailsApi,
    saveReferralDriverToDriverDetailsApi,
    getReferralCodeListApi,
    findOneReferralCodeApi,
    updatePromoCodeReferralDriverApi,
    removePromoCodeReferralDriverApi,

    getReferralPassengerToPaxDetailsApi,
    saveReferralPassengerToPaxDetailsApi,
    updatePromoCodeReferralPassengerToPaxDetailsApi,
    removePromoCodeReferralPassengerToPaxDetailsApi,

    getServiceFeesApi,
    updateServiceFeesApi,
    createSurchargeServiceFeesApi,
    updateSurchargeServiceFeesApi,
    deleteSurchargeServiceFeesApi,
    updateServiceFeeApplyTypeApi,
    updateFleetCommissionApplyTypeApi,

    getAllAirportFeeApi,
    updateAirportFeeApi,
    createAirportFeeApi,
    deleteAirportFeeApi,
    updateAllAirportFeeApi,

    getDefaultTopupValuesApi,
    updateDefaultTopupValuesApi,

    getAllFaceMaskZoneApi,
    updateAllFaceMaskZoneApi,

    voucherCodeFindApi,
    voucherCodeCreateApi,
    voucherCodeUpdateApi,
    voucherCodeDetailsApi,
    voucherCodeActivateApi,
    voucherCodeMultiActivateApi,
    voucherCodeDeleteApi,
    voucherCodeMultiDeleteApi,
    voucherCodeImports,
    voucherCodeFetchApi,
    voucherCodeFetchForReportApi,

    fetchAirlineApi,

    getPointConfigDetailsApi,
    updatePointConfigApi,
    addRedeemPointConfigApi,
    updateRedeemPointConfigApi,
    removeRedeemPointConfigApi,

    getPWASettingsApi,
    setPWASettingsApi,

    syncThirdPartyBookingSetKeyAPI,
    syncThirdPartyBookingRemoveKeyAPI,
    add3rdPartyIntegrationAPI,
    update3rdIntegrationAPI,
    get3rdPartyIntegrationAPI,
    delete3rdPartyIntegrationAPI,
    checkCredentialIntegrationAPI,
    addHolidayTaxisAPI,
    deleteHolidayTaxisAPI,

    saveInvoiceSettingAPI,
    getInvoiceSettingAPI,

    getEmailConfigDetailApi,
    updateEmailConfigDetailApi,

    driverFieldsFetchAPI,
    driverFieldsDetailAPI,
    driverFieldsEditAPI,
    driverDocumentFetchAPI,
    driverDocumentDetailAPI,
    driverDocumentEditAPI,

    getSocialSettingApi,
    detailFlightProviderApi,
    updateFlightProviderApi,
    updateSocialSettingApi,

    getBookingInvoiceApi,
    overviewInvoiceApi,
    getListInvoiceApi,
    getPDFInvoiceApi,
    createInvoiceApi,
    sendMailInvoiceApi,
    chargeCustomerForInvoiceAPI,
    editInvoiceApi,
    deleteInvoiceApi,
    detailInvoiceApi,

    getInfoSMSApi,
    updateInfoSMSApi,

    trailNotesApi,
    updateVehicleTypeApi,
    detailUpdateCarTypeApi,

    initialServerApi,
    vehicleFindByIdsApi,
    bookingAutoCompleteApi,
    getSMSCountriesAPI,
    createSMSCountriesAPI,
    deleteSMSCountriesAPI,
    updateSMSCountriesAPI,
    updateBookingVisibilityAPI,
    regularRateCreateApi,
    regularRateFindApi,
    regularRateFindOneApi,
    regularRateDeactiveApi,
    regularRateActiveApi,
    regularRateDeleteApi,
    regularRateUpdatedApi,
    flatRateCreateApi,
    flatRateFindOneApi,
    flatRateFindApi,
    flatRateActiveApi,
    flatRateDeactiveApi,
    flatRateUpdatedApi,
    flatRateDeleteApi,
    surchargeTemplateCreateApi,
    surchargeTemplateUpdatedApi,
    surchargeTemplateDeleteApi,
    surchargeTemplateFindApi,
    surchargeTemplateFindOneApi,
    penaltyTemplateFindOneApi,
    penaltyTemplateFindApi,
    penaltyTemplateDeleteApi,
    penaltyTemplateUpdatedApi,
    rateTemplateCreateApi,
    rateTemplateFindOneApi,
    rateTemplateFindApi,
    rateTemplateDeleteApi,
    rateTemplateUpdatedApi,
    rateTemplateAssignRateApi,
    vehicleFindNormalApi,
    carTypeFindNormalApi,
    rateTemplateSetDefaultApi,
    penaltyTemplateCreateApi,
    surchargeItemCreateApi,
    surchargeItemUpdateApi,
    surchargeItemDeleteApi,
    regularRateReorderSpecialApi,
    hourlyRateReorderSpecialApi,
    flatRateReorderSpecialApi,
    supplierPlanCreateApi,
    supplierPlanUpdatedApi,
    supplierPlanDeleteApi,
    supplierPlanFindApi,
    supplierPlanFindOneApi,
    flatRateCloneApi,
    hourlyRateCloneApi,
    regularRateCloneApi,
} from '../constants/ApiConfigs'
import {
    callApi,
    UrlBuilder
} from "../utils/apiUtils";

const queryString = require('query-string');

export const FLEET_INFO_REQUEST = "FLEET_INFO_REQUEST";
export const FLEET_INFO_SUCCESS = "FLEET_INFO_SUCCESS";
export const FLEET_INFO_FAILURE = "FLEET_INFO_FAILURE";

export function initialServer(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        initialServerApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function getGateWays(options) {
    options = Object.assign({}, options);
    const config = {
        method: "get"
    };

    return callApi(
        UrlBuilder(getGatewaysApi, options),
        config,
        null,
        null,
        null,
        true
    );
}

export function getStripeConnectStatus(options) {
    options = Object.assign({}, options);
    const config = {
        method: "get"
    };

    return callApi(
        UrlBuilder(getStripeConnectStatusApi, options),
        config,
        null,
        null,
        null,
        true
    );
}

export function exportCorporateToExcel(options = {}) {
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        exportCorporateToExcelApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function stripeConnectOnboarding(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        stripeConnectOnboardingApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function add3rdPartyIntegration(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        add3rdPartyIntegrationAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function update3rdPartyIntegration(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        update3rdIntegrationAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function addHolidayTaxis(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        addHolidayTaxisAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function saveInvoiceSetting(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        saveInvoiceSettingAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function syncThirdPartyBookingSetKey(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        syncThirdPartyBookingSetKeyAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function syncThirdPartyBookingRemoveKey(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        syncThirdPartyBookingRemoveKeyAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function checkCredentialIntegration(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        checkCredentialIntegrationAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function get3rdPartyIntegration(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        get3rdPartyIntegrationAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function getInvoiceSetting(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        getInvoiceSettingAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function delete3rdPartyIntegration(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        delete3rdPartyIntegrationAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteHolidayTaxis(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        deleteHolidayTaxisAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function stripeConnectLogin(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        stripeConnectLoginApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function disconnectStripe(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        disconnectStripeApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function checkExistingStripe(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        checkExistingStripeAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function getAdditionalServicesList(options) {
    options = Object.assign({}, options);
    const config = {
        method: "get"
    };

    return callApi(
        UrlBuilder(fetchAdditionalServicesApi, options),
        config,
        null,
        null,
        null,
        true
    );
}

export function createAdditionalService(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        createAdditionalServicesApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteAdditionalService(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        deleteAdditionalServicesApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function additionalServicesGetOne(options) {
    options = Object.assign({}, options);
    const config = {
        method: "get"
    };

    return callApi(
        UrlBuilder(additionalServicesGetOneApi, options),
        config,
        null,
        null,
        null,
        true
    );
}

export function additionalServicesUpdate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        additionalServicesUpdatedApi,
        config,
        null,
        null,
        null,
        true
    );
}

function fleetInfoRequest() {
    return {
        type: FLEET_INFO_REQUEST
    }
}

function fleetInfoSuccess(data) {
    return {
        type: FLEET_INFO_SUCCESS,
        data: data
    }
}

function fleetInfoFailure(error) {
    return {
        type: FLEET_INFO_FAILURE,
        error
    }
}
export function fleetInfo(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        getFleetInfoApi,
        config,
        fleetInfoRequest(),
        fleetInfoSuccess,
        fleetInfoFailure,
        true
    );
}

export function saveGeneralSetting(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        saveGeneralSettingsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function saveSettingsCommission(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        saveSettingsCommissionApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function addSurcharge(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        surchargeCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function addFleetService(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        createFleetServiceApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function editSurcharge(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        surchargeUpdateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateFleetService(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        updateFleetServiceApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteSurcharge(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        surchargeDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteFleetService(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        deleteFleetServiceApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function getSurchargeDetail(parameters) {
    const config = {
        method: "get",
    };
    return callApi(
        UrlBuilder(surchargeDetailGetApi, parameters),
        config,
        null,
        null,
        null,
        true
    )
}

export function getFleetService(parameters) {
    const config = {
        method: "get",
    };
    return callApi(
        UrlBuilder(getFleetServiceApi, parameters),
        config,
        null,
        null,
        null,
        true
    )
}

//Zone setting
export function getAllMapZone(fleetId) {
    const config = {
        method: "get"
    };

    return callApi(
        getAllZonesApi + fleetId,
        config,
        null,
        null,
        null,
        true
    );
}

export function createZone(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        createZoneApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function updateZone(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        updateZoneApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function activeZone(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        activeZoneApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deactiveZone(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        deactiveZoneApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function deleteZone(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        deleteZoneApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function fetchAllMapZone(fleetId) {
    const config = {
        method: "get"
    };

    return callApi(
        fetchAllZonesApi + fleetId,
        config,
        null,
        null,
        null,
        true
    );
}

export function importMapZone(options) {
    const config = {
        method: "POST",
        body: options,
        isFormData: true,
    };

    return callApi(
        importZoneApi,
        config,
        null,
        null,
        null,
        true
    );
}

//Company setting
export function getAllCompany(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        companyGetAllApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function fetchCompany(options = {}) {
    const config = {
        method: "get"
    };
    return callApi(
        UrlBuilder(companyFetchApi, options),
        config,
        null,
        null,
        null,
        true
    );
}
export function createCompany(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        companyCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function updateCompany(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        companyUpdateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateCompanyFromSupplier(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        companyUpdateFromSupplierApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteCompany(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        companyDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function activeCompany(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        activeCompanyApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function multiActiveCompany(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        multiActiveCompanyApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function exportCompanyToExcel(options = {}) {
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        exportCompanyToExcelApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function geDetailCompany(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        companyGetOneApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function createApiKey(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        apiKeyCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteApiKey(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        apiKeyDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateApiKey(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        apiKeyUpdateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function createEndpoint(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        createEndpointAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteEndpoint(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        deleteEndpointAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateEndpoint(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        updateEndpointAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function detailApiKey(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        apiKeyDetailApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function apiKeyFind(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        apiKeyFindApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function geDetailCampaign(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        promotionCampaignDetailsApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function getAllCampaign(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        promotionCampaignFindApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function geDispatchSettingDetail(fleetId) {
    const config = {
        method: "get"
    };

    return callApi(
        getDispatchSettingDetailApi + fleetId,
        config,
        null,
        null,
        null,
        true
    );
}

export function saveDispatchSetting(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        saveDispatchSettingDetailApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function getVoiceSMSSettingDetail(fleetId) {
    const config = {
        method: "get"
    };

    return callApi(
        getVoiceSMSSettingDetailApi + fleetId,
        config,
        null,
        null,
        null,
        true
    );
}

export function saveVoiceSMSSetting(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        saveVoiceSMSSettingDetailApi,
        config,
        null,
        null,
        null,
        true
    );
}

//Voip
export function getVoipSetting(fleetId) {
    const config = {
        method: "get"
    };

    return callApi(
        getVoipSettingApi + fleetId,
        config,
        null,
        null,
        null,
        true
    );
}

export function saveVoipSetting(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        saveVoipSettingApi,
        config,
        null,
        null,
        null,
        true
    );
}


// Rate regular settings
export function fetchRateRegular(options = {}) {
    const config = {
        method: "get"
    };
    return callApi(
        UrlBuilder(rateRegularFetchApi, options),
        config,
        null,
        null,
        null,
        true
    );
}
export function getRateAllRegulars(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        rateRegularFindApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function regularRateFind(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        regularRateFindApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function regularRateFindOne(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        regularRateFindOneApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function getRateDetailRegular(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        rateRegularFindOneApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function createRateRegular(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        rateRegularCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function createRegularRate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        regularRateCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function regularRateReorderSpecial(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        regularRateReorderSpecialApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function flatRateReorderSpecial(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        flatRateReorderSpecialApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function hourlyRateReorderSpecial(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        hourlyRateReorderSpecialApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateRateRegular(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        rateRegularUpdatedApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateRegularRate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        regularRateUpdatedApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function activeRateRegular(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "PUT",
        body: JSON.stringify(options)
    };

    return callApi(
        rateRegularActiveApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function regularRateActive(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "PUT",
        body: JSON.stringify(options)
    };

    return callApi(
        regularRateActiveApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function deactiveRateRegular(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "PUT",
        body: JSON.stringify(options)
    };

    return callApi(
        rateRegularDeactiveApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function regularRateDeactive(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "PUT",
        body: JSON.stringify(options)
    };

    return callApi(
        regularRateDeactiveApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function regularRateDelete(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        regularRateDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function deleteRateRegular(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        rateRegularDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

//Rate Flat settings
export function fetchRateFlat(options = {}) {
    const config = {
        method: "get"
    };
    return callApi(
        UrlBuilder(rateFlatFetchApi, options),
        config,
        null,
        null,
        null,
        true
    );
}
export function getAllRateFlats(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        rateFlatFindApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function importRateFlat(data) {
    const config = {
        method: "POST",
        body: data,
        isFormData: true,
        timeout: 240000
    };
    return callApi(
        rateFlatImportApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function rateFlatExport(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        rateFlatExportApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function getRateDetailRateFlat(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        rateFlatFindOneApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function createRateFlat(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        rateFlatCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function updateRateFlat(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        rateFlatUpdatedApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function activeRateFlat(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "PUT",
        body: JSON.stringify(options)
    };

    return callApi(
        rateFlatActiveApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function deactiveRateFlat(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "PUT",
        body: JSON.stringify(options)
    };

    return callApi(
        rateFlatDeactiveApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function deleteRateFlat(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        rateFlatDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

//New Rate Flat settings
export function fetchFlatRate(options = {}) {
    const config = {
        method: "get"
    };
    return callApi(
        UrlBuilder(rateFlatFetchApi, options),
        config,
        null,
        null,
        null,
        true
    );
}
export function getAllFlatRates(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        flatRateFindApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function getRateDetailFlatRate(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        flatRateFindOneApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function createFlatRate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        flatRateCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function clonehourlyRate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        hourlyRateCloneApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function cloneflatRate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        flatRateCloneApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function cloneRegularRate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        regularRateCloneApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function updateFlatRate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        flatRateUpdatedApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function activeFlatRate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "PUT",
        body: JSON.stringify(options)
    };

    return callApi(
        flatRateActiveApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function deactiveFlatRate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "PUT",
        body: JSON.stringify(options)
    };

    return callApi(
        flatRateDeactiveApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function deleteFlatRate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        flatRateDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function surchargeTemplateCreate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        surchargeTemplateCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function surchargeTemplateUpdated(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        surchargeTemplateUpdatedApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function surchargeTemplateDelete(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        surchargeTemplateDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function surchargeTemplateFind(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        surchargeTemplateFindApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function surchargeTemplateFindOne(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        surchargeTemplateFindOneApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function surchargeItemCreate(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        surchargeItemCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function surchargeItemUpdate(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        surchargeItemUpdateApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function surchargeItemDelete(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        surchargeItemDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function penaltyTemplateCreate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        penaltyTemplateCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function penaltyTemplateUpdated(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        penaltyTemplateUpdatedApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function penaltyTemplateDelete(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        penaltyTemplateDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function penaltyTemplateFind(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        penaltyTemplateFindApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function penaltyTemplateFindOne(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        penaltyTemplateFindOneApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function supplierPlanCreate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        supplierPlanCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function supplierPlanUpdated(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        supplierPlanUpdatedApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function supplierPlanDelete(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        supplierPlanDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function supplierPlanFind(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        supplierPlanFindApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function supplierPlanFindOne(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        supplierPlanFindOneApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function rateTemplateCreate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        rateTemplateCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function rateTemplateUpdated(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        rateTemplateUpdatedApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function rateTemplateDelete(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        rateTemplateDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function rateTemplateFind(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        rateTemplateFindApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function rateTemplateFindOne(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        rateTemplateFindOneApi,
        config,
        null,
        null,
        null,
        true
    );

}

export function rateTemplateAssignRate(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        rateTemplateAssignRateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function rateTemplateSetDefault(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        rateTemplateSetDefaultApi,
        config,
        null,
        null,
        null,
        true
    );
}

//Rate Hourly settings
export function fetchRateHourly(options = {}) {
    const config = {
        method: "get"
    };
    return callApi(
        UrlBuilder(rateHourlyFetchApi, options),
        config,
        null,
        null,
        null,
        true
    );
}
export function getAllRateHourlies(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        rateHourlyFindApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function getRateDetailRateHourly(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        rateHourlyFindOneApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function createRateHourly(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        rateHourlyCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function updateRateHourly(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        rateHourlyUpdatedApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function activeRateHourly(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "PUT",
        body: JSON.stringify(options)
    };

    return callApi(
        rateHourlyActiveApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function deactiveRateHourly(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "PUT",
        body: JSON.stringify(options)
    };

    return callApi(
        rateHourlyDeactiveApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function deleteRateHourly(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        rateHourlyDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}
//New Rate Hourly settings
export function fetchHourlyRate(options = {}) {
    const config = {
        method: "get"
    };
    return callApi(
        UrlBuilder(hourlyRateFetchApi, options),
        config,
        null,
        null,
        null,
        true
    );
}
export function getAllRateHourlyRate(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        hourlyRateFindApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function getRateDetailHourlyRate(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        hourlyRateFindOneApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function createHourlyRate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        hourlyRateCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function updateHourlyRate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        hourlyRateUpdatedApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function activeHourlyRate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "PUT",
        body: JSON.stringify(options)
    };

    return callApi(
        hourlyRateActiveApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function deactiveHourlyRate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "PUT",
        body: JSON.stringify(options)
    };

    return callApi(
        hourlyRateDeactiveApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function deleteHourlyRate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        hourlyRateDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

//Car MGMT settings
export function getAllCars(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        vehicleFindApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function carTypeFindNormal(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        carTypeFindNormalApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function vehicleFindByIds(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        vehicleFindByIdsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function bookingAutoComplete(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        bookingAutoCompleteApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function getRateDetailCar(options) {
    options = Object.assign({}, options);
    const config = {
        method: "get"
    };

    return callApi(
        UrlBuilder(vehicleFindOneApi, options),
        config,
        null,
        null,
        null,
        true
    );
}
export function createCars(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        vehicleCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function updateCars(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        vehicleUpdatedApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function activeCars(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "Post",
        body: JSON.stringify(options)
    };

    return callApi(
        vehicleActiveApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function deleteCars(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        vehicleDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function activeMultiCars(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "Post",
        body: JSON.stringify(options)
    };

    return callApi(
        vehicleMultiActiveApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function deleteMultiCars(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "Post",
        body: JSON.stringify(options)
    };

    return callApi(
        vehicleMultiDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function exportVehilceToExcel(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        exportVehicleToExcelApi,
        config,
        null,
        null,
        null,
        false
    );
}
//Car Car Make settings
export function getAllCarmake(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        vehiclemakeFindApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function getCarMakeList(options) {
    options = Object.assign({}, options);
    const config = {
        method: "get"
    };

    return callApi(
        UrlBuilder(vehiclemakeFetchApi, options),
        config,
        null,
        null,
        null,
        true
    );
}
export function createCarMake(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        vehiclemakeCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function updateCarMake(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        vehiclemakeUpdatedApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function deleteCarMake(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        vehiclemakeDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

//Car Car Model settings
export function getAllCarModel(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        vehiclemodelFindApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function getCarModelList(options) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        vehiclemodelFetchApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function createCarModel(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        vehiclemodelCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function updateCarModel(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        vehiclemodelUpdatedApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function deleteCarModel(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        vehiclemodelDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

//Car Color settings
export function getAllCarColor(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        vehiclecolorFindApi,
        config,
        null,
        null,
        null,
        true
    );
};
export function createCarColor(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        vehiclecolorCreateApi,
        config,
        null,
        null,
        null,
        true
    );
};
export function updateCarColor(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        vehiclecolorUpdatedApi,
        config,
        null,
        null,
        null,
        true
    );
};
export function deleteCarColor(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        vehiclecolorDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
};
export function autocompleteCarColor(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        vehiclecolorAutocompleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

//Car Car Type settings
export function loadCarType(fleetId, isLoading = true) {
    const config = {
        method: "get"
    };

    return callApi(
        carTypedApi + fleetId,
        config,
        null,
        null,
        null,
        isLoading
    );
}

export function updateReOrderCarType(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        carTypeUpdateReOrderApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function getAllCarType(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        carTypeFindApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function carTypeUpdateConfig(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        carTypeUpdateConfigApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function getCarTypeDetail(options) {
    options = Object.assign({}, options);
    const config = {
        method: "get"
    };

    return callApi(
        UrlBuilder(carTypeFindOneApi, options),
        config,
        null,
        null,
        null,
        true
    );
}
export function UpdateCarType(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        carTypeAssignrateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function carTypeExportRate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        carTypeExportRateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function carTypeImportAssignRate(data) {
    const config = {
        method: "POST",
        body: data,
        isFormData: true,
        timeout: 240000
    };
    return callApi(
        carTypeImportAssignRateApi,
        config,
        null,
        null,
        null,
        true
    );
}

//Flat rate Routes settings

export function getAllFlatRateRoutes(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        flatRoutesFindApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function getDetailFlatRateRoutes(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        flatRoutesDetailApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function getDetailFlatRateRoutesByName(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        getDetailFlatRateRoutesByNameApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function createFlatRateRoute(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        flatRoutesAddApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function updateFlatRateRoute(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        flatRoutesEditApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function deleteFlatRateRoute(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        flatRoutesDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

//Hourly rate package settings

export function getAllHourlyRatePackages(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        hourlyPackagerateFindApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function getDetailHourlyRatePackage(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        hourlyPackagerateDetailApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function hourlyPackagerateDetailByName(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        hourlyPackagerateDetailByNameApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function createHourlyRatePackage(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        hourlyPackagerateAddApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function updateHourlyRatePackage(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        hourlyPackagerateEditApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function deleteHourlyRatePackage(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        hourlyPackagerateDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}


//Shift settings settings

export function findShiftSettings(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        shiftSettingsFind,
        config,
        null,
        null,
        null,
        true
    );
}

export function createShiftSettings(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        shiftSettingsCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateShiftSettings(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        shiftSettingsUpdateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteShiftSettings(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        shiftSettingsDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function detailsShiftSettings(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        shiftSettingsDetailsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function findShiftTemplate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        shiftTemplateFindApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function createShiftTemplate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        shiftTemplateCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateShiftTemplate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        shiftTemplateUpdateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteShiftTemplate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        shiftTemplateDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function detailsShiftTemplate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        shiftTemplateDetailsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function getAllShiftSettingsForSelection(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "GET",
    };

    return callApi(
        UrlBuilder(shiftTemplateLoadAllShiftSettingsApi, options),
        config,
        null,
        null,
        null,
        true
    );
}

//Promotion Setting

export function findCampaigns(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        promotionCampaignFindApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function createCampaign(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        promotionCampaignCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateCampaign(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        promotionCampaignUpdateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function activeCampaign(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        promotionCampaignActiveApi,
        config,
        null,
        null,
        null,
        true
    );
}


export function deleteCampaign(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        promotionCampaignDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function detailsCampaign(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        promotionCampaignDetailsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function detailsPromotionCode(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        promotionCodeDetailsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function findPromotionCodes(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        promotionCodeFindApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function fetchPromoCodesForReport(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        promotionCodeFetchForReportApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function fetchVoucherCodesForReport(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        voucherCodeFetchForReportApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function createPromotionCodes(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options),
        timeout: 600000
    };

    return callApi(
        promotionCodeCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updatePromotionCode(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        promotionCodeUpdateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deletePromotionCode(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        promotionCodeDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function sendInboxPromotionCode(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        promotionSendInboxApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function activePromotionCode(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        promotionCodeActivateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteMultiPromotionCode(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        promotionCodeMultiDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function activeMultiPromotionCode(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        promotionCodeMultiActivateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function fetchPromotionCodeForReferral(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        promotionCodeFetchForReferral,
        config,
        null,
        null,
        null,
        true
    );
}

// Quest
export function findEvents(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "GET",
    };
    const stringified = queryString.stringify(options);
    let uri = eventFindApi + stringified;
    return callApi(
        uri,
        config,
        null,
        null,
        null,
        true
    );
}
export function findEventAll(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "GET",
    };
    const stringified = queryString.stringify(options);
    let uri = eventFindAllApi + stringified;
    return callApi(
        uri,
        config,
        null,
        null,
        null,
        true
    );
}


export function detailsEvent(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "GET",
    };
    const stringified = queryString.stringify(options);
    let uri = eventDetailApi + stringified;
    return callApi(
        uri,
        config,
        null,
        null,
        null,
        true
    );
};

export function fetchEvents(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "GET",
    };
    const stringified = queryString.stringify(options);
    let uri = eventFetchApi + stringified;
    return callApi(
        uri,
        config,
        null,
        null,
        null,
        true
    );
}


export function createEvent(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };
    return callApi(
        eventCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}


export function updateEvent(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };
    return callApi(
        eventUpdateApi,
        config,
        null,
        null,
        null,
        true
    );
};

export function updateEventStatus(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };
    return callApi(
        eventUpdateStatusApi,
        config,
        null,
        null,
        null,
        true
    );
};


// Voucher code
export function findVoucherCodes(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        voucherCodeFindApi,
        config,
        null,
        null,
        null,
        true
    );
};

export function createVoucherCode(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        voucherCodeCreateApi,
        config,
        null,
        null,
        null,
        true
    );
};

export function updateVoucherCode(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        voucherCodeUpdateApi,
        config,
        null,
        null,
        null,
        true
    );
};

export function detailsVoucherCode(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        voucherCodeDetailsApi,
        config,
        null,
        null,
        null,
        true
    );
};

export function activeVoucherCode(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        voucherCodeActivateApi,
        config,
        null,
        null,
        null,
        true
    );
};

export function deleteVoucherCode(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        voucherCodeDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
};

export function activeMultiVoucherCode(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        voucherCodeMultiActivateApi,
        config,
        null,
        null,
        null,
        true
    );
};

export function deleteMultiVoucherCode(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        voucherCodeMultiDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
};

export function importVoucherCodes(data) {
    const config = {
        method: "POST",
        body: data,
        isFormData: true,
        timeout: 60000
    };
    return callApi(
        voucherCodeImports,
        config,
        null,
        null,
        null,
        true
    );
};


export function findAllCampaignForSelection(fleetId, promotionCode) {
    const config = {
        method: "GET",
    };
    let uri = `${promotionCodeFetchCampaign}${fleetId}`;
    if (promotionCode) {
        uri += `&promotionCode=true`
    }
    return callApi(
        uri,
        config,
        null,
        null,
        null,
        true
    );
}

export function fetchMDispatcherTypesForSelection(fleetId) {
    const config = {
        method: "GET",
    };
    let uri = `${mDispatcherTypeSettingsFetchApi}${fleetId}`;
    return callApi(
        uri,
        config,
        null,
        null,
        null,
        true
    );
}

export function fetchMDispatcherNamesForSelection(options) {
    const config = {
        method: "GET",
    };
    return callApi(
        UrlBuilder(fetchMDispatcherNamesApi, options),
        config,
        null,
        null,
        null,
        true
    );
}

export function findAllPromotionCodeForSelection(fleetId) {
    const config = {
        method: "GET",
    };

    return callApi(
        promotionCodeFetch + fleetId,
        config,
        null,
        null,
        null,
        true
    );
}
export function findAllVoucherCodeForSelection(fleetId) {
    const config = {
        method: "GET",
    };

    return callApi(
        voucherCodeFetchApi + fleetId,
        config,
        null,
        null,
        null,
        true
    );
}

export function fetchAllCorporateForSelection(fleetId) {
    const config = {
        method: "GET",
    };
    return callApi(
        // isAirline ? fetchAirlineApi + fleetId + "&isAirline=true": fetchCorporateApi + fleetId + "&isAirline=false",
        fetchCorporateApi + fleetId,
        config,
        null,
        null,
        null,
        true
    );
}

export function importPromotionCodes(data) {
    const config = {
        method: "POST",
        body: data,
        isFormData: true,
        timeout: 60000
    };
    return callApi(
        promotionCodeImports,
        config,
        null,
        null,
        null,
        true
    );
}

//permission Setting

export function findPermission(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        permissionSettingsFind,
        config,
        null,
        null,
        null,
        true
    );
}

export function createPermission(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        permissionSettingsCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updatePermission(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        permissionSettingsUpdateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deletePermission(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        permissionSettingsDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function detailsPermission(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        permissionSettingsDetailsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function changeStatusPermission(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        permissionSettingsActive,
        config,
        null,
        null,
        null,
        true
    );
}

export function getDefaultRolesPermission(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "GET"
    };

    return callApi(
        UrlBuilder(permissionrolesDefaultApi, options),
        config,
        null,
        null,
        null,
        true
    );
}

export function exportPermissionsToExcel(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        exportPermissionsToExcelApi,
        config,
        null,
        null,
        null,
        false
    );
}

//City Setting

export function findCitis(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        citySettingsFind,
        config,
        null,
        null,
        null,
        true
    );
}

export function findJobTab(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        jobTabSettingsFind,
        config,
        null,
        null,
        null,
        true
    );
}

export function createJobtab(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        jobTabSettingsCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function detailsJobtab(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        jobTabSettingsDetailsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteJobtab(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        jobTabSettingsDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateJobtab(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        jobTabSettingsUpdateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function createCity(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        citySettingsCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateCity(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        citySettingsUpdateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteCity(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        citySettingsDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function detailsCity(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        citySettingsDetailsApi,
        config,
        null,
        null,
        null,
        true
    );
}

//mDispatcher Type

export function findMDispatcherTypes(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        mDispatcherTypeSettingsFind,
        config,
        null,
        null,
        null,
        true
    );
}

export function createMDispatcherType(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        mDispatcherTypeSettingsCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateMDispatcherType(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        mDispatcherTypeSettingsUpdateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteMDispatcherType(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        mDispatcherTypeSettingsDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function detailsMDispatcherType(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        mDispatcherTypeSettingsDetailsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function fetchMDispatcherType(fleetId) {
    const config = {
        method: "get",
    };

    return callApi(
        mDispatcherTypeSettingsFetchApi + fleetId,
        config,
        null,
        null,
        null,
        true
    );
}

//Flight Intergration Setting
export function getFlightIntergration(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "get",
    };

    return callApi(
        UrlBuilder(flightAwareSettingsGetApi, options),
        config,
        null,
        null,
        null,
        true
    );
}

export function updateFlightIntergration(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        flightAwareSettingsUpdateFind,
        config,
        null,
        null,
        null,
        true
    );
}

//Flight Provider Setting
export function detailFlightProvider(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        detailFlightProviderApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateFlightProvider(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        updateFlightProviderApi,
        config,
        null,
        null,
        null,
        true
    );
}

//Queuing Area
export function fetchAllQueuingArea(fleetId) {
    const config = {
        method: "get",
    };

    return callApi(
        UrlBuilder(queuingAreaSettingsGetAll, { fleetId }),
        config,
        null,
        null,
        null,
        true
    );
}

export function findQueuingAreas(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        queuingAreaSettingsFind,
        config,
        null,
        null,
        null,
        true
    );
}

export function createQueuingArea(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        queuingAreaSettingsCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateQueuingArea(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        queuingAreaSettingsUpdateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteQueuingArea(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        queuingAreaSettingsDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function detailsQueuingArea(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        queuingAreaSettingsDetailsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function changeStatusQueuingArea(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        queuingAreaSettingsActive,
        config,
        null,
        null,
        null,
        true
    );
}

export function checkAddressQueuingArea(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        queuingAreaSettingsCheckAddress,
        config,
        null,
        null,
        null,
        true
    );
}

export function getMDispatcherListForQueuing(fleetId, queuingAreaId) {
    const config = {
        method: "get",
    };

    return callApi(
        UrlBuilder(queuingAreaSettingsGetMDispatcherListForQueuing, { fleetId, queuingAreaId }),
        config,
        null,
        null,
        null,
        true
    );
}

//Operation
export function getOperationCompany(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "get"
    };
    return callApi(
        UrlBuilder(operationCompanyApi, options),
        config,
        null,
        null,
        null,
        true
    );
}

export function getOperationShift(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "GET",
    };
    return callApi(
        UrlBuilder(shiftOperationSettingsApi, options),
        config,
        null,
        null,
        null,
        true
    );
}

export function fetchOperation(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "GET",
    };
    return callApi(
        UrlBuilder(fetchOperationApi, options),
        config,
        null,
        null,
        null,
        true
    );
}

export function assginOperation(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        assginOperationApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function unassginOperation(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        unassginOperationApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function getAppBannerSetting(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        getAppBannerSettingsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function saveAppBannerSetting(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        setAppBannerSettingsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function getPWASetting(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        getPWASettingsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function savePWASetting(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        setPWASettingsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function savePlaybackSetting(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        setPlaybackSettingsApi,
        config,
        null,
        null,
        null,
        true
    );
}
//Third Party Location
export function createThirdPartyLocation(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        createThirdPartyLocationApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function findThirdPartyLocation(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        findThirdPartyLocationApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function detailsThirdPartyLocation(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        detailsThirdPartyLocationApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateThirdPartyLocation(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        updateThirdPartyLocationApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteThirdPartyLocation(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "delete",
        body: JSON.stringify(options)
    };

    return callApi(
        deleteThirdPartyLocationApi,
        config,
        null,
        null,
        null,
        true
    );
}

/* Category */

export function createCategory(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        createCategoryApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function findCategory(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        findCategoryApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function detailsCategory(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        detailsCategoryApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateCategory(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        updateCategoryApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteCategory(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        deleteCategoryApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function getReferralDriverToPaxDetails(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        getReferralDriverToPaxDetailsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function saveReferralDriverToPaxDetails(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        saveReferralDriverToPaxDetailsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function saveReferralDriverToDriverDetails(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        saveReferralDriverToDriverDetailsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updatePromoCodeReferralDriver(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };
    return callApi(
        updatePromoCodeReferralDriverApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function removePromoCodeReferralDriver(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };
    return callApi(
        removePromoCodeReferralDriverApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function referralCodeAutocomplete(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        getReferralCodeListApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function findOneReferralCode(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: 'get'
    };
    return callApi(UrlBuilder(findOneReferralCodeApi, options), config, null, null, null, true);
};

export function getReferralPassengerToPaxDetails(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        getReferralPassengerToPaxDetailsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function saveReferralPassengerToPaxDetails(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        saveReferralPassengerToPaxDetailsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updatePromoCodeReferralPassengerToPaxDetails(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        updatePromoCodeReferralPassengerToPaxDetailsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function removePromoCodeReferralPassengerToPaxDetails(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        removePromoCodeReferralPassengerToPaxDetailsApi,
        config,
        null,
        null,
        null,
        true
    );
}


export function getServiceFeesByZone(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "GET"
    };

    return callApi(
        UrlBuilder(getServiceFeesApi, options),
        config,
        null,
        null,
        null,
        true
    );
}

export function updateServiceFeesByZone(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        updateServiceFeesApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function createSurchargeServiceFees(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        createSurchargeServiceFeesApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function updateSurchargeServiceFees(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        updateSurchargeServiceFeesApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteSurchargeServiceFees(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        deleteSurchargeServiceFeesApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateFleetCommissionApplyType(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        updateFleetCommissionApplyTypeApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function updateServiceFeeApplyType(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        updateServiceFeeApplyTypeApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateAirportFee(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        updateAirportFeeApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateAllAirportFee(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        updateAllAirportFeeApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function createAirportFee(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        createAirportFeeApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteAirportFee(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        deleteAirportFeeApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function getAllAirportFee(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "GET"
    };

    return callApi(
        UrlBuilder(getAllAirportFeeApi, options),
        config,
        null,
        null,
        null,
        true
    );
}

// FaceMaskZone
export function getAllFaceMaskZone(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "GET"
    };

    return callApi(
        UrlBuilder(getAllFaceMaskZoneApi, options),
        config,
        null,
        null,
        null,
        true
    );
}

export function updateAllFaceMaskZone(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        updateAllFaceMaskZoneApi,
        config,
        null,
        null,
        null,
        true
    );
}



export function getDefaultTopupValues(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "GET"
    };

    return callApi(
        UrlBuilder(getDefaultTopupValuesApi, options),
        config,
        null,
        null,
        null,
        true
    );
}

export function updateDefaultTopupValues(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        updateDefaultTopupValuesApi,
        config,
        null,
        null,
        null,
        true
    );
}
//Message template Setting
export function sendMessageBooking(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        sendMessageBookingApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function findBookingSMSTemplates(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        getSMSBookingTemplateApi,
        config,
        null,
        null,
        null,
        true
    );
}


export function getAllBookingSMSTemplates(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        getAllSMSBookingTemplateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function createBookingSMSTemplate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        createSMSBookingTemplateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateBookingSMSTemplate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        updateSMSBookingTemplateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteBookingSMSTemplate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        deleteSMSBookingTemplateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function detailsBookingSMSTemplate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        detailsSMSBookingTemplateApi,
        config,
        null,
        null,
        null,
        true
    );
}
//Driver note Setting

export function findDriverNotes(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        driverNoteSettingsFind,
        config,
        null,
        null,
        null,
        true
    );
}

export function createDriverNote(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        driverNoteSettingsCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateDriverNote(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        driverNoteSettingsUpdateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteDriverNote(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        driverNoteSettingsDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function detailsDriverNote(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        driverNoteSettingsDetailsApi,
        config,
        null,
        null,
        null,
        true
    );
}
export function driverNoteAutocomplete(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        driverNoteAutocompleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function getPointConfigDetails(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        getPointConfigDetailsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updatePointConfig(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        updatePointConfigApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function addRedeemPointConfig(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        addRedeemPointConfigApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateRedeemPointConfig(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        updateRedeemPointConfigApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function removeRedeemPointConfig(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        removeRedeemPointConfigApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function fetchDriverDocument(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        driverDocumentFetchAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function detailDriverDocument(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        driverDocumentDetailAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function editDriverDocument(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        driverDocumentEditAPI,
        config,
        null,
        null,
        null,
        true
    );
}
  
export function fetchDriverFields(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        driverFieldsFetchAPI,
        config,
        null,
        null,
        null,
        true
    );
}
export function detailDriverFields(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        driverFieldsDetailAPI,
        config,
        null,
        null,
        null,
        true
    );
}
export function editDriverFields(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        driverFieldsEditAPI,
        config,
        null,
        null,
        null,
        true
    );
}
  
 export function getEmailConfigDetail(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        getEmailConfigDetailApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateEmailConfigDetail(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        updateEmailConfigDetailApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function getSocialSetting(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        getSocialSettingApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function createInvoice(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        createInvoiceApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function sendMailInvoice(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        sendMailInvoiceApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function chargeCustomerForInvoice(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        chargeCustomerForInvoiceAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function editInvoice(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "PUT",
        body: JSON.stringify(options)
    };

    return callApi(
        `${editInvoiceApi}/${options.invoiceId}`,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteInvoice(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "DELETE",
    };

    return callApi(
        `${deleteInvoiceApi}/${options.invoiceId}`,
        config,
        null,
        null,
        null,
        true
    );
}

export function getBookingInvoice(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "get"
    };

    return callApi(
        UrlBuilder(getBookingInvoiceApi, options),
        config,
        null,
        null,
        null,
        true
    );

}

export function overviewInvoice(options = {}) {
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        overviewInvoiceApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function detailInvoice(id) {
    const config = {
        method: "get"
    };

    return callApi(
        UrlBuilder(detailInvoiceApi, id, true, id),
        config,
        null,
        null,
        null,
        true
    );
}

export function getPDFInvoice(id) {
    const config = {
        method: "get"
    };

    return callApi(
        UrlBuilder(getPDFInvoiceApi, id, true, id),
        config,
        null,
        null,
        null,
        true
    );
}

export function getListInvoice(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "get"
    };

    return callApi(
        UrlBuilder(getListInvoiceApi, options),
        config,
        null,
        null,
        null,
        true
    );
}

export function updateSocialSetting(options = {}) {
    const config = {
        method: "POST",
        body: options,
        isFormData: true
    };

    return callApi(
        updateSocialSettingApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function getInfoSMS(options = {}) {
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        getInfoSMSApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function getSMSCountries(options = {}) {
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        getSMSCountriesAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function createSMSCountries(options = {}) {
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        createSMSCountriesAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteSMSCountries(options = {}) {
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        deleteSMSCountriesAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateSMSCountries(options = {}) {
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        updateSMSCountriesAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateInfoSMS(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        updateInfoSMSApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function createTrailNotes(options = {}) {
    const config = {
        method: "POST",
        body: JSON.stringify(options),
    };

    return callApi(
        trailNotesApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function getTrailNotes(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "GET",
    };

    return callApi(
        trailNotesApi + `?fleetId=${options.fleetId}&bookId=${options.bookId}`,
        config,
        null,
        null,
        null,
        true)
}
export function updateVehicleType (options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        updateVehicleTypeApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteTrailNotes(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "DELETE",
        body: JSON.stringify(options)
    };

    return callApi(
        trailNotesApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function detailUpdateCarType (options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        detailUpdateCarTypeApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateBookingVisibility(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: 'PUT',
        body: JSON.stringify(options)
    };

    return callApi(
      updateBookingVisibilityAPI,
      config,
      null,
      null,
      null,
      true,
      true
    );
}
